<ng-container *ngIf="is_data_loaded; then component_content; else preloader">
</ng-container>

<ng-template #preloader>
    <app-preloader-progressbar></app-preloader-progressbar>
</ng-template>

<ng-template #component_content>
    <div class="app-track-delta-overview-container">

        <div class="app-track-delta-overview-datepicker-container">
            <div fxLayout="row">
                <div fxFlex>
                    С даты
                </div>
                <div fxFlex>
                    По дату
                </div>
            </div>
        </div>
    </div>
</ng-template>