import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/models/user/user';

export const get_user_initials = (user: User): string => {
  const first_name_initial = user.first_name.charAt(0);
  const last_name_initial = user.last_name.charAt(0);
  return `${first_name_initial}${last_name_initial}`;
};

@Pipe({ name: 'getUserInitials' })
export class GetUserInitials implements PipeTransform {
  transform(user: User): string {
    return get_user_initials(user);
  }
}
