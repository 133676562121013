import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { SpecialityEditGradesComponent } from './speciality-edit-grades/speciality-edit-grades.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { UiComponents } from '@components/ui/ui.module';
import { SpecialityEditCompetenciesComponent } from './speciality-edit-competencies/speciality-edit-competencies.component';
import { MatTreeModule } from '@angular/material/tree';
import { CommonComponentsModule } from '@components/common/common.module';

@NgModule({
  declarations: [
    SpecialityEditGradesComponent,
    SpecialityEditCompetenciesComponent,
  ],
  imports: [
    FlexLayoutModule,
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatTreeModule,

    FormsModule,
    UiComponents,
    CommonComponentsModule,
  ],
  exports: [SpecialityEditGradesComponent, SpecialityEditCompetenciesComponent],
})
export class CommonFormModule {}
