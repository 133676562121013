import { Component, Input } from '@angular/core';
import { AsyncList } from '@rest/AsyncList';
import { ThingLevel } from '@models/ontology/thing-level';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Thing } from '@models/ontology/thing';
import { Competence } from '@models/competencies/competence';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CompetenceAssertionHttpService } from '@services/http/CompetenceAssertionHttpService';
import { CompetenceAssertion } from '@models/competencies/competence-assertion';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { getVerifiedCompetenceTooltipText } from 'src/app/utils/template-filters/get-verified-competence-tooltip-text';

@Component({
  selector: 'app-thing-select-level',
  templateUrl: './thing-select-level.component.html',
  styleUrls: ['./thing-select-level.component.css'],
})
export class ThingSelectLevelComponent {
  @Input() thingLevels: AsyncList<ThingLevel>;
  @Input() thing: AsyncDetail<Thing>;
  @Input() competencies: AsyncList<Competence>;

  competenceAssertions: AsyncList<CompetenceAssertion>;
  isSaveButtonDisabled = false;

  selectedLevelUuid = '';
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private _competenceAssertionHttpService: CompetenceAssertionHttpService
  ) {
    this.competenceAssertions = new AsyncList<CompetenceAssertion>(
      this._competenceAssertionHttpService
    );
  }

  get competence(): Competence | null {
    return this.competencies.state.items.find((competence) => {
      const user: any = competence.user;

      return (
        competence.thing == this.thing.state.item.uuid &&
        (user.uuid || user) == this.authService.get_current_user_uuid()
      );
    });
  }

  get currentLevel(): ThingLevel | null {
    return (
      this.competence &&
      this.thingLevels.state.items.find((thingLevel) => {
        return thingLevel.uuid === this.competence.thing_level;
      })
    );
  }

  selectLevel(thingLevel: ThingLevel): void {
    this.selectedLevelUuid = thingLevel.uuid;
  }

  save(): void {
    this.isSaveButtonDisabled = true;
    const thingLevel = this.thingLevels.state.items.find(
      (level) => level.uuid === this.selectedLevelUuid
    );

    if (this.competence) {
      this.competencies
        .update(this.competence?.uuid, {
          thing_level: thingLevel.uuid,
        })
        .subscribe((response) => {
          this._createCompetenceAssertion(response);
          this.selectedLevelUuid = '';
        });
    } else {
      this.competencies
        .create({
          user: this.authService.get_current_user_uuid(),
          thing: this.thing.state.item.uuid,
          thing_level: thingLevel.uuid,
        })
        .subscribe((response) => {
          this._createCompetenceAssertion(response);
          this.selectedLevelUuid = '';
        });
    }
  }

  private _createCompetenceAssertion(competence: Competence) {
    this.competenceAssertions
      .create({
        competence: competence.uuid,
        from_user: this.authService.get_current_user_uuid(),
        to_user: this.authService.get_current_user_uuid(),
        thing_level: competence.thing_level,
        thing: competence.thing,
      })
      .subscribe((response) => {
        this.isSaveButtonDisabled = false;
        this.alertService.success('Выбор сохранен');
      });
  }

  isSelected(thingLevel: ThingLevel): Boolean {
    if (this.selectedLevelUuid) {
      return thingLevel.uuid === this.selectedLevelUuid;
    }

    return thingLevel.uuid === this.currentLevel?.uuid;
  }

  getVerifiedCompetenceTooltipText = getVerifiedCompetenceTooltipText;
}
