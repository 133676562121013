<div
  class="imported-data-tree-wrapper"
  *ngIf="
    importedDataList.state.isLoaded && importedDataList.state.items.length > 0
  "
>
  <app-domain-detail-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    [hasChild]="hasChild"
    [expandDomain]="expandDomain"
    [setDetailFormConfig]="onDialogShow"
    [remove]="remove"
    [isCreateHighLevelDomainButtonShow]="false"
  ></app-domain-detail-tree>
  <div class="tree-buttons-wrapper">
    <button mat-raised-button (click)="saveToLibrary()">
      Сохранить в библиотеку
    </button>

    <button
      mat-raised-button
      color="accent"
      (click)="clearImportedData()"
      class="clear-button"
    >
      Очистить загруженные данные
    </button>
  </div>
</div>

<div
  class="load-domains-button-wrapper"
  *ngIf="
    importedDataList.state.isLoaded && importedDataList.state.items.length === 0
  "
>
  <div>
    <input
      #uploadInput
      hidden="true"
      type="file"
      onclick="this.value=null"
      (change)="uploadInputChange($event)"
      accept=".xlsx"
    />
    <button mat-flat-button color="primary" (click)="uploadInput.click()">
      Загрузить домены
      <mat-icon>cloud_upload</mat-icon>
    </button>
  </div>
</div>
