import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface TextBlocksData {
  title: string;
  text: string;
  color: string;
}

@Component({
  selector: 'app-block-text-help-dialog',
  templateUrl: './block-text-help-dialog.component.html',
  styleUrls: ['./block-text-help-dialog.component.css'],
})
export class BlockTextHelpDialogComponent implements OnInit {
  isDynamicSettings = false;

  constructor(
    public dialogRef: MatDialogRef<BlockTextHelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      textBlocksData: TextBlocksData[];
    }
  ) {}

  ngOnInit(): void {}

  getBlockColor(color: string): object {
    return { 'background-color': color };
  }
}
