<form [formGroup]="form" (ngSubmit)="onSubmitForm()">
  <h2>Отправить тестовое письмо</h2>
  <div class="form-field">
    <mat-form-field [style.width.rem]="20">
      <mat-label>Email</mat-label>
      <input
        matInput
        formControlName="email"
        placeholder="Email получателя"
        type="text"
        required
      />
    </mat-form-field>
  </div>

  <div class="form-buttons">
    <button mat-raised-button type="submit" [disabled]="!form.valid">
      Отправить
    </button>
  </div>
</form>
