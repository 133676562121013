import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewSetHttpService } from './rest/ViewSetHttpService';
import { ActiveUsers } from '@models/stats/active-users';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActiveUsersHttpService extends ViewSetHttpService<ActiveUsers> {
  http: HttpClient;

  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'stats/active_users';

  public getActiveUsersByPeriod(params: any): Observable<any> {
    return this.http.get(`${this.getUrl()}/get_active_users_by_period`, {
      // ```responseType: json``` allow set Response Type
      responseType: 'json',
      ...params,
    });
  }
}
