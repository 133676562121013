import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewSetHttpService } from './rest/ViewSetHttpService';
import { Resolution } from '@models/reviews/resolution';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResolutionHttpService extends ViewSetHttpService<Resolution> {
  http: HttpClient;
  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'reviews/resolution';

  public close(uuid: string): Observable<any> {
    return this.http.post(`${this.getUrl()}/${uuid}/close`, {});
  }
}
