import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root',
})
export class PdpStepService {
  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService
  ) {}

  public create(step_data: any): Observable<any> {
    const params = {};
    return this.api_service.post(`track/step`, step_data, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} created pdp activity step with uuid ${response.uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to create pdp activity step`
          );
        }
      )
    );
  }

  public update(step_uuid: string, step_data: any): Observable<any> {
    const params = {};
    return this.api_service
      .patch(`track/step/${step_uuid}`, step_data, params)
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} updated pdp activity step with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to update pdp activity step`
            );
          }
        )
      );
  }

  public delete(step_uuid: string): Observable<any> {
    return this.api_service.delete(`track/step/${step_uuid}`, {}).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} deleted pdp activity step with uuid ${step_uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to delete pdp activity step`
          );
        }
      )
    );
  }

  public fetch_by_pdp_uuid(pdp_uuid: string): Observable<any> {
    const params = { pdp: pdp_uuid };
    return this.api_service.get(`track/step`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} pdp activity steps`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch pdp activity steps`
          );
        }
      )
    );
  }
}
