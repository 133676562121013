import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { ThemePalette } from '@angular/material/core';

import { LoggingService } from 'src/app/services/logging.service';
import { SurveyQuestionService } from 'src/app/services/survey/survey-question.service';
import { SurveyService } from 'src/app/services/survey/survey.service';
import { TitleService } from 'src/app/services/title.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { UserService } from 'src/app/services/user/user.service';
import { CompetenceService } from 'src/app/services/competencies/competence.service';

import { User } from 'src/app/models/user/user';
import { Survey } from 'src/app/models/survey/survey';
import { SurveyQuestion } from 'src/app/models/survey/survey-question';
import { Competence } from 'src/app/models/competencies/competence';


@Component({
  selector: 'app-survey-tree-result',
  templateUrl: './survey-tree-result.component.html',
  styleUrls: ['./survey-tree-result.component.css']
})
export class SurveyTreeResultComponent implements OnInit {


  private user_uuid: string;
  public user: User;

  private survey_uuid: string;
  public survey: Survey;
  public survey_questions: SurveyQuestion[] = [];

  public competencies: Competence[] = [];

  public visual_control_subject: BehaviorSubject<any>;

  public visual_controls_show_noskill = false;
  public visual_controls_show_groupnames = false;

  public ui_checkbox_color: ThemePalette = 'warn';

  private uistate = {
    user_loaded: false,
    survey_loaded: false,
    survey_questions_loaded: false,
    competencies_loaded: false,
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logging_service: LoggingService,
    private title_service: TitleService,
    private alert_service: AlertService,
    private user_service: UserService,
    private survey_service: SurveyService,
    private survey_question_service: SurveyQuestionService,
    private competence_service: CompetenceService,
  ) { }

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.title_service.set_title('Результаты опроса');
    this.survey_uuid = this.route.snapshot.paramMap.get('survey_uuid');
    this.user_uuid = this.route.snapshot.paramMap.get('user_uuid');

    const init_control_command = {command: 'init_control_command'};
    this.visual_control_subject  = new BehaviorSubject<any>(init_control_command);

    this.load_user();
  }

  public get is_data_loaded(): boolean {
    return this.uistate.user_loaded &&
      this.uistate.survey_loaded &&
      this.uistate.survey_questions_loaded &&
      this.uistate.competencies_loaded;
  }

  private load_user(): void {
    this.user_service.fetch_user_by_uuid(this.user_uuid).subscribe(
      response => {
        this.user = response as User;
        this.logging_service.debug(`${this.constructor.name} fetched user ${this.user.uuid}`);
        this.uistate.user_loaded = true;
        this.load_survey();
        this.load_competencies();
      },
      err => {
        if (err.status === 404) {
          this.router.navigate(['/400']);
          return;
        }
        this.router.navigate(['/500']);
      }
    );
  }

  private load_survey(): void {
    this.survey_service.fetch_by_uuid(this.survey_uuid).subscribe(
      response => {
        this.survey = response as Survey;
        this.logging_service.debug(`${this.constructor.name} fetched survey ${this.survey.uuid}`);
        this.title_service.set_title(`${this.survey.name}`);
        this.uistate.survey_loaded = true;
        this.load_survey_questions();
      },
      err => {
        if (err.status === 404) {
          this.router.navigate(['/400']);
          return;
        }
        this.router.navigate(['/500']);
      }
    );
  }

  private load_survey_questions(): void {
    this.survey_question_service.fetch_all_for_survey_uuid(this.survey.uuid).subscribe(
      response => {
        this.survey_questions = response.results as SurveyQuestion[];
        this.uistate.survey_questions_loaded = true;
        this.logging_service.debug(`${this.constructor.name} loaded survey ${this.survey_questions.length} questions`);
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to load survey questions`);
        this.alert_service.error(`Ошибка загрузки вопросов ${err.status}`);
      }
    );
  }

  private load_competencies(): void {
    this.competence_service.fetch_by_user_uuid(this.user.uuid).subscribe(
      response => {
        this.competencies = response.results as Competence[];
        this.uistate.competencies_loaded = true;
        this.logging_service.debug(`${this.constructor.name} loaded ${this.competencies.length} user competencies`);
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to load user competencies`);
        this.alert_service.error(`Ошибка загрузки компетенций ${err.status}`);
      }
    );
  }

  public get svg_config(): any {
    return  {
      width: 1000,
      height: 1400,
      margin: {
        top: 10,
        right: 70,
        bottom: 10,
        left: 50
      },
      tree: {
        node_horizontal_padding: 130,
        show_node_text: true,
        show_competencies: true,
      }
    };
  }

  public on_toggle_visual_controls_show_noskill($event: any): void {
    this.logging_service.debug(`${this.constructor.name} show_noskill ${!this.visual_controls_show_noskill}`);
    $event.preventDefault();
    this.visual_controls_show_noskill = !this.visual_controls_show_noskill;
    this.visual_control_subject.next({command: 'result_toggle_show_noskill'})
  }

  public on_toggle_visual_controls_show_groupnames($event: any): void {
    this.logging_service.debug(`${this.constructor.name} show_groupnames ${!this.visual_controls_show_groupnames}`);
    $event.preventDefault();
    this.visual_controls_show_groupnames = !this.visual_controls_show_groupnames;
    this.visual_control_subject.next({command: 'result_toggle_show_groupnames'})
  }

}
