<div class='app-admin-teams-dashboard-team-list-container'>
  <div *ngFor='let team of teams.state.items'>
    <ng-container
      [ngTemplateOutlet]='
        team.uuid == get_team_edited_in_progress()
          ? list_item_edited
          : list_item
      '
      [ngTemplateOutletContext]='{ team: team }'
    >
    </ng-container>
  </div>
</div>

<ng-template #list_item let-team='team'>
  <div class='app-admin-teams-dashboard-team-listitem-container'>
    <div fxLayout='row'>
      <div fxFlex='35rem' (click)='onNavigateTeam(team)'>
        <div class='app-admin-teams-dashboard-listitem-name'>
          {{ team.name }}
        </div>
        <div class='app-admin-teams-dashboard-team-listitem-parent'>
          <div *ngIf='!team.parent'>Команда верхнего уровня иерархии</div>
          <div *ngIf='team.parent'>
            Родительская команда: {{ team.parent.name }}
          </div>
        </div>
      </div>
      <div fxFlex fxLayoutAlign='end center'>
        <div class='app-admin-teams-dashboard-listitem-buttons-container'>
          <button
            mat-icon-button
            aria-label='Edit team name'
            (click)='onEditButtonClick(team)'
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            aria-label='Delete team'
            (click)='onDeleteButtonClick($event, team)'
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #list_item_edited let-team='team'>
  <div
    class='app-admin-teams-dashboard-team-listitem-container edit-template'
    fxLayout='row'
  >
    <div
      fxFlex='50'
      fxLayout='column'
      fxLayoutAlign='center'
      class='app-admin-teams-dashboard-team-listitem-field-container'
    >
      <input
        class='app-admin-teams-dashboard-team-listitem-edit-field'
        #new_team_name
        autofocus
        matInput
        placeholder='Название группы*'
        required
        autocomplete='off'
        value='{{ team.name }}'
        maxlength='128'
        (keyup.enter)='saveNewTeamName(team, new_team_name.value)'
        (keyup.escape)='resetEditTeam()'
      />
      <div
        class='app-admin-teams-dashboard-team-listitem-edit-field-dash'
        [class.warn]="new_team_name.value === ''"
      ></div>
    </div>
    <span
      class='app-admin-teams-dashboard-team-listitem-edit-field-length'
      fxFlex='25'
      [class.warn]="new_team_name.value === ''"
    >{{ 128 - new_team_name.value.length }}/255</span
    >
    <div
      class='app-admin-teams-dashboard-listitem-buttons-container'
      fxFlex='25'
      fxLayout='row-reverse'
      fxLayoutAlign='space-around center'
    >
      <a mat-raised-button (click)='resetEditTeam()'> Отмена </a>
      <a
        mat-raised-button
        (click)='saveNewTeamName(team, new_team_name.value)'
        [disabled]="new_team_name.value === ''"
      >
        Сохранить
      </a>
    </div>
  </div>
</ng-template>
