<app-with-processing [processing]='isProcessing()'>
  <div fxFlex='row' fxFill='space-around' *ngIf='!isProcessing()'>
    <div fxFlex class='thing-main'>
      <div class='thing-main-content'>
        <div *ngIf='thing.state?.item?.domain'>
          {{ thing.state?.item?.domain.name }}
        </div>
        <div fxLayout='row' class='title'>
          <div fxFlex='90' fxLayoutAlign='start center' fxLayoutGap='2rem'>
            <button (click)='onBackClick()' mat-icon-button>
              <mat-icon>arrow_back</mat-icon>
            </button>
            <div fxLayoutAlign='start center' fxLayout='row'>
              {{ thing.state.item?.name }}
            </div>
          </div>
          <div fxLayout='row' fxLayoutAlign='center center' fxLayoutGap='8px'>
            <button
              mat-icon-button
              class='thing-action'
              fxLayoutAlign='center center'
              routerLink='edit'
              *ngIf='isUserAdmin'
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              class='thing-action'
              fxLayoutAlign='center center'
              [ngClass]='{ activated: competenciesDesire.state.items.length }'
              (click)='toggleDesire()'
            >
              <mat-icon>favorite</mat-icon>
            </button>
            <button
              mat-icon-button
              class='thing-action'
              fxLayoutAlign='center center'
              routerLink='duplicates'
              [matTooltip]='"Перейти к объединению дублей"'
            >
              <mat-icon>compare_arrows</mat-icon>
            </button>
          </div>
        </div>
        <span class='thing-main-content-description'>
          {{ thing.state.item?.description }}
        </span>

        <div class='thing-main-content-graphs'>
          <div
            *ngIf='
              competencies.state.isLoaded &&
              thingLevels.state.isLoaded &&
              thing.state.isLoaded &&
              competenciesDesire.state.isLoaded
            '
          >
            <div fxLayout='row'>
              <div fxLayout='column'>
                <div class='level-info-block'>
                  <div class='level-info-count'>
                    {{ competencies.state.items.length - lowestLevelCount }}
                  </div>
                  <div class='level-info-text'>Могут владеть</div>
                </div>
                <div class='level-info-block'>
                  <div class='level-info-count'>
                    {{ lowestLevelCount }}
                  </div>
                  <div class='level-info-text'>Не владеют</div>
                </div>
              </div>
              <div class='levels-info-grid'>
                <div *ngFor='let levelInfo of thingLevelsInfo'>
                  <div class='level-info-block'>
                    <div fxLayout='row'>
                      <div fxFlex class='level-info-count'>
                        {{ levelInfo.count }}
                      </div>
                      <div fxFlex fxLayoutAlign='end center'>
                        <div
                          class='level-info-color'
                          [style.background]='levelInfo.color'
                        ></div>
                      </div>
                    </div>
                    <div class='level-info-text'>{{ levelInfo.text }}</div>
                  </div>
                </div>
              </div>
              <div *ngIf='histogramReady && !isHistogramEmpty'>
                <app-histogram-simple
                  [chartName]="'thing-competence-level'"
                  [axisNameX]="'Уровни владения'"
                  [axisNameY]="'Пользователи'"
                  [data]='histogramData'
                  [horizontal]='true'
                  [sizes]='{ width: 300 }'
                >
                </app-histogram-simple>
              </div>
            </div>

            <form [formGroup]='form'>
              <mat-form-field fxFlex appearance='outline'>
                <input
                  matInput
                  required
                  placeholder='Поиск'
                  type='text'
                  formControlName='user'
                  (keyup)='tabDataFilter()'
                />
              </mat-form-field>
            </form>

            <mat-tab-group
              animationDuration='0ms'
              mat-stretch-tabs='false'
              (selectedIndexChange)='setActiveTabIndex($event)'
            >
              <mat-tab label='Все'>
                <ng-container
                  [ngTemplateOutlet]='userList'
                  [ngTemplateOutletContext]='{
                    filteredCompetencies: filteredByUserCompetencies,
                    withLevel: true
                  }'
                >
                </ng-container>
              </mat-tab>
              <mat-tab
                *ngFor='let level of thingLevels.state.items'
                [label]='level.title'
                fxLayoutGap='8px'
              >
                <ng-container
                  [ngTemplateOutlet]='userList'
                  [ngTemplateOutletContext]='{
                    filteredCompetencies: getFilteredCompetencies(level),
                    withLevel: false
                  }'
                >
                </ng-container>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-with-processing>

<ng-template
  #userList
  let-filteredCompetencies='filteredCompetencies'
  let-withLevel='withLevel'
>
  <div [style.margin-top.px]='10'></div>
  <div *ngIf='!filteredCompetencies?.length' fxLayoutAlign='center center'>
    Не найдено пользователей с этим уровнем
  </div>
  <div *ngIf='filteredCompetencies?.length'>
    <div
      *ngFor='let competence of filteredCompetencies'
      class='user-container'
      fxLayout='row'
      fxLayoutAlign='start center'
      (click)='navigateToUser(competence.user)'
    >
      <mat-icon fxFlex='3rem'>account_circle</mat-icon>
      <div class='user-name'>
        {{ competence.user.first_name }}
        {{ competence.user.last_name }}
      </div>
      <div
        fxLayout='row'
        fxLayoutGap='2px'
      >
        <div *ngFor='let membership of competence.user.team_memberships'>
          <div
            *ngIf='membership.team.icon_url'
            fxFlex='3rem'
            [style.cursor]="'pointer'"
            matTooltip='{{ membership.team.name }}'
          >
            <img [src]='membership.team.icon_url' [style.height.px]='30' alt='' />
          </div>
        </div>
      </div>
      <div *ngIf='withLevel' fxFlex fxLayoutAlign='end center'>
        <div *ngIf='competence.is_verified' fxFlex='3rem'>
          <mat-icon class='red-icon'>done_outline</mat-icon>
        </div>
        <div fxFlex='8rem' class='user-level'>
          {{ getLevelTitle(competence) }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
