import { Component, OnInit } from '@angular/core';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Thing } from '@models/ontology/thing';
import { ThingHttpService } from '@services/http/ThingHttpService';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AsyncList } from '@rest/AsyncList';
import { ThingLevel } from '@models/ontology/thing-level';
import { ThingLevelHttpService } from '@services/http/ThingLevelHttpService';
import { MatDialog } from '@angular/material/dialog';
import { AcceptDialogComponent } from '../../../common-ui-elements/components/accept-dialog/accept-dialog.component';
import { Domain } from '@models/ontology/domain';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-thing-edit',
  templateUrl: './thing-edit.component.html',
  styleUrls: ['./thing-edit.component.scss'],
})
export class ThingEditComponent implements OnInit {
  thing: AsyncDetail<Thing>;
  things: AsyncList<Thing>;
  domains: AsyncList<Domain>;
  thingLevels: AsyncList<ThingLevel>;
  form: UntypedFormGroup;

  constructor(
    private _thingHttpService: ThingHttpService,
    private _domainHttpService: DomainHttpService,
    private _thingLevelHttpService: ThingLevelHttpService,
    private _router: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private _dialog: MatDialog
  ) {
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      description: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
      domain: ['', []],
    });
  }

  ngOnInit(): void {
    this.thingLevels = new AsyncList<ThingLevel>(this._thingLevelHttpService);
    this.thingLevels.requestParams = {
      params: {
        thing: this._router.snapshot.paramMap.get('uuid'),
      },
    };

    this.thing = new AsyncDetail<Thing>(
      this._router.snapshot.paramMap.get('uuid'),
      this._thingHttpService
    );

    this.domains = new AsyncList<Domain>(this._domainHttpService);

    combineLatest([
      this.thingLevels.load(),
      this.thing.load(),
      this.domains.load(),
    ]).subscribe(() => {
      this._setFormValues();
    });
  }

  private _setFormValues(): void {
    this.form.controls.name.setValue(this.thing.state.item.name);
    this.form.controls.description.setValue(this.thing.state.item.description);
    this.form.controls.domain.setValue(this.getThingDomain());
  }

  getThingDomain(): Domain {
    return this.domains.state.items.find(
      (domain) => domain.uuid === this.thing.state.item.domain
    );
  }

  getDomainUuid(): string {
    return this.thing.state.item.domain as string;
  }

  save(): void {
    this.thing.state.item.domain = null;
    this.thing.state.item.team = null;
    this.thing
      .update({
        ...this.form.value,
        domain: this.form.value.domain.uuid,
      })
      .subscribe((response: Thing) => {
        this.thing.state.item = response;
        this.thing.state.item.domain = (response.domain as Domain).uuid;
        this.thing.state.item.team = response.team?.uuid;
        this._setFormValues();
      });
  }

  remove(): void {
    this.things = new AsyncList(this._thingHttpService);
    this.things.remove(this.thing.uuid).subscribe(() => {
      this.router.navigate([
        `/library/domains/${this.thing.state.item.domain}`,
      ]);
    });
  }

  openRemoveThingDialog(): void {
    this._dialog
      .open(AcceptDialogComponent, {
        data: {
          title: `Удалить компетенцию?`,
          message: 'Удаление может привести к потере данных пользователей',
          acceptLabel: 'Удалить',
          rejectLabel: 'Отмена',
        },
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) {
          this.remove();
        }
      });
  }
}
