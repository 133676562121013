import { Component, OnInit } from '@angular/core';
import { ServiceSettingsHttpService } from '@services/http/ServiceSettihgsHttpService';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-send-email-form',
  templateUrl: './send-email-form.component.html',
  styleUrls: ['./send-email-form.component.css'],
})
export class SendEmailFormComponent {
  form: UntypedFormGroup;
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _serviceSettingsHttpService: ServiceSettingsHttpService,
    private _alertService: AlertService,
    public dialogRef: MatDialogRef<SendEmailFormComponent>
  ) {
    this.form = this._formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
          Validators.email,
        ],
      ],
    });
  }

  onSubmitForm(): void {
    this._sendTestEmail(this.form.value.email);
  }

  private _sendTestEmail(email: string): void {
    this._serviceSettingsHttpService.sendTestEmail(email).subscribe(
      () => {
        this._alertService.success('Письмо успешно отправлено!');
        this.dialogRef.close();
      },
      () => {
        this._alertService.error('Не удалось отправить письмо');
      }
    );
  }
}
