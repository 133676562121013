import {Component, OnInit} from '@angular/core';
import {SurveyAnswerOption} from 'src/app/models/survey/survey-answer-option';
import {SurveyAnswerOptionService} from 'src/app/services/survey/survey-answer-option.service';
import {AlertService} from 'src/app/services/ui/ui-alert.service';
import {LoggingService} from 'src/app/services/logging.service';
import {ActivatedRoute} from '@angular/router';
import {TitleService} from 'src/app/services/title.service';
import {SurveyQuestionService} from 'src/app/services/survey/survey-question.service';
import {SurveyQuestion} from 'src/app/models/survey/survey-question';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-survey-edit-answer',
  templateUrl: './survey-edit-answer.component.html',
  styleUrls: ['./survey-edit-answer.component.css']
})
export class SurveyEditAnswerComponent implements OnInit {

  public current_question: SurveyQuestion;
  public current_question_answer_options: SurveyAnswerOption[];
  private question_uuid: string;
  public survey_uuid: string;

  public question_edit_form: UntypedFormGroup;
  public answer_edit_form: UntypedFormGroup;

  private uistate = {
    answer_loaded: false,
    head_is_editing: false,
    question_data_loaded: false,
    editing_answer_uuid: '',
    thing_notification: '22222'
  };

  constructor(
    private form_builder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private alert_service: AlertService,
    private logging_service: LoggingService,
    private title_service: TitleService,
    private answer_options_service: SurveyAnswerOptionService,
    private survey_question_service: SurveyQuestionService,
  ) {
  }

  public get thing_notification(): string {
    return this.uistate.thing_notification;
  }

  public get head_is_editing(): boolean {
    return this.uistate.head_is_editing;
  }

  public get editing_answer(): string {
    return this.uistate.editing_answer_uuid;
  }

  public edit_head() {
    this.uistate.head_is_editing = true;
    this.question_edit_form.setValue({
      question_title: this.current_question.title,
      question_description: this.current_question.description
    });
  }

  public get question_data_loaded(): boolean {
    return this.uistate.question_data_loaded;
  }

  public cancel_edit_question() {
    this.uistate.head_is_editing = false;
  }

  public cancel_edit_answer() {
    this.uistate.editing_answer_uuid = '';
  }

  ngOnInit(): void {
    this.title_service.set_title('Опрос');
    this.question_uuid = this.route.snapshot.paramMap.get('question_uuid');
    this.survey_uuid = this.route.snapshot.paramMap.get('survey_uuid');
    this.load_question();
    this.load_answer();
    this.create_question_edit_form();
    this.create_answer_edit_form();
  }

  private create_question_edit_form() {
    this.question_edit_form = this.form_builder.group({
      question_title: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(128)
      ]],
      question_description: ['', [
        Validators.maxLength(1024)
      ]]
    });
  }

  private create_answer_edit_form() {
    this.answer_edit_form = this.form_builder.group({
      answer_description: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1024)
      ]]
    });
  }

  public get is_data_loaded(): boolean {
    return this.uistate.answer_loaded && this.uistate.question_data_loaded;
  }

  private load_question() {
    this.survey_question_service.fetch_by_uuid(this.question_uuid).subscribe(
      response => {
        this.current_question = response as SurveyQuestion;
        this.logging_service.debug(`${this.constructor.name} loaded ${this.current_question.uuid} questions`);
        this.uistate.question_data_loaded = true;

        if (this.current_question.question_type === 'dummy_node') {
          this.uistate.thing_notification = 'Это характеризует подгруппу вопросов';
        } else {
          this.uistate.thing_notification = 'Этот вопрос оценивает владение навыком - "' +
            ((this.current_question.related_thing instanceof Object) ?
              this.current_question.related_thing.name : this.current_question.title) + '"';
        }
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to load question`);
        this.alert_service.error(`Ошибка загрузки вопроса ${err.status}`);
      }
    );
  }

  public load_answer() {
    this.answer_options_service.fetch_all_for_question_uuid(this.question_uuid).subscribe(
      response => {
        this.current_question_answer_options = response.results as SurveyAnswerOption[];
        this.logging_service.debug(`${this.constructor.name} successfully loaded answer options`);
        this.uistate.answer_loaded = true;
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to load answer options`);
        this.alert_service.error(`Ошибка загрузки вариантов ответа ${err.status}`);
        this.uistate.answer_loaded = false;
      }
    );
  }

  public edit_answer(answer: SurveyAnswerOption) {
    this.answer_edit_form.setValue({
      answer_description: answer.description
    });
    this.uistate.editing_answer_uuid = answer.uuid;
  }

  public save_question_data() {
    const question_new_data = {
      title: this.question_edit_form.value.question_title,
      description: this.question_edit_form.value.question_description
    };
    this.survey_question_service.update(this.current_question.uuid, question_new_data).subscribe(
      response => {
        this.logging_service.debug(`${this.constructor.name} update question ${this.current_question.uuid}`);
        this.current_question.title = question_new_data.title;
        this.current_question.description = question_new_data.description;
        this.uistate.head_is_editing = false;
        this.alert_service.info(`Сохранено`);
        this.question_edit_form.setValue({
          question_title: '',
          question_description: ''
        });
      },
      err => {
        this.uistate.head_is_editing = false;
        this.logging_service.debug(`${this.constructor.name} failed to save question`);
        this.alert_service.error(`Ошибка сохранения ${err.status}`);
      }
    );
  }

  public save_answer_new_data() {
    if (this.uistate.editing_answer_uuid === '') {
      return;
    }
    this.answer_options_service.update(this.uistate.editing_answer_uuid,
      {description: this.answer_edit_form.value.answer_description}).subscribe(
      response => {
        this.logging_service.debug(`${this.constructor.name} successfully save answer data`);
        this.uistate.editing_answer_uuid = '';
        this.current_question_answer_options.find(t => t.uuid === response.uuid)
          .description = this.answer_edit_form.value.answer_description;
        this.answer_edit_form.setValue({
          answer_description: ''
        });
        this.alert_service.info(`Сохранено`);
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to update answer options`);
        this.alert_service.error(`Ошибка сохранения ответа ${err.status}`);
        this.uistate.editing_answer_uuid = '';
        this.answer_edit_form.setValue({
          answer_description: ''
        });
      }
    );
  }
}
