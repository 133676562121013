<div class='resolution-container' fxLayout='column' *ngIf='review'>
  <div
    fxLayout='row'
    fxLayoutAlign='start center'
    fxLayoutGap='2rem'
    class='review-title'
  >
    <button (click)='onBackClick()' mat-icon-button>
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span>{{ review.name }}</span>
  </div>
  <app-static-textfield-group
    [fields]='resolutionInfoData'
  ></app-static-textfield-group>
  <mat-divider class='divider'></mat-divider>
  <app-with-processing
    [processing]='
      assessmentResponses?.state.isProcessing &&
      resolutionResponses?.state.isProcessing
    '
  >
    <span>{{
      'Завершено на ' + resolution.state.item.completion_percentage + '%'
      }}</span>
    <span (click)='onWhatThisMetricsDoes()'
          *ngIf='resolution.state.item.completion_percentage == 100'
          class='legend-desc-text'>Что означают Вы и Финальная?
        </span>
    <mat-progress-bar
      class='resolution-progress-bar'
      mode='determinate'
      [value]='resolution.state.item.completion_percentage'
    ></mat-progress-bar>
    <a
      *ngIf='resolution.state.item.completion_percentage == 100'
      class='dashboard-button'
      mat-raised-button
      (click)='downloadReviewExcel()'
    >
      <mat-icon>cloud_download</mat-icon>
      <p class='excel-button'>Результаты в excel</p>
    </a>
    <br><br>
    <app-domain-thing-tree
      *ngIf='
        assessmentResponses?.state.isLoaded &&
        resolutionResponses?.state.isLoaded
      '
      [domains]='domains.state.items'
      [things]='things'
      [reviewMode]='reviewMode'
      [assessmentResponseThingMap]='assessmentResponseThingMap'
      [resolutionResponseThingMap]='resolutionResponseThingMap'
      (resolutionChange)='onResolutionChange()'
    ></app-domain-thing-tree>
    <div
      fxLayout='row'
      fxLayoutAlign='end center'
      class='resolution-buttons-container'
      *ngIf='resolution.state.item.completion_percentage !== 100'
    >
      <button
        mat-raised-button
        class='close-button'
        [disabled]='isCloseResolutionProcessing || !speciality.state.item'
        (click)='closeResolution()'
      >
        Завершить ревью
      </button>
    </div>
  </app-with-processing>
</div>
