import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Domain } from '@models/ontology/domain';

import { ViewSetHttpService } from './rest/ViewSetHttpService';

@Injectable({
  providedIn: 'root',
})
export class DomainHttpService extends ViewSetHttpService<Domain> {
  http: HttpClient;
  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'ontology/domain';

  public getDownloadStructureLink({
    isDownloadUsersCount,
    isDownloadThingLevels,
    isDownLoadBySpecialities,
  }: {
    isDownloadUsersCount: boolean;
    isDownloadThingLevels: boolean;
    isDownLoadBySpecialities: boolean;
  }): string {
    const searchParamsData = {};

    if (isDownloadThingLevels) {
      searchParamsData['is_download_thing_levels'] = true;
    }

    if (isDownloadUsersCount) {
      searchParamsData['is_download_users_count'] = true;
    }

    if (isDownLoadBySpecialities) {
      searchParamsData['is_download_by_specialities'] = true;
    }

    const searchParams = new URLSearchParams(searchParamsData);
    return `${this.baseUrl}ontology/structure?${searchParams}`;
  }
}
