<form [formGroup]="form">
  <mat-card>
    <div class="form-group">
      <div fxLayout="row" fxLayoutGap="5rem">
        <div fxFlex="50%">
          <h3>Новая специальность</h3>
          <mat-form-field [style.width.rem]="30">
            <input
              matInput
              placeholder="Название"
              type="text"
              formControlName="name"
              required
            />
          </mat-form-field>
          <mat-form-field [style.width.rem]="30">
            <input
              matInput
              placeholder="Описание"
              type="text"
              formControlName="description"
              required
            />
          </mat-form-field>
        </div>
        <div fxFlex="50%">
          <mat-icon>help</mat-icon>
          <p>
            Специальности описывают комплекс компетенций, навыков и опыта,
            необходимых для выполнения задач.
          </p>
          <p>
            Например, это может быть Разработчик, Архитектор, Дизайнер или
            Менеджер проектов.
          </p>
        </div>
      </div>

      <button mat-raised-button [disabled]="form.invalid" (click)="create()">
        Создать
      </button>
    </div>
  </mat-card>
</form>
