<div class="domain-detail-container">
  <!-- <div class="bread-crumbs">
    <div class="bread-crumbs-item">
      <a class="bread-crumbs-item-link" routerLink="/library/domains/root">
        Все группы компетенций
      </a>
      <mat-icon *ngIf="breadCrumbs.length > 0">keyboard_arrow_right</mat-icon>
    </div>

    <div class="bread-crumbs-item" *ngFor="let breadCrumb of breadCrumbs">
      <a class="bread-crumbs-item-link" [routerLink]="breadCrumb.linkUrl">{{
        breadCrumb.name
      }}</a>
      <mat-icon
        *ngIf="breadCrumbs.indexOf(breadCrumb) + 1 !== breadCrumbs.length"
        >keyboard_arrow_right</mat-icon
      >
    </div>
  </div> -->

  <div fxLayout="row" fxLayoutAlign="space-between">
    <div fxFlex>
      <app-with-processing
        [processing]="domains?.state.isProcessing || things?.state.isProcessing"
      >
        <app-domain-detail-tree
          *ngIf="domains?.state.isLoaded && things?.state.isLoaded"
          [dataSource]="dataSource"
          [treeControl]="treeControl"
          [hasChild]="hasChild"
          [expandDomain]="expandDomain"
          [setDetailFormConfig]="onDialogShow"
          [remove]="remove"
          [isCreateHighLevelDomainButtonShow]="isUserAdmin && uuid === 'root'"
          [isEditable]="isUserAdmin"
        ></app-domain-detail-tree>
      </app-with-processing>
    </div>
  </div>
</div>
