<div class='dialog-container'>
  <app-with-processing
    *ngIf='teamMemberStatus.state.isProcessing'
  ></app-with-processing>
  <div *ngIf='teamMemberStatus.state.isLoaded'>
    <div class='dialog-title'>
      Редактирование статуса участника
      {{ data.user.last_name + ' ' + data.user.first_name }}
    </div>
    <div>
      <div class='status-content-container'>
        <div fxLayout='row' fxLayoutAlign='start center'>
          <mat-checkbox
            fxFlex='5'
            (change)='isKeyPeople = !isKeyPeople'
            [checked]='isKeyPeople'
          ></mat-checkbox>
          <span fxFlex='90' class='status-name'>Key people</span>
        </div>
        <div fxLayout='row'>
          <div fxFlex='5'></div>
          <div fxFlex class='status-reason-text' [matTooltip]='keyPeopleReason ? keyPeopleReason : ""'>
            <span>{{
              keyPeopleReason ? keyPeopleReason : 'Статус пользователя, который соответствует своей специальности на 95% и больше'
              }}</span>
          </div>
        </div>
      </div>
      <div class='status-content-container'>
        <div fxLayout='row' fxLayoutAlign='start center'>
          <mat-checkbox
            fxFlex='5'
            (change)='isBusFactor = !isBusFactor'
            [checked]='isBusFactor'
          ></mat-checkbox>
          <span fxFlex='90' class='status-name'>Bus factor</span>
        </div>
        <div fxLayout='row'>
          <div fxFlex='5'></div>
          <div fxFlex class='status-reason-text' [matTooltip]='busFactorReason ? busFactorReason : ""'>
            <span>{{
              busFactorReason ? busFactorReason : 'Статус пользователя, владеющим уникальными компетенциями в команде и специальности'
              }}</span>
          </div>
        </div>
      </div>
    </div>
    <div mat-dialog-actions fxLayoutAlign='end center'>
      <button
        class='dialog-reject-button'
        [mat-dialog-close]='true'
        mat-raised-button
      >
        Отменить
      </button>
      <button class='dialog-accept-button' mat-raised-button (click)='submit()'>
        Сохранить
      </button>
    </div>
  </div>
</div>
