import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-review-progress',
  templateUrl: './review-progress.component.html',
  styleUrls: ['./review-progress.component.css'],
})
export class ReviewProgressComponent {
  @Input() percents = 0;
  constructor() {}
}
