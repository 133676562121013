import { Component, Input, OnInit } from '@angular/core';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Speciality } from '@models/specialities/speciality';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { MatDialog } from '@angular/material/dialog';
import { AsyncList } from '@rest/AsyncList';
import { SpecialityCompetenceClaim } from '@models/specialities/speciality-competence-claim';
import { AddCompetenceFromLibraryDialogComponent } from '../../../teams/components/team-main/team-specialities/team-speciality-detail/add-competence-from-library-dialog/add-competence-from-library-dialog.component';
import { Domain } from '@models/ontology/domain';
import { Thing } from '@models/ontology/thing';
import { ThingGradeLevelsMap } from '../../../teams/components/team-main/team-specialities/team-speciality-detail/team-speciality-detail.component';
import { SpecialityCompetenceClaimHttpService } from '@services/http/SpecialityCompetenceClaimHttpService';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { ThingHttpService } from '@services/http/ThingHttpService';
import { SpecialityDomainClaim } from '@models/specialities/speciality-domain-claim';
import { SpecialityDomainClaimHttpService } from '@services/http/SpecialityDomainClaimHttpService';
import { SpecialityGradeHttpService } from '@services/http/SpecialityGradeHttpService';
import { SpecialityGrade } from '@models/specialities/speciality-grade';

@Component({
  selector: 'app-speciality-edit',
  templateUrl: './speciality-edit.component.html',
  styleUrls: ['./speciality-edit.component.css'],
})
export class SpecialityEditComponent implements OnInit {
  @Input() speciality: AsyncDetail<Speciality>;
  form: UntypedFormGroup;

  specialityCompetenceClaims: AsyncList<SpecialityCompetenceClaim>;
  specialityDomainClaims: AsyncList<SpecialityDomainClaim>;
  specialityGrades: AsyncList<SpecialityGrade>;

  things: Thing[];
  thingGradeLevelsMap: ThingGradeLevelsMap;
  thingGradeLevelsMapReady = false;

  editGradeForm: UntypedFormGroup;
  editingGrade = '';

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private _alertService: AlertService,
    private _dialog: MatDialog,
    private _specialityCompetenceClaimHttpService: SpecialityCompetenceClaimHttpService,
    private _specialityDomainClaimHttpService: SpecialityDomainClaimHttpService,
    private _specialityGradeHttpService: SpecialityGradeHttpService,
    private _thingHttpService: ThingHttpService
  ) {}

  ngOnInit(): void {
    this._loadSpecialityGrades();
    this._loadDomainClaims();
    this._loadSpecialityClaims();
    this._initForm();
  }

  private _initForm() {
    this.form = this._formBuilder.group({
      name: [
        this.speciality.state.item.name,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      description: [
        this.speciality.state.item.description,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
    });
  }

  private _loadSpecialityGrades(): void {
    this.specialityGrades = new AsyncList<SpecialityGrade>(
      this._specialityGradeHttpService
    );
    this.specialityGrades.setRequestParams({
      params: {
        speciality: this.speciality.state.item.uuid,
      },
    });
    this.specialityGrades.load().subscribe();
  }

  private _loadDomainClaims(): void {
    this.specialityDomainClaims = new AsyncList<SpecialityDomainClaim>(
      this._specialityDomainClaimHttpService
    );
    this.specialityDomainClaims.setRequestParams({
      params: {
        speciality: this.speciality.state.item.uuid,
        expand: 'domain',
      },
    });
    this.specialityDomainClaims.load().subscribe(() => {});
  }

  private _loadSpecialityClaims(): void {
    this.things = [];
    this.thingGradeLevelsMap = {};
    this.specialityCompetenceClaims = new AsyncList<SpecialityCompetenceClaim>(
      this._specialityCompetenceClaimHttpService
    );
    this.specialityCompetenceClaims.setRequestParams({
      params: {
        speciality: this.speciality.state.item.uuid,
        expand: 'thing.levels,grade',
      },
    });
    this.specialityCompetenceClaims.load().subscribe(() => {
      this.specialityCompetenceClaims.state.items.forEach(
        (competenceClaim: SpecialityCompetenceClaim<string>) => {
          if (
            !this.things.find((thing) => {
              return thing.uuid === competenceClaim.thing.uuid;
            })
          ) {
            this.things.push(competenceClaim.thing);
            this.thingGradeLevelsMap[competenceClaim.thing.uuid] = {
              isKey: competenceClaim.is_key,
              thingGradeLevels: [
                {
                  grade: competenceClaim.grade,
                  thingLevel: competenceClaim.thing_level,
                  levels: competenceClaim.thing.levels,
                },
              ],
            };
          } else {
            this.thingGradeLevelsMap[competenceClaim.thing.uuid][
              'thingGradeLevels'
            ].push({
              grade: competenceClaim.grade,
              thingLevel: competenceClaim.thing_level,
              levels: competenceClaim.thing.levels,
            });
          }
        }
      );
      this.thingGradeLevelsMapReady = true;
    });
  }

  onCompetenceFromLibraryAdd(): void {
    this._dialog.open(AddCompetenceFromLibraryDialogComponent, {
      data: {
        onSubmit: (claims: string[]) => {
          const claimsData = [];
          claims.forEach((claim) => {
            claimsData.push({
              speciality: this.speciality.state.item.uuid,
              thing: claim,
            });
          });
          this._specialityCompetenceClaimHttpService
            .addClaimsWithGrades(claimsData)
            .subscribe((response) => {
              this._loadDomainClaims();
              this._loadSpecialityClaims();
              this._dialog.closeAll();
            });
        },
      },
    });
  }

  onSpecialityGradeEdit(grade: SpecialityGrade): void {
    this.editingGrade = grade.uuid;
    this.editGradeForm = this._formBuilder.group({
      name: [
        grade.name,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      description: [
        grade.description,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
    });
  }

  onSaveGradeChanges(): void {
    this._specialityGradeHttpService
      .update(this.editingGrade, {
        name: this.editGradeForm.controls.name.value,
        description: this.editGradeForm.controls.description.value,
      })
      .subscribe(() => {
        this.editingGrade = '';
        this._loadSpecialityGrades();
      });
  }

  update(): void {
    this.speciality.update({ ...this.form.value }).subscribe(
      (response) => {
        this.form.markAsPristine();
        this._alertService.success('Специальность обновлена');
      },
      (error) => {
        this._alertService.error('Не удалось обновить специальность');
      }
    );
  }
}
