<div class='router-outlet-container'>
  <ng-container *ngIf='is_data_loaded; then component_content; else preloader'>
  </ng-container>
</div>

<ng-template #preloader>
  <app-preloader></app-preloader>
</ng-template>

<ng-template #component_content>
  <div class='app-admin-ontology-domain-details-container'>
    <div class='app-admin-ontology-domain-details-header-container'>
      <ng-container
        [ngTemplateOutlet]='in_edit_mode ? tmpl_header_edit_mode : tmpl_header'
      >
      </ng-container>
    </div>

    <div class='app-admin-ontology-domain-details-tabs-container'>
      <mat-tab-group animationDuration='0ms' mat-stretch-tabs='false'>
        <mat-tab label='Компетенции'>
          <div class='app-admin-ontology-domain-details-tabs-content-container'>
            <ng-container
              *ngIf='
                is_thing_create_form_shown;
                then tmpl_tab_thing_create;
                else tmpl_tab_thing_list
              '
            >
            </ng-container>
          </div>
        </mat-tab>
        <mat-tab label='Принадлежность к команде'>
          <div class='app-admin-ontology-domain-details-tabs-content-container'>
            <ng-container [ngTemplateOutlet]='tmpl_team_transfer'>
            </ng-container>
          </div>
        </mat-tab>
        <mat-tab label='Операции'>
          <div class='app-admin-ontology-domain-details-tabs-content-container'>
            <app-in-development-stub></app-in-development-stub>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div
      #tooltip_delete_thing_listitem
      class='app-admin-ontology-domain-details-things-delete-tooltip'
      style='display: none; opacity: 0'
      (mouseleave)='on_button_thing_delete_hide_tooltip()'
    >
      <div
        class='app-admin-ontology-domain-details-things-delete-tooltip-header'
      >
        Удалить компетенцию?
      </div>
      <div
        class='app-admin-ontology-domain-details-things-delete-tooltip-description'
      >
        Будут потеряны все связанные компетенции
      </div>
      <div fxLayout='row'>
        <div fxFlex fxLayoutAlign='space-around center'>
          <button
            mat-raised-button
            color='warn'
            [disabled]='is_button_thing_tooltip_delete_disabled'
            (click)='on_tooltip_thing_delete_confirm()'
          >
            Удалить
          </button>

          <button mat-raised-button (click)='on_tooltip_thing_delete_cancel()'>
            Отмена
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_header>
  <div fxLayout='row'>
    <div fxFlex>
      <div class='app-admin-ontology-domain-details-header-name'>
        {{ domain.name }}
      </div>
      <div class='app-admin-ontology-domain-details-header-description'>
        {{ domain.description }}
      </div>
      <div class='app-admin-ontology-domain-details-header-team'>
        <div *ngIf='!domain.team'>Публичный домен</div>
        <div *ngIf='domain.team'>
          Домен команды
          <a routerLink='/admin/teams/{{ domain.team.uuid }}'>
            {{ domain.team.name }}
          </a>
        </div>
      </div>
      <div class='app-admin-ontology-domain-details-header-parent'>
        <div *ngIf='!domain.parent_domain'>На верхнем уровне иерархии</div>
        <div *ngIf='domain.parent_domain'>
          Родительский домен
          <a
            routerLink='/admin/ontologies/domains/{{
              domain.parent_domain.uuid
            }}'
          >
            {{ domain.parent_domain.name }}
          </a>
        </div>
      </div>
    </div>
    <div fxFlex='15rem'>
      <div>
        <div class='app-admin-ontology-domain-details-header-controls'>
          <button mat-stroked-button (click)='on_button_edit()'>
            <mat-icon>edit</mat-icon>
            Редактировать
          </button>
          &nbsp;
          <button
            mat-stroked-button
            color='warn'
            (click)='on_button_domain_delete_show_tooltip($event)'
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <div class='app-admin-ontology-domain-details-header-metadata'>
          Cоздан: {{ domain.date_created | amDateFormat: 'YYYY-MM-DD HH:mm' }}
        </div>
      </div>
    </div>
  </div>
  <div
    #tooltip_delete_domain
    class='app-admin-ontology-domain-details-domain-delete-tooltip'
    style='display: none; opacity: 0'
    (mouseleave)='on_button_thing_delete_hide_tooltip()'
  >
    <div class='app-admin-ontology-domain-details-domain-delete-tooltip-header'>
      Удалить домен?
    </div>
    <div
      class='app-admin-ontology-domain-details-domain-delete-tooltip-description'
    >
      Будут потеряны все связанные компетенции
    </div>
    <div fxLayout='row'>
      <div fxFlex fxLayoutAlign='space-around center'>
        <button
          mat-raised-button
          color='warn'
          [disabled]='is_button_domain_tooltip_delete_disabled'
          (click)='on_tooltip_domain_delete_confirm()'
        >
          Удалить
        </button>

        <button mat-raised-button (click)='on_tooltip_domain_delete_cancel()'>
          Отмена
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_header_edit_mode>
  <div fxLayout='row'>
    <div fxFlex>
      <form [formGroup]='domain_edit_form' fxLayout='column' fxLayoutGap='1rem'>
        <mat-form-field>
          <mat-label>Название</mat-label>
          <input
            #input_field_name
            matInput
            placeholder='Название'
            formControlName='domain_name'
            required
          />

          <mat-hint>{{ 128 - input_field_name.value.length }}/128</mat-hint>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Описание</mat-label>
          <input
            #input_field_description
            matInput
            placeholder='Описание'
            formControlName='domain_description'
          />
          <mat-hint
          >{{ 1024 - input_field_description.value.length }}/1024
          </mat-hint
          >
        </mat-form-field>
      </form>
      <ng-select
        *ngIf='isSearchDomainsLoaded'
        [style.margin-top.px]='10'
        class='thing-input-field'
        [multiple]='false'
        appearance='outline'
        placeholder='Родительский домен'
        (search)='onDomainsSearch($event.term)'
        (change)='onSelectDomainChange($event)'
        [closeOnSelect]='true'
        [(ngModel)]='selectedDomain'
      >
        <ng-option class='thing-option' *ngIf='domain.parent_domain' [value]='null'>
          &mdash; Переместить на верхний уровень &mdash;
        </ng-option>
        <ng-option
          class='thing-option'
          *ngFor='let parent_domain of searchDomains.state.items'
          [value]='parent_domain.uuid'
        >
          {{ parent_domain.name }}
          <small>
            <span *ngIf='!parent_domain.team'> ( Публичный ) </span>
            <span *ngIf='parent_domain.team'>
                  ( {{ parent_domain.team.name }} )
                </span>
          </small>
        </ng-option>
      </ng-select>
    </div>

    <div fxFlex='20rem' fxLayoutAlign='space-around start'>
      <button
        mat-stroked-button
        [disabled]='domain_edit_form.invalid || in_edit_mode_loading'
        (click)='on_button_save_edit_form()'
      >
        <mat-icon>save</mat-icon>
        Сохранить
      </button>
      <button mat-stroked-button (click)='on_button_edit_cancel()'>
        <mat-icon>cancel</mat-icon>
        Отмена
      </button>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_tab_thing_create>
  <div class='app-admin-ontology-domain-details-thing-create-form-container'>
    <form [formGroup]='thing_create_form'>
      <div fxLayout='row' fxLayoutGap='15px'>
        <mat-form-field
          [style.width.rem]='15'
          floatLabel='never'
          appearance='outline'
        >
          <input
            matInput
            placeholder='Название'
            type='text'
            formControlName='thing_name'
          />
        </mat-form-field>
        <mat-form-field
          [style.width.rem]='15'
          floatLabel='never'
          appearance='outline'
        >
          <input
            matInput
            placeholder='Описание'
            type='text'
            formControlName='thing_description'
          />
        </mat-form-field>
        <mat-form-field
          [style.width.rem]='15'
          floatLabel='never'
          appearance='outline'
        >
          <mat-select formControlName='thing_kind' placeholder='Тип'>
            <mat-option [value]='0'> Тип не выбран</mat-option>
            <mat-option [value]='1'> Soft skill</mat-option>
            <mat-option [value]='2'> Практика</mat-option>
            <mat-option [value]='3'> Программное обеспечение</mat-option>
            <mat-option [value]='4'> Оборудование</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout='row'>
        <button
          mat-raised-button
          [disabled]='thing_create_form.invalid || is_thing_create_form_loading'
          (click)='on_button_thing_create_submit()'
        >
          Добавить
        </button>
        &nbsp;
        <button
          mat-stroked-button
          type='button'
          (click)='on_button_thing_create_cancel()'
        >
          <mat-icon>close</mat-icon>
          Отмена
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #tmpl_tab_thing_list>
  <div class='app-admin-ontology-domain-details-things-content-container'>
    <div
      class='app-admin-ontology-domain-details-add-panel'
      (click)='on_thing_show_create_form()'
    >
      <div fxLayout='row' fxLayoutAlign='center'>
        <div fxFlex='5rem'>
          <div class='app-admin-ontology-domain-details-add-panel-circle'>
            +
          </div>
        </div>
        <div fxFlex='15rem'>
          <div class='app-admin-ontology-domain-details-add-panel-text'>
            Добавить компетенцию
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class='app-admin-ontology-domain-details-things-list-container'>
    <div *ngFor='let thing of things'>
      <div class='app-admin-ontology-domain-details-things-listitem'>
        <div fxLayout='row'>
          <div fxFlex='25rem'>
            <div class='app-admin-ontology-domain-details-things-listitem-name'>
              <a routerLink='/admin/ontologies/things/{{ thing.uuid }}'>
                {{ thing.name }}
              </a>
            </div>
          </div>
          <div fxFlex='17rem'>
            <div
              class='app-admin-ontology-domain-details-things-listitem-description'
            >
              {{ thing.description }}
            </div>
          </div>
          <div fxFlex='12rem'>
            <div
              class='app-admin-ontology-domain-details-things-listitem-dates'
            >
              <div>
                Cоздана:
                {{ thing.date_created | amDateFormat: 'YYYY-MM-DD HH:mm' }}
              </div>
            </div>
          </div>
          <div fxFlex='5rem'>
            <button
              mat-icon-button
              (click)='on_button_thing_delete_show_tooltip(thing, $event)'
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_team_transfer>
  <div class='app-admin-ontology-domain-details-team-transfer-container'>
    <div fxLayout='row'>
      <div fxFlex fxLayoutAlign='center center'>
        <div *ngIf='!domain.team'>Сейчас домен публичный</div>
        <div *ngIf='domain.team'>
          <div>
            <a routerLink='/admin/teams/{{ domain.team.uuid }}'>
              {{ domain.team.name }}
            </a>
          </div>
        </div>
      </div>

      <div fxFlex='10rem' fxLayoutAlign='center center'>&rarr;</div>

      <div fxFlex fxLayoutAlign='end center'>
        <form class='app-admin-ontology-domain-details-team-transfer-form'>
          <mat-form-field
            class='app-admin-ontology-domain-details-team-transfer-form-full-width'
          >
            <mat-label>Новая команда</mat-label>
            <input
              type='text'
              #team_transfer_search_input
              placeholder='Название команды'
              aria-label='Team search'
              matInput
              [formControl]='team_transfer_search_form_control'
              [matAutocomplete]='auto'
              (input)='
                on_team_transfer_search(team_transfer_search_input.value)
              '
            />
            <mat-autocomplete
              #auto='matAutocomplete'
              [displayWith]='util_team_transfer_search_display'
              (optionSelected)='
                on_team_transfer_search_autocomplete_selected(
                  $event.option.value
                )
              '
            >
              <mat-option
                *ngFor='let team of team_transfer_search_results'
                [value]='team'
              >
                {{ team.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
      </div>
    </div>

    <br />
    <br />

    <div fxLayout='row'>
      <div fxFlex></div>
      <div fxFlex='30rem' fxLayoutAlign='center center'>
        <button
          mat-stroked-button
          (click)='on_button_team_transfer_submit()'
          [disabled]='is_button_team_transfer_submit_disabled'
        >
          <mat-icon>swap_horiz</mat-icon>
          Изменить
        </button>
        &nbsp; &nbsp;
        <button
          mat-stroked-button
          (click)='on_button_team_transfer_public()'
          [disabled]='is_button_team_transfer_public_disabled'
        >
          <mat-icon>public</mat-icon>
          Сделать публичным
        </button>
      </div>
      <div fxFlex></div>
    </div>
  </div>
</ng-template>
