import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { Thing } from '@models/ontology/thing';
import { HttpClient } from '@angular/common/http';
import { Competence } from '@models/competencies/competence';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompetenceHttpService extends ViewSetHttpService<Competence> {
  http: HttpClient;
  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'competencies/competence';

  public getTeamCompetencies(teamUuid: string): Observable<any> {
    return this.http.get<{
      teamUuid: string;
    }>(`${this.getUrl()}/fetch_by_team_uuid`, {
      responseType: 'json',
      params: {
        team: teamUuid,
      },
    });
  }
}
