<div
  class='review-container'
  fxLayout='column'
  *ngIf='review && review.state.isLoaded'
>
  <div fxLayout='row' class='review-title'>
    <div fxFlex='95' fxLayoutAlign='start center' fxLayoutGap='2rem'>
      <button (click)='onBackClick()' mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span>{{ review.state.item.name }}</span>
    </div>
    <div fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='8px'>
      <button
        mat-icon-button
        *ngIf='isAdmin()'
        (click)='onReviewDelete()'
        class='review-action'
        fxLayoutAlign='center center'
      >
        <mat-icon fxFlex>delete</mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf='
          isAdmin() ||
          (isResponsible() && review.state.item.completion_percentage < 100)
        '
        (click)='onReviewEdit(review.state.item)'
        class='review-action'
        fxLayoutAlign='center center'
      >
        <mat-icon fxFlex>edit</mat-icon>
      </button>
    </div>
  </div>
  <app-static-textfield-group
    [fields]='reviewInfoData'
  ></app-static-textfield-group>
  <mat-divider class='divider'></mat-divider>
  <div *ngIf='reviewAssessments && reviewAssessments.state.isLoaded'>
    <br />
    <div
      *ngFor='let assessment of sortedAssessments; let i = index'
      [attr.data-index]='i'
      class='assessment-data-container'
      [ngStyle]="{ cursor: isReviewer(assessment) ? 'pointer' : '' }"
      (click)='onAssessmentClick(assessment)'
    >
      <div fxLayout='column' fxFlex='30'>
        <div fxLayout='row' fxLayoutAlign='start center'>
          <span
            *ngIf='selfAssessment && selfAssessment.uuid === assessment.uuid'
          >
            {{ 'Оценил сам себя' }}
          </span>
          <span
            class='assessment-container-text'
            *ngIf='!selfAssessment || selfAssessment.uuid !== assessment.uuid'
          >{{ 'Ревьюер ' + (selfAssessment ? i : i + 1) }}</span
          >
        </div>
        <div fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='1rem'>
          <mat-progress-bar
            mode='determinate'
            [value]='assessment.completion_percentage'
          ></mat-progress-bar>
          <span>{{ assessment.completion_percentage + '%' }}</span>
        </div>
      </div>
      <div fxFlex='40' fxLayout='row' fxLayoutGap='4px'></div>
      <div
        fxFlex='20'
        class='user-container'
        fxLayout='row'
        fxLayoutAlign='start center'
      >
        <mat-icon fxFlex='3rem'>account_circle</mat-icon>
        <div fxLayout='column' fxFlex>
          <span class='user-name'>{{
            assessment.reviewer_user.first_name +
            ' ' +
            assessment.reviewer_user.last_name
            }}</span>
          <span class='user-email'>{{ assessment.reviewer_user.email }}</span>
        </div>
      </div>
      <div
        fxFlex='5'
        fxLayoutAlign='end center'
        *ngIf='assessment.completion_percentage === 100'
      >
        <mat-icon class='completed-icon'>check_circle_outline</mat-icon>
      </div>
    </div>
    <div
      *ngIf='
        reviewResolutions && reviewResolutions.state.isLoaded && resolution
      '
      class='assessment-data-container'
      (click)='onResolutionClick(resolution)'
      [ngStyle]="{ cursor: isResponsible() ? 'pointer' : '' }"
    >
      <div fxLayout='column' fxFlex='30'>
        <div fxLayout='row' fxLayoutAlign='start center'>
          <span class='assessment-container-text'>Руководитель</span>
        </div>
        <div fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='1rem'>
          <mat-progress-bar
            mode='determinate'
            [value]='resolution.completion_percentage'
          ></mat-progress-bar>
          <span>{{ resolution.completion_percentage + '%' }}</span>
        </div>
      </div>
      <div fxFlex='40' fxLayout='row' fxLayoutGap='4px'></div>
      <div
        fxFlex='20'
        class='user-container'
        fxLayout='row'
        fxLayoutAlign='center center'
      >
        <mat-icon fxFlex='3rem'>account_circle</mat-icon>
        <div fxLayout='column' fxFlex>
          <span class='user-name'>{{
            responsibleUser.first_name + ' ' + responsibleUser.last_name
            }}</span>
          <span class='user-email'>{{ responsibleUser.email }}</span>
        </div>
      </div>
      <div
        fxFlex='5'
        fxLayoutAlign='end center'
        *ngIf='resolution.completion_percentage === 100'
      >
        <mat-icon class='completed-icon'>check_circle_outline</mat-icon>
      </div>
    </div>
  </div>
</div>
