import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

import { Thing } from 'src/app/models/ontology/thing';

@Injectable({
  providedIn: 'root',
})
export class ThingService {
  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService
  ) {}

  public fetch_by_uuid(thing_uuid: string): Observable<any> {
    const params = { expand: 'domain' };
    return this.api_service.get(`ontology/thing/${thing_uuid}`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched thing with uuid ${thing_uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch thing`
          );
        }
      )
    );
  }

  public fetch_by_team_uuid(team_uuid: string): Observable<any> {
    const params = { team: team_uuid };
    return this.api_service.get(`ontology/thing`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched things for team ${team_uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch things for team`
          );
        }
      )
    );
  }

  public create(thing_data: any): Observable<any> {
    const params = { expand: 'domain' };
    return this.api_service.post(`ontology/thing`, thing_data, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} created thing with uuid ${response.uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to create thing`
          );
        }
      )
    );
  }

  public update(thing_uuid: string, thing_data: any): Observable<any> {
    return this.api_service
      .patch(`ontology/thing/${thing_uuid}`, thing_data, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} updated thing with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to update thing`
            );
          }
        )
      );
  }

  public delete(thing: Thing): Observable<any> {
    return this.api_service.delete(`ontology/thing/${thing.uuid}`, {}).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} deleted thing with uuid ${thing.uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to delete thing`
          );
        }
      )
    );
  }

  public search(term: string): Observable<any> {
    if (!term.trim()) {
      return of([]);
    }
    const params = { search: term, expand: 'domain' };
    return this.api_service.get(`ontology/thing`, params).pipe(
      tap(
        (response) => {
          const things = response.results as Thing[];
          this.logging_service.debug(
            `${this.constructor.name} found ${things.length} things for search term=${term}`
          );
        },
        (err) => {
          this.logging_service.debug(
            `${this.constructor.name} error while searching things with term=${term}`
          );
        }
      )
    );
  }

  public search_with_team(term: string, team_uuid: string): Observable<any> {
    if (!term.trim()) {
      return of({ results: [], count: 0 });
    }
    const params = { search: term, team: team_uuid, expand: 'domain,team' };
    return this.api_service.get(`ontology/thingteamglobalsearch`, params).pipe(
      tap(
        (response) => {
          const things = response.results as Thing[];
          this.logging_service.debug(
            `${this.constructor.name} found ${things.length} things for search term=${term}`
          );
        },
        (err) => {
          this.logging_service.debug(
            `${this.constructor.name} error while searching things with term=${term}`
          );
        }
      )
    );
  }

  public search_only_within_team(
    term: string,
    team_uuid: string
  ): Observable<any> {
    if (!term.trim()) {
      return of({ results: [], count: 0 });
    }
    const params = { search: term, team: team_uuid, expand: 'domain,team' };
    return this.api_service.get(`ontology/thing`, params).pipe(
      tap(
        (response) => {
          const things = response.results as Thing[];
          this.logging_service.debug(
            `${this.constructor.name} found ${things.length} things for search term=${term}`
          );
        },
        (err) => {
          this.logging_service.debug(
            `${this.constructor.name} error while searching things with term=${term}`
          );
        }
      )
    );
  }

  public fetch_by_domain_uuid(domain_uuid: string): Observable<any> {
    return this.api_service.get(`ontology/thing`, { domain: domain_uuid }).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} things`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch things`
          );
        }
      )
    );
  }
}
