import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from 'src/app/services/api.service';
import { LoggingService } from 'src/app/services/logging.service';

@Injectable({
  providedIn: 'root'
})
export class SpecialityGradeService {

  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService,
  ) { }

  public fetch_by_uuid(grade_uuid: string): Observable<any> {
    return this.api_service.get(`specialities/grade/${grade_uuid}`, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} fetched speciality grade with uuid ${grade_uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to fetch speciality grade`);
        }
    ));
  }

  public fetch_by_speciality_uuid(speciality_uuid: string): Observable<any> {
    return this.api_service.get(`specialities/grade`, {speciality: speciality_uuid}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} fetched grades for speciality ${speciality_uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to fetch speciality grades`);
        }
    ));
  }

  public create(grade_data: any): Observable<any> {
    return this.api_service.post(`specialities/grade`, grade_data, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} created speciality grade with uuid ${response.uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to create speciality grade`);
        }
    ));
  }

  public update(grade_uuid: string, grade_data: any): Observable<any> {
    return this.api_service.patch(`specialities/grade/${grade_uuid}`, grade_data, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} updated speciality grade with uuid ${response.uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to update speciality grade`);
        }
      ));
  }

  public delete(grade_uuid: string): Observable<any> {
    return this.api_service.delete(`specialities/grade/${grade_uuid}`, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} deleted speciality grade with id ${grade_uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to delete speciality grade`);
        }
      ));
  }

  public reorder({moved_uuid, target_uuid}: {moved_uuid: string, target_uuid: string}) {
    return this.api_service.post(`specialities/grade/reorder`, {moved_uuid, target_uuid}, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} reorder speciality grades`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to reorder speciality grades`);
        }
    ));
  }
}
