import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { testDataAttributes } from '@test-utils';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { TitleService } from 'src/app/services/title.service';
import { UserService } from 'src/app/services/user/user.service';
import { TeamService } from 'src/app/services/teams/team.service';
import { TeamMembershipService } from 'src/app/services/teams/team-membership.service';
import { SurveyService } from 'src/app/services/survey/survey.service';
import { SurveyTeamRelationService } from 'src/app/services/survey/survey-team-relation.service';
import { AuthService } from 'src/app/services/auth/auth.service';

import { Team } from 'src/app/models/teams/team';
import { User } from 'src/app/models/user/user';
import { TeamMembership } from 'src/app/models/teams/team-membership';
import { Survey } from 'src/app/models/survey/survey';
import { SurveyTeamRelation } from 'src/app/models/survey/survey-team-relation';
import { TeamMemberStatusHttpService } from '@services/http/TeamMemberStatusHttpService';
import { AsyncList } from '@rest/AsyncList';
import { TeamMemberStatus } from '@models/teams/team-member-status';
import { TeamHttpService } from '@services/http/TeamHttpService';
import { TeamEventHttpService } from '@services/http/TeamEventHttpService';
import { TeamEvent } from '@models/events/team-event';
import { TeamJoinRequestHttpService } from '@services/http/TeamJoinRequestHttpService';
import { TeamEmailInvitationHttpService } from '@services/http/TeamEmailInvitationHttpService';
import { TeamEmailInvitation } from '@models/teams/team-email-invitation';
import { TeamJoinRequest } from '@models/teams/team-join-request';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityGrade } from '@models/specialities/speciality-grade';

interface TeamIconsOption {
  url: string;
  name: string;
}

@Component({
  selector: 'app-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.css'],
})
export class TeamDetailsComponent implements OnInit {
  private team_uuid: string;
  public team: Team;

  teamMembersStatus: AsyncList<TeamMemberStatus>;
  events: AsyncList<TeamEvent>;

  public testDataAttributes = testDataAttributes;

  public teams: Team[] = [];

  public memberships: TeamMembership[] = [];
  public member_search_form_control = new UntypedFormControl('', []);
  private member_search_terms = new Subject<string>();
  private member_search$: Observable<{ results: User[] } | null>;
  public member_search_results: User[] = [];

  public member_search_selected_user: User;
  public member_search_selected_role: string;

  public membership_role_change_selected: TeamMembership;
  public membership_role_change_selected_role: string;

  public surveys: Survey[] = [];
  public survey_relations: SurveyTeamRelation[] = [];
  public selected_relation_selected_survey: Survey;

  public ui_tabs_color: ThemePalette = 'warn';

  public team_edit_form: UntypedFormGroup;

  teamInvitations: AsyncList<TeamEmailInvitation>;
  teamJoinRequests: AsyncList<TeamJoinRequest>;

  private uistate = {
    team_loaded: false,
    memberships_loaded: false,

    edit_mode: false,

    member_add_form_show: false,
    member_add_user_selected: false,
    button_membership_add_disabled: false,
    button_membership_delete_disabled: false,

    is_membership_role_change_form_shown: false,
    is_membership_role_change_button_disabled: false,

    button_toggle_hidden_disabled: false,

    surveys_loaded: false,
    survey_relations_loaded: false,
    survey_create_relation_form_shown: false,
    survey_create_relation_button_disabled: false,
    survey_delete_relation_button_disabled: false,
  };

  teamIconsOptions: TeamIconsOption[] = [
    { url: '', name: 'Нет иконки' },
    { url: '/assets/rb/icons/teams/PL_Plus.svg', name: 'PL_Plus' },
    {
      url: '/assets/rb/icons/teams/Security Champions.svg',
      name: 'Security Champions',
    },
    {
      url: '/assets/rb/icons/teams/Software Factory.svg',
      name: 'Software Factory',
    },
    { url: '/assets/rb/icons/teams/Гильдия Data.svg', name: 'Гильдия Data' },
    {
      url: '/assets/rb/icons/teams/Гильдия Frontend.svg',
      name: 'Гильдия Frontend',
    },
    { url: '/assets/rb/icons/teams/Гильдия IBM.svg', name: 'Гильдия IBM' },
    {
      url: '/assets/rb/icons/teams/Гильдия Integration.svg',
      name: 'Гильдия Integration',
    },
    { url: '/assets/rb/icons/teams/Гильдия Java.svg', name: 'Гильдия Java' },
    {
      url: '/assets/rb/icons/teams/Гильдия Siebel.svg',
      name: 'Гильдия Siebel',
    },
    { url: '/assets/rb/icons/teams/Гильдия SQL.svg', name: 'Гильдия SQL' },
    {
      url: '/assets/rb/icons/teams/Гильдия Админов.svg',
      name: 'Гильдия Админов',
    },
    {
      url: '/assets/rb/icons/teams/Гильдия Аналитиков.svg',
      name: 'Гильдия Аналитиков',
    },
    {
      url: '/assets/rb/icons/teams/Гильдия Архитекторов.svg',
      name: 'Гильдия Архитекторов',
    },
    {
      url: '/assets/rb/icons/teams/Гильдия Тестирования.svg',
      name: 'Гильдия Тестирования',
    },
  ];

  teamTypes = ['agile', 'admin', 'guild'];

  statusMap = ['Создан', 'Отправлен', 'Принят', 'Отклонен'];

  constructor(
    private form_builder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private auth_service: AuthService,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private title_service: TitleService,
    private user_service: UserService,
    private team_service: TeamService,
    private membership_service: TeamMembershipService,
    private survey_service: SurveyService,
    private survey_team_relation_service: SurveyTeamRelationService,
    private _teamHttpService: TeamHttpService,
    private _teamMemberStatusHttpService: TeamMemberStatusHttpService,
    private _teamEventsHttpService: TeamEventHttpService,
    private _teamJoinRequestHttpService: TeamJoinRequestHttpService,
    private _teamEmailInvitationHttpService: TeamEmailInvitationHttpService
  ) {}

  ngOnInit(): void {
    this.title_service.set_title('Команда');
    this.route.params.subscribe((route_params) => {
      this.team_uuid = route_params.team_uuid;
      this.logging_service.debug(
        `${this.constructor.name} loading ${this.team_uuid}`
      );
      this.uistate.team_loaded = false;
      this.uistate.memberships_loaded = false;
      this.uistate.survey_relations_loaded = false;
      this.uistate.surveys_loaded = false;
      this.load_team();
      this._loadTeamEvents();
      this._loadTeamMembersStatus();
      this._loadTeamInvitations();
      this._loadTeamJoinRequests();
      this.load_teams(); // needed to change parent team on edit
    });

    this.member_search$ = this.member_search_terms.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap((term: string) => this.user_service.search(term))
    );
    this.member_search$.subscribe(
      (response) => (this.member_search_results = response.results)
    );
    this.create_form();
  }

  private _loadTeamInvitations(): void {
    this.teamInvitations = new AsyncList<TeamEmailInvitation>(
      this._teamEmailInvitationHttpService
    );
    this.teamInvitations.setRequestParams({
      params: {
        team: this.team_uuid,
        expand: 'specialities,speciality_grades',
      },
    });
    this.teamInvitations.load();
  }

  private _loadTeamJoinRequests(): void {
    this.teamJoinRequests = new AsyncList<TeamJoinRequest>(
      this._teamJoinRequestHttpService
    );
    this.teamJoinRequests.setRequestParams({
      params: {
        team: this.team_uuid,
        expand: 'user,specialities,speciality_grades',
      },
    });
    this.teamJoinRequests.load();
  }

  private _loadTeamEvents(): void {
    this.events = new AsyncList<TeamEvent>(this._teamEventsHttpService);
    this.events.setRequestParams({
      params: {
        team: this.team_uuid,
      },
    });
    this.events.load();
  }

  private _loadTeamMembersStatus(): void {
    this.teamMembersStatus = new AsyncList<TeamMemberStatus>(
      this._teamMemberStatusHttpService
    );
    this.teamMembersStatus.setRequestParams({
      params: {
        team: this.team_uuid,
        expand: 'member',
      },
    });
    this.teamMembersStatus.load();
  }

  public get is_data_loaded(): boolean {
    return (
      this.uistate.team_loaded &&
      this.uistate.memberships_loaded &&
      this.uistate.surveys_loaded &&
      this.uistate.survey_relations_loaded
    );
  }

  public get is_button_membership_add_enabled(): boolean {
    return (
      this.member_search_selected_user &&
      this.member_search_selected_role &&
      !this.uistate.button_membership_add_disabled
    );
  }

  public get is_button_membership_delete_enabled(): boolean {
    return !this.uistate.button_membership_delete_disabled;
  }

  public get is_button_toggle_hidden_enabled(): boolean {
    return !this.uistate.button_toggle_hidden_disabled;
  }

  public get is_member_add_form_shown(): boolean {
    return this.uistate.member_add_form_show;
  }

  public get is_survey_create_relation_form_shown(): boolean {
    return this.uistate.survey_create_relation_form_shown;
  }

  public get is_survey_create_relation_button_disabled(): boolean {
    return this.uistate.survey_create_relation_button_disabled;
  }

  public is_membership_role_change_form_shown(
    membership: TeamMembership
  ): boolean {
    return (
      this.uistate.is_membership_role_change_form_shown &&
      this.membership_role_change_selected &&
      this.membership_role_change_selected.uuid === membership.uuid
    );
  }

  public get is_membership_role_change_button_disabled(): boolean {
    return (
      this.uistate.is_membership_role_change_button_disabled ||
      !this.membership_role_change_selected_role
    );
  }

  private load_team(): void {
    this.team_service.fetch_by_uuid(this.team_uuid).subscribe(
      (response) => {
        this.team = response as Team;
        this.title_service.set_title(this.team.name);
        this.uistate.team_loaded = true;
        this.logging_service.debug(
          `${this.constructor.name} loaded team ${this.team.name}`
        );
        this.load_memberships();
        this.load_survey_relations();
        this.load_surveys();
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to load team`
        );
        this.alert_service.error(`Ошибка загрузки команды ${err.status}`);
      }
    );
  }

  private load_teams(): void {
    this.logging_service.debug(`${this.constructor.name} loading teams...`);
    this.team_service.fetch_all().subscribe(
      (response) => {
        const teams = response.results as Team[];
        this.teams = teams.filter((t) => t.uuid !== this.team_uuid);
        this.logging_service.debug(
          `${this.constructor.name} teams loaded successfully`
        );
      },
      (err) => {
        this.alert_service.error(`Ошибка при загрузке команд: ${err.status}`);
      }
    );
  }

  private load_memberships(): void {
    this.membership_service
      .fetch_by_team_uuid(this.team.uuid, { page_size: 10000 })
      .subscribe(
        (response) => {
          this.memberships = response.results as TeamMembership[];
          this.uistate.memberships_loaded = true;
          this.logging_service.debug(
            `${this.constructor.name} loaded ${this.memberships.length} memberships`
          );
        },
        (err) => {
          this.logging_service.debug(
            `${this.constructor.name} failed to load memberships`
          );
          this.alert_service.error(`Ошибка загрузки участников ${err.status}`);
        }
      );
  }

  private load_surveys(): void {
    this.survey_service.fetch_all().subscribe(
      (response) => {
        this.surveys = response.results as Survey[];
        this.uistate.surveys_loaded = true;
        this.logging_service.debug(
          `${this.constructor.name} loaded ${this.surveys.length} surveys`
        );
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to load surveys`
        );
        this.alert_service.error(`Ошибка загрузки опросов ${err.status}`);
      }
    );
  }

  private load_survey_relations(): void {
    this.survey_team_relation_service
      .fetch_by_team_uuid(this.team.uuid)
      .subscribe(
        (response) => {
          this.survey_relations = response.results as SurveyTeamRelation[];
          this.uistate.survey_relations_loaded = true;
          this.logging_service.debug(
            `${this.constructor.name} loaded ${this.survey_relations.length} survey relations`
          );
        },
        (err) => {
          this.logging_service.debug(
            `${this.constructor.name} failed to load survey relations`
          );
          this.alert_service.error(
            `Ошибка загрузки связей с опросами ${err.status}`
          );
        }
      );
  }

  getSpecialityGradeName(
    invitation: TeamEmailInvitation | TeamJoinRequest,
    speciality: Speciality
  ): string {
    return (invitation.speciality_grades as SpecialityGrade[]).find(
      (specialityGrade) => specialityGrade.speciality === speciality.uuid
    ).name;
  }

  getPrettyInvitationDate(date: string): string {
    const retDate = new Date(date);
    return `${retDate.getDate()} ${retDate.toLocaleString('default', {
      month: 'short',
    })} ${retDate.getFullYear()}`;
  }

  public on_show_member_add_form(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_show_member_add_form`
    );
    this.uistate.member_add_form_show = true;
  }

  public on_close_member_add_form(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_close_member_add_form`
    );
    this.uistate.member_add_form_show = false;
    this.member_search_selected_user = null;
    this.member_search_selected_role = null;
    this.member_search_form_control.reset();
  }

  public on_member_search(term: string): void {
    this.member_search_terms.next(term);
  }

  on_member_search_selected(user: User): void {
    this.logging_service.debug(
      `${this.constructor.name} on_member_search_autocomplete_selected`
    );
    this.member_search_selected_user = user;
    this.member_search_results = [];
  }

  public on_button_member_add_submitted(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_member_add_submitted`
    );
    this.uistate.button_membership_add_disabled = true;
    const membership_data = {
      team: this.team.uuid,
      user: this.member_search_selected_user.uuid,
      role: this.member_search_selected_role,
    };
    this.membership_service.create(membership_data).subscribe(
      (response) => {
        const membership = {
          uuid: response.uuid,
          team: this.team,
          user: this.member_search_selected_user,
          role: this.member_search_selected_role,
          date_created: response.date_created,
        } as TeamMembership;
        this.logging_service.debug(
          `${this.constructor.name} created team membership`
        );
        const user = this.member_search_selected_user;
        const message = `${user.first_name} ${user.last_name} добавлен в команду`;
        this.alert_service.success(message);
        this.member_search_selected_role = null;
        this.member_search_selected_user = null;
        this.uistate.button_membership_add_disabled = false;
        this.uistate.member_add_form_show = false;
        this.memberships.push(membership);
        this.member_search_selected_user = null;
        this.member_search_form_control.reset();
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to create team membership`
        );
        this.alert_service.error(`Ошибка добавления в команду ${err.status}`);
      }
    );
  }

  public on_button_member_delete_submitted(membership: TeamMembership): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_member_delete_submitted`
    );
    this.uistate.button_membership_delete_disabled = true;
    this.membership_service.delete(membership).subscribe(
      (response) => {
        this.logging_service.debug(
          `${this.constructor.name} deleted team membership`
        );
        const user = membership.user as User;
        const message = `${user.first_name} ${user.last_name} удален из команды`;
        this.alert_service.success(message);
        this.uistate.button_membership_delete_disabled = false;
        this.memberships = this.memberships.filter(
          (m) => m.uuid !== membership.uuid
        );
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to delete team membership`
        );
        this.alert_service.error(`Ошибка удаления из команды ${err.status}`);
      }
    );
  }

  public on_show_change_role_form(membership: TeamMembership): void {
    this.membership_role_change_selected = membership;
    this.uistate.is_membership_role_change_form_shown = true;
  }

  public on_change_role_submit(membership: TeamMembership): void {
    this.logging_service.debug(
      `${this.constructor.name} setting ${this.membership_role_change_selected_role} role`
    );
    this.membership_service
      .update(membership.uuid, {
        role: this.membership_role_change_selected_role,
      })
      .subscribe(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} successfully changed role`
          );
          this.alert_service.success(`Роль изменена`);
          membership.role = response.role;
          this.uistate.is_membership_role_change_form_shown = false;
          this.membership_role_change_selected_role = null;
          this.membership_role_change_selected = null;
        },
        (err) => {
          this.logging_service.debug(
            `${this.constructor.name} failed to change role`
          );
          this.alert_service.error(`Ошибка изменения роли: ${err.status}`);
        }
      );
  }

  public on_change_role_cancel(): void {
    this.membership_role_change_selected_role = null;
    this.membership_role_change_selected = null;
    this.uistate.is_membership_role_change_form_shown = false;
  }

  public on_button_toggle_team_hidden(): void {
    const new_hidden_value = !this.team.is_hidden;
    this.uistate.button_toggle_hidden_disabled = true;
    this.logging_service.debug(
      `${this.constructor.name} setting team hidden to ${new_hidden_value}`
    );
    this.team_service
      .update(this.team.uuid, { is_hidden: new_hidden_value })
      .subscribe(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} update team is_hidden: ${new_hidden_value} successfully`
          );
          this.team.is_hidden = new_hidden_value;
          this.uistate.button_toggle_hidden_disabled = false;
        },
        (err) => {
          this.logging_service.debug(
            `${this.constructor.name} failed update team is_hidden: ${new_hidden_value}`
          );
          this.alert_service.error(`Ошибка изменения видимости ${err.status}`);
          this.uistate.button_toggle_hidden_disabled = false;
        }
      );
  }

  public util_member_search_display(user: User): string {
    return user ? `${user.first_name} ${user.last_name} – ${user.email}` : '';
  }

  public util_member_get_initials_for_avatar(user: User): string {
    const first_name_initial = user.first_name.charAt(0);
    const last_name_initial = user.last_name.charAt(0);
    return `${first_name_initial}${last_name_initial}`;
  }

  public util_member_get_role_display_name(role: string): string {
    if (role === 'owner') {
      return 'Руководитель';
    }
    if (role === 'lead') {
      return 'Лид';
    }
    if (role === 'member') {
      return 'Участник';
    }
  }

  public on_show_create_survey_relation_form(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_show_create_survey_relation_form`
    );
    this.uistate.survey_create_relation_form_shown = true;
  }

  public on_button_create_survey_relation(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_create_survey_relation`
    );
    this.uistate.survey_create_relation_button_disabled = true;
    const relation_data = {
      survey: this.selected_relation_selected_survey.uuid,
      team: this.team.uuid,
    };
    this.survey_team_relation_service.create(relation_data).subscribe(
      (response) => {
        const relation = response as SurveyTeamRelation;
        this.load_survey_relations();
        this.uistate.survey_create_relation_button_disabled = false;
        this.uistate.survey_create_relation_form_shown = false;
        this.selected_relation_selected_survey = null;
        this.logging_service.debug(
          `${this.constructor.name} created survey relation with uuid ${relation.uuid}`
        );
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to load survey relations`
        );
        this.alert_service.error(
          `Ошибка загрузки связей с опросами ${err.status}`
        );
      }
    );
  }

  public on_button_delete_survey_relation(relation: SurveyTeamRelation): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_delete_survey_relation`
    );
    this.uistate.survey_delete_relation_button_disabled = true;
    this.survey_team_relation_service.delete(relation.uuid).subscribe(
      (response) => {
        this.survey_relations = this.survey_relations.filter(
          (r) => r.uuid !== relation.uuid
        );
        this.uistate.survey_delete_relation_button_disabled = false;
        this.logging_service.debug(
          `${this.constructor.name} deleted survey relation with uuid ${relation.uuid}`
        );
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to delete survey relation`
        );
        this.alert_service.error(
          `Ошибка удаления связи с опросом: ${err.status}`
        );
      }
    );
  }

  public in_edit_mode(): boolean {
    return this.uistate.edit_mode;
  }

  private create_form() {
    this.team_edit_form = this.form_builder.group({
      team_name: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      team_description: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
      team_parent: ['', []],
      team_icon: ['', []],
      team_type: ['', []],
    });
  }

  public on_button_toggle_edit_mode() {
    this.uistate.edit_mode = true;
    this.team_edit_form.controls.team_name.setValue(this.team.name);
    this.team_edit_form.controls.team_description.setValue(
      this.team.description
    );
    this.team_edit_form.controls.team_parent.setValue(this.team.parent);
    this.team_edit_form.controls.team_icon.setValue(
      this.getTeamIconOption(this.team.icon_url)
    );
    this.team_edit_form.controls.team_type.setValue(this.team.team_type);
  }

  public reset_edit_mode() {
    this.uistate.edit_mode = false;
  }

  public save_edit_form() {
    if (this.team_edit_form.value.team_parent) {
      // check that we dont have a circular team hierarchy
      const selected_parent_uuid = this.team_edit_form.value.team_parent.uuid;
      let found_circle = false;

      const check_team_hierarchy_resursive = (
        current_depth,
        current_team_uuid
      ) => {
        if (current_depth > 50) {
          found_circle = true;
          return;
        }
        if (current_team_uuid === this.team.uuid) {
          const selected_parent = this.teams.find(
            (t) => t.uuid === selected_parent_uuid
          );
          check_team_hierarchy_resursive(
            current_depth + 1,
            selected_parent.uuid
          );
        } else {
          const current_team = this.teams.find(
            (t) => t.uuid === current_team_uuid
          );
          if (current_team.parent) {
            if (
              typeof current_team.parent === 'string' ||
              current_team.parent instanceof String
            ) {
              check_team_hierarchy_resursive(
                current_depth + 1,
                current_team.parent
              );
            } else {
              check_team_hierarchy_resursive(
                current_depth + 1,
                current_team.parent.uuid
              );
            }
          }
        }
      };

      check_team_hierarchy_resursive(0, this.team.uuid);

      if (found_circle) {
        this.alert_service.error(`Ошибка: иерархия команд замкнется в кольцо`);
        return;
      }
    }
    this.team_service
      .update(this.team.uuid, {
        name: this.team_edit_form.value.team_name,
        description: this.team_edit_form.value.team_description,
        parent: this.team_edit_form.value.team_parent
          ? this.team_edit_form.value.team_parent.uuid
          : null,
        icon_url: this.team_edit_form.value.team_icon?.url
          ? this.team_edit_form.value.team_icon.url
          : null,
        team_type: this.team_edit_form.value.team_type,
      })
      .subscribe(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} save new data for ${this.team.uuid}`
          );
          this.alert_service.error(`Сохранено`);
          this.team.name = this.team_edit_form.value.team_name;
          this.team.description = this.team_edit_form.value.team_description;
          this.team.parent = this.team_edit_form.value.team_parent;
          this.team.icon_url = this.team_edit_form.value.team_icon?.url;
          this.team.team_type = this.team_edit_form.value.team_type;
          this.reset_edit_mode();
        },
        (err) => {
          this.logging_service.debug(
            `${this.constructor.name} failed to save edited data for ${this.team.uuid}`
          );
          this.alert_service.error(`Ошибка сохранения ${err.status}`);
          this.reset_edit_mode();
        }
      );
  }

  startCalculateTeamMembersStatusTask(): void {
    this._teamHttpService
      .runCalculateTeamMembersStatus(this.team_uuid)
      .subscribe(
        () => {
          this.alert_service.success(`Задача по пересчету статусов запущена`);
        },
        () => {
          this.alert_service.error(`Ошибка запуска задачи`);
        }
      );
  }

  startUpdateTeamMatrixCacheTask(): void {
    this._teamHttpService.runUpdateTeamMatrixCache(this.team_uuid).subscribe(
      () => {
        this.alert_service.success(`Задача по пересчету матрицы запущена`);
      },
      () => {
        this.alert_service.error(`Ошибка запуска задачи`);
      }
    );
  }

  getTeamIconOption(iconUrl: string): TeamIconsOption {
    return this.teamIconsOptions.find((option) => option.url === iconUrl);
  }

  getTeamParent(team: Team): Team {
    return team.parent as Team;
  }

  getVerifiedText(team: Team): string {
    if (team.is_verified) {
      const user = team.verified_by as User;
      if (user) return `Верифицирована ${user?.last_name} ${user?.first_name}`;
      return 'Верифицирована';
    }
    return 'Не верифицирована';
  }

  deleteMemberStatus(statusUuid: string): void {
    this._teamMemberStatusHttpService.delete(statusUuid).subscribe(() => {
      this.teamMembersStatus.state.items =
        this.teamMembersStatus.state.items.filter(
          (status) => status.uuid !== statusUuid
        );
    });
  }

  deleteAllMemberStatus(): void {
    this._teamMemberStatusHttpService
      .deleteAllMemberStatus(this.team_uuid)
      .subscribe(() => {
        this._loadTeamMembersStatus();
      });
  }

  deleteTeamInvitation(invitationUuid: string): void {
    this._teamEmailInvitationHttpService
      .delete(invitationUuid)
      .subscribe(() => {
        this._loadTeamInvitations();
      });
  }

  deleteTeamJoinRequest(requestUuid: string): void {
    this._teamJoinRequestHttpService.delete(requestUuid).subscribe(() => {
      this._loadTeamJoinRequests();
    });
  }
}
