<mat-dialog-content>
  <div class="imported-data">
    <mat-card>
      <h4>{{ getTitleLabel() }}</h4>
      <form>
        <div [formGroup]="form">
          <div>
            <mat-form-field [style.width.rem]="30">
              <input
                matInput
                placeholder="Название"
                type="text"
                formControlName="name"
                required
              />
            </mat-form-field>
          </div>
        </div>
      </form>
    </mat-card>
  </div>
</mat-dialog-content>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button class="dialog-button" [mat-dialog-close]="true" mat-raised-button>
    Отмена
  </button>
  <button
    class="dialog-button"
    mat-raised-button
    [disabled]="form.invalid"
    (click)="close()"
  >
    {{ data.isEdit ? 'Обновить' : 'Создать' }}
  </button>
</div>
