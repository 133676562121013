<div fxLayout="row">
    <div fxFlex="80%" fxFlexOffset="10%">
        <div class="app-preloader-progressbar-container">
            <!-- <div class="app-preloader-progressbar-text">
                Загрузка...
            </div> -->
            <div class="app-preloader-progressbar">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
    </div>
</div>
