import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import * as d3 from 'd3';

import { LoggingService } from 'src/app/services/logging.service';

import { SurveyQuestion } from 'src/app/models/survey/survey-question';


@Component({
  selector: 'app-survey-tree-visual-pan',
  templateUrl: './survey-tree-visual-pan.component.html',
  styleUrls: ['./survey-tree-visual-pan.component.css']
})
export class SurveyTreeVisualPanComponent implements OnInit, AfterViewInit {

  @Input() visual_control_subject: BehaviorSubject<any>;
  @Input() survey_questions: SurveyQuestion[];

  private current_question: SurveyQuestion;

  private svg_container_class = '.app-surveys-survey-tree-visual-pan-svg-container';
  private svg_el: any;
  private svg_g: any;
  private svg_root_node: any;
  private svg_zoom: any;
  private svg_treemap: any;
  private svg_tree_data: any;

  private cfg = {
    transition: {
      duration: 0
    },
    zoom: {
      scale_min: 1,
      scale_max: 1,
      duration: 1500,
    },
    svg: {
      node: {
        width: 150,
        height: 30,
        x_padding: 30,
        y_padding: 20,
        radius: 3,
        radius_dummy: 2,
      },
      link: {
        stroke: {
          width: 2
        }
      }
    },
    colors: {
      node: {
        fill: {
          current: '#f00',
          other: '#eee'
        },
        stroke: {
          current: '#f00',
          dummy: '#ccc',
          thing: '#444'
        },
        text: {
          thing: '#222',
          dummy: '#888',
        }
      },
      link: {
        stroke: {
          default: '#ccc'
        }
      }
    }
  };

  constructor(private logging_service: LoggingService) { }

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.svg_tree_data = this.build_tree_data();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.svg_initialize();
      this.visual_control_subject.subscribe(control_command => {this.process_control_command(control_command)});
    }, 100);
  }

  private get_html_container_element_dimensions(): number[] {
    const width = document.querySelector(this.svg_container_class).getBoundingClientRect().width;
    const height = document.querySelector(this.svg_container_class).getBoundingClientRect().height;
    return [width, height]
  }

  private process_control_command(control_command: any): void {
    this.logging_service.debug(`${this.constructor.name} received command: ${control_command.command}`);
    if (control_command.command === 'set_current') {
      this.process_control_command_set_current_question(control_command.question);
    }
  }

  private process_control_command_set_current_question(question: SurveyQuestion): void {
    this.current_question = question;
    this.svg_tree_update(this.svg_root_node);
    const node = this.find_node_by_question(this.current_question);
    this.svg_pan_and_zoom_to_node(node);
  }

  private find_node_by_question(question: SurveyQuestion): any {
    const find_node_recursive = (current_node: any) => {
      if (current_node.data.uuid === question.uuid) return current_node;
      if (current_node.children) {
        for (const child of current_node.children) {
          const res = find_node_recursive(child);
          if (res) {
            return res;
          }
        }
      }
      if (current_node._children) {
        for (const child of current_node._children) {
          const res = find_node_recursive(child);
          if (res) {
            return res;
          }
        }
      }
    };
    const q = find_node_recursive(this.svg_root_node);
    return q;
  }

  private build_tree_data(): void {
    this.logging_service.debug(`${this.constructor.name} building tree data`);

    const root_questions = this.survey_questions.filter(q => q.depth === 0);
    if (root_questions.length > 1) {
      this.logging_service.error(`${this.constructor.name} multiple top level questions`);
      this.svg_tree_data = {};
      return;
    }
    const root_question = root_questions[0];

    const treenodes = [];
    this.survey_questions.forEach(q => {
      const treenode = {
        id: q.uuid,
        uuid: q.uuid,
        related_thing: q.related_thing,
        value: 0,
        question_type: q.question_type,
        name: q.title,
        children: [],
      };
      treenodes.push(treenode)
    });
    treenodes.forEach(treenode => {
      const child_questions = this.survey_questions.filter(q => {
        if ((q.parent_question) && q.parent_question === treenode.uuid) {
          return true
        }
        if (q.parent_question && ((q.parent_question as SurveyQuestion).uuid === treenode.uuid)) {
          return true;
        }
      });
      const child_treenodes = [];
      child_questions.forEach(q => {
        const child_treenode = treenodes.find(n => n.uuid === q.uuid);
        treenode.children.push(child_treenode);
      });
      child_treenodes.sort((a, b) => (a.order_number > b.order_number) ? 1 : -1);

    });
    const root_treenode = treenodes.find(n => n.uuid === root_question.uuid);
    return root_treenode;
  }

  private tree_get_node_fill_color(d): string {
    if (this.current_question && this.current_question.uuid === d.data.uuid) {
      return this.cfg.colors.node.fill.current;
    } else {
      return this.cfg.colors.node.fill.other;
    }
  }

  private tree_get_node_stroke_color(d): string {
    if (this.current_question && this.current_question.uuid === d.data.uuid) {
      return this.cfg.colors.node.stroke.current;
    } else {
      if (d.data.question_type === 'dummy_node') {
        return this.cfg.colors.node.stroke.dummy;
      } else {
        return this.cfg.colors.node.stroke.thing;
      }
    }
  }

  private tree_get_node_circle_radius(d): number {
    if (d.data.question_type === 'dummy_node') {
      return this.cfg.svg.node.radius_dummy;
    } else {
      return this.cfg.svg.node.radius;
    }
  }

  private tree_get_node_text_fill_color(d): string {
    if (d.data.question_type === 'dummy_node') {
      return this.cfg.colors.node.text.dummy;
    } else {
      return this.cfg.colors.node.text.thing;
    }
  }

  private tree_get_node_text(d): string {
    if (d.data.question_type === 'dummy_node') {
      return d.data.name;
    } else {
      return d.data.name;
    }
  }

  private tree_get_link_stroke_width(d): number {
    return this.cfg.svg.link.stroke.width;
  }

  private tree_get_link_stroke_color(d): string {
    return this.cfg.colors.link.stroke.default;
  }

  public tree_collapse_recursive(datum: any): void {
    const _collapse_recursive = (d) => {
      if (d.children) {
        d._children = d.children;
        d._children.forEach(_collapse_recursive);
        d.children = null;
      }
    }
    _collapse_recursive(datum);
  }

  public tree_uncollapse_recursive(datum: any): void {
    console.log(datum);
    const _uncollapse_recursive = (d) => {
      if (d._children) {
        d.children = d._children;
        d._children = null;
      }
      if (d.parent) {
        _uncollapse_recursive(d.parent);
      }
    }
    _uncollapse_recursive(datum);
  }

  private svg_pan_and_zoom_to_node(node): void {
    // this.tree_uncollapse_recursive(node);
    this.svg_tree_update(node);
    const [width, height] = this.get_html_container_element_dimensions();
    const zoom_scale = this.cfg.zoom.scale_min;
    this.svg_g.transition().duration(this.cfg.zoom.duration).call(
      this.svg_zoom.transform,
      d3.zoomIdentity.translate(width / 2 * zoom_scale, height / 2 * zoom_scale)
      .scale(zoom_scale).translate(-node.y, -node.x)
    );
  }

  private svg_initialize(): void {
    this.logging_service.debug(`${this.constructor.name} svg initialize`);

    const zoomed = () => this.svg_g.attr('transform', d3.event.transform);
    this.svg_zoom = d3.zoom().scaleExtent([this.cfg.zoom.scale_min, this.cfg.zoom.scale_max]).on('zoom', zoomed);

    const parent = d3.select(this.svg_container_class);
    parent.select('svg').remove();

    const [width, height] = this.get_html_container_element_dimensions();

    this.svg_el = parent.append('svg').attr('width', width).attr('height', height)
      .call(this.svg_zoom)
      .on('wheel.zoom', null)
      .on('mousedown.zoom', null)
      .on('touchstart.zoom', null)
      .on('touchmove.zoom', null)
      .on('touchend.zoom', null)
      .on('click', null)
      .on('dblclick.zoom', null);

    this.svg_g = this.svg_el.append('g');

    this.svg_treemap = d3.tree().nodeSize([
      this.cfg.svg.node.height + this.cfg.svg.node.y_padding,
      this.cfg.svg.node.width + this.cfg.svg.node.x_padding
    ]).separation((a, b) => {
      return a.parent === b.parent ? 1 : 1.25;
    });

    this.svg_root_node = d3.hierarchy(this.svg_tree_data, (d) => d.children);
    this.svg_root_node.x0 = height / 2;
    this.svg_root_node.y0 = 0;

    this.svg_tree_update(this.svg_root_node);

    // this.svg_root_node.children.forEach(this.tree_collapse_recursive);
  }

  private svg_tree_diagonal(s: any, d: any): string {
    const path = `M ${s.y} ${s.x}
            C ${(s.y + d.y) / 2} ${s.x},
              ${(s.y + d.y) / 2} ${d.x},
              ${d.y} ${d.x}`
    return path
  }

  public svg_tree_update(source: any): void {
    this.logging_service.debug(`${this.constructor.name} svg tree update`);
    const tree_data = this.svg_treemap(this.svg_root_node);
    const nodes = tree_data.descendants();
    const links = tree_data.descendants().slice(1);

    const node_selector = this.svg_g.selectAll('g.app-surveys-survey-tree-visual-pan-node')
      .data(nodes, (d) => d.id || (d.id = d.data.uuid));

    // Enter any new modes at the parent's previous position.
    const node_enter = node_selector.enter().append('g')
      .attr('class', 'app-surveys-survey-tree-visual-pan-node')
      .attr('transform', (d) => 'translate(' + source.y0 + ',' + source.x0 + ')');

    // Add circle for the nodes
    node_enter.append('circle')
      .attr('class', 'app-surveys-survey-tree-visual-pan-node')
      .attr('r', 1e-6)
      .style('fill', (d) => this.tree_get_node_fill_color(d))
      .style('stroke', (d) => this.tree_get_node_stroke_color(d));

    // Add label for the nodes
    const get_text_center_in_pixels = (d) => d.data.name.length * 3;
    const get_text_x = (d) => {
      if (d.children || d._children) return get_text_center_in_pixels(d);
      return 13;
    };
    node_enter.append('text')
      .attr('dy', '.35em')
      // .attr('x', (d) => d.children || d._children ? 20 : 13)
      .attr('x', (d) => get_text_x(d))
      .attr('y', (d) => d.children || d._children ? -10 : 0)
      .attr('text-anchor', (d) => d.children || d._children ? 'end' : 'start')
      .text((d) => this.tree_get_node_text(d))
      .style('fill', (d) => this.tree_get_node_text_fill_color(d));

    // Update nodes
    const node_update = node_enter.merge(node_selector);

    // Transition to the proper position for the node
    node_update.transition()
      .duration(this.cfg.transition.duration)
      .attr('transform', (d) => 'translate(' + d.y + ',' + d.x + ')');

    // Update the node attributes and style
    node_update.select('circle.app-surveys-survey-tree-visual-pan-node')
      .attr('r', (d) => this.tree_get_node_circle_radius(d))
      .style('stroke', (d) => this.tree_get_node_stroke_color(d))
      .style('fill', (d) => this.tree_get_node_fill_color(d));

    node_update.select('text')
      .transition()
      .duration(this.cfg.transition.duration)
      .text((d) => this.tree_get_node_text(d))
      .style('font', '11px sans-serif')
      .style('fill', (d) => this.tree_get_node_text_fill_color(d));

    // Remove any exiting nodes
    const node_exit = node_selector.exit().transition()
      .duration(this.cfg.transition.duration)
      .attr('transform', (d) => 'translate(' + source.y + ',' + source.x + ')')
      .remove();

    // On exit reduce the node circles size to 0
    node_exit.select('circle')
      .attr('r', 1e-6);

    // On exit reduce the opacity of text labels
    node_exit.select('text')
      .style('fill-opacity', 1e-6);

    // Update the links
    const link_selector = this.svg_g.selectAll('path.app-surveys-survey-tree-visual-pan-link')
      .data(links, (d) => d.id)
      .style('stroke-width', (d) => `${this.tree_get_link_stroke_width(d)}px`);

    // Enter any new links at the parent's previous position.
    const link_enter = link_selector.enter().insert('path', 'g')
      .attr('class', 'app-surveys-survey-tree-visual-pan-link')
      .attr('d', (d) => {
        const o = {x: source.x0, y: source.y0}
        return this.svg_tree_diagonal(o, o)
      })
      .style('fill', 'none')
      .style('stroke', (d) => this.tree_get_link_stroke_color(d))
      .style('stroke-width', (d) => `${this.tree_get_link_stroke_width(d)}px`);

    // UPDATE
    const link_update = link_enter.merge(link_selector);

    // Transition back to the parent element position
    link_update.transition()
      .duration(this.cfg.transition.duration)
      .style('stroke', (d) => this.tree_get_link_stroke_color(d))
      .attr('d', (d) => this.svg_tree_diagonal(d, d.parent));

    // Remove any exiting links
    const link_exit = link_selector.exit().transition()
      .duration(this.cfg.transition.duration)
      .attr('d', (d) => {
        const o = {x: source.x, y: source.y}
        return this.svg_tree_diagonal(o, o)
      })
      .style('stroke-width', (d) => `${this.tree_get_link_stroke_width(d)}px`)
      .remove();

    // Store the old positions for transition.
    nodes.forEach((d) => {
      d.x0 = d.x;
      d.y0 = d.y;
    });

  }

}
