import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewSetHttpService } from './rest/ViewSetHttpService';
import { Review } from '@models/reviews/review';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReviewHttpService extends ViewSetHttpService<Review> {
  http: HttpClient;
  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }
  public viewSetUrl = 'reviews/review';

  public close(uuid: string): Observable<any> {
    return this.http.post(`${this.getUrl()}/${uuid}/close`, {});
  }

  public createMultiple(data: Partial<Review>[]): Observable<any> {
    return this.http.post(`${this.getUrl()}/create_multiple`, data, {
      responseType: 'json',
    });
  }

  public getDownloadReviewExcelLink(review_uuid: string): string {
    return `${this.baseUrl}reviews/overview/${review_uuid}/`;
  }
}
