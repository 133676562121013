import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const serverErrorsRegex = new RegExp(`404 Not Found`, 'mi');

if (environment.production) {
  enableProdMode();
}

if (environment.sentry.is_active) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    environment: environment.sentry.environment,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: environment.sentry.tracingOrigins,
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    // ignoreErrors: [serverErrorsRegex],
    tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
