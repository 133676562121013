<div class="dialog-container" fxLayout="column">
  <div class="title">Добавить участника</div>
  <div class="hint">
    Вы можете выбрать существующего пользователя или же ввести email для
    отправки приглашения в команду
  </div>
  <form [formGroup]="form">
    <div class="input-select">
      <mat-form-field floatLabel="never" class="user-input-field">
        <input
          matInput
          type="text"
          placeholder="Введите имя и фамилию или email"
          [formControl]="userControl"
          [matAutocomplete]="auto"
          (keyup)="usersFilter()"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let user of users.state.items"
            [value]="user.email"
          >
            {{ user.first_name }} {{ user.last_name }} – {{ user.email }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="2rem" class="speciality-fields-group">
      <ng-select
        fxFlex
        class="search-field"
        [items]="data.teamSpecialities"
        (change)="getSpecialityGrades($event)"
        [multiple]="false"
        [closeOnSelect]="true"
        [clearable]="false"
        placeholder="Специальность участника"
        formControlName="speciality"
        bindLabel="name"
      >
      </ng-select>
      <ng-select
        fxFlex
        class="search-field"
        [items]="specialityGrades ? specialityGrades.state.items : []"
        [multiple]="false"
        [closeOnSelect]="true"
        [clearable]="false"
        placeholder="Уровень участника"
        formControlName="grade"
        bindLabel="name"
      >
      </ng-select>
    </div>
    <div>
      <ng-select
        class="search-field"
        [items]="roleOptions"
        [multiple]="false"
        [closeOnSelect]="true"
        [clearable]="false"
        placeholder="Роль участника"
        formControlName="role"
        bindLabel="label"
      >
      </ng-select>
    </div>
  </form>

  <div
    class="dialog-actions-container"
    fxFlex
    fxLayout="column"
    fxLayoutAlign="end end"
  >
    <div mat-dialog-actions fxLayoutAlign="end center">
      <button
        class="dialog-reject-button"
        [mat-dialog-close]="true"
        mat-raised-button
      >
        Отменить
      </button>
      <button
        class="dialog-accept-button"
        [mat-dialog-close]="true"
        [disabled]="form.invalid"
        mat-raised-button
        (click)="close()"
      >
        Добавить
      </button>
    </div>
  </div>
</div>
