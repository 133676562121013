import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import * as d3 from 'd3';

import { LoggingService } from 'src/app/services/logging.service';

import { SpecialityDomainClaim } from 'src/app/models/specialities/speciality-domain-claim';
import { SpecialityCompetenceClaim } from 'src/app/models/specialities/speciality-competence-claim';
import { SpecialityGrade } from 'src/app/models/specialities/speciality-grade';
import { sortByField } from 'src/app/utils/sort';
import { ThingLevel } from '@models/ontology/thing-level';

@Component({
  selector: 'app-speciality-radar-chart',
  templateUrl: './speciality-radar-chart.component.html',
  styleUrls: ['./speciality-radar-chart.component.css'],
})
export class SpecialityRadarChartComponent implements OnInit, OnChanges {
  @Input() html_element_class: string;
  @Input() grades: SpecialityGrade[];
  @Input() domain_claims: SpecialityDomainClaim[];
  @Input() competence_claims: SpecialityCompetenceClaim<ThingLevel>[];
  @Input() user_competencies: any[];

  private axes_domain_claims: SpecialityDomainClaim[] = [];

  private grades_data: any[] = [];
  private user_data: any[] = [];

  private axis_domain_max_value: Map<string, number>;

  private parent: any;
  private svg: any;
  private g: any;

  private axis_names: string[];
  private axis_count: number;
  private radius_outermost_circle: number;
  private radius_scale: any;
  private angle_slice: number;

  private axis_grid: any;

  showGrades = false;

  constructor(private logging_service: LoggingService) {}

  ngOnInit(): void {
    this.logging_service.debug(
      `${this.constructor.name} init ${this.html_element_class}`
    );
    this.select_domain_claims_for_axes();
    this.axis_domain_max_value = new Map();
    this.grades_data = this.get_grade_data();

    if (this.user_competencies.length) {
      this.user_data = this.get_user_data(false);
    }

    this.set_constants();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.user_competencies.length) {
      this.user_data = this.get_user_data(false);
    }
    setTimeout(() => this.draw_chart(), 100);
  }

  private get cfg(): any {
    return {
      w: 300, // Width of the circle
      h: 300, // Height of the circle
      margin: { top: 70, right: 100, bottom: 70, left: 100 }, // The margins of the SVG
      levels: 3, // How many levels or inner circles should there be drawn
      labelFactor: 1.25, // How much farther than the radius of the outer circle should the labels be placed
      wrap_width: 60, // The number of pixels after which a label needs to be given a new line
      opacity_area: 0.75, // The opacity of the area of the blob
      dot_radius: 4, // The size of the colored circles of each blog
      strokeWidth: 1.5, // The width of the stroke around each blob
      round_strokes: true, // If true the area and stroke will follow a round path (cardinal-closed)
      // color: d3.scaleOrdinal(d3.schemeCategory10), // Color function,
      // color: d3.scaleOrdinal().range(['#6e43ba', '#5e27bf', '#985dff']),
      format: '.2%',
      unit: '%',
      legend: false,
      title: false,

      circular_grid: {
        fill: '#fff',
        stroke: '#eee',
        opacity: 0.1,
      },
      axes: {
        stroke: '#aaa',
      },
      grades: {
        color: d3.scaleLinear([0, this.grades.length], ['#aaa', '#555']),
        opacity_area: 0.0,
        dot_radius: 2,
        stroke_width: 1,
        tooltip_color: '#444',
        tooltip_size: '0.7rem',
      },
      user: {
        opacity_area: 0.1,
        dot_radius: 2,
        stroke_width: 1,
        tooltip_color: '#444',
        tooltip_size: '0.7rem',
      },
    };
  }

  private get max_data_value(): number {
    // 100%
    return 110;
  }

  private select_domain_claims_for_axes(): void {
    const top_level_threshold = 8;
    const top_level_domain_claims = this.domain_claims.filter(
      (domain_claim) => domain_claim.domain.parent_domain === null
    );
    const top_level_length = top_level_domain_claims.length;
    if (top_level_domain_claims.length > top_level_threshold) {
      this.logging_service.debug(
        `${this.constructor.name} ${top_level_length} > ${top_level_threshold} - only top level`
      );
      this.axes_domain_claims = top_level_domain_claims;
      return;
    }

    sortByField('order_number')(top_level_domain_claims);

    // check, that we can go deeper for every toplevel
    top_level_domain_claims.forEach((domain_claim) => {
      // if we can find competence_claim, that is related to this domain, we cant go deeper
      const domain_uuid = domain_claim.domain.uuid;
      const competence_claim_for_domain = this.competence_claims.find(
        (c_claim) => c_claim.thing.domain === domain_uuid
      );
      if (!competence_claim_for_domain) {
        // there is no competence claims explisitly defined for this domain, can add children domains
        const domain_claim_children = this.domain_claims.filter(
          (d_claim) => d_claim.domain.parent_domain === domain_uuid
        );
        sortByField('order_number')(domain_claim_children);
        domain_claim_children.forEach((d) => this.axes_domain_claims.push(d));
      } else {
        // there is dome competence claims for this top level, cant skip it
        this.axes_domain_claims.push(domain_claim);
      }
    });
  }

  private get_grade_data(): any[] {
    const get_grade_expected_level_in_domain = (
      grade,
      domain_claim
    ): number => {
      // get competence claims for current domain if any
      //   here we can find multiple things and multiple claims for one thing for different grades
      // build map with thing: [claims]
      // for each thing look for our grade or max of grades lower than ours
      let expected_level_sum = 0;
      const domain_uuid = domain_claim.domain.uuid;
      const claims_in_this_domain = this.competence_claims.filter(
        (competence_claim) => competence_claim.thing.domain === domain_uuid
      );
      if (claims_in_this_domain.length === 0) {
        return expected_level_sum;
      }
      const thing_to_claims_map = new Map();
      claims_in_this_domain.forEach((competence_claim) => {
        if (!thing_to_claims_map.has(competence_claim.thing.uuid)) {
          thing_to_claims_map.set(competence_claim.thing.uuid, [
            competence_claim,
          ]);
        } else {
          thing_to_claims_map
            .get(competence_claim.thing.uuid)
            .push(competence_claim);
        }
      });
      for (const [thing_uuid, claims] of thing_to_claims_map) {
        // check for claim with our specific grade
        const claim_for_our_grade = claims.find(
          (claim) => claim.grade.uuid === grade.uuid
        );
        if (claim_for_our_grade) {
          expected_level_sum =
            expected_level_sum + claim_for_our_grade.thing_level.order_number;
        } else {
          // if we can find claim with grade lower than ours we use it insted, but max of those
          let max_expected_level_with_lower_grade = 0;
          claims.forEach((claim) => {
            if (claim.grade.order < grade.order) {
              if (
                claim.thing_level.order_number >
                max_expected_level_with_lower_grade
              ) {
                max_expected_level_with_lower_grade =
                  claim.thing_level.order_number;
              }
            }
          });
          expected_level_sum =
            expected_level_sum + max_expected_level_with_lower_grade;
        }
      }
      return expected_level_sum;
    };

    const get_domain_expected_sum_recursive = (
      root_domain_uuid,
      grade,
      input_domain_claim
    ): void => {
      const prior_sum = value_map.get(grade.uuid).get(root_domain_uuid);
      const expected_here = get_grade_expected_level_in_domain(
        grade,
        input_domain_claim
      );
      const current_sum = prior_sum + expected_here;
      value_map.get(grade.uuid).set(root_domain_uuid, current_sum);
      const children_domain_claims = this.domain_claims.filter(
        (d) => d.domain.parent_domain === input_domain_claim.domain.uuid
      );
      children_domain_claims.forEach((children_domain_claim) => {
        get_domain_expected_sum_recursive(
          root_domain_uuid,
          grade,
          children_domain_claim
        );
      });
    };

    const value_map = new Map();
    this.grades.forEach((grade) => {
      value_map.set(grade.uuid, new Map());
      this.axes_domain_claims.forEach((domain_claim) => {
        const root_domain_uuid = domain_claim.domain.uuid;
        value_map.get(grade.uuid).set(root_domain_uuid, 0);
        get_domain_expected_sum_recursive(
          root_domain_uuid,
          grade,
          domain_claim
        );
      });
    });

    // for percentage
    for (const [grade_uuid, domains_map] of value_map) {
      for (const [domain_uuid, value] of domains_map) {
        if (this.axis_domain_max_value.has(domain_uuid)) {
          if (this.axis_domain_max_value.get(domain_uuid) < value) {
            this.axis_domain_max_value.set(domain_uuid, value);
          }
        } else {
          this.axis_domain_max_value.set(domain_uuid, value);
        }
      }
    }

    const grades_data = [];
    this.grades.forEach((grade) => {
      const domains_value_map = value_map.get(grade.uuid);
      const axes_data = [];
      this.axes_domain_claims.forEach((domain_claim) => {
        const domain_value = domains_value_map.get(domain_claim.domain.uuid);
        let max_domain_value = this.axis_domain_max_value.get(
          domain_claim.domain.uuid
        );
        if (max_domain_value === 0) {
          max_domain_value = 1;
        }
        const value_percentage = Math.round(
          (100 / max_domain_value) * domain_value
        );
        axes_data.push({
          name: domain_claim.domain.name,
          title: grade.name,
          value: value_percentage,
          value_raw: domain_value,
        });
      });
      grades_data.push({ name: grade.name, axes: axes_data });
    });
    return grades_data;
  }

  private get_user_data(verbose: boolean): any[] {
    // for each axis domain must be sum
    // of all user competence levels in this and child domains
    // but not for all competencies, only for claimed ones

    const get_user_level_in_domain = (domain_claim): number => {
      // find competence_claims in this domain
      // gather things from competence claims
      // for every thing search for it in user competencies and if any, get it level
      let competence_level_sum = 0;
      const competence_claims_for_domain = this.competence_claims.filter(
        (cclaim) => cclaim.thing.domain === domain_claim.domain.uuid
      );
      const thing_map = new Map();
      competence_claims_for_domain.forEach((cclaim) => {
        thing_map.set(cclaim.thing.uuid, 0);
      });
      this.user_competencies.forEach((user_competence) => {
        if (thing_map.has(user_competence.thing.uuid)) {
          // here user level may be more, than expected level and this causes chart area overflow
          // thing_map.set(user_competence.thing.uuid, user_competence.level);
          const competence_claims = competence_claims_for_domain.filter(
            (cclaim) => cclaim.thing.uuid === user_competence.thing.uuid
          );
          let competence_claim_with_max_expecations = null;
          competence_claim_with_max_expecations = competence_claims[0];
          competence_claims.forEach((cclaim) => {
            if (
              cclaim.thing_level.order_number >
              competence_claim_with_max_expecations.thing_level.order_number
            ) {
              competence_claim_with_max_expecations = cclaim;
            }
          });
          if (
            user_competence.level >=
            competence_claim_with_max_expecations.expected_level
          ) {
            thing_map.set(
              user_competence.thing.uuid,
              competence_claim_with_max_expecations.thing_level.order_number
            );
            if (verbose) {
              console.log(
                `get_user_level_in_domain ${domain_claim.domain.name} | ${user_competence.thing.name} | set max ${competence_claim_with_max_expecations.expected_level}, user: ${user_competence.level}`
              );
            }
          } else {
            thing_map.set(user_competence.thing.uuid, user_competence.level);
            if (verbose) {
              console.log(
                `get_user_level_in_domain ${domain_claim.domain.name} | ${user_competence.thing.name} | set user ${user_competence.level}, max expected ${competence_claim_with_max_expecations.expected_level}`
              );
            }
          }
        }
      });

      for (const [thing_uuid, thing_level] of thing_map) {
        competence_level_sum = competence_level_sum + thing_level;
      }
      return competence_level_sum;
    };

    const get_domain_level_sum_recursive = (
      root_domain_uuid,
      input_domain_claim
    ): void => {
      const prior_sum = value_map.get(root_domain_uuid);
      const user_level_here = get_user_level_in_domain(input_domain_claim);
      const current_sum = prior_sum + user_level_here;
      // here user may have more competencies, than domain required, mark this fact for all 'non required' case
      user_expectations_overflow_map.set(input_domain_claim.domain.uuid, 1);

      if (verbose) {
        console.log(
          `get_domain_level_sum_recursive ${root_domain_uuid} ${input_domain_claim.domain.name} | prior_sum: ${prior_sum}, here: ${user_level_here}, sum: ${current_sum}`
        );
      }
      value_map.set(root_domain_uuid, current_sum);
      const children_domain_claims = this.domain_claims.filter(
        (d) => d.domain.parent_domain === input_domain_claim.domain.uuid
      );
      children_domain_claims.forEach((children_domain_claim) => {
        get_domain_level_sum_recursive(root_domain_uuid, children_domain_claim);
      });
    };

    const value_map = new Map();
    const user_expectations_overflow_map = new Map();
    this.axes_domain_claims.forEach((domain_claim) => {
      const root_domain_uuid = domain_claim.domain.uuid;
      value_map.set(root_domain_uuid, 0);
      get_domain_level_sum_recursive(root_domain_uuid, domain_claim);
      if (verbose) {
        const domain_value = value_map.get(root_domain_uuid);
        console.log(
          `User domain ${domain_claim.domain.name} value: ${domain_value}`
        );
      }
    });

    const axes_data = [];
    this.axes_domain_claims.forEach((domain_claim) => {
      let domain_value = value_map.get(domain_claim.domain.uuid);
      let max_domain_value = this.axis_domain_max_value.get(
        domain_claim.domain.uuid
      );
      if (max_domain_value === 0) {
        max_domain_value = 1;
        // we marked all 'non required' case, when user has some competencies, show that
        if (user_expectations_overflow_map.has(domain_claim.domain.uuid)) {
          domain_value = 1;
        }
      }
      let value_percentage = Math.round(
        (100 / max_domain_value) * domain_value
      );
      // if value_percentage = 0 graph looks ugly
      if (value_percentage === 0) value_percentage = 3;
      axes_data.push({
        name: domain_claim.domain.name,
        title: 'Пользователь',
        value: value_percentage,
        value_raw: domain_value,
      });
    });
    return [{ name: 'user', axes: axes_data }];
  }

  private set_constants(): void {
    this.axis_names = this.axes_domain_claims.map(
      (domain_claim, _) => domain_claim.domain.name
    );
    this.axis_count = this.axes_domain_claims.length;
    this.radius_outermost_circle = Math.min(this.cfg.w / 2, this.cfg.h / 2);
    this.angle_slice = (Math.PI * 2) / this.axis_count; // The width in radians of each slice
    this.radius_scale = d3
      .scaleLinear()
      .range([0, this.radius_outermost_circle])
      .domain([0, this.max_data_value]);
  }

  public onDebug(): void {
    console.log(`Grades data:`);
    console.log(this.grades_data);
    console.log(`User data cached:`);
    console.log(this.user_data);
    const user_data = this.get_user_data(true);
    console.log(user_data);
  }

  private draw_chart(): void {
    this.logging_service.debug(`${this.constructor.name} draw chart`);
    this.svg_init();
    this.draw_circular_grid();
    this.draw_axes();
    if (this.showGrades) {
      this.draw_grades();
    }
    this.draw_user();
  }

  private svg_init(): void {
    this.parent = d3.select(`.${this.html_element_class}`);
    this.parent.select('svg').remove();
    this.svg = this.parent
      .append('svg')
      .attr('width', this.cfg.w + this.cfg.margin.left + this.cfg.margin.right)
      .attr('height', this.cfg.h + this.cfg.margin.top + this.cfg.margin.bottom)
      .attr('class', 'app-common-speciality-radar-chart');

    this.g = this.svg
      .append('g')
      .attr(
        'transform',
        'translate(' +
          (this.cfg.w / 2 + this.cfg.margin.left) +
          ',' +
          (this.cfg.h / 2 + this.cfg.margin.top) +
          ')'
      );

    // Filter for the outside glow
    const filter = this.g.append('defs').append('filter').attr('id', 'glow');
    filter
      .append('feGaussianBlur')
      .attr('stdDeviation', '2.5')
      .attr('result', 'coloredBlur');
    const feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'coloredBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
  }

  private draw_circular_grid(): void {
    this.axis_grid = this.g.append('g').attr('class', 'axisWrapper');

    // Draw the background circles
    this.axis_grid
      .selectAll('.levels')
      .data(d3.range(1, this.cfg.levels + 1).reverse())
      .enter()
      .append('circle')
      .attr('class', 'grid-circle')
      .attr('r', (d) => (this.radius_outermost_circle / this.cfg.levels) * d)
      .style('fill', this.cfg.circular_grid.fill)
      .style('stroke', this.cfg.circular_grid.stroke)
      .style('fill-opacity', this.cfg.circular_grid.opacity)
      .style('filter', 'url(#glow)');
  }

  private draw_axes(): void {
    const axis = this.axis_grid
      .selectAll('.axis')
      .data(this.axis_names)
      .enter()
      .append('g')
      .attr('class', 'axis');

    // Append the lines
    axis
      .append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr(
        'x2',
        (d, i) =>
          this.radius_scale(this.max_data_value * 1.1) *
          Math.cos(this.angle_slice * i - Math.PI / 2)
      )
      .attr(
        'y2',
        (d, i) =>
          this.radius_scale(this.max_data_value * 1.1) *
          Math.sin(this.angle_slice * i - Math.PI / 2)
      )
      .attr('class', 'line')
      .style('stroke', this.cfg.axes.stroke)
      .style('stroke-width', '2px');

    // Append the labels at each axis
    axis
      .append('text')
      .attr('class', 'legend')
      .style('font-size', '11px')
      .attr('text-anchor', 'middle')
      .attr('dy', '0em')
      .attr(
        'x',
        (d, i) =>
          this.radius_scale(this.max_data_value * this.cfg.labelFactor) *
          Math.cos(this.angle_slice * i - Math.PI / 2)
      )
      .attr(
        'y',
        (d, i) =>
          this.radius_scale(this.max_data_value * this.cfg.labelFactor) *
          Math.sin(this.angle_slice * i - Math.PI / 2)
      )
      .text((d) => d as any)
      .call(this.wrap_text, this.cfg.wrap_width);
  }

  showHideGrades(show: boolean): void {
    if (show) {
      this.draw_grades();
    } else {
      this.g.selectAll('.radar-wrapper').remove();
    }
  }

  private draw_grades(): void {
    const radar_line = d3
      .radialLine()
      .curve(d3.curveLinearClosed)
      .radius((d) => this.radius_scale((d as any).value))
      .angle((d, i) => i * this.angle_slice);

    if (this.cfg.round_strokes) {
      radar_line.curve(d3.curveCardinalClosed);
    }

    const blob_wrapper = this.g
      .selectAll('.radar-wrapper')
      .data(this.grades_data)
      .enter()
      .append('g')
      .attr('class', 'radar-wrapper');

    // Create the outlines
    blob_wrapper
      .append('path')
      .attr('class', 'radar-grade-stroke')
      .attr('d', (d, i) => radar_line(d.axes))
      .style('stroke-width', this.cfg.grades.stroke_width + 'px')
      // .style('stroke', 'var(--accent-color)')
      .style('stroke', (d, i) => this.cfg.grades.color(i))
      .style('fill', 'none');
    // .style('filter' , 'url(#glow)');

    // Append the circles
    blob_wrapper
      .selectAll('.radar-grade-circle')
      .data((d) => (d as any).axes)
      .enter()
      .append('circle')
      .attr('class', 'radar-grade-circle')
      .attr('r', this.cfg.grades.dot_radius)
      .attr(
        'cx',
        (d, i) =>
          this.radius_scale(d.value) *
          Math.cos(this.angle_slice * i - Math.PI / 2)
      )
      .attr(
        'cy',
        (d, i) =>
          this.radius_scale(d.value) *
          Math.sin(this.angle_slice * i - Math.PI / 2)
      )
      .style('fill', (d, i) => this.cfg.grades.color(i))
      // .style('fill', 'var(--accent-color)')
      .style('fill-opacity', 0.8)
      // for tooltips on dots
      .style('pointer-events', 'all')
      .on('mouseover', (d, i) => {
        const cx =
          this.radius_scale(d.value) *
          Math.cos(this.angle_slice * i - Math.PI / 2);
        const cy =
          this.radius_scale(d.value) *
          Math.sin(this.angle_slice * i - Math.PI / 2);
        const newX = cx - 10;
        const newY = cy - 10;

        tooltip
          .attr('x', newX)
          .attr('y', newY)
          .text(`${d.title}: ${d.value_raw} (${d.value}%)`)
          .transition()
          .duration(200)
          .style('opacity', 1);
      })
      .on('mouseout', () => {
        tooltip.transition().duration(200).style('opacity', 0);
      });

    //Set up the small tooltip for when you hover over a circle
    const tooltip = this.g
      .append('text')
      .style('font-size', this.cfg.grades.tooltip_size)
      .style('color', this.cfg.grades.tooltip_color)
      .style('opacity', 0);
  }

  private draw_user(): void {
    const radar_line = d3
      .radialLine()
      .curve(d3.curveLinearClosed)
      .radius((d) => this.radius_scale((d as any).value))
      .angle((d, i) => i * this.angle_slice);

    if (this.cfg.round_strokes) {
      radar_line.curve(d3.curveCardinalClosed);
    }

    const blob_wrapper = this.g
      .selectAll('.radar-user-wrapper')
      .data(this.user_data)
      .enter()
      .append('g')
      .attr('class', 'radar-user-wrapper');

    blob_wrapper
      .append('path')
      .attr('class', 'radar-user-area')
      .attr('d', (d) => radar_line((d as any).axes))
      .style('fill', 'var(--accent-color)')
      .style('fill-opacity', this.cfg.user.opacity_area);

    // Create the outlines
    blob_wrapper
      .append('path')
      .attr('class', 'radar-user-stroke')
      .attr('d', (d, i) => radar_line(d.axes))
      .style('stroke-width', this.cfg.user.stroke_width + 'px')
      .style('stroke', 'var(--accent-color)')
      .style('fill', 'none')
      .style('filter', 'url(#glow)');

    // Append the circles
    blob_wrapper
      .selectAll('.radar-user-circle')
      .data((d) => (d as any).axes)
      .enter()
      .append('circle')
      .attr('class', 'radar-user-circle')
      .attr('r', this.cfg.user.dot_radius)
      .attr(
        'cx',
        (d, i) =>
          this.radius_scale(d.value) *
          Math.cos(this.angle_slice * i - Math.PI / 2)
      )
      .attr(
        'cy',
        (d, i) =>
          this.radius_scale(d.value) *
          Math.sin(this.angle_slice * i - Math.PI / 2)
      )
      .style('fill', 'var(--accent-color)')
      .style('fill-opacity', 0.8)

      // for tooltips on dots
      .style('pointer-events', 'all')
      .on('mouseover', (d, i) => {
        const cx =
          this.radius_scale(d.value) *
          Math.cos(this.angle_slice * i - Math.PI / 2);
        const cy =
          this.radius_scale(d.value) *
          Math.sin(this.angle_slice * i - Math.PI / 2);
        const newX = cx - 10;
        const newY = cy - 10;

        tooltip
          .attr('x', newX)
          .attr('y', newY)
          .text(`${d.title}: ${d.value_raw} (${d.value}%)`)
          .transition()
          .duration(200)
          .style('opacity', 1);
      })
      .on('mouseout', () => {
        tooltip.transition().duration(200).style('opacity', 0);
      });

    //Set up the small tooltip for when you hover over a circle
    const tooltip = this.g
      .append('text')
      .style('font-size', this.cfg.user.tooltip_size)
      .style('color', this.cfg.user.tooltip_color)
      .style('opacity', 0);
  }

  private wrap_text(input_text, width) {
    input_text.each(function () {
      const text = d3.select(this);
      const words = text.text().split(/\s+/).reverse();
      let word;
      let line = [];
      let lineNumber = 0;
      const lineHeight = 1.4; // ems
      const y = text.attr('y');
      const x = text.attr('x');
      const dy = parseFloat(text.attr('dy'));
      let tspan = text
        .text(null)
        .append('tspan')
        .attr('x', x)
        .attr('y', y)
        .attr('dy', dy + 'em');

      // tslint:disable-next-line:no-conditional-assignment
      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(' '));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text
            .append('tspan')
            .attr('x', x)
            .attr('y', y)
            .attr('dy', ++lineNumber * lineHeight + dy + 'em')
            .text(word);
        }
      }
    });
  }
}
