import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserHttpService } from '@services/http/UserHttpService';
import { AsyncDetail } from '@rest/AsyncDetail';
import { User } from '@models/user/user';
import { SpecialityUserRelationHttpService } from '@services/http/SpecialityUserRelationHttpService';
import { SpecialityUserRelation } from '@models/specialities/speciality-user-relation';
import { AsyncList } from '@rest/AsyncList';
import { get_full_parent_path } from '../../../../utils/getFullParentUrl';
import { SpecialityGrade } from '@models/specialities/speciality-grade';
import { SpecialityGradeHttpService } from '@services/http/SpecialityGradeHttpService';
import { UntypedFormControl } from '@angular/forms';
import { Domain } from '@models/ontology/domain';
import { SpecialityCompetenceClaim } from '@models/specialities/speciality-competence-claim';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { SpecialityCompetenceClaimHttpService } from '@services/http/SpecialityCompetenceClaimHttpService';
import { ThingLevel } from '@models/ontology/thing-level';
import { Thing } from '@models/ontology/thing';
import {
  ThingCurrentDesiredLevelMap,
  ThingDesireMap,
} from '@components/common/domain-thing-tree/domain-thing-tree.component';
import { DevelopmentPlanHttpService } from '@services/http/DevelopmentPlanHttpService';
import { DevelopmentPlan } from '@models/tracks/development-plan';
import { Review } from '@models/reviews/review';
import { ReviewHttpService } from '@services/http/ReviewHttpService';
import { Speciality } from '@models/specialities/speciality';
import { combineLatest } from 'rxjs';
import { Assessment } from '@models/reviews/assessment';
import { AssessmentHttpService } from '@services/http/AssessmentHttpService';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { SpecialityDomainClaim } from '@models/specialities/speciality-domain-claim';
import { SpecialityDomainClaimHttpService } from '@services/http/SpecialityDomainClaimHttpService';
import {
  computeAxeValues,
  DomainThing,
} from '../../../../utils/compute-chart-radar-values';
import { selectDomainsForAxes } from '../../../../utils/select-domains-for-chart-axes';
import { Competence } from '@models/competencies/competence';
import { CompetenceHttpService } from '@services/http/CompetenceHttpService';
import { CompetenceAssertion } from '@models/competencies/competence-assertion';
import { CompetenceAssertionHttpService } from '@services/http/CompetenceAssertionHttpService';
import { BarChartData } from '@components/ui/chart-bar/chart-bar.component';
import { CompetenceDesireHttpService } from '@services/http/CompetenceDesireHttpService';
import { CompetenceDesire } from '@models/competencies/competence-desire';
import { TitleService } from 'src/app/services/title.service';
import { MatDialog } from '@angular/material/dialog';
import { BlockTextHelpDialogComponent } from '@components/common/block-text-help-dialog/block-text-help-dialog.component';
import { StaticTextField } from '@components/common/static-textfield-group/static-textfield-group.component';
import { RadarDataAxe } from '@components/ui/chart-radar/chart-radar.component';
import { AuthService } from '../../../../services/auth/auth.service';
import { TeamMembership } from '@models/teams/team-membership';
import { TeamMembershipHttpService } from '@services/http/TeamMembershipHttpService';

interface SpecialitiesGradesMap {
  [specialityUuid: string]: SpecialityGrade[];
}

@Component({
  selector: 'app-new-profile',
  templateUrl: './new-profile.component.html',
  styleUrls: ['./new-profile.component.css'],
})
export class NewProfileComponent implements OnInit {
  userUuid: string;

  user: AsyncDetail<User>;
  domains: AsyncList<Domain>;
  userTeamMemberships: AsyncList<TeamMembership>;
  userDevelopmentPlans: AsyncList<DevelopmentPlan>;
  userSpecialityRelations: AsyncList<
    SpecialityUserRelation<string, Speciality, SpecialityGrade>
  >;
  userCompetencies: AsyncList<Competence>;
  userCompetenceDesires: AsyncList<CompetenceDesire>;
  userAssertions: AsyncList<CompetenceAssertion>;
  specialitiesGrades: AsyncList<SpecialityGrade>;
  specialityClaims: AsyncList<SpecialityCompetenceClaim>;
  specialityDomainClaims: AsyncList<SpecialityDomainClaim>;
  userReviews: AsyncList<Review>;
  userAssessments: AsyncList<Assessment>;
  reviewsSpecialities: AsyncList<Speciality>;

  things: Thing[];

  specialitiesGradesMap: SpecialitiesGradesMap = {};
  thingCurrentDesiredLevelMap: ThingCurrentDesiredLevelMap = {};
  thingDesireMap: ThingDesireMap = {};

  specialitiesUuidList = [];

  selectedSpeciality = new UntypedFormControl();
  selectedGrade = new UntypedFormControl();

  chartDataList: BarChartData[];

  userLevelInfoData: StaticTextField[];

  activePlans: DevelopmentPlan[];
  completedPlans: DevelopmentPlan[];

  isTreeDataReady = false;
  isChartDataReady = false;
  isNoSpecialities = false;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _dialog: MatDialog,
    private _authService: AuthService,
    private _userHttpService: UserHttpService,
    private _titleService: TitleService,
    private _domainHttpService: DomainHttpService,
    private _specialityHttpService: SpecialityHttpService,
    private _specialityUserRelationHttpService: SpecialityUserRelationHttpService<
      string,
      Speciality,
      SpecialityGrade
    >,
    private _specialityGradeHttpService: SpecialityGradeHttpService,
    private _specialityCompetenceClaimHttpService: SpecialityCompetenceClaimHttpService,
    private _specialityDomainClaimHttpService: SpecialityDomainClaimHttpService,
    private _competenceHttpService: CompetenceHttpService,
    private _competenceDesireHttpService: CompetenceDesireHttpService,
    private _competenceAssertionHttpService: CompetenceAssertionHttpService,
    private _developmentPlanHttpService: DevelopmentPlanHttpService,
    private _reviewHttpService: ReviewHttpService,
    private _assessmentHttpService: AssessmentHttpService,
    private _teamMembershipHttpService: TeamMembershipHttpService
  ) {}

  ngOnInit(): void {
    this.userUuid = this._activatedRoute.snapshot.paramMap.get('userUuid');
    this.user = new AsyncDetail<User>(this.userUuid, this._userHttpService);
    this.user.load().subscribe(() => {
      this._titleService.set_title(
        `${this.user.state.item.first_name} ${this.user.state.item.last_name}`
      );
    });
    this.domains = new AsyncList<Domain>(this._domainHttpService);
    this.domains.load();
    this.userTeamMemberships = new AsyncList<TeamMembership>(
      this._teamMembershipHttpService
    );
    this.userTeamMemberships.setRequestParams({
      params: {
        user: this.userUuid,
        expand: 'team',
      },
    });
    this.userTeamMemberships.load();
    this._loadUserSpecialityRelations();
    this._loadUserDevelopmentPlans();
    this._loadReviews();
  }

  private _loadReviews(): void {
    this.userReviews = new AsyncList<Review>(this._reviewHttpService);
    this.userReviews.setRequestParams({
      params: {
        subjects: this.userUuid,
      },
    });
    this.userAssessments = new AsyncList<Assessment>(
      this._assessmentHttpService
    );
    this.userAssessments.setRequestParams({
      params: {
        reviewer_user: this.userUuid,
        expand: 'review,subject_user',
      },
    });

    combineLatest([
      this.userReviews.load(),
      this.userAssessments.load(),
    ]).subscribe(() => {
      this.userReviews.state.items.forEach((review) => {
        const reviewSpecialityUuid = this.getReviewSpecialityUuid(review);

        if (!this.specialitiesUuidList.includes(reviewSpecialityUuid)) {
          this.specialitiesUuidList.push(reviewSpecialityUuid);
        }
      });

      this.userAssessments.state.items.forEach((assessment) => {
        const review = assessment.review as Review,
          reviewSpecialityUuid = this.getReviewSpecialityUuid(review);

        if (!this.specialitiesUuidList.includes(reviewSpecialityUuid)) {
          this.specialitiesUuidList.push(reviewSpecialityUuid);
        }
      });
      this._loadSpecialities();
    });
  }

  private _loadSpecialities(): void {
    this.reviewsSpecialities = new AsyncList<Speciality>(
      this._specialityHttpService
    );
    this.reviewsSpecialities.setRequestParams({
      params: {
        uuid__in: this.specialitiesUuidList.join(','),
      },
    });
    this.reviewsSpecialities.load().subscribe();
  }

  getReviewSpecialityUuid(review: Review): string {
    const reviewSubject = review.subjects[0] as string;
    return review.config?.subject_speciality_map[reviewSubject][0];
  }

  getReviewSpeciality(review: Review): string {
    if (review.config?.subject_speciality_map) {
      const reviewSpecialityUuid = this.getReviewSpecialityUuid(review);

      return this.reviewsSpecialities.state.items.find(
        (speciality) => speciality.uuid === reviewSpecialityUuid
      )?.name;
    }
    return '';
  }

  getPrettyReviewDate(review: Review): string {
    if (review.deadline_start && review.deadline_end) {
      const dateFrom = new Date(review.deadline_start),
        dateTo = new Date(review.deadline_end);
      return `${dateFrom.getDate()} ${dateFrom.toLocaleString('default', {
        month: 'short',
      })} - ${dateTo.getDate()} ${dateTo.toLocaleString('default', {
        month: 'short',
      })}`;
    }
    return '';
  }

  getAllSpecialitiesTooltipText(): string {
    let specialitiesToolipText = '';
    this.userSpecialityRelations.state.items.forEach((relation) => {
      specialitiesToolipText +=
        (relation.speciality as Speciality).name + ' \n ';
    });
    return specialitiesToolipText;
  }

  onReviewClick(review: Review): void {
    this._router.navigate([`account/user-reviews/${review.uuid}`]);
  }

  onAssessmentClick(assessment: Assessment): void {
    this._router.navigate([
      `account/reviewer-reviews/${
        (assessment.review as Review).uuid
      }/assessment/${assessment.uuid}`,
    ]);
  }

  private _loadUserDevelopmentPlans(): void {
    this.userDevelopmentPlans = new AsyncList<DevelopmentPlan>(
      this._developmentPlanHttpService
    );
    this.userDevelopmentPlans.setRequestParams({
      params: {
        subject_user: this.userUuid,
        status__in: 'future,active,completed',
      },
    });
    this.userDevelopmentPlans.load().subscribe(() => {
      this.activePlans = this.userDevelopmentPlans.state.items.filter(
        (developmentPlan) => developmentPlan.status === 'active'
      );
      this.completedPlans = this.userDevelopmentPlans.state.items.filter(
        (developmentPlan) => developmentPlan.status === 'completed'
      );
    });
  }

  private _loadUserSpecialityRelations(): void {
    this.userSpecialityRelations = new AsyncList<
      SpecialityUserRelation<string, Speciality, SpecialityGrade>
    >(this._specialityUserRelationHttpService);
    this.userSpecialityRelations.setRequestParams({
      params: {
        user: this.userUuid,
        expand: 'speciality,grade',
      },
    });
    this.userSpecialityRelations.load().subscribe(() => {
      this._loadSpecialitiesGrades();
    });
  }

  private _loadSpecialitiesGrades(): void {
    this.specialitiesGrades = new AsyncList<SpecialityGrade>(
      this._specialityGradeHttpService
    );
    this.specialitiesGrades.setRequestParams({
      params: {
        speciality__in: this.userSpecialityRelations.state.items
          .map(
            (
              userRelation: SpecialityUserRelation<
                string,
                Speciality,
                SpecialityGrade
              >
            ) => userRelation.speciality.uuid
          )
          .join(','),
      },
    });
    this.specialitiesGrades.load().subscribe(() => {
      this.userSpecialityRelations.state.items.forEach(
        (
          userRelation: SpecialityUserRelation<
            string,
            Speciality,
            SpecialityGrade
          >
        ) => {
          const specialityUuid = userRelation.speciality.uuid;

          this.specialitiesGradesMap[specialityUuid] =
            this.specialitiesGrades.state.items.filter(
              (grade) => grade.speciality === specialityUuid
            );
        }
      );
      this._setDefaultSpeciality();
    });
  }

  private _setDefaultSpeciality(): void {
    if (this.userSpecialityRelations.state.items.length === 0) {
      this.isNoSpecialities = true;
      this.isTreeDataReady = true;
      return;
    }
    const specialityUuid = (
      this.userSpecialityRelations.state.items[0]?.speciality as Speciality
    ).uuid;

    this.selectedSpeciality.setValue(specialityUuid);
    this._setDefaultGrade();
    this._loadSpecialityData();
  }

  private _setDefaultGrade(): void {
    if (!this.currentGrade) {
      return;
    }
    const currentGrade = this.currentGrade,
      nextGrade = this.specialitiesGradesMap[
        this.selectedSpeciality.value
      ].find(
        (grade) =>
          grade.order_number ===
          (currentGrade ? currentGrade.order_number + 1 : 0)
      );

    this.selectedGrade.setValue(
      nextGrade ? nextGrade.uuid : currentGrade?.uuid
    );
  }

  get currentGrade(): SpecialityGrade {
    return this.userSpecialityRelations.state.items.find(
      (userRelation) =>
        (userRelation.speciality as Speciality).uuid ===
        this.selectedSpeciality.value
    )?.grade as SpecialityGrade;
  }

  onSelectedSpecialityChange(): void {
    this._setDefaultGrade();
    this._loadSpecialityData();
  }

  onSelectedGradeChange(): void {
    this._loadSpecialityData();
  }

  private _loadSpecialityData(): void {
    this.isTreeDataReady = false;
    this.isChartDataReady = false;
    this.specialityClaims = new AsyncList<SpecialityCompetenceClaim>(
      this._specialityCompetenceClaimHttpService
    );
    this.specialityClaims.setRequestParams({
      params: {
        speciality: this.selectedSpeciality.value,
        expand: 'thing.levels,thing_level',
      },
    });

    this.specialityDomainClaims = new AsyncList<SpecialityDomainClaim>(
      this._specialityDomainClaimHttpService
    );
    this.specialityDomainClaims.setRequestParams({
      params: {
        speciality: this.selectedSpeciality.value,
        expand: 'domain',
      },
    });

    this.userCompetencies = new AsyncList<Competence>(
      this._competenceHttpService
    );
    this.userCompetencies.setRequestParams({
      params: {
        user: this.userUuid,
        expand: 'thing,thing_level',
      },
    });

    this.userCompetenceDesires = new AsyncList<CompetenceDesire>(
      this._competenceDesireHttpService
    );
    this.userCompetenceDesires.setRequestParams({
      params: {
        user: this.userUuid,
      },
    });

    this.userAssertions = new AsyncList<CompetenceAssertion>(
      this._competenceAssertionHttpService
    );
    this.userAssertions.setRequestParams({
      params: {
        from_user: this.userUuid,
        to_user: this.userUuid,
        expand: 'thing_level',
      },
    });

    combineLatest([
      this.specialityClaims.load(),
      this.specialityDomainClaims.load(),
      this.userCompetencies.load(),
      this.userAssertions.load(),
      this.userCompetenceDesires.load(),
    ]).subscribe(() => {
      this._setTreeData();
      this._setChartData();
    });
  }

  private _setTreeData(): void {
    this.things = [];
    this.specialityClaims.state.items
      .filter((claim) => claim.grade === this.currentGrade?.uuid)
      .forEach((competenceClaim: SpecialityCompetenceClaim<ThingLevel>) => {
        this.things.push(competenceClaim.thing);
        const userCompetence = this.userCompetencies.state.items.find(
          (competence) => {
            return (
              (competence.thing as Thing).uuid === competenceClaim.thing.uuid
            );
          }
        );
        this.thingCurrentDesiredLevelMap[competenceClaim.thing.uuid] = {
          currentLevel: competenceClaim.thing_level,
          desiredLevel: null,
          userCompetence: userCompetence,
          userLevel: userCompetence?.thing_level as ThingLevel,
          levels: competenceClaim.thing.levels,
        };
      });

    this.specialityClaims.state.items
      .filter((claim) => claim.grade === this.selectedGrade.value)
      .forEach((competenceClaim: SpecialityCompetenceClaim<ThingLevel>) => {
        if (this.thingCurrentDesiredLevelMap[competenceClaim.thing.uuid]) {
          this.thingCurrentDesiredLevelMap[
            competenceClaim.thing.uuid
          ].desiredLevel = competenceClaim.thing_level;
        } else {
          this.things.push(competenceClaim.thing);
          const userCompetence = this.userCompetencies.state.items.find(
            (competence) => {
              return (
                (competence.thing as Thing).uuid === competenceClaim.thing.uuid
              );
            }
          );
          this.thingCurrentDesiredLevelMap[competenceClaim.thing.uuid] = {
            currentLevel: null,
            userCompetence: userCompetence,
            desiredLevel: competenceClaim.thing_level,
            userLevel: userCompetence?.thing_level as ThingLevel,
            levels: competenceClaim.thing.levels,
          };
        }
      });

    this.userCompetenceDesires.state.items.forEach((competenceDesire) => {
      const thingUuid = competenceDesire.thing as string;

      this.thingDesireMap[thingUuid] = {
        desire: competenceDesire.uuid,
        date: this.getPrettyDesireDate(competenceDesire),
      };
    });

    this.isTreeDataReady = true;
  }

  private _setChartData(): void {
    const domains = this.specialityDomainClaims.state.items.map(
      (domainClaim) => {
        return domainClaim.domain as Domain;
      }
    );

    //selecting unique things uuid related to chosen speciality
    const specialityCompetenceThingUuids = [];
    this.specialityClaims.state.items.forEach((competenceClaim) => {
      const thing = competenceClaim.thing as Thing;
      if (!specialityCompetenceThingUuids.includes(thing.uuid)) {
        specialityCompetenceThingUuids.push(thing.uuid);
      }
    });

    //variable to check if user got any competencies related to chosen speciality
    let competenceMatch = 0;

    //selecting user things
    const chartThings = [],
      domainsThings: DomainThing[] = [];
    this.userCompetencies.state.items.forEach((competence) => {
      const thing = competence.thing as Thing;
      if (specialityCompetenceThingUuids.includes(thing.uuid)) {
        competenceMatch += 1;
      }
      chartThings.push({
        parent_domain: (thing as any).domain as string,
        name: thing.name,
      });
      domainsThings.push({
        domain: (thing as any).domain as string,
        thing: thing,
        thing_level: competence.thing_level as ThingLevel,
      });
    });

    if (competenceMatch > 0) {
      const chartDomains = selectDomainsForAxes(domains, chartThings);
      const currentLevelData = computeAxeValues(
        domains,
        chartDomains,
        domainsThings.filter((domainThing) =>
          specialityCompetenceThingUuids.includes(domainThing.thing.uuid)
        )
      );
      const expectedLevelData = computeAxeValues(
        domains,
        chartDomains,
        this.specialityClaims.state.items.filter(
          (specialityCompetenceClaim) => {
            if (this.currentGrade) {
              return specialityCompetenceClaim.grade === this.currentGrade.uuid;
            } else {
              const selectedSpecialityGrades =
                this.specialitiesGrades.state.items.filter(
                  (grade) => grade.speciality === this.selectedSpeciality.value
                );

              return (
                specialityCompetenceClaim.grade ===
                selectedSpecialityGrades[selectedSpecialityGrades.length - 1]
                  .uuid
              );
            }
          }
        ),
        this.currentGrade
      );
      const desiredGrade = this.specialitiesGrades.state.items.find(
          (grade) => grade.uuid === this.selectedGrade.value
        ),
        desiredLevelData = computeAxeValues(
          domains,
          chartDomains,
          this.specialityClaims.state.items.filter(
            (specialityCompetenceClaim) =>
              specialityCompetenceClaim.grade === desiredGrade?.uuid
          ),
          desiredGrade
        );

      const selfLevelData = computeAxeValues(
        domains,
        chartDomains,
        domainsThings
          .filter((domainThing) =>
            specialityCompetenceThingUuids.includes(domainThing.thing.uuid)
          )
          .map((domainThing) => {
            return {
              domain: domainThing.domain,
              thing: domainThing.thing,
              thing_level: this.userAssertions.state.items.find(
                (assertion) => assertion.thing === domainThing.thing.uuid
              )?.thing_level as ThingLevel,
            };
          })
      );

      this.chartDataList = chartDomains.map((chartDomain) => {
        return {
          title: chartDomain.name,
          data: [
            {
              name: 'Необходимый',
              color: '#82C4FF',
              percentage: 0,
              level: expectedLevelData.axes.find(
                (item) => item.name === chartDomain.name
              ).valueRaw,
            },
            {
              name: 'Текущий',
              color: '#9B82FF',
              percentage: 0,
              level: currentLevelData.axes.find(
                (item) => item.name === chartDomain.name
              ).valueRaw,
            },
            {
              name: 'Самооценка',
              color: '#B0D9AA',
              percentage: 0,
              level: selfLevelData.axes.find(
                (item) => item.name === chartDomain.name
              ).valueRaw,
            },
            {
              name: 'Желаемый',
              color: '#F8ACA7',
              percentage: 0,
              level: desiredLevelData.axes.find(
                (item) => item.name === chartDomain.name
              ).valueRaw,
            },
          ],
        };
      });
      this.setUserLevelInfo(currentLevelData.axes, expectedLevelData.axes);
    }
    this.chartDataList?.forEach((chartData) => {
      let maxValue = 0;
      chartData.data.forEach((data) => {
        if (data.level > maxValue) maxValue = data.level;
      });
      chartData.data.forEach((data) => {
        if (data.level !== 0) {
          data.percentage = (100 * data.level) / maxValue;
        } else {
          data.percentage = 0;
        }
      });
    });
    this.isChartDataReady = true;
  }

  setUserLevelInfo(
    currentLevelData: RadarDataAxe[],
    expectedLevelData: RadarDataAxe[]
  ): void {
    const total = currentLevelData.length,
      aboveExpectedCount = currentLevelData.filter(
        (currentThing) =>
          currentThing.valueRaw >
          expectedLevelData.find(
            (expectedthing) => expectedthing.uuid === currentThing.uuid
          ).valueRaw
      ).length,
      unexperiencedCount = currentLevelData.filter(
        (currentThing) => currentThing.valueRaw === 0
      ).length;
    this.userLevelInfoData = [
      { name: `${aboveExpectedCount} из ${total}`, text: 'Выше требуемого' },
      {
        name: `${total - unexperiencedCount - aboveExpectedCount} из ${total}`,
        text: 'Ниже требуемого',
      },
      {
        name:
          unexperiencedCount === 0
            ? 'Все навыки изучены'
            : `${unexperiencedCount} из ${total}`,
        text: unexperiencedCount === 0 ? '' : 'Не изучено',
      },
    ];
  }

  get isDataReady(): boolean {
    return (
      this.user?.state.isLoaded &&
      this.userSpecialityRelations?.state.isLoaded &&
      this.specialitiesGrades?.state.isLoaded &&
      this.userDevelopmentPlans?.state.isLoaded &&
      this.reviewsSpecialities?.state.isLoaded &&
      this.isTreeDataReady
    );
  }

  get isFromTeam(): boolean {
    return this.getFullParentPath(this._activatedRoute).includes('team');
  }

  get isCurrentUser(): boolean {
    return this.userUuid === this._authService.get_current_user_uuid();
  }

  get desiredThings(): Thing[] {
    if (!this.things) return [];
    return this.things.filter((thing) =>
      Object.keys(this.thingDesireMap).includes(thing.uuid)
    );
  }

  onDevelopmentPlanClick(development: DevelopmentPlan): void {
    this._router.navigate([
      `/account/user-profile/${this.userUuid}/pdp-details/${development.uuid}`,
    ]);
  }

  getPrettyDesireDate(desire: CompetenceDesire): string {
    if (desire.date_updated) {
      const date = new Date(desire.date_updated);
      return `${date.getDate()} ${date.toLocaleString('default', {
        month: 'short',
      })} ${date.getFullYear()}`;
    }
    return '';
  }

  getPrettyDevelopmentDate(development: DevelopmentPlan): string {
    if (development.date_created && development.due_date) {
      const dateFrom = new Date(development.date_created),
        dateTo = new Date(development.due_date);
      return `${dateFrom.getDate()} ${dateFrom.toLocaleString('default', {
        month: 'short',
      })} - ${dateTo.getDate()} ${dateTo.toLocaleString('default', {
        month: 'short',
      })}`;
    }
    return '';
  }

  redirectToTeam(teamUuid: string): void {
    this._router.navigate([`/account/user-teams/${teamUuid}`]);
  }

  onBackClick(): void {
    history.back();
  }

  onLegendDescClick(): void {
    this._dialog.open(BlockTextHelpDialogComponent, {
      data: {
        title: 'Что означают эти метрики?',
        textBlocksData: [
          {
            title: 'Необходимый',
            text: 'Целевой уровень владения навыками, определенный руководителем для этой специальности',
            color: 'rgba(130,196,255,0.16)',
          },
          {
            title: 'Текущий',
            text: 'Уровень навыков сотрудника на данный момент. Основывается на оценке руководителя и третьих лиц, которые участвовали в ревью сотрудника',
            color: 'rgba(155,130,255,0.16)',
          },
          {
            title: 'Самооценка',
            text: 'Уровень навыков, который основывается на самостоятельной оценке сотрудника',
            color: 'rgba(176,217,170,0.16)',
          },
          {
            title: 'Желаемый',
            text: 'Уровень навыков, который соответствует следующему уровню специалиста (junor, middle, senior…)',
            color: 'rgba(248,172,167,0.16)',
          },
        ],
      },
    });
  }

  getFullParentPath = get_full_parent_path;
}
