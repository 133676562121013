import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { ThingLevelsService } from 'src/app/services/ontology/thing-levels.service';

import { Thing } from 'src/app/models/ontology/thing';
import { ThingLevel } from 'src/app/models/ontology/thing-level';
import {
  ThingLevelFormComponent,
  ThingLevelFormData,
} from 'src/app/modules/ontologies/components/things/tabs/thing-tab-levels/thing-level-form/thing-level-form.component';
import { AcceptDialogComponent } from 'src/app/modules/common-ui-elements/components/accept-dialog/accept-dialog.component';
import { Domain } from '@models/ontology/domain';

@Component({
  selector: 'app-thing-tab-levels',
  templateUrl: './thing-tab-levels.component.html',
  styleUrls: ['./thing-tab-levels.component.css'],
})
export class ThingTabLevelsComponent implements OnInit {
  @Input() thing: Thing<Domain>;
  @Input() editable = true;

  public levels: ThingLevel[] = [];

  public level_edit_form: UntypedFormGroup;
  public level_edit_uuid: string;

  private uistate = {
    levels_loaded: false,

    is_button_level_edit_save_disabled: false,
  };

  constructor(
    private form_builder: UntypedFormBuilder,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private thing_levels_service: ThingLevelsService,
    public dialog: MatDialog
  ) {
    this.define_level_edit_form();
  }

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.load_levels();
  }

  private define_level_edit_form() {
    this.level_edit_form = this.form_builder.group({
      level_title: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      level_description: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
    });
  }

  public get is_data_loaded(): boolean {
    return this.uistate.levels_loaded;
  }

  public get is_button_level_edit_save_disabled(): boolean {
    return this.uistate.is_button_level_edit_save_disabled;
  }

  private load_levels(): void {
    this.thing_levels_service.fetch_by_thing_uuid(this.thing.uuid).subscribe(
      (response) => {
        this.levels = response.results as ThingLevel[];
        this.logging_service.debug(
          `${this.constructor.name} loaded ${this.levels.length} thing levels`
        );
        this.uistate.levels_loaded = true;
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to load thing levels`
        );
        this.alert_service.error(
          `Ошибка загрузки уровней владения: ${err.status}`
        );
      }
    );
  }

  public on_button_level_edit(level: ThingLevel): void {
    this.level_edit_form.controls.level_title.setValue(level.title);
    this.level_edit_form.controls.level_description.setValue(level.description);
    this.level_edit_uuid = level.uuid;
    this.logging_service.debug(
      `${this.constructor.name} edit level ${level.title}`
    );
  }

  public openRemoveDialog(thing_level: ThingLevel): void {
    this.dialog
      .open(AcceptDialogComponent, {
        data: {
          title: `Удалить ${thing_level.title}?`,
          message:
            'При удалении уровня компетенций сбросятся уровни специальностей и пользователей',
          acceptLabel: 'Удалить',
          rejectLabel: 'Отмена',
        },
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) {
          this.delete_thing_level(thing_level);
        }
      });
  }

  private delete_thing_level(removed_thing_level: ThingLevel) {
    this.thing_levels_service.delete(removed_thing_level.uuid).subscribe(
      () => {
        this.logging_service.debug(
          `${this.constructor.name} remove level data`
        );
        this.levels = this.levels.filter((thing_level: ThingLevel) => {
          return thing_level.uuid !== removed_thing_level.uuid;
        });
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to remove thing level data`
        );
        this.alert_service.error(`Ошибка удаления: ${err.status}`);
      }
    );
  }

  public on_button_level_edit_cancel(): void {
    this.level_edit_uuid = null;
    this.logging_service.debug(`${this.constructor.name} edit level cancel`);
  }

  public on_button_level_edit_save(level: ThingLevel): void {
    this.logging_service.debug(`${this.constructor.name} edit level save`);
    this.uistate.is_button_level_edit_save_disabled = true;
    const level_edit_data = {
      title: this.level_edit_form.controls.level_title.value,
      description: this.level_edit_form.controls.level_description.value,
    };
    this.thing_levels_service.update(level.uuid, level_edit_data).subscribe(
      (response) => {
        level.title = response.title;
        level.description = response.description;
        this.level_edit_uuid = null;
        this.uistate.is_button_level_edit_save_disabled = false;
        this.logging_service.debug(
          `${this.constructor.name} saved edited level data`
        );
      },
      (err) => {
        this.uistate.is_button_level_edit_save_disabled = false;
        this.logging_service.debug(
          `${this.constructor.name} failed to save thing level data`
        );
        this.alert_service.error(`Ошибка сохранения: ${err.status}`);
      }
    );
  }

  open_new_thing_level_dialog() {
    const formConfig: ThingLevelFormData = {
      submitTitle: 'Создать',
      formTitle: 'Создать новый уровень',
      onSubmit: this.onSubmitCreateLevel,
      initialValues: {
        title: '',
        description: '',
      },
    };

    this.dialog.open(ThingLevelFormComponent, {
      data: formConfig,
      width: '55rem',
    });
  }

  onSubmitCreateLevel = (formValue, dialogRef: any) => {
    this.thing_levels_service
      .create({
        ...formValue,
        thing: this.thing.uuid,
        domain: this.thing.domain.uuid,
      })
      .subscribe({
        next: (level) => {
          this.alert_service.success('Уровень создан');
          this.levels.push(level);
          dialogRef.close();
        },
        error: () => {
          this.alert_service.error('Не удалось создать уровень');
        },
      });
  };

  isBaseLevel(level) {
    return level.uuid === this.levels[0].uuid;
  }
}
