<div class="app-user-inline-container">
    <div fxLayout="row">
        <div fxFlex="2rem">
            <div class="app-user-inline-avatar">
                {{ initials_for_avatar }}
            </div>
        </div>
        <div fxFlex
             class="app-user-inline-name"
             [attr.data-test-id]="testDataAttributes.accountPanelNameId"
        >
            {{ user.first_name }} {{ user.last_name }}
        </div>
    </div>
</div>
