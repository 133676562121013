<div class="router-outlet-container">
  <ng-container *ngIf="is_data_loaded; then component_content; else preloader">
  </ng-container>
</div>

<ng-template #preloader>
  <app-preloader></app-preloader>
</ng-template>

<ng-template #component_content>
  <div class="app-admin-survey-edit-container">
    <div class="app-admin-survey-edit-header-container">
      <div fxLayout="row">
        <ng-container
          [ngTemplateOutlet]=" survey_in_edit_mode ? tmpl_survey_edit_mode : tmpl_survey_normal_mode"
          [ngTemplateOutletContext]=" {survey:survey} ">
        </ng-container>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="app-admin-survey-edit-content-container">
      <div *ngIf="survey.survey_type === 'linear'">
        <app-survey-edit-linear [survey]="survey"></app-survey-edit-linear>
      </div>
      <div *ngIf="survey.survey_type === 'tree'">
        <app-survey-edit-tree [survey]="survey"></app-survey-edit-tree>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_survey_edit_mode let-survey="survey">
  <div fxFlex fxLayoutAlign="center">
    <form [formGroup]="survey_edit_form" fxFlex fxLayout="column">
      <input #survey_new_title class="app-admin-survey-edit-title-field"
             formControlName="survey_title" matInput required placeholder="Название*" autocomplete="off"
             maxlength="255">
      <div class="app-admin-survey-edit-title-dash"
           [class.warn]="survey_new_title.value.length == 0"></div>
      <textarea #survey_new_description class="app-admin-survey-edit-description-field"
                formControlName="survey_description" required matInput placeholder="Описание*"
                [class.warn]="survey_new_description.value.length == 0">
      </textarea>
    </form>
    <div fxFlex="20rem">
      <div class="app-admin-survey-edit-header-controls" fxLayoutAlign="space-around">
        <button mat-raised-button
                aria-label="Save"
                (click)="survey_edit_save_button_click()"
                [disabled]="survey_edit_form.invalid">
          <mat-icon>save</mat-icon>
          Сохранить
        </button>
        <button mat-raised-button
                aria-label="Cancel"
                (click)="cancel_survey_edit_mode()">
          <mat-icon>close</mat-icon>
          Отменить
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_survey_normal_mode let-survey="survey">
  <div fxFlex>
    <div class="app-admin-survey-edit-header-name">
      {{ survey.name }}
    </div>
    <div class="app-admin-survey-edit-header-description">
      {{ survey.description }}
    </div>
  </div>
  <div fxFlex="20rem">
    <div fxLayout="column">
      <div class="app-admin-survey-edit-header-controls" fxLayoutAlign="space-around">
        <button mat-raised-button
                matTooltip="Сохранить"
                aria-label="Save question"
                (click)="survey_edit_button_click()">
          <mat-icon>edit</mat-icon>
          Редактировать
        </button>
      </div>
      <div class="app-admin-survey-edit-header-metadata" style="text-align: center;">
        Cоздан: &nbsp;&nbsp;&nbsp;&nbsp; {{ survey.date_created | amDateFormat: 'YYYY-MM-DD HH:mm' }}
        <br>
        Изменен: &nbsp; {{ survey.date_updated | amDateFormat: 'YYYY-MM-DD HH:mm' }}
      </div>
    </div>
  </div>
</ng-template>
