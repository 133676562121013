export const environment = {
  production: false,
  apiUrl: 'https://test.starsmap.internal.express42.io',
  apiDelayMs: 10,

  ui: {
    logo_url: '/assets/icons/org-logo-express42.svg',
  },

  keycloak: {
    url: 'https://test.starsmap.internal.express42.io/auth',
    realm: 'starsmap',
    client_id: 'starsmap-webui',
    role_user: 'user',
    role_admin: 'admin',
  },

  sentry: {
    is_active: true,
    dsn: 'https://579670d40e174ae09d7368488527e7e9@sentry.starsmap.internal.express42.io/3',
    tracingOrigins: ['https://test.starsmap.internal.express42.io/'],
    environment: 'test',
  },
};
