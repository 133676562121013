import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root',
})
export class SpecialityService {
  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService
  ) {}

  public fetch_all(): Observable<any> {
    return this.api_service
      .get(`specialities/speciality`, { expand: 'team' })
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} fetched ${response.count} specialities`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to fetch specialities`
            );
          }
        )
      );
  }

  public fetch_by_uuid(speciality_uuid: string): Observable<any> {
    return this.api_service
      .get(`specialities/speciality/${speciality_uuid}`, { expand: 'team' })
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} fetched speciality with uuid ${speciality_uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to fetch speciality`
            );
          }
        )
      );
  }

  public fetch_by_team_uuid(team_uuid: string): Observable<any> {
    return this.api_service
      .get(`specialities/speciality`, { team: team_uuid, expand: 'team' })
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} fetched speciality for team ${team_uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to fetch speciality`
            );
          }
        )
      );
  }

  public create(speciality_data: any): Observable<any> {
    return this.api_service
      .post(`specialities/speciality`, speciality_data, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} created speciality with uuid ${response.uuid} and name ${response.name}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to create speciality`
            );
          }
        )
      );
  }

  public update(
    speciality_uuid: string,
    speciality_data: any
  ): Observable<any> {
    return this.api_service
      .patch(`specialities/speciality/${speciality_uuid}`, speciality_data, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} updated speciality with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to update speciality`
            );
          }
        )
      );
  }

  public delete(speciality_uuid: string): Observable<any> {
    return this.api_service
      .delete(`specialities/speciality/${speciality_uuid}`, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} deleted speciality with id ${speciality_uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to delete speciality`
            );
          }
        )
      );
  }
}
