import { Component, Input, OnInit } from '@angular/core';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { SurveyAssignmentService } from 'src/app/services/survey/survey-assignment.service';

import { User } from 'src/app/models/user/user';
import { Survey } from 'src/app/models/survey/survey';
import { SurveyAssignment } from 'src/app/models/survey/survey-assignment';

@Component({
  selector: 'app-survey-details-tab-assignments',
  templateUrl: './survey-details-tab-assignments.component.html',
  styleUrls: ['./survey-details-tab-assignments.component.css'],
})
export class SurveyDetailsTabAssignmentsComponent implements OnInit {
  @Input() survey: Survey;

  public survey_assignments: SurveyAssignment[] = [];

  private uistate = {
    survey_assignments_loaded: false,
  };

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private survey_assignment_service: SurveyAssignmentService
  ) {}

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.load_survey_assignments();
  }

  public get is_data_loaded(): boolean {
    return this.uistate.survey_assignments_loaded;
  }

  private load_survey_assignments(): void {
    this.survey_assignment_service
      .fetch_by_survey_uuid(this.survey.uuid)
      .subscribe(
        (response) => {
          this.survey_assignments = response.results as SurveyAssignment[];
          this.uistate.survey_assignments_loaded = true;
          this.logging_service.debug(
            `${this.constructor.name} loaded ${response.count} survey assignments`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to load survey assignments`
          );
          this.alert_service.error(
            `Ошибка загрузки назначенных опросов: ${err.status}`
          );
        }
      );
  }

  public on_button_delete_survey_assignment(
    assignment: SurveyAssignment
  ): void {
    this.survey_assignment_service.delete(assignment.uuid).subscribe(
      (response) => {
        this.logging_service.debug(
          `${this.constructor.name} deleted survey assignment`
        );
        this.survey_assignments = this.survey_assignments.filter(
          (a) => a.uuid !== assignment.uuid
        );
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to delete survey assignment`
        );
        this.alert_service.error(
          `Ошибка удаления назначенного опроса: ${err.status}`
        );
      }
    );
  }

  public util_user_get_initials_for_avatar(user: User): string {
    const first_name_initial = user.first_name.charAt(0);
    const last_name_initial = user.last_name.charAt(0);
    return `${first_name_initial}${last_name_initial}`;
  }
}
