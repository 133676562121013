<div class="app-in-development-stub-container">
    <div fxLayout="row">
        <div fxFlex="100%">
            <div>
                <mat-icon>construction</mat-icon>
            </div>
            <div>
                Мы работаем над этим
            </div>
        </div>
    </div>
</div>
