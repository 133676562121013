import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { FlexLayoutModule } from '@angular/flex-layout';

import { WithProcessingComponent } from './with-processing/with-processing.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { StatusIconComponent } from './status-icon/status-icon.component';
import { ChartRadarComponent } from '@components/ui/chart-radar/chart-radar.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { ShowOnSettingsComponent } from './show-on-settings/show-on-settings.component';
import { ChartBarComponent } from './chart-bar/chart-bar.component';
import { ChartGanttComponent } from './chart-gantt/chart-gantt.component';
import { ProgressLoaderComponent } from './progress-loader/progress-loader.component';
import { ChartPieComponent } from './chart-pie/chart-pie.component';

@NgModule({
  declarations: [
    WithProcessingComponent,
    StatusIconComponent,
    ChartRadarComponent,
    ProgressBarComponent,
    ShowOnSettingsComponent,
    ChartBarComponent,
    ChartGanttComponent,
    ProgressLoaderComponent,
    ChartPieComponent,
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    FlexLayoutModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatCardModule,
  ],
  exports: [
    WithProcessingComponent,
    StatusIconComponent,
    ChartRadarComponent,
    ProgressBarComponent,
    ShowOnSettingsComponent,
    ChartBarComponent,
    ChartGanttComponent,
    ProgressLoaderComponent,
    ChartPieComponent,
  ],
})
export class UiComponents {}
