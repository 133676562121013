<div class="dialog-container">
  <app-with-processing [processing]="!isDialogReady"> </app-with-processing>
  <div *ngIf="isDialogReady">
    <div>Выбрать верифицирующих специальность</div>
    <app-select-users-with-search
      [users]="reviewers"
      [checkedUsers]="reviewers"
      (selectedUsersChange)="onSelectedReviewersChange($event)"
    ></app-select-users-with-search>
    <div mat-dialog-actions fxLayoutAlign="end center">
      <button
        class="dialog-reject-button"
        mat-raised-button
        [mat-dialog-close]="false"
      >
        Отменить
      </button>
      <button
        class="dialog-accept-button"
        [disabled]="!reviewers || reviewers.length === 0"
        [mat-dialog-close]="false"
        mat-raised-button
        (click)="submit()"
      >
        Сохранить
      </button>
    </div>
  </div>
</div>
