<app-with-processing
  [processing]='
    teamReviews.state.isProcessing && teamSpecialities.state.isProcessing
  '
>
  <div
    class='reviews-container'
    *ngIf='teamReviews.state.isLoaded && teamSpecialities.state.isLoaded'
  >
    <div fxLayout='row'>
      <div fxFlex class='title'>Ревью команды</div>
    </div>
    <div class='review-desc'>
      Оценка навыков нужна для того, чтобы актуализировать информацию по навыкам сотрудников в команде.
      <br />
      В оценке навыков могут участвовать: сотрудник, коллеги сотрудника, эксперты гильдий и руководитель.
    </div>
    <div>
      <mat-tab-group [animationDuration]="'0ms'" mat-stretch-tabs='false'>
        <mat-tab label='Текущие ревью'>
          <div fxLayout='row'>
            <div
              fxLayoutAlign='start center'
              class='add-review-button-container'
              (click)='onReviewCreateClick()'
            >
              <button
                mat-raised-button
                class='add-review-button'
                color='primary'
              >
                <mat-icon>add_circle_outline</mat-icon>
              </button>
              <span class='add-review-button-label'>Добавить новое ревью</span>
            </div>
          </div>
          <div *ngIf='ongoingReviews.length > 0'>
            <ng-container
              [ngTemplateOutlet]='reviewList'
              [ngTemplateOutletContext]='{ reviews: ongoingReviews }'
            ></ng-container>
          </div>
          <div
            *ngIf='ongoingReviews.length === 0'
            fxLayout='row'
            fxLayoutAlign='center center'
          >
            <span class='reviews-container-empty'>
              Сейчас в команде нет текущих ревью
            </span>
          </div>
        </mat-tab>
        <mat-tab label='Ответственный в ревью'>
          <br />
          <div *ngIf='responsibleReviews.length > 0'>
            <ng-container
              [ngTemplateOutlet]='reviewList'
              [ngTemplateOutletContext]='{ reviews: responsibleReviews }'
            ></ng-container>
          </div>
          <div
            *ngIf='responsibleReviews.length === 0'
            fxLayout='row'
            fxLayoutAlign='center center'
          >
            <span class='reviews-container-empty'>
              Вы пока не назначены ответственным за ревью
            </span>
          </div>
        </mat-tab>
        <mat-tab label='Завершенные ревью'>
          <br />
          <div *ngIf='completedReviews.length > 0'>
            <ng-container
              [ngTemplateOutlet]='reviewList'
              [ngTemplateOutletContext]='{ reviews: completedReviews }'
            ></ng-container>
          </div>
          <div
            *ngIf='completedReviews.length === 0'
            fxLayout='row'
            fxLayoutAlign='center center'
          >
            <span class='reviews-container-empty'>
              Пока еще нет завершенных ревью
            </span>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</app-with-processing>

<ng-template #reviewList let-reviews='reviews'>
  <div
    class='review-container'
    fxLayout='row'
    fxLayoutAlign='start center'
    fxLayoutGap='2rem'
    *ngFor='let review of reviews'
    (click)='onReviewClick(review)'
  >
    <ng-container
      [ngTemplateOutlet]='reviewItem'
      [ngTemplateOutletContext]='{ review: review }'
    ></ng-container>
  </div>
</ng-template>

<ng-template #reviewItem let-review='review'>
  <div fxLayout='column' fxFlex='31'>
    <div fxLayout='row' fxLayoutAlign='start center'>
      <span class='review-container-text'>{{ review.name }}</span>
    </div>
    <div fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='1rem'>
      <mat-progress-bar
        mode='determinate'
        [value]='review.completion_percentage'
      ></mat-progress-bar>
      <span>{{ review.completion_percentage + '%' }}</span>
    </div>
  </div>
  <div fxFlex='23'>
    <div fxLayout='row' fxLayoutAlign='center center'>
      <span class='review-container-text'>{{
        getReviewSpeciality(review)
        }}</span>
    </div>
  </div>
  <div fxFlex='23'>
    <div fxLayout='row' fxLayoutAlign='center center'>
      <span class='review-container-text'>{{
        getPrettyReviewDate(review)
        }}</span>
    </div>
  </div>
  <div fxLayout='column' fxFlex='23'>
    <span class='review-subject-desc'>Ревьюируемый</span>
    <span class='review-container-text' *ngIf='review.subjects.length'>{{
      review.subjects[0].first_name + ' ' + review.subjects[0].last_name
      }}</span>
    <span class='review-container-text' *ngIf='!review.subjects.length'>
      Пользователь удален
    </span>
  </div>
</ng-template>
