import { APP_INITIALIZER, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { ReviewGuard } from './guards/review.guard';

import { environment } from 'src/environments/environment';

import { HomePage } from '@pages/homepage/homepage.page';
import { PageNotFoundComponent } from '@components/ui/page-not-found/page-not-found.component';
import { ApplicationErrorComponent } from '@components/ui/application-error/application-error.component';
import { AccessDeniedComponent } from '@components/ui/access-denied/access-denied.component';
import { LibraryMainPage } from '@pages/library/library-main.page';
import { SpecialityGroupCreatePage } from '@pages/library/speciality-groups/speciality-group-create/speciality-group-create.page';
import { SpecialityGroupEditPage } from '@pages/library/speciality-groups/speciality-group-edit/speciality-group-edit.page';
import { ThingPage } from '@pages/library/thing/thing.page';
import { ThingEditPage } from '@pages/library/thing/thing-edit/thing-edit.page';
import { ThingCreatePage } from '@pages/library/thing/thing-create/thing-create.page';
import { LibraryDomainPage } from '@pages/library/domain/domain.page';
import { SpecialityEditPage } from '@pages/library/speciality/speciality-edit/speciality-edit.page';
import { SpecialityCreatePage } from '@pages/library/speciality/speciality-create/speciality-create.page';
import { SpecialityDetailPage } from '@pages/library/speciality/speciality-detail/speciality-detail.page';
import { SpecialityListPage } from '@pages/library/speciality/speciality-list/speciality-list.page';
import { DocsTutorialQuickstartPage } from '@pages/docs/tutorial-quickstart.page';
import { MatchUsersPage } from '@pages/teams/match-users/match-users.page';
import { MatrixV3Page } from '@pages/teams/matrix-v3/matrix-v3.page';
import { MainTeamsPage } from '@pages/teams/main/main.page';
import { TeamSpecialitiesComponent } from './modules/teams/components/team-main/team-specialities/team-specialities.component';
import { TeamMembersComponent } from './modules/teams/components/team-main/team-members/team-members.component';
import { TeamSpecialityDetailComponent } from './modules/teams/components/team-main/team-specialities/team-speciality-detail/team-speciality-detail.component';
import { TeamReviewsComponent } from './modules/teams/components/team-main/team-reviews/team-reviews.component';
import { TeamReviewCreateComponent } from './modules/teams/components/team-main/team-reviews/team-review-create/team-review-create.component';
import { NewReviewDetailComponent } from './modules/review/components/new-review-detail/new-review-detail.component';
import { NewReviewResolutionDetailComponent } from './modules/review/components/new-review-resolution-detail/new-review-resolution-detail.component';
import { NewReviewAssessmentDetailComponent } from './modules/review/components/new-review-assessment-detail/new-review-assessment-detail.component';
import { NewProfileComponent } from './modules/user-profile/components/new-profile/new-profile.component';
import { TeamDesiresComponent } from './modules/teams/components/team-main/team-desires/team-desires.component';
import { NewReviewEditComponent } from './modules/review/components/new-review-edit/new-review-edit.component';
import { CalendarComponent } from './modules/projects/components/calendar/calendar.component';
import { CompaniesComponent } from './modules/projects/components/companies/companies.component';
import { CompanyProjectsComponent } from './modules/projects/components/company-projects/company-projects.component';
import { ProjectDetailsComponent } from './modules/projects/components/project-details/project-details.component';
import { ProjectEditComponent } from './modules/projects/components/project-edit/project-edit.component';
import { HttpClient } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { TeamSettingsComponent } from '@components/common/team-settings/team-settings.component';
import { VerificationDetailComponent } from './modules/speciality-verification/components/verification-detail/verification-detail.component';
import { TeamsDashboardNewDetailComponent } from './modules/teams/components/teams-dashboard-new/teams-dashboard-new-detail/teams-dashboard-new-detail.component';
import { UserTeamComponent } from './modules/user-dashboard/components/account-main/user-teams/user-team/user-team.component';
import { LibrarySpecialityGuard } from './guards/library-speciality.guard';
import { ThingDuplicatesComponent } from './modules/library/thing/thing-duplicates/thing-duplicates.component';
import { WysiwigExampleComponent } from '@pages/wysiwig-example/wysiwig-example.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    pathMatch: 'full',
    component: HomePage,
  },

  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/user-dashboard/user-dashboard.module').then(
        (m) => m.UserDashboardModule
      ),
    canActivate: [AuthGuard],
    data: { roles: ['user'] },
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./modules/user-dashboard/user-dashboard.module').then(
        (m) => m.UserDashboardModule
      ),
    canActivate: [AuthGuard],
    data: { roles: ['user'] },
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./modules/onboarding/onboarding.module').then(
        (m) => m.OnboardingModule
      ),
    canActivate: [AuthGuard],
    data: { roles: ['user'] },
  },
  {
    path: 'old/profile',
    loadChildren: () =>
      import('./modules/user-profile/user-profile.module').then(
        (m) => m.UserProfileModule
      ),
    canActivate: [AuthGuard],
    data: { roles: ['user'] },
  },
  {
    path: 'teams',
    loadChildren: () =>
      import('./modules/teams/teams.module').then((m) => m.TeamsModule),
    canActivate: [AuthGuard],
    data: { roles: ['user'] },
  },
  {
    path: 'surveys',
    loadChildren: () =>
      import('./modules/surveys/surveys.module').then((m) => m.SurveysModule),
    canActivate: [AuthGuard],
    data: { roles: ['user'] },
  },
  {
    path: 'ontologies',
    loadChildren: () =>
      import('./modules/ontologies/ontologies.module').then(
        (m) => m.OntologiesModule
      ),
    canActivate: [AuthGuard],
    data: { roles: ['user'] },
  },
  {
    path: 'track',
    loadChildren: () =>
      import('./modules/track/track.module').then((m) => m.TrackModule),
    canActivate: [AuthGuard],
    data: { roles: ['user'] },
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'library',
    canActivate: [AuthGuard],
    component: LibraryMainPage,
  },
  {
    path: 'library/speciality-groups/create',
    canActivate: [AuthGuard],
    component: SpecialityGroupCreatePage,
    data: { roles: ['user'] },
  },
  {
    path: 'library/speciality-groups/:uuid/edit',
    canActivate: [AuthGuard],
    component: SpecialityGroupEditPage,
    data: { roles: ['user'] },
  },
  {
    path: 'things/create',
    canActivate: [AuthGuard],
    component: ThingCreatePage,
    data: { roles: ['user'] },
  },
  {
    path: 'things/:uuid',
    canActivate: [AuthGuard],
    component: ThingPage,
  },
  {
    path: 'things/:uuid/edit',
    canActivate: [AuthGuard],
    component: ThingEditPage,
    data: { roles: ['user'] },
  },
  {
    path: 'things/:uuid/duplicates',
    canActivate: [AuthGuard],
    component: ThingDuplicatesComponent,
  },
  {
    path: 'library/domains/:uuid',
    canActivate: [AuthGuard],
    component: LibraryDomainPage,
  },
  {
    path: 'library/specialities',
    canActivate: [AuthGuard],
    component: SpecialityListPage,
  },
  {
    path: 'library/specialities/create',
    canActivate: [AuthGuard],
    component: SpecialityCreatePage,
    data: { roles: ['user'] },
  },
  {
    path: 'library/specialities/:uuid',
    canActivate: [AuthGuard],
    component: SpecialityDetailPage,
    data: { roles: ['user'] },
  },
  {
    path: 'library/specialities/:uuid/edit',
    canActivate: [AuthGuard, LibrarySpecialityGuard],
    component: SpecialityEditPage,
    data: { roles: ['user'] },
  },

  {
    path: 'docs/tutorial/quickstart',
    component: DocsTutorialQuickstartPage,
  },

  {
    path: 'wysiwyg',
    component: WysiwigExampleComponent,
  },

  {
    path: 'team',
    component: MainTeamsPage,
    canActivate: [AuthGuard],
    data: { roles: ['user'] },
    children: [
      {
        path: ':uuid/settings',
        component: TeamSettingsComponent,
        runGuardsAndResolvers: 'paramsChange',
      },
      {
        path: ':uuid/specialities',
        component: TeamSpecialitiesComponent,
        runGuardsAndResolvers: 'paramsChange',
      },
      {
        path: ':uuid/specialities/:sUuid',
        component: TeamSpecialityDetailComponent,
      },
      {
        path: ':uuid/members',
        component: TeamMembersComponent,
        runGuardsAndResolvers: 'paramsChange',
      },
      {
        path: ':uuid/members/:userUuid',
        component: NewProfileComponent,
      },
      {
        path: ':uuid/reviews',
        component: TeamReviewsComponent,
        runGuardsAndResolvers: 'paramsChange',
      },
      {
        path: ':uuid/reviews/:rUuid',
        component: NewReviewDetailComponent,
      },
      {
        path: ':uuid/reviews/:rUuid/edit',
        component: NewReviewEditComponent,
      },
      {
        path: ':uuid/reviews/:rUuid/assessment/:aUuid',
        component: NewReviewAssessmentDetailComponent,
      },
      {
        path: ':uuid/reviews/:rUuid/resolution/:resUuid',
        component: NewReviewResolutionDetailComponent,
      },
      {
        path: ':uuid/review/create',
        component: TeamReviewCreateComponent,
      },
      {
        path: ':uuid/desires',
        component: TeamDesiresComponent,
      },
    ],
  },
  {
    path: 'teams/:uuid',
    component: TeamsDashboardNewDetailComponent,
    runGuardsAndResolvers: 'paramsChange',
  },
  {
    path: 'teams/:uuid/members',
    canActivate: [AuthGuard],
    component: UserTeamComponent,
    data: { roles: ['user'] },
  },
  { path: 'teams/:uuid/manage/match-users', component: MatchUsersPage },
  { path: 'teams/:uuid/manage/matrix-v3', component: MatrixV3Page },

  { path: 'companies', component: CompaniesComponent },
  { path: 'companies/:uuid', component: CompanyProjectsComponent },
  {
    path: 'companies/:uuid/project/:pUuid',
    component: ProjectDetailsComponent,
  },
  {
    path: 'companies/:uuid/project/:pUuid/edit',
    component: ProjectEditComponent,
  },
  { path: 'projects/:uuid/calendar', component: CalendarComponent },
  {
    path: 'verifications/:uuid/speciality',
    component: VerificationDetailComponent,
  },

  { path: '401', component: AccessDeniedComponent },
  { path: '403', component: AccessDeniedComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: '500', component: ApplicationErrorComponent },
  { path: '**', component: PageNotFoundComponent },
];

function initializeKeycloakWithConfig(
  http: HttpClient,
  keycloak: KeycloakService
) {
  return () => {
    environment.apiUrl = environment.apiUrl
      ? environment.apiUrl
      : window.location.hostname === 'localhost'
      ? `http://${window.location.hostname}:8000`
      : window.location.origin;

    return new Promise((resolve, reject) => {
      http
        .get(`${environment.apiUrl}/api/1/settings/frontend`)
        .subscribe((response: any) => {
          environment.keycloak.url = response.url;
          environment.keycloak.realm = response.realm;
          environment.keycloak.role_admin = response.role_admin;
          environment.keycloak.role_user = response.role_user;
          routes.forEach((route) => {
            if (route.data?.roles[0]) {
              switch (route.data.roles[0]) {
                case 'user':
                  route.data.roles[0] = environment.keycloak.role_user;
                  break;
                case 'admin':
                  route.data.roles[0] = environment.keycloak.role_admin;
                  break;
              }
            }
          });
          keycloak
            .init({
              config: {
                url: environment.keycloak.url,
                realm: environment.keycloak.realm,
                clientId: environment.keycloak.client_id,
              },
              initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri:
                  window.location.origin + '/assets/silent-check-sso.html',
              },
              bearerExcludedUrls: ['/assets'],
              loadUserProfileAtStartUp: true,
            })
            .then(() => resolve(true));
        });
    });
  };
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloakWithConfig,
      multi: true,
      deps: [HttpClient, KeycloakService],
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
