import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { Thing } from '@models/ontology/thing';
import { Domain } from '@models/ontology/domain';

import Tree, { TreeNode } from 'src/app/utils/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';

import { User } from '@models/user/user';
import { Team } from '@models/teams/team';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TeamHttpService } from '@services/http/TeamHttpService';
import { AsyncList } from '@rest/AsyncList';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { StaticTextField } from '@components/common/static-textfield-group/static-textfield-group.component';
import { TeamMembership } from '@models/teams/team-membership';
import { TeamMembershipHttpService } from '@services/http/TeamMembershipHttpService';
import { AuthService } from '../../../../../services/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { EditTeamMemberStatusDialogComponent } from '@components/common/edit-team-member-status-dialog/edit-team-member-status-dialog.component';
import { TeamMemberStatusHttpService } from '@services/http/TeamMemberStatusHttpService';
import { TeamMemberStatus } from '@models/teams/team-member-status';
import { TeamMembersStatusConfirmDialogComponent } from '@components/common/team-members-status-confirm-dialog/team-members-status-confirm-dialog.component';
import { StatsHttpService } from '@services/http/StatsHttpService';
import { MatrixLoadingStatsHttpService } from '@services/http/MatrixLoadingStatsHttpService';

export interface DomainTreeModel extends Domain {
  isThing: boolean;
  isExpanded: boolean;
}

export interface ThingTreeModel extends Thing {
  isThing: boolean;
  isExpanded: boolean;
  parent_domain: string;
}

type TreeModel = DomainTreeModel | ThingTreeModel;

interface MatrixCompliance {
  current_level_name: string;
  current_level: number;
  expected_level: number;
  percentage: number;
  speciality: string;
  is_verified: boolean;
}

interface MatrixDomain {
  current_level: number;
  expected_level: number;
  percentage: number;
  unverified_count: number;
  total: number;
  specialities_compliance?: {
    [speciality_uuid: string]: {
      current_level: number;
      expected_level: number;
      percentage: number;
      unverified_count: number;
      total: number;
    };
  };
}

interface MatrixUserData {
  things: {
    [thingUuid: string]: {
      compliance: MatrixCompliance[];
      desired: boolean;
      thing: Thing;
    };
  };
  domains: {
    [domainUuid: string]: MatrixDomain;
  };
  user_grade: string;
}

interface MatrixTeamData {
  users_data: {
    [userUuid: string]: MatrixUserData;
  };
  team_users: User[];
}

interface MatrixData {
  things: Thing[];
  domains: Domain[];
  teams: Team[];
  teams_data: {
    [teamUuid: string]: MatrixTeamData;
  };
  is_actual: boolean;
}

interface DownloadDataMap {
  things: DownloadItem[];
  users: User[];
  maxLevel: number;
}

interface DownloadItem {
  uuid: string;
  name: string;
  level: number;
  complianceData: object;
  children: DownloadItem[];
}

export interface AngularDefaultTreeNode {
  expandable: boolean;
  name: string;
  level: number;
  uuid: string;
  children: TreeNode<TreeModel>[];
}

interface Summary {
  additionalCount: number;
  matchedCount: number;
  unmatchedCount: number;
}

interface TeamMembersStatusMap {
  [userUuid: string]: TeamMemberStatus;
}

interface SpecialityUsersMap {
  [specialityUuid: string]: User[];
}

interface SpecialitiesThingsDomainsMap {
  [specialityUuid: string]: {
    things: Thing[];
  };
}

interface ThingCellDataMap {
  [userUuid: string]: {
    [thingUuid: string]: {
      color: string;
      text: string;
      desired: boolean;
    };
  };
}

interface DomainCellDataMap {
  [userUuid: string]: {
    [domainUuid: string]: {
      color: string;
      text: string;
    };
  };
}

@Component({
  selector: 'app-team-manage-matrix-v3',
  templateUrl: './team-manage-matrix-v3.component.html',
  styleUrls: ['./team-manage-matrix-v3.component.css'],
})
export class TeamManageMatrixV3Component implements OnInit {
  @Input() teamUuid = '';
  @Input() isMatrixTabSelected = false;
  @Input() specialityUsersMap: SpecialityUsersMap;
  @Input() specialitiesThingsDomainsMap: SpecialitiesThingsDomainsMap;
  @Input() embedded = true;
  tree: Tree<TreeModel>;
  teamStatusMap = {};
  teamWithParents: Team[] = [];

  defaultTeam: Team[];
  teams: Team[] = [];
  matrixData: MatrixData = null;
  matrixInfoData: StaticTextField[] = [];

  specialities: AsyncList<Speciality>;
  userRoles: AsyncList<TeamMembership>;
  teamMembersStatus: AsyncList<TeamMemberStatus>;
  teamMembersStatusMap: TeamMembersStatusMap = {};
  busFactorCount = 0;
  keyPeopleCount = 0;

  selectInputDataForm: UntypedFormGroup;
  toggleShowTeamsForm: UntypedFormGroup;

  filterSpecialityUuid: string;
  filteredUsers: User[];

  isMatrixLoaded: boolean;
  isMatrixExpanded = false;
  isMatrixExpansionComplete = true;
  isConfirmDialogOpened = false;
  loadChildTeams = false;

  thingCellsDataMap: ThingCellDataMap;
  domainCellsDataMap: DomainCellDataMap;
  downloadDataMap: DownloadDataMap;

  stickyUsers = false;
  stickyCompetencies = false;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _dialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
    private _authService: AuthService,
    private _teamHttpService: TeamHttpService,
    private _teamMembershipHttpService: TeamMembershipHttpService,
    private _teamMemberStatusHttpService: TeamMemberStatusHttpService,
    private _specialityHttpService: SpecialityHttpService,
    private _matrixLoadingStatsHttpService: MatrixLoadingStatsHttpService
  ) {}

  ngOnInit(): void {
    this._loadTeams();
  }

  openTeamMembersStatusConfirmDialog(): void {
    const filteredTeamMembersStatus = this.teamMembersStatus;
    filteredTeamMembersStatus.state.items =
      this.teamMembersStatus.state.items.filter(
        (status) =>
          !status.is_verified_by_owner &&
          (status.bus_factor || status.key_people)
      );
    if (filteredTeamMembersStatus.state.items.length) {
      this._dialog
        .open(TeamMembersStatusConfirmDialogComponent, {
          data: {
            teamMembersStatus: this.teamMembersStatus,
            users: this.matrixData.teams_data[this.teamUuid].team_users,
          },
        })
        .afterClosed()
        .subscribe(() => {
          this._loadTeamMembersStatus();
        });
      this.isConfirmDialogOpened = true;
      this._cdr.detectChanges();
    } else {
      this.isConfirmDialogOpened = true;
      this._cdr.detectChanges();
    }
  }

  private _loadTeams(): void {
    const teams = new AsyncList<Team>(this._teamHttpService);
    teams.load().subscribe((response) => {
      this.teams = response.results;
      this.defaultTeam = [
        this.teams.find((team) => team.uuid === this.teamUuid),
      ];
      this._getTeamParent(this.defaultTeam[0]);
      this._loadUserRoles();
      this._loadSpecialities();
    });
  }

  private _getTeamParent(childTeam: Team): void {
    this.teamWithParents.push(childTeam);
    const parentTeam = this.teams.find(
      (team) => childTeam.parent === team.uuid
    );
    if (parentTeam) this._getTeamParent(parentTeam);
  }

  private _loadUserRoles(): void {
    this.userRoles = new AsyncList<TeamMembership>(
      this._teamMembershipHttpService
    );
    this.userRoles.setRequestParams({
      params: {
        user: this._authService.get_current_user_uuid(),
        team__in: this.teamWithParents.map((team) => team.uuid).join(','),
        page_size: 100,
        expand: 'team',
      },
    });
    this.userRoles.load().subscribe(() => this._loadTeamMembersStatus());
  }

  private _loadTeamMembersStatus(): void {
    this.teamMembersStatus = new AsyncList<TeamMemberStatus>(
      this._teamMemberStatusHttpService
    );
    this.teamMembersStatus.setRequestParams({
      params: {
        team: this.teamUuid,
      },
    });
    this.teamMembersStatus.load().subscribe(() => {
      this._buildTeamMembersStatusMap();
    });
  }

  private _loadSpecialities(): void {
    this.specialities = new AsyncList<Speciality>(this._specialityHttpService);
    this.specialities.setRequestParams({
      params: {
        team__in: [this.teamUuid],
      },
    });
    // this.specialities.setRequestParams({
    //   params: {
    //     team__in: this.teams.map((team) => team.uuid).join(','),
    //   },
    // });
    this.specialities.load().subscribe(() => {
      this.loadMatrixData();
      // this._initSelectTeamsForm();
    });
  }

  private _buildTeamMembersStatusMap(): void {
    this.keyPeopleCount = 0;
    this.busFactorCount = 0;
    this.teamMembersStatus.state.items.forEach((teamMemberStatus) => {
      if (teamMemberStatus.key_people) this.keyPeopleCount += 1;
      if (teamMemberStatus.bus_factor) this.busFactorCount += 1;
      this.teamMembersStatusMap[teamMemberStatus.member as string] =
        teamMemberStatus;
    });
    this._buildMatrixInfo();
  }

  private _buildMatrixInfo(): void {
    const text = this.matrixInfoData.length
      ? this.matrixInfoData[0].text
      : 'Загружаем данные...';

    this.matrixInfoData = [
      {
        name: 'Всего в команде',
        text: text,
      },
      {
        name: 'Bus factor',
        text:
          this.isOwner() || this.isAdmin()
            ? this.busFactorCount.toString()
            : 'Доступно только руководителям',
      },
      {
        name: 'Key people',
        text:
          this.isOwner() || this.isAdmin()
            ? this.keyPeopleCount.toString()
            : 'Доступно только руководителям',
      },
    ];
  }

  // private _initSelectTeamsForm(): void {
  //   this.selectInputDataForm = this._formBuilder.group({
  //     teams: [this.teams, [Validators.required]],
  //     specialities: [this.specialities.state.items, [Validators.required]],
  //   });
  //   this.selectInputDataForm.controls.teams.setValue([this.teamUuid]);
  //   this.loadMatrixData();
  // }

  updateTeams($event: Team[]): void {
    this.selectInputDataForm.setValue({
      ...this.selectInputDataForm.value,
      teams: $event.map(this._serializeTeam),
    });
  }

  onFilterSpecialityChange(specialityUuid: string): void {
    if (specialityUuid === 'Все специальности') {
      this.filterSpecialityUuid = '';
      this._setFilteredUsers();
      this._buildCellsData();
      this._buildTree();
    } else {
      this.filterSpecialityUuid = specialityUuid;
      if (this.specialityUsersMap[specialityUuid]) {
        this.filteredUsers = this.specialityUsersMap[specialityUuid].sort(
          (a, b) => a.uuid.localeCompare(b.uuid)
        );
      } else {
        this.filteredUsers = [];
      }
      this._buildCellsData();
      this._buildTree(specialityUuid);
    }
    this.filteredUsers = this.sortUsersByReviewStatus(
      this.filteredUsers,
      this.matrixData
    );
  }

  onToggleLoadChildTeams(): void {
    this.loadChildTeams = !this.loadChildTeams;
  }

  private _serializeTeam(team: Team): string {
    return team.uuid;
  }

  loadMatrixData(): void {
    this.isMatrixLoaded = false;
    const startLoading = Date.parse(new Date().toISOString());
    this._teamHttpService
      .getMatrix({
        teams: [this.teamUuid],
        specialities: this.specialities.state.items.map(
          (speciality) => speciality.uuid
        ),
        with_child_teams: false,
      })
      .subscribe(
        (response) => {
          const endLoading = Date.parse(new Date().toISOString());
          this._matrixLoadingStatsHttpService
            .create({
              loading_time: (endLoading - startLoading) / 1000,
            })
            .subscribe();

          this.matrixData = response;
          this._setFilteredUsers();

          // сортируем юзеров по тем кто прошел ревью
          this.filteredUsers = this.sortUsersByReviewStatus(
            this.filteredUsers,
            this.matrixData
          );

          // caluculate matrix info
          // set default value
          if (!this.matrixInfoData.length) {
            this.matrixInfoData.push({ text: '0', name: 'Всего в команде' });
          }

          let membersCount = 0;
          if (this.matrixData.teams_data) {
            for (const [_, teamData] of Object.entries(
              this.matrixData.teams_data
            )) {
              membersCount = membersCount + teamData.team_users.length;
            }
            this.matrixInfoData[0].text = `${membersCount}`;
          }
          this._buildCellsData();
          this._buildTeamStatusMap();
          this._buildTree();
          // this._initForm();
          this.isMatrixLoaded = true;
        },
        () => {}
      );
  }

  private _setFilteredUsers(): void {
    this.filteredUsers = [];
    Object.values(this.matrixData.teams_data).forEach((teamData) => {
      this.filteredUsers.push(...teamData.team_users);
    });
    if (this.filteredUsers.length)
      this.filteredUsers.sort((a, b) => a.uuid.localeCompare(b.uuid));
  }

  private _initForm(): void {
    this.toggleShowTeamsForm = this._formBuilder.group({
      teams: [this.matrixData.teams, []],
    });
  }

  private _buildCellsData(): void {
    this.domainCellsDataMap = {};
    this.thingCellsDataMap = {};
    Object.keys(this.matrixData.teams_data).forEach((teamUuid) => {
      Object.entries(this.matrixData.teams_data[teamUuid].users_data).forEach(
        ([userUuid, userData]) => {
          this.domainCellsDataMap[userUuid] = {};
          this.thingCellsDataMap[userUuid] = {};
          this.matrixData.domains.forEach((domain) => {
            this.domainCellsDataMap[userUuid][domain.uuid] = {
              text: this.getDomainCellText(userData.domains[domain.uuid]),
              color: this.getDomainCellBackground(
                userData.domains[domain.uuid]
              ),
            };
          });
          this.matrixData.things.forEach((thing) => {
            this.thingCellsDataMap[userUuid][thing.uuid] = {
              text: this.getCellText(userData.things[thing.uuid]?.compliance),
              color: this.getCellBackground(
                userData.things[thing.uuid]?.compliance
              ),
              desired: userData.things[thing.uuid]?.desired,
            };
          });
        }
      );
    });
  }

  private _buildTeamStatusMap(): void {
    this.matrixData.teams.forEach((team) => {
      this.teamStatusMap[team.uuid] = true;
    });
  }

  private _prepareThingTreeIntegration(thing: Thing) {
    return {
      ...thing,
      parent_domain: thing.domain,
      isThing: true,
      isExpanded: false,
    };
  }

  private _prepareDomainTreeIntegration(domain: Domain) {
    return {
      ...domain,
      isThing: false,
      isExpanded: false,
    };
  }

  private _buildTree(specialityUuid?: string): void {
    let matrixTreeThings;
    let matrixTreeDomains;
    if (specialityUuid && this.specialitiesThingsDomainsMap[specialityUuid]) {
      matrixTreeThings = this.specialitiesThingsDomainsMap[
        specialityUuid
      ].things.map((thing) => this._prepareThingTreeIntegration(thing));
      matrixTreeDomains = this._getMatrixDomains(
        this.specialitiesThingsDomainsMap[specialityUuid].things
      ).map((domain) => this._prepareDomainTreeIntegration(domain));
    } else {
      matrixTreeThings = this._getMatrixThings();
      matrixTreeDomains = this._getMatrixDomains().map((domain) =>
        this._prepareDomainTreeIntegration(domain)
      );
    }

    this.tree = new Tree<TreeModel>(
      [...matrixTreeDomains, ...matrixTreeThings],
      'parent_domain'
    );

    this.dataSource.data = this.tree.getRoot().children;

    if (this.dataSource.data.length === 1) {
      this.dataSource.data[0].item.isExpanded = true;
    }
  }

  private _addNodeToDownloadData(
    parentItem: DownloadItem,
    node: TreeNode<TreeModel>,
    level: number
  ): void {
    const usersData = Object.values(
        this.matrixData.teams_data[this.teamUuid].users_data
      ),
      summary = node.children.length
        ? this.getDomainSummary(usersData, node.item.uuid)
        : this.getThingSummary(usersData, node.item.uuid),
      downloadItem = {
        uuid: node.item.uuid,
        name: node.item.name,
        level: level,
        complianceData: [
          // `${summary.additionalCount} ${summary.matchedCount} ${summary.unmatchedCount}`,
        ],
        children: [],
      };

    if (level > this.downloadDataMap.maxLevel)
      this.downloadDataMap.maxLevel += level;

    if (parentItem) {
      parentItem.children.push(downloadItem);
    } else {
      this.downloadDataMap.things.push(downloadItem);
    }

    // это сами навыки (Иностранные языки, Php Developer)
    if (node.children.length) {
      usersData.forEach((userData) => {
        const domainCompliance = userData.domains[downloadItem.uuid];
        downloadItem.complianceData.push({
          text: domainCompliance
            ? this.getDomainCellText(domainCompliance)
            : 'Не требуется',
          color: this.getDomainCellBackground(domainCompliance),
        });
      });
      node.children.forEach((childNode) => {
        this._addNodeToDownloadData(downloadItem, childNode, level + 1);
      });
    }
    // это уровни к навыкам (начинающий,увереный, соотвествует)
    else {
      usersData.forEach((userData) => {
        const compliance = userData.things[downloadItem.uuid]?.compliance;
        downloadItem.complianceData.push({
          text: this.getCellText(compliance),
          color: this.getCellBackground(compliance),
          desired: userData.things[downloadItem.uuid]?.desired,
        });
      });
    }
  }

  private sortUsersByReviewStatus(
    filteredUsers: User[],
    matrixData: MatrixData
  ): User[] {
    const teamUuid = Object.keys(matrixData.teams_data)[0];
    const userOrder = matrixData.teams_data[teamUuid].team_users.map(
      (user) => user.uuid
    );
    const result = filteredUsers.sort(
      (a, b) => userOrder.indexOf(a.uuid) - userOrder.indexOf(b.uuid)
    );
    return result;
  }

  private _getMatrixThings(): TreeModel[] {
    return this.matrixData.things.map((thing) =>
      this._prepareThingTreeIntegration(thing)
    );
  }

  private _getMatrixDomains(things?: Thing[]): TreeModel[] {
    if (!things) {
      things = this.matrixData.things;
    }
    const domainsTree = new Tree(this.matrixData.domains, 'parent_domain');

    const thingParentDomainUuids: any = things.map((thing) => thing.domain);

    const thingDomains = [];

    thingParentDomainUuids.forEach((domainUuid) => {
      const parentDomain = domainsTree.getNode(domainUuid);

      if (thingDomains.indexOf(parentDomain.item) === -1) {
        thingDomains.push(parentDomain.item);
      }

      domainsTree.getParents(parentDomain).forEach((node) => {
        if (thingDomains.indexOf(node.item) === -1) {
          thingDomains.push(node.item);
        }
      });
    });

    return thingDomains;
  }

  private _transformer = (node: TreeNode<TreeModel>, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.item.name,
      uuid: node.item.uuid,
      level: level,
      children: node.children,
    };
  };

  getNodeLevel(node: TreeNode<TreeModel>): number {
    return this.treeControl.dataNodes.find(
      (angularNode) => angularNode.uuid === node.item.uuid
    )?.level;
  }

  getNodeMargin(node: TreeNode<TreeModel>): object {
    return { 'margin-left': 10 * this.getNodeLevel(node) + 'px;' };
  }

  isNodeUneven(node: TreeNode<TreeModel>): boolean {
    return this.getNodeLevel(node) % 2 !== 0;
  }

  getMatrixTeamUuids(): string[] {
    return Object.keys(this.matrixData.teams_data);
  }

  getTeamUserUuids(teamUuid: string): string[] {
    return Object.keys(this.matrixData.teams_data[teamUuid].users_data);
  }

  getTeamUsersValues(teamUuid: string): MatrixUserData[] {
    const ret = [];
    Object.entries(this.matrixData.teams_data[teamUuid].users_data).forEach(
      ([userUuid, userData]) => {
        if (this.filteredUsers.find((user) => user.uuid === userUuid)) {
          ret.push(userData);
        }
      }
    );
    return ret;
  }

  getTeamUsers(teamUuid: string): { uuid: string; data: MatrixUserData }[] {
    const ret = [];
    Object.keys(this.matrixData.teams_data[teamUuid].users_data).forEach(
      (userUuid) => {
        if (this.filteredUsers.find((user) => user.uuid === userUuid)) {
          ret.push(userUuid);
        }
      }
    );
    return ret;
  }

  getThingSummary(usersData: MatrixUserData[], thingUuid: string): Summary {
    let matchedCount = 0,
      unmatchedCount = 0,
      additionalCount = 0;
    usersData.forEach((userData) => {
      const compliance = userData.things[thingUuid]?.compliance;
      if (compliance) {
        const complianceData = compliance[compliance.length - 1];
        if (complianceData.is_verified) {
          if (!complianceData.percentage && complianceData.percentage !== 0) {
            additionalCount += 1;
          } else if (complianceData.percentage > 99) {
            matchedCount += 1;
          } else {
            unmatchedCount += 1;
          }
        }
      }
    });
    return {
      matchedCount: matchedCount,
      unmatchedCount: unmatchedCount,
      additionalCount: additionalCount,
    };
  }

  getDomainSummary(usersData: MatrixUserData[], domainUuid: string): Summary {
    let matchedCount = 0,
      unmatchedCount = 0,
      additionalCount = 0;

    usersData.forEach((userData) => {
      const compliance = this.getSpecialityMatrixDomain(
        userData.domains[domainUuid]
      );
      if (compliance) {
        if (compliance.unverified_count !== compliance.total) {
          if (!compliance.percentage && compliance.percentage !== 0) {
            additionalCount += 1;
          } else if (
            compliance.percentage > 99 &&
            compliance.unverified_count === 0
          ) {
            matchedCount += 1;
          } else {
            unmatchedCount += 1;
          }
        }
      }
    });
    return {
      matchedCount: matchedCount,
      unmatchedCount: unmatchedCount,
      additionalCount: additionalCount,
    };
  }

  getSpecialityMatrixDomain(matrixDomain: MatrixDomain): MatrixDomain {
    if (this.filterSpecialityUuid && matrixDomain) {
      if (
        Object.keys(matrixDomain.specialities_compliance).includes(
          this.filterSpecialityUuid
        )
      ) {
        matrixDomain =
          matrixDomain.specialities_compliance[this.filterSpecialityUuid];
      }
    }
    return matrixDomain;
  }

  getDomainCellText(matrixDomain: MatrixDomain): string {
    matrixDomain = this.getSpecialityMatrixDomain(matrixDomain);
    let text = 'Оценка не пройдена';
    if (matrixDomain) {
      if (
        matrixDomain.unverified_count > 0 &&
        matrixDomain.unverified_count === matrixDomain.total
      ) {
        text = 'Оценка не пройдена';
      } else {
        if (!matrixDomain.percentage && matrixDomain.percentage !== 0) {
          if (matrixDomain.current_level > 0) {
            text = 'Не требуется, но владеет';
          } else {
            text = 'Не требуется';
          }
        } else {
          if (
            matrixDomain.percentage > 99 &&
            matrixDomain.unverified_count === 0
          ) {
            text = 'Соответствует';
          } else {
            text = 'Не соответствует';
          }
        }
      }
    }
    return text;
  }

  getCellText(compliance: Partial<MatrixCompliance>[]): string {
    const ret = 'Оценка не пройдена';
    if (!compliance) {
      return ret;
    }
    if (!compliance[0].is_verified) {
      return ret;
    }
    return compliance[0].current_level_name;
  }

  // getCellTooltip(compliance: MatrixCompliance[]): string {
  //   let retStr = '';
  //   if (compliance) {
  //     compliance.forEach((complianceData) => {
  //       const { percentage, speciality, current_level, expected_level } =
  //         complianceData;
  //       if (percentage || percentage === 0) {
  //         const formattedPercentage =
  //           percentage > 100 ? 100 : percentage.toFixed(0);
  //
  //         retStr += `${speciality
  //         }: ${formattedPercentage}% (${current_level}/${expected_level}) \n`;
  //       }
  //     });
  //   }
  //   return retStr;
  // }

  getDomainCellBackground(matrixDomain: MatrixDomain): string {
    matrixDomain = this.getSpecialityMatrixDomain(matrixDomain);
    let color = '#f9f9f9';
    if (matrixDomain) {
      if (
        matrixDomain.unverified_count > 0 &&
        matrixDomain.unverified_count === matrixDomain.total
      ) {
        //жаваскрипт не отличает 0 от None, проверяем здесь, если у пользователя есть навык, но нет специальности
        color = '#f9f9f9';
      } else if (!matrixDomain.percentage && matrixDomain.percentage !== 0) {
        color = '#cae2f6';
      } else if (
        matrixDomain.percentage > 99 &&
        matrixDomain.unverified_count === 0
      ) {
        color = '#e0f6ca';
      } else {
        color = '#FFE8E1';
      }
    }
    return color;
  }

  getCellBackground(compliance: Partial<MatrixCompliance>[]): string {
    let color = '#f9f9f9';
    if (compliance) {
      compliance.forEach((complianceData) => {
        if (complianceData.is_verified) {
          if (!complianceData.percentage && complianceData.percentage !== 0) {
            //жаваскрипт не отличает 0 от None, проверяем здесь, если у пользователя есть навык, но нет специальности
            color = '#cae2f6';
          } else if (complianceData.percentage > 99) {
            color = '#e0f6ca';
          } else {
            color = '#FFE8E1';
          }
        }
      });
    }
    return color;
  }

  getUserGradeName(teamUuid: string, userUuid: string): string {
    return this.matrixData.teams_data[teamUuid].users_data[userUuid].user_grade;
  }

  downloadMatrix(): void {
    if (!this.downloadDataMap) {
      this.downloadDataMap = { things: [], users: [], maxLevel: 1 };
      this.dataSource.data.forEach((node) => {
        this._addNodeToDownloadData(null, node, 0);
      });
      this.downloadDataMap.users = this.matrixData.teams_data[
        this.teamUuid
      ].team_users.map((user) => {
        return {
          ...user,
          user_grade: this.getUserGradeName(this.teamUuid, user.uuid),
        };
      });
    }

    this._teamHttpService
      .downloadMatrix({ data: this.downloadDataMap })
      .subscribe((response) => {
        const href = URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'file.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  toggleTreeExpansion(): void {
    this.isMatrixExpanded = !this.isMatrixExpanded;
    this.isMatrixExpansionComplete = false;
    let timeoutCount = 0;
    this.dataSource.data.forEach((node) => {
      timeoutCount = this.toggleNodeExpansion(
        node,
        this.isMatrixExpanded,
        timeoutCount
      );
    });
    setTimeout(() => {
      this.isMatrixExpansionComplete = true;
    }, timeoutCount);
  }

  toggleNodeExpansion(
    node: TreeNode<TreeModel>,
    expansionMode: boolean,
    timeoutCount: number
  ): number {
    if (node.children.length) {
      const timeout = node.children.length * 10;
      timeoutCount += timeout;
      setTimeout(() => {
        node.item.isExpanded = expansionMode;
        node.children.forEach((childNode) => {
          this.toggleNodeExpansion(childNode, expansionMode, timeoutCount);
        });
      }, timeout);
      return timeoutCount;
    }
  }

  getTeamName(teamUuid: string): string {
    return this.matrixData.teams.find((team) => team.uuid === teamUuid).name;
  }

  toggleTeamStatus(event: any): void {
    if (event.isUserInput) {
      const teamUuid = event.source.value.uuid;
      this.teamStatusMap[teamUuid] = !this.teamStatusMap[teamUuid];
    }
  }

  getNodeName(node: any): string {
    return '';
  }

  onMatrixUserClick(user: User): void {
    this._dialog
      .open(EditTeamMemberStatusDialogComponent, {
        data: {
          user: user,
          teamUuid: this.teamUuid,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this._loadTeamMembersStatus();
      });
  }

  isAdmin(): boolean {
    return this._authService.is_admin();
  }

  isOwner(): boolean {
    return !!this.userRoles.state.items.find(
      (membership) => membership.role === 'owner'
    );
  }

  _expandAngularTreeNode(
    node: AngularDefaultTreeNode,
    nodes: AngularDefaultTreeNode[],
    nextOpenLevel: number
  ): void {
    if (node.level !== 0) {
      const prevIndex = nodes.indexOf(node) - 1;

      if (nodes[prevIndex].level === nextOpenLevel) {
        this.treeControl.expand(nodes[prevIndex]);
      }

      this._expandAngularTreeNode(
        nodes[prevIndex],
        nodes,
        nodes[prevIndex].level === nextOpenLevel
          ? nextOpenLevel - 1
          : nextOpenLevel
      );
    }
  }

  // angular tree methods
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  hasChild = (_: number, node: AngularDefaultTreeNode): boolean => {
    return node.expandable;
  };

  treeControl = new FlatTreeControl<AngularDefaultTreeNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
}
