<div fxLayout="row" *ngIf="processing" class="is-processing">
  <div fxFlex fxFlexOffset="45%">
    <div class="preloader-container">
      <div class="spinner-text">Загрузка...</div>
      <div class="spinner-container">
        <mat-progress-spinner
          [diameter]="50"
          [color]="color"
          [mode]="mode"
          [strokeWidth]="3"
        ></mat-progress-spinner>
      </div>
    </div>
  </div>
</div>
<ng-content></ng-content>
