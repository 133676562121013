import { Injectable } from '@angular/core';
import { ServiceSettingsHttpService } from '@services/http/ServiceSettihgsHttpService';
import { ServiceSettings } from '@models/settings/service-settings';
import { AsyncList } from '@rest/AsyncList';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private _settings: ServiceSettings;

  serviceSettings: AsyncList<ServiceSettings>;

  constructor(
    private _serviceSettingsHttpService: ServiceSettingsHttpService
  ) {}

  public async loadSettings(): Promise<void> {
    this.serviceSettings = new AsyncList<ServiceSettings>(
      this._serviceSettingsHttpService
    );
    this.serviceSettings.load().subscribe((response) => {
      if (response) {
        this._settings = response.results[0];
      } else {
        // create defaults if there is no settings created yet
        this._settings = {
          uuid: '',
          name: '',
          service_url: '',
          email_settings: '',
          use_team_validation: false,
        } as ServiceSettings;
      }
    });
  }

  public getSettings(): ServiceSettings {
    return this._settings;
  }

  public setSettings(settings: ServiceSettings): void {
    this._settings = settings;
  }
}
