<div class="select-grade-level" *ngFor="let grade of grades; let i = index">
  <span class="grade-name">{{ grade.name }}</span>
  <mat-select
    class="grade-level"
    [value]="getGradeValue(i)"
    (selectionChange)="onChange(i, $event.value)"
  >
    <mat-option *ngFor="let level of levels" [value]="level.uuid">
      {{ level.title }}
    </mat-option>
  </mat-select>
</div>
