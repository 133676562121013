import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewSetHttpService } from './rest/ViewSetHttpService';
import { ServiceSettings } from '@models/settings/service-settings';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServiceSettingsHttpService extends ViewSetHttpService<ServiceSettings> {
  http: HttpClient;
  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }
  public viewSetUrl = 'settings/services';

  public sendTestEmail(email: string): Observable<any> {
    return this.http.post(`${this.getUrl()}/send_test_email`, { email });
  }
}
