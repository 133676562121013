import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AsyncList } from '@rest/AsyncList';
import { User } from '@models/user/user';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { AuthService } from '../../../../../services/auth/auth.service';
import { UserHttpService } from '@services/http/UserHttpService';
import { DevelopmentPlanHttpService } from '@services/http/DevelopmentPlanHttpService';
import { AsyncDetail } from '@rest/AsyncDetail';
import { DevelopmentPlan } from '@models/tracks/development-plan';

@Component({
  selector: 'app-pdp-edit',
  templateUrl: './pdp-edit.component.html',
  styleUrls: ['./pdp-edit.component.css'],
})
export class PdpEditComponent implements OnInit {
  form: UntypedFormGroup;

  pdpUuid: string;

  pdp: AsyncDetail<DevelopmentPlan>;
  users: AsyncList<User>;

  options: User[];

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    private _dateAdapter: DateAdapter<Date>,
    private _authService: AuthService,
    private _userHttpService: UserHttpService,
    private _developmentPlanHttpService: DevelopmentPlanHttpService
  ) {
    this._dateAdapter.setLocale('ru-RU'); // dd/MM/yyyy
  }

  ngOnInit(): void {
    this.pdpUuid = this._activatedRoute.snapshot.paramMap.get('uuid');

    this.form = this._formBuilder.group({
      objective: ['', [Validators.required]],
      personal_value: ['', [Validators.required]],
      accomplishment_indicator: ['', [Validators.required]],
      responsible_user: ['', [Validators.required]],
      due_date: ['', [Validators.required]],
    });

    this.pdp = new AsyncDetail<DevelopmentPlan>(
      this.pdpUuid,
      this._developmentPlanHttpService
    );
    this.pdp.requestParams = {
      params: {
        expand: 'responsible_user',
      },
    };
    this.pdp.load().subscribe(() => {
      this.setPdpData();
    });

    this.users = new AsyncList<User>(this._userHttpService);
    this.users.load().subscribe(() => {
      this.options = this.users.state.items;
    });
  }

  setPdpData(): void {
    const pdp = this.pdp.state.item;
    this.form.setValue({
      objective: pdp.objective,
      personal_value: pdp.personal_value,
      organisation_value: '',
      accomplishment_indicator: pdp.accomplishment_indicator,
      responsible_user: pdp.responsible_user,
      due_date: new Date(pdp.due_date),
    });
  }

  onUserSelection(user: User): void {
    this.form.controls.responsible_user.setValue(user);
  }

  onBackClick(): void {
    history.back();
  }

  onPdpCreate(): void {
    const formControls = this.form.controls;
    this._developmentPlanHttpService
      .update(this.pdpUuid, {
        subject_user: this._authService.get_current_user_uuid(),
        responsible_user: formControls.responsible_user.value.uuid,
        objective: formControls.objective.value,
        accomplishment_indicator: formControls.accomplishment_indicator.value,
        personal_value: formControls.personal_value.value,
        organisation_value: formControls.organisation_value.value,
        due_date: formControls.due_date.value.toISOString(),
      })
      .subscribe(() => {
        this.onBackClick();
      });
  }
}
