import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';
import { Thing } from 'src/app/models/ontology/thing';
import { ThingLevel } from 'src/app/models/ontology/thing-level';

@Injectable({
  providedIn: 'root',
})
export class ThingLevelsService {
  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService
  ) {}

  public fetch_all(params = {}): Observable<any> {
    return this.api_service.get(`ontology/thinglevel`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} thing levels`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch thing levels`
          );
        }
      )
    );
  }

  public fetch_by_uuid(uuid: string): Observable<any> {
    return this.api_service.get(`ontology/thinglevel/${uuid}`).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched thinglevel with uuid ${uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch thinglevel`
          );
        }
      )
    );
  }

  public fetch_by_thing_uuid(thing_uuid: string): Observable<any> {
    const params = { thing: thing_uuid };
    return this.api_service.get(`ontology/thinglevel`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched levels for thing ${thing_uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch levels for thing`
          );
        }
      )
    );
  }

  public create(thinglevel_data: any): Observable<any> {
    return this.api_service
      .post(`ontology/thinglevel`, thinglevel_data, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} created thinglevel with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to create thinglevel`
            );
          }
        )
      );
  }

  public update(
    thinglevel_uuid: string,
    thinglevel_data: any
  ): Observable<any> {
    return this.api_service
      .patch(`ontology/thinglevel/${thinglevel_uuid}`, thinglevel_data, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} updated thinglevel with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to update thinglevel`
            );
          }
        )
      );
  }

  public delete(thinglevel_uuid: string): Observable<any> {
    return this.api_service
      .delete(`ontology/thinglevel/${thinglevel_uuid}`, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} deleted thinglevel with uuid ${thinglevel_uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to delete thinglevel`
            );
          }
        )
      );
  }
}
