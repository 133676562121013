import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { AdoitArchitect } from '@models/adoit/adoit-architect';

@Injectable({
  providedIn: 'root',
})
export class AdoitArchitectHttpService extends ViewSetHttpService<AdoitArchitect> {
  constructor(_http: HttpClient) {
    super(_http);
  }

  public viewSetUrl = 'adoit/architects';
}
