<div fxLayout="row" fxLayoutGap="5rem" fxLayoutAlign="baseline center">
  <div fxFlex="20%" class="thing-text">
    {{ thing.name }}
    <br />
    <small>{{ thing.description }}</small>
  </div>

  <div fxFlex="20%">
    <div>
      <ng-select
        placeholder="Выберите уровень навыка"
        [items]="thing.levels"
        bindLabel="title"
        [clearable]="false"
        [searchable]="false"
        (change)="onSelectThingLevel($event)"
        [(ngModel)]="selectedThingLevel"
        notFoundText="У навыка нет уровней"
      ></ng-select>
    </div>
  </div>

  <div fxFlex="20%">
    <div>
      <ng-select
        [clearable]="false"
        [searchable]="false"
        placeholder="Соответствите уровню"
        [items]="levelMatchOptions"
        (change)="onSelectThingLevelMatch($event)"
        [(ngModel)]="selectedThingLevelMatch"
      ></ng-select>
    </div>
  </div>
  <div fxFlex="20%">
    <mat-checkbox
      (change)="toggleThingRequired($event.checked)"
      type="checkbox"
      required
      >Обязательный навык</mat-checkbox
    >
  </div>

  <div fxFlex="20%" fxLayout="row" fxLayoutAlign="start center">
    <span *ngIf="getThingSearchResults().isShow">
      Найдено: {{ getThingSearchResults().count }}
    </span>
  </div>
</div>
