import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  UrlTree,
} from '@angular/router';

interface MenuOption {
  url: string;
  name: string;
}

export interface OptionsMap {
  name: string;
  uuid: string;
  url: string | null;
  isExpanded: boolean;
  options: MenuOption[] | null;
}

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css'],
})
export class SideMenuComponent implements OnInit {
  @Input() title: string;
  @Input() optionsMap: OptionsMap[];

  selectedUrl = '';
  navigationSubscription;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    this.navigationSubscription = this._router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    if (this._activatedRoute.snapshot.firstChild) {
      const activatedUrl = this._activatedRoute.snapshot.firstChild.url
        .map((urlSegment) => urlSegment.path)
        .join('/');

      // for some reason NavigationEnd can be emitted while component is not rendered yet
      if (!this.optionsMap) return;

      this.optionsMap.forEach((entity) => {
        let matchingUrl;
        if (entity.url && activatedUrl.includes(entity.url)) {
          matchingUrl = entity.url;
        }
        if (!matchingUrl && entity.options) {
          matchingUrl = entity.options.find((option) =>
            activatedUrl.includes(option.url)
          )?.url;
        }
        if (matchingUrl) {
          this.selectedUrl = matchingUrl;
          entity.isExpanded = true;
        }
      });
    }
  }

  isSelected(url: string): boolean {
    return this.selectedUrl === url;
  }

  isAnyChildSelected(options: MenuOption[]): boolean {
    if (options) {
      return !!options.find((option) => this.isSelected(option.url));
    }
    return false;
  }

  getFullRoute(currentRoute: ActivatedRoute): string {
    let route = currentRoute.snapshot.routeConfig.path;
    if (currentRoute.parent.routeConfig) {
      route = this.getFullRoute(currentRoute.parent) + '/' + route;
    }
    return route;
  }

  onEntityClick(entity: OptionsMap): void {
    if (entity.url) {
      this.selectItem(entity.url);
    } else {
      entity.isExpanded = !entity.isExpanded;
    }
  }

  onOptionClick(option: MenuOption): void {
    this.selectItem(option.url);
  }

  selectItem(url: string): void {
    this.selectedUrl = url;
    this._router.navigate([
      `/${this.getFullRoute(this._activatedRoute)}/${url}`,
    ]);
  }
}
