import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from 'src/app/services/api.service';
import { LoggingService } from 'src/app/services/logging.service';

@Injectable({
  providedIn: 'root',
})
export class SpecialityCompetenceClaimService {
  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService
  ) {}

  public fetch_by_speciality_uuid(speciality_uuid: string): Observable<any> {
    const params = {
      speciality: speciality_uuid,
      expand: 'speciality,grade,thing.levels,thing_level',
    };
    return this.api_service.get(`specialities/competenceclaim`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched competence claims for speciality ${speciality_uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch speciality competence claims`
          );
        }
      )
    );
  }

  public create(claim_data: any): Observable<any> {
    const params = { expand: 'speciality,grade,thing' };
    return this.api_service
      .post(`specialities/competenceclaim`, claim_data, params)
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} created speciality competence claim with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to create speciality competence claim`
            );
          }
        )
      );
  }

  public add_claims_with_grades(claim_data: any): Observable<any> {
    const params = { expand: 'speciality,grade,thing' };
    return this.api_service
      .post(
        `specialities/competenceclaim/add_claims_with_grades`,
        claim_data,
        params
      )
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} created speciality competence claims`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to create speciality competence claims`
            );
          }
        )
      );
  }

  public update(claim_uuid: string, claim_data: any): Observable<any> {
    return this.api_service
      .patch(`specialities/competenceclaim/${claim_uuid}`, claim_data, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} updated speciality competence claim with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to update speciality competence claim`
            );
          }
        )
      );
  }

  public delete(claim_uuid: string): Observable<any> {
    return this.api_service
      .delete(`specialities/competenceclaim/${claim_uuid}`, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} deleted speciality competence claim with uuid ${claim_uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to delete speciality competence claim`
            );
          }
        )
      );
  }

  public on_speciality_thing_delete({
    thing,
    speciality,
  }: {
    thing: string;
    speciality: string;
  }) {
    const params = { expand: 'speciality,grade,thing' };
    return this.api_service
      .post(
        `specialities/competenceclaim/on_speciality_thing_delete`,
        { thing, speciality },
        params
      )
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} remove speciality ${speciality} competence claims thing ${thing}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to remove speciality ${speciality} competence claims thing ${thing}`
            );
          }
        )
      );
  }
}
