import { Component, OnInit } from '@angular/core';
import { zip } from 'rxjs';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { Domain } from '@models/ontology/domain';
import { Thing } from '@models/ontology/thing';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { ActivatedRoute, Router } from '@angular/router';
import { AsyncList } from '@rest/AsyncList';
import Tree, { TreeNode } from 'src/app/utils/tree';
import { ThingHttpService } from '@services/http/ThingHttpService';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  DetailFormData,
  DomainDetailFormConfig,
} from 'src/app/modules/library/domains/domain-detail/domain-detail-form/domain-detail-form.component';

import {
  AcceptDialogComponent,
  AcceptDialogData,
} from 'src/app/modules/common-ui-elements/components/accept-dialog/accept-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import { BreadCrumb } from '@models/ui/bread-crumbs';
import { DomainDetailEditDialogComponent } from './domain-detail-edit-dialog/domain-detail-edit-dialog.component';
import { getSpecialityDomains } from '../../../../utils/template-filters/get-speciality-domains';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DirectionHttpService } from '@services/http/DirectionHttpService';

interface DomainTreeModel extends Domain {
  isThing: boolean;
  parent_domain: string;
}

interface ThingTreeModel extends Thing {
  isThing: boolean;
  parent_domain: string;
}

type TreeModel = DomainTreeModel | ThingTreeModel;

export interface AngularDefaultTreeNode {
  expandable: boolean;
  name: string;
  level: number;
  isThing: boolean;
  uuid: string;
  parent_domain: string;
  children: TreeNode<TreeModel>[];
}

@Component({
  selector: 'app-library-domain-detail',
  templateUrl: './domain-detail.component.html',
  styleUrls: ['./domain-detail.component.css'],
})
export class LibraryDomainDetailComponent implements OnInit {
  tree: Tree<TreeModel>;
  domains: AsyncList<Domain>;
  things: AsyncList<Thing>;
  uuid: string;
  isUserAdmin: boolean;
  breadCrumbs: BreadCrumb[] = [];
  formConfig: DomainDetailFormConfig = {
    defaultData: {
      name: '',
      description: '',
    },
    submitLabel: '',
    titleLabel: 'update',
    onSubmit: null,
    onReset: null,
  };

  constructor(
    private _domainHttpService: DomainHttpService,
    private _directionHttpService: DirectionHttpService,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _thingHttpService: ThingHttpService,
    private _router: Router,
    private _dialog: MatDialog
  ) {
    this.uuid = this._activatedRoute.snapshot.params.uuid;
    this.isUserAdmin = false;

    this.domains = new AsyncList(this._domainHttpService);
    this.domains.setRequestParams({
      params: {
        team__isnull: 'True',
      },
    });
    this.things = new AsyncList(this._thingHttpService);
    this.things.setRequestParams({
      params: {
        team__isnull: 'True',
      },
    });
  }

  ngOnInit(): void {
    this.isUserAdmin = this._authService.is_admin();
    zip(this.domains.load(), this.things.load()).subscribe(() => {
      const treeItems: TreeModel[] = [
        ...this.domains.state.items.map(this._prepareDomainTreeIntegration),
        ...this.things.state.items.map(this._prepareThingTreeIntegration),
      ].sort((a, b) => a.name.localeCompare(b.name));

      this.tree = new Tree(treeItems, 'parent_domain');
      this.uuid = 'root';
      this._renderTree();
      this.breadCrumbs = this._getBreadCrumbs();
    });
  }

  expandDomain(uuid: string): void {
    const angularTreeNode = this.treeControl.dataNodes.find(
      (node) => node.uuid === uuid
    );

    if (this.treeControl.isExpanded(angularTreeNode)) {
      return this.treeControl.collapse(angularTreeNode);
    }
    if (angularTreeNode.parent_domain)
      this.expandDomain(angularTreeNode.parent_domain);
    return this.treeControl.expand(angularTreeNode);
  }

  private _renderTree() {
    const expandedCache = (this.treeControl.dataNodes || []).reduce(
      (cache, node) => {
        return { ...cache, [node.uuid]: this.treeControl.isExpanded(node) };
      },
      {}
    );

    this.dataSource.data =
      this.uuid === 'root'
        ? this.tree.getRoot().children
        : [this.tree.getNode(this.uuid)];

    this.treeControl.dataNodes // restore expanded nodes on rerender
      .forEach((dataNode) => {
        if (expandedCache[dataNode.uuid]) {
          this.treeControl.expand(dataNode);
        }
      });
  }

  private _transformer = (node: TreeNode<TreeModel>, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.item.name,
      isThing: node.item.isThing,
      uuid: node.item.uuid,
      level: level,
      children: node.children,
      parent_domain: node.item.parent_domain,
    };
  };

  private _prepareThingTreeIntegration(thing: Thing) {
    return { ...thing, parent_domain: thing.domain, isThing: true };
  }

  private _prepareDomainTreeIntegration(domain: Domain) {
    return { ...domain, isThing: false };
  }

  private _getBreadCrumbs(): BreadCrumb[] {
    return this.tree
      .getParents(this.tree.getNode(this.uuid))
      .map((node: TreeNode<TreeModel>) => {
        return this._serializeBreadCrumb(node.item);
      });
  }

  _serializeBreadCrumb(item: TreeModel): BreadCrumb {
    return {
      name: item.name,
      linkUrl: `/library/domains/${item.uuid}`,
    };
  }

  onDialogShow = (
    uuid: string,
    mode: 'update' | 'create' = 'update',
    isThing: boolean
  ): void => {
    const item = this.tree.getNode(uuid).item;
    const isEdit = mode === 'update';

    this._dialog.open(DomainDetailEditDialogComponent, {
      data: {
        domains: this.domains.state.items,
        things: this.things.state.items,
        thingUuid: uuid,
        isThing: isThing,
        isEdit: isEdit,
        defaultData:
          mode === 'create'
            ? { name: '', description: '' }
            : {
                name: item.name,
                description: item.description,
                kind: 'kind' in item ? item.kind : 0,
              },
        onSubmit: (data: DetailFormData) => {
          const asyncList: any = isThing ? this.things : this.domains;

          if (isEdit) {
            asyncList.update(uuid, data).subscribe((response) => {
              this.tree.getNode(uuid).item = {
                ...this.tree.getNode(uuid).item,
                ...response,
              };
              const node = this.treeControl.dataNodes.find(
                (node) => node.uuid === uuid
              );
              node.name = data.name;
            });
          }

          if (!isEdit) {
            const parentDomain = this.tree.getNode(uuid).item;
            asyncList
              .create({
                ...data,
                [isThing ? 'domain' : 'parent_domain']:
                  parentDomain?.uuid || null,
              })
              .subscribe((response) => {
                if (!isThing) {
                  this._authService.updateUserDirectionsDomains(response.uuid);
                  this._directionHttpService
                    .updateDirectionsDomains({
                      directions: this._authService
                        .getUserDirections()
                        .state.items.map((direction) => direction.uuid),
                      domain: response.uuid,
                    })
                    .subscribe();
                }

                const insertedNode = isThing
                  ? this._prepareThingTreeIntegration(response)
                  : this._prepareDomainTreeIntegration(response);
                this.tree.insertNode(
                  parentDomain?.uuid || 'root',
                  insertedNode
                );
                this._renderTree();
              });
          }
        },
      },
    });
  };

  remove = (node: AngularDefaultTreeNode): void => {
    const formConfig: AcceptDialogData = {
      title: `Удалить ${node.name}`,
      message: `Вы уверены, что хотите удалить ${
        node.isThing ? 'компетенцию' : 'домен'
      }?`,
      acceptLabel: 'Удалить',
      rejectLabel: 'Отмена',
    };

    this._dialog
      .open(AcceptDialogComponent, {
        data: formConfig,
        width: '55rem',
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) {
          const asyncList = node.isThing ? this.things : this.domains;
          asyncList.remove(node.uuid).subscribe(() => {
            this.tree.removeNode(node.uuid);
            this._renderTree();
            this.formConfig.onSubmit = null;
          });
        }
      });
  };

  // angular tree methods
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  hasChild = (_: number, node: AngularDefaultTreeNode): boolean => {
    return node.expandable;
  };

  treeControl = new FlatTreeControl<AngularDefaultTreeNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
}
