<div
  class="project-container"
  fxLayout="column"
  *ngIf="project?.state.isLoaded"
>
  <div fxLayout="row" class="project-title">
    <div fxFlex fxLayoutAlign="start center" fxLayoutGap="2rem">
      <button (click)="onBackClick()" mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span>{{ project.state.item.name }}</span>
    </div>
  </div>
  <div>
    <form [formGroup]="form" class="project-create-form">
      <div fxLayout="column">
        <mat-label class="textfield-label">Название проекта</mat-label>
        <mat-form-field floatLabel="never" appearance="outline">
          <input matInput type="text" formControlName="name" />
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-label class="textfield-label">Описание проекта</mat-label>
        <mat-form-field floatLabel="never" appearance="outline">
          <input matInput type="text" formControlName="description" />
        </mat-form-field>
      </div>
      <div>
        <mat-label class="textfield-label">Клиент</mat-label>
        <ng-select
          appearance="outline"
          [loading]="companies?.state.isProcessing"
          notFoundText="Компании не найдены"
          loadingText="Ищем..."
          appendTo="body"
          class="users-search"
          formControlName="company"
          [items]="companies?.state.items"
          bindLabel="name"
          bindValue="uuid"
        >
        </ng-select>
      </div>
      <div>
        <mat-label class="textfield-label">Участники проекта</mat-label>
        <ng-select
          appearance="outline"
          (search)="search($event.term)"
          [loading]="searchUsers?.state.isProcessing"
          [multiple]="true"
          notFoundText="По вашему запросу не нашлось пользователей"
          loadingText="Ищем..."
          appendTo="body"
          class="users-search"
          formControlName="resources"
        >
          <ng-option *ngFor="let user of combinedUsers" [value]="user.uuid"
            >{{ user.first_name }} {{ user.last_name }} ({{
              user.email
            }})</ng-option
          >
        </ng-select>
      </div>
      <div fxLayout="column">
        <mat-label>Срок проекта</mat-label>
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="dateFrom" />
            <input matEndDate formControlName="dateTo" />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
    <button mat-raised-button (click)="onBackClick()">Отменить</button>
    <button mat-raised-button (click)="onProjectEdit()">Сохранить</button>
  </div>
</div>
