import { Component, Input, OnInit } from '@angular/core';
import { AsyncList } from '@rest/AsyncList';
import { Review } from '@models/reviews/review';
import { ReviewHttpService } from '@services/http/ReviewHttpService';
import { Router } from '@angular/router';
import { User } from '@models/user/user';

@Component({
  selector: 'app-subject-review-list',
  templateUrl: './subject-review-list.component.html',
  styleUrls: ['./subject-review-list.component.css'],
})
export class SubjectReviewListComponent implements OnInit {
  @Input() user: User;
  subjectReviews: AsyncList<Review>;
  reviewList: Review[];

  constructor(
    private _reviewHttpService: ReviewHttpService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.subjectReviews = new AsyncList<Review>(this._reviewHttpService);
    this.subjectReviews.setRequestParams({
      params: {
        subjects: this.user.uuid,
      },
    });
    this.subjectReviews.load().subscribe((response) => {
      this.reviewList = response.results;
    });
  }

  reviewRedirect(review: Review): void {
    this._router.navigate([`/review/${review.uuid}`]);
  }
}
