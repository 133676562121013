<div class='router-outlet-container'>
  <ng-container *ngIf='is_data_loaded; then component_content; else preloader'>
  </ng-container>
</div>

<ng-template #preloader>
  <app-preloader-progressbar></app-preloader-progressbar>
</ng-template>

<ng-template #component_content>
  <div class='app-admin-ontology-dashboard-container'>
    <div fxLayout='row'>
      <div fxFlex>
        <div class='app-admin-ontology-dashboard-main-container'>
          <div class='app-admin-ontology-dashboard-main-header-container'>
            <div fxLayout='row' fxLayoutAlign='start center'>
              <div fxFlex>
                <div class='app-admin-ontology-dashboard-main-header-text'>
                  Домены
                </div>
              </div>
              <div fxFlex='45rem' fxLayoutAlign='end center'>
                <div class='app-admin-ontology-dashboard-main-header-controls' fxLayoutAlign='start center'>
                  <!-- <a
                    mat-stroked-button
                    class="dashboard-button"
                    routerLink="/admin/thing_level_template"
                  >
                    <mat-icon>settings</mat-icon>
                    Уровни компетенций
                  </a> -->
                  <a
                    class='dashboard-button'
                    mat-raised-button
                    routerLink='/admin/ontologies/domains/create'
                  >
                    <mat-icon class='dashboard-icon'>group_add</mat-icon>
                    Создать домен
                  </a>

                  <a
                    class='dashboard-button'
                    mat-raised-button
                    (click)='downloadOntologies()'
                  >
                    <mat-icon class='dashboard-icon'>cloud_download</mat-icon>
                    Скачать структуру
                  </a>

                  <button mat-icon-button [matMenuTriggerFor]='downloadOptions'>
                    <mat-icon>settings</mat-icon>
                  </button>

                  <mat-menu
                    #downloadOptions='matMenu'
                    class='download-options-menu'
                  >
                    <button mat-menu-item (click)='toggleDownloadUsersCount()'>
                      <div
                        fxLayout='row'
                        fxLayoutAlign='start center'
                        fxLayoutGap='1rem'
                      >
                        <mat-icon
                          fxFlex='10'
                          class='red-icon'
                          *ngIf='isDownloadUsersCount'
                        >done_outline
                        </mat-icon
                        >
                        <span fxFlex='90'>Количество пользователей</span>
                      </div>
                    </button>
                    <button mat-menu-item (click)='toggleDownloadThingLevels()'>
                      <div
                        fxLayout='row'
                        fxLayoutAlign='start center'
                        fxLayoutGap='1rem'
                      >
                        <mat-icon
                          *ngIf='isDownloadThingLevels'
                          fxFlex='10'
                          class='red-icon'
                        >done_outline
                        </mat-icon
                        >
                        <span fxFlex='90'>Уровни навыков</span>
                      </div>
                    </button>
                    <button
                      mat-menu-item
                      (click)='toggleDownLoadBySpecialities()'
                    >
                      <div
                        fxLayout='row'
                        fxLayoutAlign='start center'
                        fxLayoutGap='1rem'
                      >
                        <mat-icon
                          *ngIf='isDownLoadBySpecialities'
                          fxFlex='10'
                          class='red-icon'
                        >done_outline
                        </mat-icon
                        >
                        <span fxFlex='90'>Группировка по специальностям</span>
                      </div>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div fxLayout='row'>
      <div fxFlex>
        <mat-tab-group animationDuration='0ms'>
          <mat-tab label='Текущие домены'>
            <div
              class='app-admin-ontology-dashboard-domains-hierarchy-container'
            >
              <div *ngIf='!domains.length'>
                <div
                  class='app-admin-ontology-dashboard-domains-hierarchy-empty'
                >
                  Еще создано ни одного домена
                </div>
              </div>
              <div *ngIf='domains.length'>
                <div *ngFor='let domain_treenode of hierarchy_root_treenodes'>
                  <ng-container
                    [ngTemplateOutlet]='tmpl_domain_treenode'
                    [ngTemplateOutletContext]='{ treenode: domain_treenode }'
                  >
                  </ng-container>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label='Загрузить домены'>
            <app-upload-domains></app-upload-domains>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_domain_treenode let-treenode='treenode'>
  <div
    class='app-admin-ontology-dashboard-domains-hierarchy-treenode-container'
  >
    <div fxLayout='row'>
      <div fxFlex='3rem'>
        <div
          *ngIf='treenode.children.length'
          class='
            app-admin-ontology-dashboard-domains-hierarchy-treenode-children-toggle
          '
        >
          <button
            mat-icon-button
            (click)='on_treenode_toggle_show_children(treenode)'
          >
            <mat-icon>
              {{ treenode.show_children ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
        </div>
      </div>
      <div fxFlex fxLayoutAlign='start center'>
        <div
          class='
            app-admin-ontology-dashboard-domains-hierarchy-treenode-domain-name
          '
        >
          <a routerLink='/admin/ontologies/domains/{{ treenode.domain.uuid }}'>
            {{ treenode.domain.name }}
          </a>
        </div>
      </div>
    </div>

    <div fxLayout='row'>
      <div fxFlex='3rem'>
        <!-- chevnon offset -->
      </div>
      <div fxFlex>
        <div
          class='
            app-admin-ontology-dashboard-domains-hierarchy-treenode-domain-muted
          '
        >
          <div fxLayout='row'>
            <div fxFlex='2rem' fxLayoutAlign='start center'>
              <div *ngIf='!treenode.domain.team'>
                <mat-icon>public</mat-icon>
              </div>
              <div *ngIf='treenode.domain.team'>
                <mat-icon>group</mat-icon>
              </div>
            </div>
            <div fxFlex='10rem' fxLayoutAlign='start center'>
              <div *ngIf='!treenode.domain.team'>Публичный</div>
              <div *ngIf='treenode.domain.team'>
                {{ treenode.domain.team.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout='row'>
      <div fxFlex='3rem'>
        <!-- chevnon offset -->
      </div>
      <div fxFlex>
        <div *ngIf='treenode.show_children'>
          <div *ngIf='treenode.children.length'>
            <div *ngFor='let domain_treenode of treenode.children'>
              <ng-container
                [ngTemplateOutlet]='tmpl_domain_treenode'
                [ngTemplateOutletContext]='{ treenode: domain_treenode }'
              >
              </ng-container>
            </div>
          </div>
          <div *ngIf='!treenode.children.length'>
            <!-- <div class="app-domains-user-tab-competencies-domain-treenode-nochildren">
                            Нет дочерних доменов компетенций
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
