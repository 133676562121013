import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.page.html',
  styleUrls: ['./main.page.css'],
})
export class MainTeamsPage implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
