import { Component, Input, OnInit } from '@angular/core';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { DomainService } from 'src/app/services/ontology/domain.service';
import { CompetenceService } from 'src/app/services/competencies/competence.service';
import { CompetenceDesireService } from 'src/app/services/competencies/competence-desire.service';

import { User } from 'src/app/models/user/user';
import { Domain } from 'src/app/models/ontology/domain';
import { Thing } from 'src/app/models/ontology/thing';
import { Competence } from 'src/app/models/competencies/competence';
import { ThingLevel } from '@models/ontology/thing-level';
import { CompetenceDesire } from 'src/app/models/competencies/competence-desire';

import { getVerifiedCompetenceTooltipText } from 'src/app/utils/template-filters/get-verified-competence-tooltip-text';

@Component({
  selector: 'app-domains-user-tab-competencies',
  templateUrl: './domains-user-tab-competencies.component.html',
  styleUrls: ['./domains-user-tab-competencies.component.css'],
})
export class DomainsUserTabCompetenciesComponent implements OnInit {
  @Input() user: User;

  public domains: Domain[] = [];
  public competencies: Competence[] = [];
  public desires: CompetenceDesire[] = [];

  public all_domain_treenodes: any[] = [];
  public hierarchy_root_treenodes: any[] = [];

  private uistate = {
    domains_loaded: false,
    competencies_loaded: false,
    desires_loaded: false,
  };

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private domain_service: DomainService,
    private competence_service: CompetenceService,
    private competence_desire_service: CompetenceDesireService
  ) {}

  ngOnInit(): void {
    this.load_domains();
  }

  public get is_data_loaded(): boolean {
    return (
      this.uistate.domains_loaded &&
      this.uistate.competencies_loaded &&
      this.uistate.desires_loaded
    );
  }

  private load_domains(): void {
    this.domain_service
      .fetch_domains_for_user_competencies(this.user.uuid)
      .subscribe(
        (response) => {
          this.domains = response.results as Domain[];
          this.logging_service.debug(
            `${this.constructor.name} fetched ${this.domains.length} domains for user competencies`
          );
          this.build_domain_tree_data();
          this.uistate.domains_loaded = true;
          this.load_competencies();
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch domains for user competencies`
          );
          this.alert_service.error(
            `Ошибка загрузки доменов компетенций: ${err.status}`
          );
        }
      );
  }

  private load_competencies(): void {
    this.competence_service.fetch_by_user_uuid(this.user.uuid).subscribe(
      (response) => {
        this.competencies = response.results as Competence[];
        this.logging_service.debug(
          `${this.constructor.name} fetched ${this.competencies.length} user competencies`
        );
        this.add_competencies_to_treenodes();
        this.filter_empty_treenodes();
        this.uistate.competencies_loaded = true;
        this.load_competence_desires();
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to fetch user competencies`
        );
        this.alert_service.error(`Ошибка загрузки компетенций: ${err.status}`);
      }
    );
  }

  private load_competence_desires(): void {
    this.competence_desire_service.fetch_by_user_uuid(this.user.uuid).subscribe(
      (response) => {
        this.desires = response.results as CompetenceDesire[];
        this.logging_service.debug(
          `${this.constructor.name} fetched ${this.desires.length} user desires`
        );
        this.mark_desired_competencies();
        this.uistate.desires_loaded = true;
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to fetch user desires`
        );
        this.alert_service.error(`Ошибка загрузки хотелок: ${err.status}`);
      }
    );
  }

  private build_domain_tree_data(): void {
    this.logging_service.debug(
      `${this.constructor.name} build_domain_tree_data`
    );
    this.all_domain_treenodes = [];
    this.domains.forEach((domain) => {
      const treenode = {
        domain,
        parent_treenode: null,
        children: [],
        competencies: [],
        show_children: true,
        show_competencies: true,
      };
      this.all_domain_treenodes.push(treenode);
    });
    this.all_domain_treenodes.forEach((treenode) => {
      const child_domains = this.domains.filter((domain) => {
        return (
          domain.parent_domain && domain.parent_domain === treenode.domain.uuid
        );
      });
      child_domains.forEach((child_domain) => {
        const child_treenode = this.all_domain_treenodes.find(
          (node) => node.domain.uuid === child_domain.uuid
        );
        child_treenode.parent_treenode = treenode;
        treenode.children.push(child_treenode);
      });
    });
    this.hierarchy_root_treenodes = this.all_domain_treenodes.filter(
      (node) => node.domain.parent_domain === null
    );
    if (this.hierarchy_root_treenodes.length === 1) {
      this.hierarchy_root_treenodes[0].show_children = true;
    }
    // this.hierarchy_root_treenodes.forEach(treenode => treenode.show_children = true);
  }

  private add_competencies_to_treenodes(): void {
    this.logging_service.debug(
      `${this.constructor.name} add_competencies_to_treenodes`
    );
    this.competencies.forEach((competence) => {
      if ((competence.thing_level as ThingLevel).order_number > 0) {
        const domain_treenode = this.all_domain_treenodes.find(
          (node) => node.domain.uuid === (competence.thing as Thing).domain
        );
        domain_treenode.competencies.push(competence);
      }
    });
    this.all_domain_treenodes.forEach((treenode) => {
      treenode.competencies.sort((a, b) => (a.level > b.level ? -1 : 1));
    });
  }

  private filter_empty_treenodes(): void {
    this.logging_service.debug(
      `${this.constructor.name} filter_empty_treenodes`
    );
    const filter_empty_treenodes_recursive = (treenode) => {
      treenode.children.forEach((child_treenode) =>
        filter_empty_treenodes_recursive(child_treenode)
      );
      const filtered_children = [];
      for (const child of treenode.children) {
        if (child.children.length || child.competencies.length)
          filtered_children.push(child);
      }
      treenode.children = filtered_children;
    };
    this.hierarchy_root_treenodes.forEach((treenode) =>
      filter_empty_treenodes_recursive(treenode)
    );
    const filtered_root_nodes = [];
    for (const root_node of this.hierarchy_root_treenodes) {
      if (root_node.children.length || root_node.competencies.length)
        filtered_root_nodes.push(root_node);
    }
    this.hierarchy_root_treenodes = filtered_root_nodes;
  }

  private mark_desired_competencies(): void {
    this.logging_service.debug(
      `${this.constructor.name} mark_desired_treenodes`
    );
    this.desires.forEach((desire) => {
      const desired_competence = this.competencies.find(
        (c) => (c.thing as Thing).uuid === (desire.thing as Thing).uuid
      );
      if (desired_competence) desired_competence.is_desired = true;
    });
  }

  public get_treenode_children_competencies_to_display(treenode): Competence[] {
    if (treenode.show_children) {
      return treenode.competencies;
    } else {
      const competencies = [];
      treenode.competencies.forEach((c) => competencies.push(c));
      const gather_competencies_from_children_recursive = (child_treenode) => {
        child_treenode.children.forEach((node) => {
          node.competencies.forEach((c) => competencies.push(c));
          gather_competencies_from_children_recursive(node);
        });
      };
      gather_competencies_from_children_recursive(treenode);
      competencies.sort((a, b) => (a.level > b.level ? -1 : 1));
      return competencies;
    }
  }

  public get_competence_level_readable(competence): string {
    const thingLevel = competence.thing.levels.find(
      (level: any) => level.order_number === competence.level
    );

    if (thingLevel) {
      return thingLevel?.title || 'Ошибка';
    } else {
      // there is a chance, that for this competence ThingLevels is not created for some reason
      // this is data error, not code error, but we need check that case
      if (competence.level === 0) return 'Навыка нет';
      if (competence.level === 1) return 'Начинающий';
      if (competence.level === 2) return 'Уверенный';
      if (competence.level === 3) return 'Эксперт';
    }
  }

  public on_treenode_toggle_show_children(treenode) {
    const new_value = !treenode.show_children;
    treenode.show_children = new_value;
    if (!new_value) {
      // when open – we need to open only child domains, when close - close competencies too
      this.set_show_children(treenode, new_value);
      this.set_show_competencies(treenode, new_value);
    }
  }

  public on_treenode_toggle_show_competencies(treenode) {
    const new_value = !treenode.show_competencies;
    this.set_show_children(treenode, new_value);
    this.set_show_competencies(treenode, new_value);
  }

  public set_show_children(treenode, value) {
    const toggle_recursive = (node) => {
      node.show_children = value;
      node.children.forEach((n) => toggle_recursive(n));
    };
    toggle_recursive(treenode);
  }

  public set_show_competencies(treenode, value) {
    const toggle_recursive = (node) => {
      node.show_competencies = value;
      node.children.forEach((n) => toggle_recursive(n));
    };
    toggle_recursive(treenode);
  }

  public getCompetenceThingUuid(competence: Competence): string {
    const thing = competence.thing as Thing;
    return thing.uuid;
  }

  getVerifiedCompetenceTooltipText = getVerifiedCompetenceTooltipText;
}
