import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.css']
})
export class StatusIconComponent implements OnInit {
  @Input() percentage: number;
  @Input() description: string = null;
  iconType: string;


  constructor() { }

  ngOnInit() {
    if (this.percentage >= 0 && this.percentage < 100) {
      this.iconType = 'hourglass_empty';
      this.description = 'В процессе';
    } else if (this.percentage === 100) {
      this.iconType = 'check';
      if (!this.description) this.description = 'Завершено';
    } else if (this.percentage === -1) {
      this.iconType = 'cancel';
      this.description = 'Нет селф-ревью';
    }
  }
}
