import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface TextField {
  label: string;
  placeholder: string;
  exampleText: string;
  required: boolean;
  formControlName: string;
  value: string;
}

export interface TextfieldDialogData {
  title: string;
  description: string;
  acceptLabel: string;
  rejectLabel: string;
  floatLabel: 'never' | 'always' | 'auto';
  fields: TextField[];
  onSubmit(formData: any): void;
}

@Component({
  selector: 'app-textfield-dialog',
  templateUrl: './textfield-dialog.component.html',
  styleUrls: ['./textfield-dialog.component.css'],
})
export class TextfieldDialogComponent implements OnInit {
  form: UntypedFormGroup;
  constructor(
    public dialogRef: MatDialogRef<TextfieldDialogComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: TextfieldDialogData
  ) {}

  ngOnInit(): void {
    console.log(this.data.floatLabel);
    const formConfig = {};
    this.data.fields.forEach((field) => {
      formConfig[field.formControlName] = [field.value, []];
      if (field.required) {
        formConfig[field.formControlName][1].push(Validators.required);
      }
    });
    this.form = this._formBuilder.group(formConfig);
  }

  close(): void {
    this.data.onSubmit(this.form.value);
    this.dialogRef.close();
  }
}
