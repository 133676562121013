import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { Team } from '@models/teams/team';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Thing } from '@models/ontology/thing';

@Injectable({
  providedIn: 'root',
})
export class TeamHttpService extends ViewSetHttpService<Team> {
  http: HttpClient;
  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'teams/team';

  public addSpeciality({
    team,
    speciality,
  }: {
    team: string;
    speciality: string;
  }): Observable<any> {
    return this.http.post<{ speciality: string; thing: string }>(
      `${this.getUrl()}/${team}/add_speciality`,
      { speciality }
    );
  }

  public getMatrix({
    teams,
    specialities,
    with_child_teams,
  }: {
    teams: string[];
    specialities: string[];
    with_child_teams: boolean;
  }): Observable<any> {
    return this.http.post(`${this.getUrl()}/get_matrix`, {
      teams: teams,
      specialities: specialities,
      with_child_teams: with_child_teams,
    });
  }

  public downloadMatrix({ data }: { data: object }): Observable<any> {
    return this.http.post(
      `${this.getUrl()}/download_matrix`,
      {
        data: data,
      },
      {
        headers: {
          // 'Content-Type': 'application/vnd.ms-excel',
          //'Content-Disposition': 'attachment',
        },
        responseType: 'blob',
      }
    );
  }

  public matchUsers(
    {
      teams,
      things,
      thingLevelsMap,
      thingIsRequiredMap,
      thingLevelMatchMap,
    }: {
      teams: Team[];
      things: Thing[];
      thingLevelsMap: { [thingUuid: string]: string };
      thingIsRequiredMap: { [thingUuid: string]: boolean };
      thingLevelMatchMap: { [thingUuid: string]: string };
    },
    team: string
  ): Observable<any> {
    return this.http.post(`${this.getUrl()}/${team}/match_users`, {
      teams: teams.map((team) => team.uuid),
      things: things.map((thing) => thing.uuid),
      thing_levels_map: thingLevelsMap,
      thing_is_required_map: thingIsRequiredMap,
      thing_level_match_map: thingLevelMatchMap,
    });
  }

  public runCalculateTeamMembersStatus(teamUuid: string): Observable<any> {
    return this.http.post(
      `${this.getUrl()}/run_calculate_team_members_status`,
      { team: teamUuid }
    );
  }

  public runUpdateTeamMatrixCache(teamUuid: string): Observable<any> {
    return this.http.post(`${this.getUrl()}/run_update_team_matrix_cache`, {
      team: teamUuid,
    });
  }

  public updateAllTeamMatrixCache(): Observable<any> {
    return this.http.post(`${this.getUrl()}/update_all_team_matrix_cache`, {});
  }
}
