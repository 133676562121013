import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyAnswerService {

  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService,
  ) { }

  public create(answer_data: any): Observable<any> {
    return this.api_service.post(`surveys/answer`, answer_data, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} created question answer with uuid ${response.uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to create question answer`);
        }
    ));
  }

  public update(answer_uuid: string, answer_data: any): Observable<any> {
    return this.api_service.patch(`surveys/answer/${answer_uuid}`, answer_data, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} updated answer with uuid ${response.uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to update answer`);
        }
    ));
  }

}
