<div class='router-outlet-container'>
  <ng-container *ngIf='is_data_loaded; then component_content; else preloader'>
  </ng-container>
</div>

<ng-template #preloader>
  <app-preloader-progressbar></app-preloader-progressbar>
</ng-template>

<ng-template #component_content>
  <div fxLayout='row'>
    <div fxFlex>
      <div class='app-admin-users-user-header-container'>
        <div fxLayout='row'>
          <div fxFlex='5rem'>
            <div class='app-admin-users-user-header-avatar'>
              {{ util_user_get_initials_for_avatar(user) }}
            </div>
          </div>
          <div fxFlex='25rem'>
            <div class='app-admin-users-user-header-name'>
              {{ user.first_name }} {{ user.last_name }}
            </div>
            <div class='app-admin-users-user-header-email'>
              {{ user.email }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex='5rem'>
      <div>
        <button
          mat-stroked-button
          color='warn'
          (click)='on_button_user_delete()'
        >
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div fxLayout='row'>
    <div fxFlex>
      <mat-tab-group animationDuration='0ms' mat-stretch-tabs='false'>
        <mat-tab label='Команды'>
          <ng-template matTabContent>
            <div class='app-admin-users-user-content-tab-container'>
              <app-admin-user-tab-teams
                [user]='user'
              ></app-admin-user-tab-teams>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label='Специальности'>
          <ng-template matTabContent>
            <div class='app-admin-users-user-content-tab-container'>
              <app-admin-user-tab-specialities
                [user]='user'
              ></app-admin-user-tab-specialities>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label='Компетенции'>
          <ng-template matTabContent>
            <div class='app-admin-users-user-content-tab-container'>
              <app-admin-user-tab-competencies
                [user]='user'
              ></app-admin-user-tab-competencies>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label='Ревью'>
          <ng-template matTabContent>
            <div class='app-admin-users-user-content-tab-container'>
              <app-subject-review-list
                [user]='user'
              ></app-subject-review-list>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label='PDP'>
          <ng-template matTabContent>
            <div class='app-admin-users-user-content-tab-container'>
              <app-admin-user-tab-pdps [user]='user'></app-admin-user-tab-pdps>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label='События'>
          <ng-template matTabContent>
            <div class='app-admin-users-user-content-tab-container'>
              <app-admin-user-tab-events
                [user]='user'
              ></app-admin-user-tab-events>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</ng-template>
