import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { SpecialityEvent } from '@models/events/speciality-event';
import { TeamMembership } from '@models/teams/team-membership';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpecialityEventHttpService extends ViewSetHttpService<SpecialityEvent> {
  http: HttpClient;

  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'events/speciality';

  public setSpecialityEventsIsViewed({
    events,
  }: {
    events: string[];
  }): Observable<any> {
    return this.http.post<{
      events: string[];
    }>(`${this.getUrl()}/set_is_viewed`, {
      events,
    });
  }
}
