<app-with-processing [processing]="isProcessing">
  <div class="app-speciality-group-create-container">
    <div fxLayout="row">
      <div fxFlex="60" fxFlexOffset="20">
        <div class="app-speciality-group-create-main-container">
          <div class="app-speciality-group-create-main-header-title">
            Добавить группу специальностей
          </div>
          <div class="app-speciality-group-create-main-header-hint">
            Подсказочка
          </div>

          <form [formGroup]="form">
            <div class="app-speciality-group-create-main-input">
              <mat-form-field [style.width.rem]="30">
                <input
                  matInput
                  placeholder="Название"
                  type="text"
                  formControlName="name"
                  required
                />
                <mat-hint>{{ 128 - form.value.name.length }}/128</mat-hint>
              </mat-form-field>
            </div>

            <div class="app-speciality-group-create-main-input">
              <ng-select
                [items]="specialities.state.items"
                [multiple]="true"
                (add)="addSpeciality($event)"
                (remove)="removeSpeciality($event)"
                notFoundText="Специальности не найдены"
                placeholder="Добавить специальности"
                bindLabel="name"
                bindValue="uuid"
              >
              </ng-select>
            </div>

            <button
              mat-raised-button
              [disabled]="form.invalid"
              (click)="submit()"
              [disabled]="form.invalid"
            >
              Создать
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</app-with-processing>
