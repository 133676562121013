export const testDataAttributes = {
  appTitleId: 'app-title',
  accountPanelNameId: 'account-panel-name',
  topMenuAdminLinkId: 'top-menu-admin-link',
  loginButtonId: 'login-button',
  adminMenuTeamsLinkId: 'admin-menu-teams-link',
  createNewTeamButtonId: 'create-new-team-button',
  createNewTeamInputNameId: 'create-new-team-input-name',
  createNewTeamInputDescriptionId: 'create-new-team-input-description',
  createNewTeamSubmitButtonId: 'create-new-team-submit-button',
  createdTeamDescriptionId: 'created-team-description',
};
