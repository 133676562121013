import { Component, Input, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';

import { AsyncDetail } from '@rest/AsyncDetail';
import { AsyncList } from '@rest/AsyncList';

import { MatDialog } from '@angular/material/dialog';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import Tree, { TreeNode } from 'src/app/utils/tree';

import { SpecialityCompetenceClaimHttpService } from '@services/http/SpecialityCompetenceClaimHttpService';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { CompetenceHttpService } from '@services/http/CompetenceHttpService';
import { AuthService } from 'src/app/services/auth/auth.service';

import { Speciality } from '@models/specialities/speciality';
import { SpecialityGrade } from '@models/specialities/speciality-grade';
import { SpecialityCompetenceClaim } from '@models/specialities/speciality-competence-claim';
import { Competence } from '@models/competencies/competence';
import { Domain } from '@models/ontology/domain';
import { Thing } from '@models/ontology/thing';
import { ThingLevel } from '@models/ontology/thing-level';

import { AddSpecialityToTeamComponent } from '@components/common/add-speciality-to-team/add-speciality-to-team.component';
import { ThingGradeLevelsMap } from 'src/app/modules/teams/components/team-main/team-specialities/team-speciality-detail/team-speciality-detail.component';
import { SpecialityDomainClaimHttpService } from '@services/http/SpecialityDomainClaimHttpService';
import { SpecialityDomainClaim } from '@models/specialities/speciality-domain-claim';
import { SpecialityGradeHttpService } from '@services/http/SpecialityGradeHttpService';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { bootstrapApplication } from '@angular/platform-browser';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AddCompetenceFromLibraryDialogComponent } from '../../../teams/components/team-main/team-specialities/team-speciality-detail/add-competence-from-library-dialog/add-competence-from-library-dialog.component';
import { TextfieldDialogComponent } from '../../../common-ui-elements/components/textfield-dialog/textfield-dialog.component';
import { AlertService } from '../../../../services/ui/ui-alert.service';
import { BlockTextHelpDialogComponent } from '@components/common/block-text-help-dialog/block-text-help-dialog.component';
import {
  build_thing_competenceclaim_map,
  ThingCompetenceClaimMap,
} from '../../../../utils/build-thing-competenceclaim-map';
import { AutoLevelCompetenceclaimsDialogComponent } from '@components/common/auto-level-competenceclaims-dialog/auto-level-competenceclaims-dialog.component';

interface DomainTreeModel extends Domain {
  isThing: boolean;
}

interface ThingTreeModel extends Thing {
  isThing: boolean;
}

type TreeModel = DomainTreeModel | ThingTreeModel;

export interface AngularDefaultTreeNode {
  expandable: boolean;
  name: string;
  level: number;
  uuid: string;
  children: TreeNode<TreeModel>[];
}

export interface ThingGrade {
  grade: SpecialityGrade;
  thingLevel: ThingLevel;
}

export interface ThingGradeMap {
  [thingUuid: string]: ThingGrade[];
}

export interface UserThingLevelMap {
  [thingUuid: string]: ThingLevel;
}

export interface ChildSpecialitiesUpdateConfig {
  update_textfields: boolean;
  update_grades: boolean;
  update_competencies: boolean;
}

interface CompetenceDiff {
  added_claims: SpecialityCompetenceClaim[][];
  missing_claims: SpecialityCompetenceClaim[][];
}

interface GradesDiff {
  added_grades: SpecialityGrade[];
  missing_grades: SpecialityGrade[];
  diff_grades: {
    diff: {
      name: string;
      description: string;
      order_number: number;
    };
    grade: SpecialityGrade;
  }[];
}

interface ChildSpecialityDiff {
  uuid: string;
  name_diff: string;
  description_diff: string;
  competence_claims: CompetenceDiff;
  grades: GradesDiff;
}

@Component({
  selector: 'app-speciality-detail',
  templateUrl: './speciality-detail.component.html',
  styleUrls: ['./speciality-detail.component.css'],
})
export class SpecialityDetailComponent implements OnInit {
  @Input() speciality: AsyncDetail<Speciality>;

  specialityCompetenceClaims: AsyncList<SpecialityCompetenceClaim>;
  specialityDomainClaims: AsyncList<SpecialityDomainClaim>;
  specialityGrades: AsyncList<SpecialityGrade>;

  childSpecialities: AsyncList<Speciality>;
  childSpecialitiesDiff: ChildSpecialityDiff[];

  isChildSpecialitiesDiffLoaded = false;

  expandedSpeciality: string;

  isEditModeOn: boolean;

  isSelectedAll = false;
  selectedSpecialities: string[] = [];

  updateConfig: ChildSpecialitiesUpdateConfig = {
    update_textfields: true,
    update_grades: true,
    update_competencies: true,
  };

  things: Thing[] = [];

  thingGradeLevelsMap: ThingGradeLevelsMap;
  thingGradeLevelsMapReady = false;

  thingCompetenceClaimMap: ThingCompetenceClaimMap;

  isTreeReady = false;

  editableGradeName: string;
  editableGradeDesc: string;

  gradeForm: UntypedFormGroup;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _authService: AuthService,
    private _alertService: AlertService,
    private _domainHttpService: DomainHttpService,
    private _specialityHttpService: SpecialityHttpService,
    private _specialityDomainClaimHttpService: SpecialityDomainClaimHttpService,
    private _specialityCompetenceClaimHttpService: SpecialityCompetenceClaimHttpService,
    private _specialityGradeHttpService: SpecialityGradeHttpService,
    private _dialog: MatDialog
  ) {}

  buildThingCompetenceClaimMap = build_thing_competenceclaim_map;

  ngOnInit(): void {
    this.isEditModeOn = this.isUserAdmin || this.isDirectionSpecialityAdmin;
    this._loadDomainClaims();
    this._loadChildSpecialitiesDiff();
    this._loadSpecialityGrades();
    this._loadSpecialityClaims();
    this._loadChildSpecialities();
  }

  private _loadDomainClaims(): void {
    this.specialityDomainClaims = new AsyncList<SpecialityDomainClaim>(
      this._specialityDomainClaimHttpService
    );
    this.specialityDomainClaims.setRequestParams({
      params: {
        speciality: this.speciality.state.item.uuid,
        expand: 'domain',
      },
    });
    this.specialityDomainClaims.load().subscribe();
  }

  private _loadChildSpecialitiesDiff(): void {
    this._specialityHttpService
      .getChildSpecialitiesDiff(this.speciality.state.item.uuid)
      .subscribe((response) => {
        this.childSpecialitiesDiff = response.result;
        this.isChildSpecialitiesDiffLoaded = true;
      });
  }

  private _loadChildSpecialities(): void {
    this.childSpecialities = new AsyncList<Speciality>(
      this._specialityHttpService
    );
    this.childSpecialities.setRequestParams({
      params: {
        library_speciality: this.speciality.state.item.uuid,
        expand: 'team',
      },
    });
    this.childSpecialities.load().subscribe(() => {
      this.childSpecialities.state.items =
        this.childSpecialities.state.items.filter((s) => !!s.team);
      this.onSelectionToggleAll(true);
    });
  }

  private _loadSpecialityGrades(): void {
    this.specialityGrades = new AsyncList<SpecialityGrade>(
      this._specialityGradeHttpService
    );
    this.specialityGrades.setRequestParams({
      params: {
        speciality: this.speciality.state.item.uuid,
      },
    });
    this.specialityGrades.load().subscribe();
  }

  private _loadSpecialityClaims(): void {
    this.things = [];
    this.thingGradeLevelsMap = {};
    this.specialityCompetenceClaims = new AsyncList<SpecialityCompetenceClaim>(
      this._specialityCompetenceClaimHttpService
    );
    this.specialityCompetenceClaims.setRequestParams({
      params: {
        speciality: this.speciality.state.item.uuid,
        expand: 'thing.levels,grade',
      },
    });
    this.specialityCompetenceClaims.load().subscribe(() => {
      this.specialityCompetenceClaims.state.items.forEach(
        (competenceClaim: SpecialityCompetenceClaim<string>) => {
          if (
            !this.things.find((thing) => {
              return thing.uuid === competenceClaim.thing.uuid;
            })
          ) {
            this.things.push(competenceClaim.thing);
            this.thingGradeLevelsMap[competenceClaim.thing.uuid] = {
              isKey: competenceClaim.is_key,
              thingGradeLevels: [
                {
                  grade: competenceClaim.grade,
                  thingLevel: competenceClaim.thing_level,
                  levels: competenceClaim.thing.levels,
                },
              ],
            };
          } else {
            this.thingGradeLevelsMap[competenceClaim.thing.uuid][
              'thingGradeLevels'
            ].push({
              grade: competenceClaim.grade,
              thingLevel: competenceClaim.thing_level,
              levels: competenceClaim.thing.levels,
            });
          }
        }
      );
      this.thingCompetenceClaimMap = this.buildThingCompetenceClaimMap(
        this.specialityCompetenceClaims.state.items
      );
      this.thingGradeLevelsMapReady = true;
    });
  }

  get sortedSpecialitiesByDiffCount(): Speciality[] {
    return this.childSpecialities.state.items.sort(
      (a, b) => this.getDiffCount(b.uuid) - this.getDiffCount(a.uuid)
    );
  }

  editGradeName(grade: SpecialityGrade): void {
    this.editableGradeName = grade.uuid;
    this.createGradeForm(grade);
  }

  editGradeDesc(grade: SpecialityGrade): void {
    this.editableGradeDesc = grade.uuid;
    this.createGradeForm(grade);
  }

  createGradeForm(grade: SpecialityGrade): void {
    this.gradeForm = this._formBuilder.group({
      name: [
        grade.name,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],

      description: [
        grade.description,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
    });
  }

  cancelGradeUpdate(): void {
    this.editableGradeName = '';
    this.editableGradeDesc = '';
  }

  updateGrade(gradeUuid: string): void {
    this.editableGradeName = '';
    this.editableGradeDesc = '';
    this._specialityGradeHttpService
      .update(gradeUuid, {
        name: this.gradeForm.controls.name.value,
        description: this.gradeForm.controls.description.value,
      })
      .subscribe(() => this._loadSpecialityGrades());
  }

  getDiffCount(specialityUuid: string): number {
    return (
      this.getGradesDiffCount(specialityUuid) +
      this.getCompetenciesDiffCount(specialityUuid)
    );
  }

  getGradesDiffCount(specialityUuid: string): number {
    const gradesDiff = this.getChildSpecialityGradesDiff(specialityUuid);
    return (
      gradesDiff.diff_grades.length +
      gradesDiff.missing_grades.length +
      gradesDiff.added_grades.length
    );
  }

  getCompetenciesDiffCount(specialityUuid: string): number {
    const competenceDiff =
      this.getChildSpecialityCompetenciesDiff(specialityUuid);

    return (
      competenceDiff.missing_claims.length + competenceDiff.added_claims.length
    );
  }

  getChildSpecialityCompetenciesDiff(specialityUuid: string): CompetenceDiff {
    return this.childSpecialitiesDiff.find(
      (childSpecialityDiff) => childSpecialityDiff.uuid === specialityUuid
    ).competence_claims;
  }

  getChildSpecialityGradesDiff(specialityUuid: string): GradesDiff {
    return this.childSpecialitiesDiff.find(
      (childSpecialityDiff) => childSpecialityDiff.uuid === specialityUuid
    ).grades;
  }

  getChildSpecialityDiffName(specialityUuid: string): string {
    const name_diff = this.childSpecialitiesDiff.find(
      (childSpecialityDiff) => childSpecialityDiff.uuid === specialityUuid
    ).name_diff;
    if (name_diff) {
      return `(${name_diff})`;
    }
    return '';
  }

  isChildSpecialityGradesDiffEmpty(specialityUuid: string): boolean {
    const childSpecialityGradesDiff: GradesDiff =
      this.childSpecialitiesDiff.find(
        (childSpecialityDiff) => childSpecialityDiff.uuid === specialityUuid
      ).grades;
    return (
      childSpecialityGradesDiff.missing_grades.length +
        childSpecialityGradesDiff.added_grades.length +
        childSpecialityGradesDiff.diff_grades.length ===
      0
    );
  }

  isMissingClaimsEmpty(specialityUuid: string): boolean {
    return (
      this.getChildSpecialityCompetenciesDiff(specialityUuid).missing_claims
        .length === 0
    );
  }

  isAddedClaimsEmpty(specialityUuid: string): boolean {
    return (
      this.getChildSpecialityCompetenciesDiff(specialityUuid).added_claims
        .length === 0
    );
  }

  onInfoSpecialityTextClick(): void {
    this._dialog.open(BlockTextHelpDialogComponent, {
      data: {
        title: '',
        textBlocksData: [
          {
            title: 'Добавление компетенций из библиотеки',
            text: 'Раздел “Компетенции из библиотеки” показывает компетенции, которые недавно добавили в библиотечную специальность.',
            color: '',
          },
          {
            title: 'Компетенции из команды',
            text: 'Раздел "Компетенции из команды" показывает компетенции, которые участники команды дополнительно добавили, но которых нет в специальности из библиотеки.',
            color: '',
          },
          {
            title: 'Уровни развития',
            text: 'Раздел “Уровни развития” показывает новые описания к уровням развития.',
            color: '',
          },
        ],
      },
    });
  }

  //
  // isChildSpecialityCompetenciesDiffEmpty(specialityUuid: string): boolean {
  //   const childSpecialityCompetenciesDiff: CompetenceDiff =
  //     this.childSpecialitiesDiff.find(
  //       (childSpecialityDiff) => childSpecialityDiff.uuid === specialityUuid
  //     ).competence_claims;
  //   return (
  //     childSpecialityCompetenciesDiff.added_claims.length +
  //       childSpecialityCompetenciesDiff.missing_claims.length ===
  //     0
  //   );
  // }

  updateChildSpecialities(): void {
    this.isChildSpecialitiesDiffLoaded = false;
    this._specialityHttpService
      .updateChildSpecialities(
        this.speciality.state.item.uuid,
        this.selectedSpecialities,
        this.updateConfig
      )
      .subscribe(
        (response) => {
          this.childSpecialitiesDiff = response.result;
          this.isChildSpecialitiesDiffLoaded = true;
        },
        (error) => {
          this._alertService.error('Ошибка при копировании');
        }
      );
  }

  get isUserAdmin(): boolean {
    return this._authService.is_admin();
  }

  get isDirectionSpecialityAdmin(): boolean {
    return !!this._authService
      .getUserDirectionSpecialities()
      .includes(this.speciality.uuid);
  }

  showAddTeamDialog(): void {
    this._dialog.open(AddSpecialityToTeamComponent, {
      data: {
        speciality: this.speciality,
      },
    });
  }

  onSpecialityEdit(): void {
    const speciality = this.speciality.state.item;
    this._dialog.open(TextfieldDialogComponent, {
      data: {
        title: `Редактирование специальности`,
        description: ``,
        acceptLabel: 'Сохранить',
        rejectLabel: 'Отменить',
        floatLabel: 'never',
        fields: [
          {
            label: 'Название специальности',
            placeholder: 'Введите название специальности',
            exampleText: '',
            required: true,
            formControlName: 'name',
            value: speciality.name,
          },
          {
            label: 'Какие задачи выполняет',
            placeholder:
              'Подробно опишите какие задачи будет решать специалист',
            exampleText:
              'Например: Дизайнер интерфейсов будет решать задачи коммуникации между системой ввода данных и пользователем',
            required: true,
            formControlName: 'description',
            value: speciality.description,
          },
        ],
        onSubmit: (data: any) => {
          this.speciality.update(data);
        },
      },
    });
  }

  onCompetenceFromLibraryAdd(): void {
    this._dialog.open(AddCompetenceFromLibraryDialogComponent, {
      data: {
        onSubmit: (claims: string[]) => {
          const claimsData = [];
          claims.forEach((claim) => {
            claimsData.push({
              speciality: this.speciality.state.item.uuid,
              thing: claim,
            });
          });
          this._specialityCompetenceClaimHttpService
            .addClaimsWithGrades(claimsData)
            .subscribe((response) => {
              this._loadDomainClaims();
              this._loadSpecialityClaims();
              this._dialog.closeAll();
            });
        },
      },
    });
  }

  onCompetenciesAutoLevel(): void {
    if (Object.values(this.thingCompetenceClaimMap).length) {
      const levels = this.things.find(
        (thing) => thing.levels.length > 0
      ).levels;
      const firstThingCompetenceClaims = Object.values(
        this.thingCompetenceClaimMap
      )[0].map((competenceClaim) => {
        return {
          ...competenceClaim,
          thing_level: competenceClaim.thing.levels.find(
            (level) => level.uuid === competenceClaim.thing_level
          ),
        };
      });
      const gradeFormData = {};
      firstThingCompetenceClaims.forEach((competenceClaim) => {
        gradeFormData[competenceClaim.grade.uuid] = [0, []];
      });
      this._dialog.open(AutoLevelCompetenceclaimsDialogComponent, {
        data: {
          firstThingCompetenceClaims,
          levels,
          gradeFormData,
          onSubmit: (levelNumbers: { [gradeUuid: string]: number }) => {
            this._specialityCompetenceClaimHttpService
              .setSpecialityCompetenceClaimsLevel({
                speciality: this.speciality.state.item.uuid,
                things_competence_claims: this.thingCompetenceClaimMap,
                grade_levels: levelNumbers,
              })
              .subscribe((response) => {
                this._loadSpecialityClaims();
              });
          },
        },
      });
    } else {
      this._alertService.error('У навыков специальности отсутствуют уровни');
    }
  }

  onSelectionToggle(isSelected: boolean, specialityUuid: string): void {
    if (isSelected) {
      this.selectedSpecialities.push(specialityUuid);
    } else {
      this.selectedSpecialities = this.selectedSpecialities.filter(
        (sUuid) => sUuid !== specialityUuid
      );
    }
  }

  onSelectionToggleAll(isSelected: boolean): void {
    if (isSelected) {
      this.selectedSpecialities.push(
        ...this.childSpecialities.state.items.map((s) => s.uuid)
      );
      this.isSelectedAll = true;
    } else {
      this.selectedSpecialities = [];
      this.isSelectedAll = false;
    }
  }

  onUpdateGradesToggle(updateGrades: boolean): void {
    if (!updateGrades) {
      this.updateConfig.update_competencies = false;
    }
  }
}
