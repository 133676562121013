import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TeamMembershipHttpService } from '@services/http/TeamMembershipHttpService';
import { AsyncList } from '@rest/AsyncList';
import { TeamMembership } from '@models/teams/team-membership';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { SpecialityGradeHttpService } from '@services/http/SpecialityGradeHttpService';
import { UserService } from 'src/app/services/user/user.service';
import { LoggingService } from 'src/app/services/logging.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AppService } from 'src/app/services/app/app.service';
import { SpecialityUserRelationHttpService } from '@services/http/SpecialityUserRelationHttpService';
import { User } from '@models/user/user';
import { SpecialityUserRelation } from '@models/specialities/speciality-user-relation';
import {
  AddTeamMemberDialogComponent,
  AddTeamMemberFormData,
} from './add-team-member-dialog/add-team-member-dialog.component';
import { TeamEmailInvitationHttpService } from '@services/http/TeamEmailInvitationHttpService';
import { AlertService } from '../../../../../services/ui/ui-alert.service';
import { TitleService } from 'src/app/services/title.service';
import { SpecialityGrade } from '@models/specialities/speciality-grade';
import { EditTeamMemberDialogComponent } from './edit-team-member-dialog/edit-team-member-dialog.component';
import { TeamJoinRequest } from '@models/teams/team-join-request';
import { TeamJoinRequestHttpService } from '@services/http/TeamJoinRequestHttpService';
import { TeamEmailInvitation } from '@models/teams/team-email-invitation';

interface UsersRelationsMap {
  [userUuid: string]: SpecialityUserRelation[];
}

interface SpecialityUsersMap {
  [specialityUuid: string]: User[];
}

@Component({
  selector: 'app-team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.css'],
})
export class TeamMembersComponent implements OnInit, OnDestroy {
  teamUuid;
  teamMemberships: AsyncList<TeamMembership>;
  teamJoinRequests: AsyncList<TeamJoinRequest>;
  teamEmailInvitations: AsyncList<TeamEmailInvitation>;
  _teamSpecialities: AsyncList<Speciality>;
  teamSpecialities: Speciality[] = [];
  _teamSpecialityGrades: AsyncList<SpecialityGrade>;
  teamSpecialityGradesMap: Map<string, SpecialityGrade[]>;
  specialityUsersRelations: AsyncList<SpecialityUserRelation>;

  usersUuidList = [];

  isMatrixTabSelected = false;

  usersRelationsMap: UsersRelationsMap;
  specialityUsersMap: SpecialityUsersMap = {};
  specialitiesThingsDomainsMap;

  rolesMap = {
    owner: 'Руководитель',
    lead: 'Лид',
    member: 'Участник',
  };

  navigationSubscription;
  componentReused = false;

  isLoaded = false;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _dialog: MatDialog,
    private _appService: AppService,
    private _authService: AuthService,
    private _userService: UserService,
    private _alertService: AlertService,
    private _loggingService: LoggingService,
    private _teamEmailInvitationsHttpService: TeamEmailInvitationHttpService,
    private _teamJoinRequestHttpService: TeamJoinRequestHttpService,
    private _teamMembershipHttpService: TeamMembershipHttpService,
    private _specialityService: SpecialityHttpService,
    private _specialityGradeService: SpecialityGradeHttpService,
    private _specialityUserRelationHttpService: SpecialityUserRelationHttpService,
    private _titleService: TitleService
  ) {
    this.navigationSubscription = this._router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initialise the component
      if (this.componentReused && e instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    this.componentReused = true;
    this._titleService.set_title('Участники команды');
    this.usersRelationsMap = {};
    this.teamSpecialities = [];
    this.usersUuidList = [];
    this.teamSpecialityGradesMap = new Map();
    this.teamUuid = this._activatedRoute.snapshot.paramMap.get('uuid');

    this.teamJoinRequests = new AsyncList<TeamJoinRequest>(
      this._teamJoinRequestHttpService
    );
    this.teamJoinRequests.setRequestParams({
      params: {
        team: this.teamUuid,
        status__in: '0,1',
        expand: 'user,specialities,speciality_grades',
      },
    });
    this.teamJoinRequests.load();

    this.teamEmailInvitations = new AsyncList<TeamEmailInvitation>(
      this._teamEmailInvitationsHttpService
    );
    this.teamEmailInvitations.setRequestParams({
      params: {
        team: this.teamUuid,
        status__in: '0,1',
      },
    });
    this.teamEmailInvitations.load();

    this.teamMemberships = new AsyncList<TeamMembership>(
      this._teamMembershipHttpService
    );
    this.teamMemberships.setRequestParams({
      params: {
        team: this.teamUuid,
        expand: 'user.team_memberships.team',
        page_size: 100,
      },
    });
    this.teamMemberships.load().subscribe((response) => {
      this.usersUuidList = this.teamMemberships.state.items.map(
        (membership) => {
          const user = membership.user as User;
          this.usersRelationsMap[user.uuid] = [];
          return user.uuid;
        }
      );
      // console.log('Loading relations...');
      this._loadUsersSpecialities();
    });

    this._teamSpecialities = new AsyncList<Speciality>(this._specialityService);
    this._teamSpecialityGrades = new AsyncList<SpecialityGrade>(
      this._specialityGradeService
    );
    this._teamSpecialities.setRequestParams({
      params: {
        team: this.teamUuid,
        expand: 'related_grades',
        omit: 'related_grades.related_speciality_competence_claims',
        page_size: 100,
      },
    });
    this._teamSpecialities.load().subscribe((response) => {
      this._teamSpecialities.state.items.forEach((speciality) => {
        this.teamSpecialities.push(speciality);
      });
      const specialityUuidList = this._teamSpecialities.state.items.map(
        (speciality) => {
          return speciality.uuid;
        }
      );
      this._teamSpecialityGrades.setRequestParams({
        params: {
          speciality__in: specialityUuidList,
          page_size: 100,
        },
      });
      this._teamSpecialityGrades.load().subscribe((response) => {
        this._teamSpecialityGrades.state.items.forEach((grade) => {
          const specialityUuid = grade.speciality as string;
          if (this.teamSpecialityGradesMap.has(specialityUuid)) {
            this.teamSpecialityGradesMap.get(specialityUuid).push(grade);
          } else {
            this.teamSpecialityGradesMap.set(specialityUuid, [grade]);
          }
        });
      });
    });
  }

  ngOnDestroy(): void {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  private _loadUsersSpecialities(): void {
    this.specialityUsersRelations = new AsyncList<SpecialityUserRelation>(
      this._specialityUserRelationHttpService
    );
    this.specialityUsersRelations.setRequestParams({
      params: {
        user__in: this.usersUuidList.join(','),
        expand: 'speciality,grade,user',
      },
    });
    this.specialityUsersRelations.load().subscribe((response) => {
      // this.teamSpecialities = [];
      const specialitiesUuidList = [];
      response.results.forEach(
        (
          relation: SpecialityUserRelation<User, Speciality, SpecialityGrade>
        ) => {
          const userUuid = relation.user.uuid;
          if (relation.speciality.team === this.teamUuid) {
            if (!specialitiesUuidList.includes(relation.speciality.uuid)) {
              specialitiesUuidList.push(relation.speciality.uuid);
              // this.teamSpecialities.push(relation.speciality);
            }
            if (this.specialityUsersMap[relation.speciality.uuid]) {
              this.specialityUsersMap[relation.speciality.uuid].push(
                relation.user
              );
            } else {
              this.specialityUsersMap[relation.speciality.uuid] = [
                relation.user,
              ];
            }

            if (this.usersRelationsMap[userUuid]) {
              this.usersRelationsMap[userUuid].push(relation);
            } else {
              this.usersRelationsMap[userUuid] = [relation];
            }
          }
        }
      );
      this._specialityService
        .getSpecialitiesThings(specialitiesUuidList)
        .subscribe((response) => {
          this.specialitiesThingsDomainsMap = response.specialities;
        });
    });
  }

  getSpecialityName(userUuid: string): string {
    let text = '';
    if (this.usersRelationsMap[userUuid].length) {
      text = (this.usersRelationsMap[userUuid][0].speciality as Speciality)
        .name;
      if (this.usersRelationsMap[userUuid][0].grade) {
        text =
          text +
          ' ' +
          (this.usersRelationsMap[userUuid][0].grade as SpecialityGrade)?.name;
      }
    } else {
      text = 'Специальность не выбрана';
    }
    return text;
  }

  getUserSpecialitiesCount(userUuid: string): number {
    return this.usersRelationsMap[userUuid]?.length;
  }

  getSpecialitiesCountText(membershipUuid: string): string {
    return 'Ещё ' + (this.getUserSpecialitiesCount(membershipUuid) - 1);
  }

  redirectToMember(user: User, event: any): void {
    if (
      !event
        .composedPath()
        .map((el) => el.className)
        .includes('edit-btn')
    ) {
      this._router.navigate([`team/${this.teamUuid}/members/${user.uuid}`]);
    }
  }

  onMemberAdd(): void {
    const specialities = [];
    this._teamSpecialities.state.items.forEach((speciality) => {
      specialities.push(speciality);
    });
    this._dialog.open(AddTeamMemberDialogComponent, {
      height: '80vh',
      data: {
        teamUuid: this.teamUuid,
        teamSpecialities: specialities,
        onSubmit: (data: AddTeamMemberFormData) => {
          if (data.type === 'add') {
            this.addUser(data as AddTeamMemberFormData<User>);
          } else {
            this.inviteUser(data as AddTeamMemberFormData<string>);
          }
        },
      },
    });
  }

  onMemberEdit(membership: TeamMembership): void {
    const userUuid = (membership.user as User).uuid;
    const specialities = [];
    this._teamSpecialities.state.items.forEach((speciality) => {
      specialities.push(speciality);
      // console.log(`team speciality ${speciality.name}`);
    });
    this._dialog
      .open(EditTeamMemberDialogComponent, {
        height: '95vh',
        data: {
          userUuid: userUuid,
          teamUuid: this.teamUuid,
          userMembershipUuid: membership.uuid,
          teamSpecialities: specialities,
          teamSpecialityGradesMap: this.teamSpecialityGradesMap,
          userRole: membership.role,
          userSpecialitiesRelations: this.usersRelationsMap[userUuid],
        },
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) this.ngOnInit();
      });
  }

  addUser(data: AddTeamMemberFormData<User>): void {
    this._teamMembershipHttpService
      .createWithSpecialities({
        teamMembership: {
          team: this.teamUuid,
          user: data.user.uuid,
          role: data.role.value,
        },
        specialitiesData: [
          {
            speciality_uuid: data.speciality.uuid,
            speciality_grade_uuid: data.grade.uuid,
          },
        ],
      })
      .subscribe(() => {
        this._appService.load_user_memberships();
        this._userService.get_edit_teams().subscribe((response) => {
          this._authService.set_user_edit_teams(response);
          this.ngOnInit();
        });
      });
  }

  onMatrixSelect(event: any): void {
    if (
      event.tab.textLabel === 'Матрица компетенций' &&
      !this.isMatrixTabSelected
    ) {
      this.isMatrixTabSelected = true;
    }
  }

  onTeamJoinAccept(teamJoinRequest: TeamJoinRequest): void {
    this._teamJoinRequestHttpService
      .update(teamJoinRequest.uuid, {
        status: 2,
      })
      .subscribe(() => {
        this.teamJoinRequests.state.items.filter(
          (request) => request.uuid !== teamJoinRequest.uuid
        );
        this._appService.load_user_memberships();
        this._userService.get_edit_teams().subscribe((response) => {
          this._authService.set_user_edit_teams(response);
          this.ngOnInit();
        });
      });
  }

  onTeamJoinReject(teamJoinRequest: TeamJoinRequest): void {
    this.teamJoinRequests.state.isProcessing = true;
    this.teamJoinRequests.state.isLoaded = false;
    this._teamJoinRequestHttpService
      .update(teamJoinRequest.uuid, {
        status: 3,
      })
      .subscribe(() => {
        this.teamJoinRequests.state.items =
          this.teamJoinRequests.state.items.filter(
            (request) => request.uuid !== teamJoinRequest.uuid
          );
        this.teamJoinRequests.state.isProcessing = false;
        this.teamJoinRequests.state.isLoaded = true;
      });
  }

  onEmailInvitationDelete(emailInvitation: TeamEmailInvitation): void {
    this._teamEmailInvitationsHttpService
      .delete(emailInvitation.uuid)
      .subscribe(() => {
        this.teamEmailInvitations.state.items =
          this.teamEmailInvitations.state.items.filter(
            (invitation) => invitation.uuid !== emailInvitation.uuid
          );
      });
  }

  inviteUser(data: AddTeamMemberFormData<string>): void {
    this._teamEmailInvitationsHttpService
      .create({
        email: data.user,
        created_by: this._authService.get_current_user_uuid(),
        team: this.teamUuid,
        specialities: [data.speciality.uuid],
        speciality_grades: [data.grade.uuid],
      })
      .subscribe(
        () => {
          this._alertService.success('Приглашение отправлено!');
          this.teamEmailInvitations.load();
        },
        () => {
          this._alertService.error('Не удалось отправить приглашение!');
        }
      );
  }
}
