import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Speciality } from '@models/specialities/speciality';
import { TeamHttpService } from '@services/http/TeamHttpService';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Team } from '@models/teams/team';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-speciality-to-team',
  templateUrl: './add-speciality-to-team.component.html',
  styleUrls: ['./add-speciality-to-team.component.css'],
})
export class AddSpecialityToTeamComponent implements OnInit {
  editTeams: Team[] = [];
  selectedTeam: Team = null;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      speciality: AsyncDetail<Speciality>;
    },
    private _teamHttpService: TeamHttpService,
    private _authService: AuthService,
    private _alertService: AlertService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._authService.check_can_user_edit_team_subject.subscribe((data) => {
      if (data.value.loaded) {
        this.editTeams = data.value.teams;
      }
    });
  }

  addSpecialityToTeam(): void {
    this._teamHttpService
      .addSpeciality({
        team: this.selectedTeam.uuid,
        speciality: this.data.speciality.uuid,
      })
      .subscribe(
        (response) => {
          this._alertService.success('Успешно добавлено в команду');
          this._router.navigate([
            `/team/${this.selectedTeam.uuid}/specialities/${response.uuid}`,
          ]);
        },
        () => {
          this._alertService.error('Не удалось добавить в команду');
        }
      );
  }
}
