import { Component, OnInit } from '@angular/core';
import { TeamHttpService } from '@services/http/TeamHttpService';
import { AsyncList } from '@rest/AsyncList';
import { Team } from '@models/teams/team';
import { Router } from '@angular/router';
import { TitleService } from '../../../../services/title.service';
import { FirstLevelTeamData } from '@components/common/teams-grid/teams-grid.component';

@Component({
  selector: 'app-teams-dashboard-new',
  templateUrl: './teams-dashboard-new.component.html',
  styleUrls: ['./teams-dashboard-new.component.css'],
})
export class TeamsDashboardNewComponent implements OnInit {
  teams: AsyncList<Team>;

  firstLevelTeamsData: FirstLevelTeamData[] = [];
  adminTeams: FirstLevelTeamData[];
  guildTeams: FirstLevelTeamData[];
  agileTeams: FirstLevelTeamData[];

  firstLevelTeamsDataReady = false;

  constructor(
    private _router: Router,
    private titleService: TitleService,
    private _teamHttpService: TeamHttpService
  ) {}

  ngOnInit(): void {
    this.titleService.set_title('Структура компании');
    this.teams = new AsyncList<Team>(this._teamHttpService);
    this.teams.setRequestParams({
      params: {
        expand: 'members',
      },
    });
    this.teams.load().subscribe(() => this._setFirstLevelTeamsData());
  }

  private _setFirstLevelTeamsData(): void {
    this.teams.state.items.forEach((team) => {
      if (!team.parent) {
        const firstLevelTeamData = { ...team, childrenCount: 0 };
        this.firstLevelTeamsData.push(firstLevelTeamData);
        this._countChildTeams(firstLevelTeamData, team);
      }
    });
    this.adminTeams = this.firstLevelTeamsData.filter(
      (team) => team.team_type === 'admin'
    );
    this.guildTeams = this.firstLevelTeamsData.filter(
      (team) => team.team_type === 'guild'
    );
    this.agileTeams = this.firstLevelTeamsData.filter(
      (team) => team.team_type === 'agile'
    );
    this.firstLevelTeamsDataReady = true;
  }

  private _countChildTeams(
    firstLevelTeam: FirstLevelTeamData,
    team: Team
  ): void {
    const childTeams = this.teams.state.items.filter(
      (t) => t.parent === team.uuid
    );
    firstLevelTeam.childrenCount += childTeams.length;
    childTeams.forEach((t) => this._countChildTeams(firstLevelTeam, t));
  }
}
