<div class='reviews-container' *ngIf='!preview'>
  <div fxLayout='row'>
    <div fxFlex class='title'>Оценка команды</div>
  </div>
  <div class='review-desc'>
    Для каждого выбранного участника будет создано отдельное ревью
    <br />
    На последнем шаге вы сможете провести детальную настройку ревью для каждого
    участника
  </div>
  <div>
    <mat-horizontal-stepper
      [@.disabled]='true'
      [linear]='true'
      labelPosition='end'
      (selectionChange)='onNextMatStep($event)'
    >
      <mat-step
        label='Название и участники'
        [editable]='false'
        fxFlex
        [completed]='isFirstStepCompleted()'
      >
        <mat-divider class='divider'></mat-divider>
        <form [formGroup]='reviewInfoForm'>
          <div fxLayout='row' fxLayoutGap='16px'>
            <div fxFlex='50' fxLayout='column'>
              <mat-label>Название ревью</mat-label>
              <mat-form-field appearance='outline' fxFlex='100'>
                <input matInput formControlName='name' type='text' />
              </mat-form-field>
            </div>
            <div fxFlex='50' fxLayout='column'>
              <mat-label>Срок ревью</mat-label>
              <mat-form-field appearance='outline' fxFlex='100'>
                <mat-date-range-input [rangePicker]='picker'>
                  <input matStartDate formControlName='dateFrom' />
                  <input matEndDate formControlName='dateTo' />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]='picker'
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
            </div>
          </div>
          <div fxLayout='row'>
            <mat-form-field
              floatLabel='never'
              appearance='outline'
              fxFlex='100'
            >
              <input
                matInput
                placeholder='Фильтр'
                type='text'
                autocomplete='off'
                formControlName='search'
                (keyup)='searchSubject()'
              />
            </mat-form-field>
          </div>
        </form>
        <app-with-processing
          [processing]='!teamMemberships && !teamMemberships.state.isLoaded'
        >
          <app-user-list
            [users]='teamSubjects'
            [selectMode]="'multiple'"
            [checkedUsers]='reviewInfoForm.controls.subject.value'
            (selectUsers)='onSelectUsers($event)'
          ></app-user-list>
        </app-with-processing>
        <div
          fxLayout='row'
          fxLayoutAlign='end center'
          fxLayoutGap='6px'
          class='stepper-buttons-container'
        >
          <button
            mat-raised-button
            class='stepper-prev-button'
            routerLink='/team/{{ teamUuid }}/reviews'
          >
            Отменить
          </button>
          <button
            matStepperNext
            mat-raised-button
            class='stepper-next-button'
            color='primary'
            [disabled]='!isFirstStepCompleted()'
          >
            Далее
          </button>
        </div>
      </mat-step>
      <mat-step label='Тип ревью' [editable]='false' fxFlex>
        <mat-divider class='divider'></mat-divider>
        <div fxLayout='row' fxLayoutGap='24px'>
          <mat-card
            fxFlex
            fxLayout='column'
            [class.review-type_selected]='isSelfReview'
            class='review-type-container'
          >
            <span
              class='review-type-title'
              [class.review-type-text_selected]='isSelfReview'
            >Самооценка с коллегами</span
            >
            <span
              class='review-type-desc'
              [class.review-type-text_selected]='isSelfReview'
            >
            Самооценка пользователя и завершение оценки от руководителя.
            Также в этот вид оценки можно пригласить коллег в качестве ревьеров.
            </span>
            <div
              fxLayout='row'
              fxLayoutAlign='start end'
              class='review-type-button-container'
            >
              <button
                mat-raised-button
                fxLayout='row'
                class='review-type-button'
                color='{{isSelfReview ? "primary" : ""}}'
                (click)='isSelfReview = !isSelfReview'
              >
                <span>{{ isSelfReview ? 'Выбрано' : 'Выбрать' }}</span>
                <mat-icon *ngIf='isSelfReview' class='review-type-icon'
                >check_circle_outline
                </mat-icon
                >
              </button>
            </div>
          </mat-card>
          <mat-card
            fxFlex
            fxLayout='column'
            [class.review-type_selected]='!isSelfReview'
            class='review-type-container'
          >
            <span
              class='review-type-title'
              [class.review-type-text_selected]='!isSelfReview'
            >Оценка 360</span
            >
            <span
              class='review-type-desc'
              [class.review-type-text_selected]='!isSelfReview'
            >
            Оценка от ревьюеров и завершение оценки от руководителя.
            Сотрудник в этом виде оценки не оценивает свои навыки.
            </span>
            <div
              fxLayout='row'
              fxLayoutAlign='start end'
              class='review-type-button-container'
            >
              <button
                mat-raised-button
                fxLayout='row'
                class='review-type-button'
                color='{{!isSelfReview ? "primary" : ""}}'
                (click)='isSelfReview = !isSelfReview'
              >
                <span>{{ !isSelfReview ? 'Выбрано' : 'Выбрать' }}</span>
                <mat-icon *ngIf='!isSelfReview' class='review-type-icon'
                >check_circle_outline
                </mat-icon
                >
              </button>
            </div>
          </mat-card>
        </div>
        <div
          fxLayout='row'
          fxLayoutAlign='end center'
          fxLayoutGap='6px'
          class='stepper-buttons-container'
        >
          <button
            mat-raised-button
            class='stepper-prev-button'
            routerLink='/team/{{ teamUuid }}/reviews'
          >
            Отменить
          </button>
          <button
            matStepperNext
            mat-raised-button
            class='stepper-next-button'
            color='primary'
            (click)='onSpecialitiesStep()'
          >
            Далее
          </button>
        </div>
      </mat-step>
      <mat-step
        label='Специальности'
        [editable]='false'
        fxFlex
        [completed]='!!selectedSpeciality'
      >
        <app-with-processing
          *ngIf='teamSpecialities'
          [processing]='!teamSpecialities.state.isLoaded'
        >
          <div
            fxLayout='column'
            class='specialities-container'
            fxLayoutGap='8px'
          >
            <div
              *ngFor='let speciality of teamSpecialities.state.items'
              fxLayout='row'
              class='speciality-container'
              (click)='selectSpeciality(speciality)'
            >
              <div
                class='speciality-name'
                [class.speciality_selected]='
                  isSpecialitySelected(speciality.uuid)
                '
                fxFlex
              >
                <span>{{ speciality.name }}</span>
              </div>
              <div
                class='speciality-selected-icon'
                fxFlex
                fxLayoutAlign='end center'
              >
                <mat-icon *ngIf='isSpecialitySelected(speciality.uuid)'
                >check_circle_outline
                </mat-icon
                >
              </div>
            </div>
          </div>
          <div
            fxLayout='row'
            fxLayoutAlign='end center'
            fxLayoutGap='6px'
            class='stepper-buttons-container'
          >
            <button
              mat-raised-button
              class='stepper-prev-button'
              routerLink='/team/{{ teamUuid }}/reviews'
            >
              Отменить
            </button>
            <button
              matStepperNext
              mat-raised-button
              class='stepper-next-button'
              color='primary'
              [disabled]='!selectedSpeciality'
              (click)='onCompetenciesStep()'
            >
              Далее
            </button>
          </div>
        </app-with-processing>
      </mat-step>
      <mat-step
        label='Компетенции'
        [editable]='false'
        fxFlex
        [completed]='isCompetenciesStepCompleted()'
      >
        <app-select-library-things
          *ngIf='selectedSpeciality'
          [specialityUuid]='selectedSpeciality.uuid'
          [selectedClaims]='selectedClaims'
          (selectedClaimsChange)='onSelectedClaimsChange($event)'
        ></app-select-library-things>
        <div
          fxLayout='row'
          fxLayoutAlign='end center'
          fxLayoutGap='6px'
          class='stepper-buttons-container'
        >
          <button
            mat-raised-button
            class='stepper-prev-button'
            routerLink='/team/{{ teamUuid }}/reviews'
          >
            Отменить
          </button>
          <button
            matStepperNext
            mat-raised-button
            class='stepper-next-button'
            color='primary'
            [disabled]='!isCompetenciesStepCompleted()'
            (click)='onReviewersStep()'
          >
            Далее
          </button>
        </div>
      </mat-step>
      <mat-step label='Ревьюеры' [editable]='false' fxFlex>
        <mat-divider class='divider'></mat-divider>
        <div *ngIf='isSelfReview' class='skip-reviewers-container' fxLayout='row'>
          <div fxFlex='100' fxLayout='row' fxLayoutAlign='start center'>
            <div fxLayout='column' fxFlex>
              <span class='skip-reviewers-header'>
                Продолжить без выбора ревьюеров
              </span>
              <span
                class='skip-reviewers-desc'>В таком случае пользователь пройдет селф-ревью с верификацией руководителем
              </span>
            </div>
            <div>
              <button mat-raised-button (click)='onFinalStep()' color='primary'>
                Продолжить без ревьюеров
              </button>
            </div>
          </div>
        </div>
        <app-select-users-with-search
          *ngIf='teamReviewers'
          [users]='teamReviewers'
          [checkedUsers]='selectedReviewers'
          (selectedUsersChange)='onSelectedReviewersChange($event)'
        ></app-select-users-with-search>
        <div *ngIf='teamReviewers?.length === 0'>
          Воспользуйтесь поиском для выбора ревьюера
        </div>
        <div
          fxLayout='row'
          fxLayoutAlign='end center'
          fxLayoutGap='6px'
          class='stepper-buttons-container'
        >
          <button
            mat-raised-button
            class='stepper-prev-button'
            routerLink='/team/{{ teamUuid }}/reviews'
          >
            Отменить
          </button>
          <button
            matStepperNext
            mat-raised-button
            class='stepper-next-button'
            [disabled]='!(selectedReviewers?.length > 0)'
            (click)='onFinalStep()'
          >
            Готово
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
<div *ngIf='preview'>
  <div class='reviews-container' fxLayout='column'>
    <div
      fxLayout='row'
      fxLayoutAlign='start center'
      fxLayoutGap='2rem'
      class='reviews-preview-title'
    >
      <button (click)='preview = false' mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span>Предпросмотр</span>
    </div>
    <div fxLayout='row' fxLayoutGap='2rem'>
      <div fxLayout='column' fxFlex class='reviews-preview-data-card'>
        <span>Срок ревью</span>
        <span>{{ PrettyReviewDate }}</span>
      </div>
      <div fxLayout='column' fxFlex class='reviews-preview-data-card'>
        <span>Специальность</span>
        <span>{{ selectedSpeciality.name }}</span>
      </div>
    </div>
    <div fxLayout='row' class='reviews-preview-container-header'>
      <span fxFlex='33'>Участник</span>
      <span fxFlex='34'>Компетенции</span>
      <span fxFlex='33'>Ревьюеры</span>
    </div>
    <mat-divider class='divider'></mat-divider>
    <div
      *ngFor='let subjectData of getReviewSubjectsData()'
      class='review-data-container'
    >
      <span fxFlex='33'>{{
        subjectData.subject.first_name + ' ' + subjectData.subject.last_name
        }}</span>
      <div fxFlex='34' fxLayout='row' fxLayoutGap='4px'>
        <span class='review-data-item'>{{
          getSubjectClaim(subjectData.specialityClaims[0])
          }}</span>
        <span
          class='review-data-item'
          *ngIf='subjectData.specialityClaims.length > 1'
        >{{ 'ещё ' + (subjectData.specialityClaims.length - 1) }}</span
        >
        <div>
          <button
            mat-icon-button
            class='review-data-edit'
            (click)='editSpecialityClaims(subjectData)'
          >
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
      <div fxFlex='33' fxLayout='row' fxLayoutGap='4px'>
        <span class='review-data-item' *ngIf='!subjectData.reviewers.length'>Без ревьюеров</span>
        <span class='review-data-item' *ngIf='subjectData.reviewers.length'>{{
          subjectData.reviewers[0].last_name +
          ' ' +
          getUserInitials(subjectData.reviewers[0])
          }}</span>
        <span
          class='review-data-item'
          *ngIf='subjectData.reviewers.length > 1'
        >{{ 'ещё ' + (subjectData.reviewers.length - 1) }}</span
        >
        <div>
          <button
            mat-icon-button
            class='review-data-edit'
            (click)='editReviewers(subjectData.subject.uuid)'
          >
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <mat-divider [style.margin]="'16px 0 24px'"></mat-divider>
    <div>Руководитель</div>
    <div
      class='review-responsible-container'
      fxLayout='row'
      fxLayoutAlign='start center'
      fxLayoutGap='24px'
    >
      <div>
        <mat-icon>person</mat-icon>
      </div>
      <div fxLayout='column'>
        <span>{{
          reviewResponsible.last_name + ' ' + reviewResponsible.first_name
          }}</span>
        <span>{{ reviewResponsible.email }}</span>
      </div>
      <button
        mat-raised-button
        class='review-back-button'
        (click)='onResponsibleChange()'
      >
        Изменить
      </button>
    </div>
    <mat-divider class='divider'></mat-divider>
    <div fxLayout='row' fxLayoutAlign='end center' fxLayoutGap='1rem'>
      <button
        mat-raised-button
        class='review-back-button'
        (click)='preview = false'
      >
        Отменить
      </button>
      <button
        mat-raised-button
        class='review-create-button'
        [disabled]='isCreateButtonDisabled'
        (click)='reviewCreate()'
      >
        Создать
      </button>
    </div>
  </div>
</div>
