import { Observable, Subject } from 'rxjs';
import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { PageEvent } from '@angular/material/paginator';
import { Thing } from 'src/app/models/ontology/thing';
import { ThingLevel } from 'src/app/models/ontology/thing-level';

export interface ListState {
  items: any[];
  isLoaded: boolean;
  isProcessing: boolean;
}

interface ListPagination {
  page: number;
  pageSize: number;
  length: number;
}

interface ListRequestParams {
  [key: string]: any;
}

interface FetchAllConfig {
  page?: number;
  [key: string]: any;
}

export interface ListApiService {
  fetch_all(config: FetchAllConfig): Observable<any>;
  fetch_by_uuid(detail_uuid: string): Observable<any>;
  delete(detail_uuid: string): Observable<any>;
  update(uuid: string, data: any): Observable<any>;
  create(data: any): Observable<any>;
}

interface ListRequestResponse {
  results: any[];
  count: number;
}

export class List {
  public page_event: PageEvent;

  public state: ListState = {
    isProcessing: false,
    isLoaded: false,
    items: [],
  };
  public pagination: ListPagination = {
    pageSize: 10,
    page: 1,
    length: 0,
  };
  public requestParams: ListRequestParams = {};
  public label = 'items';

  public loggingService: LoggingService;
  public alertService: AlertService;
  public apiService: ListApiService;

  constructor(loggingService: LoggingService, alertService, apiService: any) {
    this.loggingService = loggingService;
    this.alertService = alertService;
    this.apiService = apiService;
  }

  load() {
    this.state.isProcessing = true;
    return this.apiService
      .fetch_all({
        page: this.pagination.page,
        ...this.requestParams,
      })
      .subscribe(
        (response: ListRequestResponse) => {
          this.state = {
            items: response.results,
            isLoaded: true,
            isProcessing: false,
          };

          this.pagination.length = response.count;
          this.loggingService.debug(
            `${this.constructor.name} loaded ${this.state.items.length} ${this.label}`
          );
          this.onLoadData(this.state);
        },
        (err) => {
          this.alertService.error(
            `Ошибка при загрузке ${this.label}: ${err.status}`
          );
          this.loggingService.error(
            `${this.constructor.name} failed to load ${this.label}`
          );
        }
      );
  }

  public onChangePage($event) {
    this.pagination.page = $event.pageIndex + 1;
    this.load();
    return $event;
  }

  public onLoadData(state) {}

  public remove(uuid) {
    this.apiService.delete(uuid).subscribe(
      () => {
        this.loggingService.debug(
          `${this.constructor.name} remove ${this.label}`
        );
        this.state.items = this.state.items.filter((element: any) => {
          return element.uuid !== uuid;
        });
      },
      (err) => {
        this.loggingService.debug(
          `${this.constructor.name} failed to remove ${this.label}`
        );
        this.alertService.error(`Ошибка удаления: ${err.status}`);
      }
    );
  }

  public update(uuid, data) {
    const onSuccess$ = new Subject();
    this.apiService.update(uuid, data).subscribe({
      next: () => {
        this.loggingService.debug(
          `${this.constructor.name} update ${this.label}`
        );
        this.state.items = this.state.items.map((item) => {
          return item.uuid === uuid ? { ...item, ...data } : item;
        });
        onSuccess$.next();
      },
      error: (err) => {
        this.alertService.error(`Ошибка обновления: ${err.status}`);
      },
    });

    return onSuccess$;
  }
}
