<div class="dialog-container">
  <div class="title">Добавить компетенцию в специальность</div>
  <form [formGroup]="form">
    <mat-form-field class="search-field">
      <input
        matInput
        placeholder="Поиск по компетенциям"
        type="text"
        (keyup)="search()"
        formControlName="search"
        autocomplete="off"
      />
    </mat-form-field>
    <div class="competencies-container">
      <app-with-processing
        [processing]="
          domains?.state.isProcessing &&
          things?.state.isProcessing &&
          processingFilteredThings
        "
      >
        <app-domain-thing-tree
          *ngIf="
            domains?.state.isLoaded &&
            things?.state.isLoaded &&
            !processingFilteredThings
          "
          [domains]="domains.state.items"
          [things]="filteredThings"
          [selectMode]="true"
          (selectedClaimsChange)="onSelectedClaimsChange($event)"
        ></app-domain-thing-tree>
      </app-with-processing>
    </div>
    <!-- <div fxLayout="row">
      <div fxFlex fxLayoutAlign="start center">
        <span>Не нашли нужную компетенцию?</span>
      </div>
      <div
        fxFlex
        fxLayoutAlign="end center"
        (click)="openCreateDialog()"
        class="create-dialog-button-container"
      >
        <button mat-icon-button><mat-icon>add_circle_outline</mat-icon></button>
        <span>Предложите свою</span>
      </div>
    </div> -->
  </form>

  <div mat-dialog-actions fxLayoutAlign="end center">
    <button
      class="dialog-reject-button"
      [mat-dialog-close]="true"
      mat-raised-button
    >
      Отменить
    </button>
    <button
      class="dialog-accept-button"
      [disabled]="form.invalid"
      mat-raised-button
      (click)="close()"
    >
      Добавить
    </button>
  </div>
</div>
