import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-speciality-create-page',
  templateUrl: './speciality-create.page.html',
  styleUrls: ['./speciality-create.page.css'],
})
export class SpecialityCreatePage implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
