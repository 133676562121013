import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { TeamMembership } from '@models/teams/team-membership';
import { Observable } from 'rxjs';

interface CreateMembershipSpecialityData {
  speciality_uuid: string;
  speciality_grade_uuid: string;
}

@Injectable({
  providedIn: 'root',
})
export class TeamMembershipHttpService extends ViewSetHttpService<TeamMembership> {
  http: HttpClient;
  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'teams/membership';

  public createWithSpecialities({
    teamMembership,
    specialitiesData,
  }: {
    teamMembership: Partial<TeamMembership>;
    specialitiesData: CreateMembershipSpecialityData[];
  }): Observable<any> {
    return this.http.post<{
      team_membership: Partial<TeamMembership>;
    }>(`${this.getUrl()}/${teamMembership.team}/create_with_specialities`, {
      team_membership: teamMembership,
      specialities_data: specialitiesData,
    });
  }
}
