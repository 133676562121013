import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectHttpService } from '@services/http/ProjectHttpService';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Project } from '@models/projects/project';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { get_full_parent_path } from '../../../../utils/getFullParentUrl';
import { AsyncList } from '@rest/AsyncList';
import { User } from '@models/user/user';
import { Company } from '@models/projects/company';
import { CompanyHttpService } from '@services/http/CompanyHttpService';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css'],
})
export class ProjectDetailsComponent implements OnInit {
  projectUuid: string;
  project: AsyncDetail<Project>;
  companies: AsyncList<Company>;

  projectInfo;

  form: UntypedFormGroup;

  projectUsers: User[] = [];
  combinedUsers: User[] = [];
  searchUsers: AsyncList<User>;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _dateAdapter: DateAdapter<Date>,
    private _formBuilder: UntypedFormBuilder,
    private _projectHttpService: ProjectHttpService,
    private _companyHttpService: CompanyHttpService
  ) {
    this._dateAdapter.setLocale('ru-RU'); // dd/MM/yyyy
  }

  ngOnInit(): void {
    this.projectUuid = this._activatedRoute.snapshot.paramMap.get('pUuid');
    this.project = new AsyncDetail<Project>(
      this.projectUuid,
      this._projectHttpService
    );
    this.project.requestParams = {
      params: {
        expand: 'resources',
      },
    };
    this.project.load().subscribe(() => {
      this._setProjectInfo();
      this.companies = new AsyncList<Company>(this._companyHttpService);
      this.companies.load();
    });
  }

  private _setProjectInfo(): void {
    const projectData = this.project.state.item;
    this.projectInfo = [
      { name: 'Название', text: projectData.name },
      { name: 'Описание', text: projectData.description },
      {
        name: 'Срок проекта',
        text: this.getPrettyProjectDate(projectData),
      },
    ];
  }

  getPrettyProjectDate(project: Project): string {
    const dateFrom = new Date(project.planned_start),
      dateTo = new Date(project.planned_finish);
    return `${dateFrom.getDate()} ${dateFrom.toLocaleString('default', {
      month: 'short',
    })} - ${dateTo.getDate()} ${dateTo.toLocaleString('default', {
      month: 'short',
    })}`;
  }

  onProjectEdit(): void {
    this._router.navigate([
      `${this.getFullParentPath(this._activatedRoute)}/edit`,
    ]);
  }

  onBackClick(): void {
    history.back();
  }

  getFullParentPath = get_full_parent_path;
}
