import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import * as d3 from 'd3';

import { LoggingService } from 'src/app/services/logging.service';
import {
  getDefaultChartRadarCfg,
  ChartRadarCfg,
} from 'src/app/utils/chart-radar-cfg';

export interface ChartInputAxesData {
  name: string;
}

export interface RadarDataAxe {
  uuid: string;
  name: string;
  title: string;
  value: number;
  valueRaw: number;
}

export interface ChartInputRadarData {
  axes: RadarDataAxe[];
  color: string;
  desc: string;
  name: string;
}

@Component({
  selector: 'app-chart-radar',
  templateUrl: './chart-radar.component.html',
  styleUrls: ['./chart-radar.component.css'],
})
export class ChartRadarComponent implements OnInit, OnChanges {
  @Input() htmlElementClass: string;
  @Input() grades: ChartInputRadarData[];
  @Input() axesData: ChartInputAxesData[];
  @Input() radarData: ChartInputRadarData[];
  @Input() cfg: ChartRadarCfg = getDefaultChartRadarCfg();

  private _parent: any;
  private _svg: any;
  private _g: any;

  private _axisNames: string[];
  private _axisCount: number;
  private _radiusOutermostCircle: number;
  private _radiusScale: any;
  private _angleSlice: number;
  private _axisGrid: any;

  showGrades = false;

  constructor(private loggingService: LoggingService) {}

  ngOnInit(): void {
    this.loggingService.debug(
      `${this.constructor.name} init ${this.htmlElementClass}`
    );
    this.setConstants();
  }

  private get maxDataValue(): number {
    // 100%
    return 110;
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.setConstants();
      this.drawChart();
    }, 100);
  }

  private setConstants(): void {
    this._axisNames = this.axesData.map((axesItem) => axesItem.name);
    this._axisCount = this.axesData.length;
    this._radiusOutermostCircle = Math.min(
      this.cfg.width / 2,
      this.cfg.height / 2
    );
    this._angleSlice = (Math.PI * 2) / this._axisCount; // The width in radians of each slice
    this._radiusScale = d3
      .scaleLinear()
      .range([0, this._radiusOutermostCircle])
      .domain([0, this.maxDataValue]);
  }

  private drawChart(): void {
    this.loggingService.debug(`${this.constructor.name} draw chart`);
    this.svgInit();
    this.drawCircularGrid();
    this.drawAxes();
    this.drawRadar();
    if (this.showGrades) {
      this.drawGrades();
    }
  }

  private svgInit(): void {
    this._parent = d3.select(`.${this.htmlElementClass}`);
    this._parent.select('svg').remove();
    this._svg = this._parent
      .append('svg')
      .attr(
        'width',
        this.cfg.width + this.cfg.margin.left + this.cfg.margin.right
      )
      .attr(
        'height',
        this.cfg.height + this.cfg.margin.top + this.cfg.margin.bottom
      )
      .attr('class', 'app-common-speciality-radar-chart');

    this._g = this._svg
      .append('g')
      .attr(
        'transform',
        'translate(' +
          (this.cfg.width / 2 + this.cfg.margin.left) +
          ',' +
          (this.cfg.height / 2 + this.cfg.margin.top) +
          ')'
      );

    // Filter for the outside glow
    const filter = this._g.append('defs').append('filter').attr('id', 'glow');
    filter
      .append('feGaussianBlur')
      .attr('stdDeviation', '2.5')
      .attr('result', 'coloredBlur');
    const feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'coloredBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
  }

  private drawCircularGrid(): void {
    this._axisGrid = this._g.append('g').attr('class', 'axisWrapper');

    // Draw the background circles
    this._axisGrid
      .selectAll('.levels')
      .data(d3.range(1, this.cfg.levels + 1).reverse())
      .enter()
      .append('circle')
      .attr('class', 'grid-circle')
      .attr('r', (d) => (this._radiusOutermostCircle / this.cfg.levels) * d)
      .style('fill', this.cfg.circularGrid.fill)
      .style('stroke', this.cfg.circularGrid.stroke)
      .style('fill-opacity', this.cfg.circularGrid.opacity)
      .style('filter', 'url(#glow)');
  }

  private drawAxes(): void {
    const axis = this._axisGrid
      .selectAll('.axis')
      .data(this._axisNames)
      .enter()
      .append('g')
      .attr('class', 'axis');

    // Append the lines
    axis
      .append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr(
        'x2',
        (d, i) =>
          this._radiusScale(this.maxDataValue * 1.1) *
          Math.cos(this._angleSlice * i - Math.PI / 2)
      )
      .attr(
        'y2',
        (d, i) =>
          this._radiusScale(this.maxDataValue * 1.1) *
          Math.sin(this._angleSlice * i - Math.PI / 2)
      )
      .attr('class', 'line')
      .style('stroke', this.cfg.axes.stroke)
      .style('stroke-width', '2px');

    // Append the labels at each axis
    axis
      .append('text')
      .attr('class', 'legend')
      .style('font-size', '11px')
      .attr('text-anchor', 'middle')
      .attr('dy', '0em')
      .attr(
        'x',
        (d, i) =>
          this._radiusScale(this.maxDataValue * this.cfg.labelFactor) *
          Math.cos(this._angleSlice * i - Math.PI / 2)
      )
      .attr(
        'y',
        (d, i) =>
          this._radiusScale(this.maxDataValue * this.cfg.labelFactor) *
          Math.sin(this._angleSlice * i - Math.PI / 2)
      )
      .text((d) => d as any)
      .call(this.wrapText, this.cfg.wrapWidth);
  }

  private wrapText(inputText, width) {
    inputText.each(function () {
      const text = d3.select(this);
      const words = text.text().split(/\s+/).reverse();
      let word;
      let line = [];
      let lineNumber = 0;
      const lineHeight = 1.4; // ems
      const y = text.attr('y');
      const x = text.attr('x');
      const dy = parseFloat(text.attr('dy'));
      let tspan = text
        .text(null)
        .append('tspan')
        .attr('x', x)
        .attr('y', y)
        .attr('dy', dy + 'em');

      // tslint:disable-next-line:no-conditional-assignment
      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(' '));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text
            .append('tspan')
            .attr('x', x)
            .attr('y', y)
            .attr('dy', ++lineNumber * lineHeight + dy + 'em')
            .text(word);
        }
      }
    });
  }

  private drawRadar(): void {
    const radarLine = d3
      .radialLine()
      .curve(d3.curveLinearClosed)
      .radius((d) => this._radiusScale((d as any).value))
      .angle((d, i) => i * this._angleSlice);

    if (this.cfg.roundStrokes) {
      radarLine.curve(d3.curveCardinalClosed);
    }

    const blobWrapper = this._g
      .selectAll('.radar-user-wrapper')
      .data(this.radarData)
      .enter()
      .append('g')
      .attr('class', 'radar-user-wrapper');

    blobWrapper
      .append('path')
      .attr('class', 'radar-user-area')
      .attr('d', (d) => radarLine((d as any).axes))
      .style('fill', (d, i) => d.color)
      .style('fill-opacity', this.cfg.area.opacityArea);

    // Create the outlines
    blobWrapper
      .append('path')
      .attr('class', 'radar-user-stroke')
      .attr('d', (d, i) => radarLine(d.axes))
      .style('stroke-width', this.cfg.area.strokeWidth + 'px')
      .style('stroke', (d, i) => d.color)
      .style('fill', 'none')
      .style('filter', 'url(#glow)');

    // Append the circles
    blobWrapper
      .selectAll('.radar-user-circle')
      .data((d) => (d as any).axes)
      .enter()
      .append('circle')
      .attr('class', 'radar-user-circle')
      .attr('r', this.cfg.area.dotRadius)
      .attr(
        'cx',
        (d, i) =>
          this._radiusScale(d.value) *
          Math.cos(this._angleSlice * i - Math.PI / 2)
      )
      .attr(
        'cy',
        (d, i) =>
          this._radiusScale(d.value) *
          Math.sin(this._angleSlice * i - Math.PI / 2)
      )
      .style('fill', 'grey')
      .style('fill-opacity', 0.8)

      // for tooltips on dots
      .style('pointer-events', 'all')
      .on('mouseover', (d, i) => {
        const cx =
          this._radiusScale(d.value) *
          Math.cos(this._angleSlice * i - Math.PI / 2);
        const cy =
          this._radiusScale(d.value) *
          Math.sin(this._angleSlice * i - Math.PI / 2);
        const newX = cx - 10;
        const newY = cy - 10;

        tooltip
          .attr('x', newX)
          .attr('y', newY)
          .text(
            `${d.title}: ${
              Number.isInteger(d.valueRaw) ? d.valueRaw : d.valueRaw.toFixed(2)
            } (${d.value}%)`
          )
          .transition()
          .duration(200)
          .style('opacity', 1);
      })
      .on('mouseout', () => {
        tooltip.transition().duration(200).style('opacity', 0);
      });

    //Set up the small tooltip for when you hover over a circle
    const tooltip = this._g
      .append('text')
      .style('font-size', this.cfg.area.tooltipSize)
      .style('color', this.cfg.area.tooltipColor)
      .style('opacity', 0);
  }

  private drawGrades(): void {
    const radarLine = d3
      .radialLine()
      .curve(d3.curveLinearClosed)
      .radius((d) => this._radiusScale((d as any).value))
      .angle((d, i) => i * this._angleSlice);

    if (this.cfg.roundStrokes) {
      radarLine.curve(d3.curveCardinalClosed);
    }

    const blob_wrapper = this._g
      .selectAll('.radar-wrapper')
      .data(this.grades)
      .enter()
      .append('g')
      .attr('class', 'radar-wrapper');

    // Create the outlines
    blob_wrapper
      .append('path')
      .attr('class', 'radar-grade-stroke')
      .attr('d', (d, i) => radarLine(d.axes))
      .style('stroke-width', this.cfg.path.strokeWidth + 'px')
      .style('stroke', (d, i) => d.color)
      .style('fill', 'none');

    // Append the circles
    blob_wrapper
      .selectAll('.radar-grade-circle')
      .data((d) => (d as any).axes)
      .enter()
      .append('circle')
      .attr('class', 'radar-grade-circle')
      .attr('r', this.cfg.path.dotRadius)
      .attr(
        'cx',
        (d, i) =>
          this._radiusScale(d.value) *
          Math.cos(this._angleSlice * i - Math.PI / 2)
      )
      .attr(
        'cy',
        (d, i) =>
          this._radiusScale(d.value) *
          Math.sin(this._angleSlice * i - Math.PI / 2)
      )
      .style('fill', (d, i) => d.color)
      .style('fill-opacity', 0.8)
      // for tooltips on dots
      .style('pointer-events', 'all')
      .on('mouseover', (d, i) => {
        const cx =
          this._radiusScale(d.value) *
          Math.cos(this._angleSlice * i - Math.PI / 2);
        const cy =
          this._radiusScale(d.value) *
          Math.sin(this._angleSlice * i - Math.PI / 2);
        const newX = cx - 10;
        const newY = cy - 10;

        tooltip
          .attr('x', newX)
          .attr('y', newY)
          .text(`${d.title}: ${d.valueRaw} (${d.value}%)`)
          .transition()
          .duration(200)
          .style('opacity', 1);
      })
      .on('mouseout', () => {
        tooltip.transition().duration(200).style('opacity', 0);
      });

    //Set up the small tooltip for when you hover over a circle
    const tooltip = this._g
      .append('text')
      .style('font-size', this.cfg.path.tooltipSize)
      .style('color', this.cfg.path.tooltipColor)
      .style('opacity', 0);
  }

  toggleGradeVisibility(isVisible: boolean): void {
    if (isVisible) {
      this.drawGrades();
    } else {
      this._g.selectAll('.radar-wrapper').remove();
    }
  }
}
