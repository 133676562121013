import { Component, OnInit } from '@angular/core';
import { AssessmentResponse } from '@models/reviews/assessment-response';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../services/auth/auth.service';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { AssessmentResponseHttpService } from '@services/http/AssessmentResponseHttpService';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { AsyncList } from '@rest/AsyncList';
import { Domain } from '@models/ontology/domain';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Speciality } from '@models/specialities/speciality';
import { StaticTextField } from '@components/common/static-textfield-group/static-textfield-group.component';
import { Thing } from '@models/ontology/thing';
import { Resolution } from '@models/reviews/resolution';
import { ResolutionHttpService } from '@services/http/ResolutionHttpService';
import { combineLatest } from 'rxjs';
import { Review } from '@models/reviews/review';
import { ResolutionResponse } from '@models/reviews/resolution-response';
import { ResolutionResponseHttpService } from '@services/http/ResolutionResponseHttpService';
import { AlertService } from '../../../../services/ui/ui-alert.service';
import { get_full_parent_path } from '../../../../utils/getFullParentUrl';
import { TitleService } from 'src/app/services/title.service';
import { ReviewMode } from '@components/common/domain-thing-tree/domain-thing-tree.component';
import { ReviewHttpService } from '@services/http/ReviewHttpService';
import { BlockTextHelpDialogComponent } from '@components/common/block-text-help-dialog/block-text-help-dialog.component';
import { MatDialog } from '@angular/material/dialog';

interface AssessmentResponseThingMap {
  [thingUuid: string]: AssessmentResponse[];
}

export interface ResolutionResponseThingMap {
  [thingUuid: string]: ResolutionResponse;
}

@Component({
  selector: 'app-new-review-resolution-detail',
  templateUrl: './new-review-resolution-detail.component.html',
  styleUrls: ['./new-review-resolution-detail.component.css'],
})
export class NewReviewResolutionDetailComponent implements OnInit {
  resolution: AsyncDetail<Resolution>;
  assessmentResponses: AsyncList<AssessmentResponse>;
  resolutionResponses: AsyncList<ResolutionResponse>;
  domains: AsyncList<Domain>;
  speciality: AsyncDetail<Speciality>;

  resolutionInfoData: StaticTextField[];

  assessmentResponseThingMap: AssessmentResponseThingMap = {};
  resolutionResponseThingMap: ResolutionResponseThingMap = {};

  things: Thing[] = [];

  review: Review;

  isCloseResolutionProcessing = false;

  isResolutionCompleted = false;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _alertService: AlertService,
    private _activatedRoute: ActivatedRoute,
    private _specialityHttpService: SpecialityHttpService,
    private _resolutionHttpService: ResolutionHttpService,
    private _assessmentResponseHttpService: AssessmentResponseHttpService,
    private _resolutionResponseHttpService: ResolutionResponseHttpService,
    private _domainHttpService: DomainHttpService,
    private _titleService: TitleService,
    private _reviewHttpService: ReviewHttpService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this._titleService.set_title('Ревью');
    this.domains = new AsyncList<Domain>(this._domainHttpService);

    this.resolution = new AsyncDetail<Resolution>(
      this._activatedRoute.snapshot.paramMap.get('resUuid'),
      this._resolutionHttpService
    );

    this.resolution.requestParams = {
      params: {
        expand: 'review,subject_user',
      },
    };

    combineLatest([this.resolution.load(), this.domains.load()]).subscribe(
      () => {
        this.review = this.resolution.state.item.review as Review;
        this._titleService.set_title(this.review.name);
        this._getReviewInfoData();
        this._loadAssessmentResponses();
        this._loadResolutionResponses();
      }
    );
  }

  private _loadAssessmentResponses(): void {
    this.assessmentResponses = new AsyncList<AssessmentResponse>(
      this._assessmentResponseHttpService
    );
    this.assessmentResponses.setRequestParams({
      params: {
        assessment__review: this.review.uuid,
        expand:
          'thing.levels,competence_assertion.thing_level,assessment.reviewer_user',
      },
    });
    this.assessmentResponses.load().subscribe(() => {
      this.assessmentResponses.state.items.forEach((assessmentResponse) => {
        if (this.assessmentResponseThingMap[assessmentResponse.thing.uuid]) {
          this.assessmentResponseThingMap[assessmentResponse.thing.uuid].push(
            assessmentResponse
          );
        } else {
          this.assessmentResponseThingMap[assessmentResponse.thing.uuid] = [
            assessmentResponse,
          ];
          this.things.push(assessmentResponse.thing);
        }
      });
    });
  }

  private _loadResolutionResponses(): void {
    this.resolutionResponses = new AsyncList<ResolutionResponse>(
      this._resolutionResponseHttpService
    );
    this.resolutionResponses.setRequestParams({
      params: {
        resolution: this.resolution.state.item.uuid,
        expand: 'thing.levels,resolved_thing_level',
      },
    });
    this.resolutionResponses.load().subscribe(() => {
      this.resolutionResponses.state.items.forEach((resolutionResponse) => {
        this.resolutionResponseThingMap[resolutionResponse.thing.uuid] =
          resolutionResponse;
      });
    });
  }

  private _getReviewInfoData(): void {
    const specialityUuid = Object.values(
      this.review.config?.subject_speciality_map
    )[0][0];

    this.speciality = new AsyncDetail<Speciality>(
      specialityUuid,
      this._specialityHttpService
    );
    this.speciality.load().subscribe(
      () => {
        this.resolutionInfoData = [
          { name: 'Специальность', text: this.speciality.state.item.name },
          {
            name: 'Срок ревью',
            text: this.getPrettyReviewDate(this.review),
          },
          {
            name: 'Ревьюируемый',
            text: `${this.resolution.state.item.subject_user.last_name} ${this.resolution.state.item.subject_user.first_name}`,
          },
        ];
      },
      () => {
        this.resolutionInfoData = [
          { name: 'Специальность', text: 'Удалена' },
          {
            name: 'Срок ревью',
            text: this.getPrettyReviewDate(
              this.resolution.state.item.review as Review
            ),
          },
          {
            name: 'Ревьюируемый',
            text: `${this.resolution.state.item.subject_user.last_name} ${this.resolution.state.item.subject_user.first_name}`,
          },
        ];
      }
    );
  }

  get reviewMode(): ReviewMode {
    if (this.resolution.state.item.completion_percentage === 100) {
      return 'result';
    }
    return 'resolution';
  }

  getPrettyReviewDate(review: Review): string {
    if (review.deadline_start && review.deadline_end) {
      const dateFrom = new Date(review.deadline_start),
        dateTo = new Date(review.deadline_end);
      return `${dateFrom.getDate()} ${dateFrom.toLocaleString('default', {
        month: 'short',
      })} - ${dateTo.getDate()} ${dateTo.toLocaleString('default', {
        month: 'short',
      })}`;
    }
    return '';
  }

  onBackClick(): void {
    history.back();
  }

  closeResolution(): void {
    this.isCloseResolutionProcessing = true;
    this._resolutionHttpService.close(this.resolution.uuid).subscribe(
      (response) => {
        const subjectUser = this.resolution.state.item.subject_user;
        this._alertService.success(
          `Финализация для ${subjectUser.first_name} ${subjectUser.last_name} успешно закрыта`
        );
        this.isCloseResolutionProcessing = false;
        this.onBackClick();
      },
      () => {
        this._alertService.error('Не удалось закрыть финализацию');
        this.isCloseResolutionProcessing = false;
      }
    );
  }

  onResolutionChange(): void {
    this.resolution.load().subscribe();
  }

  getFullParentPath = get_full_parent_path;

  downloadReviewExcel(): void {
    window.open(
      this._reviewHttpService.getDownloadReviewExcelLink(this.review.uuid)
    );
  }

  onWhatThisMetricsDoes(): void {
    this._dialog.open(BlockTextHelpDialogComponent, {
      data: {
        title: 'Что означают Вы и Финальная?',
        textBlocksData: [
          {
            title: 'Оценка "Вы"',
            text: 'Это оценка, которая была проставлена вами.',
            color: 'rgba(130,196,255,0.16)',
          },
          {
            title: 'Оценка "Финальная"',
            text: 'Это оценка, которая была проставлена руководителем. Эта оценка считается финальной, так как она отображается в профиле у сотрудника. ',
            color: 'rgba(155,130,255,0.16)',
          },
        ],
      },
    });
  }
}
