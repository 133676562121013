import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ServiceEmailSettingsHttpService } from '@services/http/ServiceEmailSettingsHttpService';
import { ServiceEmailSettings } from '@models/settings/service-email-settings';
import { AsyncList } from '@rest/AsyncList';

@Component({
  selector: 'app-create-email-service-settings',
  templateUrl: './create-email-service-settings.component.html',
  styleUrls: ['./create-email-service-settings.component.css'],
})
export class CreateEmailServiceSettingsComponent implements OnInit {
  form: UntypedFormGroup;
  serviceEmailSettings: AsyncList<ServiceEmailSettings>;

  constructor(
    private _serviceEmailSettingsHttpService: ServiceEmailSettingsHttpService,
    private _formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreateEmailServiceSettingsComponent>
  ) {}

  ngOnInit(): void {
    this.serviceEmailSettings = new AsyncList<ServiceEmailSettings>(
      this._serviceEmailSettingsHttpService
    );

    this.form = this._formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      host: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],

      port: [
        '',
        [Validators.required, Validators.minLength(1), Validators.maxLength(5)],
      ],

      default_from_email: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
          Validators.email,
        ],
      ],

      default_from_name: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],

      host_user: ['', [Validators.minLength(1), Validators.maxLength(128)]],

      host_password: ['', [Validators.minLength(1), Validators.maxLength(128)]],

      host_secure: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
    });
  }

  onSubmitForm(): void {
    this.serviceEmailSettings.create(this.form.value);
    this.dialogRef.close();
  }
}
