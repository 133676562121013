import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TeamManageThingLevelsComponent } from 'src/app/modules/teams/components/team-manage/thing-level-templates/thing-level-templates.component';

import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { AdminMainComponent } from './components/admin-main/admin-main.component';

import { UsersDashboardComponent } from './components/users/users-dashboard/users-dashboard.component';
import { UserMainComponent } from './components/users/user-main/user-main/user-main.component';

import { TeamsDashboardComponent } from './components/teams/teams-dashboard/teams-dashboard.component';

import { TeamDetailsComponent } from './components/teams/team-details/team-details.component';

import { SpecialitiesDashboardComponent } from './components/specialities/specialities-dashboard/specialities-dashboard.component';
import { SpecialityCreateComponent } from './components/specialities/speciality-create/speciality-create.component';
import { SpecialityDetailsComponent } from './components/specialities/speciality-details/speciality-details.component';

import { OntologyDashboardComponent } from './components/ontology/ontology-dashboard/ontology-dashboard.component';
import { DomainCreateComponent } from './components/ontology/domains/domain-create/domain-create.component';
import { DomainDetailsComponent } from './components/ontology/domains/domain-details/domain-details.component';
import { ThingDetailsComponent } from './components/ontology/things/thing-details/thing-details.component';

import { SurveysDashboardComponent } from './components/surveys/surveys-dashboard/surveys-dashboard.component';
import { SurveyCreateComponent } from './components/surveys/survey-create/survey-create.component';
import { SurveyDetailsComponent } from './components/surveys/survey-details/survey-details.component';
import { SurveyEditComponent } from './components/surveys/survey-edit/survey-edit.component';
import { SurveyEditAnswerComponent } from './components/surveys/survey-edit-answer/survey-edit-answer.component';

import { StatisticsDashboardComponent } from './components/statistics/statistics-dashboard/statistics-dashboard.component';
import { DebuggingDashboardComponent } from './components/system/debugging-dashboard/debugging-dashboard.component';
import { ReleasenotesComponent } from './components/system/releasenotes/releasenotes.component';
import { AdminSettingsComponent } from './components/admin-settings/admin-settings.component';
import { DirectionsDashboardComponent } from './components/directions/directions-dashboard/directions-dashboard.component';
import { DirectionDetailsComponent } from './components/directions/direction-details/direction-details.component';

const children_routes: Routes = [
  { path: 'main', component: AdminMainComponent },
  { path: 'users', component: UsersDashboardComponent },
  { path: 'users/:user_uuid', component: UserMainComponent },

  { path: 'teams', component: TeamsDashboardComponent },
  { path: 'teams/:team_uuid', component: TeamDetailsComponent },

  { path: 'specialities', component: SpecialitiesDashboardComponent },
  { path: 'specialities/create', component: SpecialityCreateComponent },
  {
    path: 'specialities/speciality/:speciality_uuid',
    component: SpecialityDetailsComponent,
  },
  {
    path: 'directions',
    component: DirectionsDashboardComponent,
  },
  {
    path: 'directions/:uuid',
    component: DirectionDetailsComponent,
  },
  { path: 'ontologies', component: OntologyDashboardComponent },
  { path: 'ontologies/domains/create', component: DomainCreateComponent },
  {
    path: 'ontologies/domains/:domain_uuid',
    component: DomainDetailsComponent,
  },
  { path: 'ontologies/things/:thing_uuid', component: ThingDetailsComponent },

  { path: 'surveys', component: SurveysDashboardComponent },
  { path: 'surveys/create', component: SurveyCreateComponent },
  { path: 'surveys/survey/:survey_uuid', component: SurveyDetailsComponent },
  { path: 'surveys/survey/:survey_uuid/edit', component: SurveyEditComponent },
  {
    path: 'surveys/question_edit/:question_uuid/:survey_uuid',
    component: SurveyEditAnswerComponent,
  },

  { path: 'system/stats', component: StatisticsDashboardComponent },
  { path: 'system/debug', component: DebuggingDashboardComponent },
  { path: 'system/releasenotes', component: ReleasenotesComponent },
  { path: 'system/settings', component: AdminSettingsComponent },

  { path: 'thing_level_template', component: TeamManageThingLevelsComponent },
];

const routes: Routes = [
  { path: '', component: AdminDashboardComponent, children: children_routes },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
