import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AsyncList } from '@rest/AsyncList';
import { Direction } from '@models/directions/direction';
import { Team } from '@models/teams/team';
import { Speciality } from '@models/specialities/speciality';
import { DirectionHttpService } from '@services/http/DirectionHttpService';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { AuthService } from '../../../../../services/auth/auth.service';
import { combineLatest, Subject } from 'rxjs';
import { TeamMembership } from '@models/teams/team-membership';
import { TeamMembershipHttpService } from '@services/http/TeamMembershipHttpService';
import { SpecialityUserRelation } from '@models/specialities/speciality-user-relation';
import { SpecialityUserRelationHttpService } from '@services/http/SpecialityUserRelationHttpService';
import { SpecialityGrade } from '@models/specialities/speciality-grade';
import { TeamMemberStatus } from '@models/teams/team-member-status';
import { TeamMemberStatusHttpService } from '@services/http/TeamMemberStatusHttpService';
import { DevelopmentPlan } from '@models/tracks/development-plan';
import { DevelopmentPlanHttpService } from '@services/http/DevelopmentPlanHttpService';
import { SpecialityCompetenceClaim } from '@models/specialities/speciality-competence-claim';
import { SpecialityCompetenceClaimHttpService } from '@services/http/SpecialityCompetenceClaimHttpService';
import { Competence } from '@models/competencies/competence';
import { CompetenceHttpService } from '@services/http/CompetenceHttpService';
import { ThingLevel } from '@models/ontology/thing-level';
import { Thing } from '@models/ontology/thing';
import { CompetenceDesireHttpService } from '@services/http/CompetenceDesireHttpService';
import { CompetenceDesire } from '@models/competencies/competence-desire';
import { PieChartItem } from '@components/ui/chart-pie/chart-pie.component';

interface TeamMembersSpecialityGradeMapValue {
  gradeName: string;
  gradeOrder: number;
  specialityGradeCount: number;
}

interface TeamMembersSpecialityGradeMap {
  [gradeOrderNumber: string]: TeamMembersSpecialityGradeMapValue;
}

interface ThingCount {
  name: string;
  count: number;
  uuid: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  mainForm: UntypedFormGroup;
  gradeForm: UntypedFormGroup;

  directions: AsyncList<Direction>;
  specialities: Speciality[] = [];
  teamMemberships: AsyncList<TeamMembership>;
  teamMembersStatus: AsyncList<TeamMemberStatus>;
  specialityRelations: AsyncList<SpecialityUserRelation>;
  teamSpecialityPdp: AsyncList<DevelopmentPlan>;

  selectedDirection: Direction;

  teams: Team[] = [];
  teamSpecialities: Speciality[] = [];
  teamSpecialityRelations: SpecialityUserRelation[] = [];
  teamUserCompetencies: Competence[];
  specialitiesCompetenceClaims: AsyncList<SpecialityCompetenceClaim>;
  selectedSpecialityGrades: SpecialityGrade[];

  usersDesires: CompetenceDesire[];

  specialityDesiresCount: ThingCount[];
  expectedThingsCount: ThingCount[];
  unfitCompetenciesCount: ThingCount[];

  teamMembersSpecialityGradeMap: TeamMembersSpecialityGradeMap;

  teamSpecialityKeyPeopleCount = 0;
  teamSpecialityBusFactorCount = 0;
  teamSpecialityFitUsersCount = '0';

  specialityDesiresDataLoading = false;
  competenciesFitnessDataLoading = false;

  isDataLoadedSubject = new Subject();
  isDataLoadedMap = {
    gradeDataReady: {
      isLoaded: false,
      loadingText: 'Загружаем уровни..',
    },
    teamStatusDataReady: {
      isLoaded: false,
      loadingText: 'Загружаем статусы команд..',
    },
    competenceClaimsLoaded: {
      isLoaded: false,
      loadingText: 'Загружаем компетенции..',
    },
    specialityDesiresDataReady: {
      isLoaded: false,
      loadingText: 'Загружаем запросы на обучение..',
    },
    competenciesFitnessDataReady: {
      isLoaded: false,
      loadingText: 'Проверяем соответствие навыков..',
    },
  };

  isAllExpectedThingsVisible = false;
  isAllSpecialityDesiresVisible = false;
  isAllCompetenciesFitnessVisible = false;

  childSpecialities: Speciality[] = [];
  specialitiesUsers: string[] = [];
  specialityUsersMap: {
    [specialityUuid: string]: string[];
  } = {};
  specialitiesUsersCompetencies: AsyncList<Competence>;
  selectedTeam: Team;
  selectedSpeciality: Speciality;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _authService: AuthService,
    private _directionHttpService: DirectionHttpService,
    private _specialityHttpService: SpecialityHttpService,
    private _teamMembershipHttpService: TeamMembershipHttpService,
    private _specialityUserRelationHttpService: SpecialityUserRelationHttpService,
    private _specialityCompetenceClaimHttpService: SpecialityCompetenceClaimHttpService,
    private _teamMemberStatusHttpService: TeamMemberStatusHttpService,
    private _developmentPlanHttpService: DevelopmentPlanHttpService,
    private _competenceHttpService: CompetenceHttpService,
    private _competenceDesireHttpService: CompetenceDesireHttpService
  ) {
    this.mainForm = this._formBuilder.group({
      direction: ['', []],
      team: ['', []],
      speciality: ['', []],
    });

    this.gradeForm = this._formBuilder.group({
      grade: ['', []],
    });
  }

  ngOnInit(): void {
    this.isDataLoadedSubject.next(this.isDataLoadedMap);

    this.directions = new AsyncList<Direction>(this._directionHttpService);
    this.directions.setRequestParams({
      params: {
        admins: this._authService.get_current_user_uuid(),
        expand: 'teams,specialities.related_grades',
      },
    });
    this.directions.load().subscribe(() => {});
  }

  private _updateIsDataLoadedSubject(): void {
    this.isDataLoadedSubject.next(this.isDataLoadedMap);
  }

  onChangeSelectedDirection(direction: Direction): void {
    this.selectedDirection = direction;
    this.selectedSpeciality = null;
    this.selectedTeam = null;
    this._resetForm();
    this._setDirectionData();
    this._resetData();
  }

  private _resetForm(): void {
    this.mainForm.controls.team.setValue('');
    this.mainForm.controls.speciality.setValue('');
  }

  private _setDirectionData(): void {
    this.teams = this.selectedDirection.teams as Team[];
    this.specialities = this.selectedDirection.specialities as Speciality[];
  }

  onChangeSelectedTeam(team: Team): void {
    if (!team) {
      if (!this.selectedSpeciality) {
        this.onChangeSelectedDirection(this.selectedDirection);
      } else {
        if (this.selectedSpeciality.team === this.selectedTeam.uuid) {
          this.onChangeSelectedDirection(this.selectedDirection);
        } else {
          this.onChangeSelectedSpeciality(this.selectedSpeciality);
        }
      }
      return;
    }
    this.selectedTeam = team;

    this._directionHttpService
      .getDirectionTeamSpecialities(
        this.selectedDirection.uuid,
        this.selectedTeam.uuid
      )
      .subscribe((response) => {
        if (
          this.selectedSpeciality &&
          !this.selectedSpeciality.library_speciality
        ) {
          this._buildData(
            response.find(
              (speciality) =>
                speciality.library_speciality === this.selectedSpeciality.uuid
            ).uuid
          );
        } else {
          this.specialities = response;
        }
      });
  }

  onChangeSelectedSpeciality(speciality: Speciality): void {
    this.selectedSpeciality = speciality;
    if (!this.selectedSpeciality) {
      this._resetData();
      return;
    }
    this.selectedSpecialityGrades = this.selectedSpeciality.related_grades;
    this.gradeForm.controls.grade.setValue(this.selectedSpecialityGrades[0]);

    if (!this.mainForm.controls.team.value) {
      this._specialityHttpService
        .getChildSpecialities(speciality.uuid)
        .subscribe((response) => {
          this.childSpecialities = response;
          this.teams = (this.selectedDirection.teams as Team[]).filter((team) =>
            this.childSpecialities.map((s) => s.team).includes(team.uuid)
          );
          const specialitiesStr = this.childSpecialities
            .map((s) => s.uuid)
            .join(',');
          this._buildData(specialitiesStr);
        });
    } else {
      this.childSpecialities = [speciality];
      this._buildData(speciality.uuid);
    }
  }

  private _resetData(): void {
    this.teamSpecialityRelations = [];
    this.teamMembersSpecialityGradeMap = {};
    this.usersDesires = [];
    this.teamSpecialityBusFactorCount = 0;
    this.teamSpecialityKeyPeopleCount = 0;
    this.teamSpecialityFitUsersCount = '0';
    this.isDataLoadedMap.gradeDataReady.isLoaded = false;
    this.isDataLoadedMap.teamStatusDataReady.isLoaded = false;
    this.isDataLoadedMap.specialityDesiresDataReady.isLoaded = false;
    this.isDataLoadedMap.competenciesFitnessDataReady.isLoaded = false;
    this.isDataLoadedMap.competenceClaimsLoaded.isLoaded = false;
  }

  private _buildData(specialitiesStr: string): void {
    this._resetData();
    this._updateIsDataLoadedSubject();

    this.specialityDesiresDataLoading = true;
    this.competenciesFitnessDataLoading = true;

    this.specialityRelations = new AsyncList<SpecialityUserRelation>(
      this._specialityUserRelationHttpService
    );
    this.specialityRelations.setRequestParams({
      params: {
        speciality__in: specialitiesStr,
        expand: 'grade',
      },
    });
    this.specialitiesCompetenceClaims =
      new AsyncList<SpecialityCompetenceClaim>(
        this._specialityCompetenceClaimHttpService
      );
    this.specialitiesCompetenceClaims.setRequestParams({
      params: {
        expand: 'thing,thing_level,grade',
        speciality__in: specialitiesStr,
      },
    });
    combineLatest([
      this.specialitiesCompetenceClaims.load(),
      this.specialityRelations.load(),
    ]).subscribe(() => {
      this.childSpecialities.forEach((s) => {
        this.specialityUsersMap[s.uuid] = this.specialityRelations.state.items
          .filter((relation) => relation.speciality === s.uuid)
          .map((relation) => relation.user as string);
      });
      this.specialityRelations.state.items.forEach((relation) => {
        const user = relation.user as string;
        if (!this.specialitiesUsers.includes(user)) {
          this.specialitiesUsers.push(user);
        }
      });
      this.specialitiesUsersCompetencies = new AsyncList(
        this._competenceHttpService
      );
      this.specialitiesUsersCompetencies.setRequestParams({
        params: {
          user__in: this.specialitiesUsers.join(','),
          expand: 'thing_level',
        },
      });

      this._setSpecialityGradeData();
      this._setTeamsStatusData();
      this._setSpecialitiesPdpData();

      this.specialitiesUsersCompetencies.load().subscribe(() => {
        this._setSpecialitiesCompetenceData();
        this._setSpecialityCompetenciesFitnessData();
      });
      this._competenceDesireHttpService
        .getUsersDesires(this.specialitiesUsers)
        .subscribe((r) => {
          this.usersDesires = r.results;
          this._setSpecialityDesiresData();
        });
    });
  }

  private _setSpecialityGradeData(): void {
    this.selectedSpecialityGrades.forEach((grade) => {
      this.teamMembersSpecialityGradeMap[grade.order_number] = {
        gradeName: grade.name,
        gradeOrder: grade.order_number,
        specialityGradeCount: 0,
      };
    });

    this.specialityRelations.state.items.forEach((relation) => {
      const specialityGrade = relation.grade as SpecialityGrade;
      if (specialityGrade) {
        if (
          Object.keys(this.teamMembersSpecialityGradeMap).includes(
            String(specialityGrade.order_number)
          )
        ) {
          this.teamMembersSpecialityGradeMap[
            specialityGrade.order_number
          ].specialityGradeCount += 1;
        }
      }
    });
    this.isDataLoadedMap.gradeDataReady.isLoaded = true;
    this._updateIsDataLoadedSubject();
  }

  private _setTeamsStatusData(): void {
    const teams = (this.selectedDirection.teams as Team[]).filter((team) =>
      this.childSpecialities
        .map((speciality) => speciality.team)
        .includes(team.uuid)
    );
    this.teamMembersStatus = new AsyncList<TeamMemberStatus>(
      this._teamMemberStatusHttpService
    );
    this.teamMembersStatus.setRequestParams({
      params: {
        team__in: teams.map((team) => team.uuid).join(','),
      },
    });
    this.teamMembersStatus.load().subscribe(() => {
      this.teamMembersStatus.state.items.forEach((status) => {
        if (
          this.specialityRelations.state.items
            .map((relation) => relation.user)
            .includes(status.member)
        ) {
          this.teamSpecialityKeyPeopleCount += status.key_people ? 1 : 0;
          this.teamSpecialityBusFactorCount += status.bus_factor ? 1 : 0;
        }
      });
      this.isDataLoadedMap.teamStatusDataReady.isLoaded = true;
      this._updateIsDataLoadedSubject();
    });
  }

  private _setSpecialitiesPdpData(): void {
    this.teamSpecialityPdp = new AsyncList<DevelopmentPlan>(
      this._developmentPlanHttpService
    );
    this.teamSpecialityPdp.setRequestParams({
      params: {
        subject_user__in: this.specialityRelations.state.items
          .map((relation) => relation.user)
          .join(','),
      },
    });
    this.teamSpecialityPdp.load();
  }

  private _setSpecialitiesCompetenceData(grade?: SpecialityGrade): void {
    this.expectedThingsCount = [];
    const filteredSpecialityCompetenceClaims =
      this.specialitiesCompetenceClaims.state.items.filter(
        (competenceClaim) =>
          competenceClaim.grade.order_number ===
          (grade ? grade.order_number : 0)
      );
    filteredSpecialityCompetenceClaims.forEach((competenceClaim) => {
      const thing = competenceClaim.thing as Thing;
      const expectedCount =
        this.specialitiesUsersCompetencies.state.items.filter(
          (competence) =>
            this.specialityUsersMap[competenceClaim.speciality].includes(
              competence.user as string
            ) &&
            thing.uuid === competence.thing &&
            (competence.thing_level as ThingLevel).order_number >=
              (competenceClaim.thing_level as ThingLevel).order_number
        ).length;
      if (
        !this.expectedThingsCount
          .map((thingCount) => thingCount.uuid)
          .includes(thing.uuid)
      ) {
        this.expectedThingsCount.push({
          uuid: thing.uuid,
          count: expectedCount,
          name: thing.name,
        });
      } else {
        this.expectedThingsCount.find(
          (countTHing) => countTHing.uuid === thing.uuid
        ).count += expectedCount;
      }
    });
    this.expectedThingsCount = this.expectedThingsCount.sort(
      (a, b) => b.count - a.count
    );

    this.isDataLoadedMap.competenceClaimsLoaded.isLoaded = true;
    this._updateIsDataLoadedSubject();
  }

  private _setSpecialityDesiresData(): void {
    const desireThingsCountMap: {
      [thingUuid: string]: ThingCount;
    } = {};

    this.usersDesires.forEach((desire) => {
      const thing = desire.thing as Thing;
      if (
        this.specialitiesCompetenceClaims.state.items.find(
          (competenceClaim) => competenceClaim.thing.uuid === thing.uuid
        )
      ) {
        if (!Object.keys(desireThingsCountMap).includes(thing.uuid)) {
          desireThingsCountMap[thing.uuid] = {
            uuid: thing.uuid,
            name: thing.name,
            count: 1,
          };
        } else {
          desireThingsCountMap[thing.uuid].count += 1;
        }
      }
    });
    this.specialityDesiresCount = Object.values(desireThingsCountMap).sort(
      (a, b) => b.count - a.count
    );

    this.isDataLoadedMap.specialityDesiresDataReady.isLoaded = true;
    this._updateIsDataLoadedSubject();
    this.specialityDesiresDataLoading = false;
  }

  private _setSpecialityCompetenciesFitnessData(): void {
    let nonGradeUserCount = 0,
      fitUsersCount = 0;
    this.unfitCompetenciesCount = [];
    const unfitCompetenciesCountMap: {
      [thingUuid: string]: ThingCount;
    } = {};
    this.specialityRelations.state.items.forEach((relation) => {
      const userGrade = relation.grade as SpecialityGrade;
      let fitUserCompetenciesCount = 0;
      if (userGrade) {
        const filteredSpecialityCompetenceClaims =
          this.specialitiesCompetenceClaims.state.items.filter(
            (competenceClaim) => competenceClaim.grade.uuid === userGrade.uuid
          );

        filteredSpecialityCompetenceClaims.forEach((competenceClaim) => {
          const thing = competenceClaim.thing as Thing;
          const userCompetence =
            this.specialitiesUsersCompetencies.state.items.find(
              (competence) =>
                competence.user === relation.user &&
                competence.thing === thing.uuid
            );

          if (!userCompetence) {
            addUnfitUserCompetence(unfitCompetenciesCountMap, thing);
          } else {
            if (
              (userCompetence.thing_level as ThingLevel).order_number <
              competenceClaim.expected_level
            ) {
              addUnfitUserCompetence(unfitCompetenciesCountMap, thing);
            } else {
              fitUserCompetenciesCount += 1;
            }
          }
        });
        if (
          (fitUserCompetenciesCount /
            filteredSpecialityCompetenceClaims.length) *
            100 >
          95
        ) {
          fitUsersCount += 1;
        }
      } else {
        nonGradeUserCount += 1;
      }

      function addUnfitUserCompetence(
        unfitCompetenciesCountMap,
        thing: Thing
      ): void {
        if (!Object.keys(unfitCompetenciesCountMap).includes(thing.uuid)) {
          unfitCompetenciesCountMap[thing.uuid] = {
            uuid: thing.uuid,
            name: thing.name,
            count: 1,
          };
        } else {
          unfitCompetenciesCountMap[thing.uuid].count += 1;
        }
      }
    });

    this.unfitCompetenciesCount = Object.values(unfitCompetenciesCountMap).sort(
      (a, b) => b.count - a.count
    );

    if (nonGradeUserCount) {
      this.unfitCompetenciesCount.forEach((thing) => {
        thing.count += nonGradeUserCount;
      });
    }

    this.teamSpecialityFitUsersCount =
      (
        (fitUsersCount / this.specialityRelations.state.items.length) *
        100
      ).toFixed(0) + '%';

    this.isDataLoadedMap.competenciesFitnessDataReady.isLoaded = true;
    this._updateIsDataLoadedSubject();
    this.competenciesFitnessDataLoading = false;
  }

  get pieData(): PieChartItem[] {
    return [
      {
        color: '#ffffff',
        value: 100 - parseInt(this.teamSpecialityFitUsersCount, 10),
      },
      {
        color: '#9B82FF',
        value: parseInt(this.teamSpecialityFitUsersCount, 10),
      },
    ];
  }

  get gradeData(): TeamMembersSpecialityGradeMapValue[] {
    return Object.values(this.teamMembersSpecialityGradeMap).sort(
      (a, b) => a.gradeOrder - b.gradeOrder
    );
  }

  onChangeSelectedSpecialityGrade(grade: SpecialityGrade): void {
    this._setSpecialitiesCompetenceData(grade);
  }

  showAllExpectedThings(): void {
    this.isAllExpectedThingsVisible = true;
  }

  showAllSpecialityDesires(): void {
    this.isAllSpecialityDesiresVisible = true;
  }

  showAllCompetenciesFitness(): void {
    this.isAllCompetenciesFitnessVisible = true;
  }

  hideAllExpectedThings(): void {
    this.isAllExpectedThingsVisible = false;
  }

  hideAllSpecialityDesires(): void {
    this.isAllSpecialityDesiresVisible = false;
  }

  hideAllCompetenciesFitness(): void {
    this.isAllCompetenciesFitnessVisible = false;
  }
}
