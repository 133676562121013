import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TeamMembershipHttpService } from '@services/http/TeamMembershipHttpService';
import { AsyncList } from '@rest/AsyncList';
import { TeamMembership } from '@models/teams/team-membership';
import { User } from '@models/user/user';
import { SpecialityValidationHttpService } from '@services/http/SpecialityValidationHttpService';
import { AuthService } from '../../../services/auth/auth.service';
import { UserTeamSpecialityData } from '@components/common/team-join-dialog/team-join-dialog.component';

@Component({
  selector: 'app-create-speciality-validation-dialog',
  templateUrl: './create-speciality-validation-dialog.component.html',
  styleUrls: ['./create-speciality-validation-dialog.component.css'],
})
export class CreateSpecialityValidationDialogComponent implements OnInit {
  form: UntypedFormGroup;

  teamMemberships: AsyncList<TeamMembership>;

  reviewers: User[];

  isDialogReady = false;

  constructor(
    public dialogRef: MatDialogRef<CreateSpecialityValidationDialogComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reviewers: User[];
      validationUuid: string;
      specialityUuid: string;
      teamUuid: string;
      onSubmit(validationUuid: string): void;
    },
    private _authService: AuthService,
    private _teamMembershipHttpService: TeamMembershipHttpService,
    private _specialityValidationHttpService: SpecialityValidationHttpService
  ) {}

  ngOnInit(): void {
    if (this.data.reviewers) {
      this.reviewers = this.data.reviewers;
      this.isDialogReady = true;
    } else {
      // this.teamMemberships = new AsyncList<TeamMembership>(
      //   this._teamMembershipHttpService
      // );
      // this.teamMemberships.setRequestParams({
      //   params: {
      //     team: this.data.teamUuid,
      //     expand: 'user',
      //     page_size: 100,
      //   },
      // });
      // this.teamMemberships.load().subscribe(() => {
      //   this.reviewers = this.teamMemberships.state.items
      //     .filter((membership) => membership.role === 'owner')
      //     .map((membership) => membership.user as User);
      //
      //   this.isDialogReady = true;
      // });
      this.reviewers = [];
      this.isDialogReady = true;
    }
  }

  onSelectedReviewersChange(reviewers: User[]): void {
    this.reviewers = reviewers;
  }

  submit(): void {
    const reviewersUuids = this.reviewers.map((reviewer) => reviewer.uuid);

    if (this.data.validationUuid) {
      this._specialityValidationHttpService
        .updateReviewers(this.data.validationUuid, reviewersUuids)
        .subscribe(() => {
          this.data.onSubmit(this.data.validationUuid);
          this.dialogRef.close(true);
        });
    } else {
      this._specialityValidationHttpService
        .createWithReviewers(
          {
            speciality: this.data.specialityUuid,
            created_by: this._authService.get_current_user_uuid(),
          },
          reviewersUuids
        )
        .subscribe((response) => {
          this.data.onSubmit(response.uuid);
          this.dialogRef.close(true);
        });
    }
  }
}
