import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { LoggingService } from 'src/app/services/logging.service';
import { TitleService } from 'src/app/services/title.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { SpecialityService } from 'src/app/services/specialities/speciality.service';

import { Speciality } from 'src/app/models/specialities/speciality';
import { MatDialog } from '@angular/material/dialog';
import { AcceptDialogComponent } from '../../../../common-ui-elements/components/accept-dialog/accept-dialog.component';

@Component({
  selector: 'app-specialities-dashboard',
  templateUrl: './specialities-dashboard.component.html',
  styleUrls: ['./specialities-dashboard.component.css'],
})
export class SpecialitiesDashboardComponent implements OnInit {
  public specialities: Speciality[];

  private uistate = {
    specialities_loaded: false,
    target_edit_speciality: '',
  };

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private title_service: TitleService,
    private speciality_service: SpecialityService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.title_service.set_title('Специальности');
    this.load_specialities();
  }

  public get is_data_loaded(): boolean {
    return this.uistate.specialities_loaded;
  }

  private load_specialities(): void {
    this.logging_service.debug(
      `${this.constructor.name} loading specialities...`
    );
    this.speciality_service.fetch_all().subscribe(
      (response) => {
        this.specialities = response.results as Speciality[];
        this.specialities.sort((a, b) => a.name.localeCompare(b.name));
        this.uistate.specialities_loaded = true;
        this.logging_service.debug(
          `${this.constructor.name} specialities loaded successfully`
        );
      },
      (err) => {
        this.alert_service.error(
          `Ошибка при загрузке специальностей: ${err.status}`
        );
      }
    );
  }

  get_speciality_edited_in_progress(): string {
    return this.uistate.target_edit_speciality;
  }

  public on_delete_button_click(e, speciality) {
    this._dialog
      .open(AcceptDialogComponent, {
        data: {
          title: `Удалить специальность?`,
          message: 'Вы уверены?',
          acceptLabel: 'Удалить',
          rejectLabel: 'Отмена',
        },
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) {
          this.speciality_service.delete(speciality.uuid).subscribe(
            (response) => {
              this.logging_service.debug(
                `${this.constructor.name} speciality ${speciality.uuid} is deleted`
              );
              this.uistate.specialities_loaded = false;
              this.speciality_service.fetch_all().subscribe(
                (response_on_success_delete) => {
                  this.specialities =
                    response_on_success_delete.results as Speciality[];
                  this.uistate.specialities_loaded = true;
                  this.logging_service.debug(
                    `${this.constructor.name} specialities loaded successfully`
                  );
                  this.alert_service.error(`Специальность удалена`);
                },
                (err) => {
                  this.alert_service.error(
                    `Ошибка при загрузке специальностей: ${err.status}`
                  );
                }
              );
            },
            (err) => {
              this.logging_service.debug(
                `${this.constructor.name} failed to delete speciality ${speciality.uuid}`
              );
              this.alert_service.error(`Ошибка удаления специальности`);
            }
          );
        }
      });
  }

  on_edit_button_click(speciality: Speciality) {
    this.uistate.target_edit_speciality = speciality.uuid;
  }

  reset_edit_speciality() {
    this.uistate.target_edit_speciality = '';
  }

  save_new_speciality_name(speciality: Speciality, new_name: string) {
    this.speciality_service
      .update(speciality.uuid, { name: new_name })
      .subscribe(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} save new name for ${speciality.uuid}`
          );
          this.specialities.find((t) => t.uuid === response.uuid).name =
            response.name;
          this.alert_service.error(`Сохранено`);
          this.reset_edit_speciality();
        },
        (err) => {
          this.logging_service.debug(
            `${this.constructor.name} failed save new name for ${speciality.uuid}`
          );
          this.alert_service.error(`Ошибка изменения сохранения ${err.status}`);
          this.reset_edit_speciality();
        }
      );
  }
}
