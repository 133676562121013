<app-with-processing [processing]='firstThing.state.isProcessing'></app-with-processing>
<div *ngIf='firstThing.state.isLoaded' class='container'>
  <div fxLayout='row'>
    <div fxFlex fxLayout='row' class='title'>
      <div fxFlex fxLayoutAlign='start center' fxLayoutGap='2rem'>
        <button (click)='onBackClick()' mat-icon-button>
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div fxLayoutAlign='start center' fxLayout='row'>
          Объединение дублей
        </div>
      </div>
    </div>
  </div>
  <div fxLayout='row' class='things' fxLayoutGap='16px'>
    <div fxFlex fxLayout='column' class='thing-container'>
      <div fxFlex fxLayout='column' class='thing-header-container'>
        <div class='thing-name-container' fxLayout='row' fxLayoutAlign='start center'>
        <span fxFlex class='thing-name'>
          {{ firstThing.state.item.name }}
        </span>
        </div>
        <div class='thing-desc'>
          {{ firstThing.state.item.description }}
        </div>
        <mat-divider class='small-divider'></mat-divider>
        <div class='domain-container'>Компетенция: <span
          class='domain-name'>{{ getDomainName(firstThing.state.item) }}</span>
        </div>
      </div>
      <div fxLayout='column' fxLayoutGap='8px' fxFlex>
        <div fxLayout='row' fxLayoutGap='8px' fxFlex>
          <div class='thing-info-container' fxFlex>
            <div class='thing-info-value'>{{ firstThingRelations?.teams }}</div>
            <div class='thing-info-desc'>Используют в командах</div>
          </div>
          <div class='thing-info-container' fxFlex>
            <div class='thing-info-value'>{{ firstThingRelations?.users }}</div>
            <div class='thing-info-desc'>Владеют навыком</div>
          </div>
          <div class='thing-info-container' fxFlex>
            <div class='thing-info-value'>{{ firstThingRelations?.reviews }}</div>
            <div class='thing-info-desc'>Участвует в ревью</div>
          </div>
        </div>
        <div fxLayout='row' fxLayoutGap='8px' fxFlex>
          <div class='thing-info-container' fxFlex>
            <div class='thing-info-value'>{{ firstThingRelations?.specialities }}</div>
            <div class='thing-info-desc'>Специальности</div>
          </div>
          <div class='thing-info-container' fxFlex>
            <div class='thing-info-value'>{{ firstThingRelations?.development_plans }}</div>
            <div class='thing-info-desc'>План развития</div>
          </div>
          <div class='thing-info-container' fxFlex>
            <div class='thing-info-value'>{{ firstThingRelations?.desires }}</div>
            <div class='thing-info-desc'>Запросы на обучение</div>
          </div>
        </div>
      </div>
      <mat-divider class='divider'></mat-divider>
      <div class='thing-levels-header'>Уровни Владения</div>
      <div fxLayout='column' fxLayoutGap='8px' fxFlex>
        <div class='thing-level-container' *ngFor='let level of firstThing.state.item.levels'>
          <div class='thing-level-name'>{{ level.title }}</div>
          <div class='thing-level-desc'>{{ level.description }}</div>
        </div>
      </div>
    </div>
    <div fxFlex fxLayout='column' class='thing-container'>
      <div fxLayout='column' class='thing-header-container'>
        <ng-select
          class='thing-input-field'
          [multiple]='false'
          placeholder='Выберите навык'
          (search)='onSearchThings($event.term)'
          (change)='onSelectSecondThing($event)'
          [closeOnSelect]='true'
          dropdownPosition='bottom'
        >
          <ng-option
            class='thing-option'
            *ngFor='let thing of resultThings'
            [value]='thing'
          >
            <div>{{ thing.name }}</div>
          </ng-option>
        </ng-select>
        <div fxLayout='column' *ngIf='secondThing'>
          <div class='thing-desc'>
            {{ secondThing?.description }}
          </div>
          <mat-divider class='small-divider'></mat-divider>
          <div class='domain-container'>Компетенция: <span class='domain-name'>{{ getDomainName(secondThing) }}</span>
          </div>
        </div>
      </div>
      <div fxFlex fxLayout='column' *ngIf='secondThing'>
        <div fxLayout='column' fxLayoutGap='8px' fxFlex>
          <div fxLayout='row' fxLayoutGap='8px' fxFlex>
            <div class='thing-info-container' fxFlex>
              <div class='thing-info-value'>{{ secondThingRelations?.teams }}</div>
              <div class='thing-info-desc'>Используют в командах</div>
            </div>
            <div class='thing-info-container' fxFlex>
              <div class='thing-info-value'>{{ secondThingRelations?.users }}</div>
              <div class='thing-info-desc'>Владеют навыком</div>
            </div>
            <div class='thing-info-container' fxFlex>
              <div class='thing-info-value'>{{ secondThingRelations?.reviews }}</div>
              <div class='thing-info-desc'>Участвует в ревью</div>
            </div>
          </div>
          <div fxLayout='row' fxLayoutGap='8px' fxFlex>
            <div class='thing-info-container' fxFlex>
              <div class='thing-info-value'>{{ secondThingRelations?.specialities }}</div>
              <div class='thing-info-desc'>Специальности</div>
            </div>
            <div class='thing-info-container' fxFlex>
              <div class='thing-info-value'>{{ secondThingRelations?.development_plans }}</div>
              <div class='thing-info-desc'>План развития</div>
            </div>
            <div class='thing-info-container' fxFlex>
              <div class='thing-info-value'>{{ secondThingRelations?.desires }}</div>
              <div class='thing-info-desc'>Запросы на обучение</div>
            </div>
          </div>
        </div>
        <mat-divider class='divider'></mat-divider>
        <div class='thing-levels-header'>Уровни Владения</div>
        <div fxLayout='column' fxLayoutGap='8px' fxFlex>
          <div class='thing-level-container' *ngFor='let level of secondThing.levels'>
            <div class='thing-level-name'>{{ level.title }}</div>
            <div class='thing-level-desc'>{{ level.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <form [formGroup]='form' class='squash-form-container'>
      <div fxLayout='row' fxLayoutGap='16px' fxLayoutAlign='start center' fxFlex>
        <div fxLayout='column' fxFlex>
          <mat-label class='squash-form-label'>Название</mat-label>
          <mat-form-field appearance='outline'>
            <mat-select
              formControlName='name'
              placeholder='Выберите название'
            >
              <mat-option [value]='firstThing.state.item.name'>
                <mat-icon>keyboard_arrow_right</mat-icon>
                {{ firstThing.state.item.name }}
              </mat-option>
              <mat-option *ngIf='secondThing' [value]='secondThing.name'>
                <mat-icon>keyboard_arrow_left</mat-icon>
                {{ secondThing.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout='column' fxFlex>
          <mat-label class='squash-form-label'>Описание</mat-label>
          <mat-form-field appearance='outline'>
            <mat-select
              placeholder='Выберите описание'
              formControlName='description'
            >
              <mat-option [value]='firstThing.state.item.description'>
                <mat-icon>keyboard_arrow_right</mat-icon>
                {{ firstThing.state.item.description }}
              </mat-option>
              <mat-option *ngIf='secondThing' [value]='secondThing.description'>
                <mat-icon>keyboard_arrow_left</mat-icon>
                {{ secondThing.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout='column' fxFlex>
          <mat-label class='squash-form-label'>Домен</mat-label>
          <mat-form-field appearance='outline'>
            <mat-select
              placeholder='Выберите домен'
              formControlName='domain'
            >
              <mat-option [value]='getDomainUuid(firstThing.state.item)'>
                <mat-icon>keyboard_arrow_right</mat-icon>
                {{ getDomainName(firstThing.state.item) }}
              </mat-option>
              <mat-option *ngIf='secondThing' [value]='getDomainUuid(secondThing)'>
                <mat-icon>keyboard_arrow_left</mat-icon>
                {{ getDomainName(secondThing) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout='column' fxFlex>
          <mat-label class='squash-form-label'>Уровни владения</mat-label>
          <mat-form-field appearance='outline'>
            <mat-select
              placeholder='Выберите уровни владения'
              formControlName='change_levels'
            >
              <mat-option [value]='false'>
                <mat-icon>keyboard_arrow_right</mat-icon>
                Уровни владения {{ firstThing.state.item.name }}
              </mat-option>
              <mat-option *ngIf='secondThing' [value]='true' [disabled]='!secondThing.levels.length'>
                <mat-icon>keyboard_arrow_left</mat-icon>
                Уровни владения {{ secondThing.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <mat-divider class='divider'></mat-divider>
  <div fxLayout='row' fxLayoutAlign='end center'>
    <button mat-raised-button class='squash-button' color='primary' [disabled]='!secondThing'
            (click)='squashThings()'>
      Объединить дубли
    </button>
  </div>
</div>

