import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CreateEmailServiceSettingsComponent } from './create-email-service-settings/create-email-service-settings.component';
import { EditEmailServiceSettingsComponent } from 'src/app/modules/admin/components/admin-settings/edit-email-service-settings/edit-email-service-settings.component';

import { MatDialog } from '@angular/material/dialog';
import { AsyncList } from '@rest/AsyncList';
import { ServiceEmailSettings } from '@models/settings/service-email-settings';
import { ServiceEmailSettingsHttpService } from '@services/http/ServiceEmailSettingsHttpService';
import { ServiceSettingsHttpService } from '@services/http/ServiceSettihgsHttpService';
import { ServiceSettings } from '@models/settings/service-settings';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { SendEmailFormComponent } from 'src/app/modules/admin/components/admin-settings/send-email-form/send-email-form.component';
import { SettingsService } from '@services/system/settings/settings.service';
import { AppService } from '../../../../services/app/app.service';
import { ContentSettings } from '@models/settings/content-settings';
import { ContentService } from '@services/system/settings/content.service';
import { ContentSettingsHttpService } from '@services/http/ContentSettingsHttpService';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.css'],
})
export class AdminSettingsComponent implements OnInit {
  serviceEmailSettings: AsyncList<ServiceEmailSettings>;
  serviceSettings: AsyncList<ServiceSettings>;
  contentSettings: AsyncList<ContentSettings>;
  form: UntypedFormGroup;
  currentContentSettings: ContentSettings;
  currentServiceSettings: ServiceSettings;
  currentServiceEmailSettings: ServiceEmailSettings;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _serviceEmailSettingsHttpService: ServiceEmailSettingsHttpService,
    private _serviceSettingsHttpService: ServiceSettingsHttpService,
    private _contentSettingsHttpService: ContentSettingsHttpService,
    private _settingsService: SettingsService,
    private _contentService: ContentService,
    private _alertService: AlertService,
    private _appService: AppService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this._initForm();

    this.serviceEmailSettings = new AsyncList<ServiceEmailSettings>(
      this._serviceEmailSettingsHttpService
    );
    this.serviceSettings = new AsyncList<ServiceSettings>(
      this._serviceSettingsHttpService
    );

    this.contentSettings = new AsyncList<ContentSettings>(
      this._contentSettingsHttpService
    );

    this.currentContentSettings = this._contentService.getSettings();

    this._loadData();
  }

  private _loadData(): void {
    this.serviceEmailSettings.load().subscribe(() => {
      this._loadServiceSettings();
    });
  }

  private _initForm(): void {
    this.form = this._formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],

      service_url: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],

      host: [
        { value: '', disabled: true },
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],

      default_from_email: [
        { value: '', disabled: true },
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
          Validators.email,
        ],
      ],

      default_from_name: [
        { value: '', disabled: true },
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],

      host_user: [
        { value: '', disabled: true },
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],

      host_password: [
        { value: '', disabled: true },
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],

      port: [
        { value: '', disabled: true },
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],

      host_secure: [
        { value: '', disabled: true },
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
    });
  }

  showCreateServiceForm(): void {
    this.dialog
      .open(CreateEmailServiceSettingsComponent, {
        width: '600px',
      })
      .afterClosed()
      .subscribe(() => {
        this._loadData();
      });
  }

  showSendTestEmailForm(): void {
    this.dialog
      .open(SendEmailFormComponent, {
        width: '600px',
      })
      .afterClosed();
  }

  showEditEmailServiceForm(settings: ServiceEmailSettings): void {
    this.dialog
      .open(EditEmailServiceSettingsComponent, {
        width: '600px',
        data: { settings },
      })
      .afterClosed()
      .subscribe(() => {
        this._loadData();
      });
  }

  createServiceSettingsConfiguration(): void {
    this.serviceSettings
      .create({
        name: 'Базовая конфигурация',
        use_team_validation: false,
      })
      .subscribe(() => {
        this._loadData();
      });

    this.contentSettings
      .create({
        allow_content_edit: false,
      })
      .subscribe(() => {
        this._loadContentSettings();
      });
  }

  setCurrentEmailServiceSettings($event: ServiceEmailSettings): void {
    this.serviceSettings
      .update(this.currentServiceSettings.uuid, { email_settings: $event.uuid })
      .subscribe((response) => {
        this.currentServiceEmailSettings = $event;

        this.form.setValue({
          name: this.currentServiceSettings.name,
          service_url: this.currentServiceSettings.service_url,
          host: this.currentServiceEmailSettings.host,
          port: this.currentServiceEmailSettings.port,
          default_from_email:
            this.currentServiceEmailSettings.default_from_email,

          default_from_name: this.currentServiceEmailSettings.default_from_name,
          host_user: this.currentServiceEmailSettings.host_user,
          host_password: this.currentServiceEmailSettings.host_password,
          host_secure: this.currentServiceEmailSettings.host_secure,
        });

        this._settingsService.setSettings(response);
        this._alertService.success('Конфигурация обновлена');
      });
  }

  removeEmailServiceSettings(uuid: string): void {
    this.serviceEmailSettings.remove(uuid).subscribe(() => {
      this._loadData();
    });
  }

  updateServiceSettings(): void {
    this.serviceSettings
      .update(this.currentServiceSettings.uuid, this.form.value)
      .subscribe((response) => {
        this._settingsService.setSettings(response);
        this._alertService.success('Конфигурация обновлена');
      });
  }

  _loadServiceSettings(): void {
    this.serviceSettings.load().subscribe(() => {
      if (this.serviceSettings.state.items.length > 0) {
        this.currentServiceSettings = this.serviceSettings.state.items[0];
        this.currentServiceEmailSettings = this.getCurrentEmailSettings();
        this.form.controls.name.setValue(this.currentServiceSettings.name);

        if (this.currentServiceEmailSettings) {
          this.form.setValue({
            name: this.currentServiceSettings.name,
            service_url: this.currentServiceSettings.service_url,
            host: this.currentServiceEmailSettings.host,
            port: this.currentServiceEmailSettings.port,
            default_from_email:
              this.currentServiceEmailSettings.default_from_email,

            default_from_name:
              this.currentServiceEmailSettings.default_from_name,
            host_user: this.currentServiceEmailSettings.host_user,
            host_password: this.currentServiceEmailSettings.host_password,
            host_secure: this.currentServiceEmailSettings.host_secure,
          });
        }
      }
    });
  }

  _loadContentSettings(): void {
    this.contentSettings.load().subscribe(() => {
      this.currentContentSettings = this.contentSettings.state.items[0];
    });
  }

  getCurrentEmailSettings(): ServiceEmailSettings {
    if (
      this.currentServiceSettings &&
      this.serviceEmailSettings.state.isLoaded
    ) {
      const currentEmailSettings = this.serviceEmailSettings.state.items.find(
        (serviceEmailSettigns) => {
          return (
            serviceEmailSettigns.uuid ===
            this.currentServiceSettings.email_settings
          );
        }
      );

      return currentEmailSettings;
    }
  }

  toggleUseTeamValidation(): void {
    this.serviceSettings
      .update(this.currentServiceSettings.uuid, {
        use_team_validation: !this.currentServiceSettings.use_team_validation,
      })
      .subscribe((response) => {
        this._settingsService.setSettings(response);
      });
  }

  public get useNewLayout(): boolean {
    return this._appService.useNewLayout;
  }

  toggleAllowContentEdit(): void {
    this.contentSettings
      .update(this.currentContentSettings.uuid, {
        allow_content_edit: !this.currentContentSettings.allow_content_edit,
      })
      .subscribe((response) => {
        this._contentService.setSettings(response);
      });
  }
}
