<div class='router-outlet-container'>
  <ng-container *ngIf='is_data_loaded; then component_content; else preloader'>
  </ng-container>
</div>

<ng-template #preloader>
  <app-preloader></app-preloader>
</ng-template>

<ng-template #component_content>
  <div class='app-admin-ontology-thing-details-container'>
    <div class='app-admin-ontology-thing-details-header-container'>

      <ng-container [ngTemplateOutlet]=' in_edit_mode ? tmpl_header_edit_mode : tmpl_header'>
      </ng-container>

    </div>

    <div class='app-admin-ontology-thing-details-content-container'>
      <mat-tab-group animationDuration='0ms' mat-stretch-tabs='false'>

        <mat-tab label='Уровни владения'>
          <div class='app-admin-ontology-thing-details-content-tab-container'>
            <app-thing-tab-levels [thing]='thing'></app-thing-tab-levels>
          </div>
        </mat-tab>

        <mat-tab label='Материалы для изучения' [disabled]='true'>
          <div class='app-admin-ontology-thing-details-content-tab-container'>
            ???
          </div>
        </mat-tab>

      </mat-tab-group>
    </div>

  </div>
</ng-template>


<ng-template #tmpl_header>
  <div fxLayout='row'>
    <div fxFlex>
      <div class='app-admin-ontology-thing-details-header-name'>
        {{ thing.name }}
      </div>
      <div class='app-admin-ontology-thing-details-header-description'>
        {{ thing.description }}
      </div>
      <div class='app-admin-ontology-thing-details-header-domain'>
        Из домена
        <a routerLink='/admin/ontologies/domains/{{ thing.domain.uuid }}'>
          {{ thing.domain.name }}
        </a>
      </div>
      <div class='app-admin-ontology-thing-details-header-team'>
        <div *ngIf='!thing.team'>
          Публичная компетенция
        </div>
        <div *ngIf='thing.team'>
          <a routerLink='/admin/teams/{{ thing.team }}'>
            Компетенция привязана к команде
          </a>
        </div>
      </div>
    </div>
    <div fxFlex='15rem'>
      <div>
        <div class='app-admin-ontology-thing-details-header-controls'>
          <button mat-stroked-button (click)='on_button_edit()'>
            <mat-icon>edit</mat-icon>
            Редактировать
          </button>
          &nbsp;
          <button mat-stroked-button color='warn' (click)='on_button_thing_delete_show_tooltip($event)'>
            <mat-icon>delete</mat-icon>
          </button>

          <div #tooltip_delete_thing
               class='app-admin-ontology-thing-details-header-delete-tooltip' style='display: none; opacity: 0;'
               (mouseleave)='on_button_thing_delete_hide_tooltip()'>
            <div class='app-admin-ontology-thing-details-header-delete-tooltip-header'>
              Удалить сущность?
            </div>
            <div class='app-admin-ontology-thing-details-header-delete-tooltip-description'>
              Будут потеряны все связанные компетенции
            </div>
            <div fxLayout='row'>
              <div fxFlex fxLayoutAlign='space-around center'>
                <button mat-raised-button color='warn'
                        [disabled]='is_button_thing_tooltip_delete_disabled'
                        (click)='on_tooltip_thing_delete_confirm()'>
                  Удалить
                </button>

                <button mat-raised-button (click)='on_tooltip_thing_delete_cancel()'>
                  Отмена
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class='app-admin-ontology-thing-details-header-metadata'>
          Дата создания: {{ thing.date_created | amDateFormat: 'YYYY-MM-DD HH:mm' }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_header_edit_mode>
  <div class='app-admin-ontology-thing-details-header-edit-container'>
    <div fxLayout='row'>
      <div fxFlex>

        <form [formGroup]='thing_edit_form' fxLayout='column' fxLayoutGap='1rem'>
          <mat-form-field>
            <mat-label>Название</mat-label>
            <input #input_field_name matInput
                   placeholder='Название'
                   formControlName='thing_name' required>

            <mat-hint>{{ 128 - input_field_name.value.length }}/128</mat-hint>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Описание</mat-label>
            <input #input_field_description matInput
                   placeholder='Описание'
                   formControlName='thing_description' required>
            <mat-hint>{{ 1024 - input_field_description.value.length }}/1024</mat-hint>
          </mat-form-field>

          <mat-form-field [style.width.rem]=30>
            <mat-label>Домен</mat-label>
            <mat-select formControlName='thing_domain' [value]='thing.domain'>
              <mat-option [value]='thing.domain' disabled='disabled'>
                {{ thing.domain.name }}
              </mat-option>
              <mat-option *ngFor='let domain of domains' [value]='domain'>
                {{ domain.name }}
                <small>
                                    <span *ngIf='!domain.team'>
                                        ( Публичный )
                                    </span>
                  <span *ngIf='domain.team'>
                                        ( {{ domain.team.name }} )
                                    </span>
                </small>
              </mat-option>
            </mat-select>
          </mat-form-field>

        </form>

      </div>

      <div fxFlex='20rem' fxLayoutAlign='space-around start'>
        <button mat-stroked-button [disabled]='thing_edit_form.invalid' (click)='on_button_save_edit_form()'>
          <mat-icon>save</mat-icon>
          Сохранить
        </button>
        <button mat-stroked-button (click)='on_button_edit_cancel()'>
          <mat-icon>cancel</mat-icon>
          Отмена
        </button>
      </div>

    </div>
  </div>
</ng-template>
