import { Component, OnInit } from '@angular/core';
import { get_full_parent_path } from '../../../../../utils/getFullParentUrl';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TeamHttpService } from '@services/http/TeamHttpService';
import { Team } from '@models/teams/team';
import { AsyncList } from '@rest/AsyncList';
import {
  TeamCreateDialogComponent,
  TeamData,
} from '@components/common/team-create-dialog/team-create-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../../../services/auth/auth.service';
import { AppService } from '../../../../../services/app/app.service';
import { UserService } from '../../../../../services/user/user.service';
import { LoggingService } from '../../../../../services/logging.service';
import { AlertService } from '../../../../../services/ui/ui-alert.service';
import { TeamMembershipHttpService } from '@services/http/TeamMembershipHttpService';
import { TitleService } from '../../../../../services/title.service';

interface TeamTreeData extends Team {
  children: TeamTreeData[];
  childrenCount: number;
  level: number;
  isExpanded: boolean;
}

@Component({
  selector: 'app-teams-dashboard-new-detail',
  templateUrl: './teams-dashboard-new-detail.component.html',
  styleUrls: ['./teams-dashboard-new-detail.component.css'],
})
export class TeamsDashboardNewDetailComponent implements OnInit {
  parentTeamUuid: string;

  parentTeam: Team;

  teams: AsyncList<Team>;

  teamsTreeData: TeamTreeData[];

  teamsTreeDataReady = false;
  componentReused = false;

  navigationSubscription;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _dialog: MatDialog,
    private _authService: AuthService,
    private _appService: AppService,
    private _titleService: TitleService,
    private _userService: UserService,
    private _loggingService: LoggingService,
    private _alertService: AlertService,
    private _teamHttpService: TeamHttpService,
    private _teamMembershipHttpService: TeamMembershipHttpService
  ) {
    this.navigationSubscription = this._router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (this.componentReused && e instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
  }

  ngOnDestroy(): void {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.componentReused = true;
    this.parentTeamUuid = this._activatedRoute.snapshot.paramMap.get('uuid');
    this.teams = new AsyncList<Team>(this._teamHttpService);
    this.teams.setRequestParams({
      params: {
        team: this.parentTeamUuid,
        with_child_teams: true,
        expand: 'members',
      },
    });
    this._loadTeams();
  }

  private _loadTeams(): void {
    this.teams.load().subscribe(() => {
      this.teamsTreeDataReady = false;
      this.teamsTreeData = [];
      this.teams.state.items = this.teams.state.items.filter((team) => {
        if (team.uuid !== this.parentTeamUuid) {
          return true;
        } else {
          this.parentTeam = team;
          return false;
        }
      });

      let firstTeam = true;
      this.teams.state.items.forEach((team) => {
        if (team.parent === this.parentTeamUuid) {
          const result = this._getTeamChildren(team, 0);
          this.teamsTreeData.push({
            ...team,
            children: result.children,
            childrenCount: result.count,
            level: 0,
            isExpanded: firstTeam,
          });
          firstTeam = false;
        }
      });
      this._titleService.set_title(this.parentTeam.name);
      this.teamsTreeDataReady = true;
    });
  }

  private _getTeamChildren(
    parentTeam: Team,
    level: number
  ): {
    children: TeamTreeData[];
    count: number;
    level: number;
  } {
    level += 1;
    const children: TeamTreeData[] = [];
    let count = 0;
    this.teams.state.items.forEach((team) => {
      if (team.parent === parentTeam.uuid) {
        count += 1;
        const result = this._getTeamChildren(team, level);
        children.push({
          ...team,
          children: result.children,
          childrenCount: result.count,
          level: level,
          isExpanded: false,
        });
        count += result.count;
      }
    });
    return {
      children: children,
      count: count,
      level: level,
    };
  }

  onTeamAdd(): void {
    this._dialog.open(TeamCreateDialogComponent, {
      data: {
        teams: [...this.teams.state.items, this.parentTeam],
        parentTeam: this.parentTeam,
        onCreateTeam: (formData: TeamData) => {
          this.createTeam(formData);
        },
      },
    });
  }

  createTeam(formData: TeamData): void {
    const teamData = {
      name: formData.teamName,
      description: formData.teamDescription,
      parent: formData.teamParent.uuid,
      created_by: this._authService.get_current_user_uuid(),
    };
    this._teamHttpService.create({ ...teamData }).subscribe({
      next: (team) => {
        this._loggingService.debug(
          `${this.constructor.name} successfully created team`
        );
        const membershipData = {
          team: team.uuid,
          user: this._authService.get_current_user_uuid(),
          role: 'owner',
        };

        this._teamMembershipHttpService
          .create({ ...membershipData })
          .subscribe(() => {
            this._appService.load_user_memberships();
            this._userService.get_edit_teams().subscribe((response) => {
              this._loggingService.debug(
                `${this.constructor.name} fetched user edit teams`
              );
              this._authService.set_user_edit_teams(response);
            });
            this._loadTeams();
          });
      },
      error: (error) => {
        this._loggingService.debug(
          `${this.constructor.name} team creation failed`
        );
        this._alertService.error('Ошибка создания команды');
      },
    });
  }

  navigateToTeam(team: TeamTreeData): void {
    if (team.children.length) this._router.navigate([`teams/${team.uuid}`]);
  }

  redirectToUserTeam(team: TeamTreeData): void {
    this._router.navigate([`account/user-teams/${team.uuid}`]);
  }

  onBackClick(): void {
    history.back();
  }

  getFullParentPath = get_full_parent_path;
}
