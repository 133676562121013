<h2 mat-dialog-title>
  Отображение {{ data.speciality.name }}
</h2>
<mat-dialog-content>
  <div fxLayout="row" fxLayoutGap="3rem">
    <div fxFlex="600px">
      <ng-container *ngIf="selected_visual === 'progressbar'; then tmpl_layout_progressbar; else tmpl_layout_radar">
      </ng-container>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Закрыть</button>
</mat-dialog-actions>

<ng-template #tmpl_layout_progressbar>
  progressbar
</ng-template>

<ng-template #tmpl_layout_radar>
  <app-speciality-radar-chart
    [html_element_class]="'app-speciality-profile-dialog'"
    [grades]="data.grades"
    [domain_claims]="data.domain_claims"
    [competence_claims]="data.competence_claims"
    [user_competencies]="[]">
  </app-speciality-radar-chart>
</ng-template>


