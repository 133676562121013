<div *ngIf='isTreeReady'>
  <div *ngIf='isTreeEmpty' fxLayoutAlign='center center'>
    Для этой специальности еще не настроен профиль компетенций
  </div>
  <div *ngIf='!isTreeEmpty'>
    <div *ngFor='let node of dataSource.data'>
      <app-domain-thing-tree-node
        [node]='node'
        [angularNodes]='treeControl.dataNodes'
        [userUuid]='userUuid'
        [selectMode]='selectMode'
        [profileLevels]='profileLevels'
        (selectThing)='
        onSelectedThingsChange($event.selectedThing, $event.remove)
      '
        (selectDomain)='onDomainSelectionChange($event)'
        [thingDesireMap]='thingDesireMap'
        [thingDesireWithUsersMap]='thingDesireWithUsersMap'
        [libraryEdit]='libraryEdit'
        [thingGradeLevelsMap]='thingGradeLevelsMap'
        [thingUserCompetenceMap]='thingUserCompetenceMap'
        (thingDelete)='deleteThing($event, null)'
        (domainDelete)='deleteDomain($event)'
        (changeDomainOrder)='onChangeDomainOrder($event.domain, $event.mode)'
        (isKeyCompetenceToggle)='
        onToggleKeyCompetence($event.thingUuid, $event.isKey)
      '
        (changeGradeLevel)='
        onGradeLevelChange(
          $event.thingUuid,
          $event.gradeUuid,
          $event.nextThingLevelUuid
        )
      '
        (changeUserCompetenceLevel)='
        onUserCompetenceLevelChange(
          $event.competenceUuid,
          $event.levelUuid,
          $event.thingUuid
        )
      '
        [thingCurrentDesiredLevelMap]='thingCurrentDesiredLevelMap'
        [firstNode]='expandFirstNode ? dataSource.data[0] : null'
        [expandNode]='expandAllNodes'
        [reviewMode]='reviewMode'
        [isEditable]='isEditable'
        [assessmentResponseThingMap]='assessmentResponseThingMap'
        [resolutionResponseThingMap]='resolutionResponseThingMap'
        (acceptAssessmentResponse)='
        onAcceptAssessmentResponse(
          $event.thingNode,
          $event.assessmentResponseUuid,
          $event.selectedLevelUuid,
          $event.comment
        )
      '
        (acceptResolutionResponse)='
        onAcceptResolutionResponse(
          $event.thingNode,
          $event.resolutionResponseUuid,
          $event.selectedLevelUuid
        )
      '
      ></app-domain-thing-tree-node>
    </div>
  </div>
</div>
