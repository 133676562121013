<div fxLayout="row">
  <div fxFlex="100%">
    <h3>Шаблоны для уровней владения компетенциями</h3>
    <p>
      Вы можете определить собственные уровни владения компетенциями, тогда для
      всех новых компетенций, созданных в редакторе команды, будут применяться
      именно ваши уровни владения.
    </p>
    <p>
      Шаблоны уровней владения применяются к компетенции при ее создании, их
      изменение или удаление не повлияет на уже существующие компетенции и на
      пользовательские данные.
    </p>
  </div>
</div>

<div *ngIf="state.isProcessing">
  <app-preloader></app-preloader>
</div>

<p>
  <mat-card *ngIf="state.items.length === 0 && state.isLoaded">
    <mat-card-title>Не создано ни одного уровня</mat-card-title>
  </mat-card>
</p>

<div class="wrapper" *ngFor="let level of state.items">
  <div class="item">
    <div fxLayout="row">
      <div fxFlex="89%">
        <div class="title">
          {{ level.title }}
        </div>
        <div class="description">
          {{ level.description }}
        </div>
      </div>
      <div fxFlex="5%" fxFlexOffset="15%">
        <button mat-icon-button (click)="openEditDialog(level)">
          <mat-icon>edit</mat-icon>
        </button>
        <button (click)="openRemoveDialog(level)" mat-icon-button>
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="buttons">
  <button mat-stroked-button (click)="openCreateDialog()">
    Добавить уровень
  </button>
</div>
