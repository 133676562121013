import { Component, OnInit } from '@angular/core';
import { SpecialityGradeHttpService } from '@services/http/SpecialityGradeHttpService';
import { AsyncList } from '@rest/AsyncList';
import { SpecialityGrade } from '@models/specialities/speciality-grade';
import { ActivatedRoute, Router } from '@angular/router';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { TextfieldDialogComponent } from '../../../../../common-ui-elements/components/textfield-dialog/textfield-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AcceptDialogComponent } from '../../../../../common-ui-elements/components/accept-dialog/accept-dialog.component';
import { SpecialityCompetenceClaimHttpService } from '@services/http/SpecialityCompetenceClaimHttpService';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { SpecialityCompetenceClaim } from '@models/specialities/speciality-competence-claim';
import { Domain } from '@models/ontology/domain';
import { Thing } from '@models/ontology/thing';
import { AddCompetenceFromLibraryDialogComponent } from './add-competence-from-library-dialog/add-competence-from-library-dialog.component';
import { TitleService } from 'src/app/services/title.service';
import { AuthService } from '../../../../../../services/auth/auth.service';
import { TeamMembershipHttpService } from '@services/http/TeamMembershipHttpService';
import { TeamMembership } from '@models/teams/team-membership';
import { User } from '@models/user/user';
import { CreateSpecialityValidationDialogComponent } from '@components/common/create-speciality-validation-dialog/create-speciality-validation-dialog.component';
import { SpecialityValidationHttpService } from '@services/http/SpecialityValidationHttpService';
import { SpecialityValidation } from '@models/specialities/speciality-validation';
import { UserTeamSpecialityData } from '@components/common/team-join-dialog/team-join-dialog.component';
import { SpecialityDomainClaim } from '@models/specialities/speciality-domain-claim';
import { SpecialityDomainClaimHttpService } from '@services/http/SpecialityDomainClaimHttpService';
import { get_full_parent_path } from '../../../../../../utils/getFullParentUrl';
import { SpecialityEventHttpService } from '@services/http/SpecialityEventHttpService';
import { SpecialityEvent } from '@models/events/speciality-event';
import {
  build_thing_competenceclaim_map,
  ThingCompetenceClaimMap,
} from '../../../../../../utils/build-thing-competenceclaim-map';
import { AutoLevelCompetenceclaimsDialogComponent } from '@components/common/auto-level-competenceclaims-dialog/auto-level-competenceclaims-dialog.component';
import { AlertService } from '../../../../../../services/ui/ui-alert.service';

export interface Level {
  description: string;
  order_number: number;
  title: string;
  uuid: string;
}

export interface ThingGradeLevels {
  grade: SpecialityGrade;
  thingLevel: string;
  levels: Level[];
}

export interface ThingGradeLevelsMap {
  [thingUuid: string]: {
    isKey: boolean;
    thingGradeLevels: ThingGradeLevels[];
  };
}

@Component({
  selector: 'app-team-speciality-detail',
  templateUrl: './team-speciality-detail.component.html',
  styleUrls: ['./team-speciality-detail.component.css'],
})
export class TeamSpecialityDetailComponent implements OnInit {
  teamUuid: string;
  specialityUuid: string;
  speciality: AsyncDetail<Speciality>;
  specialityGrades: AsyncList<SpecialityGrade>;
  specialityCompetenceClaims: AsyncList<SpecialityCompetenceClaim>;
  specialityValidation: AsyncList<SpecialityValidation>;
  specialityEvents: AsyncList<SpecialityEvent>;

  specialityDomainClaims: AsyncList<SpecialityDomainClaim>;
  things: Thing[];
  thingGradeLevelsMap: ThingGradeLevelsMap;
  thingCompetenceClaimMap: ThingCompetenceClaimMap;
  thingGradeLevelsMapReady = false;

  notViewedEventsCount = 0;

  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _alertService: AlertService,
    private _specialityDomainClaimHttpService: SpecialityDomainClaimHttpService,
    private _specialityHttpService: SpecialityHttpService,
    private _specialityGradeHttpService: SpecialityGradeHttpService,
    private _specialityCompetenceClaimHttpService: SpecialityCompetenceClaimHttpService,
    private _specialityValidationHttpService: SpecialityValidationHttpService,
    private _specialityEventHttpService: SpecialityEventHttpService,
    private _titleService: TitleService
  ) {}

  getFullParentPath = get_full_parent_path;
  buildThingCompetenceClaimMap = build_thing_competenceclaim_map;

  ngOnInit(): void {
    this._titleService.set_title('Специальность');
    this.teamUuid = this._activatedRoute.snapshot.paramMap.get('uuid');
    this.specialityUuid = this._activatedRoute.snapshot.paramMap.get('sUuid');
    this._loadDomainClaims();
    this._loadSpeciality();
    this._loadSpecialityGrades();
    this._loadSpecialityClaims();
    this._loadSpecialityValidation();
    this._loadSpecialityEvents();
  }

  private _loadSpecialityEvents(): void {
    const params = {
      speciality: this.specialityUuid,
      expand: 'created_by',
    };
    if (!this._authService.is_admin()) {
      params['archive'] = false;
    }

    this.specialityEvents = new AsyncList<SpecialityEvent>(
      this._specialityEventHttpService
    );
    this.specialityEvents.setRequestParams({
      params,
    });
    this.specialityEvents.load().subscribe(() => {
      //show dialog if needed
      this.notViewedEventsCount = this.specialityEvents.state.items.filter(
        (event) => !event.is_viewed
      ).length;
    });
  }

  private _loadDomainClaims(): void {
    this.specialityDomainClaims = new AsyncList<SpecialityDomainClaim>(
      this._specialityDomainClaimHttpService
    );
    this.specialityDomainClaims.setRequestParams({
      params: {
        speciality: this.specialityUuid,
        expand: 'domain',
      },
    });
    this.specialityDomainClaims.load().subscribe();
  }

  private _loadSpecialityClaims(): void {
    this.things = [];
    this.thingGradeLevelsMap = {};
    this.specialityCompetenceClaims = new AsyncList<SpecialityCompetenceClaim>(
      this._specialityCompetenceClaimHttpService
    );
    this.specialityCompetenceClaims.setRequestParams({
      params: {
        speciality: this.specialityUuid,
        expand: 'thing.levels,grade',
      },
    });
    this.specialityCompetenceClaims.load().subscribe(() => {
      this.specialityCompetenceClaims.state.items.forEach(
        (competenceClaim: SpecialityCompetenceClaim<string>) => {
          if (
            !this.things.find((thing) => {
              return thing.uuid === competenceClaim.thing.uuid;
            })
          ) {
            this.things.push(competenceClaim.thing);
            this.thingGradeLevelsMap[competenceClaim.thing.uuid] = {
              isKey: competenceClaim.is_key,
              thingGradeLevels: [
                {
                  grade: competenceClaim.grade,
                  thingLevel: competenceClaim.thing_level,
                  levels: competenceClaim.thing.levels,
                },
              ],
            };
          } else {
            this.thingGradeLevelsMap[competenceClaim.thing.uuid][
              'thingGradeLevels'
            ].push({
              grade: competenceClaim.grade,
              thingLevel: competenceClaim.thing_level,
              levels: competenceClaim.thing.levels,
            });
          }
        }
      );
      this.thingCompetenceClaimMap = this.buildThingCompetenceClaimMap(
        this.specialityCompetenceClaims.state.items
      );
      this.thingGradeLevelsMapReady = true;
    });
  }

  private _loadSpeciality(): void {
    this.speciality = new AsyncDetail<Speciality>(
      this.specialityUuid,
      this._specialityHttpService
    );
    this.speciality.load().subscribe(() => {
      this._titleService.set_title(this.speciality.state.item.name);
    });
  }

  private _loadSpecialityGrades(): void {
    this.specialityGrades = new AsyncList<SpecialityGrade>(
      this._specialityGradeHttpService
    );
    this.specialityGrades.setRequestParams({
      params: {
        speciality: this.specialityUuid,
      },
    });
    this.specialityGrades.load().subscribe(() => {
      this.specialityGrades.state.items.sort((a, b) => a.order - b.order);
    });
  }

  private _loadSpecialityValidation(): void {
    this.specialityValidation = new AsyncList<SpecialityValidation>(
      this._specialityValidationHttpService
    );
    this.specialityValidation.setRequestParams({
      params: {
        speciality: this.specialityUuid,
      },
    });
    this.specialityValidation.load();
  }

  getCreatorText(event: SpecialityEvent): string {
    const creator = event.created_by as User;
    if (!creator) {
      return '';
    }
    return creator.last_name + ' ' + creator.first_name;
  }

  getDateStr(dateStr: string): string {
    const date = new Date(dateStr);
    return date.toLocaleDateString();
  }

  get isTeamOwner(): boolean {
    return (
      this._authService
        .get_user_memberships()
        .data.find((membership) => membership.team.uuid === this.teamUuid)
        ?.role === 'owner'
    );
  }

  onTabChange(index: number): void {
    if (index === 2) {
      if (this.isTeamOwner) {
        const unviewedEvents = this.specialityEvents.state.items.filter(
          (event) => !event.is_viewed
        );
        if (unviewedEvents.length)
          this._specialityEventHttpService
            .setSpecialityEventsIsViewed({
              events: unviewedEvents.map((event) => event.uuid),
            })
            .subscribe(() => {
              this.notViewedEventsCount = 0;
            });
      }
    }
  }

  onGradeAdd(): void {
    this._dialog.open(TextfieldDialogComponent, {
      data: {
        title: `Добавить уровень позиции`,
        description: ``,
        acceptLabel: 'Добавить',
        rejectLabel: 'Отменить',
        floatLabel: 'never',
        fields: [
          {
            label: 'Название уровня',
            placeholder: 'Введите название уровня',
            exampleText: '',
            required: true,
            formControlName: 'name',
            value: '',
          },
          {
            label: 'Описание',
            placeholder: 'Подробно опишите',
            exampleText:
              'Например: Дизайнер интерфейсов будет решать задачи коммуникации между системой ввода данных и пользователем',
            required: false,
            formControlName: 'description',
            value: '',
          },
        ],
        onSubmit: (data: any) => {
          this.specialityGrades.create({
            speciality: this.specialityUuid,
            name: data.name,
            description: data.description,
          });
        },
      },
    });
  }

  onGradeEdit(specialityGrade: SpecialityGrade): void {
    this._dialog.open(TextfieldDialogComponent, {
      data: {
        title: `Редактирование уровня позиции`,
        description: ``,
        acceptLabel: 'Сохранить',
        rejectLabel: 'Отменить',
        floatLabel: 'never',
        fields: [
          {
            label: 'Название уровня',
            placeholder: 'Введите название уровня',
            exampleText: '',
            required: true,
            formControlName: 'name',
            value: specialityGrade.name,
          },
        ],
        onSubmit: (data: any) => {
          this.specialityGrades.update(specialityGrade.uuid, {
            name: data.name,
            description: data.description,
          });
        },
      },
    });
  }

  onGradeDelete(specialityGrade: SpecialityGrade): void {
    this._dialog
      .open(AcceptDialogComponent, {
        data: {
          title: `Удалить уровень знания`,
          message: `Вы уверены, что хотите удалить уровень позиции ${specialityGrade.name}?`,
          acceptLabel: 'Удалить',
          rejectLabel: 'Отменить',
        },
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) {
          this.specialityGrades.remove(specialityGrade.uuid);
        }
      });
  }

  onSpecialityEdit(speciality: Speciality): void {
    this._dialog.open(TextfieldDialogComponent, {
      data: {
        title: `Редактирование специальности`,
        description: ``,
        acceptLabel: 'Сохранить',
        rejectLabel: 'Отменить',
        floatLabel: 'never',
        fields: [
          {
            label: 'Название специальности',
            placeholder: 'Введите название специальности',
            exampleText: '',
            required: true,
            formControlName: 'name',
            value: speciality.name,
          },
          {
            label: 'Какие задачи выполняет',
            placeholder:
              'Подробно опишите какие задачи будет решать специалист',
            exampleText:
              'Например: Дизайнер интерфейсов будет решать задачи коммуникации между системой ввода данных и пользователем',
            required: true,
            formControlName: 'description',
            value: speciality.description,
          },
        ],
        onSubmit: (data: any) => {
          this.speciality.update(data);
        },
      },
    });
  }

  onSpecialityDelete(speciality: Speciality): void {
    this._dialog
      .open(AcceptDialogComponent, {
        data: {
          title: `Удалить специальность`,
          message: `Вы уверены, что хотите удалить специальность ${speciality.name}?`,
          acceptLabel: 'Удалить',
          rejectLabel: 'Отменить',
        },
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) {
          this.speciality.remove().subscribe(() => {
            this._router.navigate([
              `${this.getFullParentPath(this._activatedRoute).replace(
                `/${this.specialityUuid}`,
                ''
              )}`,
            ]);
          });
        }
      });
  }

  onCompetenceFromLibraryAdd(): void {
    this._dialog.open(AddCompetenceFromLibraryDialogComponent, {
      data: {
        onSubmit: (claims: string[]) => {
          const claimsData = [];
          claims.forEach((claim) => {
            claimsData.push({
              speciality: this.specialityUuid,
              thing: claim,
            });
          });
          this._specialityCompetenceClaimHttpService
            .addClaimsWithGrades(claimsData)
            .subscribe((response) => {
              this._loadDomainClaims();
              this._loadSpecialityClaims();
              this._dialog.closeAll();
            });
        },
      },
    });
  }

  openCreateValidationDialog(): void {
    this._dialog.open(CreateSpecialityValidationDialogComponent, {
      data: {
        specialityUuid: this.specialityUuid,
        teamUuid: this.teamUuid,
        onSubmit: (validationUuid: string) => {
          this._router.navigate([`verifications/${validationUuid}/speciality`]);
        },
      },
    });
  }

  onCompetenciesAutoLevel(): void {
    if (Object.values(this.thingCompetenceClaimMap).length) {
      const levels = this.things.find(
        (thing) => thing.levels.length > 0
      ).levels;
      const firstThingCompetenceClaims = Object.values(
        this.thingCompetenceClaimMap
      )[0].map((competenceClaim) => {
        return {
          ...competenceClaim,
          thing_level: competenceClaim.thing.levels.find(
            (level) => level.uuid === competenceClaim.thing_level
          ),
        };
      });
      const gradeFormData = {};
      firstThingCompetenceClaims.forEach((competenceClaim) => {
        gradeFormData[competenceClaim.grade.uuid] = [0, []];
      });
      this._dialog.open(AutoLevelCompetenceclaimsDialogComponent, {
        data: {
          firstThingCompetenceClaims,
          levels,
          gradeFormData,
          onSubmit: (levelNumbers: { [gradeUuid: string]: number }) => {
            this._specialityCompetenceClaimHttpService
              .setSpecialityCompetenceClaimsLevel({
                speciality: this.specialityUuid,
                things_competence_claims: this.thingCompetenceClaimMap,
                grade_levels: levelNumbers,
              })
              .subscribe((response) => {
                this._loadSpecialityClaims();
              });
          },
        },
      });
    } else {
      this._alertService.error('У навыков специальности отсутствуют уровни');
    }
  }

  redirectToValidation(): void {
    this._router.navigate([
      `verifications/${this.specialityValidation.state.items[0].uuid}/speciality`,
    ]);
  }

  get isAdmin(): boolean {
    return this._authService.is_admin();
  }
}
