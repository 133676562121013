<div class='router-outlet-container'>
  <ng-container *ngIf='is_data_loaded; then component_content; else preloader'>
  </ng-container>
</div>

<ng-template #preloader>
  <app-preloader-progressbar></app-preloader-progressbar>
</ng-template>

<ng-template #component_content>
  <div class='app-admin-specialities-dashboard-container'>
    <div fxLayout='row'>
      <div fxFlex>
        <div class='app-admin-specialities-dashboard-main-container'>
          <div class='app-admin-specialities-dashboard-main-header-container'>
            <div fxLayout='row'>
              <div fxFlex='70'>
                <div class='app-admin-specialities-dashboard-main-header-text'>
                  Специальности
                </div>
              </div>
              <div fxFlex='30rem'>
                <div
                  class='app-admin-specialities-dashboard-main-header-controls'
                >
                  <a
                    mat-raised-button
                    class='dashboard-button'
                    routerLink='/admin/specialities/create'
                  >
                    <mat-icon>group_add</mat-icon>
                    Создать специальность
                  </a>
                </div>
              </div>
            </div>
          </div>

          <mat-divider></mat-divider>

          <div
            class='app-admin-specialities-dashboard-speciality-list-container'
          >
            <div *ngFor='let speciality of specialities'>
              <ng-container
                [ngTemplateOutlet]='
                  speciality.uuid == get_speciality_edited_in_progress()
                    ? list_item_edited
                    : list_item
                '
                [ngTemplateOutletContext]='{ speciality: speciality }'
              >
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #list_item_edited let-speciality='speciality'>
  <div
    class='app-admin-specialities-dashboard-listitem edit-template'
    fxLayout='row'
  >
    <div
      fxFlex='50'
      fxLayout='column'
      fxLayoutAlign='center'
      class='app-admin-specialities-dashboard-listitem-field-container'
    >
      <input
        class='app-admin-specialities-dashboard-listitem-edit-field'
        #new_speciality_name
        autofocus
        matInput
        placeholder='Название специальности*'
        required
        autocomplete='off'
        value='{{ speciality.name }}'
        maxlength='128'
        (keyup.enter)='
          save_new_speciality_name(speciality, new_speciality_name.value)
        '
        (keyup.escape)='reset_edit_speciality()'
      />
      <div
        class='app-admin-specialities-dashboard-listitem-edit-field-dash'
        [class.warn]="new_speciality_name.value === ''"
      ></div>
    </div>
    <span
      class='app-admin-specialities-dashboard-listitem-edit-field-length'
      fxFlex='25'
      [class.warn]="new_speciality_name.value === ''"
    >{{ 128 - new_speciality_name.value.length }}/255</span
    >
    <div
      class='app-admin-specialities-dashboard-listitem-buttons-container'
      fxFlex='25'
      fxLayout='row-reverse'
      fxLayoutAlign='space-around center'
    >
      <a mat-raised-button (click)='reset_edit_speciality()'> Отмена </a>
      <a
        mat-raised-button
        (click)='
          save_new_speciality_name(speciality, new_speciality_name.value)
        '
        [disabled]="new_speciality_name.value === ''"
      >
        Сохранить
      </a>
    </div>
  </div>
</ng-template>

<ng-template #list_item let-speciality='speciality'>
  <div
    class='app-admin-specialities-dashboard-listitem'
    fxLayout='row'
    fxLayoutAlign='space-between'
  >
    <a
      routerLink='/admin/specialities/speciality/{{ speciality.uuid }}'
      class='app-admin-speciality-dashboard-listitem-link'
      fxFlex='35rem'
    >
      {{ speciality.name }}
      <div class='app-admin-specialities-dashboard-listitem-team'>
        <div *ngIf='speciality.team'>{{ speciality.team.name }}</div>
        <div *ngIf='!speciality.team'>Не привязана к команде</div>
      </div>
    </a>
    <div
      class='app-admin-speciality-dashboard-listitem-buttons-container'
      fxFlex='25'
      fxLayout='row-reverse'
    >
      <button
        fxFlex='24px'
        mat-icon-button
        aria-label='Delete speciality'
        (click)='on_delete_button_click($event, speciality)'
        style='float: right'
      >
        <mat-icon>delete</mat-icon>
      </button>
      &nbsp; &nbsp;
      <button
        fxFlex='24px'
        mat-icon-button
        aria-label='Edit speciality name'
        (click)='on_edit_button_click(speciality)'
        style='float: right'
      >
        <mat-icon>edit</mat-icon>
      </button>
      &nbsp; &nbsp;
      <mat-icon>
        {{ speciality.is_hidden ? 'visibility_off' : 'visibility' }}
      </mat-icon>
    </div>
  </div>
</ng-template>
