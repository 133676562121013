import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { User } from '@models/user/user';
import { ThingLevel } from '@models/ontology/thing-level';

@Component({
  selector: 'app-auto-level-competenceclaims-dialog',
  templateUrl: './auto-level-competenceclaims-dialog.component.html',
  styleUrls: ['./auto-level-competenceclaims-dialog.component.css'],
})
export class AutoLevelCompetenceclaimsDialogComponent implements OnInit {
  gradeLevelsForm: UntypedFormGroup;
  grades: string[];

  constructor(
    public dialogRef: MatDialogRef<AutoLevelCompetenceclaimsDialogComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      firstThingCompetenceClaims: User[];
      levels: ThingLevel[];
      gradeFormData;
      onSubmit(levelNumbers: number[]): void;
    }
  ) {}

  ngOnInit(): void {
    this.gradeLevelsForm = this._formBuilder.group(this.data.gradeFormData);
    this.grades = Object.keys(this.data.gradeFormData);
  }

  submit(): void {
    this.data.onSubmit(this.gradeLevelsForm.value);
  }
}
