import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ThemePalette } from '@angular/material/core';

import { Subject, BehaviorSubject } from 'rxjs';

import { LoggingService } from 'src/app/services/logging.service';
import { TitleService } from 'src/app/services/title.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SurveyService } from 'src/app/services/survey/survey.service';
import { SurveySessionService } from 'src/app/services/survey/survey-session.service';
import { SurveyQuestionService } from 'src/app/services/survey/survey-question.service';
import { SurveyAnswerService } from 'src/app/services/survey/survey-answer.service';

import { Survey } from 'src/app/models/survey/survey';
import { SurveySession } from 'src/app/models/survey/survey-session';
import { SurveyQuestion } from 'src/app/models/survey/survey-question';
import { SurveyAnswer } from 'src/app/models/survey/survey-answer';

@Component({
  selector: 'app-survey-main',
  templateUrl: './survey-main.component.html',
  styleUrls: ['./survey-main.component.css']
})
export class SurveyMainComponent implements OnInit {

  private survey_uuid: string;
  public survey: Survey;
  public survey_session: SurveySession;
  public survey_questions: SurveyQuestion[] = [];

  public submitted_answer_subject: Subject<SurveyAnswer>;
  public skipped_question_subject: Subject<SurveyQuestion>;
  public returned_question_subject: Subject<SurveyQuestion>;
  public control_subject: BehaviorSubject<any>;
  public visual_control_subject: BehaviorSubject<any>;
  public breadcrumbs_subject: Subject<string[]>;

  // public breadcrumbs: string;
  public breadcrumbs: string[];

  private progress_questions_current = 0;

  public ui_color: ThemePalette = 'warn';

  private uistate = {
    survey_loaded: false,
    survey_session_loaded: false,
    survey_questions_loaded: false,
    header_shown: false,
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logging_service: LoggingService,
    private title_service: TitleService,
    private alert_service: AlertService,
    private auth_service: AuthService,
    private survey_service: SurveyService,
    private survey_session_service: SurveySessionService,
    private survey_question_service: SurveyQuestionService,
    private survey_answer_service: SurveyAnswerService,
  ) { }

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.title_service.set_title('Опрос');
    this.survey_uuid = this.route.snapshot.paramMap.get('survey_uuid');

    const init_control_command = {command: 'init_control_command'};
    this.control_subject = new BehaviorSubject<any>(init_control_command);
    this.visual_control_subject = new BehaviorSubject<any>(init_control_command);

    this.submitted_answer_subject = new Subject<SurveyAnswer>();
    this.submitted_answer_subject.subscribe(submitted_answer => this.submit_answer(submitted_answer));
    this.skipped_question_subject = new Subject<SurveyQuestion>();
    this.skipped_question_subject.subscribe(skipped_question => this.skip_question(skipped_question));
    this.returned_question_subject = new Subject<SurveyQuestion>();
    this.returned_question_subject.subscribe(returned_question => this.returned_to_prev_question(returned_question));

    this.breadcrumbs_subject = new Subject<string[]>();
    this.breadcrumbs_subject.subscribe(breadcrumbs => {setTimeout(() => this.breadcrumbs = breadcrumbs, 50);});

    this.load_survey();
  }

  public get is_data_loaded(): boolean {
    return this.uistate.survey_loaded &&
      this.uistate.survey_session_loaded &&
      this.uistate.survey_questions_loaded;
  }

  public get is_survey_started(): boolean {
    return Boolean(this.survey_session);
  }

  public get is_survey_finished(): boolean {
    return this.survey_session && this.survey_session.is_finished;
  }

  public get is_header_shown(): boolean {
    return this.uistate.header_shown;
  }

  public get current_percentage(): number {
    return Math.round(this.progress_questions_current * (100 / this.survey_questions.length));
  }

  public get get_survey_estimated_time_minutes(): number {
    const seconds_per_answer = 5;
    const seconds_for_all_questions = this.survey_questions.length * seconds_per_answer;
    const minutes_for_all_questions = Math.round(seconds_for_all_questions / 60);
    return minutes_for_all_questions
  }

  private load_survey(): void {
    this.survey_service.fetch_by_uuid(this.survey_uuid).subscribe(
      response => {
        this.survey = response as Survey;
        this.logging_service.debug(`${this.constructor.name} fetched survey ${this.survey.uuid}`);
        this.title_service.set_title(`${this.survey.name}`);
        this.uistate.survey_loaded = true;
        this.load_survey_session();
        this.load_survey_questions();
      },
      err => {
        if (err.status === 404) {
          this.router.navigate(['/400']);
          return;
        }
        this.router.navigate(['/500']);
      }
    );
  }

  private load_survey_session(): void {
    const user_uuid = this.auth_service.get_current_user_uuid();
    this.survey_session_service.fetch_user_session_survey(user_uuid, this.survey.uuid).subscribe(
      response => {
        const sessions = response.results as SurveySession[];
        if (sessions.length !== 0) {
          sessions.forEach(s => {
            if (!s.is_finished) {
              this.survey_session = s
              this.uistate.header_shown = true;
              if (this.survey_session.current_question) {
                this.skip_to_question(this.survey_session.current_question as SurveyQuestion);
              }
            }
          })
        }
        this.uistate.survey_session_loaded = true;
        this.logging_service.debug(`${this.constructor.name} loaded survey session`);
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to load survey session`);
        this.alert_service.error(`Ошибка загрузки сессии ${err.status}`);
      }
    );
  }

  private load_survey_questions(): void {
    this.survey_question_service.fetch_all_for_survey_uuid(this.survey.uuid).subscribe(
      response => {
        this.survey_questions = response.results as SurveyQuestion[];
        this.uistate.survey_questions_loaded = true;
        this.logging_service.debug(`${this.constructor.name} loaded survey ${this.survey_questions.length} questions`);
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to load survey questions`);
        this.alert_service.error(`Ошибка загрузки вопросов ${err.status}`);
      }
    );
  }

  private complete_survey(): void {
    const session_data = {
      is_finished: true,
      current_percentage: 100,
      date_finished: new Date()
    };
    this.survey_session_service.update(this.survey_session.uuid, session_data).subscribe(
      response => {
        this.survey_session = response as SurveySession;
        this.uistate.header_shown = false;
        this.logging_service.debug(`${this.constructor.name} updated survey session`);
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to update survey session`);
        this.alert_service.error(`Ошибка обновления сессии ${err.status}`);
      }
    );
  }

  public on_toggle_survey_header(): void {
    this.uistate.header_shown = !this.uistate.header_shown;
  }

  public on_start(): void {
    this.logging_service.debug(`${this.constructor.name} on_start`);
    const user_uuid = this.auth_service.get_current_user_uuid();
    const survey_sessoin_data = {
      survey: this.survey.uuid,
      user: user_uuid,
    };
    this.survey_session_service.create(survey_sessoin_data).subscribe(
      response => {
        this.survey_session = response as SurveySession;
        this.uistate.header_shown = true;
        this.logging_service.debug(`${this.constructor.name} created survey session`);
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to create survey session`);
        this.alert_service.error(`Ошибка создания сессии ${err.status}`);
      }
    );
  }

  public on_finish(): void {
    this.logging_service.debug(`${this.constructor.name} on_finish, navigate to profile`);
    const user_uuid = this.auth_service.get_current_user_uuid();
    this.router.navigate([`/profile/${user_uuid}`]);
  }

  public on_again(): void {
    this.logging_service.debug(`${this.constructor.name} on_again`);
    this.progress_questions_current = 0;
    this.on_start();
  }

  private submit_answer(answer: SurveyAnswer): void {
    this.logging_service.debug(`${this.constructor.name} received submitted answer from child component`);
    const user_uuid = this.auth_service.get_current_user_uuid();
    answer.survey = this.survey.uuid;
    answer.user = user_uuid;
    answer.session = this.survey_session.uuid;
    this.survey_answer_service.create(answer).subscribe(
      response_answer => {
        this.logging_service.debug(`${this.constructor.name} successfully created answer`);
        this.progress_questions_current = this.progress_questions_current + 1;

        const data = {
          current_question: answer.question.toString(),
          current_percentage: this.current_percentage
        };
        this.survey_session_service.update(this.survey_session.uuid, data).subscribe(
          response_state => {
            this.survey_session = response_state as SurveySession;
            this.logging_service.debug(`${this.constructor.name} updated survey session`);
            if (this.progress_questions_current === this.survey_questions.length) {
              this.complete_survey();
            } else {
              const control_command = {command: 'next_question'};
              this.control_subject.next(control_command);
            }
          },
          err => {
            this.logging_service.debug(`${this.constructor.name} failed to update survey session`);
            this.alert_service.error(`Ошибка обновления сессии ${err.status}`);
          }
        );
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to create answer`);
        this.alert_service.error(`Ошибка сохранения ответа ${err.status}`);
        const control_command = {command: 'submission_error'};
        this.control_subject.next(control_command);
      }
    );
  }

  private skip_to_question(question: SurveyQuestion): void {
    this.logging_service.debug(`${this.constructor.name} will skip to question ${question.uuid}`);
    const control_command = {command: 'skip_to_question', question};
    this.control_subject.next(control_command);
  }

  private skip_question(question: SurveyQuestion): void {
    this.logging_service.debug(`${this.constructor.name} received skipped question from child component`);
    setTimeout(() => {
      this.progress_questions_current = this.progress_questions_current + 1;
    });
  }

  private returned_to_prev_question(question: SurveyQuestion): void {
    this.logging_service.debug(`${this.constructor.name} received return to prev question from child component`);
    setTimeout(() => {
      this.progress_questions_current = this.progress_questions_current - 1;
    });
  }

  public on_button_visual_control_next(): void {
    const control_command = {command: 'next'};
    this.visual_control_subject.next(control_command);
  }

}
