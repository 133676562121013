import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CompetenceDesireHttpService } from '@services/http/CompetenceDesireHttpService';

@Component({
  selector: 'app-desire-button',
  templateUrl: './desire-button.component.html',
  styleUrls: ['./desire-button.component.css']
})
export class DesireButtonComponent implements OnInit {

  constructor(
    private _authService: AuthService,
    private _competenceDesireHttpService: CompetenceDesireHttpService,
  ) { }

  @Input() thingUuid = null;
  desireUuid: string;

  ngOnInit(): void {
    this._competenceDesireHttpService
      .list({params: {user: this._authService.get_current_user_uuid(), thing: this.thingUuid}})
      .subscribe((response: any) => {
        if (response.count != 0) {
          this.desireUuid = response.results[0].uuid;
        }
      });
  }

  desireButtonClick(): void {
    if (!this.desireUuid) {
      this._competenceDesireHttpService
        .create({user: this._authService.get_current_user_uuid(), thing: this.thingUuid})
        .subscribe((response) => {
          this.desireUuid = response.uuid;
        });
    } else {
      this._competenceDesireHttpService
        .delete(this.desireUuid)
        .subscribe((response: any) => {
          this.desireUuid = '';
        });
    }
  }

}
