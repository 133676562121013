import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { MomentModule } from 'ngx-moment';

import { CommonUIElementsModule } from 'src/app/modules/common-ui-elements/common-ui-elements.module';
import { CommonD3ChartsModule } from 'src/app/modules/common-d3-charts/common-d3-charts.module';
import { OntologiesModule } from '../ontologies/ontologies.module';

import { UserProfileRoutingModule } from './user-profile-routing.module';

import { NewProfileComponent } from './components/new-profile/new-profile.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonComponentsModule } from '@components/common/common.module';
import { UiComponents } from '@components/ui/ui.module';

@NgModule({
  declarations: [NewProfileComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MomentModule,

    MatDividerModule,
    MatTabsModule,
    MatStepperModule,
    MatInputModule,
    MatOptionModule,
    MatButtonModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatIconModule,
    MatSelectModule,
    MatProgressBarModule,

    CommonUIElementsModule,
    CommonD3ChartsModule,
    OntologiesModule,

    UserProfileRoutingModule,
    MatTooltipModule,
    CommonComponentsModule,
    UiComponents,
  ],
})
export class UserProfileModule {}
