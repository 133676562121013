import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AsyncDetail } from '@rest/AsyncDetail';
import { SpecialityValidation } from '@models/specialities/speciality-validation';
import { SpecialityValidationHttpService } from '@services/http/SpecialityValidationHttpService';
import { get_full_parent_path } from '../../../../utils/getFullParentUrl';
import { User } from '@models/user/user';
import { Speciality } from '@models/specialities/speciality';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { SpecialityCompetenceClaimHttpService } from '@services/http/SpecialityCompetenceClaimHttpService';
import { AsyncList } from '@rest/AsyncList';
import { Domain } from '@models/ontology/domain';
import { Thing } from '@models/ontology/thing';
import { SpecialityCompetenceClaim } from '@models/specialities/speciality-competence-claim';
import { AuthService } from '../../../../services/auth/auth.service';
import { SpecialityReviewerHttpService } from '@services/http/SpecialityReviewerHttpService';
import { SpecialityValidationComment } from '@models/specialities/speciality-validation-comment';
import { SpecialityValidationCommentHttpService } from '@services/http/SpecialityValidationCommentHttpService';
import { join } from '@angular/compiler-cli/src/ngtsc/file_system';
import { MatDialog } from '@angular/material/dialog';
import { TextInputDialogComponent } from '@components/common/text-input-dialog/text-input-dialog.component';
import { DetailFormData } from '../../../library/domains/domain-detail/domain-detail-form/domain-detail-form.component';
import { SpecialityReviewer } from '@models/specialities/speciality-reviewer';
import { CreateSpecialityValidationDialogComponent } from '@components/common/create-speciality-validation-dialog/create-speciality-validation-dialog.component';

const validationStates = [
  'В процессе',
  'Одобрено',
  'Отклонено',
  'В библиотеке',
];

@Component({
  selector: 'app-verification-detail',
  templateUrl: './verification-detail.component.html',
  styleUrls: ['./verification-detail.component.css'],
})
export class VerificationDetailComponent implements OnInit {
  validationUuid: string;

  validation: AsyncDetail<SpecialityValidation>;
  validationComments: AsyncList<SpecialityValidationComment>;
  speciality: Speciality;

  domains: AsyncList<Domain>;
  specialityCompetenceClaims: AsyncList<SpecialityCompetenceClaim>;
  things: Thing[];

  validationInfoData;

  treeDataReady = false;

  isCopyBtnDisabled = false;

  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _specialityValidationHttpService: SpecialityValidationHttpService,
    private _specialityReviewerHttpService: SpecialityReviewerHttpService,
    private _specialityValidationCommentHttpService: SpecialityValidationCommentHttpService,
    private _domainHttpService: DomainHttpService,
    private _specialityCompetenceClaimHttpService: SpecialityCompetenceClaimHttpService
  ) {}

  ngOnInit(): void {
    this.validationUuid = this._activatedRoute.snapshot.paramMap.get('uuid');

    this._loadValidation();
    this._loadDomains();
  }

  private _loadValidation(): void {
    this.validation = new AsyncDetail<SpecialityValidation>(
      this.validationUuid,
      this._specialityValidationHttpService
    );
    this.validation.requestParams = {
      params: {
        expand: 'speciality,created_by,related_reviewers.user',
      },
    };
    this.validation.load().subscribe(() => {
      if (!this.speciality) {
        this.speciality = this.validation.state.item.speciality as Speciality;
        this._loadSpecialityClaims();
      }
      this._setValidationInfo();
      this._loadComments();
    });
  }

  private _loadComments(): void {
    this.validationComments = new AsyncList<SpecialityValidationComment>(
      this._specialityValidationCommentHttpService
    );
    this.validationComments.setRequestParams({
      params: {
        speciality_reviewer__in: this.validation.state.item.related_reviewers
          .map((reviewer) => reviewer.uuid)
          .join(','),
        expand: 'speciality_reviewer.user',
      },
    });
    this.validationComments.load();
  }

  private _loadDomains(): void {
    this.domains = new AsyncList<Domain>(this._domainHttpService);
    this.domains.load();
  }

  private _loadSpecialityClaims(): void {
    this.things = [];
    this.specialityCompetenceClaims = new AsyncList<SpecialityCompetenceClaim>(
      this._specialityCompetenceClaimHttpService
    );
    this.specialityCompetenceClaims.setRequestParams({
      params: {
        speciality: this.speciality.uuid,
        expand: 'thing',
      },
    });
    this.specialityCompetenceClaims.load().subscribe(() => {
      this.specialityCompetenceClaims.state.items.forEach(
        (competenceClaim: SpecialityCompetenceClaim<string>) => {
          if (
            !this.things.find((thing) => {
              return thing.uuid === competenceClaim.thing.uuid;
            })
          ) {
            this.things.push(competenceClaim.thing);
          }
        }
      );
      this.treeDataReady = true;
    });
  }

  private _setValidationInfo(): void {
    const creator = this.validation.state.item.created_by as User;
    this.validationInfoData = [
      {
        name: 'Дата создания',
        text: this.getPrettyDate(this.validation.state.item.date_created),
      },
      {
        name: 'Автор',
        text: `${creator.last_name} ${creator.first_name}`,
      },
      {
        name: 'Статус',
        text: `${validationStates[this.validation.state.item.status]}`,
      },
    ];
  }

  get selfReviewer(): SpecialityReviewer {
    return this.validation.state.item.related_reviewers.find(
      (reviewer) =>
        (reviewer.user as User).uuid ===
        this._authService.get_current_user_uuid()
    );
  }

  get selfReviewerUuid(): string {
    return this.selfReviewer?.uuid;
  }

  isReviewer(): boolean {
    return !!this.selfReviewerUuid;
  }

  getPrettyDate(dateStr: string): string {
    const date = new Date(dateStr);
    return `${date.getDate()} ${date.toLocaleString('default', {
      month: 'short',
    })} ${date.getFullYear()}`;
  }

  onBackClick(): void {
    history.back();
  }

  declineValidation(): void {
    this._specialityReviewerHttpService
      .update(this.selfReviewerUuid, {
        status: 2,
      })
      .subscribe(() => {
        this._loadValidation();
      });
  }

  acceptValidation(): void {
    this._specialityReviewerHttpService
      .update(this.selfReviewerUuid, {
        status: 1,
      })
      .subscribe(() => {
        this._loadValidation();
      });
  }

  createValidationComment(onDecline: boolean): void {
    this._dialog
      .open(TextInputDialogComponent, {
        data: {
          submit: (text: string) => {
            this._specialityValidationCommentHttpService
              .create({
                speciality_reviewer: this.selfReviewerUuid,
                message: text,
              })
              .subscribe(() => {
                this.validationComments.load();
              });
          },
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (onDecline && result) {
          this.declineValidation();
        }
      });
  }

  openCreateValidationDialog(): void {
    this._dialog
      .open(CreateSpecialityValidationDialogComponent, {
        data: {
          validationUuid: this.validation.state.item.uuid,
          specialityUuid: this.speciality.uuid,
          teamUuid: this.speciality.team,
          reviewers: this.validation.state.item.related_reviewers.map(
            (reviewer) => reviewer.user
          ),
          onSubmit: (validationUuid: string) => {
            this._loadValidation();
          },
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) this._loadValidation();
      });
  }

  onCopySpecialityToLibrary(): void {
    this.isCopyBtnDisabled = true;
    this._specialityValidationHttpService
      .copySpecialityToLibrary(this.validationUuid)
      .subscribe((response) => {
        this._loadValidation();
      });
  }

  get isCreator(): boolean {
    return (
      this._authService.get_current_user_uuid() ===
      (this.validation.state.item.created_by as User).uuid
    );
  }

  get isAdmin(): boolean {
    return this._authService.is_admin();
  }

  getFullParentPath = get_full_parent_path;
}
