import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-speciality-group-edit-page',
  templateUrl: './speciality-group-edit.page.html',
  styleUrls: ['./speciality-group-edit.page.css'],
})
export class SpecialityGroupEditPage {
  constructor() {}
}
