import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { LoggingService } from 'src/app/services/logging.service';

import { Survey } from 'src/app/models/survey/survey';
import { SurveyQuestionTreeNode } from 'src/app/models/survey/survey-question-treenode';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SurveyQuestion } from 'src/app/models/survey/survey-question';
import { SurveyQuestionService } from 'src/app/services/survey/survey-question.service';

@Component({
  selector: 'app-survey-edit-subtree',
  templateUrl: './survey-edit-subtree.component.html',
  styleUrls: ['./survey-edit-subtree.component.css'],
})
export class SurveyEditSubtreeComponent implements OnInit {

  @Input() survey: Survey;
  @Input() tree_data: SurveyQuestionTreeNode[];
  @Input() node_create_request_subject: Subject<SurveyQuestionTreeNode>;
  @Input() node_delete_request_subject: Subject<SurveyQuestionTreeNode>;

  public node_to_delete: SurveyQuestionTreeNode;

  private uistate = {
    reordering_disabled: false
  };

  constructor(
    private logging_service: LoggingService,
    private survey_question_service: SurveyQuestionService,
  ) { }

  public get is_reordering_disabled(): boolean {
    return this.uistate.reordering_disabled;
  }

  public on_drop(event: CdkDragDrop<SurveyQuestion[]>): void {
    this.logging_service.debug(`${this.constructor.name} on_drop`);
    moveItemInArray(this.tree_data, event.previousIndex, event.currentIndex);
    let min_index = event.previousIndex;
    let max_index = event.previousIndex;
    if (event.currentIndex > max_index) {
      max_index = event.currentIndex;
    } else {
      min_index = event.currentIndex;
    }
    this.reorder_questions(min_index, max_index);
  }

  private reorder_questions(min_index: number, max_index: number): void {
    this.logging_service.debug(`${this.constructor.name} reordering questions from ${min_index} to ${max_index}`);
    this.uistate.reordering_disabled = true;
    this.tree_data.forEach((q, index) => {
      if ((min_index <= index) && (index <= max_index)) {
        this.logging_service.debug(`${this.constructor.name} reordering ${min_index} <= ${index} <= ${max_index}`);
        this.survey_question_service.update(q.question.uuid, {order_number: index}).subscribe(
          response => {
            q.question.order_number = index;
            if (index === max_index) {
              this.uistate.reordering_disabled = false;
            }
          },
          err => {
          }
        );
      }
    });
  }

  ngOnInit(): void {
    // this.logging_service.debug(`${this.constructor.name} init`);
  }

  public toggle_child(node: SurveyQuestionTreeNode): void {
    this.logging_service.debug(`${this.constructor.name} toggle child`);
    node.show_children = !node.show_children;
  }

  public on_button_create_child_question(node: SurveyQuestionTreeNode): void {
    this.logging_service.debug(`${this.constructor.name} on_button_show_create_child_question_from`);
    this.node_create_request_subject.next(node);
  }

  public on_button_delete_child_question(node: SurveyQuestionTreeNode): void {
    this.logging_service.debug(`${this.constructor.name} on_button_delete_child_question`);
    this.node_to_delete = node;
    node.to_delete = true;
  }

  public on_button_delete_child_question_confirm(): void {
    this.logging_service.debug(`${this.constructor.name} on_button_delete_child_question_confirm`);
    this.node_delete_request_subject.next(this.node_to_delete);
  }

  public on_button_delete_child_question_cancel(): void {
    this.logging_service.debug(`${this.constructor.name} on_button_delete_child_question_cancel`);
    this.node_to_delete.to_delete = false;
    this.node_to_delete = null;
  }
}
