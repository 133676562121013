<form class="search-form">
  <mat-form-field class="search-form-field">
    <mat-label>{{ title }}</mat-label>
    <input
      type="text"
      matInput
      [placeholder]="placeholder"
      [attr.aria-label]="ariaLabel"
      [formControl]="searchFormControl"
      [matAutocomplete]="auto"
      (input)="onInput($event)"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayWith"
      (optionSelected)="onSelectHandler($event)">
      <mat-option *ngFor="let option of state.items" [value]="option">
        <ng-container
          [ngTemplateOutlet]="optionTemplate"
          [ngTemplateOutletContext]="{ $implicit: option }">
        </ng-container>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
