<ng-template #preloader>
    <app-preloader></app-preloader>
</ng-template>

<div class="app-survey-linear-container">
    <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="40rem">
            <div *ngIf="current_question">
                <div class="app-survey-linear-question-title">
                    {{ current_question.title }}
                </div>
                <div class="app-survey-linear-question-description"
                    [innerHTML]="current_question.description | newlineToBr">
                </div>
            </div>

            <div class="app-survey-linear-answer-options-container">
                <ng-container *ngIf="is_data_loaded; then tmpl_answer_options; else preloader">
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #tmpl_answer_options>
    <div *ngFor="let answer_option of current_question_answer_options"
        class="app-survey-linear-answer-options-listitem"
        [class.app-survey-linear-answer-options-listitem-selected]="is_answer_option_selected(answer_option)"
        (click)="on_select_answer_option(answer_option)">
        <div fxLayout="row">
            <div fxFlex>
                <div class="app-survey-linear-answer-options-listitem-title">
                    {{ answer_option.title }}
                </div>
                <div class="app-survey-linear-answer-options-listitem-description"
                    [innerHTML]="answer_option.description | newlineToBr">
                </div>
            </div>
        </div>
    </div>
    <div class="app-survey-linear-answer-options-controls">
        <button mat-stroked-button
            [disabled]="!is_button_answer_sumbit_enabled"
            (click)="on_submit_answer_option()">
            Ответить и перейти к следующему вопросу
        </button>
    </div>
</ng-template>