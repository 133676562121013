import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { CompetenceDesire } from '@models/competencies/competence-desire';
import { TeamMembership } from '@models/teams/team-membership';
import { Observable } from 'rxjs';
import { SpecialityValidation } from '@models/specialities/speciality-validation';

@Injectable({
  providedIn: 'root',
})
export class CompetenceDesireHttpService extends ViewSetHttpService<CompetenceDesire> {
  http: HttpClient;
  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'competencies/desire';

  public getTeamDesires(teamUuid: string): Observable<any> {
    return this.http.get<{
      teamUuid: string;
    }>(`${this.getUrl()}/team`, {
      responseType: 'json',
      params: {
        team: teamUuid,
      },
    });
  }

  public getUsersDesires(users: string[]): Observable<any> {
    return this.http.post<{
      users: string[];
    }>(`${this.getUrl()}/users_desires`, {
      users: users,
    });
  }
}
