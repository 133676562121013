<div class='library-item' (click)='redirectToItem(item.linkUrl)' [class.link]='librarySearch'>
  <div fxLayout='row' fxLayoutAlign='start center'>
    <div fxFlex='95'>
      <a [routerLink]='item.linkUrl'>
        <div fxLayout='row' fxLayoutGap='8px' fxLayoutAlign='start center'>
          <span *ngIf='item.header' [class.item-header]='!librarySearch'
                [class.item-header_search]='librarySearch'>{{ item.header }}</span>
          <div class='new-flag' *ngIf='item.newFlag'>Новая</div>
        </div>
      </a>
      <div [class.item-content]='!librarySearch'
           [class.item-content_search]='librarySearch'>{{ item.content }}</div>
      <div *ngIf='item.footer' [class.item-footer]='!librarySearch'
           [class.item-footer_search]='librarySearch'>{{ item.footer }}</div>
    </div>
    <!--    <div-->
    <!--      class='item-edit'-->
    <!--      (click)='onItemEdit(item)'-->
    <!--      fxFlex='5'-->
    <!--      *ngIf='(isAdmin() || item.editable) && !librarySearch'-->
    <!--    >-->
    <!--      <button mat-icon-button class='item-edit-button'>-->
    <!--        <mat-icon>edit</mat-icon>-->
    <!--      </button>-->
    <!--    </div>-->
  </div>
</div>
