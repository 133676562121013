import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

import * as d3 from 'd3';

import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-competence-heatmap-chart',
  templateUrl: './competence-heatmap-chart.component.html',
  styleUrls: ['./competence-heatmap-chart.component.css']
})
export class CompetenceHeatmapChartComponent implements OnInit, AfterViewInit {

  private _config: any;
  private _data: any[];
  private _html_element_selector_class: string;

  @Input()
  set chart_name(html_el_class_name: string) {
    this._html_element_selector_class = html_el_class_name;
  }
  get chart_name(): string { return this._html_element_selector_class; }

  @Input()
  set data(input_data: any[]) {
    this._data = input_data;
  }
  get data(): any[] { return this._data; }

  @Input()
  set config(config_data: any) {
    this._config = config_data;
  }
  get config(): any {
    if (this._config) {
      return this._config;
    } else {
      return this._default_config;
    }
  }

  private _default_config = {
    width: 400,
    height: 100,
    margin: {
      top: 30,
      right: 70,
      bottom: 70,
      left: 30
    },
    colors: {
      positive: '#fff',
      negative: '#ff4343'
    }
  };

  constructor(private logging_service: LoggingService) { }

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.draw_chart(), 100);
  }

  private get svg_width(): any { return this.config.width + this.svg_margin_left + this.svg_margin_right + 50; }
  private get svg_height(): any { return this.config.height + this.svg_margin_top + this.svg_margin_bottom + 50; }
  private get svg_margin_top(): any { return this.config.margin.top; }
  private get svg_margin_bottom(): any { return this.config.margin.bottom; }
  private get svg_margin_left(): any { return this.config.margin.left; }
  private get svg_margin_right(): any { return this.config.margin.right; }
  private get svg_color_positive(): any { return this.config.colors.positive; }
  private get svg_color_negative(): any { return this.config.colors.negative; }

  private get svg_effective_width(): any { return this.svg_width - this.svg_margin_left - this.svg_margin_right }
  private get svg_effective_height(): any { return this.svg_height - this.svg_margin_top - this.svg_margin_bottom }

  private draw_chart() {
    this.logging_service.debug(`${this.constructor.name} draw chart`);

    const parent = d3.select(`.${this.chart_name}`);

    // redraw entire element
    parent.select('svg').remove();

    const svg = parent.append('svg')
      .attr('width', this.svg_width)
      .attr('height', this.svg_height);

    const g = svg.append('g').attr('transform', 'translate(' + this.svg_margin_left + ',' + this.svg_margin_top + ')');

    // Labels of row and columns
    const areas = [];
    const users = [];
    this.data.forEach(record => {
      const area = record[0];
      const user = record[1];
      const score = record[2];
      // console.log('record area ' + area + ' user ' + user + ' score ' + score);
      if (!areas.some(a => a === area)) {
        areas.push(area);
      }
      if (!users.some(u => u === user)) {
        users.push(user);
      }
    });

    const x_scale = d3.scaleBand()
      .range([ 0, this.svg_effective_width ])
      .domain(areas)
      .padding(0.01);

    const y_scale = d3.scaleBand()
      .range([ this.svg_effective_height, 0 ])
      .domain(users)
      .padding(0.01);

    const color_scale = d3.scaleLinear<string>()
      .range([this.svg_color_positive, this.svg_color_negative])
      .domain([1, 100]);

    g.append('g')
      .attr('transform', 'translate(0,0)')
      .call(d3.axisTop(x_scale))
      .selectAll('text')
        .attr('y', 0)
        .attr('x', -9)
        .attr('dy', '.35em')
        .attr('transform', 'rotate(45)')
        .style('text-anchor', 'end');

    g.append('g')
      .call(d3.axisLeft(y_scale));


    g.selectAll()
      .data(this.data, (d) => d[0] + ':' + d[1])
      .enter()
      .append('rect')
      .attr('x', (d) => x_scale(d[0]))
      .attr('y', (d) => y_scale(d[1]))
      .attr('width', x_scale.bandwidth() )
      .attr('height', y_scale.bandwidth() )
      .style('fill', (d) => color_scale(d[2]));
  }

}
