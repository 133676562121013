import { Component, OnInit } from '@angular/core';
import { Speciality } from '@models/specialities/speciality';
import { LibraryItemProps } from '@components/common/library-item/library-item.component';
import { AsyncList } from '@rest/AsyncList';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SpecialityGroupHttpService } from '@services/http/SpecialityGroupHttpService';
import { SpecialityGroup } from '@models/specialities/speciality-group';

@Component({
  selector: 'app-library-speciality-group-list',
  templateUrl: './speciality-group-list.component.html',
  styleUrls: ['./speciality-group-list.component.css'],
})
export class SpecialityGroupListComponent implements OnInit {
  specialityGroups: AsyncList<SpecialityGroup>;
  showMoreMap = {};

  constructor(
    private _specialityGroupHttpService: SpecialityGroupHttpService,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this.specialityGroups = new AsyncList(this._specialityGroupHttpService);
    this.specialityGroups.load();
  }

  serializeSpeciality(speciality: Speciality): LibraryItemProps {
    return {
      header: 'Специальность',
      content: speciality.name,
      footer: speciality.description,
      linkUrl: `/library/specialities/${speciality.uuid}`,
    };
  }

  isAdmin(): boolean {
    return this._authService.is_admin();
  }

  showMore(uuid: string): void {
    this.showMoreMap[uuid] = true;
  }

  isShowMore(specialityGroup: SpecialityGroup): boolean {
    return (
      specialityGroup.specialities.length > 3 &&
      !this.showMoreMap[specialityGroup.uuid]
    );
  }
}
