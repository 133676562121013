import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root'
})
export class SentryCheckService {
  private logging_prefix = 'SentryCheckService'

  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService,
  ) {}

  public run(): Observable<any> {
    return this.api_service.get(`sentry_check`, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.logging_prefix} sentry check fail`);
        },
        err => {
          this.logging_service.error(`${this.logging_prefix} sentry check success`);
        }
      ));
  }
}
