<div class="app-common-specialty-radar-container">
  <div class="{{ html_element_class }}"></div>
  <div class="app-common-specialty-radar-debug" (click)="onDebug()">.</div>
</div>
<div fxLayout="row" fxLayoutAlign="center center">
  <mat-checkbox
    [checked]="showGrades"
    (change)="showHideGrades($event.checked)"
  >Вкл/выкл отображение уровней</mat-checkbox>
</div>
