<div class="router-outlet-container">
  <ng-container *ngIf="is_data_loaded; then component_content; else preloader">
  </ng-container>

  <div
    #tooltip_delete_grade
    class="app-admin-speciality-details-grade-delete-tooltip"
    style="display: none; opacity: 0"
    (mouseleave)="on_button_grade_delete_hide_tooltip()"
  >
    <div class="app-admin-speciality-details-grade-delete-tooltip-header">
      Удалить грейд?
    </div>
    <div class="app-admin-speciality-details-grade-delete-tooltip-description">
      Пользователям с этим грейдом нужно будет назначить что-то другое
    </div>
    <div fxLayout="row">
      <div fxFlex fxLayoutAlign="space-around center">
        <button
          mat-raised-button
          color="warn"
          [disabled]="is_button_grade_tooltip_delete_disabled"
          (click)="on_tooltip_grade_delete_confirm()"
        >
          Удалить
        </button>

        <button mat-raised-button (click)="on_tooltip_grade_delete_cancel()">
          Отмена
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #preloader>
  <app-preloader></app-preloader>
</ng-template>

<ng-template #component_content>
  <div class="app-admin-speciality-details-container">
    <ng-container
      [ngTemplateOutlet]="
        is_header_in_edit_mode ? tmpl_header_edit_mode : tmpl_header
      "
    >
    </ng-container>

    <div class="app-admin-speciality-details-content-container">
      <mat-tab-group animationDuration="0ms">
        <mat-tab label="Пользователи">
          <div class="app-admin-speciality-details-content-tab-container">
            <ng-container
              *ngIf="
                is_user_add_form_shown;
                then tmpl_user_add;
                else tmpl_user_list
              "
            >
            </ng-container>
          </div>
        </mat-tab>

        <mat-tab label="Грейды">
          <div class="app-admin-speciality-details-content-tab-container">
            <ng-container [ngTemplateOutlet]="tmpl_tab_grades"> </ng-container>
          </div>
        </mat-tab>

        <mat-tab label="Профиль компетенций">
          <div class="app-admin-speciality-details-content-tab-container">
            <ng-template matTabContent>
              <app-admin-speciality-profile
                [speciality]="speciality"
                [grades]="speciality_grades"
              >
              </app-admin-speciality-profile>
            </ng-template>
          </div>
        </mat-tab>

        <mat-tab label="Опросы">
          <div class="app-admin-speciality-details-content-tab-container">
            <ng-container
              *ngIf="
                is_survey_create_relation_form_shown;
                then tmpl_survey_relation_create;
                else tmpl_survey_relation_list
              "
            >
            </ng-container>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_user_list>
  <div
    class="app-admin-speciality-details-user-add-container"
    (click)="on_show_user_add_form()"
  >
    <div fxLayout="row">
      <div fxFlex="5rem">
        <div class="app-admin-speciality-details-user-add-avatar">+</div>
      </div>
      <div fxFlex="30rem">
        <div class="app-admin-speciality-details-user-add-text">
          Назначить специальность
        </div>
      </div>
    </div>
  </div>
  <div class="app-admin-speciality-details-user-list-container">
    <div *ngFor="let relation of speciality_user_relations">
      <div class="app-admin-speciality-details-user-listitem">
        <div fxLayout="row">
          <div fxFlex="5rem">
            <div class="app-admin-speciality-details-user-avatar">
              {{ util_user_get_initials_for_avatar(relation.user) }}
            </div>
          </div>
          <div fxFlex="30rem">
            <div class="app-admin-speciality-details-user-name">
              <a routerLink="/profile/{{ relation.user.uuid }}">
                {{ relation.user.first_name }} {{ relation.user.last_name }}
              </a>
            </div>
            <div class="app-admin-speciality-details-user-email">
              {{ relation.user.email }}
            </div>
          </div>
          <div fxFlex>
            <div class="app-admin-speciality-details-user-joined">
              Назначена:
              {{ relation.date_created | amDateFormat: 'YYYY-MM-DD HH:mm' }}
            </div>
          </div>
          <div fxFlex="5rem">
            <button
              mat-icon-button
              (click)="on_button_user_delete_submitted(relation)"
              [disabled]="!is_button_user_delete_enabled"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_user_add_search>
  <app-search-select-user
    (userSelected)="on_user_search_selected($event)"
  ></app-search-select-user>
</ng-template>

<ng-template #tmpl_user_add>
  <div>
    <ng-container
      *ngIf="
        user_search_selected_user;
        then tmpl_user_add_user_selected;
        else tmpl_user_add_search
      "
    >
    </ng-container>
  </div>

  <button
    mat-stroked-button
    (click)="on_button_user_add_submitted()"
    [disabled]="!is_button_user_add_enabled"
  >
    <mat-icon>person_add</mat-icon>
    Назначить
  </button>

  &nbsp;

  <button mat-stroked-button (click)="on_close_user_add_form()">
    <mat-icon>close</mat-icon>
    Отмена
  </button>
</ng-template>

<ng-template #tmpl_user_add_user_selected>
  <div class="app-admin-speciality-details-user-listitem">
    <div fxLayout="row">
      <div fxFlex="5rem">
        <div class="app-admin-speciality-details-user-avatar">
          {{ util_user_get_initials_for_avatar(user_search_selected_user) }}
        </div>
      </div>
      <div fxFlex="30rem">
        <div class="app-admin-speciality-details-user-name">
          {{ user_search_selected_user.first_name }}
          {{ user_search_selected_user.last_name }}
        </div>
        <div class="app-admin-speciality-details-user-email">
          {{ user_search_selected_user.email }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_survey_relation_list>
  <div
    class="app-admin-speciality-details-surveys-relation-create-show"
    (click)="on_show_create_survey_relation_form()"
  >
    <div fxLayout="row">
      <div fxFlex="5rem">
        <div class="app-admin-speciality-details-user-add-avatar">+</div>
      </div>
      <div fxFlex="30rem">
        <div class="app-admin-speciality-details-user-add-text">
          Связать с опросом
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!survey_relations.length">
    <div class="app-admin-speciality-details-surveys-empty">
      Нет связанных опросов
    </div>
  </div>

  <div *ngIf="survey_relations.length">
    <div class="app-admin-speciality-details-surveys-list">
      <div *ngFor="let relation of survey_relations">
        <div class="app-admin-speciality-details-surveys-listitem">
          <div fxLayout="row">
            <div fxFlex>
              <a routerLink="/admin/surveys/survey/{{ relation.survey.uuid }}">
                {{ relation.survey.name }}
              </a>
            </div>
            <div fxFlex="3rem">
              <button
                mat-icon-button
                (click)="on_button_delete_survey_relation(relation)"
              >
                <mat-icon color="warn">close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_survey_relation_create>
  <div class="app-admin-speciality-details-surveys-relation-create">
    <mat-form-field [style.width.rem]="30">
      <mat-label>Выберите опрос для специальности</mat-label>
      <mat-select [(ngModel)]="selected_relation_selected_survey">
        <mat-option *ngFor="let survey of surveys" [value]="survey">{{
          survey.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <br />

    <button
      mat-stroked-button
      (click)="on_button_create_survey_relation()"
      [disabled]="
        !selected_relation_selected_survey ||
        is_survey_create_relation_button_disabled
      "
    >
      <mat-icon>add_link</mat-icon>
      Связать
    </button>
  </div>
</ng-template>

<ng-template #tmpl_header>
  <div class="app-admin-speciality-details-header-container">
    <div fxLayout="row">
      <div fxFlex>
        <div class="app-admin-speciality-details-header-name">
          {{ speciality.name }}
        </div>
        <div class="app-admin-speciality-details-header-description">
          {{ speciality.description }}
        </div>
        <div class="app-admin-speciality-details-header-team">
          <div *ngIf="speciality.team">Команда: {{ speciality.team.name }}</div>
          <div *ngIf="!speciality.team">Не привязана к команде</div>
        </div>
      </div>
      <div fxFlex="15em">
        <div>
          <div
            class="app-admin-speciality-details-header-controls"
            fxLayout="row-reverse"
            fxLayoutGap="0.5rem"
          >
            <button
              mat-stroked-button
              [disabled]="!is_button_toggle_hidden_enabled"
              (click)="on_button_toggle_speciality_hidden()"
            >
              <mat-icon>
                {{ speciality.is_hidden ? 'visibility_off' : 'visibility' }}
              </mat-icon>
            </button>
            <button mat-stroked-button (click)="on_button_toggle_edit_mode()">
              <mat-icon>edit</mat-icon>
              Редактировать
            </button>
          </div>
          <div class="app-admin-speciality-details-header-metadata">
            Cоздана:
            {{ speciality.date_created | amDateFormat: 'YYYY-MM-DD HH:mm' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_header_edit_mode>
  <div class="app-admin-speciality-details-header-container">
    <div fxLayout="row">
      <div fxFlex>
        <form
          [formGroup]="speciality_edit_form"
          fxLayout="column"
          fxLayoutGap="1rem"
        >
          <mat-form-field>
            <mat-label>Название</mat-label>
            <input
              #input_field_name
              matInput
              placeholder="Название*"
              autocomplete="off"
              [value]="speciality.name"
              formControlName="speciality_name"
            />
            <mat-hint>{{ 128 - input_field_name.value.length }}/128</mat-hint>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Описание</mat-label>
            <textarea
              #input_field_description
              matInput
              placeholder="Описание*"
              formControlName="speciality_description"
              >{{ speciality.description }}</textarea
            >
            <mat-hint
              >{{ 1024 - input_field_description.value.length }}/1024</mat-hint
            >
          </mat-form-field>
          <ng-select
            [items]="teamsOptions"
            (search)="searchTeams($event.term)"
            [multiple]="false"
            [closeOnSelect]="true"
            [clearable]="false"
            notFoundText="Команды не найдены"
            placeholder="Начните набирать название для поиска"
            formControlName="team"
            bindLabel="name"
          ></ng-select>
        </form>
      </div>
      <div fxFlex="25" fxLayoutAlign="space-around center">
        <a
          mat-raised-button
          [disabled]="speciality_edit_form.invalid"
          (click)="save_edit_form()"
        >
          Сохранить
        </a>
        <a mat-raised-button (click)="reset_edit_mode()"> Отмена </a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_tab_grades>
  <ng-container
    *ngIf="
      is_create_grade_from_shown;
      then tmpl_tab_grade_create_form;
      else tmpl_tab_grade_create_button
    "
  >
  </ng-container>

  <div fxLayout="row">
    <div fxFlex>
      <div *ngIf="!speciality_grades.length">
        <div class="app-admin-speciality-details-tab-grades-empty">
          Для этой специальности не создано грейдов
        </div>
      </div>

      <div *ngIf="speciality_grades.length">
        <div class="app-admin-speciality-details-tab-grades-list-container">
          <div *ngFor="let grade of speciality_grades">
            <ng-container
              [ngTemplateOutlet]="
                grade_currently_edited &&
                grade.uuid === grade_currently_edited.uuid
                  ? grade_listitem_edit
                  : grade_listitem
              "
              [ngTemplateOutletContext]="{ grade: grade }"
            >
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_tab_grade_create_button>
  <div
    class="app-admin-speciality-details-tab-grades-header-container"
    (click)="on_button_show_create_grade()"
  >
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="2rem">
        <div class="app-admin-speciality-details-tab-grades-header-create-icon">
          <mat-icon>add</mat-icon>
        </div>
      </div>
      <div fxFlex="15rem">
        <div class="app-admin-speciality-details-tab-grades-header-create-text">
          Добавить грейд
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_tab_grade_create_form>
  <div class="app-admin-speciality-details-tab-grades-create-form-container">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div
        fxFlex="35rem"
        class="app-admin-speciality-details-tab-grades-create-form-input-container"
      >
        <input
          #grade_create_input
          autofocus
          matInput
          placeholder="Название грейда"
          required
          autocomplete="off"
          maxlength="128"
          (keyup.enter)="
            on_button_grade_create_submit(grade_create_input.value)
          "
          (keyup.escape)="on_button_grade_create_cancel()"
          class="app-admin-speciality-details-tab-grades-create-form-input"
        />
        <div
          class="app-admin-speciality-details-tab-grades-create-form-input-dash"
          [class.warn]="grade_create_input.value == ''"
        ></div>
      </div>
      <span
        fxFlex="25"
        class="app-admin-speciality-details-tab-grades-create-form-input-length"
        [class.warn]="grade_create_input.value == ''"
      >
        {{ 128 - grade_create_input.value.length }}/128
      </span>

      <div
        fxFlex="25"
        fxLayoutAlign="end center"
        class="app-admin-speciality-details-tab-grades-create-buttons-container"
      >
        <button
          mat-raised-button
          (click)="on_button_grade_create_submit(grade_create_input.value)"
          [disabled]="
            grade_create_input.value == '' ||
            is_button_grade_create_submit_disabled
          "
        >
          Добавить
        </button>
        &nbsp;
        <button mat-raised-button (click)="on_button_grade_create_cancel()">
          Отмена
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #grade_listitem let-grade="grade">
  <div class="app-admin-speciality-details-tab-grades-listitem-container">
    <div fxLayout="row">
      <div fxFlex="35rem" fxLayoutAlign="start center">
        {{ grade.name }}
      </div>
      <div fxFlex fxLayoutAlign="end center">
        <div
          class="app-admin-speciality-details-tab-grades-listitem-buttons-container"
        >
          <button mat-icon-button (click)="on_button_grade_edit(grade)">
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="on_button_grade_delete_show_tooltip(grade, $event)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #grade_listitem_edit let-grade="grade">
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    class="app-admin-speciality-details-tab-grades-listitem-container edit-template"
  >
    <div
      fxFlex="35rem"
      class="app-admin-speciality-details-tab-grades-listitem-edit-input-container"
    >
      <input
        #grade_edit_input
        autofocus
        matInput
        placeholder="Название грейда"
        required
        autocomplete="off"
        value="{{ grade.name }}"
        maxlength="128"
        (keyup.enter)="on_button_grade_edit_save(grade, grade_edit_input.value)"
        (keyup.escape)="on_button_grade_edit_cancel()"
        class="app-admin-speciality-details-tab-grades-listitem-edit-input"
      />
      <div
        class="app-admin-speciality-details-tab-grades-listitem-edit-input-dash"
        [class.warn]="grade_edit_input.value == ''"
      ></div>
    </div>

    <span
      fxFlex="25"
      class="app-admin-speciality-details-tab-grades-listitem-edit-input-length"
      [class.warn]="grade_edit_input.value == ''"
    >
      {{ 128 - grade_edit_input.value.length }}/128
    </span>

    <div
      fxFlex="25"
      fxLayoutAlign="end center"
      class="app-admin-speciality-details-tab-grades-listitem-edit-buttons-container"
    >
      <button
        mat-raised-button
        (click)="on_button_grade_edit_save(grade, grade_edit_input.value)"
        [disabled]="
          grade_edit_input.value == '' || is_button_grade_edit_save_disabled
        "
      >
        Сохранить
      </button>
      &nbsp;
      <button mat-raised-button (click)="on_button_grade_edit_cancel()">
        Отмена
      </button>
    </div>
  </div>
</ng-template>
