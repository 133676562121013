import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ViewSetHttpService } from './rest/ViewSetHttpService';

import { SpecialityDomainClaim } from '@models/specialities/speciality-domain-claim';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpecialityDomainClaimHttpService extends ViewSetHttpService<SpecialityDomainClaim> {
  http: HttpClient;

  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'specialities/domainclaim';

  public reorder(data: {
    moved_uuid: string;
    target_uuid: string;
  }): Observable<any> {
    return this.http.post<{ moved_uuid: string; target_uuid: string }>(
      `${this.getUrl()}/reorder`,
      data
    );
  }
}
