import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '@models/user/user';

@Component({
  selector: 'app-review-create-responsible-edit-dialog',
  templateUrl: './review-create-responsible-edit-dialog.component.html',
  styleUrls: ['./review-create-responsible-edit-dialog.component.css'],
})
export class ReviewCreateResponsibleEditDialogComponent implements OnInit {
  responsible: User;
  constructor(
    public dialogRef: MatDialogRef<ReviewCreateResponsibleEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      owners: User[];
      onSubmit(responsible: User): void;
    }
  ) {}

  ngOnInit(): void {}

  onSelectResponsible(responsible: User): void {
    this.responsible = responsible;
  }

  submit(): void {
    this.data.onSubmit(this.responsible);
    this.dialogRef.close();
  }
}
