import { Component, Input, OnInit } from '@angular/core';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Domain } from '@models/ontology/domain';

@Component({
  selector: 'app-parent-domain',
  templateUrl: './parent-domain.component.html',
  styleUrls: ['./parent-domain.component.css'],
})
export class ParentDomainComponent implements OnInit {
  @Input() domainUuid = '';
  @Input() isClickable = true;
  domain: AsyncDetail<Domain>;
  constructor(private _domainHttpService: DomainHttpService) {}

  ngOnInit(): void {
    this.domain = new AsyncDetail(this.domainUuid, this._domainHttpService);
    this.domain.load();
  }
}
