import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AsyncList } from '@rest/AsyncList';
import { SpecialityCompetenceClaim } from '@models/specialities/speciality-competence-claim';
import { Thing } from '@models/ontology/thing';
import { Domain } from '@models/ontology/domain';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SpecialityCompetenceClaimHttpService } from '@services/http/SpecialityCompetenceClaimHttpService';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { ThingHttpService } from '@services/http/ThingHttpService';
import { SpecialityDomainClaim } from '@models/specialities/speciality-domain-claim';
import { SpecialityDomainClaimHttpService } from '@services/http/SpecialityDomainClaimHttpService';

@Component({
  selector: 'app-select-library-things',
  templateUrl: './select-library-things.component.html',
  styleUrls: ['./select-library-things.component.css'],
})
export class SelectLibraryThingsComponent implements OnInit {
  @Input() specialityUuid: string;
  @Input() selectedClaims: string[] = [];
  @Input() selectAllNodes = true;
  @Output() selectedClaimsChange: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  competenciesForm: UntypedFormGroup;

  domains: AsyncList<Domain>;
  specialityDomainClaims: AsyncList<SpecialityDomainClaim>;
  specialityCompetenceClaims: AsyncList<SpecialityCompetenceClaim>;

  libraryThings: AsyncList<Thing>;

  things: Thing[] = [];
  filteredThings: Thing[];

  processingFilteredThings = false;
  libraryThingsReady = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _specialityDomainClaimHttpService: SpecialityDomainClaimHttpService,
    private _specialityCompetenceClaimHttpService: SpecialityCompetenceClaimHttpService,
    private _domainHttpService: DomainHttpService,
    private _thingHttpService: ThingHttpService
  ) {
    this.domains = new AsyncList<Domain>(this._domainHttpService);
    this.specialityDomainClaims = new AsyncList<SpecialityDomainClaim>(
      this._specialityDomainClaimHttpService
    );
  }

  ngOnInit(): void {
    this.competenciesForm = this._formBuilder.group({
      search: ['', []],
    });
    if (this.specialityUuid) {
      this._loadDomainClaims();
    } else {
      this._loadDomains();
    }

    this._loadThings();
  }

  private _loadDomainClaims(): void {
    this.specialityDomainClaims.setRequestParams({
      params: {
        speciality: this.specialityUuid,
        expand: 'domain',
      },
    });
    this.specialityDomainClaims.load();
  }

  private _loadDomains(): void {
    this.domains.setRequestParams({
      params: {
        team__isnull: 'True',
      },
    });
    this.domains.load();
  }

  private _loadThings(): void {
    if (this.specialityUuid) {
      this.specialityCompetenceClaims =
        new AsyncList<SpecialityCompetenceClaim>(
          this._specialityCompetenceClaimHttpService
        );
      this.specialityCompetenceClaims.setRequestParams({
        params: {
          speciality: this.specialityUuid,
          expand: 'thing',
        },
      });
      this.specialityCompetenceClaims.load().subscribe(() => {
        this.specialityCompetenceClaims.state.items.forEach(
          (competenceClaim: SpecialityCompetenceClaim<string>) => {
            if (
              !this.things.find((thing) => {
                return thing.uuid === competenceClaim.thing.uuid;
              })
            ) {
              this.things.push(competenceClaim.thing);
            }
          }
        );
        this.filteredThings = this.things;
      });
    } else {
      this.libraryThings = new AsyncList<Thing>(this._thingHttpService);
      this.libraryThings.setRequestParams({
        params: {
          team__isnull: 'True',
        },
      });
      this.libraryThings.load().subscribe(() => {
        this.things = this.libraryThings.state.items;
        this.filteredThings = this.things;
        this.libraryThingsReady = true;
      });
    }
  }

  searchThing(): void {
    this.processingFilteredThings = true;
    this.filteredThings = this.things.filter((thing) =>
      thing.name
        .toLowerCase()
        .includes(this.competenciesForm.controls.search.value.toLowerCase())
    );
    setTimeout(() => {
      this.processingFilteredThings = false;
    }, 100);
  }

  onSelectedClaimsChange(selectedClaims: string[]): void {
    setTimeout(() => {
      this.selectedClaimsChange.emit(selectedClaims);
    }, 100);
  }
}
