<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="domains-tree"
  fxLayout="column"
>
  <mat-tree-node
    *matTreeNodeDef="let node"
    matTreeNodePadding
    fxFlex
    fxLayout="row"
  >
    <button mat-icon-button disabled></button>
    <div
      *ngIf="node.isThing"
      class="thing-item"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <a
        class="thing-link"
        [routerLink]="['/things/', node.uuid]"
        fxFlex="50"
        >{{ node.name }}</a
      >
      <div fxFlex="40" fxLayout="column">
        <div
          *ngFor="let thingGrade of thingGradeMap[node.uuid]"
          fxFlex
          fxLayout="row"
          fxFill="space-between"
          class="grade-level"
          [class.user-level-match]="
            isUserLevelMatchesGrade(
              thingGrade.thingLevel.order_number,
              node.uuid
            )
          "
        >
          <span fxFlex>{{ thingGrade.grade.name }}</span>
          <span fxFlex>{{ thingGrade.thingLevel.title }}</span>
        </div>
      </div>
      <span
        *ngIf="isEditable"
        class="thing-icons"
        fxFlex="10"
        fxLayoutAlign="end center"
      >
        <button
          mat-icon-button
          (click)="setDetailFormConfig(node.uuid, 'update', true)"
          matTooltip="Редактировать"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="remove(node)" matTooltip="Удалить">
          <mat-icon>delete</mat-icon>
        </button>
      </span>
    </div>
    <div *ngIf="!node.isThing" class="domain-item">
      <a class="domain-link">{{ node.name }}</a>
      <span *ngIf="isEditable" class="domain-icons">
        <button
          mat-icon-button
          matTooltip="Добавить компетенцию в домен"
          (click)="setDetailFormConfig(node.uuid, 'create', true)"
        >
          <mat-icon>add</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="setDetailFormConfig(node.uuid, 'create', false)"
          matTooltip="Добавить дочерний домен"
        >
          <mat-icon>create_new_folder</mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="domainMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #domainMenu="matMenu">
          <button
            mat-menu-item
            (click)="setDetailFormConfig(node.uuid, 'update', false)"
          >
            <mat-icon>edit</mat-icon>
            Редактировать
          </button>
          <button mat-menu-item (click)="remove(node)">
            <mat-icon>delete</mat-icon>
            Удалить домен
          </button>
        </mat-menu>
      </span>
    </div>
  </mat-tree-node>
  <mat-tree-node
    *matTreeNodeDef="let node; when: hasChild"
    matTreeNodePadding
    fxFlex
    fxLayout="row"
  >
    <div
      class="domain-item"
      [ngClass]="treeControl.isExpanded(node) ? 'domain-item_expanded' : ''"
      fxLayout="row"
      fxFlex
      fxLayoutAlign="start center"
    >
      <button
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <a
        class=".domain-item__name"
        (click)="expandDomain(node.uuid)"
        fxFlex="90"
        >{{ node.name }}</a
      >
      <span
        *ngIf="isEditable"
        class="domain-item__icons"
        fxFlex="10"
        fxLayoutAlign="end center"
      >
        <button
          mat-icon-button
          (click)="setDetailFormConfig(node.uuid, 'create', true)"
          matTooltip="Добавить компетенцию в домен"
        >
          <mat-icon>add</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="setDetailFormConfig(node.uuid, 'create', false)"
          matTooltip="Добавить дочерний домен"
        >
          <mat-icon>create_new_folder</mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="domainMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #domainMenu="matMenu">
          <button
            mat-menu-item
            (click)="setDetailFormConfig(node.uuid, 'update', false)"
          >
            <mat-icon>edit</mat-icon>
            Редактировать
          </button>
          <button mat-menu-item (click)="remove(node)">
            <mat-icon>delete</mat-icon>
            Удалить домен
          </button>
        </mat-menu>
      </span>
    </div>
  </mat-tree-node>
</mat-tree>
