<div
  class='speciality-grades-container'
  *ngIf='
    specialityGrades?.state.isLoaded &&
    speciality?.state.isLoaded &&
    specialityValidation?.state.isLoaded
  '
>
  <div fxLayout='row'>
    <div fxFlex>
      <div class='title'>{{ speciality.state.item.name }}</div>
    </div>
    <div fxFlex fxLayoutAlign='end start' fxLayoutGap='8px'>
      <button
        mat-raised-button
        *ngIf='isAdmin && !specialityValidation.state.items.length'
        (click)='openCreateValidationDialog()'
      >
        Отправить на верификацию
      </button>
      <button
        mat-stroked-button
        *ngIf='isAdmin && specialityValidation.state.items.length'
        (click)='redirectToValidation()'
        [disabled]='specialityValidation.state.items[0]?.status === 3'
      >
        <mat-icon
          *ngIf='specialityValidation.state.items[0]?.status === 0'
          [style.color]="'#757272'"
        >edit
        </mat-icon
        >
        <mat-icon
          *ngIf='specialityValidation.state.items[0]?.status === 1'
          [style.color]="'green'"
        >check
        </mat-icon
        >
        <mat-icon
          *ngIf='specialityValidation.state.items[0]?.status === 2'
          [style.color]="'red'"
        >close
        </mat-icon
        >
        {{
        specialityValidation.state.items[0]?.status === 1
          ? 'Верификация пройдена'
          : specialityValidation.state.items[0]?.status === 2
            ? 'Верификация отклонена'
            : specialityValidation.state.items[0]?.status === 3
              ? 'Добавлена в библиотеку'
              : 'Верификация в процессе'
        }}
      </button>
      <button mat-icon-button (click)='onSpecialityEdit(speciality.state.item)'>
        <mat-icon>edit</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)='onSpecialityDelete(speciality.state.item)'
      >
        <mat-icon>delete_outline</mat-icon>
      </button>
    </div>
  </div>
  <div class='speciality-desc'>{{ speciality.state.item.description }}</div>
  <div>
    <mat-tab-group [animationDuration]="'0ms'" mat-stretch-tabs='false' (selectedIndexChange)='onTabChange($event)'>
      <mat-tab label='Профиль компетенций'>
        <div fxLayout='row'>
          <div fxFlex fxLayout='row' fxLayoutAlign='start center'>
            <div
              class='add-competence-button-container'
              (click)='onCompetenceFromLibraryAdd()'
            >
              <button
                mat-raised-button
                class='add-competence-button'
                color='primary'
              >
                <mat-icon>add_circle_outline</mat-icon>
              </button>
              <span class='add-competence-button-label'
              >Добавить компетенцию в профиль</span
              >
            </div>
          </div>
          <div fxFlex fxLayout='row' fxLayoutAlign='end center'>
            <div class='auto-level-button-container'
                 (click)='onCompetenciesAutoLevel()'>
              <button
                mat-raised-button
                class='add-competence-button'
                color='primary'
              >
                <mat-icon svgIcon='custom_library_add_check'></mat-icon>
              </button>
              <span class='add-competence-button-label'
              >Проставить уровни владения навыком</span
              >
            </div>
          </div>
        </div>
        <div *ngIf='!thingGradeLevelsMapReady'>
          <mat-progress-bar mode='indeterminate'></mat-progress-bar>
        </div>
        <app-with-processing
          [processing]='
            specialityDomainClaims?.state.isProcessing &&
            specialityCompetenceClaims?.state.isProcessing &&
            !thingGradeLevelsMapReady
          '
        >
          <app-domain-thing-tree
            *ngIf='
              specialityDomainClaims?.state.isLoaded &&
              specialityCompetenceClaims?.state.isLoaded &&
              thingGradeLevelsMapReady
            '
            [specialityUuid]='specialityUuid'
            [specialityDomainsClaims]='specialityDomainClaims.state.items'
            [things]='things'
            [thingGradeLevelsMap]='thingGradeLevelsMap'
            [specialityCompetenceClaims]='specialityCompetenceClaims'
            [expandFirstNode]='true'
          ></app-domain-thing-tree>
        </app-with-processing>
      </mat-tab>
      <mat-tab label='Уровень специалистов'>
        <div class='empty-space'></div>
        <div
          *ngFor='let specialityGrade of specialityGrades.state.items'
          class='grade-container'
          fxLayout='row'
        >
          <div fxLayout='column' fxFlex='90'>
            <div fxLayout='row' fxLayoutAlign='start center'>
              <span class='grade-name'>{{ specialityGrade.name }}</span>
            </div>
            <div fxLayout='row' fxLayoutAlign='start center'>
              <span class='grade-desc'>{{ specialityGrade.description }}</span>
            </div>
          </div>
          <!--          <button mat-icon-button (click)="onGradeEdit(specialityGrade)">-->
          <!--            <mat-icon fxFlex="5">edit</mat-icon>-->
          <!--          </button>-->
          <!--          <button mat-icon-button (click)="onGradeDelete(specialityGrade)">-->
          <!--            <mat-icon fxFlex="5">delete_outline</mat-icon>-->
          <!--          </button>-->
        </div>
        <!-- <div fxLayout="row">
          <div
            fxLayoutAlign="start center"
            class="add-grade-button-container"
            (click)="onGradeAdd()"
          >
            <button
              mat-raised-button
              class="add-grade-button"
              fxLayoutAlign="center center"
            >
              <mat-icon>add_circle_outline</mat-icon>
            </button>
            <span class="add-grade-button-label">Добавить уровень позиции</span>
          </div>
        </div> -->
      </mat-tab>
      <mat-tab>
        <ng-template matTabLabel>
          <span>Журнал изменений</span>
          <span *ngIf='isTeamOwner'
                matBadge='!'
                matBadgeSize='small'
                [matBadgeHidden]='notViewedEventsCount === 0'
                matBadgeOverlap='false'
          >
          </span>
        </ng-template>
        <div *ngIf='!specialityEvents.state.items.length' [style.margin-top.px]='20' fxLayoutAlign='center center'>
          Для этой специальности пока нет изменений
        </div>
        <div fxLayout='column' fxLayoutGap='12px' [style.margin-top.px]='20'
             *ngIf='specialityEvents.state.items.length'>
          <div fxFlex fxLayout='row' fxLayoutAlign='start center'>
            <span fxFlex='60'>Событие</span>
            <span fxFlex='20'>Инициатор</span>
            <span fxFlex='10'>Дата</span>
          </div>
          <div *ngFor='let event of specialityEvents.state.items' fxLayout='row' fxLayoutAlign='start center' fxFlex>
            <span fxFlex='60'>{{ event.text }}</span>
            <span fxFlex='20'>{{ getCreatorText(event) }}</span>
            <span fxFlex='10'>{{ getDateStr(event.date_created) }}</span>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
