<ng-container *ngIf="is_data_loaded; then tmpl_tab_content; else tmpl_preloader">
</ng-container>

<ng-template #tmpl_preloader>
    <app-preloader-progressbar></app-preloader-progressbar>
</ng-template>

<ng-template #tmpl_tab_content>
    <div fxLayout="row">
        <div fxFlex>

            <div *ngIf="speciality_relations.length === 0">
                <div class="app-admin-users-user-tab-specialities-empty">
                    На пользователя не назначено специальностей
                </div>
            </div>

            <div *ngIf="speciality_relations.length > 0">

                <div *ngFor="let relation of speciality_relations">
                    <div class="app-admin-users-user-tab-specialities-listitem-container">

                        <div fxLayout="row">
                            <div fxFlex fxLayoutAlign="start center">
                                <div class="app-admin-users-user-tab-specialities-listitem-name">
                                    <a routerLink="/admin/specialities/speciality/{{ relation.speciality.uuid }}">
                                        {{ relation.speciality.name }}
                                    </a>
                                </div>
                            </div>
                            <div fxFlex="20rem" fxLayoutAlign="start center">
                                <div class="app-admin-users-user-tab-specialities-listitem-grade">
                                    <div *ngIf="relation.grade">
                                        грейд: {{ relation.grade.name }}
                                    </div>
                                    <div *ngIf="!relation.grade">
                                        грейд не указан
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="3rem">
                                <button mat-icon-button (click)="on_button_delete_speciality_relation(relation)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</ng-template>