<div class="dialog-container">
  <div class="title">Редактирование данных участника</div>
  <div fxLayout="row">
    <div fxFlex="50">
      <form [formGroup]="form">
        <ng-select
          class="search-field"
          [items]="roleOptions"
          [multiple]="false"
          [closeOnSelect]="true"
          [clearable]="false"
          placeholder="Роль участника"
          formControlName="role"
          bindLabel="label"
        >
        </ng-select>
      </form>
    </div>
    <div fxFlex="50" fxLayoutAlign="end center">
      <button mat-raised-button (click)="removeFromTeam()">
        <mat-icon class="remove-member-icon"> delete </mat-icon>
        Удалить из команды
      </button>
    </div>
  </div>
  <div>Выберите специальность из доступных в команде</div>
  <div class="specialities-container specialities-container-all">
    <div
      *ngIf="data.teamSpecialities.length === 0"
      class="speciality-container-empty"
    >
      В команде пока нет специальностей
    </div>
    <div
      *ngFor="let speciality of data.teamSpecialities"
      class="speciality-container"
      fxLayout="row"
      fxLayoutAlign="start center"
      (click)="selectSpeciality(speciality)"
    >
      <span fxFlex>{{ speciality.name }}</span>
      <mat-icon fxFlex="3rem" class="speciality-select-icon"
        >add_circle_outline</mat-icon
      >
    </div>
  </div>
  <div>Специальности пользователя</div>
  <div
    class="specialities-container specialities-container-selected"
    *ngIf="!isDataLoading"
  >
    <div
      *ngIf="data.userSpecialitiesRelations.length === 0"
      class="speciality-container-empty"
    >
      У пользователя пока нет специальностей, выберите из списка
    </div>
    <div
      *ngFor="let relation of data.userSpecialitiesRelations"
      class="speciality-container"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <span fxFlex>{{ relation.speciality.name }}</span>
      <!-- <span fxFlex>{{ relation.grade?.name }}</span> -->
      <form fxFlex="15rem">
        <ng-select
          class="speciality-grade-select"
          [items]="getSpecialityGrades(relation.speciality.uuid)"
          [(ngModel)]="userSpecialityCurrentGrade[relation.speciality.uuid]"
          [ngModelOptions]="{ standalone: true }"
          [multiple]="false"
          [closeOnSelect]="true"
          [clearable]="false"
          appendTo="body"
          placeholder="Выберите уровень позиции"
          bindLabel="name"
          (change)="onSpecialityGradeChange(relation, $event)"
        >
        </ng-select>
      </form>
      &nbsp; &nbsp;
      <mat-icon
        (click)="deselectSpeciality(relation)"
        fxFlex="3rem"
        class="speciality-select-icon"
        >cancel</mat-icon
      >
    </div>
  </div>

  <div mat-dialog-actions fxLayoutAlign="end center">
    <button
      class="dialog-accept-button"
      [mat-dialog-close]="true"
      mat-raised-button
      (click)="close()"
    >
      Сохранить
    </button>
  </div>
</div>
