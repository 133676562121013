import { Domain } from '@models/ontology/domain';
import { ChartInputRadarData } from '@components/ui/chart-radar/chart-radar.component';
import { Thing } from '@models/ontology/thing';
import { ThingLevel } from '@models/ontology/thing-level';
import { SpecialityGrade } from '@models/specialities/speciality-grade';
import { SpecialityCompetenceClaim } from '@models/specialities/speciality-competence-claim';

export interface DomainThing {
  domain: string;
  thing: Thing;
  thing_level: ThingLevel;
}

export const computeAxeValues = (
  domains: Domain[],
  chartDomains: Domain[],
  domainsThings: DomainThing[] | SpecialityCompetenceClaim[],
  specialityGrade: SpecialityGrade = null
): ChartInputRadarData => {
  const axes = [];
  let levels = [];
  chartDomains.forEach((chartDomain: Domain) => {
    const domainThings = [];
    if (domainsThings.length > 0) {
      if (specialityGrade) {
        getDomainThingsRecursiveForGrades(
          domains,
          [chartDomain.uuid],
          domainsThings as SpecialityCompetenceClaim[],
          domainThings
        );
      } else {
        getDomainThingsRecursive(
          domains,
          [chartDomain.uuid],
          domainsThings as DomainThing[],
          domainThings
        );
      }
    }

    if (domainThings.length > 0) {
      let domainLevelSum = 0;
      domainThings.forEach((domainThing) => {
        if (domainThing.thing_level) {
          domainLevelSum += domainThing.thing_level.order_number;
          if (domainThing.thing.levels.length > levels.length)
            levels = domainThing.thing.levels;
        }
      });
      //const domainLevelValueRaw = domainLevelSum / domainThings.length;
      const domainLevelValueRaw = domainLevelSum;
      axes.push({
        uuid: chartDomain.uuid,
        name: chartDomain.name,
        title: Number.isInteger(domainLevelValueRaw)
          ? levels[domainLevelValueRaw]?.title
          : 'Промежуточный',
        value: parseInt(
          String(
            (100 / (levels.length - 1)) * (domainLevelSum / domainThings.length)
          )
        ),
        valueRaw: domainLevelValueRaw,
      });
    } else {
      axes.push({
        uuid: chartDomain.uuid,
        name: chartDomain.name,
        title: 'Нет уровня',
        value: 0,
        valueRaw: 0,
      });
    }
  });

  return {
    axes: axes,
    color: specialityGrade ? '#8a8585' : '#ff3434',
    desc: '---',
    name: specialityGrade ? specialityGrade.name : '---',
  };
};

const getDomainThingsRecursive = (
  domains: Domain[],
  domainUuids: string[],
  domainsThings: DomainThing[],
  domainThings: DomainThing[]
): void => {
  domainThings.push(
    ...domainsThings.filter((domainThing) =>
      domainUuids.includes(domainThing.domain)
    )
  );

  const childDomains = domains.filter((domain) =>
    domainUuids.includes(domain.parent_domain)
  );
  if (childDomains.length > 0) {
    getDomainThingsRecursive(
      domains,
      childDomains.map((domain) => domain.uuid),
      domainsThings,
      domainThings
    );
  }
};

const getDomainThingsRecursiveForGrades = (
  domains: Domain[],
  domainUuids: string[],
  specialityCompetenceClaims: SpecialityCompetenceClaim[],
  domainThings: SpecialityCompetenceClaim[]
): void => {
  domainThings.push(
    ...specialityCompetenceClaims.filter((specialityCompetenceClaim) =>
      domainUuids.includes(specialityCompetenceClaim.thing.domain)
    )
  );

  const childDomains = domains.filter((domain) =>
    domainUuids.includes(domain.parent_domain)
  );
  if (childDomains.length > 0) {
    getDomainThingsRecursiveForGrades(
      domains,
      childDomains.map((domain) => domain.uuid),
      specialityCompetenceClaims,
      domainThings
    );
  }
};
