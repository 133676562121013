import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewSetHttpService } from './rest/ViewSetHttpService';
import { SpecialityGrade } from '@models/specialities/speciality-grade';

@Injectable({
  providedIn: 'root',
})
export class SpecialityGradeHttpService extends ViewSetHttpService<SpecialityGrade> {
  constructor(_http: HttpClient) {
    super(_http);
  }
  public viewSetUrl = 'specialities/grade';
}
