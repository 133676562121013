import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserHttpService } from '@services/http/UserHttpService';
import { AsyncList } from '@rest/AsyncList';
import { User } from '@models/user/user';

@Component({
  selector: 'app-search-select-user',
  templateUrl: './search-select-user.component.html',
  styleUrls: ['./search-select-user.component.css'],
})
export class SearchSelectUserComponent implements OnInit {
  @Input() hiddenUsers: User[] = null;
  @Input() excludeTeam: string = '';
  @Input() clearAfterSelection: boolean = false;
  @Output() userSelected: EventEmitter<User> = new EventEmitter<User>();

  form: UntypedFormGroup;
  searchUsers: AsyncList<User>;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _userHttpService: UserHttpService
  ) {}

  ngOnInit(): void {
    this.searchUsers = new AsyncList<User>(this._userHttpService);
    this.form = this._formBuilder.group({
      search: ['', []],
    });
  }

  search(search: string): void {
    this.searchUsers.setRequestParams({
      params: {
        exclude_team: this.excludeTeam,
        search: search,
      },
    });
    this.searchUsers.load().subscribe(() => {
      if (this.hiddenUsers) this._hideUsersFromSearch();
    });
  }

  searchSelect(selection: User): void {
    if (!selection) {
      return;
    }
    this.userSelected.emit(selection);
    if (this.clearAfterSelection) this.form.controls.search.setValue('');
  }

  private _hideUsersFromSearch(): void {
    this.searchUsers.state.items = this.searchUsers.state.items.filter(
      (searchUser) => {
        return !this._isUserHidden(searchUser);
      }
    );
  }

  private _isUserHidden(user: User): boolean {
    return !!this.hiddenUsers.find((u) => u.uuid === user.uuid);
  }
}
