import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { Thing } from '@models/ontology/thing';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThingHttpService<Model = Thing> extends ViewSetHttpService<Model> {
  http: HttpClient;

  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'ontology/thing';

  public getThingRelationsCount(thingUuid: string): Observable<any> {
    return this.http.get<string[]>(
      `${this.getUrl()}/${thingUuid}/get_thing_relations_count`,
      {
        responseType: 'json',
      }
    );
  }

  public squashThings(
    firstThingUuid: string,
    secondThingUuid: string,
    params: {
      name: string;
      description: string;
      domain: string;
      change_levels: boolean;
    }
  ): Observable<any> {
    return this.http.post<string[]>(
      `${this.getUrl()}/${firstThingUuid}/squash_things`,
      {
        thing: secondThingUuid,
        ...params,
      }
    );
  }
}
