<div class='app-admin-system-debug'>
  <h3>Version</h3>
  <div>
    <span>Frontend: {{ frontendVersion }}; </span>
    <span>Backend: {{ backendVersion }}</span>
  </div>
  <h3>Web UI config</h3>
  <div *ngIf='environment.production'>production environment</div>
  <div *ngIf='!environment.production'>non-production environment</div>
  <p>backend api url: {{ environment.apiUrl }}</p>
  <p>api delay: {{ environment.apiDelayMs }}ms</p>

  <mat-divider></mat-divider>

  <h3>Auth data</h3>
  <pre><code>{{ auth_info }}</code></pre>

  <mat-divider></mat-divider>
  <h3>Check sentry</h3>
  <div class='buttons-group'>
    <button
      mat-raised-button
      class='debug-button'
      (click)='check_backend_sentry_error()'
      color='primary'
    >
      Backend error
    </button>

    <button
      mat-raised-button
      class='debug-button'
      (click)='check_frontend_sentry_error()'
      color='primary'
    >
      Frontend error
    </button>
  </div>
  <h3>Check celery</h3>
  <button
    mat-raised-button
    class='debug-button'
    (click)='check_celery()'
    color='primary'
  >
    check
  </button>
  <h3>Teams matrix</h3>
  <button
    mat-raised-button
    class='debug-button'
    (click)='all_team_matrix_cache()'
    color='primary'
  >
    calculate
  </button>
  <h3>Test Adoit</h3>
  <div fxLayout='column' fxLayoutGap='8px'>
    <div>
      <form [formGroup]='adoitForm'>
        <div fxLayout='row' fxLayoutGap='8px' fxLayoutAlign='start center'>
          <mat-form-field
            appearance='outline'
            label='Введите starsmap_thing_id'
          >
            <input matInput type='text' formControlName='starsmap_thing_id' />
          </mat-form-field>
          <div>
            <button
              mat-raised-button
              class='debug-button'
              (click)='import_adoit()'
              color='primary'
              [disabled]='!adoitForm.controls.starsmap_thing_id.value'
            >
              import
            </button>
          </div>
          <div>
            <button
              mat-raised-button
              class='debug-button'
              (click)='experts_adoit()'
              color='primary'
              [disabled]='!adoitForm.controls.starsmap_thing_id.value'
            >
              experts
            </button>
          </div>
        </div>
      </form>
    </div>
    <div fxLayout='row' fxLayoutGap='8px'>
      <button
        mat-raised-button
        class='debug-button'
        (click)='patch_adoit()'
        color='primary'
      >
        patch
      </button>
      <button
        mat-raised-button
        class='debug-button'
        (click)='list_adoit()'
        color='primary'
      >
        list
      </button>
    </div>
  </div>
  <mat-divider [style.margin-top.px]='20' [style.margin-bottom.px]='20'>
  </mat-divider>
  <div *ngIf='isAddArchitectVisible' [style.margin-top.px]='20'>
    <app-search-select-user
      [hiddenUsers]='serializedArchitects'
      (userSelected)='onUserSelected($event)'
    ></app-search-select-user>
    <div fxLayout='row' fxLayoutGap='8px'>
      <button mat-stroked-button (click)='addArchitect()'>Добавить</button>
      <button
        mat-stroked-button
        (click)='isAddArchitectVisible = !isAddArchitectVisible'
      >
        Отмена
      </button>
    </div>
  </div>
  <div *ngIf='!isAddArchitectVisible' [style.margin-top.px]='20'>
    <div
      fxLayoutAlign='start center'
      class='add-button-container'
      (click)='isAddArchitectVisible = !isAddArchitectVisible'
    >
      <button
        mat-raised-button
        class='add-button'
        color='primary'
      >
        <mat-icon>add_circle_outline</mat-icon>
      </button>
      <span class='add-button-label'>Добавить adoit architect'a</span>
    </div>
  </div>
  <div *ngIf='architects.state.isLoaded'>
    <div
      *ngFor='let architect of architects.state.items'
      fxLayout='row'
      fxLayoutAlign='start center'
      class='item-container'
    >
      <div fxFlex class='item-name'>
        {{ architect.user.last_name + ' ' + architect.user.first_name }}
      </div>
      <div fxFlex='6rem'>
        <button mat-icon-button (click)='removeArchitect(architect.uuid)'>
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
