import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TeamManageThingLevelsComponent } from 'src/app/modules/teams/components/team-manage/thing-level-templates/thing-level-templates.component';
import { TeamsDashboardNewComponent } from './components/teams-dashboard-new/teams-dashboard-new.component';

const manage_routes: Routes = [
  { path: 'old/thing_levels', component: TeamManageThingLevelsComponent },
];

const routes: Routes = [{ path: '', component: TeamsDashboardNewComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TeamsRoutingModule {}
