import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FlexLayoutModule } from '@angular/flex-layout';

import { NgSelectModule } from '@ng-select/ng-select';

import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThingLevelFormComponent } from './thing-level-form/thing-level-form.component';
import { ThingEditLevelsComponent } from '@components/common/thing-edit-levels/thing-edit-levels.component';
import { ThingSelectLevelComponent } from './thing-select-level/thing-select-level.component';
import { ParentDomainComponent } from './parent-domain/parent-domain.component';
import { SpecialityCompetenciesComponent } from './speciality-competencies/speciality-competencies.component';
import { AddSpecialityCompetenceComponent } from './add-speciality-competence/add-speciality-competence.component';

import { LibraryItemComponent } from './library-item/library-item.component';
import { SpecialityThingGradesComponent } from './speciality-thing-grades/speciality-thing-grades.component';
import { CommonUIElementsModule } from 'src/app/modules/common-ui-elements/common-ui-elements.module';
import { AddSpecialityToTeamComponent } from './add-speciality-to-team/add-speciality-to-team.component';
import { ReviewProgressComponent } from './review-progress/review-progress.component';
import { ReviewThingsTreeComponent } from './review-things-tree/review-things-tree.component';
import { ReviewThingLevelsComponent } from './review-thing-levels/review-thing-levels.component';
import { ReviewThingCommentComponent } from './review-thing-comment/review-thing-comment.component';
import { DesireButtonComponent } from './desire-button/desire-button.component';
import { ReviewAssessmentResolutionHeaderComponent } from './review-assessment-resolution-header/review-assessment-resolution-header.component';
import { SubjectReviewListComponent } from './subject-review-list/subject-review-list.component';
import { TeamCreateDialogComponent } from './team-create-dialog/team-create-dialog.component';
import { TeamJoinDialogComponent } from './team-join-dialog/team-join-dialog.component';
import { TeamLinkComponent } from './team-link/team-link.component';
import { UiComponents } from '@components/ui/ui.module';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { DomainThingTreeComponent } from './domain-thing-tree/domain-thing-tree.component';
import { DomainThingTreeNodeComponent } from './domain-thing-tree/domain-thing-tree-node/domain-thing-tree-node.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UserListComponent } from './user-list/user-list.component';
import { SelectLibraryThingsComponent } from './select-library-things/select-library-things.component';
import { SelectUsersWithSearchComponent } from './select-users-with-search/select-users-with-search.component';
import { StaticTextfieldGroupComponent } from './static-textfield-group/static-textfield-group.component';
import { SearchSelectUserComponent } from './search-select-user/search-select-user.component';
import { CreateProjectDialogComponent } from './create-project-dialog/create-project-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CreateEditBookingDialogComponent } from './create-edit-booking-dialog/create-edit-booking-dialog.component';
import { EditTeamMemberStatusDialogComponent } from './edit-team-member-status-dialog/edit-team-member-status-dialog.component';
import { TeamSettingsComponent } from './team-settings/team-settings.component';
import { BlockTextHelpDialogComponent } from './block-text-help-dialog/block-text-help-dialog.component';
import { TeamMembersStatusConfirmDialogComponent } from './team-members-status-confirm-dialog/team-members-status-confirm-dialog.component';
import { TeamJoinRequestDialogComponent } from './team-join-request-dialog/team-join-request-dialog.component';
import { TextInputDialogComponent } from './text-input-dialog/text-input-dialog.component';
import { CreateSpecialityValidationDialogComponent } from './create-speciality-validation-dialog/create-speciality-validation-dialog.component';
import { TeamsGridComponent } from './teams-grid/teams-grid.component';
import { AutoLevelCompetenceclaimsDialogComponent } from './auto-level-competenceclaims-dialog/auto-level-competenceclaims-dialog.component';

@NgModule({
  declarations: [
    LibraryItemComponent,
    ThingEditLevelsComponent,
    ThingLevelFormComponent,
    ThingSelectLevelComponent,
    ParentDomainComponent,
    SpecialityCompetenciesComponent,
    AddSpecialityCompetenceComponent,
    SpecialityThingGradesComponent,
    AddSpecialityToTeamComponent,
    ReviewProgressComponent,
    ReviewThingsTreeComponent,
    ReviewThingLevelsComponent,
    ReviewThingCommentComponent,
    DesireButtonComponent,
    ReviewAssessmentResolutionHeaderComponent,
    SubjectReviewListComponent,
    TeamCreateDialogComponent,
    TeamLinkComponent,
    TeamJoinDialogComponent,
    SideMenuComponent,
    DomainThingTreeComponent,
    DomainThingTreeNodeComponent,
    UserListComponent,
    SelectLibraryThingsComponent,
    SelectUsersWithSearchComponent,
    StaticTextfieldGroupComponent,
    SearchSelectUserComponent,
    CreateProjectDialogComponent,
    CreateEditBookingDialogComponent,
    EditTeamMemberStatusDialogComponent,
    TeamSettingsComponent,
    BlockTextHelpDialogComponent,
    TeamMembersStatusConfirmDialogComponent,
    TeamJoinRequestDialogComponent,
    TextInputDialogComponent,
    CreateSpecialityValidationDialogComponent,
    TeamsGridComponent,
    AutoLevelCompetenceclaimsDialogComponent,
  ],
  imports: [
    CommonModule,
    UiComponents,
    CommonUIElementsModule,
    MatDividerModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatTooltipModule,

    MatCardModule,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatDialogModule,
    FlexLayoutModule,
    NgSelectModule,
    FormsModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDatepickerModule,
  ],
  exports: [
    LibraryItemComponent,
    ThingEditLevelsComponent,
    ThingLevelFormComponent,
    ThingSelectLevelComponent,
    ParentDomainComponent,
    SpecialityThingGradesComponent,
    ReviewProgressComponent,
    ReviewThingsTreeComponent,
    ReviewThingLevelsComponent,
    ReviewThingCommentComponent,
    ReviewAssessmentResolutionHeaderComponent,
    SubjectReviewListComponent,
    TeamLinkComponent,
    SideMenuComponent,
    DomainThingTreeComponent,
    UserListComponent,
    SelectLibraryThingsComponent,
    SelectUsersWithSearchComponent,
    StaticTextfieldGroupComponent,
    SearchSelectUserComponent,
    TeamSettingsComponent,
    TeamsGridComponent,
  ],
})
export class CommonComponentsModule {}
