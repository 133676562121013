import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AsyncList } from '@rest/AsyncList';
import { TeamMembership } from '@models/teams/team-membership';
import { FormGroup } from '@angular/forms';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { AuthService } from '../../../services/auth/auth.service';
import { TeamJoinRequestHttpService } from '@services/http/TeamJoinRequestHttpService';
import { AlertService } from '../../../services/ui/ui-alert.service';

type SpecialtyGradeUuid = string;

interface SelectedGrades {
  [specialityUuid: string]: SpecialtyGradeUuid;
}

@Component({
  selector: 'app-team-join-request-dialog',
  templateUrl: './team-join-request-dialog.component.html',
  styleUrls: ['./team-join-request-dialog.component.css'],
})
export class TeamJoinRequestDialogComponent implements OnInit {
  specialities: AsyncList<Speciality>;

  selectedSpecialities: Speciality[];
  selectedGrades: SelectedGrades = {};

  constructor(
    private _authService: AuthService,
    private _alertService: AlertService,
    private _teamJoinRequestHttpService: TeamJoinRequestHttpService,
    private _specialityHttpService: SpecialityHttpService,
    public dialogRef: MatDialogRef<TeamJoinRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      teamUuid: string;
    }
  ) {}

  ngOnInit(): void {
    this.specialities = new AsyncList<Speciality>(this._specialityHttpService);
    this.specialities.setRequestParams({
      params: {
        team: this.data.teamUuid,
        expand: 'related_grades',
      },
    });
    this.specialities.load().subscribe(() => {
      this.selectedSpecialities = [];
      this.selectedGrades = {};
    });
  }

  getGradePlaceholder(specialityName: string): string {
    return `Выбрать уровень для ${specialityName}`;
  }

  onSelectSpecialities($event: Speciality[]): void {
    $event.forEach((speciality) => {
      if (!this.selectedGrades[speciality.uuid]) {
        this.selectedGrades[speciality.uuid] =
          speciality.related_grades[0].uuid;
      }
    });
  }

  createTeamJoinRequest(): void {
    this._teamJoinRequestHttpService
      .create({
        user: this._authService.get_current_user_uuid(),
        team: this.data.teamUuid,
        specialities: this.selectedSpecialities.map(
          (speciality) => speciality.uuid
        ),
        speciality_grades: this.selectedSpecialities.map(
          (speciality) => this.selectedGrades[speciality.uuid]
        ),
      })
      .subscribe(
        () => {
          this.dialogRef.close();
          this._alertService.success('Приглашение отправлено!');
        },
        () => {
          this._alertService.error('Не удалось отправить приглашение!');
        }
      );
  }
}
