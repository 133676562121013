<div
  class="project-container"
  fxLayout="column"
  *ngIf="project?.state.isLoaded"
>
  <div fxLayout="row" class="project-title">
    <div fxFlex="5" fxLayoutAlign="start center" fxLayoutGap="2rem">
      <button (click)="onBackClick()" mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div fxFlex="90" class="project-info">
      <app-static-textfield-group
        [fields]="projectInfo"
      ></app-static-textfield-group>
    </div>
    <div fxLayoutAlign="start center">
      <button
        mat-icon-button
        (click)="onProjectEdit()"
        class="project-action"
        fxLayoutAlign="center center"
      >
        <mat-icon fxFlex="5">edit</mat-icon>
      </button>
    </div>
  </div>
  <app-calendar [project]="project.state.item"></app-calendar>
</div>
