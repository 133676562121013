import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { Project } from '@models/projects/project';

@Injectable({
  providedIn: 'root',
})
export class ProjectHttpService extends ViewSetHttpService<Project> {
  constructor(_http: HttpClient) {
    super(_http);
  }

  public viewSetUrl = 'projects/project';
}
