import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewSetHttpService } from './rest/ViewSetHttpService';
import { SpecialityUserRelation } from '@models/specialities/speciality-user-relation';
import { User } from '@models/user/user';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityGrade } from '@models/specialities/speciality-grade';
import { ChildSpecialitiesUpdateConfig } from '../../../modules/library/speciality/speciality-detail/speciality-detail.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpecialityUserRelationHttpService<
  UserModel = User | string,
  SpecialityModel = Speciality | string,
  GradeModel = SpecialityGrade | string
> extends ViewSetHttpService<
  SpecialityUserRelation<UserModel, SpecialityModel, GradeModel>
> {
  http: HttpClient;

  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'specialities/user';

  public createMultipleWithGrades(
    specialities_data: { speciality: string; grade: string }[],
    user: string,
    with_review: boolean
  ): Observable<any> {
    return this.http.post<string[]>(
      `${this.getUrl()}/create_multiple_with_grades`,
      {
        specialities_data,
        user,
        with_review,
      }
    );
  }
}
