<app-with-processing [processing]='!isDataReady'></app-with-processing>
<div *ngIf='isDataReady' class='profile-container' fxLayout='column'>
  <div
    fxLayout='row'
    fxLayoutAlign='start center'
    fxLayoutGap='2rem'
    class='profile-title'
    *ngIf='isFromTeam && !isCurrentUser'
  >
    <button (click)='onBackClick()' mat-icon-button>
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span>К списку участников</span>
  </div>
  <div class='profile-title' *ngIf='!isCurrentUser && !isFromTeam'>
    Профиль пользователя
  </div>
  <div
    fxLayout='row'
    fxLayoutAlign='start center'
    fxLayoutGap='2rem'
    class='profile-title'
    *ngIf='!isFromTeam && isCurrentUser'
  >
    Мой профиль
  </div>
  <div fxLayout='row' fxLayoutAlign='start center' class='user-info'>
    <div fxLayout='row' fxFlex fxLayoutGap='10px'>
      <div fxLayout='column'>
        <span class='user-info-name'>{{
          user.state.item.first_name + ' ' + user.state.item.last_name
          }}</span>
        <span class='user-info-email'>
          {{ user.state.item.email }}
        </span>
      </div>
      <div
        fxLayout='row'
        *ngIf='userTeamMemberships.state.isLoaded'
        fxLayoutGap='2px'
      >
        <div *ngFor='let membership of userTeamMemberships.state.items'>
          <div
            *ngIf='membership.team.icon_url'
            fxFlex='3rem'
            (click)='redirectToTeam(membership.team.uuid)'
            [style.cursor]="'pointer'"
          >
            <img [src]='membership.team.icon_url' [style.height.px]='30' alt='' />
          </div>
        </div>
      </div>
    </div>
    <div
      fxLayout='row'
      fxFlex
      fxLayoutAlign='end center'
      fxLayoutGap='8px'
      *ngIf='userSpecialityRelations.state.items.length'
    >
      <span class='user-info-speciality'>{{
        userSpecialityRelations.state.items[0].speciality.name +
        ', ' +
        (userSpecialityRelations.state.items[0].grade?.name || 'базовый')
        }}</span>
      <span
        class='user-info-speciality'
        *ngIf='userSpecialityRelations.state.items.length > 1'
      >{{
        userSpecialityRelations.state.items[1].speciality.name +
        ', ' +
        (userSpecialityRelations.state.items[1].grade?.name || 'базовый')
        }}</span
      >
      <span
        matTooltip='{{ getAllSpecialitiesTooltipText() }}'
        matTooltipClass='user-info-speciality-tooltip'
        class='user-info-speciality'
        *ngIf='userSpecialityRelations.state.items.length > 2'
      >{{ 'Ещё ' + (userSpecialityRelations.state.items.length - 2) }}</span
      >
    </div>
  </div>
  <h3 *ngIf='isFromTeam'>Матрица компетенций</h3>
  <div fxLayout='row' fxLayoutGap='8px' class='select-container'>
    <div
      *ngIf='userSpecialityRelations.state.items.length === 0'
      class='select'
      fxFlex='35'
    >
      Нет специальностей для отображения
    </div>
    <div
      *ngIf='userSpecialityRelations.state.items.length > 0'
      class='select'
      fxFlex='35'
    >
      <mat-select
        [formControl]='selectedSpeciality'
        [placeholder]="'Ваши специальности: '"
        (selectionChange)='onSelectedSpecialityChange()'
      >
        <mat-option
          *ngFor='let userRelation of userSpecialityRelations.state.items'
          [value]='userRelation.speciality.uuid'
        >
          {{ 'Ваши специальности: ' + userRelation.speciality.name }}
        </mat-option>
      </mat-select>
    </div>
    <div fxFlex='20px'></div>
    <div class='select' fxFlex='25'>
      <mat-select
        *ngIf='userSpecialityRelations.state.items.length > 0'
        [formControl]='selectedGrade'
        [placeholder]="'Желаемый уровень: '"
        (selectionChange)='onSelectedGradeChange()'
      >
        <mat-option
          *ngFor='let grade of specialitiesGradesMap[selectedSpeciality.value]'
          [value]='grade?.uuid'
        >
          {{ 'Желаемый уровень: ' + grade?.name }}
        </mat-option>
      </mat-select>
    </div>
  </div>
  <app-static-textfield-group
    [fields]='userLevelInfoData'
  ></app-static-textfield-group>
  <div *ngIf='!isNoSpecialities' fxLayout='column' class='chart-list-container'>
    <div
      fxLayout='row'
      fxLayoutAlign='start center'
      class='chart-desc-container'
    >
      <div class='chart-desc-color' [style.background]="'#82C4FF'"></div>
      <span>Необходимый</span>
      <div
        class='chart-desc-color'
        [style.background]="'#9B82FF'"
        [style.margin-left]="'24px'"
      ></div>
      <span>Текущий</span>
      <div
        class='chart-desc-color'
        [style.background]="'#B0D9AA'"
        [style.margin-left]="'24px'"
      ></div>
      <span>Самооценка</span>
      <div
        class='chart-desc-color'
        [style.background]="'#F8ACA7'"
        [style.margin-left]="'24px'"
      ></div>
      <span>Желаемый</span>
      <div fxFlex fxLayoutAlign='end center'>
        <span class='legend-desc-text' (click)='onLegendDescClick()'
        >Что означают эти метрики?</span
        >
      </div>
    </div>
    <div
      *ngIf='isChartDataReady && !chartDataList'
      class='chart-container-empty'
    >
      Нет данных для отображения
    </div>
    <div *ngIf='!isChartDataReady' class='chart-container-empty'>
      Загружаем данные...
    </div>
    <div
      *ngIf='chartDataList && isChartDataReady'
      fxLayout='row'
      fxLayoutGap='8px'
      class='charts-container'
    >
      <div
        *ngFor='let chartData of chartDataList; let i = index'
        fxFlex
        class='chart-container'
      >
        <app-chart-bar
          [chartData]='chartData'
          [htmlElementClass]="'levels-bar-chart' + i"
        ></app-chart-bar>
      </div>
    </div>
  </div>
  <div>
    <div *ngIf='!isTreeDataReady'>
      <mat-progress-bar mode='indeterminate'></mat-progress-bar>
    </div>
    <app-domain-thing-tree
      *ngIf='!isNoSpecialities && isTreeDataReady'
      [domains]='domains.state.items'
      [things]='things'
      [userUuid]='userUuid'
      [thingCurrentDesiredLevelMap]='thingCurrentDesiredLevelMap'
      [expandFirstNode]='true'
      [profileLevels]='true'
    ></app-domain-thing-tree>
  </div>
  <h3>План развития</h3>
  <mat-tab-group [animationDuration]="'0ms'" mat-stretch-tabs='false'>
    <mat-tab label='Текущие'>
      <br />
      <div
        *ngIf='!activePlans.length'
        class='profile-data-tab-empty'
      >
        Пока нет планов развития
      </div>
      <div *ngIf='activePlans.length'>
        <ng-container
          [ngTemplateOutlet]='developmentPlans'
          [ngTemplateOutletContext]='{ plans: activePlans }'
        >
        </ng-container>
      </div>
    </mat-tab>
    <mat-tab label='Завершенные'>
      <br />
      <div class='profile-data-tab-empty' *ngIf='!completedPlans.length'>
        Пока нет завершенных планов развития
      </div>
      <div *ngIf='completedPlans.length'>
        <ng-container
          [ngTemplateOutlet]='developmentPlans'
          [ngTemplateOutletContext]='{ plans: completedPlans }'
        >
        </ng-container>
      </div>
    </mat-tab>
  </mat-tab-group>
  <h3>Запросы на обучение</h3>
  <mat-tab-group [animationDuration]="'0ms'" mat-stretch-tabs='false'>
    <mat-tab label='Текущие'>
      <br *ngIf='desiredThings.length' />
      <app-domain-thing-tree
        *ngIf='isTreeDataReady'
        [domains]='domains.state.items'
        [things]='desiredThings'
        [thingDesireMap]='thingDesireMap'
        [expandFirstNode]='true'
      ></app-domain-thing-tree>
      <div class='profile-data-tab-empty' *ngIf='!desiredThings.length'>
        Не найдены запросы на обучение
      </div>
    </mat-tab>
    <mat-tab label='Завершенные'>
      <div class='profile-data-tab-empty'>
        Закрытые запросы на обучение сохраняются здесь
      </div>
    </mat-tab>
  </mat-tab-group>
  <h3>Ревью</h3>
  <mat-tab-group [animationDuration]="'0ms'" mat-stretch-tabs='false'>
    <mat-tab label="{{ 'Ревью ' + user.state.item.first_name }}">
      <div
        *ngIf='userReviews.state.items.length === 0'
        class='profile-data-tab-empty'
      >
        Еще не проводилось ревью
      </div>
      <div class='reviews-container' *ngIf='userReviews.state.items.length > 0'>
        <div
          class='review-container'
          fxLayout='row'
          fxLayoutAlign='start center'
          fxLayoutGap='2rem'
          *ngFor='let review of userReviews.state.items'
          (click)='onReviewClick(review)'
        >
          <div fxLayout='column' fxFlex='40'>
            <div fxLayout='row' fxLayoutAlign='start center'>
              <span class='review-container-text'>{{ review.name }}</span>
            </div>
            <div fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='1rem'>
              <mat-progress-bar
                mode='determinate'
                [value]='review.completion_percentage'
              ></mat-progress-bar>
              <span>{{ review.completion_percentage + '%' }}</span>
            </div>
          </div>
          <div fxFlex='30'>
            <div fxLayout='row' fxLayoutAlign='center center'>
              <span class='review-container-text'>{{
                getReviewSpeciality(review)
                }}</span>
            </div>
          </div>
          <div fxFlex='30'>
            <div fxLayout='row' fxLayoutAlign='center center'>
              <span class='review-container-text'>{{
                getPrettyReviewDate(review)
                }}</span>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label='Входящие ревью'>
      <div
        *ngIf='userAssessments.state.items.length === 0'
        class='profile-data-tab-empty'
      >
        {{ user.state.item.first_name }} пока не участвует в ревью других
        пользователей
      </div>
      <div
        class='reviews-container'
        *ngIf='userAssessments.state.items.length > 0'
      >
        <div
          class='review-container'
          fxLayout='row'
          fxLayoutAlign='start center'
          fxLayoutGap='2rem'
          *ngFor='let assessment of userAssessments.state.items'
          (click)='onAssessmentClick(assessment)'
        >
          <div fxLayout='column' fxFlex='30'>
            <div fxLayout='row' fxLayoutAlign='start center'>
              <span class='review-container-text'>{{
                assessment.review.name
                }}</span>
            </div>
            <div fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='1rem'>
              <mat-progress-bar
                mode='determinate'
                [value]='assessment.review.completion_percentage'
              ></mat-progress-bar>
              <span>{{ assessment.review.completion_percentage + '%' }}</span>
            </div>
          </div>
          <div fxFlex='23'>
            <div fxLayout='row' fxLayoutAlign='center center'>
              <span class='review-container-text'>{{
                getReviewSpeciality(assessment.review)
                }}</span>
            </div>
          </div>
          <div fxFlex='23'>
            <div fxLayout='row' fxLayoutAlign='center center'>
              <span class='review-container-text'>{{
                getPrettyReviewDate(assessment.review)
                }}</span>
            </div>
          </div>
          <div fxFlex='24'>
            <div fxLayout='column' fxFlex>
              <span class='desc-text'>Ревьюируемый</span>
              <span class='user-name'>{{
                assessment.subject_user.first_name +
                ' ' +
                assessment.subject_user.last_name
                }}</span>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #developmentPlans let-plans='plans'>
  <div
    fxLayout='column'
    fxLayoutGap='8px'
  >
    <div
      fxLayout='row'
      fxLayoutAlign='start center'
      class='development-container'
      *ngFor='let plan of plans'
      (click)='onDevelopmentPlanClick(plan)'
    >
      <div fxFlex='40' fxLayout='column' fxLayoutGap='8px'>
            <span class='development-container-text'>{{
              plan.objective
              }}</span>
        <div fxLayout='row' fxLayoutGap='8px' fxLayoutAlign='start center'>
          <mat-progress-bar
            [value]='plan.completion_percentage'
          ></mat-progress-bar>
          <span>{{ plan.completion_percentage + '%' }}</span>
        </div>
      </div>
      <div fxFlex='30'></div>
      <span
        fxFlex='30'
        fxLayoutAlign='center center'
        class='development-container-text'
      >{{ getPrettyDevelopmentDate(plan) }}</span
      >
    </div>
  </div>
</ng-template>
