import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, forkJoin, combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LoggingService } from 'src/app/services/logging.service';
import { TitleService } from 'src/app/services/title.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { UserService } from 'src/app/services/user/user.service';
import { SpecialityService } from 'src/app/services/specialities/speciality.service';
import { SpecialityGradeService } from 'src/app/services/specialities/speciality-grade.service';
import { SpecialityUserRelationService } from 'src/app/services/specialities/speciality-user-relation.service';
import { SpecialityDomainClaimService } from 'src/app/services/specialities/speciality-domain-claim.service';
import { SpecialityCompetenceClaimService } from 'src/app/services/specialities/speciality-competence-claim.service';
import { CompetenceService } from 'src/app/services/competencies/competence.service';

import { User } from 'src/app/models/user/user';
import { Thing } from 'src/app/models/ontology/thing';
import { Competence } from 'src/app/models/competencies/competence';
import { Speciality } from 'src/app/models/specialities/speciality';
import { SpecialityGrade } from 'src/app/models/specialities/speciality-grade';
import { SpecialityUserRelation } from 'src/app/models/specialities/speciality-user-relation';
import { SpecialityDomainClaim } from 'src/app/models/specialities/speciality-domain-claim';
import { SpecialityCompetenceClaim } from 'src/app/models/specialities/speciality-competence-claim';
import { sortByField, sort_recursive } from 'src/app/utils/sort';
import { CompetenceHttpService } from '@services/http/CompetenceHttpService';
import { SpecialityDomainClaimHttpService } from '@services/http/SpecialityDomainClaimHttpService';
import { SpecialityCompetenceClaimHttpService } from '@services/http/SpecialityCompetenceClaimHttpService';
import { SpecialityGradeHttpService } from '@services/http/SpecialityGradeHttpService';
import { AsyncList } from '@rest/AsyncList';
import { Domain } from '@models/ontology/domain';
import {
  computeAxeValues,
  DomainThing,
} from 'src/app/utils/compute-chart-radar-values';
import { ThingLevel } from '@models/ontology/thing-level';
import { selectDomainsForAxes } from 'src/app/utils/select-domains-for-chart-axes';
import {
  ChartInputAxesData,
  ChartInputRadarData,
} from '@components/ui/chart-radar/chart-radar.component';

@Component({
  selector: 'app-track-speciality-user-compare',
  templateUrl: './track-speciality-user-compare.component.html',
  styleUrls: ['./track-speciality-user-compare.component.css'],
})
export class TrackSpecialityUserCompareComponent implements OnInit {
  private speciality_uuid: string;
  private user_uuid: string;

  public speciality: Speciality;
  public speciality_grades: SpecialityGrade[] = [];
  public speciality_user_relation: SpecialityUserRelation<
    User,
    Speciality,
    SpecialityGrade
  >;

  public domain_claims: SpecialityDomainClaim[] = [];
  public competence_claims: SpecialityCompetenceClaim<ThingLevel>[] = [];

  public user: User;
  public user_competencies: Competence[] = [];

  public virtual_competencies: Competence[] = [];
  public unsatisfied_competence_claims: Competence[] = [];
  public competence_claims_expected_for_grade: SpecialityCompetenceClaim<ThingLevel>[] =
    [];

  private all_domain_claim_treenodes: any[] = [];
  private nonempty_domain_treenodes: any[] = [];
  public hierarchy_root_treenodes: any[] = [];

  public chartDomains: Domain[];

  public actualChartHtmlElementClass =
    'track-speciality-user-compare-actual-radar-chart';
  public chartGrades: ChartInputRadarData[] = null;
  public chartAxesData: ChartInputAxesData[] = null;
  public chartRadarData: ChartInputRadarData[] = null;

  public desiredChartHtmlElementClass =
    'track-speciality-user-compare-desired-radar-chart';
  public desiredChartGrade: ChartInputRadarData[] = null;

  public userCompetencies: AsyncList<Competence>;
  public domainClaims: AsyncList<SpecialityDomainClaim>;
  public specialityCompetenceClaims: AsyncList<SpecialityCompetenceClaim>;
  public specialityGrades: AsyncList<SpecialityGrade>;

  public selected_grade: SpecialityGrade;

  private uistate = {
    user_loaded: false,
    speciality_loaded: false,
    speciality_grades_loaded: false,
    speciality_user_relation_loaded: false,
    speciality_domain_claims_loaded: false,
    speciality_competence_claims_loaded: false,
    user_competencies_loaded: false,
  };

  constructor(
    private route: ActivatedRoute,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private title_service: TitleService,
    private user_service: UserService,
    private speciality_service: SpecialityService,
    private speciality_grade_service: SpecialityGradeService,
    private speciality_user_relation_service: SpecialityUserRelationService,
    private competence_service: CompetenceService,
    private domain_claim_service: SpecialityDomainClaimService,
    private competence_claim_service: SpecialityCompetenceClaimService,

    private _competenceHttpService: CompetenceHttpService,
    private _domainClaimService: SpecialityDomainClaimHttpService,
    private _specialityCompetenceClaimsService: SpecialityCompetenceClaimHttpService,
    private _specialityGradeService: SpecialityGradeHttpService
  ) {}

  ngOnInit(): void {
    this.speciality_uuid = this.route.snapshot.paramMap.get('speciality_uuid');
    this.user_uuid = this.route.snapshot.paramMap.get('user_uuid');
    this.title_service.set_title('Трек специальности');
    combineLatest([
      this.load_user(),
      this.load_speciality(),
      this.loadSpecialityGrades(),
    ]).subscribe(() => {
      combineLatest([
        this.load_user_relations(),
        this.loadCompetenciesForUser(),
        this.loadSpecialityDomainClaims(),
        this.loadSpecialityCompetenceClaims(),
      ]).subscribe(() => {
        this.choose_default_selected_grade();
        this.choose_virtual_competencies();
        this._configureDiagramData();
      });
    });
  }

  public get is_data_loaded(): boolean {
    return (
      this.uistate.user_loaded &&
      this.uistate.speciality_loaded &&
      this.uistate.speciality_grades_loaded &&
      this.uistate.speciality_user_relation_loaded &&
      this.uistate.speciality_domain_claims_loaded &&
      this.uistate.speciality_competence_claims_loaded &&
      this.uistate.user_competencies_loaded
    );
  }

  public get is_claims_empty(): boolean {
    return (
      this.domain_claims.length === 0 && this.competence_claims.length === 0
    );
  }

  public get is_user_has_this_speciality(): boolean {
    return Boolean(this.speciality_user_relation);
  }

  private load_user(): Observable<any> {
    return this.user_service.fetch_user_by_uuid(this.user_uuid).pipe(
      tap(
        (response) => {
          this.user = response as User;
          this.logging_service.debug(
            `${this.constructor.name} loaded user ${this.user.uuid}`
          );
          this.uistate.user_loaded = true;
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to load user`
          );
          this.alert_service.error(
            `Ошибка загрузки пользователя: ${err.status}`
          );
        }
      )
    );
  }

  private load_speciality(): Observable<any> {
    return this.speciality_service.fetch_by_uuid(this.speciality_uuid).pipe(
      tap(
        (response) => {
          this.speciality = response as Speciality;
          this.logging_service.debug(
            `${this.constructor.name} loaded speciality ${this.speciality.name}`
          );
          this.title_service.set_title(`Трек ${this.speciality.name}`);
          this.uistate.speciality_loaded = true;
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to load speciality`
          );
          this.alert_service.error(
            `Ошибка загрузки специальности ${err.status}`
          );
        }
      )
    );
  }

  private loadSpecialityGrades(): Observable<any> {
    this.specialityGrades = new AsyncList<SpecialityGrade>(
      this._specialityGradeService
    );
    this.specialityGrades.setRequestParams({
      params: {
        speciality: this.speciality_uuid,
      },
    });
    return this.specialityGrades.load().pipe(
      tap(
        (response) => {
          this.speciality_grades = response.results as SpecialityGrade[];
          sortByField('order_number')(this.speciality_grades);
          this.uistate.speciality_grades_loaded = true;
          this.logging_service.debug(
            `${this.constructor.name} loaded ${this.speciality_grades.length} grades`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to load grades`
          );
          this.alert_service.error(`Ошибка загрузки грейдов: ${err.status}`);
        }
      )
    );
  }

  private load_user_relations(): Observable<any> {
    return this.speciality_user_relation_service
      .fetch_by_user_uuid(this.user_uuid)
      .pipe(
        tap(
          (response) => {
            const speciality_user_relations =
              response.results as SpecialityUserRelation<
                User,
                Speciality,
                SpecialityGrade
              >[];
            this.logging_service.debug(
              `${this.constructor.name} loaded ${speciality_user_relations.length} user relations`
            );
            speciality_user_relations.forEach((relation) => {
              if (relation.speciality.uuid === this.speciality_uuid)
                this.speciality_user_relation = relation;
            });
            this.uistate.speciality_user_relation_loaded = true;
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to load user speciality relations`
            );
            this.alert_service.error(
              `Ошибка загрузки связей со специальностью: ${err.status}`
            );
          }
        )
      );
  }

  private loadSpecialityDomainClaims(): Observable<any> {
    this.logging_service.debug(
      `${this.constructor.name} loading speciality domain claims...`
    );
    this.domainClaims = new AsyncList<SpecialityDomainClaim>(
      this._domainClaimService
    );
    this.domainClaims.setRequestParams({
      params: {
        speciality: this.speciality_uuid,
        expand: 'speciality,domain',
      },
    });
    return this.domainClaims.load().pipe(
      tap(
        (response) => {
          this.domain_claims = response.results as SpecialityDomainClaim[];
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} speciality domain claims`
          );
          this.uistate.speciality_domain_claims_loaded = true;
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch speciality domain claims`
          );
          this.alert_service.error(
            `Ошибка загрузки доменов специальности: ${err.status}`
          );
        }
      )
    );
  }

  private loadSpecialityCompetenceClaims(): Observable<any> {
    this.logging_service.debug(
      `${this.constructor.name} loading speciality competence claims...`
    );
    this.specialityCompetenceClaims = new AsyncList<SpecialityCompetenceClaim>(
      this._specialityCompetenceClaimsService
    );
    this.specialityCompetenceClaims.setRequestParams({
      params: {
        speciality: this.speciality_uuid,
        expand: 'speciality,grade,thing,thing_level',
      },
    });
    return this.specialityCompetenceClaims.load().pipe(
      tap(
        (response) => {
          this.competence_claims =
            response.results as SpecialityCompetenceClaim<ThingLevel>[];
          this.uistate.speciality_competence_claims_loaded = true;
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} speciality competence claims`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch speciality competence claims`
          );
          this.alert_service.error(
            `Ошибка загрузки компетенций специальности: ${err.status}`
          );
        }
      )
    );
  }

  private loadCompetenciesForUser(): Observable<any> {
    this.userCompetencies = new AsyncList<Competence>(
      this._competenceHttpService
    );
    this.userCompetencies.setRequestParams({
      params: {
        user: this.user.uuid,
        expand: 'thing,thing_level',
      },
    });
    return this.userCompetencies.load().pipe(
      tap(
        (response) => {
          this.user_competencies = response.results as Competence[];
          this.logging_service.debug(
            `${this.constructor.name} loaded ${this.user_competencies.length} user competencies`
          );
          this.uistate.user_competencies_loaded = true;
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to load user competencies`
          );
          this.alert_service.error(
            `Ошибка загрузки компетенций: ${err.status}`
          );
        }
      )
    );
  }

  private choose_default_selected_grade(): void {
    this.logging_service.debug(
      `${this.constructor.name} choosing default selected grade...`
    );
    if (this.speciality_user_relation) {
      let current_grade_order = 0;
      let current_grade_name = 'no grade';
      if (this.speciality_user_relation.grade) {
        current_grade_order = this.speciality_user_relation.grade.order;
        current_grade_name = this.speciality_user_relation.grade.name;
        this.logging_service.debug(
          `${this.constructor.name} choosing grade: current ${current_grade_name} (${current_grade_order})`
        );
      } else {
        this.logging_service.debug(
          `${this.constructor.name} choosing grade: user has no grade assigned`
        );
      }
      const grades_higher_than_ours = [];
      this.speciality_grades.forEach((grade) => {
        if (grade.order < current_grade_order) {
          this.logging_service.debug(
            `${this.constructor.name} choosing grade: skipping ${grade.name} (${grade.order})`
          );
        } else {
          this.logging_service.debug(
            `${this.constructor.name} choosing grade: there is higher grade ${grade.name}`
          );
          grades_higher_than_ours.push(grade);
        }
      });
      if (grades_higher_than_ours.length) {
        grades_higher_than_ours.sort((a, b) => (a.order > b.order ? 1 : -1));
        this.selected_grade = grades_higher_than_ours[0];
        this.logging_service.debug(
          `${this.constructor.name} choosing grade: choosed lower of higher ${this.selected_grade.name}`
        );
      } else {
        this.selected_grade = this.speciality_grades.find(
          (grade) => grade.order === current_grade_order
        );
        this.logging_service.debug(
          `${this.constructor.name} choosing grade: choosed our ${this.selected_grade.name}`
        );
      }
    } else {
      this.selected_grade = this.speciality_grades[0];
      this.logging_service.debug(
        `${this.constructor.name} choosing grade: choosed first ${this.selected_grade.name}`
      );
    }
  }

  private choose_virtual_competencies(): void {
    const virtual_competencies_thing_map = new Map<string, any[]>();
    const virtual_competencies = [];

    this.competence_claims.forEach((claim) => {
      if (claim.grade.order <= this.selected_grade.order) {
        const virtual_competence = {
          thing: claim.thing,
          level: claim.thing_level.order_number,
        } as any;
        if (!virtual_competencies_thing_map.has(claim.thing.uuid)) {
          virtual_competencies_thing_map.set(claim.thing.uuid, []);
        }
        virtual_competencies_thing_map
          .get(claim.thing.uuid)
          .push(virtual_competence);
      }
    });

    for (const [
      thing_uuid,
      thing_virtual_competencies,
    ] of virtual_competencies_thing_map) {
      thing_virtual_competencies.sort((a, b) => (a.level > b.level ? -1 : 1));
      const highest_level_virtual_competence = thing_virtual_competencies[0];
      virtual_competencies.push(highest_level_virtual_competence);
    }

    // we also need to add user competencies, that exists and in claims
    // but required only for higher grades
    // if we dont do that, sometimes target radar area may me smaller that user already has

    // filter user competencies to only required by competence claims
    // than filter already existing in virtual
    const user_competencies_matching_speciality = this.user_competencies.filter(
      (user_competence) => {
        if (
          this.competence_claims.find(
            (cclaim) =>
              cclaim.thing.uuid === (user_competence.thing as Thing).uuid
          )
        ) {
          return true;
        }
      }
    );
    const not_so_virtual_competencies =
      user_competencies_matching_speciality.filter((user_competence) => {
        if (
          !virtual_competencies.find(
            (virtual_competence) =>
              virtual_competence.thing.uuid ===
              (user_competence.thing as Thing).uuid
          )
        ) {
          return true;
        }
      });
    not_so_virtual_competencies.forEach((competence) =>
      virtual_competencies.push(competence)
    );

    this.virtual_competencies = virtual_competencies;
    this.calculate_competence_difference_to_selected_grade();
  }

  private calculate_competence_difference_to_selected_grade(): void {
    this.logging_service.debug(
      `${this.constructor.name} calculating difference to grade ${this.selected_grade.name}`
    );
    // for every competence claim from grade <= selected
    // check, that user reached expected level
    // if not: add this claim to list
    // from that list get all domains
    // build domain tree
    const competence_claims_expected_for_grade = [];
    const unsatisfied_competence_claims = [];
    const thing_claims_map = new Map();
    this.competence_claims.forEach((competence_claim) => {
      if (competence_claim.grade.order <= this.selected_grade.order) {
        // remember competence claim with highest level
        if (!thing_claims_map.has(competence_claim.thing.uuid)) {
          thing_claims_map.set(competence_claim.thing.uuid, competence_claim);
        } else {
          const known_claim = thing_claims_map.get(competence_claim.thing.uuid);
          if (
            known_claim.thing_level.order_number <
            competence_claim.thing_level.order_number
          ) {
            thing_claims_map.set(competence_claim.thing.uuid, competence_claim);
          }
        }
      }
    });

    // gather claims expected for selected grade
    for (const [thing_uuid, competence_claim] of thing_claims_map) {
      competence_claims_expected_for_grade.push(competence_claim);
    }

    // filter claims, that has already satisfied expectactions
    competence_claims_expected_for_grade.forEach((competence_claim) => {
      const user_competence_for_claim = this.user_competencies.find(
        (user_competence) => {
          return (
            (user_competence.thing as Thing).uuid ===
            competence_claim.thing.uuid
          );
        }
      ) as any;
      if (user_competence_for_claim) {
        if (
          user_competence_for_claim.thing_level.order_number <
          competence_claim.thing_level.order_number
        ) {
          unsatisfied_competence_claims.push(competence_claim);
        }
      } else {
        // user has no competence for this claim
        unsatisfied_competence_claims.push(competence_claim);
      }
    });
    this.competence_claims_expected_for_grade =
      competence_claims_expected_for_grade;
    this.unsatisfied_competence_claims = unsatisfied_competence_claims;
    this.build_domain_tree_data();
    this.sort_domain_tree_data();
  }

  private build_domain_tree_data(): void {
    this.logging_service.debug(
      `${this.constructor.name} build_domain_tree_data`
    );
    this.all_domain_claim_treenodes = [];
    this.domain_claims.forEach((domain_claim) => {
      const treenode = {
        domain_claim,
        parent_treenode: null,
        children: [],
        competencies: [],
      };
      this.all_domain_claim_treenodes.push(treenode);
    });
    this.all_domain_claim_treenodes.forEach((treenode) => {
      const child_domain_claims = this.domain_claims.filter((domain_claim) => {
        return (
          domain_claim.domain.parent_domain &&
          domain_claim.domain.parent_domain ===
            treenode.domain_claim.domain.uuid
        );
      });
      child_domain_claims.forEach((child_domain_claim) => {
        const child_treenode = this.all_domain_claim_treenodes.find((node) => {
          return (
            node.domain_claim.domain.uuid === child_domain_claim.domain.uuid
          );
        });
        child_treenode.parent_treenode = treenode;
        treenode.children.push(child_treenode);
      });
    });
    this.add_competencies_to_treenodes();
    const hierarchy_root_treenodes = this.all_domain_claim_treenodes.filter(
      (node) => node.domain_claim.domain.parent_domain === null
    );
    // remove treenodes, where there is no child domains and competencies
    const filter_empty_treenodes_recursive = (treenode) => {
      treenode.children.forEach((child_treenode) =>
        filter_empty_treenodes_recursive(child_treenode)
      );
      const filtered_children = [];
      for (const child of treenode.children) {
        if (child.children.length || child.competencies.length)
          filtered_children.push(child);
      }
      treenode.children = filtered_children;
    };
    hierarchy_root_treenodes.forEach((treenode) =>
      filter_empty_treenodes_recursive(treenode)
    );
    const filtered_root_nodes = [];
    for (const root_node of hierarchy_root_treenodes) {
      if (root_node.children.length || root_node.competencies.length)
        filtered_root_nodes.push(root_node);
    }
    this.hierarchy_root_treenodes = filtered_root_nodes;
  }

  private sort_domain_tree_data() {
    sort_recursive(
      (treenode) => treenode.domain_claim.order_number,
      (treenode) => treenode.children
    )(this.hierarchy_root_treenodes);
  }

  private add_competencies_to_treenodes(): void {
    this.logging_service.debug(
      `${this.constructor.name} add_competencies_to_treenodes`
    );
    this.virtual_competencies.forEach((competence) => {
      const domain_claim_treenode = this.all_domain_claim_treenodes.find(
        (node) => {
          return (
            node.domain_claim.domain.uuid === (competence.thing as Thing).domain
          );
        }
      );
      // not all user competencies is required for speciality, so there is some unrelated
      if (domain_claim_treenode) {
        if (
          this.competence_claims_expected_for_grade.find(
            (cclaim) =>
              (cclaim.thing as Thing).uuid === (competence.thing as Thing).uuid
          )
        ) {
          domain_claim_treenode.competencies.push(competence);
        }
      }
    });
    this.all_domain_claim_treenodes.forEach((treenode) => {
      treenode.competencies.sort((a, b) => (a.level > b.level ? -1 : 1));
    });
  }

  public on_selected_grade_change(): void {
    this.choose_virtual_competencies();
    this._setDesiredChartGrade();
  }

  public util_user_get_initials_for_avatar(user: User): string {
    const first_name_initial = user.first_name.charAt(0);
    const last_name_initial = user.last_name.charAt(0);
    return `${first_name_initial}${last_name_initial}`;
  }

  public is_competence_claim_satisfied(competence: Competence): boolean {
    return !Boolean(
      this.unsatisfied_competence_claims.find(
        (cclaim) =>
          (cclaim.thing as Thing).uuid === (competence.thing as Thing).uuid
      )
    );
  }

  public get_competence_level_readable({
    thing,
    level,
  }: {
    thing: Thing;
    level: number;
  }): string {
    return thing.levels[level].title;
  }

  public get_user_competence_level_readable(competence: Competence): string {
    const user_competence = this.user_competencies.find((c) => {
      return (c.thing as Thing).uuid === (competence.thing as Thing).uuid;
    }) as any;
    if (user_competence) {
      return this.get_competence_level_readable({
        thing: user_competence.thing,
        level: user_competence.thing_level.order_number,
      });
    }

    return 'Навыка нет';
  }

  public get_expected_competence_level_readable(
    competence: Competence
  ): string {
    const competence_claim_for_selected_grade =
      this.competence_claims_expected_for_grade.find((cclaim) => {
        return (
          (cclaim.thing as Thing).uuid === (competence.thing as Thing).uuid
        );
      });
    return this.get_competence_level_readable({
      thing: competence_claim_for_selected_grade.thing,
      level: competence_claim_for_selected_grade.thing_level.order_number,
    });
  }

  private _configureDiagramData(): void {
    const domains = this.domainClaims.state.items.map((domainClaim) => {
      return domainClaim.domain as Domain;
    });

    this.specialityCompetenceClaims.state.items =
      this.specialityCompetenceClaims.state.items.map((competenceClaim) => {
        const competenceClaimGrade = competenceClaim.grade as SpecialityGrade,
          competenceClaimSpeciality = competenceClaim.speciality as Speciality;
        return {
          ...competenceClaim,
          grade: competenceClaimGrade.uuid,
          speciality: competenceClaimSpeciality.uuid,
        };
      });

    //selecting unique things uuid related to chosed speciality
    const specialityCompetenceThingUuids = [];
    this.specialityCompetenceClaims.state.items.forEach((competenceClaim) => {
      const thing = competenceClaim.thing as Thing;
      if (!specialityCompetenceThingUuids.includes(thing.uuid)) {
        specialityCompetenceThingUuids.push(thing.uuid);
      }
    });

    //variable to check if user got any competencies related to chosen speciality
    let competenceMatch = 0;

    //selecting user things
    const chartThings = [],
      domainsThings: DomainThing[] = [];
    this.userCompetencies.state.items.forEach((competence) => {
      const thing = competence.thing as Thing;
      if (specialityCompetenceThingUuids.includes(thing.uuid)) {
        competenceMatch += 1;
      }
      chartThings.push({
        parent_domain: (thing as any).domain as string,
        name: thing.name,
      });
      domainsThings.push({
        domain: (thing as any).domain as string,
        thing: thing,
        thing_level: competence.thing_level as ThingLevel,
      });
    });

    this.chartRadarData = [];
    this.chartAxesData = [];
    if (competenceMatch > 0) {
      this.chartDomains = selectDomainsForAxes(domains, chartThings);
      this.chartRadarData = [
        computeAxeValues(
          domains,
          this.chartDomains,
          domainsThings.filter((domainThing) =>
            specialityCompetenceThingUuids.includes(domainThing.thing.uuid)
          )
        ),
      ];

      this.chartAxesData = this.chartDomains.map((domain) => ({
        name: domain.name,
      }));
    }
    this._setDesiredChartGrade();
  }

  private _setDesiredChartGrade(): void {
    const domains = this.domainClaims.state.items.map((domainClaim) => {
      return domainClaim.domain as Domain;
    });
    this.specialityGrades.state.items.forEach((specialityGrade) => {
      if (specialityGrade.uuid === this.selected_grade.uuid) {
        this.desiredChartGrade = [];
        this.desiredChartGrade.push(
          computeAxeValues(
            domains,
            this.chartDomains,
            this.specialityCompetenceClaims.state.items.filter(
              (specialityCompetenceClaim) =>
                specialityCompetenceClaim.grade === specialityGrade.uuid
            ),
            specialityGrade
          )
        );
      }
    });
  }
}
