import { Component, Inject, OnInit } from '@angular/core';
import { User } from '@models/user/user';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { ProjectHttpService } from '@services/http/ProjectHttpService';
import { AsyncList } from '@rest/AsyncList';
import { UserHttpService } from '@services/http/UserHttpService';
import { CompanyHttpService } from '@services/http/CompanyHttpService';
import { Company } from '@models/projects/company';
import { Project } from '@models/projects/project';

@Component({
  selector: 'app-create-project-dialog',
  templateUrl: './create-project-dialog.component.html',
  styleUrls: ['./create-project-dialog.component.css'],
})
export class CreateProjectDialogComponent implements OnInit {
  form: UntypedFormGroup;
  searchUsers: AsyncList<User>;
  companies: AsyncList<Company>;

  constructor(
    public dialogRef: MatDialogRef<CreateProjectDialogComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _dateAdapter: DateAdapter<Date>,
    private _companyHttpService: CompanyHttpService,
    private _projectHttpService: ProjectHttpService,
    private _userHttpService: UserHttpService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      company: Company;
      submit(project: Project): void;
    }
  ) {
    this._dateAdapter.setLocale('ru-RU'); // dd/MM/yyyy
  }

  ngOnInit(): void {
    this.searchUsers = new AsyncList<User>(this._userHttpService);
    this.companies = new AsyncList<Company>(this._companyHttpService);
    this.companies.load();
    this.form = this._formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      company: [''],
      resources: [''],
      dateFrom: ['', [Validators.required]],
      dateTo: ['', [Validators.required]],
    });
  }

  search(search: string): void {
    this.searchUsers.setRequestParams({
      params: {
        search: search,
      },
    });
    this.searchUsers.load();
  }

  submit(): void {
    this._projectHttpService
      .create({
        name: this.form.controls.name.value,
        description: this.form.controls.description.value,
        company: this.data.company
          ? this.data.company.uuid
          : this.form.controls.company.value,
        resources: this.form.controls.resources.value.map(
          (resource) => resource.uuid
        ),
        planned_start: this.form.controls.dateFrom.value.toISOString(),
        planned_finish: this.form.controls.dateTo.value.toISOString(),
      })
      .subscribe((project) => {
        this.data.submit(project);
        this.dialogRef.close();
      });
  }
}
