import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { DevelopmentPlan } from '@models/tracks/development-plan';

@Injectable({
  providedIn: 'root',
})
export class DevelopmentPlanHttpService extends ViewSetHttpService<DevelopmentPlan> {
  constructor(_http: HttpClient) {
    super(_http);
  }

  public viewSetUrl = 'track/pdp';
}
