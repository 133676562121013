import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { User } from '@models/user/user';
import { UserHttpService } from '@services/http/UserHttpService';
import { AsyncList } from '@rest/AsyncList';
import { DateAdapter } from '@angular/material/core';
import { DevelopmentPlanHttpService } from '@services/http/DevelopmentPlanHttpService';
import { AuthService } from '../../../../../services/auth/auth.service';

@Component({
  selector: 'app-pdp-create',
  templateUrl: './pdp-create.component.html',
  styleUrls: ['./pdp-create.component.css'],
})
export class PdpCreateComponent implements OnInit {
  form: UntypedFormGroup;

  users: AsyncList<User>;

  options: User[];

  constructor(
    private _router: Router,
    private _formBuilder: UntypedFormBuilder,
    private _dateAdapter: DateAdapter<Date>,
    private _authService: AuthService,
    private _userHttpService: UserHttpService,
    private _developmentPlanHttpService: DevelopmentPlanHttpService
  ) {
    this._dateAdapter.setLocale('ru-RU'); // dd/MM/yyyy
  }

  ngOnInit(): void {
    this.users = new AsyncList<User>(this._userHttpService);
    this.users.load().subscribe(() => {
      this.options = this.users.state.items;
    });

    this.form = this._formBuilder.group({
      objective: ['', [Validators.required]],
      personal_value: ['', [Validators.required]],
      accomplishment_indicator: ['', [Validators.required]],
      responsible_user: ['', [Validators.required]],
      due_date: ['', [Validators.required]],
    });

    this.form.controls.due_date.setValue(new Date());
  }

  onUserSelection(user: User): void {
    this.form.controls.responsible_user.setValue(user);
  }

  onBackClick(): void {
    history.back();
  }

  onPdpCreate(): void {
    const formControls = this.form.controls;
    this._developmentPlanHttpService
      .create({
        subject_user: this._authService.get_current_user_uuid(),
        responsible_user: formControls.responsible_user.value.uuid,
        objective: formControls.objective.value,
        accomplishment_indicator: formControls.accomplishment_indicator.value,
        personal_value: formControls.personal_value.value,
        organisation_value: '',
        due_date: formControls.due_date.value.toISOString(),
      })
      .subscribe(() => {
        this.onBackClick();
      });
  }
}
