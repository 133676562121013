import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root',
})
export class CompetenceService {
  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService
  ) {}

  public fetch_by_thing_uuid(thing_uuid: string): Observable<any> {
    const params = { thing: thing_uuid, expand: 'user' };
    return this.api_service.get(`competencies/competence`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} competencies`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch competencies`
          );
        }
      )
    );
  }

  public fetch_by_user_uuid(user_uuid: string): Observable<any> {
    const params = { user: user_uuid, expand: 'thing,thing_level,verified_by' };
    return this.api_service.get(`competencies/competence`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} competencies`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch competencies`
          );
        }
      )
    );
  }

  public fetch_by_team_uuid(team_uuid: string): Observable<any> {
    const params = { team: team_uuid, expand: 'thing_level' };
    return this.api_service
      .get(`competencies/competence/fetch_by_team_uuid`, params)
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} fetched team competencies`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to fetch team competencies`
            );
          }
        )
      );
  }
}
