import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewSetHttpService } from './rest/ViewSetHttpService';
import { SpecialityValidation } from '@models/specialities/speciality-validation';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpecialityValidationHttpService extends ViewSetHttpService<SpecialityValidation> {
  http: HttpClient;
  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }
  public viewSetUrl = 'specialities/validation';

  public createWithReviewers(
    validationData: Partial<SpecialityValidation>,
    reviewers: string[]
  ): Observable<any> {
    return this.http.post<{
      validationData: Partial<SpecialityValidation>;
      reviewers: string[];
    }>(`${this.getUrl()}/create_with_reviewers`, {
      validation_data: validationData,
      reviewers: reviewers,
    });
  }

  public updateReviewers(
    validationUuid: string,
    reviewers: string[]
  ): Observable<any> {
    return this.http.post<{ validationUuid: string; reviewers: string[] }>(
      `${this.getUrl()}/${validationUuid}/update_reviewers`,
      {
        reviewers: reviewers,
      }
    );
  }

  public copySpecialityToLibrary(validationUuid: string): Observable<any> {
    return this.http.post<{ validationUuid: string }>(
      `${this.getUrl()}/${validationUuid}/copy_speciality_to_library`,
      {}
    );
  }
}
