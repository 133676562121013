import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Speciality } from '@models/specialities/speciality';

@Component({
  selector: 'app-add-library-speciality-dialog',
  templateUrl: './add-library-speciality-dialog.component.html',
  styleUrls: ['./add-library-speciality-dialog.component.css'],
})
export class AddLibrarySpecialityDialogComponent implements OnInit {
  form: UntypedFormGroup;

  filterString = '';
  constructor(
    public dialogRef: MatDialogRef<AddLibrarySpecialityDialogComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      specialities: Speciality[];
      parentSpecialities: Speciality[];
      onSubmit(formData: Speciality[]): void;
      onOpenCreateDialog(): void;
    }
  ) {}

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      search: ['', []],
      selectedSpecialities: [[], [Validators.required]],
    });
  }

  search(): void {
    this.filterString = this.form.value.search;
  }

  getFilteredSpecialities(): Speciality[] {
    if (this.filterString.length > 0) {
      return this.data.specialities.filter((speciality) =>
        speciality.name.includes(this.filterString)
      );
    }
    return this.data.specialities;
  }

  selectSpeciality(speciality: Speciality): void {
    const selectedSpecialities = this.form.value.selectedSpecialities;
    if (
      !selectedSpecialities.find((s: Speciality) => s.uuid === speciality.uuid)
    ) {
      selectedSpecialities.push(speciality);
      this.form.controls.selectedSpecialities.setValue(selectedSpecialities);
    }
  }

  isParentSpeciality(specialityUuid: string): boolean {
    return !!this.data.parentSpecialities?.find(
      (speciality) => speciality.uuid === specialityUuid
    );
  }

  openCreateDialog(): void {
    this.data.onOpenCreateDialog();
    this.dialogRef.close();
  }

  close(): void {
    this.data.onSubmit(this.form.value.selectedSpecialities);
    this.dialogRef.close();
  }
}
