<div class="router-outlet-container">
  <ng-container *ngIf="is_data_loaded; then component_content; else preloader">
  </ng-container>
</div>

<ng-template #preloader>
  <app-preloader></app-preloader>
</ng-template>

<ng-template #component_content>
  <div class="app-admin-survey-edit-linear-container">
    <div fxLayout="row" fxLayoutGap="2rem">
      <div fxFlex="25rem">
        <div>
          <div class="app-admin-survey-edit-linear-create-question-panel"
               (click)="on_question_create_form_show()">
            <div fxLayout="row">
              <div fxFlex="5rem">
                <div class="app-admin-survey-edit-linear-create-question-panel-circle">
                  +
                </div>
              </div>
              <div fxFlex>
                <div class="app-admin-survey-edit-linear-create-question-panel-text">
                  Добавить вопрос
                </div>
              </div>
            </div>
          </div>
        </div>
        <div cdkDropList class="app-admin-survey-edit-linear-question-list" (cdkDropListDropped)="on_drop($event)">
          <div class="app-admin-survey-edit-linear-question-listitem"
               *ngFor="let question of questions"
               cdkDrag [cdkDragDisabled]="is_reordering_disabled"
               [class.app-admin-survey-edit-linear-question-listitem-selected]="is_question_listitem_selected(question)"
               (click)="on_question_select(question)">
            {{ question.title }}
          </div>
        </div>
      </div>
      <div fxFlex>
        <ng-container *ngIf="is_question_create_form_shown; then tmpl_question_create; else tmpl_selected_question">
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_question_create>
  <div class="app-admin-survey-edit-linear-question-create-container">
    <div fxLayout="row">
      <div fxFlex>
        <div class="app-admin-survey-edit-linear-question-create-header-title">
          Новый вопрос
        </div>
        <div class="app-admin-survey-edit-linear-question-create-header-hint">
          Подсказочка
        </div>

        <form [formGroup]="question_create_form">
          <!-- <div>
              <mat-form-field [style.width.rem]=30>
                  <mat-label>Тип вопроса</mat-label>
                  <mat-select required [(value)]="question_create_selected_type" formControlName="question_type">
                      <mat-option value="save_answered_option">Простой вопрос</mat-option>
                      <mat-option value="assert_competence_level">Владение навыком</mat-option>
                  </mat-select>
              </mat-form-field>
          </div> -->

          <div>
            <mat-form-field [style.width.rem]=30>
              <input matInput required placeholder="Название" type="text" formControlName="question_title">
              <!-- <mat-error>
                  {{errors.ou_name}}
              </mat-error> -->
            </mat-form-field>
          </div>

          <div>
            <mat-form-field [style.width.rem]=30>
              <input matInput placeholder="Описание" type="text" formControlName="question_description">
              <!-- <mat-error>
                  {{errors.ou_description}}
              </mat-error> -->
            </mat-form-field>
          </div>

          <button mat-raised-button
                  [disabled]="question_create_form.invalid || is_question_create_form_loading"
                  (click)="on_create_question()">
            Добавить вопрос
          </button>

          &nbsp;

          <button mat-raised-button (click)="on_create_question_cancel()">
            Отмена
          </button>

        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_selected_question>
  <div class="app-admin-survey-edit-linear-selected-question-container">
    <div fxLayout="row">
      <div fxFlex>
        <div *ngIf="selected_question">
          <div class="app-admin-survey-edit-linear-selected-question-header">
            <div fxLayout="row">
              <ng-container
                [ngTemplateOutlet]=" get_selected_question_edit_mode() ? tmpl_selected_question_edit_mode : tmpl_selected_question_normal_mode">

              </ng-container>
            </div>
          </div>

          <mat-divider></mat-divider>

          <div class="app-admin-survey-edit-linear-selected-question-options">

            <ng-container
              *ngIf="is_selected_question_options_loaded; then tmpl_selected_question_options; else preloader">
            </ng-container>

          </div>

        </div>
        <div *ngIf="!selected_question">
          <div class="app-admin-survey-edit-linear-selected-question-empty">
            Выберите вопрос из списка
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_selected_question_edit_mode>
  <form [formGroup]="question_edit_form" fxFlex="90" fxLayout="column">
    <input #question_new_title class="app-admin-survey-linear-selected-question-edit-field"
           formControlName="question_title" matInput required placeholder="Вопрос*" autocomplete="off" maxlength="255">
    <div class="app-admin-survey-linear-selected-question-edit-field-dash"
         [class.warn]="question_new_title.value.length == 0"></div>
    <textarea #question_new_description class="app-admin-survey-linear-selected-question-edit-description"
              formControlName="question_description" required matInput placeholder="Описание*"
              [class.warn]="question_new_description.value.length == 0"></textarea>
  </form>
  <div fxFlex="10" fxLayout="column" fxLayoutAlign="center"
       class="app-admin-survey-edit-linear-selected-question-button-container">
    <div class="app-admin-survey-edit-linear-selected-question-button-area">
      <button mat-icon-button
              color="warn"
              matTooltip="Отмена"
              aria-label="Cancel editing"
              (click)="on_button_selected_question_cancel_edit()">
        <mat-icon>close</mat-icon>
      </button>
      <button mat-icon-button
              matTooltip="Сохранить"
              aria-label="Save question"
              (click)="save_select_question_new_data()"
              [disabled]="question_edit_form.invalid">
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_selected_question_normal_mode>
  <div fxFlex="90">
    <div class="app-admin-survey-edit-linear-selected-question-title">
      {{ selected_question.title }}
    </div>
    <div class="app-admin-survey-edit-linear-selected-question-description"
         [innerHTML]="selected_question.description | newlineToBr">
    </div>
  </div>
  <div fxFlex="10" fxLayout="column" fxLayoutAlign="center"
       class="app-admin-survey-edit-linear-selected-question-button-container">
    <div class="app-admin-survey-edit-linear-selected-question-button-area">
      <button mat-icon-button
              aria-label="Delete question"
              matTooltip="Удалить? Связанные ответы пользователей, будут так же удалены."
              matTooltipClass="app-admin-survey_buttons_tooltip"
              (click)="on_button_question_delete(selected_question)"
              [disabled]="is_question_delete_button_diabled">
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-icon-button
              aria-label="Edit question"
              matTooltip="Редактировать"
              (click)="on_button_selected_question_edit()">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>
</ng-template>


<ng-template #tmpl_selected_question_options>
  <div class="app-admin-survey-edit-linear-selected-question-options-container">
    <div *ngIf="!is_answer_option_create_form_shown">
      <div fxLayout="row">
        <div fxFlex>
          <div *ngIf="!selected_question_options.length">
            <div class="app-admin-survey-edit-linear-selected-question-options-empty">
              Пока нет вариантов ответа, добавьте их
            </div>
          </div>
          <div *ngIf="selected_question_options.length">
            <div *ngFor="let answer of selected_question_options">
              <ng-container
                [ngTemplateOutlet]=" answer.uuid == get_answer_editing_uuid() ? tmpl_answer_edit_mode : tmpl_answer_normal_mode"
                [ngTemplateOutletContext]=" {answer:answer} ">
              </ng-container>
            </div>
          </div>

          <div class="app-admin-survey-edit-linear-selected-question-options-panel"
               (click)="on_answer_option_create_form_show()">
            <div fxLayout="row">
              <div fxFlex="5rem">
                <div class="app-admin-survey-edit-linear-selected-question-options-panel-circle">
                  +
                </div>
              </div>
              <div fxFlex>
                <div class="app-admin-survey-edit-linear-selected-question-options-panel-text">
                  Добавить вариант ответа
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="is_answer_option_create_form_shown">
      <div class="app-admin-survey-edit-linear-question-create-header-title">
        Новый вариант ответа
      </div>
      <div class="app-admin-survey-edit-linear-question-create-header-hint">
        <!-- Подсказочка -->
      </div>

      <form [formGroup]="answer_option_create_form">

        <div>
          <mat-form-field [style.width.rem]=20>
            <input matInput required placeholder="Название" type="text" formControlName="answer_option_title">
            <!-- <mat-error>
                {{errors.ou_name}}
            </mat-error> -->
          </mat-form-field>
        </div>

        <div>
          <mat-form-field [style.width.rem]=20>
            <input matInput placeholder="Описание" type="text" formControlName="answer_option_description">
            <!-- <mat-error>
                {{errors.ou_description}}
            </mat-error> -->
          </mat-form-field>
        </div>

        <button mat-raised-button
                [disabled]="answer_option_create_form.invalid || is_answer_option_create_form_loading"
                (click)="on_create_answer_option()">
          Добавить вариант
        </button>

        &nbsp;

        <button mat-raised-button
                (click)="on_create_answer_option_cancel()">
          Отмена
        </button>

      </form>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_answer_normal_mode let-answer='answer'>
  <div fxLayout="row">
    <div fxFlex="90">
      <div class="app-admin-survey-edit-linear-selected-question-options-listitem">
        <div class="app-admin-survey-edit-linear-selected-question-options-listitem-title">
          {{ answer.title }}
        </div>
        <div class="app-admin-survey-edit-linear-selected-question-options-listitem-description"
             [innerHTML]="answer.description | newlineToBr">
        </div>
      </div>
    </div>
    <div fxFlex="10" fxLayout="column" fxLayoutAlign="center"
         class="app-admin-survey-edit-linear-selected-question-button-container">
      <div class="app-admin-survey-edit-linear-selected-question-button-area">
        <button mat-icon-button
                aria-label="Delete answer"
                matTooltip="Удалить"
                (click)="on_button_answer_option_delete(answer)"
                [disabled]="is_answer_option_delete_button_disabled">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button
                aria-label="Edit answer"
                matTooltip="Редактировать"
                (click)="on_button_answer_option_edit(answer)">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_answer_edit_mode let-answer='answer'>
  <div fxLayout="row">
    <form [formGroup]="answer_edit_form" fxFlex="90" fxLayout="column">
      <input #answer_new_title class="app-admin-survey-linear-answer-edit-field"
             formControlName="answer_title" matInput required placeholder="Ответ*" autocomplete="off" maxlength="255">
      <div class="app-admin-survey-linear-answer-edit-field-dash"
           [class.warn]="answer_new_title.value.length == 0"></div>
      <textarea #answer_new_description class="app-admin-survey-linear-answer-edit-description"
                formControlName="answer_description" matInput placeholder="Описание*">
      </textarea>
    </form>
    <div fxFlex="10" fxLayout="column" fxLayoutAlign="center"
         class="app-admin-survey-edit-linear-answer-button-container">
      <div class="app-admin-survey-edit-linear-answer-button-area">
        <button mat-icon-button
                color="warn"
                matTooltip="Отмена"
                aria-label="Cancel editing"
                (click)="on_button_answer_cancel_edit()">
          <mat-icon>close</mat-icon>
        </button>
        <button mat-icon-button
                matTooltip="Сохранить"
                aria-label="Save question"
                (click)="save_answer_new_data(answer)"
                [disabled]="answer_edit_form.invalid">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
