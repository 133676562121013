import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@models/user/user';
import { UserHttpService } from '@services/http/UserHttpService';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-select-users-with-search',
  templateUrl: './select-users-with-search.component.html',
  styleUrls: ['./select-users-with-search.component.css'],
})
export class SelectUsersWithSearchComponent implements OnInit {
  @Input() users: User[];
  @Input() checkedUsers: User[];
  @Output() selectedUsersChange: EventEmitter<User[]> = new EventEmitter<
    User[]
  >();
  form: UntypedFormGroup;
  newUsers: User[] = [];
  addedUser = new BehaviorSubject<User>(null);

  options: User[];

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _userHttpService: UserHttpService
  ) {}

  ngOnInit(): void {
    this.newUsers.push(...this.users);
  }

  onUserSelected(selection: User): void {
    if (!this.newUsers.find((user) => user.uuid === selection.uuid)) {
      this.newUsers.push(selection);
      this.addedUser.next(selection);
    }
  }

  onSelectUsers(users: User[]): void {
    this.selectedUsersChange.emit(users);
  }
}
