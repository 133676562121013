import { Component, Input, OnInit } from '@angular/core';
import { SpecialityValidationHttpService } from '@services/http/SpecialityValidationHttpService';
import { AsyncList } from '@rest/AsyncList';
import { SpecialityValidation } from '@models/specialities/speciality-validation';
import { SpecialityReviewerHttpService } from '@services/http/SpecialityReviewerHttpService';
import { AuthService } from '../../../../services/auth/auth.service';
import { SpecialityReviewer } from '@models/specialities/speciality-reviewer';
import { combineLatest } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verifications',
  templateUrl: './verifications.component.html',
  styleUrls: ['./verifications.component.css'],
})
export class VerificationsComponent implements OnInit {
  userUuid: string;

  @Input() selfCreatedValidations: AsyncList<SpecialityValidation>;
  @Input() selfSpecialityReviewer: AsyncList<SpecialityReviewer>;

  selfReviewerValidations: SpecialityValidation[];

  allValidations: SpecialityValidation[] = [];
  selfAnsweredValidations: SpecialityValidation[] = [];
  selfUnansweredValidations: SpecialityValidation[] = [];
  acceptedValidations: SpecialityValidation[];
  declinedValidations: SpecialityValidation[];

  validationUuidList = [];

  validationsReviewers: SpecialityReviewer[];

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _specialityValidationHttpService: SpecialityValidationHttpService,
    private _specialityReviewerHttpService: SpecialityReviewerHttpService
  ) {}

  ngOnInit(): void {
    this.userUuid = this._authService.get_current_user_uuid();

    this.selfSpecialityReviewer.state.items.forEach((specialityReviewer) => {
      if (specialityReviewer.status !== 0) {
        this.selfAnsweredValidations.push(
          specialityReviewer.speciality_validation as SpecialityValidation
        );
      } else {
        this.selfUnansweredValidations.push(
          specialityReviewer.speciality_validation as SpecialityValidation
        );
      }
    });

    this.selfReviewerValidations = this.selfSpecialityReviewer.state.items.map(
      (reviewer) => reviewer.speciality_validation as SpecialityValidation
    );
    this.selfCreatedValidations.state.items.forEach((validation) =>
      this._addToAllValidations(validation)
    );
    this.selfReviewerValidations.forEach((validation) =>
      this._addToAllValidations(validation)
    );

    this.acceptedValidations = this.allValidations.filter(
      (validation) => validation.status === 1
    );
    this.declinedValidations = this.allValidations.filter(
      (validation) => validation.status === 2
    );
  }

  private _addToAllValidations(validation: SpecialityValidation): void {
    if (!this.validationUuidList.includes(validation.uuid)) {
      this.validationUuidList.push(validation.uuid);
      this.allValidations.push(validation);
    }
  }

  getFilteredValidations(
    validations: SpecialityValidation[]
  ): SpecialityValidation[] {
    return validations.filter(
      (validation) => validation.status !== 1 && validation.status !== 2
    );
  }

  redirectToValidation(validation: SpecialityValidation): void {
    this._router.navigate([`verifications/${validation.uuid}/speciality`]);
  }

  getPrettyValidationDate(dateStr: string): string {
    const date = new Date(dateStr);
    return `${date.getDate()} ${date.toLocaleString('default', {
      month: 'short',
    })} ${date.getFullYear()}`;
  }
}
