<div class='router-outlet-container'>
  <ng-container
    *ngIf='teams.state.isLoaded; then component_content; else preloader'
  >
  </ng-container>
</div>

<ng-template #preloader>
  <app-preloader></app-preloader>
</ng-template>

<ng-template #component_content>
  <div class='app-admin-teams-dashboard-container'>
    <div fxLayout='row'>
      <div fxFlex>
        <div class='app-admin-teams-dashboard-main-container'>
          <div class='app-admin-teams-dashboard-main-header-container'>
            <div fxLayout='row'>
              <div fxFlex='70'>
                <div class='app-admin-teams-dashboard-main-header-text'>
                  Команды
                </div>
              </div>
              <div fxFlex>
                <div class='app-admin-teams-dashboard-main-header-controls'>
                  <a
                    mat-raised-button
                    (click)='onDialogShow()'
                    class='app-admin-teams-dashboard-main-header-controls-link'
                    [attr.data-test-id]='
                      testDataAttributes.createNewTeamButtonId
                    '
                  >
                    <mat-icon>group_add</mat-icon>
                    Создать команду
                  </a>
                </div>
              </div>
            </div>
          </div>

          <app-show-on-settings
            [settingName]="'use_team_validation'"
            [showOnActiveSetting]='true'
            [showOnDisabledSetting]='false'
          >
            <mat-tab-group
              animationDuration='0ms'
              mat-stretch-tabs='false'
              dynamicHeight
              *ngIf='teams.state.isLoaded'
            >
              <mat-tab label='Все команды'>
                <app-teams-dashboard-list-all
                  [teams]='teams'
                ></app-teams-dashboard-list-all>
              </mat-tab>
              <mat-tab>
                <ng-template matTabLabel>
                  <span
                    matBadge='{{ getUnverifiedTeams.length }}'
                    matBadgeSize='small'
                    [matBadgeHidden]='getUnverifiedTeams.length === 0'
                    matBadgeOverlap='false'
                  >
                    Неверифицированные команды
                  </span>
                </ng-template>
                <app-teams-dashboard-list-unverified
                  [teams]='teams'
                  [unverifiedTeams]='getUnverifiedTeams'
                ></app-teams-dashboard-list-unverified>
              </mat-tab>
            </mat-tab-group>
          </app-show-on-settings>
          <app-show-on-settings
            [settingName]="'use_team_validation'"
            [showOnActiveSetting]='false'
            [showOnDisabledSetting]='true'
          >
            <app-teams-dashboard-list-all
              [teams]='teams'
            ></app-teams-dashboard-list-all>
          </app-show-on-settings>
        </div>
      </div>
    </div>
  </div>
</ng-template>
