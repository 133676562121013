import { Component, OnInit } from '@angular/core';

import { AsyncList } from '@rest/AsyncList';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { Domain } from '@models/ontology/domain';
import { LibraryItemProps } from '@components/common/library-item/library-item.component';

@Component({
  selector: 'app-library-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.css'],
})
export class DomainsComponent implements OnInit {
  public domains: AsyncList<Domain>;
  constructor(private _domainHttpService: DomainHttpService) {}

  ngOnInit(): void {
    this.domains = new AsyncList(this._domainHttpService);
    this.domains.requestParams = {
      params: {
        team__isnull: 'True',
        parent_domain__isnull: 'True',
      },
    };
    this.domains.load();
  }

  serializeDomain(domain: Domain): LibraryItemProps {
    return {
      content: domain.name,
      footer: domain.description,
      linkUrl: `/library/domains/${domain.uuid}`,
    };
  }
}
