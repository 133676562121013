<div class="level" *ngFor="let level of levels">
  <div class="level-title">{{ level.title }}</div>
  <div
    class="empty-message"
    *ngIf="getFilteredCompetencies(level).length === 0"
  >
    На этом уровне нет пользователей
  </div>
  <div class="user-list">
    <div
      class="user-list-item"
      *ngFor="let competence of getFilteredCompetencies(level)"
    >
      <a routerLink="/account/user-profile/{{ competence.user.uuid }}">
        <div fxLayout="row">
          <div fxFlex="2rem">
            <div class="user-list-item-avatar">
              {{ getUserInitials(competence.user) }}
            </div>
          </div>
          <div class="user-list-item-name">
            {{ competence.user.first_name }} {{ competence.user.last_name }}
          </div>
          <div *ngIf="competence.is_verified">
            <mat-icon
              class="verified-icon"
              matTooltip="{{
                getVerifiedCompetenceTooltipText(competence.verified_by) +
                  '\n' +
                  (competence.date_verified | date: 'dd-MM-yyyy hh:mm')
              }}"
              matTooltipClass="verified-tooltip"
            >
              done_outline
            </mat-icon>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
