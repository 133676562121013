import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { AlertService } from 'src/app/services/ui/ui-alert.service';

export interface ThingLevelFormData {
  submitTitle: string;
  formTitle: string;
  initialValues: {
    title: string;
    description: string;
  };
  onSubmit(
    formValue: any,
    dialogRef: MatDialogRef<ThingLevelFormComponent>
  ): void;
}

@Component({
  selector: 'app-thing-level-form',
  templateUrl: './thing-level-form.component.html',
  styleUrls: ['./thing-level-form.component.css'],
})
export class ThingLevelFormComponent {
  form: UntypedFormGroup;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<ThingLevelFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ThingLevelFormData
  ) {
    this.form = this.formBuilder.group({
      title: [
        this.data.initialValues.title,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      description: [
        this.data.initialValues.description,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  submit() {
    this.data.onSubmit(this.form.value, this.dialogRef);
  }
}
