import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

import { LoggingService } from 'src/app/services/logging.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    protected readonly logging_service: LoggingService,
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
        this.logging_service.debug(`AuthGuard: user is not authenticated, proceed to login`);
        await this.keycloak.login({
            redirectUri: window.location.origin + state.url,
        });
    }

    // Get the roles required from the route.
    const required_roles = route.data.roles;

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(required_roles instanceof Array) || required_roles.length === 0) {
        this.logging_service.debug(`AuthGuard: route is unprotected, allowing navigation`);
        return true;
    }

    // Allow the user to proceed if all the required roles are present.
    // this.logging_service.debug(`AuthGuard: navigation required roles: ${required_roles}, user roles: ${this.roles}`);
    const navigation_permission_granted = required_roles.every((role) => this.roles.includes(role));
    if (navigation_permission_granted) {
        this.logging_service.debug(`AuthGuard: route allowed, found required ${required_roles}`);
        return navigation_permission_granted;
    } else {
        this.logging_service.debug(`AuthGuard: route navigation permission denied, missing required ${required_roles}`);
        this.router.navigate(['/401']);
        return navigation_permission_granted;
    }
  }
}
