<h2 mat-dialog-title>
    Новое требование для {{ data.speciality.name }}
</h2>
<mat-dialog-content>

    <div fxLayout="row" fxLayoutGap="3rem">
        <div fxFlex="25rem">
            <ng-container *ngIf="thing_search_selected; then tmpl_thing_selected; else tmpl_thing_search">
            </ng-container>
        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button
        (click)="on_create_claim()"
        [disabled]="!is_all_data_specified || is_claim_create_loading">
        Добавить
    </button>
    <button mat-button mat-dialog-close>Отмена</button>
</mat-dialog-actions>

<ng-template #tmpl_thing_search>
    <mat-form-field class="app-admin-speciality-details-tab-profile-dialog-claim-search-input-field">
        <mat-label>Укажите компетенцию</mat-label>

        <input matInput type="text"
            placeholder="Ansible"
            [formControl]="thing_search_form_control"
            [matAutocomplete]="auto"
            (input)="on_thing_search(thing_search_form_control.value)">
        <mat-autocomplete #auto="matAutocomplete"
            (optionSelected)="on_thing_search_autocomplete_selected($event.option.value)">
            <mat-option *ngFor="let thing of thing_search_results" [value]="thing">
                <strong>{{ thing.name }}</strong>
                &nbsp;&nbsp;
                <small>( {{ get_thing_domain_name(thing) }} )</small>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</ng-template>

<ng-template #tmpl_thing_selected>
    <div class="app-admin-speciality-details-tab-profile-dialog-claim-thing-selected-container">
        <div class="app-admin-speciality-details-tab-profile-dialog-claim-thing-selected-hint">
            Компетенция:
        </div>
        <div class="app-admin-speciality-details-tab-profile-dialog-claim-thing-selected-text">
            {{ thing_search_selected.name }}
        </div>
        <div class="app-admin-speciality-details-tab-profile-dialog-claim-thing-selected-hint">
            Из домена:
        </div>
        <div class="app-admin-speciality-details-tab-profile-dialog-claim-thing-selected-text">
            {{ get_thing_domain_name(thing_search_selected) }}
        </div>
    </div>
</ng-template>

<ng-template #tmpl_expectations>
    <div class="app-admin-speciality-details-tab-profile-dialog-claim-expectations-container">
        <div>
            <mat-form-field>
                <mat-label>С какого грейда?</mat-label>
                <mat-select [(value)]="selected_grade">
                    <mat-option *ngFor="let grade of data.grades" [value]="grade">
                        {{ grade.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Уровень владения?</mat-label>
                <mat-select [(value)]="selected_level">
                    <mat-option [value]="1">Начинающий</mat-option>
                    <mat-option [value]="2">Уверенный</mat-option>
                    <mat-option [value]="3">Эксперт</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</ng-template>

<ng-template #tmpl_hint>
    <div class="app-admin-speciality-details-tab-profile-dialog-claim-hint-container">
        <div class="app-admin-speciality-details-tab-profile-dialog-claim-hint-icon">
            <mat-icon>help</mat-icon>
        </div>
        <div class="app-admin-speciality-details-tab-profile-dialog-claim-hint-text">
            <p>
                Какая компетенция требуется для {{ data.speciality.name }}?
            </p>
            <p>
                Для какого грейда? Для разных грейдов может быть разный набор навыков.
            </p>
        </div>
    </div>
</ng-template>
