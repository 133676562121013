import { Component, Input, OnInit } from '@angular/core';
import { SpecialityGrade } from '@models/specialities/speciality-grade';
import { ThingLevel } from '@models/ontology/thing-level';

@Component({
  selector: 'app-speciality-thing-grades',
  templateUrl: './speciality-thing-grades.component.html',
  styleUrls: ['./speciality-thing-grades.component.css'],
})
export class SpecialityThingGradesComponent {
  @Input() getGradeValue: any;
  @Input() grades: SpecialityGrade[];
  @Input() levels: ThingLevel[];
  @Input() onChange: (gradeOrderNumber: number, levelUuid: string) => void;
}
