import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LoggingService } from 'src/app/services/logging.service';
import { TitleService } from 'src/app/services/title.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { UserService } from 'src/app/services/user/user.service';

import { User } from 'src/app/models/user/user';
import { AcceptDialogComponent } from '../../../../../common-ui-elements/components/accept-dialog/accept-dialog.component';
import { Speciality } from '@models/specialities/speciality';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-user-main',
  templateUrl: './user-main.component.html',
  styleUrls: ['./user-main.component.css'],
})
export class UserMainComponent implements OnInit {
  @ViewChild('tooltip_delete_user') tooltip_delete_user: ElementRef;

  public user_uuid: string;
  public user: User;

  private uistate = {
    user_loaded: false,

    is_tooltip_button_delete_user_disabled: false,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private title_service: TitleService,
    private user_service: UserService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.title_service.set_title('Пользователь ...');
    this.route.params.subscribe((route_params) => {
      this.user_uuid = route_params.user_uuid;
      this.uistate.user_loaded = false;
      this.load_user();
    });
  }

  public get is_data_loaded(): boolean {
    return this.uistate.user_loaded;
  }

  public get is_tooltip_button_delete_user_disabled(): boolean {
    return this.uistate.is_tooltip_button_delete_user_disabled;
  }

  private load_user(): void {
    this.user_service.fetch_user_by_uuid(this.user_uuid).subscribe(
      (response) => {
        this.user = response as User;
        this.logging_service.debug(
          `${this.constructor.name} fetched user ${this.user.uuid}`
        );
        this.title_service.set_title(
          `${this.user.first_name} ${this.user.last_name}`
        );
        this.uistate.user_loaded = true;
        // this.load_memberships();
        // this.load_specialities_relations();
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to load user ${this.user_uuid}`
        );
        this.alert_service.error(`Ошибка загрузки пользователя: ${err.status}`);
      }
    );
  }

  public util_user_get_initials_for_avatar(user: User): string {
    const first_name_initial = user.first_name.charAt(0);
    const last_name_initial = user.last_name.charAt(0);
    return `${first_name_initial}${last_name_initial}`;
  }

  public on_button_user_delete(): void {
    this._dialog
      .open(AcceptDialogComponent, {
        data: {
          title: `Удалить пользователя?`,
          message: 'Будут потеряны все связанные данные',
          acceptLabel: 'Удалить',
          rejectLabel: 'Отмена',
        },
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) {
          this.user_service.delete(this.user.uuid).subscribe(
            (response) => {
              this.uistate.is_tooltip_button_delete_user_disabled = false;
              this.logging_service.debug(
                `${this.constructor.name} user deleted`
              );
              this.router.navigate([`/admin/users`]);
            },
            (err) => {
              this.uistate.is_tooltip_button_delete_user_disabled = false;
              this.logging_service.error(
                `${this.constructor.name} failed to delete user ${this.user.uuid}`
              );
              this.alert_service.error(`Ошибка удаления: ${err.status}`);
            }
          );
        }
      });
  }
}
