import { Component, OnInit } from '@angular/core';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Project } from '@models/projects/project';
import { AsyncList } from '@rest/AsyncList';
import { Company } from '@models/projects/company';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProjectHttpService } from '@services/http/ProjectHttpService';
import { CompanyHttpService } from '@services/http/CompanyHttpService';
import { User } from '@models/user/user';
import { get_full_parent_path } from '../../../../utils/getFullParentUrl';
import { UserHttpService } from '@services/http/UserHttpService';
import { AlertService } from '../../../../services/ui/ui-alert.service';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.css'],
})
export class ProjectEditComponent implements OnInit {
  projectUuid: string;
  project: AsyncDetail<Project>;
  companies: AsyncList<Company>;

  form: UntypedFormGroup;

  projectUsers: User[] = [];
  combinedUsers: User[] = [];
  searchUsers: AsyncList<User>;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _dateAdapter: DateAdapter<Date>,
    private _formBuilder: UntypedFormBuilder,
    private _alertService: AlertService,
    private _projectHttpService: ProjectHttpService,
    private _companyHttpService: CompanyHttpService,
    private _userHttpService: UserHttpService
  ) {
    this._dateAdapter.setLocale('ru-RU'); // dd/MM/yyyy
  }

  ngOnInit(): void {
    this.projectUuid = this._activatedRoute.snapshot.paramMap.get('pUuid');
    this.project = new AsyncDetail<Project>(
      this.projectUuid,
      this._projectHttpService
    );
    this.project.requestParams = {
      params: {
        expand: 'resources',
      },
    };
    this.project.load().subscribe(() => {
      this._setForm();
      this.companies = new AsyncList<Company>(this._companyHttpService);
      this.companies.load();
    });
  }

  private _setForm(): void {
    this.searchUsers = new AsyncList<User>(this._userHttpService);
    this.form = this._formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      company: [''],
      resources: [''],
      dateFrom: ['', [Validators.required]],
      dateTo: ['', [Validators.required]],
    });

    this.projectUsers.push(...(this.project.state.item.resources as User[]));
    this.combinedUsers.push(...this.projectUsers);

    this.form.controls.name.setValue(this.project.state.item.name);
    this.form.controls.description.setValue(
      this.project.state.item.description
    );
    this.form.controls.company.setValue(this.project.state.item.company);
    this.form.controls.resources.setValue(
      this.projectUsers.map((user) => user.uuid)
    );
    this.form.controls.dateFrom.setValue(
      new Date(this.project.state.item.planned_start)
    );
    this.form.controls.dateTo.setValue(
      new Date(this.project.state.item.planned_finish)
    );
  }

  search(search: string): void {
    this.searchUsers.setRequestParams({
      params: {
        search: search,
      },
    });
    this.searchUsers.load().subscribe(() => {
      this.combinedUsers = [];
      this.combinedUsers.push(...this.projectUsers);
      this.combinedUsers.push(...this.searchUsers.state.items);
    });
  }

  onBackClick(): void {
    history.back();
  }

  onProjectEdit(): void {
    this._projectHttpService
      .update(this.projectUuid, {
        name: this.form.controls.name.value,
        description: this.form.controls.description.value,
        company: this.form.controls.company.value,
        resources: this.form.controls.resources.value,
        planned_start: this.form.controls.dateFrom.value.toISOString(),
        planned_finish: this.form.controls.dateTo.value.toISOString(),
      })
      .subscribe(() => {
        this._alertService.success('Данные проекта обновлены');
      });
  }

  getFullParentPath = get_full_parent_path;
}
