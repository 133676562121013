import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { TeamMemberStatus } from '@models/teams/team-member-status';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TeamMemberStatusHttpService extends ViewSetHttpService<TeamMemberStatus> {
  http: HttpClient;
  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'teams/member_status';

  public deleteAllMemberStatus(teamUuid: string): Observable<any> {
    return this.http.post<{
      teamUuid: string;
    }>(`${this.getUrl()}/delete_team_members_status`, {
      team: teamUuid,
    });
  }
}
