import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

interface dict {
  [key: string]: boolean;
}

@Component({
  selector: 'app-progress-loader',
  templateUrl: './progress-loader.component.html',
  styleUrls: ['./progress-loader.component.css'],
})
export class ProgressLoaderComponent implements OnInit {
  @Input() dataReadySubject: Subject<{
    [key: string]: {
      isLoaded: boolean;
      loadingText: string;
    };
  }>;

  readyCount = 0;
  readyPersent = 0;
  loadingText = '';
  isLoading = false;

  seenMessages = [];

  constructor() {}

  ngOnInit(): void {
    this.dataReadySubject.subscribe((data) => {
      this.isLoading = true;
      this.readyCount = 0;
      const values = Object.values(data);
      this.loadingText = values.find(
        (item) => item.isLoaded && !this.seenMessages.includes(item.loadingText)
      )?.loadingText;

      if (this.loadingText) this.seenMessages.push(this.loadingText);

      values.forEach((val) => {
        this.readyCount += val.isLoaded ? 1 : 0;
        this.readyPersent = this.readyCount
          ? (this.readyCount / values.length) * 100
          : 0;
      });

      if (this.readyCount === values.length) {
        this.seenMessages = [];
        this.isLoading = false;
      }
    });
  }
}
