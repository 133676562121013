import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../services/auth/auth.service';
import { AssessmentHttpService } from '@services/http/AssessmentHttpService';
import { AssessmentResponseHttpService } from '@services/http/AssessmentResponseHttpService';
import { DomainHttpService } from '@services/http/DomainHttpService';

@Component({
  selector: 'app-new-review-result-detail',
  templateUrl: './new-review-result-detail.component.html',
  styleUrls: ['./new-review-result-detail.component.css'],
})
export class NewReviewResultDetailComponent implements OnInit {
  assessmentUuid: string;
  resolutionUuid: string;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _assessmentHttpService: AssessmentHttpService,
    private _assessmentResponseHttpService: AssessmentResponseHttpService,
    private _domainHttpService: DomainHttpService
  ) {}

  ngOnInit(): void {
    this.assessmentUuid = this._activatedRoute.snapshot.paramMap.get('aUuid');
    this.resolutionUuid = this._activatedRoute.snapshot.paramMap.get('resUuid');
  }
}
