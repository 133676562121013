import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  OnChanges,
} from '@angular/core';

import * as d3 from 'd3';

import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-line-simple',
  templateUrl: './line-simple.component.html',
  styleUrls: ['./line-simple.component.css'],
})
export class LineSimpleComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  set chartName(htmlElementClassName: string) {
    this._htmlElementSelectorClass = htmlElementClassName;
  }
  get chartName(): string {
    return this._htmlElementSelectorClass;
  }

  @Input() axisNameX: string;
  @Input() axisNameY: string;
  @Input() sizes: SizesLine = {};
  @Input()
  set data(inputData: ChartLineData[]) {
    this._data = inputData.map((data) => {
      return [new Date(data[0]), data[1]];
    });
  }
  get data(): ChartLineData[] {
    return this._data;
  }

  private _htmlElementSelectorClass: string;
  private _data: ChartLineData[];

  private _svgOptions: SizesLine = {
    width: 400,
    height: 100,
    margin: {
      top: 30,
      right: 10,
      bottom: 50,
      left: 30,
    },
    colors: {
      bar: '#eee',
    },
  };

  constructor(private _loggingService: LoggingService) {}

  ngOnInit(): void {
    this._loggingService.debug(`${this.constructor.name} init`);
    this._svgOptions = { ...this._svgOptions, ...this.sizes };
  }

  ngAfterViewInit(): void {
    this._drawChart();
  }

  ngOnChanges(): void {
    this._drawChart();
  }

  private get _svgWidth(): number {
    return (
      this._svgOptions.width + this._svgMarginLeft + this._svgMarginRight + 50
    );
  }
  private get _svgHeight(): number {
    return (
      this._svgOptions.height + this._svgMarginTop + this._svgMarginBottom + 50
    );
  }
  private get _svgMarginTop(): number {
    return this._svgOptions.margin.top;
  }
  private get _svgMarginBottom(): number {
    return this._svgOptions.margin.bottom;
  }
  private get _svgMarginLeft(): number {
    return this._svgOptions.margin.left;
  }
  private get _svgMarginRight(): number {
    return this._svgOptions.margin.right;
  }
  private get _svgColorBar(): string {
    return this._svgOptions.colors.bar;
  }

  private get _svgEffectiveWidth(): number {
    return this._svgWidth - this._svgMarginLeft - this._svgMarginRight;
  }
  private get _svgEffectiveHeight(): number {
    return this._svgHeight - this._svgMarginTop - this._svgMarginBottom;
  }

  private _drawChart() {
    this._loggingService.debug(`${this.constructor.name} draw chart`);
    const parent = d3.select(`.${this.chartName}`);

    // redraw entire element
    parent.select('svg').remove();

    const svg = parent
      .append('svg')
      .attr('width', this._svgWidth)
      .attr('height', this._svgHeight);

    const g = svg
      .append('g')
      .attr(
        'transform',
        'translate(' + this._svgMarginLeft + ',' + this._svgMarginTop + ')'
      );

    const x_scale = d3
      .scaleTime()
      .domain(d3.extent(this.data, (d) => d[0] as Date))
      .range([0, this._svgEffectiveWidth]);

    const y_scale = d3
      .scaleLinear()
      .domain([0, d3.max(this.data, (d) => +d[1])])
      .range([this._svgEffectiveHeight, 0]);

    g.append('g')
      .attr('transform', 'translate(0,' + this._svgEffectiveHeight + ')')
      .call(d3.axisBottom(x_scale))
      .selectAll('text')
      .attr('y', 0)
      .attr('x', -25)
      .attr('dy', '2em')
      .attr('transform', 'rotate(25)')
      .style('text-anchor', 'start');

    const max_ticks = this.data.length > 5 ? 5 : this.data.length;
    g.append('g')
      .call(d3.axisLeft(y_scale).ticks(max_ticks).tickFormat(d3.format('.0f')))
      .append('text')
      .attr('fill', '#000')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('dx', '0.71em')
      .attr('text-anchor', 'end')
      .text(this.axisNameY);

    // TODO: График работает не совсем правильно, нужно переделать
    g.append('path')
      .datum(this.data)
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 1.5)
      .attr(
        'd',
        d3
          .line()
          .x((d) => x_scale(d[0]))
          .y((d) => y_scale(Number(d[1])))
      );
  }
}

type ChartLineData = [number | string | Date, number];

interface SizesLine {
  width?: number;
  height?: number;
  margin?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  colors?: {
    bar?: string;
  };
}
