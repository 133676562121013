import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DevelopmentPlanActivity } from '@models/tracks/development-plan-activity';
import { AsyncList } from '@rest/AsyncList';
import { Thing } from '@models/ontology/thing';
import { ThingHttpService } from '@services/http/ThingHttpService';
import { DevelopmentPlanActivityHttpService } from '@services/http/DevelopmentPlanActivityHttpService';
import { DevelopmentPlanStep } from '@models/tracks/development-plan-step';
import { DevelopmentPlanStepHttpService } from '@services/http/DevelopmentPlanStepHttpService';

@Component({
  selector: 'app-add-edit-pdp-activity-dialog',
  templateUrl: './add-edit-pdp-activity-dialog.component.html',
  styleUrls: ['./add-edit-pdp-activity-dialog.component.css'],
})
export class AddEditPdpActivityDialogComponent implements OnInit {
  form: UntypedFormGroup;
  things: AsyncList<Thing>;
  options: Thing[];

  selectedClaims: string[] = [];

  isAdditionalSettingsShowed = false;

  constructor(
    public dialogRef: MatDialogRef<AddEditPdpActivityDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      pdpUuid: string;
      dialogType: 'activity' | 'step';
      activity: DevelopmentPlanActivity;
      step: DevelopmentPlanStep;
      onSubmit(selectedClaims: string[]): void;
    },
    private _formBuilder: UntypedFormBuilder,
    private _thingHttpService: ThingHttpService,
    private _developmentPlanActivityHttpService: DevelopmentPlanActivityHttpService,
    private _developmentPlanStepHttpService: DevelopmentPlanStepHttpService
  ) {}

  ngOnInit(): void {
    this.things = new AsyncList<Thing>(this._thingHttpService);
    this.form = this._formBuilder.group({
      name: ['', [Validators.required]],
    });

    if (this.data.dialogType === 'activity') {
      if (this.data.activity) {
        this.form.controls.name.setValue(this.data.activity.name);
        this.selectedClaims = this.data.activity.things.map(
          (thing) => thing.uuid
        ) as string[];
      }
    } else {
      if (this.data.step) {
        this.form.controls.name.setValue(this.data.step.name);
        this.selectedClaims = this.data.step.things.map(
          (thing) => thing.uuid
        ) as string[];
      }
    }
  }

  onSelectedClaimsChange(selectedClaims: string[]): void {
    this.selectedClaims = selectedClaims;
  }

  onAccept(): void {
    if (this.data.dialogType === 'activity') {
      if (this.data.activity) {
        this._developmentPlanActivityHttpService
          .update(this.data.activity.uuid, {
            things: this.selectedClaims,
            name: this.form.controls.name.value,
          })
          .subscribe(() => {
            this.dialogRef.close(true);
          });
      } else {
        this._developmentPlanActivityHttpService
          .create({
            pdp: this.data.pdpUuid,
            things: this.selectedClaims,
            name: this.form.controls.name.value,
          })
          .subscribe(() => {
            this.dialogRef.close(true);
          });
      }
    } else {
      if (this.data.step) {
        this._developmentPlanStepHttpService
          .update(this.data.step.uuid, {
            name: this.form.controls.name.value,
            things: this.selectedClaims,
          })
          .subscribe((response) => {
            this.dialogRef.close(true);
          });
      } else {
        this._developmentPlanStepHttpService
          .create({
            pdp: this.data.pdpUuid,
            activity: this.data.activity.uuid,
            name: this.form.controls.name.value,
            things: this.selectedClaims,
          })
          .subscribe((response) => {
            this.dialogRef.close(true);
          });
      }
    }
  }
}
