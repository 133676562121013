<app-with-processing [processing]='!firstLevelTeamsDataReady'>
  <div class='teams-dashboard-container' *ngIf='firstLevelTeamsDataReady'>
    <div class='title'>Структура компании</div>
    <div>
      <mat-tab-group [animationDuration]="'0ms'" mat-stretch-tabs='false'>
        <mat-tab label='Все'>
          <app-teams-grid [teams]='firstLevelTeamsData'></app-teams-grid>
        </mat-tab>
        <mat-tab label='Административные'>
          <app-teams-grid [teams]='adminTeams'></app-teams-grid>
        </mat-tab>
        <mat-tab label='Agile'>
          <app-teams-grid [teams]='agileTeams'></app-teams-grid>
        </mat-tab>
        <mat-tab label='Гильдии'>
          <app-teams-grid [teams]='guildTeams'></app-teams-grid>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</app-with-processing>
