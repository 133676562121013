<div class='dialog-container'>
  <div class='title'>
    Выбор команды
  </div>

  <div class='description'>
    <br />
    <span>Административная команда, в который ты состоишь, </span>
    <div fxLayoutAlign='start center'>
      <span>автоматически отмечена галочкой </span>
      <img src='/assets/rb/icons/onboarding/checkbox.png'>
    </div>
    <br />
    <span>Тебе осталось только <strong>присоединиться</strong> к команде своего проекта.</span>
    <br />
    <br />
    <span>Если команды нет в списке, ты можешь <strong>пропустить</strong> этот шаг или <strong>добавить</strong> новую команду.</span>
  </div>

  <div mat-dialog-actions fxLayoutAlign='end center'>
    <button
      class='dialog-accept-button'
      color='primary'
      style="font-family: 'manrope';"
      [mat-dialog-close]='true'
      mat-raised-button
    >Понятно
    </button>
  </div>
</div>
