<div class="dialog-container">
  <div class="dialog-title">Создание бронирования</div>
  <div>
    <form [formGroup]="form" class="project-create-form">
      <div fxLayout="column">
        <mat-label class="textfield-label">Проект</mat-label>
        <ng-select
          appearance="outline"
          [items]="projects"
          (change)="onProjectSelect($event)"
          appendTo="body"
          class="users-search"
          formControlName="project"
          bindLabel="name"
          bindValue="uuid"
        >
        </ng-select>
      </div>
      <div fxLayout="column">
        <mat-label class="textfield-label">Задача</mat-label>
        <ng-select
          appearance="outline"
          [loading]="tasks?.state.isProcessing"
          [items]="tasks?.state.items"
          appendTo="body"
          class="users-search"
          formControlName="project_task"
        >
        </ng-select>
      </div>
      <div>
        <mat-label class="textfield-label">Участники проекта</mat-label>
        <ng-select
          appearance="outline"
          (search)="search($event.term)"
          [loading]="searchUsers.state.isProcessing"
          notFoundText="По вашему запросу не нашлось пользователей"
          loadingText="Ищем..."
          appendTo="body"
          class="users-search"
          formControlName="resource"
        >
          <ng-option *ngFor="let user of combinedUsers" [value]="user.uuid"
            >{{ user.first_name }} {{ user.last_name }} ({{
              user.email
            }})</ng-option
          >
        </ng-select>
      </div>
      <div fxLayout="column">
        <mat-label class="textfield-label">Тип бронирования</mat-label>
        <mat-form-field floatLabel="never" appearance="outline">
          <mat-select formControlName="kind">
            <mat-option value="S"> Soft </mat-option>
            <mat-option value="H"> Hard </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-label class="textfield-label">Загрузка, %</mat-label>
        <mat-form-field floatLabel="never" appearance="outline">
          <input matInput type="text" formControlName="utilization" />
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-label>Срок бронирования</mat-label>
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="dateFrom" />
            <input matEndDate formControlName="dateTo" />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button
      class="dialog-reject-button"
      [mat-dialog-close]="true"
      mat-raised-button
    >
      Отменить
    </button>
    <button
      class="dialog-accept-button"
      [disabled]="form.invalid"
      mat-raised-button
      (click)="submit()"
    >
      Сохранить
    </button>
  </div>
</div>
