<div fxLayout="row">
  <app-side-menu
    fxFlex="20"
    *ngIf="userMemberships.state.isLoaded"
    [title]="'Команды'"
    [optionsMap]="optionsMap"
  ></app-side-menu>
  <div fxFlex="80">
    <router-outlet></router-outlet>
  </div>
</div>
