import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';

import { CommonUIElementsModule } from 'src/app/modules/common-ui-elements/common-ui-elements.module';

import { OnboadingRoutingModule } from './onboarding-routing.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OnboardingMainNewComponent } from './components/onboarding-main-new/onboarding-main-new.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonComponentsModule } from '@components/common/common.module';
import { MatDividerModule } from '@angular/material/divider';
import { UiComponents } from '@components/ui/ui.module';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
  declarations: [OnboardingMainNewComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,

    MatStepperModule,
    MatInputModule,
    MatOptionModule,
    MatButtonModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatIconModule,
    MatSelectModule,

    CommonUIElementsModule,

    CommonUIElementsModule,

    OnboadingRoutingModule,
    MatTooltipModule,
    MatTabsModule,
    CommonComponentsModule,
    MatDividerModule,
    UiComponents,
    MatBadgeModule,
  ],
})
export class OnboardingModule {}
