<div class="dialog-container">
  <div class="dialog-title">Создание компании</div>
  <div>
    <form [formGroup]="form" class="company-create-form">
      <div fxLayout="column">
        <mat-label class="textfield-label">Название компании</mat-label>
        <mat-form-field floatLabel="never" appearance="outline">
          <input matInput type="text" formControlName="name" />
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-label class="textfield-label">Описание компании</mat-label>
        <mat-form-field floatLabel="never" appearance="outline">
          <input matInput type="text" formControlName="description" />
        </mat-form-field>
      </div>
    </form>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button
      class="dialog-reject-button"
      [mat-dialog-close]="true"
      mat-raised-button
    >
      Отменить
    </button>
    <button
      class="dialog-accept-button"
      [disabled]="form.invalid"
      mat-raised-button
      (click)="submit()"
    >
      Сохранить
    </button>
  </div>
</div>
