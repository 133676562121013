import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyQuestionService {
  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService
  ) {}

  public fetch_by_uuid(uuid: string): Observable<any> {
    return this.api_service
      .get(`surveys/question/${uuid}`, { expand: 'related_thing' })
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} fetched ${response.count}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to fetch question`
            );
          }
        )
      );
  }

  public fetch_all_for_survey_uuid(survey_uuid: string): Observable<any> {
    return this.api_service
      .get(`surveys/question`, {
        survey: survey_uuid,
        expand: 'parent_question',
      })
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} fetched ${response.count} survey questions`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to fetch survey questions`
            );
          }
        )
      );
  }

  public create(question_data: any): Observable<any> {
    return this.api_service.post(`surveys/question`, question_data, {}).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} created question with uuid ${response.uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to create survey question`
          );
        }
      )
    );
  }

  public update(question_uuid: string, question_data: any): Observable<any> {
    return this.api_service
      .patch(`surveys/question/${question_uuid}`, question_data, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} updated question with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to update survey question`
            );
          }
        )
      );
  }

  public delete(question_uuid: string): Observable<any> {
    return this.api_service
      .delete(`surveys/question/${question_uuid}`, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} deleted question with uuid ${question_uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to delete survey question`
            );
          }
        )
      );
  }
}
