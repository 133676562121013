import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewSetHttpService } from './rest/ViewSetHttpService';
import { AssessmentResponse } from '@models/reviews/assessment-response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssessmentResponseHttpService extends ViewSetHttpService<AssessmentResponse> {
  http: HttpClient;

  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'reviews/response';

  public accept({
    assessmentResponse,
    level,
    comment,
  }: {
    assessmentResponse: string;
    level: string;
    comment?: string;
  }): Observable<any> {
    return this.http.post<{
      assessmentResponse: AssessmentResponse;
      competenceAssertionUuid: string;
      level: string;
      comment?: string;
    }>(`${this.getUrl()}/${assessmentResponse}/accept`, { level, comment });
  }

  public skip(assessmentResponse: string): Observable<any> {
    return this.http.post<string>(
      `${this.getUrl()}/${assessmentResponse}/skip`,
      {}
    );
  }
}
