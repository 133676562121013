import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { PdpService } from 'src/app/services/track/pdp.service';

import { DevelopmentPlan } from 'src/app/models/tracks/development-plan';
import { Router } from '@angular/router';


@Component({
  selector: 'app-track-user-pdp-dialog-complete',
  templateUrl: './track-user-pdp-dialog-complete.component.html',
  styleUrls: ['./track-user-pdp-dialog-complete.component.css']
})
export class TrackUserPdpDialogCompleteComponent implements OnInit {

  private uistate = {
    is_button_complete_loading: false,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { pdp: DevelopmentPlan },
    public dialogRef: MatDialogRef<TrackUserPdpDialogCompleteComponent>,
    public router: Router,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private pdp_service: PdpService,
  ) { }

  ngOnInit(): void {
  }

  public get is_button_complete_loading(): boolean {
    return this.uistate.is_button_complete_loading;
  }

  public on_complete(): void {
    this.logging_service.debug(`${this.constructor.name} updating pdp status to completed`);
    this.pdp_service.update(this.data.pdp.uuid, {status: 'completed'}).subscribe(
      response => {
        this.data.pdp.status = 'completed';
        this.logging_service.debug(`${this.constructor.name} updated pdp status: completed`);
        this.alert_service.success(`План завершен`, {keepAfterRouteChange: true});
        this.dialogRef.close();
        this.router.navigate(['/dashboard']);
      },
      err => {
        this.logging_service.error(`${this.constructor.name} failed to update pdp status: completed`);
        this.alert_service.error(`Ошибка завершения плана: ${err.status}`);
      }
    );
  }

}
