<div class='router-outlet-container'>
  <ng-container *ngIf='data; then component_content; else preloader'>
  </ng-container>
</div>

<ng-template #preloader>
  <app-preloader></app-preloader>
</ng-template>

<ng-template #component_content>
  <h2>Статистика посещений</h2>

  <div fxLayout='row' fxLayoutGap='3rem' fxLayoutAlign='start center'>
    <div fxFlex='20rem'>
      <mat-card>
        <div class='metric-name'>Всего посещений за последние {{totalUsersDaysStats}} дней</div>
        <div class='metric-value-big'>
          {{ totalActiveUsers }}
        </div>
      </mat-card>
    </div>
    <div fxFlex='450px'>
      <div class='chart-title'>Статистика посещений за последние {{totalUsersDaysStats}} дней</div>
      <app-histogram-simple
        *ngIf='data'
        [chartName]="'active-users-hist'"
        [axisNameX]="''"
        [axisNameY]="''"
        [axisLabelsX]='getXAxisLabelsHist(dailyActiveUsers)'
        [data]='getHistChartData(dailyActiveUsers)'
        [sizes]="{
          colors: { bar: chartHistBarColor },
          xLabelsFont: { size: '8px' }
        }"
      >
      </app-histogram-simple>
      <div class='mat-date-range'>
        <mat-form-field appearance='fill'>
          <mat-label>Введите диапазон дат</mat-label>
          <mat-date-range-input [rangePicker]='dateRangePicker'>
            <input class='chart-title' matStartDate [(ngModel)]='startDateVisits'>
            <input class='chart-title' matEndDate [(ngModel)]='endDateVisits'
                   (dateChange)='dateUsersRangeChange()'>
          </mat-date-range-input>
          <mat-datepicker-toggle matPrefix [for]='dateRangePicker'>
          </mat-datepicker-toggle>
          <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div fxLayout='row' fxLayoutGap='3rem'>
    <div fxFlex='20rem'>
      <mat-card fxFlex>
        <div class='metric-name'>Сумма по периодам</div>
        <div class='metric-value-big'>
          {{ totalWauMau }}
        </div>
        <div *ngIf='wauMauLoading' fxLayoutAlign='center center' [style.font-size]='"1.5rem"'>Загрузка...</div>
      </mat-card>
    </div>
    <div fxFlex='450px'>
      <div class='chart-title'>Статистика посещений ({{ sliceType }})</div>
      <app-histogram-simple
        *ngIf='totalWauMau > 0'
        [chartName]="'mau-wau-hist'"
        [axisNameX]="''"
        [axisNameY]="''"
        [axisLabelsX]='getXAxisLabelsHist(wauMauUsers)'
        [data]='getHistChartData(wauMauUsers)'
        [sizes]="{
          colors: { bar: chartHistBarColor },
          xLabelsFont: { size: '8px' }
        }"
      >
      </app-histogram-simple>
      <div *ngIf='!totalWauMau' fxLayout='row' fxLayoutAlign='center center' class='empty-data'>
        <div>Нет данных</div>
      </div>
      <div fxLayout='row' fxLayoutGap='10px'>
        <div fxFlex class='mat-date-range'>
          <mat-form-field appearance='fill'>
            <mat-label>Введите диапазон дат</mat-label>
            <mat-date-range-input [rangePicker]='dateRangePickerWauMau'>
              <input class='chart-title' matStartDate [(ngModel)]='startDateWauMau'>
              <input class='chart-title' matEndDate [(ngModel)]='endDateWauMau'
                     (dateChange)='loadWauMauUsers()'>
            </mat-date-range-input>
            <mat-datepicker-toggle matPrefix [for]='dateRangePickerWauMau'>
            </mat-datepicker-toggle>
            <mat-date-range-picker #dateRangePickerWauMau></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div fxFlex>
          <mat-form-field appearance='fill'>
            <mat-label>Выберите срез</mat-label>
            <mat-select (selectionChange)='onActiveUsersSliceChange($event.value)' [value]='"WAU"'>
              <mat-option [value]='"DAU"'>DAU</mat-option>
              <mat-option [value]='"WAU"'>WAU</mat-option>
              <mat-option [value]='"MAU"'>MAU</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>


  <mat-divider></mat-divider>

  <h2>Пользователи и команды</h2>

  <div fxLayout='row' fxLayoutGap='3rem' fxLayoutAlign='start center'>
    <div fxFlex='20rem'>
      <mat-card>
        <div class='metric-name'>Пользователей</div>
        <div class='metric-value-big'>
          {{ totalUsers }}
        </div>
        <div class='metric-name'>Команд</div>
        <div class='metric-value-big'>
          {{ teamCount }}
        </div>
      </mat-card>
    </div>
    <div fxFlex='450px'>
      <div class='chart-title'>Регистрации по месяцам</div>
      <app-histogram-simple
        *ngIf='data'
        [chartName]="'stats-users-hist'"
        [axisNameX]="''"
        [axisNameY]="''"
        [axisLabelsX]='getXAxisLabelsHist(data.users.hist)'
        [data]='getHistChartData(data.users.hist)'
        [sizes]='{ colors: { bar: chartHistBarColor } }'
      >
      </app-histogram-simple>


      <div class='mat-date-range'>
        <mat-form-field appearance='fill'>
          <mat-label>Введите диапазон дат</mat-label>
          <mat-date-range-input [rangePicker]='dateRangePickerUsersTeam'>
            <input class='chart-title' matStartDate [(ngModel)]='startDateUsersTeams'>
            <input class='chart-title' matEndDate [(ngModel)]='endDateUsersTeams'
                   (dateChange)='dateRangeUsersTeamChange()'>
          </mat-date-range-input>
          <mat-datepicker-toggle matPrefix [for]='dateRangePickerUsersTeam'>
          </mat-datepicker-toggle>
          <mat-date-range-picker #dateRangePickerUsersTeam></mat-date-range-picker>
        </mat-form-field>
      </div>


    </div>
  </div>

  <mat-divider></mat-divider>

  <h2>Общий объем модели компетенций</h2>
  <div fxLayout='row' fxLayoutGap='3rem' fxLayoutAlign='start center'>
    <div fxFlex='20rem'>
      <mat-card>
        <div class='metric-name'>Компетенций</div>
        <div class='metric-value-big'>
          {{ data.things.total }}
        </div>
        <div class='metric-name'>Групп компетенций</div>
        <div class='metric-value-big'>
          {{ data.domains.total }}
        </div>
      </mat-card>
    </div>
    <div fxFlex='450px'>
      <div class='chart-title'>Создание компетенций по месяцам</div>
      <app-histogram-simple
        *ngIf='data'
        [chartName]="'stats-things-hist'"
        [axisNameX]="''"
        [axisNameY]="''"
        [axisLabelsX]='getXAxisLabelsHist(data.things.hist)'
        [data]='getHistChartData(data.things.hist)'
        [sizes]='{ colors: { bar: chartHistBarColor } }'
      >
      </app-histogram-simple>
    </div>
  </div>

  <mat-divider></mat-divider>

  <h2>Специальности</h2>
  <div fxLayout='row' fxLayoutGap='3rem' fxLayoutAlign='start center'>
    <div fxFlex='20rem'>
      <mat-card>
        <div class='metric-name'>Специальностей</div>
        <div class='metric-value-big'>
          {{ data.specialities.total }}
        </div>
        <div class='metric-name'>
          Объем профиля
          <br />
          <small class='metric-value-description'>(среднее)</small>
        </div>
        <div class='metric-value-big'>
          {{ data.specialities.things_mean }}
          <span class='metric-value-description'>компетенции</span>
        </div>
        <div class='metric-name'>
          Объем профиля
          <br />
          <small class='metric-value-description'>(медиана)</small>
        </div>
        <div class='metric-value-big'>
          {{ data.specialities.things_median }}
          <span class='metric-value-description'>компетенции</span>
        </div>
      </mat-card>
    </div>
    <div fxFlex='450px'>
      <div class='chart-title'>Количество компетенций в специальности</div>
      <app-histogram-simple
        *ngIf='data'
        [chartName]="'stats-speciality-profile'"
        [axisNameX]="''"
        [axisNameY]="''"
        [axisLabelsX]='getXAxisLabelsHist(data.specialities.things_hist)'
        [data]='getHistChartData(data.specialities.things_hist)'
        [sizes]='{ colors: { bar: chartHistBarColor } }'
      >
      </app-histogram-simple>
    </div>
  </div>

  <br />
  <mat-divider></mat-divider>

  <h2>Самостоятельная оценка</h2>
  <div fxLayout='row' fxLayoutGap='3rem' fxLayoutAlign='start center'>
    <div fxFlex='20rem'>
      <mat-card>
        <div class='metric-name'>Завершенных оценок</div>
        <div class='metric-value-big'>
          {{ data.surveys.finished_percentage }}%
        </div>
        <div class='metric-name'>
          Время на оценку
          <br />
          <small class='metric-value-description'>(среднее)</small>
        </div>
        <div class='metric-value-big'>
          {{ data.surveys.duration_mean }}
          <span class='metric-value-description'>минут</span>
        </div>
        <div class='metric-name'>
          Время на оценку
          <br />
          <small class='metric-value-description'>(медиана)</small>
        </div>
        <div class='metric-value-big'>
          {{ data.surveys.duration_median }}
          <span class='metric-value-description'>минут</span>
        </div>
      </mat-card>
    </div>
    <div fxFlex='450px'>
      <div class='chart-title'>
        Продолжительность самостоятельной оценки (минуты)
      </div>
      <app-histogram-simple
        *ngIf='data'
        [chartName]="'stats-survey-duration'"
        [axisNameX]="''"
        [axisNameY]="''"
        [axisLabelsX]='getXAxisLabelsHist(data.surveys.duration_hist)'
        [data]='getHistChartData(data.surveys.duration_hist)'
        [sizes]='{ colors: { bar: chartHistBarColor } }'
      >
      </app-histogram-simple>
    </div>
  </div>

  <br />
  <mat-divider></mat-divider>

  <h2>Компетенции пользователей</h2>
  <div fxLayout='row' fxLayoutGap='3rem' fxLayoutAlign='start center'>
    <div fxFlex='20rem'>
      <mat-card>
        <div class='metric-name'>Отмеченных компетенций</div>
        <div class='metric-value-big'>
          {{ data.competencies.competencies_total }}
        </div>
        <div class='metric-name'>Подтвержденных компетенций</div>
        <div class='metric-value-big'>
          {{ data.competencies.verified_percentage }}%
        </div>
        <div class='metric-name'>Желаемых к прокачке</div>
        <div class='metric-value-big'>
          {{ data.competencies.desires_total }}
        </div>
        <div class='metric-name'>Планов развития</div>
        <div class='metric-value-big'>
          {{ data.competencies.pdps_total }}
        </div>
      </mat-card>
    </div>
    <div fxFlex='450px'>
      <div class='chart-title'>Пользователи по количеству компетенций</div>
      <app-histogram-simple
        *ngIf='data'
        [chartName]="'stats-competence-count'"
        [axisNameX]="''"
        [axisNameY]="''"
        [axisLabelsX]='getXAxisLabelsHist(data.competencies.competence_hist)'
        [data]='getHistChartData(data.competencies.competence_hist)'
        [sizes]='{ colors: { bar: chartHistBarColor } }'
      >
      </app-histogram-simple>
    </div>
  </div>

  <br />
  <mat-divider></mat-divider>

  <div>
    <div fxLayout='row' fxLayoutAlign='end center'>
      <div class='mat-date-range'>
        <mat-form-field appearance='fill'>
          <mat-label>Введите диапазон дат</mat-label>
          <mat-date-range-input [rangePicker]='dateRangePickerAnalitycs'>
            <input class='chart-title' matStartDate [(ngModel)]='startDateAnalitycs'>
            <input class='chart-title' matEndDate [(ngModel)]='endDateAnalitycs'
                   (dateChange)='dateRangeAnalitycsChange()'>
          </mat-date-range-input>
          <mat-datepicker-toggle matPrefix [for]='dateRangePickerAnalitycs'>
          </mat-datepicker-toggle>
          <mat-date-range-picker #dateRangePickerAnalitycs></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf='analitycsStatsReady'>

      <div fxLayout='row' fxLayoutGap='3rem' fxLayoutAlign='start center'>
        <div fxFlex='20rem'>
          <mat-card>
            <div class='metric-name'>Проведенные ревью</div>
            <div class='metric-value-big'>
              {{ totalCompletedReviews }}
            </div>
          </mat-card>
        </div>
        <div fxLayout='column'>
          <div class='chart-title'>Количество проведенных ревью</div>
          <app-histogram-simple
            [chartName]="'compeleted-review-count'"
            [axisNameX]="''"
            [axisNameY]="''"
            [axisLabelsX]='getXAxisLabelsHist(completedReviewsData)'
            [data]='getHistChartData(completedReviewsData)'
            [sizes]='{ colors: { bar: chartHistBarColor } }'
          >
          </app-histogram-simple>
        </div>
      </div>
      <div fxLayout='row' fxLayoutGap='3rem' fxLayoutAlign='start center'>
        <div fxFlex='20rem'>
          <mat-card>
            <div class='metric-name'>Пользователи с несколькими ревью</div>
            <div class='metric-value-big'>
              {{ totalManyReviewsUsers }}
            </div>
          </mat-card>
        </div>
        <div fxLayout='column'>
          <div class='chart-title'>Количество пользователей c несколькими ревью</div>
          <app-histogram-simple
            [chartName]="'many-reviews-users-count'"
            [axisNameX]="''"
            [axisNameY]="''"
            [axisLabelsX]='getXAxisLabelsHist(manyReviewUsersData)'
            [data]='getHistChartData(manyReviewUsersData)'
            [sizes]='{ colors: { bar: chartHistBarColor } }'
          >
          </app-histogram-simple>
        </div>
      </div>
      <div fxLayout='row' fxLayoutGap='3rem' fxLayoutAlign='start center'>
        <div fxFlex='20rem'>
          <mat-card>
            <div class='metric-name'>Минимальная скорость загрузки</div>
            <div class='metric-value-big'>
              {{ minMatrixLoadingTime }}
            </div>
            <div class='metric-name'>Средняя скорость загрузки</div>
            <div class='metric-value-big'>
              {{ averageMatrixLoadingTime }}
            </div>
            <div class='metric-name'>Максимальная скорость загрузки</div>
            <div class='metric-value-big'>
              {{ maxMatrixLoadingTime }}
            </div>
          </mat-card>
        </div>
        <div fxLayout='column'>
          <div class='chart-title'>Скорость загрузки матрицы в команде</div>
          <app-histogram-simple
            [chartName]="'matrix-loading-time'"
            [axisNameX]="''"
            [axisNameY]="''"
            [axisLabelsX]='getXAxisLabelsHist(matrixLoadingData)'
            [data]='getHistChartData(matrixLoadingData)'
            [sizes]='{ colors: { bar: chartHistBarColor } }'
          >
          </app-histogram-simple>
        </div>
      </div>
    </div>
  </div>
</ng-template>
