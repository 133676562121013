interface CircularGrid {
  fill: string;
  stroke: string;
  opacity: number;
}

interface Axes {
  stroke: string;
}

interface Path {
  opacityArea: number;
  dotRadius: number;
  strokeWidth: number;
  tooltipColor: string;
  tooltipSize: string;
}

interface Area {
  opacityArea: number;
  dotRadius: number;
  strokeWidth: number;
  tooltipColor: string;
  tooltipSize: string;
}

export interface ChartRadarCfg {
  width: number; // Width of the circle
  height: number; // Height of the circle
  margin: { top: number; right: number; bottom: number; left: number }; // The margins of the SVG
  levels: number; // How many levels or inner circles should there be drawn
  labelFactor: number; // How much farther than the radius of the outer circle should the labels be placed
  wrapWidth: number; // The number of pixels after which a label needs to be given a new line
  opacityArea: number; // The opacity of the area of the blob
  dotRadius: number; // The size of the colored circles of each blog
  strokeWidth: number; // The width of the stroke around each blob
  roundStrokes: boolean; // If true the area and stroke will follow a round path (cardinal-closed)
  format: string;
  unit: string;
  legend: boolean;
  title: boolean;
  circularGrid: CircularGrid;
  axes: Axes;
  path: Path;
  area: Area;
}

export const getDefaultChartRadarCfg = (): ChartRadarCfg => {
  return {
    width: 300,
    height: 300,
    margin: { top: 70, right: 100, bottom: 70, left: 100 },
    levels: 3,
    labelFactor: 1.25,
    wrapWidth: 60,
    opacityArea: 0.75,
    dotRadius: 4,
    strokeWidth: 1.5,
    roundStrokes: true,
    format: '.2%',
    unit: '%',
    legend: false,
    title: false,

    circularGrid: {
      fill: '#fff',
      stroke: '#eee',
      opacity: 0.1,
    },
    axes: {
      stroke: '#aaa',
    },
    path: {
      opacityArea: 0.0,
      dotRadius: 2,
      strokeWidth: 1,
      tooltipColor: '#444',
      tooltipSize: '0.7rem',
    },
    area: {
      opacityArea: 0.1,
      dotRadius: 2,
      strokeWidth: 1,
      tooltipColor: '#444',
      tooltipSize: '0.7rem',
    },
  };
};
