<div class="dialog-container">
  <div>Выбрать ревьюеров</div>
  <app-select-users-with-search
    [users]="data.reviewers"
    [checkedUsers]="data.reviewers"
    (selectedUsersChange)="onSelectedReviewersChange($event)"
  ></app-select-users-with-search>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button
      class="dialog-reject-button"
      [mat-dialog-close]="true"
      mat-raised-button
    >
      Отменить
    </button>
    <button
      class="dialog-accept-button"
      [mat-dialog-close]="true"
      [disabled]="!this.reviewers || this.reviewers.length === 0"
      mat-raised-button
      (click)="submit()"
    >
      Добавить
    </button>
  </div>
</div>
