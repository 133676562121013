<div class="dialog-container">
  <div>Выбрать компетенции</div>
  <div class="select-container">
    <app-select-library-things
      [specialityUuid]="data.specialityUuid"
      [selectedClaims]="data.selectedClaims"
      (selectedClaimsChange)="onSelectedClaimsChange($event)"
    ></app-select-library-things>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button
      class="dialog-reject-button"
      [mat-dialog-close]="true"
      mat-raised-button
    >
      Отменить
    </button>
    <button
      class="dialog-accept-button"
      [mat-dialog-close]="true"
      [disabled]="!this.selectedClaims || this.selectedClaims.length === 0"
      mat-raised-button
      (click)="submit()"
    >
      Добавить
    </button>
  </div>
</div>
