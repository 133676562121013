import { Component, OnInit } from '@angular/core';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Assessment } from '@models/reviews/assessment';
import { AssessmentHttpService } from '@services/http/AssessmentHttpService';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticTextField } from '@components/common/static-textfield-group/static-textfield-group.component';
import { Review } from '@models/reviews/review';
import { AssessmentResponseHttpService } from '@services/http/AssessmentResponseHttpService';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { AsyncList } from '@rest/AsyncList';
import { AssessmentResponse } from '@models/reviews/assessment-response';
import { Thing } from '@models/ontology/thing';
import { Domain } from '@models/ontology/domain';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import {
  ReviewMode,
  ThingDesireMap,
} from '@components/common/domain-thing-tree/domain-thing-tree.component';
import { ResolutionResponseHttpService } from '@services/http/ResolutionResponseHttpService';
import { ResolutionResponse } from '@models/reviews/resolution-response';
import { AuthService } from '../../../../services/auth/auth.service';
import { get_full_parent_path } from '../../../../utils/getFullParentUrl';
import { TitleService } from 'src/app/services/title.service';
import { CompetenceDesire } from '@models/competencies/competence-desire';
import { CompetenceDesireHttpService } from '@services/http/CompetenceDesireHttpService';
import { ReviewHttpService } from '@services/http/ReviewHttpService';
import { BlockTextHelpDialogComponent } from '@components/common/block-text-help-dialog/block-text-help-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export interface AssessmentResponseThingMap {
  [thingUuid: string]: AssessmentResponse[];
}

interface ResolutionResponseThingMap {
  [thingUuid: string]: ResolutionResponse;
}

@Component({
  selector: 'app-new-review-assessment-detail',
  templateUrl: './new-review-assessment-detail.component.html',
  styleUrls: ['./new-review-assessment-detail.component.css'],
})
export class NewReviewAssessmentDetailComponent implements OnInit {
  assessment: AsyncDetail<Assessment>;
  assessmentResponses: AsyncList<AssessmentResponse>;
  resolutionResponses: AsyncList<ResolutionResponse>;
  userCompetenceDesires: AsyncList<CompetenceDesire>;
  domains: AsyncList<Domain>;
  speciality: AsyncDetail<Speciality>;

  assessmentInfoData: StaticTextField[];

  assessmentResponseThingMap: AssessmentResponseThingMap;
  resolutionResponseThingMap: ResolutionResponseThingMap;

  isAssessmentCompleted = false;

  treeDataReady = false;

  things: Thing[] = [];

  thingDesireMap: ThingDesireMap = {};

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    private _specialityHttpService: SpecialityHttpService,
    private _assessmentHttpService: AssessmentHttpService,
    private _assessmentResponseHttpService: AssessmentResponseHttpService,
    private _resolutionResponseHttpService: ResolutionResponseHttpService,
    private _competenceDesireHttpService: CompetenceDesireHttpService,
    private _domainHttpService: DomainHttpService,
    private _titleService: TitleService,
    private _reviewHttpService: ReviewHttpService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this._titleService.set_title('Ревью');
    this.domains = new AsyncList<Domain>(this._domainHttpService);

    this.assessment = new AsyncDetail<Assessment>(
      this._activatedRoute.snapshot.paramMap.get('aUuid'),
      this._assessmentHttpService
    );
    this.assessment.requestParams = {
      params: {
        expand: 'review,reviewer_user',
      },
    };

    combineLatest([this.assessment.load(), this.domains.load()]).subscribe(
      () => {
        this._titleService.set_title(
          (this.assessment.state.item.review as Review).name
        );
        this._loadAssessmentResponses();
        this._getReviewInfoData().subscribe(() => {
          switch (this.reviewMode) {
            case 'self-assessment':
              this._loadUserCompetenceDesires();
              break;
            case 'result':
              this._loadResolutionResponses();
              break;
            default:
              this.treeDataReady = true;
              break;
          }
        });
      }
    );
  }

  private _loadAssessmentResponses(): void {
    this.assessmentResponses = new AsyncList<AssessmentResponse>(
      this._assessmentResponseHttpService
    );
    this.assessmentResponses.setRequestParams({
      params: {
        assessment: this.assessment.state.item.uuid,
        expand: 'thing.levels,competence_assertion.thing_level',
      },
    });
    this.assessmentResponses.load().subscribe(() => {
      this.assessmentResponseThingMap = {};
      this.assessmentResponses.state.items.forEach((assessmentResponse) => {
        this.assessmentResponseThingMap[assessmentResponse.thing.uuid] = [
          assessmentResponse,
        ];
        this.things.push(assessmentResponse.thing);
      });
    });
  }

  private _loadResolutionResponses(): void {
    this.resolutionResponses = new AsyncList<ResolutionResponse>(
      this._resolutionResponseHttpService
    );
    this.resolutionResponses.setRequestParams({
      params: {
        resolution__review: (this.assessment.state.item.review as Review).uuid,
        expand: 'resolved_thing_level,thing.levels',
      },
    });
    this.resolutionResponses.load().subscribe(() => {
      this.resolutionResponseThingMap = {};
      this.resolutionResponses.state.items.forEach((resolutionResponse) => {
        this.resolutionResponseThingMap[resolutionResponse.thing.uuid] =
          resolutionResponse;
      });
      this.treeDataReady = true;
    });
  }

  private _loadUserCompetenceDesires(): void {
    this.userCompetenceDesires = new AsyncList<CompetenceDesire>(
      this._competenceDesireHttpService
    );
    this.userCompetenceDesires.setRequestParams({
      params: {
        user: this._authService.get_current_user_uuid(),
      },
    });
    this.userCompetenceDesires.load().subscribe(() => {
      this.userCompetenceDesires.state.items.forEach((competenceDesire) => {
        const thingUuid = competenceDesire.thing as string;

        this.thingDesireMap[thingUuid] = {
          desire: competenceDesire.uuid,
          date: '',
        };
      });

      this.treeDataReady = true;
    });
  }

  private _getReviewInfoData(): Observable<any> {
    const assessment = this.assessment.state.item,
      review = assessment.review as Review,
      specialityUuid = Object.values(
        review.config?.subject_speciality_map
      )[0][0],
      reviewInfoSubject = new Subject();

    this.speciality = new AsyncDetail<Speciality>(
      specialityUuid,
      this._specialityHttpService
    );
    this.speciality.load().subscribe(
      () => {
        this.assessmentInfoData = [
          { name: 'Специальность', text: this.speciality.state.item.name },
          {
            name: 'Срок ревью',
            text: this.getPrettyReviewDate(review),
          },
          {
            name: 'Ревьюер',
            text: `${assessment.reviewer_user.last_name} ${assessment.reviewer_user.first_name}`,
          },
        ];
        reviewInfoSubject.next();
      },
      (error) => {
        this.assessmentInfoData = [
          { name: 'Специальность', text: 'Удалена' },
          {
            name: 'Срок ревью',
            text: this.getPrettyReviewDate(review),
          },
          {
            name: 'Ревьюер',
            text: `${assessment.reviewer_user.last_name} ${assessment.reviewer_user.first_name}`,
          },
        ];
        reviewInfoSubject.next();
      }
    );

    return reviewInfoSubject;
  }

  get reviewName(): string {
    return (this.assessment.state.item.review as Review).name;
  }

  get isSelfReview(): boolean {
    return (
      this.assessment.state.item.reviewer_user.uuid ===
      this.assessment.state.item.subject_user
    );
  }

  get reviewMode(): ReviewMode {
    if (this.assessment.state.item.completion_percentage === 100) {
      return 'result';
    }
    return this.isSelfReview ? 'self-assessment' : 'assessment';
  }

  getPrettyReviewDate(review: Review): string {
    if (review.deadline_start && review.deadline_end) {
      const dateFrom = new Date(review.deadline_start),
        dateTo = new Date(review.deadline_end);
      return `${dateFrom.getDate()} ${dateFrom.toLocaleString('default', {
        month: 'short',
      })} - ${dateTo.getDate()} ${dateTo.toLocaleString('default', {
        month: 'short',
      })}`;
    }
    return '';
  }

  closeAssessmentResponse(): void {
    this._assessmentHttpService.close(this.assessment.uuid).subscribe(() => {
      this.isAssessmentCompleted = true;
    });
  }

  onBackClick(): void {
    history.back();
  }

  onAssessmentChange(): void {
    this.assessment.load().subscribe(() => {
      if (this.assessment.state.item.completion_percentage === 100) {
        this.isAssessmentCompleted = true;
      }
    });
  }

  getFullParentPath = get_full_parent_path;

  downloadReviewExcel() {
    const review_uuid = (this.assessment.state.item.review as Review).uuid;
    window.open(
      this._reviewHttpService.getDownloadReviewExcelLink(review_uuid)
    );
  }

  onWhatThisMetricsDoes(): void {
    this._dialog.open(BlockTextHelpDialogComponent, {
      data: {
        title: 'Что означают Вы и Финальная?',
        textBlocksData: [
          {
            title: 'Оценка "Вы"',
            text: 'Это оценка, которая была проставлена вами.',
            color: 'rgba(130,196,255,0.16)',
          },
          {
            title: 'Оценка "Финальная"',
            text: 'Это оценка, которая была проставлена руководителем. Эта оценка считается финальной, так как она отображается в профиле у сотрудника. ',
            color: 'rgba(155,130,255,0.16)',
          },
        ],
      },
    });
  }
}
