import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {LoggingService} from 'src/app/services/logging.service';
import {AlertService} from 'src/app/services/ui/ui-alert.service';
import {TitleService} from 'src/app/services/title.service';
import {SurveyService} from 'src/app/services/survey/survey.service';

import {Survey} from 'src/app/models/survey/survey';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-survey-edit',
  templateUrl: './survey-edit.component.html',
  styleUrls: ['./survey-edit.component.css']
})
export class SurveyEditComponent implements OnInit {

  private survey_uuid: string;
  public survey: Survey;

  public survey_edit_form: UntypedFormGroup;

  private uistate = {
    survey_loaded: false,
    survey_in_edit_mode: false
  };

  constructor(
    private route: ActivatedRoute,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private title_service: TitleService,
    private survey_service: SurveyService,
    private form_builder: UntypedFormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.title_service.set_title('Опрос');
    this.survey_uuid = this.route.snapshot.paramMap.get('survey_uuid');
    this.logging_service.debug(`${this.constructor.name} init`);
    this.load_survey();
    this.create_survey_edit_form();
  }

  private create_survey_edit_form() {
    this.survey_edit_form = this.form_builder.group({
      survey_title: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(255)
      ]],
      survey_description: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1024)
      ]]
    });
  }

  public get is_data_loaded(): boolean {
    return this.uistate.survey_loaded;
  }

  public get survey_in_edit_mode(): boolean {
    return this.uistate.survey_in_edit_mode;
  }

  public survey_edit_button_click() {
    this.uistate.survey_in_edit_mode = true;
    this.survey_edit_form.setValue({
      survey_title: this.survey.name,
      survey_description: this.survey.description
    });
  }

  public survey_edit_save_button_click() {
    const new_survey_data = {
      name: this.survey_edit_form.value.survey_title,
      description: this.survey_edit_form.value.survey_description
    };
    this.survey_service.update(this.survey_uuid, new_survey_data).subscribe(
      response => {
        this.alert_service.error(`Сохранено`);
        this.survey.name = response.name;
        this.survey.description = response.description;
        this.uistate.survey_in_edit_mode = false;
        this.logging_service.debug(`${this.constructor.name} updated survey ${this.survey_uuid}`);
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to update survey ${this.survey_uuid}`);
        this.alert_service.error(`Ошибка сохроанения ${err.status}`);
      }
    );
  }

  public cancel_survey_edit_mode() {
    this.uistate.survey_in_edit_mode = false;
  }

  private load_survey(): void {
    this.survey_service.fetch_by_uuid(this.survey_uuid).subscribe(
      response => {
        this.survey = response as Survey;
        this.logging_service.debug(`${this.constructor.name} loaded survey ${this.survey.name}`);
        this.title_service.set_title(this.survey.name);
        this.uistate.survey_loaded = true;
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to load survey`);
        this.alert_service.error(`Ошибка загрузки опроса ${err.status}`);
      }
    );
  }
}
