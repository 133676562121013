import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DirectionHttpService } from '@services/http/DirectionHttpService';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Direction } from '@models/directions/direction';
import { TextfieldDialogComponent } from '../../../../common-ui-elements/components/textfield-dialog/textfield-dialog.component';
import { User } from '@models/user/user';
import { AsyncList } from '@rest/AsyncList';
import { Team } from '@models/teams/team';
import { TeamHttpService } from '@services/http/TeamHttpService';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { Domain } from '@models/ontology/domain';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';

interface DomainTreeData extends Domain {
  children: DomainTreeData[];
  level: number;
  isExpanded: boolean;
  isChecked: boolean;
}

interface SpecialitySelection extends Speciality {
  isChecked: boolean;
}

@Component({
  selector: 'app-direction-details',
  templateUrl: './direction-details.component.html',
  styleUrls: ['./direction-details.component.css'],
})
export class DirectionDetailsComponent implements OnInit {
  directionUuid: string;

  direction: AsyncDetail<Direction>;

  selectedAdmin: User;
  updatedAdmins: User[];

  searchTeams: AsyncList<Team>;
  selectedTeam: Team;
  updatedTeams: Team[];

  librarySpecialities: AsyncList<Speciality>;
  specialitiesSelection: SpecialitySelection[];

  domains: AsyncList<Domain>;
  domainsTreeData: DomainTreeData[];
  domainsTreeDataReady = false;
  updatedDomains: Domain[];

  isAddTeamVisible = false;
  isAddSpecialityVisible = false;
  isAddAdminVisible = false;

  constructor(
    private _dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _directionHttpService: DirectionHttpService,
    private _teamHttpService: TeamHttpService,
    private _domainHttpService: DomainHttpService,
    private _specialityHttpService: SpecialityHttpService
  ) {}

  ngOnInit(): void {
    this.directionUuid = this._activatedRoute.snapshot.paramMap.get('uuid');
    this.searchTeams = new AsyncList<Team>(this._teamHttpService);
    this._loadDirection();
  }

  private _loadDirection(): void {
    this.direction = new AsyncDetail<Direction>(
      this.directionUuid,
      this._directionHttpService
    );
    this.direction.requestParams = {
      params: {
        expand: 'teams,domains,admins',
      },
    };
    this.direction.load().subscribe(() => {
      this.updatedDomains = this.direction.state.item.domains as Domain[];
      this._loadSpecialities();
      this._loadDomains();
    });
  }

  private _loadSpecialities(): void {
    this.librarySpecialities = new AsyncList<Speciality>(
      this._specialityHttpService
    );
    this.librarySpecialities.setRequestParams({
      params: {
        team__isnull: 'True',
      },
    });
    this.librarySpecialities.load().subscribe(() => {
      this.specialitiesSelection = this.librarySpecialities.state.items.map(
        (librarySpeciality) => {
          return {
            ...librarySpeciality,
            isChecked: !!(
              this.direction.state.item.specialities as string[]
            ).find(
              (specialityUuid) => specialityUuid === librarySpeciality.uuid
            ),
          };
        }
      );
    });
  }

  private _loadDomains(): void {
    this.domains = new AsyncList<Domain>(this._domainHttpService);
    this.domains.load().subscribe(() => {
      this.domainsTreeData = [];

      let firstDomain = true;
      this.domains.state.items.forEach((domain) => {
        if (!domain.parent_domain) {
          const result = this._getDomainChildren(domain, 0);
          this.domainsTreeData.push({
            ...domain,
            children: result.children,
            level: result.level,
            isExpanded: firstDomain,
            isChecked: this.isDirectionDomain(domain),
          });
          firstDomain = false;
        }
      });
      this.domainsTreeDataReady = true;
    });
  }

  private _getDomainChildren(
    parentDomain: Domain,
    level: number
  ): {
    children: DomainTreeData[];
    level: number;
  } {
    level += 1;
    const children: DomainTreeData[] = [];
    this.domains.state.items.forEach((domain) => {
      if (domain.parent_domain === parentDomain.uuid) {
        const result = this._getDomainChildren(domain, level);
        children.push({
          ...domain,
          children: result.children,
          level: result.level,
          isChecked: this.isDirectionDomain(domain),
          isExpanded: false,
        });
      }
    });
    return {
      children: children,
      level: level,
    };
  }

  onDomainToggle(domain: DomainTreeData): void {
    domain.isChecked = !domain.isChecked;
    this._toggleDomainsDeep(domain, domain.isChecked);
    this._toggleParentDomain(this._findDomainParent(domain));
    this.direction
      .update({
        domains: this.updatedDomains.map((d) => d.uuid),
      })
      .subscribe(() => {});
  }

  private _findDomainParent(domain: DomainTreeData): DomainTreeData {
    let parentDomain;
    this.domainsTreeData.forEach((d) => {
      if (!parentDomain) {
        parentDomain = this._getDomainParent(d, domain);
      }
    });
    return parentDomain;
  }

  private _getDomainParent(
    domain: DomainTreeData,
    searchDomain: DomainTreeData
  ): DomainTreeData {
    let parentDomain;
    if (domain.children.length) {
      if (domain.uuid === searchDomain.parent_domain) {
        return domain;
      } else {
        domain.children.forEach((childDomain) => {
          if (!parentDomain) {
            parentDomain = this._getDomainParent(childDomain, searchDomain);
          }
        });
      }
    }
    return parentDomain;
  }

  private _toggleParentDomain(domain: DomainTreeData): void {
    if (domain) {
      domain.isChecked =
        domain.children.length ===
        domain.children.filter((d) => d.isChecked).length;
      this._updateDomains(domain);
      this._toggleParentDomain(this._findDomainParent(domain));
    }
  }

  private _toggleDomainsDeep(domain: DomainTreeData, checked: boolean): void {
    domain.isChecked = checked;
    this._updateDomains(domain);
    if (domain.children.length) {
      domain.children.forEach((childDomain) => {
        this._toggleDomainsDeep(childDomain, checked);
      });
    }
  }

  private _updateDomains(domain: DomainTreeData): void {
    if (domain.isChecked) {
      this.updatedDomains.push(domain);
    } else {
      this.updatedDomains = this.updatedDomains.filter(
        (d) => d.uuid !== domain.uuid
      );
    }
  }

  isDirectionDomain(domain: Domain): boolean {
    return !!(this.direction.state.item.domains as Domain[]).find(
      (d) => d.uuid === domain.uuid
    );
  }

  onSpecialityToggle(speciality: SpecialitySelection): void {
    speciality.isChecked = !speciality.isChecked;
    this.direction
      .update({
        specialities: this.specialitiesSelection
          .filter((specialitySelection) => specialitySelection.isChecked)
          .map((specialitySelection) => specialitySelection.uuid),
      })
      .subscribe(() => {});
  }

  onEditDirection(): void {
    this._dialog.open(TextfieldDialogComponent, {
      data: {
        title: 'Редактирование направления',
        description: ``,
        acceptLabel: 'Сохранить',
        rejectLabel: 'Отменить',
        floatLabel: 'never',
        fields: [
          {
            label: 'Название',
            placeholder: 'Введите название направления',
            exampleText: '',
            required: true,
            formControlName: 'name',
            value: this.direction.state.item.name,
          },
          {
            label: 'Описание',
            placeholder: 'Подробно опишите',
            exampleText:
              'Например: Дизайнер интерфейсов будет решать задачи коммуникации между системой ввода данных и пользователем',
            required: false,
            formControlName: 'description',
            value: this.direction.state.item.description,
          },
        ],
        onSubmit: (data: any) => {
          this._directionHttpService
            .update(this.direction.uuid, {
              name: data.name,
              description: data.description,
            })
            .subscribe(() => {
              this.direction.state.item.name = data.name;
              this.direction.state.item.description = data.description;
            });
        },
      },
    });
  }

  get directionAdmins(): User[] {
    return this.direction.state.item.admins as User[];
  }

  onAdminSelected(admin: User): void {
    this.selectedAdmin = admin;
  }

  addDirectionAdmin(): void {
    const currentAdmins = this.direction.state.item.admins as User[];
    this.updatedAdmins = [this.selectedAdmin, ...currentAdmins];
    this.updateDirectionAdmins(this.updatedAdmins.map((admin) => admin.uuid));
  }

  removeDirectionAdmin(adminUuid: string): void {
    const currentAdmins = this.direction.state.item.admins as User[];
    this.updatedAdmins = currentAdmins.filter(
      (admin) => admin.uuid !== adminUuid
    );
    this.updateDirectionAdmins(this.updatedAdmins.map((a) => a.uuid));
  }

  updateDirectionAdmins(admins: string[]): void {
    this.direction
      .update({
        admins: admins,
      })
      .subscribe(() => {
        this.direction.load();
        this.isAddAdminVisible = false;
      });
  }

  addDirectionTeam(): void {
    const currentTeams = this.direction.state.item.teams as Team[];
    this.updatedTeams = [this.selectedTeam, ...currentTeams];
    this.updateDirectionTeams(this.updatedTeams.map((team) => team.uuid));
  }

  removeDirectionTeam(teamUuid: string): void {
    const currentTeams = this.direction.state.item.teams as Team[];
    this.updatedTeams = currentTeams.filter((team) => team.uuid !== teamUuid);
    this.updateDirectionTeams(this.updatedTeams.map((t) => t.uuid));
  }

  updateDirectionTeams(teams: string[]): void {
    this.direction
      .update({
        teams: teams,
      })
      .subscribe(() => {
        this.direction.load();
        this.isAddTeamVisible = false;
      });
  }

  searchTeam(search: string): void {
    this.searchTeams.setRequestParams({
      params: {
        search: search,
      },
    });
    this.searchTeams.load().subscribe(() => {
      this._hideTeamsFromSearch();
    });
  }

  searchSelectTeam(selection: Team): void {
    if (!selection) {
      return;
    }
    this.selectedTeam = selection;
  }

  private _hideTeamsFromSearch(): void {
    this.searchTeams.state.items = this.searchTeams.state.items.filter(
      (searchTeam) => {
        return !this._isTeamHidden(searchTeam);
      }
    );
  }

  private _isTeamHidden(team: Team): boolean {
    return !!(this.direction.state.item.teams as Team[]).find(
      (t) => t.uuid === team.uuid
    );
  }

  // searchSpeciality(search: string): void {
  //   this.searchSpecialities.setRequestParams({
  //     params: {
  //       search: search,
  //     },
  //   });
  //   this.searchSpecialities.load().subscribe(() => {
  //     this._hideSpecialitiesFromSearch();
  //   });
  // }
  //
  // searchSelectSpeciality(selection: Speciality): void {
  //   if (!selection) {
  //     return;
  //   }
  //   this.selectedSpeciality = selection;
  // }
  //
  // private _hideSpecialitiesFromSearch(): void {
  //   this.searchSpecialities.state.items =
  //     this.searchSpecialities.state.items.filter((searchSpeciality) => {
  //       return !this._isSpecialityHidden(searchSpeciality);
  //     });
  // }
  //
  // private _isSpecialityHidden(speciality: Speciality): boolean {
  //   return !!(this.direction.state.item.specialities as Speciality[]).find(
  //     (s) => s.uuid === speciality.uuid
  //   );
  // }
}
