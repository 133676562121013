import { Component, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import * as d3 from 'd3';

import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-competence-delta-chart',
  templateUrl: './competence-delta-chart.component.html',
  styleUrls: ['./competence-delta-chart.component.css']
})
export class CompetenceDeltaChartComponent implements OnInit, AfterViewInit {

  private _config: any;
  private _data: any[];
  private _html_element_selector_class: string;

  @Input()
  set chart_name(html_el_class_name: string) {
    this._html_element_selector_class = html_el_class_name;
  }
  get chart_name(): string { return this._html_element_selector_class; }

  @Input()
  set data(input_data: any[]) {
    this._data = input_data;
  }
  get data(): any[] { return this._data; }

  @Input()
  set config(config_data: any) {
    this._config = config_data;
  }
  get config(): any {
    if (this._config) {
      return this._config;
    } else {
      return this._default_config;
    }
  }

  private _default_config = {
    width: 400,
    height: 100,
    margin: {
      top: 30,
      right: 70,
      bottom: 70,
      left: 30
    },
    colors: {
      positive_bar: '#aaa',
      negative_bar: '#ff7878'
    }
  };

  public selected_aggregation_period: string;
  public range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });

  constructor(private logging_service: LoggingService) { }

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.draw_chart(), 100);
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   setTimeout(() => this.draw_chart(), 100);
  // }

  public on_selected_aggregation_period(): void {
    this.draw_chart();
  }

  private get svg_width(): any { return this.config.width + this.svg_margin_left + this.svg_margin_right + 50; }
  private get svg_height(): any { return this.config.height + this.svg_margin_top + this.svg_margin_bottom + 50; }
  private get svg_margin_top(): any { return this.config.margin.top; }
  private get svg_margin_bottom(): any { return this.config.margin.bottom; }
  private get svg_margin_left(): any { return this.config.margin.left; }
  private get svg_margin_right(): any { return this.config.margin.right; }
  private get svg_color_bar_positive(): any { return this.config.colors.positive_bar; }
  private get svg_color_bar_negative(): any { return this.config.colors.negative_bar; }

  private get svg_effective_width(): any { return this.svg_width - this.svg_margin_left - this.svg_margin_right }
  private get svg_effective_height(): any { return this.svg_height - this.svg_margin_top - this.svg_margin_bottom }

  private aggregate_data_by_period(data: any[], period: string): any[] {
    let date_comparator = null;
    if (period === 'year') {
      date_comparator = (a, b) => a.getFullYear() === b.getFullYear()
    }
    if (period === 'month') {
      date_comparator = (a, b) => a.getFullYear() === b.getFullYear() &&
                                  a.getMonth() === b.getMonth()
    }
    if (period === 'day') {
      date_comparator = (a, b) => a.getFullYear() === b.getFullYear() &&
                                  a.getMonth() === b.getMonth() &&
                                  a.getDay() === b.getDay()
    }
    if (period === 'hour') {
      date_comparator = (a, b) => a.getFullYear() === b.getFullYear() &&
                                  a.getMonth() === b.getMonth() &&
                                  a.getDay() === b.getDay() &&
                                  a.getHours() === b.getHours()
    }
    if (period === 'min') {
      date_comparator = (a, b) => a.getFullYear() === b.getFullYear() &&
                                  a.getMonth() === b.getMonth() &&
                                  a.getDay() === b.getDay() &&
                                  a.getHours() === b.getHours() &&
                                  a.getMinutes() === b.getMinutes()
    }

    const result = [];
    data.forEach(d => {
      const date = new Date(d.date_created);
      const existed_datum = result.find(e => date_comparator(e.x, date));
      if (existed_datum) {
        existed_datum.y = existed_datum.y + d.increment;
      } else {
        result.push({x: date, y: d.increment})
      }
    });
    return result
  }

  private draw_chart() {
    this.logging_service.debug(`${this.constructor.name} draw chart`);

    if (this.data.length === 0) { return }

    const date_start = d3.min(this.data, (d) => new Date(d.date_created));
    const date_end = d3.max(this.data, (d) => new Date(d.date_created));
    const date_diff = {
      in_hours: (d1, d2) => (d2.getTime() - d1.getTime())/(3600*1000),
      in_days: (d1, d2) => (d2.getTime() - d1.getTime())/(24*3600*1000),
      in_weeks: (d1, d2) => (d2.getTime() - d1.getTime())/(24*3600*1000*7),
      in_months: (d1, d2) => (d2.getMonth()+12*d2.getFullYear())-(d1.getMonth()+12*d1.getFullYear()),
      in_years: (d1, d2) => d2.getFullYear()-d1.getFullYear()
    }

    let period = null;
    let calculated_aggragation_period = 'min';
    if (date_diff.in_hours(date_start, date_end) > 5) { calculated_aggragation_period = 'hour'}
    if (date_diff.in_days(date_start, date_end) > 10) { calculated_aggragation_period = 'day'}
    if (date_diff.in_months(date_start, date_end) > 5) { calculated_aggragation_period = 'month'}
    if (date_diff.in_years(date_start, date_end) > 3) { calculated_aggragation_period = 'year'}

    if (this.selected_aggregation_period) {
      period = this.selected_aggregation_period;
      this.logging_service.debug(`${this.constructor.name} aggragating data by selected period: ${period}`);
    } else {
      period = calculated_aggragation_period;
      this.logging_service.debug(`${this.constructor.name} aggragating data by calculated period: ${period}`);
    }

    let domain_date_start = null;
    let domain_date_end = null;
    switch(period) {
      case 'min': {
        domain_date_start = new Date(date_start.getTime() - 10 * 60 * 1000);
        domain_date_end = new Date(date_end.getTime() + 10 * 60 * 1000);
        break;
      }
      case 'hour': {
        domain_date_start = new Date(date_start.getTime() - 3 * 60 * 60 * 1000);
        domain_date_end = new Date(date_end.getTime() + 3 * 60 * 60 * 1000);
        break;
      }
      case 'day': {
        domain_date_start = new Date(date_start.getTime() - 30 * 24 * 60 * 60 * 1000);
        domain_date_end = new Date(date_end.getTime() + 30 * 24 * 60 * 60 * 1000);
        break;
      }
      case 'month': {
        domain_date_start = new Date(date_start.getTime() - 12 * 30 * 24 * 60 * 60 * 1000);
        domain_date_end = new Date(date_end.getTime() + 12 * 30 * 24 * 60 * 60 * 1000);
        break;
      }
      default: {
        domain_date_start = new Date(date_start.getTime() - 12 * 30 * 24 * 60 * 60 * 1000);
        domain_date_end = new Date(date_end.getTime() + 12 * 30 * 24 * 60 * 60 * 1000);
        break;
      }
   }

    const data = this.aggregate_data_by_period(this.data, period);
    // this.logging_service.debug(`${this.constructor.name} aggragated data:`);
    // console.log(data);

    const parent = d3.select(`.${this.chart_name}`);

    // redraw entire element
    parent.select('svg').remove();

    const svg = parent.append('svg')
      .attr('width', this.svg_width)
      .attr('height', this.svg_height);

    const g = svg.append('g').attr('transform', 'translate(' + this.svg_margin_left + ',' + this.svg_margin_top + ')');

    const x_scale = d3.scaleTime().rangeRound([0, this.svg_effective_width]);
    const y_scale = d3.scaleLinear().rangeRound([this.svg_effective_height, 0]);
    const center_scale = d3.scaleLinear().rangeRound([0, this.svg_effective_width]);

    const center_line = d3.axisTop(center_scale).ticks(0);

    let y_min = d3.min(data, (d) => (d as any).y - 0.1);
    const y_max = d3.max(data, (d) => (d as any).y + 0.1);

    // console.log('y_min', y_min, 'y_max', y_max)
    if (y_min > 0) { y_min = 0}

    x_scale.domain([domain_date_start, domain_date_end]);
    y_scale.domain([y_min, parseInt(y_max, 10)]);

    g.append('g')
      .attr('class', 'centerline')
      .attr('transform', 'translate(0,' + y_scale(0) + ')')
      .call(center_line);

    g.append('g')
      .attr('transform', 'translate(0,' + this.svg_effective_height + ')')
      .call(d3.axisBottom(x_scale))
      .selectAll('text')
        .attr('y', 0)
        .attr('x', 9)
        .attr('dy', '.35em')
        .attr('transform', 'rotate(35)')
        .style('text-anchor', 'start');

    g.append('g')
      .call(d3.axisLeft(y_scale).ticks(5))
      .append('text')
        .attr('fill', '#000')
        .attr('transform', 'rotate(-90)')
        .attr('y', 6)
        .attr('dy', '0.71em')
        .attr('dx', '0.71em')
        .attr('text-anchor', 'end')
        .text('Прирост');

    g.selectAll('.bar')
      .data(data)
      .enter().append('rect')
        .attr('class', 'bar')
        .attr('x', (d) => x_scale((d as any).x))
        .attr('y', (d) => (d as any).y < 0 ? y_scale(0) : y_scale(Number((d as any).y)))
        .attr('width', '5px')
        .attr('height', (d) => {
          if (data.length > 1) {
            return Math.abs( y_scale(Number((d as any).y)) - y_scale(0) );
          } else {
            return this.svg_effective_height;
          }
        })
        .attr('fill', (d) => (d as any).y < 0 ? this.svg_color_bar_negative : this.svg_color_bar_positive);

    g.selectAll('.bar')
        .data(data)
        .exit().remove();

  }
}
