<div *ngFor="let level of thingLevels.state.items" class="level-container">
  <ng-container
    [ngTemplateOutlet]="
            editUuid == level.uuid ? tmpl_level_edit_mode : tmpl_level
          "
    [ngTemplateOutletContext]="{ level: level }"
  >
  </ng-container>
</div>

<div class="buttons">
  <button mat-stroked-button (click)="openCreateDialog()">
    Добавить уровень
  </button>
</div>

<ng-template #tmpl_level let-level="level">
  <div fxLayout="row" *ngIf="editUuid !== level.uuid">
    <div fxFlex="89%">
      <div class="level-title">
        {{ level.title }}
      </div>
      <div
        class="level-description"
        [innerHTML]="level.description"
      ></div>
    </div>
    <div fxFlex="5%" fxFlexOffset="15%">
      <button mat-icon-button (click)="startUpdate(level)">
        <mat-icon>edit</mat-icon>
      </button>
      <button *ngIf="catDelete(level)"
        mat-icon-button
        (click)="openRemoveDialog(level)"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_level_edit_mode let-level="level">
  <div>
    <div fxLayout="row">
      <div fxFlex="80%">
        <form
          [formGroup]="thingLevelEditForm"
          fxLayout="column"
          fxLayoutGap="1rem"
        >
            <mat-form-field class="edit-field">
              <mat-label>Уровень владения</mat-label>
              <input
                #input_field_title
                matInput
                required
                placeholder="Уровень владения"
                formControlName="title"
              />
              <mat-hint>{{ 128 - input_field_title.value.length }}/128</mat-hint>
            </mat-form-field>
            <mat-form-field class="edit-field">
              <mat-label>Описание</mat-label>
              <textarea
                matInput
                #input_field_description
                required
                formControlName="description"
                placeholder="Описание*"
              >
            </textarea>
              <mat-hint
              >{{ 1024 - input_field_description.value.length }}/1024
              </mat-hint
              >
            </mat-form-field>
        </form>
      </div>
      <div fxFlex="5%" fxFlexOffset="15%">
        <button
          mat-icon-button
          [disabled]="thingLevelEditForm.pristine"
          (click)="save()"
        >
          <mat-icon>save</mat-icon>
        </button>
        <button mat-icon-button (click)="cancelUpdate()">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
