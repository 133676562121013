import { Component, Input, OnInit } from '@angular/core';
import { AsyncDetail } from '@rest/AsyncDetail';
import { TeamHttpService } from '@services/http/TeamHttpService';
import { Team } from '@models/teams/team';

@Component({
  selector: 'app-team-link',
  templateUrl: './team-link.component.html',
  styleUrls: ['./team-link.component.css'],
})
export class TeamLinkComponent implements OnInit {
  @Input() teamUuid = '';
  team: AsyncDetail<Team>;
  constructor(private _teamHttpService: TeamHttpService) {}

  ngOnInit(): void {
    this.team = new AsyncDetail(this.teamUuid, this._teamHttpService);
    this.team.load();
  }
}
