import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from 'src/app/services/api.service';
import { LoggingService } from 'src/app/services/logging.service';


@Injectable({
  providedIn: 'root'
})
export class SpecialityUserRelationService {

  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService,
  ) { }

  public fetch_by_speciality_uuid(speciality_uuid: string): Observable<any> {
    const params = {speciality: speciality_uuid, expand: 'user,speciality,grade'};
    return this.api_service.get(`specialities/user`, params).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} fetched speciality user relations for uuid ${speciality_uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to fetch speciality user relations`);
        }
    ));
  }

  public fetch_by_user_uuid(user_uuid: string): Observable<any> {
    const params = {user: user_uuid, expand: 'user,speciality,grade'};
    return this.api_service.get(`specialities/user`, params).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} fetched speciality user relations for user id ${user_uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to fetch speciality user relations`);
        }
    ));
  }

  public create(relation_data: any): Observable<any> {
    const params = {expand: 'user,speciality,grade'};
    return this.api_service.post(`specialities/user`, relation_data, params).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} created speciality user relation with uuid ${response.uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to create speciality user relation`);
        }
    ));
  }

  public update(relation_uuid: string, relation_data: any): Observable<any> {
    return this.api_service.patch(`specialities/user/${relation_uuid}`, relation_data, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} updated speciality user relation with uuid ${response.uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to update speciality user relation`);
        }
      ));
  }

  public delete(relation_uuid: string): Observable<any> {
    return this.api_service.delete(`specialities/user/${relation_uuid}`, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} deleted speciality user relation with uuid ${relation_uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to delete speciality user relation`);
        }
    ));
  }
}
