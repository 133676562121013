import { SpecialityCompetenceClaim } from '@models/specialities/speciality-competence-claim';

export interface ThingCompetenceClaimMap {
  [thingUuid: string]: SpecialityCompetenceClaim[];
}

export const build_thing_competenceclaim_map = (
  competenceClaims: SpecialityCompetenceClaim[]
): ThingCompetenceClaimMap => {
  const thingCompetenceClaimMap = {};
  competenceClaims.forEach((competenceClaim) => {
    if (competenceClaim.thing.levels.length) {
      if (
        !Object.keys(thingCompetenceClaimMap).includes(
          competenceClaim.thing.uuid
        )
      ) {
        thingCompetenceClaimMap[competenceClaim.thing.uuid] = [competenceClaim];
      } else {
        thingCompetenceClaimMap[competenceClaim.thing.uuid].push(
          competenceClaim
        );
      }
    }
  });
  return thingCompetenceClaimMap;
};
