import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { testDataAttributes } from '@test-utils';

import { LoggingService } from 'src/app/services/logging.service';
import { TitleService } from 'src/app/services/title.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { TeamService } from 'src/app/services/teams/team.service';
import { Team } from 'src/app/models/teams/team';
import {
  TeamCreateDialogComponent,
  TeamData,
} from '@components/common/team-create-dialog/team-create-dialog.component';

import { TeamHttpService } from '@services/http/TeamHttpService';
import { TeamMembershipHttpService } from '@services/http/TeamMembershipHttpService';
import { AsyncList } from '@rest/AsyncList';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AppService } from 'src/app/services/app/app.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-teams-dashboard',
  templateUrl: './teams-dashboard.component.html',
  styleUrls: ['./teams-dashboard.component.css'],
})
export class TeamsDashboardComponent implements OnInit {
  public testDataAttributes = testDataAttributes;
  public teams: AsyncList<Team>;

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private auth_service: AuthService,
    private user_service: UserService,
    private app_service: AppService,
    private title_service: TitleService,
    private teamService: TeamService,
    private _teamService: TeamHttpService,
    private _teamMembershipService: TeamMembershipHttpService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.title_service.set_title('Команды');
    this.loadTeams();
  }

  private loadTeams(): void {
    this.logging_service.debug(`${this.constructor.name} loading teams...`);
    this.teams = new AsyncList<Team>(this._teamService);
    this.teams.setRequestParams({
      params: { expand: 'parent,created_by' },
    });
    this.teams.load().subscribe(
      (response) => {
        this.logging_service.debug(
          `${this.constructor.name} teams loaded successfully`
        );
        this.teams.state.items.sort((a, b) => a.name.localeCompare(b.name));
      },
      (err) => {
        this.alert_service.error(`Ошибка при загрузке команд: ${err.status}`);
        this.logging_service.error(
          `${this.constructor.name} failed to load teams`
        );
      }
    );
  }

  get getUnverifiedTeams(): Team[] {
    return this.teams.state.items.filter((team) => team.is_verified === false);
  }

  onDialogShow(): void {
    this._dialog.open(TeamCreateDialogComponent, {
      data: {
        teams: this.teams.state.items,
        onCreateTeam: (formData: TeamData) => {
          this.createTeam(formData);
        },
      },
    });
  }

  createTeam(formData: TeamData): void {
    const userUuid = this.auth_service.get_current_user_uuid();
    const teamData = {
      name: formData.teamName,
      description: formData.teamDescription,
      parent: formData.teamParent.uuid,
      team_type: formData.teamType,
      created_by: userUuid,
    };
    this._teamService.create({ ...teamData }).subscribe({
      next: (team) => {
        this.logging_service.debug(
          `${this.constructor.name} successfully created team`
        );
        const membershipData = {
          team: team.uuid,
          user: userUuid,
          role: 'owner',
        };
        this._teamMembershipService
          .create({ ...membershipData })
          .subscribe(() => {
            this.app_service.load_user_memberships();
            this.user_service.get_edit_teams().subscribe((response) => {
              this.logging_service.debug(
                `${this.constructor.name} fetched user edit teams`
              );
              this.auth_service.set_user_edit_teams(response);
            });
            this.loadTeams();
          });
      },
      error: (error) => {
        this.logging_service.debug(
          `${this.constructor.name} team creation failed`
        );
        this.alert_service.error('Ошибка создания команды');
      },
    });
  }
}
