import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { HistogramSimpleComponent } from './components/histograms/histogram-simple/histogram-simple.component';
import { CompetenceDeltaChartComponent } from './components/competencies/competence-delta-chart/competence-delta-chart.component';
import { CompetenceHeatmapChartComponent } from './components/competencies/competence-heatmap-chart/competence-heatmap-chart.component';
import { SimpleRadarChartComponent } from './simple-radar/simple-radar-chart/simple-radar-chart.component';
import { SpecialityRadarChartComponent } from './specialities/speciality-radar-chart/speciality-radar-chart.component';
import { LineSimpleComponent } from './components/lines/line-simple/line-simple.component';


@NgModule({
  declarations: [
    HistogramSimpleComponent,
    CompetenceDeltaChartComponent,
    CompetenceHeatmapChartComponent,
    SimpleRadarChartComponent,
    SpecialityRadarChartComponent,
    LineSimpleComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,

    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule
  ],
  exports: [
    HistogramSimpleComponent,
    CompetenceDeltaChartComponent,
    CompetenceHeatmapChartComponent,
    SimpleRadarChartComponent,
    SpecialityRadarChartComponent,
    LineSimpleComponent,
  ],
})
export class CommonD3ChartsModule {}
