<div class="router-outlet-container">
  <ng-container *ngIf="is_data_loaded; then component_content; else preloader">
  </ng-container>
</div>

<ng-template #preloader>
  <app-preloader></app-preloader>
</ng-template>

<ng-template #component_content>
  <div class="app-track-speciality-user-compare-container">
    <div class="app-track-speciality-user-compare-header-container">
      <div fxLayout="row">
        <div fxFlex>
          <div
            class="app-track-speciality-user-compare-header-speciality-prefix"
          >
            Трек специальности
          </div>
          <div class="app-track-speciality-user-compare-header-speciality-name">
            {{ speciality.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="app-track-speciality-user-compare-visual-container">
      <div fxLayout="row">
        <div fxFlex="500px" fxFlexOffset="2rem">
          <div class="app-track-speciality-user-compare-user-container">
            <div fxLayout="row">
              <div fxFlex="5rem">
                <div class="app-track-speciality-user-compare-user-avatar">
                  {{ util_user_get_initials_for_avatar(user) }}
                </div>
              </div>
              <div fxFlex="25rem">
                <div class="app-track-speciality-user-compare-user-name">
                  {{ user.first_name }} {{ user.last_name }}
                </div>
                <div class="app-track-speciality-user-compare-user-email">
                  {{ user.email }}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="chartAxesData?.length > 2">
            <app-chart-radar
              [htmlElementClass]="actualChartHtmlElementClass"
              [grades]="chartGrades"
              [axesData]="chartAxesData"
              [radarData]="chartRadarData"
            ></app-chart-radar>
          </div>

          <div *ngIf="chartAxesData?.length > 0 && chartAxesData?.length < 3">
            <div *ngFor="let dataItem of chartRadarData[0].axes">
              <app-progress-bar
                [title]="dataItem.name"
                [description]="
                  dataItem.title +
                  ': ' +
                  dataItem.valueRaw +
                  ' (' +
                  dataItem.value +
                  '%)'
                "
                [value]="dataItem.value"
              ></app-progress-bar>
            </div>
          </div>
          <div
            *ngIf="chartAxesData.length === 0"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <p>
              Отсутствуют навыки, относящиеся к специальности (возможно
              требуется пройти опрос)
            </p>
          </div>
        </div>
        <div fxFlex fxLayoutAlign="center center">
          <div style="color: #999; font-size: 3rem; margin-top: 3rem">
            &rarr;
          </div>
        </div>
        <div fxFlex="500px" fxFlexOffset="2rem">
          <div
            class="app-track-speciality-user-compare-grade-selector-container"
          >
            <mat-form-field>
              <mat-label>Целевой уровень</mat-label>
              <mat-select
                [(value)]="selected_grade"
                (selectionChange)="on_selected_grade_change()"
              >
                <mat-option
                  *ngFor="let grade of speciality_grades"
                  [value]="grade"
                >
                  {{ grade.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="chartAxesData?.length > 2">
            <app-chart-radar
              [htmlElementClass]="desiredChartHtmlElementClass"
              [grades]="chartGrades"
              [axesData]="chartAxesData"
              [radarData]="desiredChartGrade"
            ></app-chart-radar>
          </div>

          <div *ngIf="chartAxesData?.length > 0 && chartAxesData?.length < 3">
            <div *ngFor="let dataItem of desiredChartGrade[0].axes">
              <app-progress-bar
                [title]="dataItem.name"
                [description]="
                  dataItem.title +
                  ': ' +
                  dataItem.valueRaw +
                  ' (' +
                  dataItem.value +
                  '%)'
                "
                [value]="dataItem.value"
              ></app-progress-bar>
            </div>
          </div>
          <div
            *ngIf="chartAxesData.length === 0"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <p>
              Отсутствуют навыки, относящиеся к специальности (возможно
              требуется пройти опрос)
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="app-track-speciality-user-compare-competencies-tree-container">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="15rem">
          <div style="font-size: 1.2rem; margin: 2rem">Области роста</div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="50rem">
          <div *ngFor="let domainclaim_treenode of hierarchy_root_treenodes">
            <ng-container
              [ngTemplateOutlet]="tmpl_domainclaim_treenode"
              [ngTemplateOutletContext]="{ treenode: domainclaim_treenode }"
            >
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_domainclaim_treenode let-treenode="treenode">
  <div
    class="app-track-speciality-user-compare-competencies-treenode-container"
  >
    <div fxLayout="row">
      <div fxFlex>
        <div
          class="app-track-speciality-user-compare-competencies-treenode-domain-name"
        >
          {{ treenode.domain_claim.domain.name }}
        </div>
      </div>
    </div>

    <div fxLayout="row">
      <div fxFlex fxFlexOffset="1rem" fxLayoutAlign="start center">
        <div *ngIf="treenode.competencies.length">
          <div *ngFor="let competence of treenode.competencies">
            <ng-container
              [ngTemplateOutlet]="
                is_competence_claim_satisfied(competence)
                  ? tmpl_satisfied
                  : tmpl_unsatisfied
              "
              [ngTemplateOutletContext]="{ competence: competence }"
            >
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="row">
      <div fxFlex fxFlexOffset="1rem">
        <div *ngIf="treenode.children.length">
          <div *ngFor="let domainclaim_treenode of treenode.children">
            <ng-container
              [ngTemplateOutlet]="tmpl_domainclaim_treenode"
              [ngTemplateOutletContext]="{ treenode: domainclaim_treenode }"
            >
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_satisfied let-competence="competence">
  <div
    class="app-track-speciality-user-compare-competencies-treenode-competence-listitem-container app-track-speciality-user-compare-competencies-treenode-competence-listitem-satisfied"
  >
    <div fxLayout="row">
      <div fxFlex="25rem">
        <div
          class="app-track-speciality-user-compare-competencies-treenode-competence-listitem-name"
        >
          {{ competence.thing.name }}
        </div>
      </div>
      <div fxFlex="25rem">
        <mat-icon style="color: #20a200">check</mat-icon>
        {{ get_competence_level_readable(competence) }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_unsatisfied let-competence="competence">
  <div
    class="app-track-speciality-user-compare-competencies-treenode-competence-listitem-container app-track-speciality-user-compare-competencies-treenode-competence-listitem-unsatisfied"
  >
    <div fxLayout="row">
      <div fxFlex="25rem">
        <div
          class="app-track-speciality-user-compare-competencies-treenode-competence-listitem-name"
        >
          {{ competence.thing.name }}
        </div>
      </div>
      <div fxFlex="25rem">
        {{ get_user_competence_level_readable(competence) }}
        &nbsp; <span style="color: #ff0050">&rarr;</span> &nbsp;
        {{ get_expected_competence_level_readable(competence) }}
      </div>
    </div>
  </div>
</ng-template>
