import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root',
})
export class PdpStepThingRelationService {
  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService
  ) {}

  public create(relation_data: any): Observable<any> {
    const params = { expand: 'thing' };
    return this.api_service
      .post(`track/stepthingrelation`, relation_data, params)
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} created pdp step thing relation with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to create pdp step thing relation`
            );
          }
        )
      );
  }

  public delete(relation_uuid: string): Observable<any> {
    return this.api_service
      .delete(`track/stepthingrelation/${relation_uuid}`, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} deleted pdp step thing relation with uuid ${relation_uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to delete pdp step thing relation`
            );
          }
        )
      );
  }

  public fetch_by_pdp_uuid(pdp_uuid: string): Observable<any> {
    const params = { pdp: pdp_uuid, expand: 'thing' };
    return this.api_service.get(`track/stepthingrelation`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} pdp step thing relations`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch pdp step thing relations`
          );
        }
      )
    );
  }
}
