import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import * as d3 from 'd3';

import { LoggingService } from 'src/app/services/logging.service';

interface BarData {
  name: string;
  color: string;
  level: number;
  percentage: number;
}

export interface BarChartData {
  title: string;
  data: BarData[];
}

@Component({
  selector: 'app-chart-bar',
  templateUrl: './chart-bar.component.html',
  styleUrls: ['./chart-bar.component.css'],
})
export class ChartBarComponent implements OnInit {
  @Input() htmlElementClass: string;

  @Input() chartData: BarChartData;

  private _parent: any;
  private _svg: any;

  margin = { top: 20, right: 10, bottom: 40, left: 10 };
  svgWidth = 107;
  svgHeight = 160;
  width;
  height;
  x;
  y;
  xAxis;
  yAxis;
  rx;
  ry;

  constructor(private loggingService: LoggingService) {}

  ngOnInit(): void {
    this.loggingService.debug(
      `${this.constructor.name} init ${this.htmlElementClass}`
    );
    this.setConstants();
  }

  private get maxDataValue(): number {
    // 100%
    return 110;
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.setConstants();
      this.drawChart();
    }, 100);
  }

  private setConstants(): void {
    this.width = this.svgWidth - this.margin.left - this.margin.right;
    this.height = this.svgHeight - this.margin.top - this.margin.bottom;
    this.rx = 12;
    this.ry = 12;
    this.x = d3
      .scaleBand()
      .range([0, this.width])
      .domain(this.chartData.data.map((d) => d.name))
      .padding(0.1);

    this.y = d3
      .scaleLinear()
      .range([this.height, 0])
      .domain([-5, 105])
      // .domain([
      //   d3.min(this.chartData.data, (d) => d.percentage - 5),
      //   d3.max(this.chartData.data, (d) => d.percentage + 5),
      // ])
      .nice();

    this.xAxis = d3.axisBottom(this.x).tickFormat((d, i) => {
      return Math.ceil(
        this.chartData.data.find((e) => e.name === d).level
      ).toString();
    });
    this.yAxis = d3.axisLeft(this.y).ticks(5);
  }

  private drawChart(): void {
    this.loggingService.debug(
      `${this.constructor.name} draw chart ${this.htmlElementClass}`
    );
    this.svgInit();
    this.drawAxes();
  }

  private svgInit(): void {
    this._parent = d3.select(`.${this.htmlElementClass}`);
    this._parent.select('svg').remove();
    this._svg = this._parent
      .append('svg')
      .attr('width', this.svgWidth)
      .attr('height', this.svgHeight);
  }

  private drawAxes(): void {
    const graphArea = this._svg
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    const gxAxis = graphArea
      .append('g')
      .attr('class', 'axis')
      .attr('transform', `translate(0, ${this.height})`)
      .call(this.xAxis);
    gxAxis.selectAll('line').style('stroke', '#F9F9F9');
    gxAxis.selectAll('path').style('stroke', '#F9F9F9');

    // graphArea.append('g').attr('class', 'axis').call(this.yAxis);

    graphArea
      .selectAll('bar')
      .data(this.chartData.data)
      .enter()
      .append('path')
      .style('fill', (item) => item.color)
      .attr(
        'd',
        (item) => `
        M${this.x(item.name)},${this.y(item.percentage) + this.ry}
        a${this.rx},${this.ry} 0 0 1 ${this.rx},${-this.ry}
        h${this.x.bandwidth() - 2 * this.rx}
        a${this.rx},${this.ry} 0 0 1 ${this.rx},${this.ry}
        v${this.height - this.y(item.percentage) - this.ry}
        h${-this.x.bandwidth()}Z
      `
      );
  }
}
