<div fxFlex="row" fxFill="space-around">
  <div fxFlex="70" class="thing-main">
    <div *ngIf="thing.state?.item" fxLayout="row" class="thing-links">
      <app-team-link
        *ngIf="thing.state.item.team"
        [teamUuid]="thing.state.item.team"
      >
      </app-team-link>
      <span class="thing-links__delimeter" *ngIf="thing.state.item.team">
        >
      </span>
      <app-parent-domain
        *ngIf="thing.state.item.domain"
        [domainUuid]="getDomainUuid()"
      ></app-parent-domain>
      <span class="thing-links__delimeter"> > </span>
      <span>{{ thing.state.item.name }}</span>
    </div>

    <form class="thing-main-content" [formGroup]="form" fxLayout="column">
      <mat-form-field class="thing-title-input" fxFlex>
        <input matInput formControlName="name" />
      </mat-form-field>

      <span class="thing-main-content-description" fxLayout="column">
        <span class="thing-title__label">Описание:</span>
        <mat-form-field class="thing-title__description" fxFlex>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>
      </span>
      <mat-form-field
        [style.width.rem]="30"
        *ngIf="thing.state?.item?.domain && domains.state.isLoaded"
      >
        <mat-label>Домен</mat-label>
        <mat-select formControlName="domain" [value]="thing.state.item.domain">
          <mat-option [value]="thing.state.item.domain" disabled="disabled">
            {{ getThingDomain().name }}
          </mat-option>
          <mat-option
            *ngFor="let domain of domains.state.items"
            [value]="domain"
          >
            {{ domain.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div [fxLayoutAlign]="'space-between'">
        <button
          mat-stroked-button
          color="primary"
          [disabled]="form.pristine"
          (click)="save()"
        >
          Сохранить
        </button>

        <button
          mat-stroked-button
          color="primary"
          (click)="openRemoveThingDialog()"
          fxLayoutAlign="end"
        >
          Удалить
        </button>
      </div>
    </form>

    <!-- <app-thing-edit-levels
      [thingLevels]="thingLevels"
      [thing]="thing"
    ></app-thing-edit-levels> -->
  </div>

  <div fxFlex="30" class="thing-sidebar-content">
    <div class="thing-sidebar-content-materials">
      <p><mat-icon>warning</mat-icon></p>
      <p>Вы редактируете компетенцию из библиотеки</p>
    </div>

    <div class="thing-sidebar-content-level">
      <p>Изменения здесь отразятся на всех командах</p>
    </div>
  </div>
</div>
