import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { LoggingService } from 'src/app/services/logging.service';
import { ApiService } from '../api.service';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private app_state = {
    initialization: {
      auth: {
        logged_in: false,
        profile: false,
        model: false,
      },
    },
    unread_counter: 0,
  };
  public version = '2.2.5';

  private auth_events_subject: Subject<any>;

  constructor(
    private router: Router,
    private logging_service: LoggingService,
    private api_service: ApiService,
    private auth_service: AuthService,
    private user_service: UserService
  ) {
    this.auth_events_subject = new Subject<any>();
    this.auth_events_subject.subscribe((e) => this.on_auth_event(e));
  }

  public initialize(): void {
    this.logging_service.debug(`${this.constructor.name} initialize`);
    this.auth_service.set_auth_events_subject(this.auth_events_subject);
    this.auth_service.auth().then(() => {});
  }

  public on_auth_logged_in_status(logged_in_status: boolean): void {
    this.logging_service.debug(
      `${this.constructor.name} on_auth_logged_in_status`
    );
  }

  public is_app_ready(): boolean {
    return (
      this.app_state.initialization.auth.logged_in &&
      this.app_state.initialization.auth.profile &&
      this.app_state.initialization.auth.model
    );
  }

  public get useNewLayout(): boolean {
    const useNewLayout = localStorage.getItem('useNewLayout');
    if (useNewLayout) {
      return JSON.parse(useNewLayout);
    }
  }

  public get user_unread_counter(): number {
    return this.app_state.unread_counter;
  }

  private on_auth_event(e: any): void {
    this.logging_service.debug(
      `${this.constructor.name} on_auth_event ${e.event}`
    );
    if (e.event === 'logged_in_status_changed') {
      this.process_event_auth_logged_in_status_changed(e);
    }
    if (e.event === 'auth_profile_loaded') {
      this.process_event_auth_profile_loaded(e);
    }
  }

  private process_event_auth_logged_in_status_changed(e: any): void {
    this.logging_service.debug(
      `${this.constructor.name} process_event_auth_logged_in_status_changed`
    );
    this.app_state.initialization.auth.logged_in = true;
    if (e.value === true) {
      this.logging_service.debug(
        `${this.constructor.name} process_event_auth_logged_in_status_changed: value=${e.value}`
      );
    } else {
      this.app_state.initialization.auth.profile = true; // we do not request profile next on logged out user
      this.app_state.initialization.auth.model = true; // we do not request profile next on logged out user
      this.logging_service.debug(
        `${this.constructor.name} process_event_auth_logged_in_status_changed: value=${e.value}`
      );
    }
  }

  private process_event_auth_profile_loaded(e: any): void {
    this.logging_service.debug(
      `${this.constructor.name} process_event_auth_profile_loaded: value=${e.value}`
    );
    this.app_state.initialization.auth.profile = true;
    this.logging_service.debug(`${this.constructor.name} fetching user model`);
    this.setUserModel();
  }

  public setUserModel(): void {
    this.user_service
      .fetch_user_by_uuid(this.auth_service.get_current_user_uuid())
      .subscribe(
        (user_response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched user data`
          );
          this.auth_service.set_current_user_data(user_response);
          this.app_state.initialization.auth.model = true;
          if (localStorage.getItem('useNewLayout')) {
            this.setUseNewLayout(
              JSON.parse(localStorage.getItem('useNewLayout'))
            );
          } else {
            this.setUseNewLayout(true);
          }
          this.load_user_dashboard_counters(
            user_response.is_onboarding_complete
          );
          this.load_user_edit_teams();
          this.load_user_memberships();
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name}: failed to fetch user data`
          );
          if (err.status === 401 || err.status === 404) {
            this.router.navigate(['/401']);
            return;
          }
          this.router.navigate(['/500']);
        }
      );
  }

  public setUseNewLayout(useNewLayout: boolean): void {
    localStorage.setItem('useNewLayout', JSON.stringify(useNewLayout));
  }

  private load_user_dashboard_counters(is_onboarding_complete: boolean) {
    if (!is_onboarding_complete) {
      this.router.navigate(['/onboarding']);
    } else {
      this.api_service.get(`dashboard/counters`, {}).subscribe(
        (response) => {
          const counters = response as any;
          this.logging_service.debug(
            `${this.constructor.name} fetched dashboard counters`
          );
          this.app_state.unread_counter =
            counters.surveys.unfinished_assignments +
            counters.surveys.unfinished_sessions +
            counters.unfinished_reviews;
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch dashboard counters`
          );
        }
      );
    }
  }

  public load_user_memberships() {
    this.user_service.get_user_team_memberships().subscribe((response) => {
      this.auth_service.set_user_memberships(response.results);
    });
  }

  private load_user_edit_teams() {
    this.user_service.get_edit_teams().subscribe(
      (response) => {
        this.logging_service.debug(
          `${this.constructor.name} fetched user edit teams`
        );
        this.auth_service.set_user_edit_teams(response);
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name}: failed to fetch user edit teams`
        );
      }
    );
  }
}
