<ng-container *ngIf="is_data_loaded; then tmpl_tab_content; else tmpl_preloader">
</ng-container>

<ng-template #tmpl_preloader>
    <app-preloader-progressbar></app-preloader-progressbar>
</ng-template>

<ng-template #tmpl_tab_content>
    <div class="app-admin-survey-details-tab-container">
        <div fxLayout="row">
            <div fxFlex>

                <div *ngIf="survey_assignments.length === 0">
                    Нет назначенных опросов
                </div>

                <div *ngIf="survey_assignments.length > 0">

                    <div *ngFor="let assignment of survey_assignments">
                        <div class="app-admin-survey-details-assignment-listitem-container">

                            <div fxLayout="row">
                                <div fxFlex="5rem">
                                    <div class="app-admin-survey-details-assignment-listitem-status">
                                        <div *ngIf="assignment.is_complete">
                                            <mat-icon>check</mat-icon>
                                        </div>
                                        <div *ngIf="!assignment.is_complete">
                                            <mat-icon>hourglass_top</mat-icon>
                                        </div>
                                    </div>
                                </div>
                                <div fxFlex="5rem">
                                    <div class="app-admin-survey-details-assignment-listitem-user-avatar">
                                        {{ util_user_get_initials_for_avatar(assignment.user) }}
                                    </div>
                                </div>
                                <div fxFlex="25rem">
                                    <div class="app-admin-survey-details-assignment-listitem-user-name">
                                        {{ assignment.user.first_name }} {{ assignment.user.last_name }}
                                    </div>
                                    <div class="app-admin-survey-details-assignment-listitem-user-email">
                                        {{ assignment.user.email }}
                                    </div>
                                </div>
                                <div fxFlex>
                                    <div class="app-admin-survey-details-assignment-listitem-dates">
                                        <div fxLayout="row">
                                            <div fxFlex="7rem">
                                                Назначен
                                                <br>
                                                Обновлен
                                            </div>
                                            <div fxFlex>
                                                {{ assignment.date_created | amDateFormat: 'YYYY-MM-DD HH:mm' }}
                                                <br>
                                                {{ assignment.date_updated | amDateFormat: 'YYYY-MM-DD HH:mm' }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div fxFlex="5rem">
                                    <button mat-icon-button (click)="on_button_delete_survey_assignment(assignment)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>
</ng-template>