import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { ThingService } from 'src/app/services/ontology/thing.service';
import { SpecialityCompetenceClaimService } from 'src/app/services/specialities/speciality-competence-claim.service';

import { Thing } from 'src/app/models/ontology/thing';
import { Domain } from 'src/app/models/ontology/domain';
import { Speciality } from 'src/app/models/specialities/speciality';
import { SpecialityGrade } from 'src/app/models/specialities/speciality-grade';
import { SpecialityCompetenceClaim } from 'src/app/models/specialities/speciality-competence-claim';

@Component({
  selector: 'app-speciality-profile-dialog-claim-create',
  templateUrl: './speciality-profile-dialog-claim-create.component.html',
  styleUrls: ['./speciality-profile-dialog-claim-create.component.css'],
})
export class SpecialityProfileDialogClaimCreateComponent implements OnInit {
  public thing_search_form_control = new UntypedFormControl('', []);
  private thing_search_terms = new Subject<string>();
  private thing_search$: Observable<{ results: Thing[] } | null>;
  public thing_search_results: Thing[] = [];
  public thing_search_selected: Thing<Domain>;

  public selected_grade: SpecialityGrade;
  public selected_level: number;

  private uistate = {
    claim_create_loading: false,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { speciality: Speciality; grades: SpecialityGrade[] },
    public dialogRef: MatDialogRef<SpecialityProfileDialogClaimCreateComponent>,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private thing_service: ThingService,
    private competence_claim_service: SpecialityCompetenceClaimService
  ) {}

  ngOnInit(): void {
    this.thing_search$ = this.thing_search_terms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) => {
        return this.data.speciality.team
          ? this.thing_service.search_with_team(
              term,
              this.data.speciality.team?.uuid
            )
          : this.thing_service.search(term);
      })
    );
    this.thing_search$.subscribe((response) => {
      console.log(response);
      this.thing_search_results = response.results;
    });
  }

  public get is_claim_create_loading(): boolean {
    return this.uistate.claim_create_loading;
  }

  public get is_all_data_specified(): boolean {
    return Boolean(this.thing_search_selected);
  }

  public on_thing_search(term: string): void {
    this.thing_search_terms.next(term);
  }

  public on_thing_search_autocomplete_selected(thing: Thing<Domain>): void {
    this.logging_service.debug(
      `${this.constructor.name} on_thing_search_autocomplete_selected`
    );
    this.thing_search_selected = thing;
    this.thing_search_results = [];
  }

  public util_thing_search_display(thing: Thing): string {
    return thing ? `${thing.name} – ${this.get_thing_domain_name(thing)}` : '';
  }

  public get_thing_domain_name(thing: Thing): string {
    return thing ? (thing.domain as Domain).name : '';
  }

  public on_create_claim(): void {
    this.uistate.claim_create_loading = true;
    this.logging_service.debug(`${this.constructor.name} creating claim...`);
    const claim_data = {
      speciality: this.data.speciality.uuid,
      thing: this.thing_search_selected.uuid,
      domain: this.thing_search_selected.domain.uuid,
    };
    this.competence_claim_service
      .add_claims_with_grades([claim_data])
      .subscribe(
        (response) => {
          const claim = response as SpecialityCompetenceClaim;
          this.logging_service.debug(
            `${this.constructor.name} successfully created claims`
          );
          this.uistate.claim_create_loading = false;
          this.dialogRef.close(claim);
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} claims creation failed`
          );
          this.alert_service.error(`Ошибка создания требования: ${err.status}`);
          this.uistate.claim_create_loading = false;
        }
      );
  }
}
