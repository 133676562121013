<span *ngIf="!isClickable" class="parent-domain">
  {{ domain?.state?.item?.name }}
</span>

<a
  *ngIf="isClickable"
  class="parent-domain-link"
  [routerLink]="['/library/domains', domain?.state?.item?.uuid]"
>
  {{ domain?.state?.item?.name }}
</a>
