<div class="dialog-container">
  <div class="title">Добавить компетенцию в библиотеку</div>
  <form [formGroup]="form">
    <ng-select
      (search)="search($event.term)"
      (change)="select($event)"
      [items]="domains.state.items"
      [loading]="domains.state.isProcessing"
      loadingText="Загружаем домены"
      bindLabel="name"
      appendTo="body"
      class="domains-search"
      formControlName="search"
    >
    </ng-select>
    <mat-form-field class="search-field">
      <input
        matInput
        placeholder="Имя компетенции"
        type="text"
        formControlName="name"
      />
    </mat-form-field>
    <mat-form-field class="search-field">
      <input
        matInput
        placeholder="Описание компетенции"
        type="text"
        formControlName="description"
      />
    </mat-form-field>
  </form>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button class="dialog-reject-button" mat-raised-button>Отменить</button>
    <button
      class="dialog-accept-button"
      [disabled]="form.invalid"
      mat-raised-button
      (click)="close()"
    >
      Добавить
    </button>
  </div>
</div>
