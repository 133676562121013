import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewSetHttpService } from './rest/ViewSetHttpService';
import { SpecialityReviewer } from '@models/specialities/speciality-reviewer';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpecialityReviewerHttpService extends ViewSetHttpService<SpecialityReviewer> {
  http: HttpClient;
  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }
  public viewSetUrl = 'specialities/reviewer';

  public getValidationsReviewers(data: string[]): Observable<any> {
    return this.http.post<string[]>(
      `${this.getUrl()}/get_validations_reviewers`,
      data
    );
  }
}
