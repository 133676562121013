import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SurveyMainComponent } from './components/survey-main/survey-main.component';
import { SurveyTreeResultComponent } from './components/survey-tree-result/survey-tree-result.component';

const routes: Routes = [
  { path: 'survey/:survey_uuid', component: SurveyMainComponent},
  { path: 'result/tree/:survey_uuid/:user_uuid', component: SurveyTreeResultComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SurveysRoutingModule { }
