<div
  class='node-container'
  [class.node-container_hover]='node.item.isThing || !node.item.isExpanded'
  [class.gray-background]='isNodeUneven'
  *ngIf='!isDeleted'
>
  <div
    fxLayout='row'
    fxLayoutAlign='start center'
    [class.node-margin]='node.item.isExpanded && !node.item.isThing'
  >
    <ng-container [ngTemplateOutlet]='domainNode'></ng-container>
    <ng-container
      *ngIf='node.item.isThing && thingGradeLevelsMap'
      [ngTemplateOutlet]='thingGradeLevelsNode'
    ></ng-container>
    <ng-container
      *ngIf="
        node.item.isThing &&
        (reviewMode === 'self-assessment' || reviewMode === 'assessment')
      "
      [ngTemplateOutlet]='assessmentNode'
    >
    </ng-container>
    <ng-container
      *ngIf="node.item.isThing && reviewMode === 'resolution'"
      [ngTemplateOutlet]='resolutionNode'
    ></ng-container>
    <ng-container
      *ngIf="node.item.isThing && reviewMode === 'result'"
      [ngTemplateOutlet]='resultNode'
    ></ng-container>
    <ng-container
      *ngIf='node.item.isThing && thingCurrentDesiredLevelMap'
      [ngTemplateOutlet]='currentDesiredNode'
    ></ng-container>
    <ng-container
      *ngIf='node.item.isThing && thingDesireMap && !reviewMode'
      [ngTemplateOutlet]='desireDateNode'
    ></ng-container>
    <ng-container
      *ngIf='node.item.isThing && thingDesireWithUsersMap'
      [ngTemplateOutlet]='desireWithUserNode'
    ></ng-container>
    <ng-container *ngIf='node.item.isThing && thingUserCompetenceMap'
                  [ngTemplateOutlet]='userCompetenceNode'></ng-container>
    <button
      fxFlex='48px'
      *ngIf='angularNode.expandable'
      (click)='node.item.isExpanded = !node.item.isExpanded'
      mat-icon-button
    >
      <mat-icon>{{
        node.item.isExpanded ? 'expand_less' : 'expand_more'
        }}</mat-icon>
    </button>
  </div>
  <div *ngIf='angularNode.expandable && node.item.isExpanded'>
    <div *ngFor='let childNode of node.children'>
      <app-domain-thing-tree-node
        [node]='childNode'
        [angularNodes]='angularNodes'
        [userUuid]='userUuid'
        [selectMode]='selectMode'
        [profileLevels]=profileLevels
        (selectThing)='
          onToggleThingSelection($event.selectedThing, $event.remove)
        '
        (selectDomain)='onToggleDomainSelection($event)'
        [libraryEdit]='libraryEdit'
        [thingGradeLevelsMap]='thingGradeLevelsMap'
        [thingUserCompetenceMap]='thingUserCompetenceMap'
        (thingDelete)='onDeleteThing($event)'
        (domainDelete)='onDeleteDomain($event)'
        (changeDomainOrder)='onChangeDomainOrder($event.domain, $event.mode)'
        (isKeyCompetenceToggle)='
          onToggleKeyCompetence($event.thingUuid, $event.isKey)
        '
        (changeGradeLevel)='
          onGradeLevelChange(
            $event.thingUuid,
            $event.gradeUuid,
            $event.nextThingLevelUuid
          )
        '
        (changeUserCompetenceLevel)='
          onUserCompetenceLevelChange(
            $event.competenceUuid,
            $event.levelUuid,
            $event.thingUuid
          )
        '
        [thingDesireMap]='thingDesireMap'
        [thingDesireWithUsersMap]='thingDesireWithUsersMap'
        [thingCurrentDesiredLevelMap]='thingCurrentDesiredLevelMap'
        [firstNode]='firstNode'
        [expandNode]='expandNode'
        [reviewMode]='reviewMode'
        [isEditable]='isEditable'
        [assessmentResponseThingMap]='assessmentResponseThingMap'
        [resolutionResponseThingMap]='resolutionResponseThingMap'
        (acceptAssessmentResponse)='
          onAcceptAssessmentResponse(
            $event.thingNode,
            $event.assessmentResponseUuid,
            $event.selectedLevelUuid,
            $event.comment
          )
        '
        (acceptResolutionResponse)='
          onAcceptResolutionResponse(
            $event.thingNode,
            $event.resolutionResponseUuid,
            $event.selectedLevelUuid
          )
        '
      ></app-domain-thing-tree-node>
    </div>
  </div>
</div>

<ng-template #domainNode>
  <mat-checkbox
    fxFlex='48px'
    *ngIf='selectMode'
    (change)='onSelectionToggle()'
    [checked]='node.item.isSelected'
  ></mat-checkbox>
  <div fxFlex='90'
       *ngIf='
      !node.item.isThing ||
      (!thingGradeLevelsMap &&
        !assessmentResponseThingMap &&
        !thingCurrentDesiredLevelMap &&
        !thingDesireMap &&
        !thingDesireWithUsersMap &&
        !thingUserCompetenceMap)
    '
  >
    <span class='node-name'>{{ node.item.name }} </span>
    <span
      *ngIf='
        !node.item.isThing &&
        thingGradeLevelsMap &&
        countKeyChildren(node.children)
      '
      class='key-children-count'
      [class.gray-background]='!isNodeUneven'
      [class.white-background]='isNodeUneven'
    >{{ getCountKeyChildrenText(node.children) }}</span
    >
  </div>
  <div *ngIf='!node.item.isThing' fxFlex='48px'>
    <button mat-icon-button *ngIf='node.item.hasSiblings' [disabled]='node.item.reorderDisabled'
            (click)='onChangeDomainOrder(node, "up")'>
      <mat-icon>arrow_upward</mat-icon>
    </button>
  </div>
  <div *ngIf='!node.item.isThing' fxFlex='48px'>
    <button mat-icon-button *ngIf='node.item.hasSiblings' [disabled]='node.item.reorderDisabled'
            (click)='onChangeDomainOrder(node, "down")'>
      <mat-icon>arrow_downward</mat-icon>
    </button>
  </div>
  <mat-icon
    fxFlex='48px'
    fxLayoutAlign='start center'
    class='answered-icon'
    *ngIf='!node.item.isThing'
  >{{ node.item.isAnswered ? 'check_circle_outline' : '' }}</mat-icon
  >
  <span
    fxLayoutAlign='start center'
    class='conflict-text'
    *ngIf='!node.item.isThing && node.item.isConflicted'
  >Конфликт</span
  >
  <div *ngIf='profileLevels && lastNode && node.item.isExpanded' fxLayout='row'
       style='width: 115%;'>
    <span class='node-name skill-level'>Текущий  уровень навыка</span>
    <span class='node-name skill-level'>Желаемый уровень навыка</span>
  </div>
</ng-template>

<ng-template #thingGradeLevelsNode>
  <div fxLayout='row' fxFlex>
    <div fxFlex='90' fxLayout='row'>
      <div fxFlex='50' fxLayout='column' fxLayoutAlign='start space-between'>
        <span class='node-name' fxFlex>
          <a routerLink='/things/{{ node.item.uuid }}'>
            {{ node.item.name }}
          </a>
        </span>
        <div fxFlex fxLayoutAlign='start end'>
          <mat-checkbox
            [disabled]='!libraryEdit'
            (change)='onToggleKeyCompetence(node.item.uuid, $event.checked)'
            [checked]='isKeyCompetence(node.item.uuid)'
          >Ключевой навык
          </mat-checkbox
          >
        </div>
      </div>
      <div fxLayout='column' fxFlex='50' fxLayoutGap='8px'>
        <div
          *ngFor='let thing of getThingFromMap(node.item.uuid)'
          fxLayout='row'
          fxLayoutAlign='center center'
        >
          <div fxFlex='60' fxLayoutAlign='center center'>
            <span class='grade-name'>{{ thing.grade.name }}</span>
          </div>
          <div fxFlex='40' class='level-select'>
            <mat-select
              [class.gray-background]='!isNodeUneven'
              [class.white-background]='isNodeUneven'
              [value]='thing.thingLevel'
              [disabled]='!isEditable'
              (selectionChange)='
                onGradeLevelChange(
                  node.item.uuid,
                  thing.grade.uuid,
                  $event.value
                )
              '
            >
              <mat-option
                *ngFor='let level of thing.levels'
                [value]='level.uuid'
              >
                {{ level.title }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </div>
    </div>
    <div
      fxLayoutAlign='end center'
      fxFlex='10'
      *ngIf='(isEditable && !isKeyCompetence(node.item.uuid)) || libraryEdit'
    >
      <div>
        <button
          mat-icon-button
          (click)='onDeleteThingClick(node.item.uuid)'
          class='thing-delete-button'
          [class.gray-background]='!isNodeUneven'
          [class.white-background]='isNodeUneven'
        >
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
    </div>
    <div
      fxLayoutAlign='end center'
      fxFlex='10'
      *ngIf='isEditable && !libraryEdit && isKeyCompetence(node.item.uuid)'
    >
      <div matTooltip='Ключевую компетенцию нельзя удалить'>
        <button
          mat-icon-button
          [disabled]='isKeyCompetence(node.item.uuid)'
          (click)='onDeleteThingClick(node.item.uuid)'
          class='thing-delete-button'
          [class.gray-background]='!isNodeUneven'
          [class.white-background]='isNodeUneven'
        >
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #assessmentNode>
  <div fxFlex='100' fxLayout='column'>
    <div fxLayout='row' fxLayoutAlign='start center' class='node-margin'>
      <span class='node-name' fxFlex='90'>{{ node.item.name }}</span>
      <mat-icon fxFlex='5' class='answered-icon'>{{
        node.item.isAnswered ? 'check_circle_outline' : ''
        }}</mat-icon>
      <button
        fxFlex='48px'
        (click)='node.item.isExpanded = !node.item.isExpanded'
        mat-icon-button
      >
        <mat-icon>{{
          node.item.isExpanded ? 'expand_less' : 'expand_more'
          }}</mat-icon>
      </button>
    </div>
    <div class='node-desc' *ngIf='node.item.isExpanded && node.item.isThing'>
      {{ node.item.description }}
    </div>
    <div fxLayout='column' *ngIf='node.item.isExpanded' fxLayoutGap='8px'>
      <div
        fxFlex
        *ngFor='let thingLevel of thingLevels'
        class='assessment-content-container'
        [class.gray-background]='!isNodeUneven'
        [class.white-background]='isNodeUneven'
      >
        <div fxLayout='row' fxLayoutAlign='start center'>
          <!--          <mat-checkbox-->
          <!--            fxFlex='48px'-->
          <!--            (change)='onThingLevelSelectionToggle(thingLevel)'-->
          <!--            [checked]='thingLevel.isSelected'-->
          <!--          ></mat-checkbox>-->
          <mat-checkbox
            fxFlex='48px'
            (change)='onThingLevelSelectionToggle(thingLevel, node, assessmentResponse.uuid, comment.value)'
            [checked]='thingLevel.isSelected'
          ></mat-checkbox>
          <span fxFlex='90' class='level-name'>{{ thingLevel.name }}</span>
          <button
            fxFlex='48px'
            (click)='thingLevel.isExpanded = !thingLevel.isExpanded'
            mat-icon-button
          >
            <mat-icon>{{
              thingLevel.isExpanded ? 'expand_less' : 'expand_more'
              }}</mat-icon>
          </button>
        </div>
        <div *ngIf='thingLevel.isExpanded' fxLayout='row'>
          <div fxFlex='5'></div>
          <div fxFlex>
            <pre class='level-desc-text-pre'>{{ thingLevel.description }}</pre>
          </div>
        </div>
      </div>
      <div fxLayout='row'>
        <div
          *ngIf="reviewMode === 'self-assessment'"
          fxLayoutAlign='start center'
          fxFlex
        >
          <button
            mat-icon-button
            (click)='onDesireToggle()'
            [disabled]='isDesireBtnDisabled'
          >
            <mat-icon [class.desired-icon]='isThingDesired'>{{
              isThingDesired ? 'favorite' : 'favorite_border'
              }}</mat-icon>

          </button>
          <span>Хочу в этом прокачаться</span>
        </div>
        <div
          fxLayoutAlign='end center'
          fxFlex
          *ngIf="reviewMode === 'assessment'"
        >
          <mat-checkbox
            (change)='isCommentFieldVisible = !isCommentFieldVisible'
            [checked]='isCommentFieldVisible'
            class='comment-checkbox-text'
          >Добавить комментарий
          </mat-checkbox
          >
        </div>
      </div>
      <div fxLayout='row' class='text-area'>
        <mat-form-field
          *ngIf='isCommentFieldVisible'
          fxFlex
          floatLabel='never'
          appearance='outline'
          class='comment-field'
        >
          <input
            matInput
            placeholder='Введите ваш комментарий'
            type='text'
            [formControl]='comment'
          />
        </mat-form-field>
      </div>
      <!--      <div fxLayout='row' fxLayoutAlign='end center'>-->
      <!--        <button-->
      <!--          mat-raised-button-->
      <!--          class='accept-button'-->
      <!--          [disabled]='!isThingLevelSelected()'-->
      <!--          (click)='-->
      <!--            onAcceptAssessmentResponse(-->
      <!--              node,-->
      <!--              assessmentResponse.uuid,-->
      <!--              selectedThingLevel.uuid,-->
      <!--              comment.value-->
      <!--            )-->
      <!--          '-->
      <!--        >-->
      <!--          Добавить оценку-->
      <!--        </button>-->
      <!--      </div>-->
    </div>
  </div>
</ng-template>

<ng-template #resolutionNode>
  <div fxFlex='100' fxLayout='column'>
    <div fxLayout='row' fxLayoutAlign='start center' class='node-margin'>
      <span class='node-name' fxFlex='90'>{{ node.item.name }}</span>
      <mat-icon fxFlex class='answered-icon'>{{
        node.item.isAnswered ? 'check_circle_outline' : ''
        }}</mat-icon>
      <span fxFlex *ngIf='node.item.isConflicted' class='conflict-text'
      >Конфликт</span
      >
      <button
        fxFlex='48px'
        (click)='node.item.isExpanded = !node.item.isExpanded'
        mat-icon-button
      >
        <mat-icon>{{
          node.item.isExpanded ? 'expand_less' : 'expand_more'
          }}</mat-icon>
      </button>
    </div>
    <div class='node-desc' *ngIf='node.item.isExpanded && node.item.isThing'>
      {{ node.item.description }}
    </div>
    <div fxLayout='column' *ngIf='node.item.isExpanded'>
      <div
        fxFlex
        *ngFor='
          let reviewerAssessmentResponse of assessmentResponseThingMap[
            node.item.uuid
          ]
        '
        class='resolution-content-container'
        [class.gray-background]='!isNodeUneven'
        [class.white-background]='isNodeUneven'
      >
        <div fxLayout='row'>
          <div fxFlex fxLayoutAlign='start center'>
            <div fxLayout='column' fxFlex>
              <span class='user-name'>{{
                reviewerAssessmentResponse.assessment.reviewer_user.first_name +
                ' ' +
                reviewerAssessmentResponse.assessment.reviewer_user.last_name
                }}</span>
              <span class='user-email'>{{
                reviewerAssessmentResponse.assessment.reviewer_user.email
                }}</span>
            </div>
          </div>
          <div fxFlex fxLayoutAlign='end center' fxLayout='row'>
            <button
              *ngIf='reviewerAssessmentResponse.comment.length > 0'
              fxFlex='48px'
              fxLayoutAlign='end center'
              mat-icon-button
              matTooltip='{{ reviewerAssessmentResponse.comment }}'
              matTooltipPosition='below'
            >
              <mat-icon>comment</mat-icon>
            </button>
            <div fxLayoutAlign='end center'>
              <span
                class='assessment-response-level'
                [class.gray-background]='isNodeUneven'
                [class.white-background]='!isNodeUneven'
              >{{
                reviewerAssessmentResponse.competence_assertion?.thing_level
                  .title
                  ? reviewerAssessmentResponse.competence_assertion
                    ?.thing_level.title
                  : 'Не оценено'
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div
        fxLayout='row'
        fxLayoutAlign='end center'
        fxLayoutGap='1rem'
        class='custom-mat-select'
      >
        <mat-select
          [class.gray-background]='!isNodeUneven'
          [class.white-background]='isNodeUneven'
          (selectionChange)='onResolutionResponseLevelSelect($event.value)'
          placeholder='Выберите свой уровень'
          fxFlex='180px'
        >
          <mat-option
            *ngFor='let level of resolutionThingLevels'
            [value]='level.uuid'
          >
            {{ level.title }}
          </mat-option>
        </mat-select>
        <button
          mat-raised-button
          [disabled]='!isCanBeAccepted(node)'
          (click)='
            onAcceptResolutionResponse(
              node,
              resolutionResponse.uuid,
              selectedResolutionResponseLevel
            )
          '
          class='resolution-response-btn'
          color='primary'
        >
          Зафиксировать решение
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #resultNode>
  <div
    fxLayout='row'
    fxLayoutAlign='start center'
    fxFlex='100'
    fxLayoutGap='8px'
  >
    <span class='node-name' fxFlex='60'>{{ node.item.name }}</span>
    <span
      fxFlex
      class='result-text'
      (click)=onYourThingLevelClick()
      [ngClass]='ThingBackgroundColor'
    >{{ assessmentResponseLevel }}</span
    >
    <span
      fxFlex
      class='result-text'
      (click)=onFinalThingLevelClick()
      [ngClass]='ThingBackgroundColor'
    >{{ resolutionResponseLevel }}</span
    >
  </div>
</ng-template>

<ng-template #currentDesiredNode>
  <div
    fxLayout='row'
    fxLayoutAlign='start center'
    fxFlex='100'
    fxLayoutGap='8px'
  >
    <span class='node-name' fxFlex='50'>
      <a routerLink='/things/{{ node.item.uuid }}'>
        {{ node.item.name }}
      </a>
    </span>
    <span
      *ngIf='
        thingCurrentDesiredLevelMap[node.item.uuid].userLevel?.order_number <
        thingCurrentDesiredLevelMap[node.item.uuid].desiredLevel?.order_number
      '
      fxLayoutAlign='start center'
      class='conflict-text'
    >
      На прокачку
    </span>
    <div
      class='result-text'
      style="{{ isCurrentUser ? 'cursor: pointer' : '' }}"
      fxFlex
      [class.gray-background]='!isNodeUneven'
      [class.white-background]='isNodeUneven'
      (click)='
        showLevelsPopup($event, thingCurrentDesiredLevelMap[node.item.uuid])
      '
    >
      {{
      thingCurrentDesiredLevelMap[node.item.uuid].userLevel
        ? thingCurrentDesiredLevelMap[node.item.uuid].userLevel.title
        : thingCurrentDesiredLevelMap[node.item.uuid].levels[0].title
      }}
    </div>
    <div
      #levelsPopup
      class='levels-popup'
      [style.display]="showPopUp ? 'block' : 'none'"
    >
      <div
        *ngFor='let level of thingCurrentDesiredLevelMap[node.item.uuid].levels'
        class='levels-popup-row'
        fxLayout='row'
      >
        <div
          fxFlex='40px'
          (click)='
            onUserCompetenceLevelChange(
              thingCurrentDesiredLevelMap[node.item.uuid].userCompetence.uuid,
              level.uuid,
              node.item.uuid
            )
          '
        >
          <mat-icon
            [class.red-icon]='
              thingCurrentDesiredLevelMap[node.item.uuid].userLevel?.uuid ===
              level.uuid
            '
            [class.gray-icon]='
              thingCurrentDesiredLevelMap[node.item.uuid].userLevel?.uuid !==
              level.uuid
            '
          >{{
            thingCurrentDesiredLevelMap[node.item.uuid].userLevel?.uuid ===
            level.uuid
              ? 'radio_button_checked'
              : 'radio_button_unchecked'
            }}</mat-icon
          >
        </div>
        <div
          fxFlex
          (click)='onShowLevelDescription(level.uuid)'
          [class.levels-popup-row-desc_cut]='popupLevelDescShow !== level.uuid'
        >
          <div class='levels-popup-row-text'>{{ level.title }}</div>
          <div class='levels-popup-row-desc'>{{ level.description }}</div>
        </div>
      </div>
    </div>
    <div
      #levelsPopupMask
      class='levels-popup-mask'
      [style.display]="showPopUp ? 'block' : 'none'"
      (click)='hideLevelsPopup()'
    ></div>
    <mat-icon
      *ngIf='
        thingCurrentDesiredLevelMap[node.item.uuid].userCompetence &&
        thingCurrentDesiredLevelMap[node.item.uuid].userCompetence.is_verified
      '
      class='verified-icon'
      matTooltip='Навык прошел ревью'
      matTooltipClass='verified-tooltip'
    >
      done_outline
    </mat-icon>
    <span> &rarr; </span>
    <span
      fxFlex
      class='result-text'
      [class.gray-background]='!isNodeUneven'
      [class.white-background]='isNodeUneven'
    >{{
      thingCurrentDesiredLevelMap[node.item.uuid].desiredLevel
        ? thingCurrentDesiredLevelMap[node.item.uuid].desiredLevel.title
        : 'Уровень не назначен'
      }}</span
    >
  </div>
</ng-template>

<ng-template #desireDateNode>
  <div
    fxLayout='row'
    fxLayoutAlign='start center'
    fxFlex='100'
    fxLayoutGap='8px'
  >
    <span class='node-name' fxFlex='50'>
      <a routerLink='/things/{{ node.item.uuid }}'>
        {{ node.item.name }}
      </a>
    </span>
    <div fxFlex>
      <div fxLayoutAlign='end center'>
        <span
          class='result-text'
          [class.gray-background]='!isNodeUneven'
          [class.white-background]='isNodeUneven'
        >{{
          thingDesireMap[node.item.uuid].desire
            ? thingDesireMap[node.item.uuid].date
            : ''
          }}
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #desireWithUserNode>
  <div fxFlex='100' fxLayout='column'>
    <div fxLayout='row' fxLayoutAlign='start center' class='node-margin'>
      <span class='node-name' fxFlex='95'>
        <a routerLink='/things/{{ node.item.uuid }}'>
          {{ node.item.name }}
        </a>
      </span>
      <button
        fxFlex='48px'
        (click)='node.item.isExpanded = !node.item.isExpanded'
        mat-icon-button
      >
        <mat-icon>{{
          node.item.isExpanded ? 'expand_less' : 'expand_more'
          }}</mat-icon>
      </button>
    </div>
    <div fxLayout='column' *ngIf='node.item.isExpanded'>
      <div
        fxFlex
        *ngFor='let desire of thingDesireWithUsersMap[node.item.uuid]'
        class='resolution-content-container'
        [class.gray-background]='!isNodeUneven'
        [class.white-background]='isNodeUneven'
      >
        <div
          fxLayout='row'
          fxLayoutAlign='start center'
          fxFlex='100'
          fxLayoutGap='8px'
        >
          <span class='result-text' fxFlex='50'>{{ desire.user }}</span>
          <div fxFlex>
            <div fxLayoutAlign='end center'>
              <span
                class='result-text'
                [class.gray-background]='isNodeUneven'
                [class.white-background]='!isNodeUneven'
              >{{ desire.date }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #userCompetenceNode>
  <div fxFlex='100' fxLayout='column' (click)='redirectToThing(node.item.uuid)' [style.cursor]='"pointer"'>
    <div fxLayout='row' fxLayoutAlign='start center' class='node-margin'>
      <span class='node-name' fxFlex='80'>
        {{ node.item.name }}
      </span>
      <span fxFlex='20'>{{ getUserCompetenceThingLevel(node.item.uuid).title }}</span>
    </div>
  </div>
</ng-template>
