import { Component, Input, OnInit } from '@angular/core';
import { AsyncList } from '@rest/AsyncList';
import { Speciality } from '@models/specialities/speciality';
import { Thing } from '@models/ontology/thing';
import { Domain } from '@models/ontology/domain';
import Tree, { TreeNode } from 'src/app/utils/tree';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { ThingHttpService } from '@services/http/ThingHttpService';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { MatDialog } from '@angular/material/dialog';
import { AddSpecialityCompetenceComponent } from '@components/common/add-speciality-competence/add-speciality-competence.component';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { AsyncDetail } from '@rest/AsyncDetail';
import { SpecialityCompetenceClaim } from '@models/specialities/speciality-competence-claim';
import { SpecialityCompetenceClaimHttpService } from '@services/http/SpecialityCompetenceClaimHttpService';
import { SpecialityGrade } from '@models/specialities/speciality-grade';
import { ThingLevel } from '@models/ontology/thing-level';

interface DomainTreeModel extends Domain {
  isThing: boolean;
}

interface ThingTreeModel extends Thing {
  isThing: boolean;
}

type TreeModel = DomainTreeModel | ThingTreeModel;

interface LevelsMap {
  [thingUuid: string]: {
    [gradeOrderNumber: number]: {
      level: string;
      claim: string;
    };
  };
}

export interface AngularDefaultTreeNode {
  expandable: boolean;
  name: string;
  level: number;
  levels: any[];
  isThing: boolean;
  uuid: string;
  children: TreeNode<TreeModel>[];
}

@Component({
  selector: 'app-speciality-edit-competencies',
  templateUrl: './speciality-edit-competencies.component.html',
  styleUrls: ['./speciality-edit-competencies.component.css'],
})
export class SpecialityEditCompetenciesComponent implements OnInit {
  @Input() specialityUuid;

  form: UntypedFormGroup;
  public speciality: AsyncDetail<Speciality>;
  public specialityCompetenceClaims: AsyncList<SpecialityCompetenceClaim>;
  private _things: AsyncList<Thing>;
  private _domains: AsyncList<Domain>;
  private _domainTree: Tree<Domain>;
  private levelsMap: LevelsMap = {};

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _specialityHttpService: SpecialityHttpService,
    private _domainHttpService: DomainHttpService,
    private _specialityCompetenceClaimHttpService: SpecialityCompetenceClaimHttpService,
    private _thingHttpService: ThingHttpService,
    private _router: Router,
    private _alertService: AlertService,
    private _dialog: MatDialog
  ) {
    this._things = new AsyncList<Thing>(this._thingHttpService);
    this._domains = new AsyncList<Domain>(this._domainHttpService);
    this._domains.load().subscribe(() => {
      this._domainTree = new Tree<Domain>(
        this._domains.state.items,
        'parent_domain'
      );
    });
  }

  ngOnInit(): void {
    this.speciality = new AsyncDetail(
      this.specialityUuid,
      this._specialityHttpService
    );

    this.specialityCompetenceClaims = new AsyncList<SpecialityCompetenceClaim>(
      this._specialityCompetenceClaimHttpService
    );

    this.specialityCompetenceClaims.setRequestParams({
      params: {
        speciality: this.specialityUuid,
        expand: 'thing.levels',
      },
    });

    this.speciality.requestParams = {
      params: {
        expand: 'related_grades',
      },
    };

    this.speciality.load().subscribe(() => {
      this.specialityCompetenceClaims.load().subscribe((response) => {
        this._buildLevelsMap();
        this._renderCompetenciesTree();
      });
    });
  }

  get competenciesTree(): Tree<TreeModel> {
    const things = this.specialityCompetenceClaims.state.items.map(
      (competenceClaim) => {
        return competenceClaim.thing;
      }
    );

    const competenciesTreeMap = things.reduce(
      (treeMap: { [uuid: string]: TreeModel }, thing: Thing) => {
        const parentDomainUuid: any = thing.domain;
        const withThing = {
          ...treeMap,
          [thing.uuid]: {
            ...thing,
            parent_domain: thing.domain,
          },
        };

        const domainNode = this._domainTree.getNode(parentDomainUuid);
        const ancestors = this._domainTree.getParents(domainNode);

        const withDomains = [domainNode, ...ancestors].reduce(
          (thingTreeMap, domainNode) => {
            const domain = domainNode.item;
            return {
              ...thingTreeMap,
              [domain.uuid]: domain,
            };
          },
          withThing
        );
        return withDomains;
      },
      {}
    );

    return new Tree<TreeModel>(
      Object.values(competenciesTreeMap),
      'parent_domain'
    );
  }

  removeCompetence(uuid: string): void {
    this._specialityCompetenceClaimHttpService
      .onSpecialityThingDelete({
        speciality: this.specialityUuid,
        thing: uuid,
      })
      .subscribe(() => {
        this.specialityCompetenceClaims.load().subscribe(() => {
          this._renderCompetenciesTree();
        });
      });
  }

  addCompetence(thing: Thing): void {
    this._specialityCompetenceClaimHttpService
      .addClaimsWithGrades([
        {
          speciality: this.specialityUuid,
          thing: thing.uuid,
        },
      ])
      .subscribe(() => {
        this.specialityCompetenceClaims.load().subscribe(() => {
          this._buildLevelsMap();
          this._renderCompetenciesTree();
        });
      });
  }

  get grades(): SpecialityGrade[] {
    if (this.speciality.state.isLoaded) {
      return this.speciality.state.item.related_grades;
    }

    return [];
  }

  private _buildLevelsMap(): void {
    const gradesMap = this.speciality.state.item.related_grades.reduce(
      (result, grade) => {
        return {
          ...result,
          [grade.uuid]: grade,
        };
      },
      {}
    );

    this.specialityCompetenceClaims.state.items.forEach((competenceClaim) => {
      if (!this.levelsMap[competenceClaim.thing.uuid]) {
        this.levelsMap[competenceClaim.thing.uuid] = {};
      }
      const level: any = competenceClaim.thing_level;
      const gradeOrderNumber = gradesMap[competenceClaim.grade].order_number;
      this.levelsMap[competenceClaim.thing.uuid][gradeOrderNumber] = {
        level: level,
        claim: competenceClaim.uuid,
      };
    });
  }

  showAddCompetenceDialog(): void {
    this._dialog.open(AddSpecialityCompetenceComponent, {
      data: {
        things: this._things,
        onSelect: (thing: Thing) => {
          this.addCompetence(thing);
        },
        excluded: [],
      },
    });
  }

  public getGradeValueFabric =
    (thingUuid: string) =>
    (gradeOrderNumber: number): string => {
      if (this.levelsMap[thingUuid][gradeOrderNumber]) {
        return this.levelsMap[thingUuid][gradeOrderNumber].level;
      }

      return null;
    };

  setGradeLevel =
    (thingUuid: string) =>
    (gardeOrderNumber: number, levelUuid: string): void => {
      const claim = this.levelsMap[thingUuid][gardeOrderNumber].claim;
      const nextLevel: any = levelUuid;
      this.specialityCompetenceClaims.update(claim, { thing_level: nextLevel });
    };

  // tree node methods
  private _renderCompetenciesTree(): void {
    this.dataSource.data = this.competenciesTree.getRoot().children;
    this.treeControl.expandAll();
  }

  private _transformer = (node: TreeNode<TreeModel>, level: number) => {
    const itemData: any = node.item;
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.item.name,
      uuid: node.item.uuid,
      level: level,
      levels: itemData.levels,
      children: node.children,
    };
  };

  // angular tree methods
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  hasChild = (_: number, node: AngularDefaultTreeNode): boolean => {
    return node.expandable;
  };

  treeControl = new FlatTreeControl<AngularDefaultTreeNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
}
