<h3>Уровни</h3>
<div
  class="speciality-grades"
  [ngClass]="{
    'is-processing': specialityGrades.state.isProcessing
  }"
>
  <div class="processing-wrapper">
    <app-with-processing [processing]="specialityGrades.state.isProcessing">
    </app-with-processing>
  </div>

  <div
    class="grade-item"
    *ngFor="let grade of specialityGrades.state.items; let i = index"
  >
    <div fxLayout="row">
      <div fxFlex="35rem" fxLayoutAlign="start center">
        <mat-form-field [style.width.rem]="30">
          <input
            matInput
            placeholder="Название уровня"
            [ngModel]="grade.name"
            (ngModelChange)="onChange($event, grade.uuid)"
            type="text"
            required
          />
        </mat-form-field>
      </div>
      <div fxFlex fxLayoutAlign="end center">
        <div>
          <button mat-icon-button (click)="removeGrade(grade.uuid)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <button mat-raised-button (click)="addGrade()">Добавить уровень</button>
</div>
