import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { Thing } from '@models/ontology/thing';
import { HttpClient } from '@angular/common/http';
import { ThingLevel } from '@models/ontology/thing-level';

@Injectable({
  providedIn: 'root',
})
export class ThingLevelHttpService extends ViewSetHttpService<ThingLevel> {
  constructor(_http: HttpClient) {
    super(_http);
  }

  public viewSetUrl = 'ontology/thinglevel';
}
