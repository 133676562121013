<div *ngFor="let team of unverifiedTeams">
  <div class="app-admin-teams-dashboard-team-listitem-container">
    <div fxLayout="row">
      <div fxFlex="35rem">
        <div class="app-admin-teams-dashboard-listitem-name">
          <a
            class="app-admin-team-item"
            routerLink="/admin/teams/{{ team.uuid }}"
          >
            {{ team.name }}
          </a>
        </div>
        <div class="app-admin-teams-dashboard-team-listitem-parent">
          <div *ngIf="!team.parent">Команда верхнего уровня иерархии</div>
          <div *ngIf="team.parent">
            Родительская команда: {{ team.parent.name }}
          </div>
        </div>
      </div>
      <div fxFlex fxLayoutAlign="start center">
        {{ getCreatedByText(team) }}
      </div>
      <div class="team-button-container" fxFlex fxLayoutAlign="end center">
        <button mat-raised-button (click)="verifyTeam(team.uuid)">
          Подтвердить
        </button>
      </div>
    </div>
  </div>
</div>
