<div class="dialog-container">
  <div class="title">Добавить специальность из библиотеки</div>
  <form [formGroup]="form">
    <mat-form-field class="search-field">
      <input
        matInput
        placeholder="Поиск по специальностям"
        type="text"
        (keyup)="search()"
        formControlName="search"
        autocomplete="off"
      />
    </mat-form-field>
    <div class="specialities-container">
      <div
        *ngFor="let speciality of getFilteredSpecialities()"
        class="speciality-container"
        fxLayout="row"
        fxLayoutAlign="start center"
        (click)="selectSpeciality(speciality)"
      >
        <span fxFlex="90" class="speciality-name">{{ speciality.name }}</span>
        <div fxFlex="30" *ngIf="isParentSpeciality(speciality.uuid)">
          <span class="parent-team-flag">В родительской команде</span>
        </div>
        <mat-icon fxFlex="3rem" class="speciality-select-icon"
          >add_circle_outline</mat-icon
        >
      </div>
    </div>
    <div fxLayout="row">
      <div fxFlex fxLayoutAlign="start center">
        <span>Не нашли нужную специальность?</span>
      </div>
      <div
        fxFlex
        fxLayoutAlign="end center"
        (click)="openCreateDialog()"
        class="create-dialog-button-container"
      >
        <button mat-icon-button><mat-icon>add_circle_outline</mat-icon></button>
        <span>Предложите свою</span>
      </div>
    </div>
    <ng-select
      required
      placeholder="Выбранные специальности"
      [multiple]="true"
      [items]="[]"
      formControlName="selectedSpecialities"
      bindLabel="name"
    ></ng-select>
  </form>

  <div mat-dialog-actions fxLayoutAlign="end center">
    <button
      class="dialog-reject-button"
      [mat-dialog-close]="true"
      mat-raised-button
    >
      Отменить
    </button>
    <button
      class="dialog-accept-button"
      [mat-dialog-close]="true"
      [disabled]="form.invalid"
      mat-raised-button
      (click)="close()"
    >
      Добавить
    </button>
  </div>
</div>
