<mat-dialog-content>
  <div class='app-admin-teams-create-container'>
    <div fxLayout='row'>
      <div>
        <div class='app-admin-teams-create-main-container'>
          <div class='title'>
            Новая команда
          </div>
          <div class='header-hint'>
            Укажите данные команды, отдела, подрядчика или сообщества.
            <br />
            Информацию можно будет отредактировать позднее.
          </div>
          <app-show-on-settings
            [settingName]="'use_team_validation'"
            [showOnActiveSetting]='true'
            [showOnDisabledSetting]='false'
          >
            <p>Ваша команда будет провалидирована администратором</p>
          </app-show-on-settings>
          <br />

          <form fxLayout='column' *ngIf='!data.isOnboarding'>
            <ng-container [ngTemplateOutlet]='teamName'>
            </ng-container>

            <ng-container [ngTemplateOutlet]='teamDesc'>
            </ng-container>


            <div fxLayout='row' fxLayoutGap='10px' [style.width.rem]='30'>
              <mat-form-field fxFlex='40' *ngIf='!data.isTypeHidden'>
                <mat-label>Тип команды</mat-label>
                <mat-select formControlName='teamType'>
                  <mat-option *ngFor='let teamType of teamTypes' [value]='teamType'>
                    {{ teamType }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <ng-container [ngTemplateOutlet]='teamParent'>
              </ng-container>
            </div>
          </form>
          <form *ngIf='data.isOnboarding' fxLayout='column'>
            <div fxLayout='row' fxLayoutGap='16px'>
              <ng-container [ngTemplateOutlet]='teamNameOnboarding'>
              </ng-container>
              <ng-container [ngTemplateOutlet]='teamParentOnboarding'>
              </ng-container>
            </div>
            <ng-container [ngTemplateOutlet]='teamDescOnboarding'>
            </ng-container>
          </form>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<div mat-dialog-actions fxLayoutAlign='end center'>
  <button class='dialog-button' [mat-dialog-close]='true' mat-raised-button>
    Отмена
  </button>
  <button
    class='dialog-button'
    mat-raised-button
    [disabled]='form.invalid'
    (click)='close()'
  >
    Создать
  </button>
</div>

<ng-template #teamName>
  <mat-form-field fxFlex [formGroup]='form'>
    <input
      matInput
      required
      placeholder='Название'
      type='text'
      formControlName='teamName'
    />
  </mat-form-field>
</ng-template>

<ng-template #teamDesc>
  <mat-form-field fxFlex [formGroup]='form'>
    <input
      matInput
      required
      placeholder='Описание'
      type='text'
      formControlName='teamDescription'
    />
  </mat-form-field>
</ng-template>

<ng-template #teamParent>
  <mat-form-field fxFlex [formGroup]='form'>
    <mat-label>Родительская команда</mat-label>
    <mat-select formControlName='teamParent'>
      <mat-option>--</mat-option>
      <mat-option *ngFor='let team of data.teams' [value]='team'>
        {{ team.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #teamNameOnboarding>
  <div fxFlex fxLayout='column' fxLayoutGap='4px'>
    <mat-label fxFlex class='onboarding-field-label'>Название</mat-label>
    <mat-form-field fxFlex [formGroup]='form' appearance='outline'>
      <input
        matInput
        required
        placeholder='Например «Marvel»'
        type='text'
        formControlName='teamName'
      />
    </mat-form-field>
  </div>
</ng-template>

<ng-template #teamDescOnboarding>
  <div fxFlex fxLayout='column' fxLayoutGap='4px'>
    <mat-label fxFlex class='onboarding-field-label'>Описание</mat-label>
    <mat-form-field fxFlex [formGroup]='form' appearance='outline' [style.height.px]='72'>
      <input
        matInput
        required
        placeholder='Например «Команда проекта Ипотеки»'
        type='text'
        formControlName='teamDescription'
      />
    </mat-form-field>
  </div>
</ng-template>

<ng-template #teamParentOnboarding>
  <div fxFlex fxLayout='column' fxLayoutGap='4px'>
    <mat-label fxFlex class='onboarding-field-label'>Родительская группа</mat-label>
    <mat-form-field fxFlex [formGroup]='form' appearance='outline'>
      <mat-select formControlName='teamParent' placeholder='Название родительской'>
        <mat-option>--</mat-option>
        <mat-option *ngFor='let team of data.teams' [value]='team'>
          {{ team.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-template>
