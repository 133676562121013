import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root',
})
export class UserEventsService {
  private logging_prefix = 'UserEventsService';

  constructor(
    private logging_service: LoggingService,
    private api_service: ApiService
  ) {}

  public fetch_by_user_uuid(user_uuid: string): Observable<any> {
    return this.api_service.get(`events/user`, { user: user_uuid }).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.logging_prefix} fetched ${response.count} user events`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.logging_prefix} failed to fetch user events`
          );
        }
      )
    );
  }
}
