import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewSetHttpService } from './rest/ViewSetHttpService';
import { SpecialityValidationComment } from '@models/specialities/speciality-validation-comment';

@Injectable({
  providedIn: 'root',
})
export class SpecialityValidationCommentHttpService extends ViewSetHttpService<SpecialityValidationComment> {
  constructor(_http: HttpClient) {
    super(_http);
  }
  public viewSetUrl = 'specialities/validation_comment';
}
