import { Component, OnInit } from '@angular/core';
import { Company } from '@models/projects/company';
import { AsyncList } from '@rest/AsyncList';
import { CompanyHttpService } from '@services/http/CompanyHttpService';
import { MatDialog } from '@angular/material/dialog';
import { CreateCompanyDialogComponent } from './create-company-dialog/create-company-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css'],
})
export class CompaniesComponent implements OnInit {
  companies: AsyncList<Company>;

  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private _companyHttpService: CompanyHttpService
  ) {}

  ngOnInit(): void {
    this.companies = new AsyncList<Company>(this._companyHttpService);
    this.companies.load();
  }

  onCompanyAdd(): void {
    this._dialog.open(CreateCompanyDialogComponent, {});
  }

  onCompanyClick(company: Company): void {
    this._router.navigate([`companies/${company.uuid}`]);
  }
}
