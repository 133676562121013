import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-onboarding-teams-dialog',
  templateUrl: './onboarding-teams-dialog.component.html',
  styleUrls: ['./onboarding-teams-dialog.component.css'],
})
export class OnboardingTeamsDialogComponent {
  constructor(public dialogRef: MatDialogRef<OnboardingTeamsDialogComponent>) {}

  close(): void {
    this.dialogRef.close();
  }
}
