import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Domain } from '@models/ontology/domain';
import { Thing } from '@models/ontology/thing';
import { getSpecialityDomains } from '../../../utils/template-filters/get-speciality-domains';
import Tree, { TreeNode } from '../../../utils/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  Level,
  ThingGradeLevelsMap,
} from '../../../modules/teams/components/team-main/team-specialities/team-speciality-detail/team-speciality-detail.component';
import { SpecialityCompetenceClaimHttpService } from '@services/http/SpecialityCompetenceClaimHttpService';
import { AsyncList } from '@rest/AsyncList';
import { SpecialityCompetenceClaim } from '@models/specialities/speciality-competence-claim';
import { AssessmentResponseThingMap } from '../../../modules/review/components/new-review-assessment-detail/new-review-assessment-detail.component';
import { AssessmentResponseHttpService } from '@services/http/AssessmentResponseHttpService';
import { AlertService } from '../../../services/ui/ui-alert.service';
import { CompetenceAssertion } from '@models/competencies/competence-assertion';
import { ThingLevel } from '@models/ontology/thing-level';
import { ResolutionResponseThingMap } from '../../../modules/review/components/new-review-resolution-detail/new-review-resolution-detail.component';
import { ResolutionResponseHttpService } from '@services/http/ResolutionResponseHttpService';
import { Competence } from '@models/competencies/competence';
import { CompetenceHttpService } from '@services/http/CompetenceHttpService';
import { CompetenceAssertionHttpService } from '@services/http/CompetenceAssertionHttpService';
import { AuthService } from '../../../services/auth/auth.service';
import { SpecialityDomainClaim } from '@models/specialities/speciality-domain-claim';
import { SpecialityDomainClaimHttpService } from '@services/http/SpecialityDomainClaimHttpService';
import { combineLatest } from 'rxjs';
import { tree } from 'd3';
import { ThingUserCompetenceMap } from '../../../modules/admin/components/users/user-tabs/admin-user-tab-competencies/admin-user-competencies-tab.component';

export interface DomainTreeModel extends Domain {
  isThing: boolean;
  isSelected: boolean;
  isAnswered: boolean;
  isExpanded: boolean;
  isConflicted: boolean;
  hasSiblings: boolean;
  reorderDisabled: boolean;
}

export interface ThingTreeModel extends Thing {
  isThing: boolean;
  isSelected: boolean;
  isAnswered: boolean;
  isExpanded: boolean;
  isConflicted: boolean;
  parent_domain: string;
  hasSiblings: boolean;
  reorderDisabled: boolean;
}

type TreeModel = DomainTreeModel | ThingTreeModel;

export interface AngularDefaultTreeNode {
  parent_domain: string;
  expandable: boolean;
  isThing: boolean;
  isSelected: boolean;
  name: string;
  level: number;
  uuid: string;
  children: TreeNode<TreeModel>[];
}

export interface ThingCurrentDesiredLevelMap {
  [thingUuid: string]: {
    currentLevel: ThingLevel;
    desiredLevel: ThingLevel;
    userCompetence: Competence | null;
    userLevel: ThingLevel | null;
    levels: Level[];
  };
}

export interface ThingDesireMap {
  [thingUuid: string]: {
    desire: string;
    date: string;
  };
}

export interface ThingDesireWithUsersMap {
  [thingUuid: string]: {
    user: string;
    date: string;
  }[];
}

export type ReviewMode =
  | 'self-assessment'
  | 'assessment'
  | 'resolution'
  | 'result';

@Component({
  selector: 'app-domain-thing-tree',
  templateUrl: './domain-thing-tree.component.html',
  styleUrls: ['./domain-thing-tree.component.css'],
})
export class DomainThingTreeComponent implements OnInit {
  @Input() userUuid: string; // if permission control needed
  @Input() expandNode: string;

  // true if all nodes should be fully expanded
  @Input() expandAllNodes = false;

  // true if first node should be fully expanded
  @Input() expandFirstNode = false;

  // user competencies
  @Input() thingUserCompetenceMap: ThingUserCompetenceMap;

  // selectable nodes
  @Input() selectMode = false;
  @Input() selectAllNodes = false;
  @Input() selectedClaims: string[] = [];
  @Output() selectedClaimsChange: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  // thing nodes with thing levels
  @Input() libraryEdit: boolean; // if tree is loaded in library/specialities/:uuid/edit
  @Input() specialityUuid: string;
  @Input() specialityCompetenceClaims: AsyncList<SpecialityCompetenceClaim>;
  @Input() thingGradeLevelsMap: ThingGradeLevelsMap = null;

  // thing nodes with current and desired thing level
  @Input() thingCurrentDesiredLevelMap: ThingCurrentDesiredLevelMap = null;

  // thing nodes with desire and its date
  @Input() thingDesireMap: ThingDesireMap = null;

  // thing nodes with desire, its users and dates
  @Input() thingDesireWithUsersMap: ThingDesireWithUsersMap = null;

  //
  @Input() profileLevels = false;
  @Input() reviewMode: ReviewMode = null;
  @Input() assessmentResponseThingMap: AssessmentResponseThingMap = null;
  @Input() resolutionResponseThingMap: ResolutionResponseThingMap = null;
  @Output() assessmentChange: EventEmitter<any> = new EventEmitter();
  @Output() resolutionChange: EventEmitter<any> = new EventEmitter();

  // base
  @Input() domains: Domain[];
  @Input() things: Thing[];
  @Input() specialityDomainsClaims: SpecialityDomainClaim[];
  @Input() reorderHidden = false;
  @Input() reorderDisabled: boolean;
  @Input() isEditable = true;

  specialityDomains: Domain[];

  tree: Tree<TreeModel>;

  isTreeReady = false;

  isTreeEmpty = true;

  constructor(
    private _authService: AuthService,
    private _alertService: AlertService,
    private _specialityDomainClaimHttpService: SpecialityDomainClaimHttpService,
    private _specialityCompetenceClaimHttpService: SpecialityCompetenceClaimHttpService,
    private _assessmentResponseHttpService: AssessmentResponseHttpService,
    private _resolutionResponseHttpService: ResolutionResponseHttpService,
    private _competenceHttpService: CompetenceHttpService,
    private _competenceAssertionHttpService: CompetenceAssertionHttpService
  ) {}

  ngOnInit(): void {
    if (this.selectAllNodes) {
      this.selectedClaims = this.things.map((thing) => thing.uuid);
      this.selectedClaimsChange.emit(this.selectedClaims);
    }
    this._buildTree();
  }

  private _getNodeSelectedState(uuid: string): boolean {
    if (this.selectedClaims) {
      return !!this.selectedClaims.find(
        (selectedClaim) => selectedClaim === uuid
      );
    }
    return false;
  }

  private _getNodeAnsweredState(uuid: string): boolean {
    if (
      this.reviewMode === 'self-assessment' ||
      this.reviewMode === 'assessment'
    ) {
      return this.assessmentResponseThingMap[uuid][0].status !== 'CREATED';
    }
    if (this.reviewMode === 'resolution') {
      return !!this.resolutionResponseThingMap[uuid].resolved_thing_level;
    }
    return false;
  }

  private _getNodeConflictedState(uuid: string): boolean {
    let conflict = false,
      prevCompetenceAssertion: CompetenceAssertion;
    if (this.reviewMode === 'resolution') {
      if (this.resolutionResponseThingMap[uuid].resolved_thing_level) {
        return conflict;
      }
      this.assessmentResponseThingMap[uuid].forEach((assessmentResponse) => {
        if (
          prevCompetenceAssertion &&
          assessmentResponse.competence_assertion
        ) {
          const competenceAssertion =
            assessmentResponse.competence_assertion as CompetenceAssertion;
          conflict =
            (prevCompetenceAssertion.thing_level as ThingLevel).order_number !==
            (competenceAssertion.thing_level as ThingLevel).order_number;

          if (!conflict) {
            prevCompetenceAssertion = competenceAssertion;
          } else {
            return;
          }
        } else {
          prevCompetenceAssertion =
            assessmentResponse.competence_assertion as CompetenceAssertion;
        }
      });
    }
    return conflict;
  }

  private _checkNodeSiblings(domain: Domain): boolean {
    return (
      this.specialityDomains.filter(
        (d) => d.parent_domain === domain.parent_domain
      ).length > 1
    );
  }

  private _prepareThingTreeIntegration(thing: Thing) {
    return {
      ...thing,
      parent_domain: thing.domain,
      isThing: true,
      isSelected: this._getNodeSelectedState(thing.uuid),
      isAnswered: this._getNodeAnsweredState(thing.uuid),
      isConflicted: this._getNodeConflictedState(thing.uuid),
      hasSiblings: null,
      isExpanded: false,
      reorderDisabled: this.reorderDisabled,
    };
  }

  private _prepareDomainTreeIntegration(domain: Domain) {
    return {
      ...domain,
      isThing: false,
      isSelected: false,
      isAnswered: false,
      isConflicted: false,
      hasSiblings: this.specialityDomainsClaims
        ? !this.reorderHidden
          ? this._checkNodeSiblings(domain)
          : null
        : null,
      isExpanded: false,
      reorderDisabled: this.reorderDisabled,
    };
  }

  private _buildTree(): void {
    this.isTreeReady = false;

    if (this.specialityDomainsClaims?.length) {
      this.specialityDomains = this.specialityDomainsClaims
        .sort((a, b) => a.order_number - b.order_number)
        .map((domainClaim) => domainClaim.domain);

      // checking if all domains have matching parent domain
      this.specialityDomains = this.specialityDomains.filter((domain) => {
        if (!domain.parent_domain) return true;
        if (
          this.specialityDomains.find(
            (parentDomain) => parentDomain.uuid === domain.parent_domain
          )
        ) {
          return true;
        } else {
          console.log(
            'отсутствует parent_domain на который ссылается этот domain',
            domain
          );
          return false;
        }
      });

      // checking if all things have matching parent domain
      this.things = this.things.filter((thing) => {
        if (
          this.specialityDomains.find((domain) => domain.uuid === thing.domain)
        ) {
          return true;
        } else {
          console.log(
            'отсутствует domain_claim на который ссылается этот thing',
            thing
          );
          return false;
        }
      });
    } else if (this.domains) {
      this.specialityDomains = getSpecialityDomains(this.domains, this.things);
    } else {
      this.isTreeEmpty = true;
      this.isTreeReady = true;
      return;
    }

    this.isTreeEmpty = false;

    const specialityTreeThings = this.things
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((thing) => this._prepareThingTreeIntegration(thing));
    const specialityTreeDomains = this.specialityDomains.map((domain) =>
      this._prepareDomainTreeIntegration(domain)
    );

    this.tree = new Tree<TreeModel>(
      [...specialityTreeDomains, ...specialityTreeThings],
      'parent_domain'
    );

    this.dataSource.data = this.tree.getRoot().children;
    if (this.selectedClaims.length > 0) {
      this.specialityDomains.forEach((domain) => {
        this.toggleParentNodeParam(
          this.tree.getNode(domain.uuid),
          'isSelected'
        );
      });
    }

    if (this.assessmentResponseThingMap) {
      switch (this.reviewMode) {
        case 'self-assessment':
        case 'assessment':
          this.specialityDomains.forEach((domain) => {
            this.toggleParentNodeParam(
              this.tree.getNode(domain.uuid),
              'isAnswered'
            );
          });
          break;
        case 'resolution':
          this.specialityDomains.forEach((domain) => {
            this.toggleParentNodeParam(
              this.tree.getNode(domain.uuid),
              'isAnswered'
            );
            this.toggleParentNodeParam(
              this.tree.getNode(domain.uuid),
              'isConflicted'
            );
          });
      }
      this.expandNextUnAnswered();
    }
    if (this.expandNode) {
      this.expandParentNodes(this.tree.getNode(this.expandNode));
    }
    this.isTreeReady = true;
  }

  deleteThing(thingUuid: string, domainsToDelete: string[]): void {
    this._specialityCompetenceClaimHttpService
      .onSpecialityThingDelete({
        speciality: this.specialityUuid,
        thing: thingUuid,
      })
      .subscribe(() => {
        delete this.thingGradeLevelsMap[thingUuid];
        this.things = this.things.filter((thing) => thing.uuid !== thingUuid);

        if (domainsToDelete) {
          this.specialityDomainsClaims = this.specialityDomainsClaims.filter(
            (domainClaim) => !domainsToDelete.includes(domainClaim.domain.uuid)
          );

          this._buildTree();
        } else {
          this.tree.removeNode(thingUuid);
        }
      });
  }

  deleteDomain(domainUuid: string): void {
    const lastChild = this.tree.getNode(domainUuid).children[0];
    this.deleteLastChildThing(lastChild, [domainUuid]);
  }

  deleteLastChildThing(
    node: TreeNode<TreeModel>,
    domainsToDelete: string[]
  ): void {
    if (node.item.isThing) {
      this.deleteThing(node.item.uuid, domainsToDelete);
    } else {
      domainsToDelete.push(node.item.uuid);
      this.deleteLastChildThing(node.children[0], domainsToDelete);
    }
  }

  onChangeDomainOrder(domain: TreeNode<TreeModel>, mode: string): void {
    const parentDomain = this.tree.getNode(domain.item.parent_domain),
      siblings = parentDomain
        ? parentDomain.children
        : this.tree.getRoot().children,
      domainIndex = siblings.indexOf(domain);
    let siblingIndex;

    if (domainIndex !== 0 && mode === 'up') {
      siblingIndex = domainIndex - 1;
    }

    if (domainIndex !== siblings.length - 1 && mode === 'down') {
      siblingIndex = domainIndex + 1;
    }

    if (typeof siblingIndex !== 'undefined') {
      this.treeControl.dataNodes.forEach((node) => {
        const treeNode = this.tree.getNode(node.uuid).item;
        if (!treeNode.isThing) {
          treeNode.reorderDisabled = true;
        }
      });

      let domainClaim, siblingDomainClaim;
      this.specialityDomainsClaims = this.specialityDomainsClaims.filter(
        (claim) => {
          if (claim.domain.uuid === domain.item.uuid) {
            domainClaim = claim;
            return false;
          }
          if (claim.domain.uuid === siblings[siblingIndex].item.uuid) {
            siblingDomainClaim = claim;
            return false;
          }
          return true;
        }
      );
      this._specialityDomainClaimHttpService
        .reorder({
          moved_uuid: domainClaim.uuid,
          target_uuid: siblingDomainClaim.uuid,
        })
        .subscribe((response) => {
          domainClaim.order_number = response[0].order_number;
          siblingDomainClaim.order_number = response[1].order_number;

          this.specialityDomainsClaims.push(
            ...[domainClaim, siblingDomainClaim]
          );
          this._buildTree();
        });
    }
  }

  onToggleKeyCompetence(thingUuid: string, isKey: boolean): void {
    const speciality = this.specialityCompetenceClaims.state.items.find(
      (competenceClaim) => competenceClaim.thing.uuid === thingUuid
    ).speciality;

    this._specialityCompetenceClaimHttpService
      .updateClaimsIsKey({
        speciality: speciality as string,
        thing: thingUuid,
        is_key: isKey,
      })
      .subscribe(() => {});
  }

  onUserCompetenceLevelChange(
    competenceUuid: string,
    levelUuid: string,
    thingUuid: string
  ): void {
    this._competenceHttpService
      .update(competenceUuid, {
        thing_level: levelUuid,
        is_verified: false,
      })
      .subscribe(
        (response) => {
          this.thingCurrentDesiredLevelMap[thingUuid].userCompetence = response;
          this.thingCurrentDesiredLevelMap[thingUuid].userLevel =
            response.thing_level;
        },
        () => {
          this._alertService.error('Не удалось сохранить выбранный уровень');
        }
      );
  }

  onGradeLevelChange(
    thingUuid: string,
    gradeUuid: string,
    nextThingLevelUuid: string
  ): void {
    const competenceClaim = this.specialityCompetenceClaims.state.items.find(
      (specialityCompetenceClaim) =>
        specialityCompetenceClaim.thing.uuid === thingUuid &&
        specialityCompetenceClaim.grade.uuid === gradeUuid
    );
    this.specialityCompetenceClaims.update(competenceClaim.uuid, {
      thing_level: nextThingLevelUuid,
    });
  }

  checkAllChildrenParam(node: TreeNode<TreeModel>, paramName: string): boolean {
    let paramCount = 0;
    node.children.forEach((childNode) => {
      if (childNode.item[paramName]) paramCount += 1;
    });
    return paramCount === node.children.length;
  }

  toggleParentNodeParam(node: TreeNode<TreeModel>, paramName: string): void {
    if (node) {
      node.item[paramName] = this.checkAllChildrenParam(node, paramName);
      this.toggleParentNodeParam(
        this.tree.getNode(node.item.parent_domain),
        paramName
      );
    }
  }

  collapseParentNodes(node: TreeNode<TreeModel>): void {
    if (node.item.parent_domain) {
      const parentNode = this.tree.getNode(node.item.parent_domain);
      parentNode.item.isExpanded = false;
      this.collapseParentNodes(parentNode);
    }
  }

  expandParentNodes(node: TreeNode<TreeModel>): void {
    if (node.item.parent_domain) {
      const parentNode = this.tree.getNode(node.item.parent_domain);
      parentNode.item.isExpanded = true;
      this.expandParentNodes(parentNode);
    }
  }

  expandNextUnAnswered(): void {
    const unAnsweredNode = this.tree.getNode(
      this.treeControl.dataNodes.find((angularNode) => {
        const node = this.tree.getNode(angularNode.uuid);
        return !!(node.item.isThing && !node.item.isAnswered);
      })?.uuid
    );
    if (unAnsweredNode) {
      unAnsweredNode.item.isExpanded = true;
      this.expandParentNodes(unAnsweredNode);
    }
  }

  onDomainSelectionChange(domain: TreeNode<TreeModel>): void {
    this.toggleParentNodeParam(
      this.tree.getNode(domain.item.parent_domain),
      'isSelected'
    );
    this.toggleChildrenSelection(domain);
  }

  toggleChildrenSelection(parentNode: TreeNode<TreeModel>): void {
    parentNode.children.forEach((childNode) => {
      childNode.item.isSelected = parentNode.item.isSelected;
      if (!childNode.item.isThing) {
        this.toggleChildrenSelection(childNode);
      } else {
        this.selectThing(childNode, !parentNode.item.isSelected);
      }
    });
  }

  selectThing(selectedThing: TreeNode<TreeModel>, remove: boolean): void {
    if (remove) {
      this.selectedClaims = this.selectedClaims.filter(
        (claim) => claim !== selectedThing.item.uuid
      );
    } else {
      this.selectedClaims.push(selectedThing.item.uuid);
    }
    this.selectedClaimsChange.emit(this.selectedClaims);
  }

  onSelectedThingsChange(
    selectedThing: TreeNode<TreeModel>,
    remove: boolean
  ): void {
    this.toggleParentNodeParam(
      this.tree.getNode(selectedThing.item.parent_domain),
      'isSelected'
    );
    this.selectThing(selectedThing, remove);
  }

  onAcceptAssessmentResponse(
    thingNode: TreeNode<TreeModel>,
    assessmentResponseUuid: string,
    selectedLevelUuid: string,
    comment: string
  ): void {
    this._assessmentResponseHttpService
      .accept({
        assessmentResponse: assessmentResponseUuid,
        level: selectedLevelUuid,
        comment: comment,
      })
      .subscribe(
        (response) => {
          thingNode.item.isAnswered = true;
          thingNode.item.isExpanded = false;
          this.assessmentResponseThingMap[thingNode.item.uuid] = [response];
          this.toggleParentNodeParam(
            this.tree.getNode(thingNode.item.parent_domain),
            'isAnswered'
          );
          this.collapseParentNodes(thingNode);
          this.expandNextUnAnswered();
          this.assessmentChange.emit();
        },
        () => {
          this._alertService.error('Не удалось обновить уровень');
        }
      );
  }

  onAcceptResolutionResponse(
    thingNode: TreeNode<TreeModel>,
    resolutionResponseUuid: string,
    selectedLevelUuid: string
  ): void {
    this._resolutionResponseHttpService
      .accept({
        resolution: resolutionResponseUuid,
        level: selectedLevelUuid,
      })
      .subscribe(() => {
        thingNode.item.isAnswered = true;
        thingNode.item.isConflicted = false;
        thingNode.item.isExpanded = false;
        this.toggleParentNodeParam(
          this.tree.getNode(thingNode.item.parent_domain),
          'isAnswered'
        );
        this.toggleParentNodeParam(
          this.tree.getNode(thingNode.item.parent_domain),
          'isConflicted'
        );
        this.collapseParentNodes(thingNode);
        this.expandNextUnAnswered();
        this.resolutionChange.emit();
      });
  }

  private _transformer = (node: TreeNode<TreeModel>, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.item.name,
      uuid: node.item.uuid,
      level: level,
      isThing: node.item.isThing,
      isSelected: node.item.isSelected,
      children: node.children,
    };
  };

  // angular tree methods
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  treeControl = new FlatTreeControl<AngularDefaultTreeNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
}
