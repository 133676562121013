import { AfterViewInit, Component, Input } from '@angular/core';
import { Competence } from '@models/competencies/competence';
import { CompetenceDesire } from '@models/competencies/competence-desire';
import { AsyncList } from '@rest/AsyncList';
import { ThingLevel } from '@models/ontology/thing-level';
import { Thing } from '@models/ontology/thing';
import { AsyncDetail } from '@rest/AsyncDetail';
import { UserHttpService } from '@services/http/UserHttpService';

@Component({
  selector: 'app-thing-detail-graph',
  templateUrl: './thing-detail-graph.component.html',
  styleUrls: ['./thing-detail-graph.component.scss'],
})
export class ThingDetailGraphComponent implements AfterViewInit {
  @Input() competencies: AsyncList<Competence>;
  @Input() competenciesDesire: AsyncList<CompetenceDesire>;
  @Input() thing: AsyncDetail<Thing>;
  @Input() thingLevels: AsyncList<ThingLevel>;

  public keys: string[] = [];
  public dates: string[] = [];
  public data: HistogramData = {};
  public chartLineData: [string, number][] = [];
  public chartHistogramData: ChartHistogramData[] = [];

  constructor(private _userHttpService: UserHttpService) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._buildData();
    }, 0);
  }

  private _buildChartHistogramData(): void {
    const factory: ChartHistogramData[] = [];
    this.keys.forEach((key) => {
      factory.push({
        x: this.data[key].order_number,
        y: this.data[key].users.length,
      });
    });
    this.chartHistogramData = factory;
  }

  private _buildKeys() {
    this.keys = Object.keys(this.data);
  }

  private _buildChartLineData(): void {
    this.competencies.state.items.sort((a, b) => {
      return a.date_created.localeCompare(b.date_created);
    });
    let cumulativeSum = 0;
    this.chartLineData = this.competencies.state.items.map((item) => {
      const thingLevel = this.thingLevels.state.items.find((thingLevel) => {
        return thingLevel.uuid === item.thing_level;
      });
      cumulativeSum = cumulativeSum + thingLevel.order_number;
      return [item.date_created, cumulativeSum];
    });
    if (this.chartLineData.length < 2) {
      // we need two dots to draw the line, so add dummy value
      this.chartLineData.unshift(['2020-01-01T00:00:00', 0]);
    }
    // add last data point for current date
    this.chartLineData.push([new Date().toISOString(), cumulativeSum]);
  }

  private _buildData() {
    const items = this.thingLevels.state.items;
    items.forEach((level) => {
      this.data[level.title] = {
        ...level,
        users: this.competencies.state.items.filter((item) => {
          return item.thing_level === level.uuid;
        }),
      };
    });
    this._buildKeys();
    this._buildChartLineData();
    this._buildChartHistogramData();
  }

  public get deltasSvgConfig(): any {
    return {
      width: 400,
      height: 100,
      margin: {
        top: 30,
        right: 70,
        bottom: 70,
        left: 30,
      },
      colors: {
        positive_bar: '#aaa',
        negative_bar: '#ff7878',
      },
    };
  }
}

interface HistogramData {
  [key: string]: ThingLevel & { users: Competence[] };
}

interface ChartHistogramData {
  x: number;
  y: number;
}
