import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { ProjectTask } from '@models/projects/project-task';

@Injectable({
  providedIn: 'root',
})
export class ProjectTaskHttpService extends ViewSetHttpService<ProjectTask> {
  constructor(_http: HttpClient) {
    super(_http);
  }

  public viewSetUrl = 'projects/task';
}
