<h2 mat-dialog-title>Выбор компетенций</h2>
<mat-dialog-content>
  <div fxLayout="row" fxLayoutGap="3rem">
    <div fxFlex="35rem">
      <div class="help">
        Начните набирать название компетенции в поле ниже для поиска.
        <br />
        Если нужная вам компетенция не находится, то ее следует предварительно
        создать в библиотеке.
      </div>
      <div class="ng-select-wrapper">
        <ng-select
          (search)="search($event.term)"
          [(ngModel)]="selectedThing"
          [items]="options"
          notFoundText="Компетенции не найдены"
          placeholder="Добавить компетенцию"
          bindLabel="name"
        >
        </ng-select>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close (click)="onAccept()">
    Добавить
  </button>
  <button mat-button mat-dialog-close>Отмена</button>
</mat-dialog-actions>
