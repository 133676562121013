import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { TitleService } from 'src/app/services/title.service';
import { UserService } from 'src/app/services/user/user.service';
import { SpecialityService } from 'src/app/services/specialities/speciality.service';
import { SurveyService } from 'src/app/services/survey/survey.service';
import { SpecialityUserRelationService } from 'src/app/services/specialities/speciality-user-relation.service';
import { SurveySpecialityRelationService } from 'src/app/services/survey/survey-speciality-relation.service';
import { SpecialityGradeService } from 'src/app/services/specialities/speciality-grade.service';

import { User } from 'src/app/models/user/user';
import { Speciality } from 'src/app/models/specialities/speciality';
import { Survey } from 'src/app/models/survey/survey';
import { SpecialityUserRelation } from 'src/app/models/specialities/speciality-user-relation';
import { SurveySpecialityRelation } from 'src/app/models/survey/survey-speciality-relation';
import { SpecialityGrade } from 'src/app/models/specialities/speciality-grade';
import { Team } from '@models/teams/team';
import { AsyncList } from '@rest/AsyncList';
import { TeamHttpService } from '@services/http/TeamHttpService';

@Component({
  selector: 'app-speciality-details',
  templateUrl: './speciality-details.component.html',
  styleUrls: ['./speciality-details.component.css'],
})
export class SpecialityDetailsComponent implements OnInit {
  @ViewChild('tooltip_delete_grade') tooltip_delete_grade: ElementRef;

  private speciality_uuid: string;
  public speciality: Speciality;

  teams: AsyncList<Team>;
  teamsOptions: Team[] = [];

  public speciality_user_relations: SpecialityUserRelation[] = [];

  public surveys: Survey[] = [];
  public survey_relations: SurveySpecialityRelation[] = [];
  public selected_relation_selected_survey: Survey;

  public speciality_grades: SpecialityGrade[] = [];
  public speciality_grade_currently_edited: SpecialityGrade;
  public speciality_grade_currently_deleted: SpecialityGrade;

  public user_search_form_control = new UntypedFormControl('', []);
  private user_search_terms = new Subject<string>();
  private user_search$: Observable<{ results: User[] } | null>;
  public user_search_results: User[] = [];

  public user_search_selected_user: User;

  public speciality_edit_form: UntypedFormGroup;

  private uistate = {
    speciality_loaded: false,
    speciality_user_relations_loaded: false,
    speciality_grades_loaded: false,

    is_header_in_edit_mode: false,

    user_add_form_shown: false,
    user_add_user_selected: false,
    button_user_add_disabled: false,
    button_user_delete_disabled: false,
    button_toggle_hidden_disabled: false,

    surveys_loaded: false,
    survey_relations_loaded: false,
    survey_create_relation_form_shown: false,
    survey_create_relation_button_disabled: false,
    survey_delete_relation_button_disabled: false,

    is_create_grade_from_shown: false,
    is_button_grade_create_submit_disabled: false,
    is_button_grade_edit_save_disabled: false,
    is_button_grade_tooltip_delete_disabled: false,
  };

  constructor(
    private form_builder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private title_service: TitleService,
    public user_service: UserService,
    private speciality_service: SpecialityService,
    private speciality_user_relation_service: SpecialityUserRelationService,
    private survey_service: SurveyService,
    private survey_speciality_relation_service: SurveySpecialityRelationService,
    private speciality_grade_service: SpecialityGradeService,
    private _teamHttpService: TeamHttpService
  ) {}

  ngOnInit(): void {
    this.title_service.set_title('Специальность');
    this.speciality_uuid = this.route.snapshot.paramMap.get('speciality_uuid');
    this.logging_service.debug(`${this.constructor.name} init`);

    this.teams = new AsyncList<Team>(this._teamHttpService);
    this._setOptions();

    this.user_search$ = this.user_search_terms.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap((term: string) => this.user_service.search(term))
    );
    this.user_search$.subscribe(
      (response) => (this.user_search_results = response.results)
    );

    this.load_speciality();
    this.create_form();
  }

  public get is_header_in_edit_mode(): boolean {
    return this.uistate.is_header_in_edit_mode;
  }

  public get is_data_loaded(): boolean {
    return (
      this.uistate.speciality_loaded &&
      this.uistate.speciality_user_relations_loaded &&
      this.uistate.surveys_loaded &&
      this.uistate.survey_relations_loaded &&
      this.uistate.speciality_grades_loaded
    );
  }

  public get is_button_user_add_enabled(): boolean {
    return (
      this.user_search_selected_user && !this.uistate.button_user_add_disabled
    );
  }

  public get is_button_user_delete_enabled(): boolean {
    return !this.uistate.button_user_delete_disabled;
  }

  public get is_button_toggle_hidden_enabled(): boolean {
    return !this.uistate.button_toggle_hidden_disabled;
  }

  public get is_user_add_form_shown(): boolean {
    return this.uistate.user_add_form_shown;
  }

  public get is_survey_create_relation_form_shown(): boolean {
    return this.uistate.survey_create_relation_form_shown;
  }

  public get is_survey_create_relation_button_disabled(): boolean {
    return this.uistate.survey_create_relation_button_disabled;
  }

  public get is_create_grade_from_shown(): boolean {
    return this.uistate.is_create_grade_from_shown;
  }

  public get is_button_grade_create_submit_disabled(): boolean {
    return this.uistate.is_button_grade_create_submit_disabled;
  }

  public get grade_currently_edited(): SpecialityGrade | null {
    return this.speciality_grade_currently_edited;
  }

  public get is_button_grade_edit_save_disabled(): boolean {
    return this.uistate.is_button_grade_edit_save_disabled;
  }

  public get is_button_grade_tooltip_delete_disabled(): boolean {
    return this.uistate.is_button_grade_tooltip_delete_disabled;
  }

  private load_speciality(): void {
    this.speciality_service.fetch_by_uuid(this.speciality_uuid).subscribe(
      (response) => {
        this.speciality = response as Speciality;
        this.logging_service.debug(
          `${this.constructor.name} loaded speciality ${this.speciality.name}`
        );
        this.title_service.set_title(this.speciality.name);
        this.uistate.speciality_loaded = true;
        this.load_user_relations();
        this.load_survey_relations();
        this.load_surveys();
        this.load_grades();
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to load speciality`
        );
        this.alert_service.error(`Ошибка загрузки специальности ${err.status}`);
      }
    );
  }

  private load_user_relations(): void {
    this.speciality_user_relation_service
      .fetch_by_speciality_uuid(this.speciality.uuid)
      .subscribe(
        (response) => {
          this.speciality_user_relations =
            response.results as SpecialityUserRelation[];
          this.uistate.speciality_user_relations_loaded = true;
          this.logging_service.debug(
            `${this.constructor.name} loaded ${this.speciality_user_relations.length} user relations`
          );
        },
        (err) => {
          this.logging_service.debug(
            `${this.constructor.name} failed to load user relations`
          );
          this.alert_service.error(
            `Ошибка загрузки связей с пользователями ${err.status}`
          );
        }
      );
  }

  private load_surveys(): void {
    this.survey_service.fetch_all().subscribe(
      (response) => {
        this.surveys = response.results as Survey[];
        this.uistate.surveys_loaded = true;
        this.logging_service.debug(
          `${this.constructor.name} loaded ${this.surveys.length} surveys`
        );
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to load surveys`
        );
        this.alert_service.error(`Ошибка загрузки опросов ${err.status}`);
      }
    );
  }

  private load_survey_relations(): void {
    this.survey_speciality_relation_service
      .fetch_by_speciality_uuid(this.speciality.uuid)
      .subscribe(
        (response) => {
          this.survey_relations =
            response.results as SurveySpecialityRelation[];
          this.uistate.survey_relations_loaded = true;
          this.logging_service.debug(
            `${this.constructor.name} loaded ${this.survey_relations.length} survey relations`
          );
        },
        (err) => {
          this.logging_service.debug(
            `${this.constructor.name} failed to load survey relations`
          );
          this.alert_service.error(
            `Ошибка загрузки связей с опросами ${err.status}`
          );
        }
      );
  }

  private load_grades(): void {
    this.speciality_grade_service
      .fetch_by_speciality_uuid(this.speciality.uuid)
      .subscribe(
        (response) => {
          this.speciality_grades = response.results as SpecialityGrade[];
          this.uistate.speciality_grades_loaded = true;
          this.logging_service.debug(
            `${this.constructor.name} loaded ${this.speciality_grades.length} grades`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to load grades`
          );
          this.alert_service.error(`Ошибка загрузки грейдов: ${err.status}`);
        }
      );
  }

  public on_show_user_add_form(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_show_user_add_form`
    );
    this.uistate.user_add_form_shown = true;
  }

  public on_close_user_add_form(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_close_user_add_form`
    );
    this.uistate.user_add_form_shown = false;
    this.user_search_selected_user = null;
    this.user_search_form_control.reset();
  }

  public on_user_search(term: string): void {
    this.user_search_terms.next(term);
  }

  public on_user_search_selected(user: User): void {
    this.logging_service.debug(
      `${this.constructor.name} on_user_search_autocomplete_selected`
    );
    this.user_search_selected_user = user;
    this.user_search_results = [];
  }

  public on_button_user_add_submitted(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_user_add_submitted`
    );
    this.uistate.button_user_add_disabled = true;
    const relation_data = {
      speciality: this.speciality.uuid,
      user: this.user_search_selected_user.uuid,
    };
    this.speciality_user_relation_service.create(relation_data).subscribe(
      (response) => {
        const relation = {
          uuid: response.uuid,
          speciality: this.speciality,
          user: this.user_search_selected_user,
          date_created: response.date_created,
        } as SpecialityUserRelation;
        this.logging_service.debug(
          `${this.constructor.name} created speciality user relation`
        );
        this.alert_service.success('Специальноть назначена');
        this.user_search_selected_user = null;
        this.uistate.button_user_add_disabled = false;
        this.uistate.user_add_form_shown = false;
        this.speciality_user_relations.push(relation);
        this.user_search_selected_user = null;
        this.user_search_form_control.reset();
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to create speciality user relation`
        );
        this.alert_service.error(
          `Ошибка назначения специальности ${err.status}`
        );
      }
    );
  }

  public on_button_user_delete_submitted(
    relation: SpecialityUserRelation
  ): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_user_delete_submitted`
    );
    this.uistate.button_user_delete_disabled = true;
    this.speciality_user_relation_service.delete(relation.uuid).subscribe(
      (response) => {
        this.logging_service.debug(
          `${this.constructor.name} deleted speciality user relation`
        );
        this.alert_service.success('Назначение специльности удалено');
        this.uistate.button_user_delete_disabled = false;
        this.speciality_user_relations = this.speciality_user_relations.filter(
          (r) => r.uuid !== relation.uuid
        );
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to delete speciality user relation`
        );
        this.alert_service.error(
          `Ошибка удаления назначения специальности ${err.status}`
        );
      }
    );
  }

  public util_user_search_display(user: User): string {
    return user ? `${user.first_name} ${user.last_name} – ${user.email}` : '';
  }

  public util_user_get_initials_for_avatar(user: User): string {
    const first_name_initial = user.first_name.charAt(0);
    const last_name_initial = user.last_name.charAt(0);
    return `${first_name_initial}${last_name_initial}`;
  }

  public on_show_create_survey_relation_form(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_show_create_survey_relation_form`
    );
    this.uistate.survey_create_relation_form_shown = true;
  }

  public on_button_create_survey_relation(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_create_survey_relation`
    );
    this.uistate.survey_create_relation_button_disabled = true;
    const relation_data = {
      survey: this.selected_relation_selected_survey.uuid,
      speciality: this.speciality.uuid,
    };
    this.survey_speciality_relation_service.create(relation_data).subscribe(
      (response) => {
        const relation = response as SurveySpecialityRelation;
        this.load_survey_relations();
        this.uistate.survey_create_relation_button_disabled = false;
        this.uistate.survey_create_relation_form_shown = false;
        this.selected_relation_selected_survey = null;
        this.logging_service.debug(
          `${this.constructor.name} created survey relation with uuid ${relation.uuid}`
        );
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to load survey relation`
        );
        this.alert_service.error(
          `Ошибка создания связи с опросами ${err.status}`
        );
      }
    );
  }

  public on_button_delete_survey_relation(
    relation: SurveySpecialityRelation
  ): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_delete_survey_relation`
    );
    this.uistate.survey_delete_relation_button_disabled = true;
    this.survey_speciality_relation_service.delete(relation.uuid).subscribe(
      (response) => {
        this.survey_relations = this.survey_relations.filter(
          (r) => r.uuid !== relation.uuid
        );
        this.uistate.survey_delete_relation_button_disabled = false;
        this.logging_service.debug(
          `${this.constructor.name} deleted survey relation with uuid ${relation.uuid}`
        );
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to delete survey relation`
        );
        this.alert_service.error(
          `Ошибка удаления связи с опросом ${err.status}`
        );
      }
    );
  }

  public on_button_toggle_speciality_hidden(): void {
    const new_hidden_value = !this.speciality.is_hidden;
    this.uistate.button_toggle_hidden_disabled = true;
    this.logging_service.debug(
      `${this.constructor.name} setting speciality hidden to ${new_hidden_value}`
    );
    this.speciality_service
      .update(this.speciality.uuid, { is_hidden: new_hidden_value })
      .subscribe(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} update specilality is_hidden: ${new_hidden_value} successfully`
          );
          this.speciality.is_hidden = new_hidden_value;
          this.uistate.button_toggle_hidden_disabled = false;
        },
        (err) => {
          this.logging_service.debug(
            `${this.constructor.name} failed update speciality is_hidden: ${new_hidden_value}`
          );
          this.alert_service.error(`Ошибка изменения видимости ${err.status}`);
          this.uistate.button_toggle_hidden_disabled = false;
        }
      );
  }

  public reset_edit_mode() {
    this.uistate.is_header_in_edit_mode = false;
  }

  public on_button_toggle_edit_mode() {
    this.uistate.is_header_in_edit_mode = true;
    this.speciality_edit_form.controls.speciality_name.setValue(
      this.speciality.name
    );
    this.speciality_edit_form.controls.speciality_description.setValue(
      this.speciality.description
    );
    this.speciality_edit_form.controls.team.setValue(this.speciality.team);
  }

  private create_form() {
    this.speciality_edit_form = this.form_builder.group({
      speciality_name: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      speciality_description: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
      team: ['', []],
    });
  }

  public searchTeams(teamName: string): void {
    this.teams.setRequestParams({
      params: { search: teamName },
    });
    this.teams.load().subscribe(() => {
      this._setOptions();
    });
  }

  private _setOptions(): void {
    this.teamsOptions = [
      {
        uuid: null,
        name: 'Не в команде (библиотека)',
        description: '',
        parent_team: null,
        parent: null,
        team_type: 'agile',
        date_created: '',
        members: [],
        is_hidden: false,
        specialities: [],
        editors: [],
        created_by: '',
        is_verified: false,
        verified_by: '',
        date_verified: '',
        icon_url: null,
      },
      ...this.teams.state.items,
    ];
  }

  public save_edit_form() {
    const specialityData = {
      name: this.speciality_edit_form.value.speciality_name,
      description: this.speciality_edit_form.value.speciality_description,
    };
    if (this.speciality_edit_form.value.team) {
      specialityData['team'] = this.speciality_edit_form.value.team.uuid;
    }
    this.speciality_service
      .update(this.speciality.uuid, specialityData)
      .subscribe(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} save new data for ${this.speciality.uuid}`
          );
          this.alert_service.error(`Сохранено`);
          this.speciality.name =
            this.speciality_edit_form.value.speciality_name;
          this.speciality.description =
            this.speciality_edit_form.value.speciality_description;
          if (response.team) {
            this.speciality.team = this.speciality_edit_form.value.team;
          } else {
            this.speciality.team = null;
          }
          this.reset_edit_mode();
        },
        (err) => {
          this.logging_service.debug(
            `${this.constructor.name} failed save new data for ${this.speciality.uuid}`
          );
          this.alert_service.error(`Ошибка сохранения изменений ${err.status}`);
          this.reset_edit_mode();
        }
      );
  }

  public on_button_show_create_grade(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_show_create_grade`
    );
    this.uistate.is_create_grade_from_shown = true;
  }

  public on_button_grade_create_cancel(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_grade_create_cancel`
    );
    this.uistate.is_create_grade_from_shown = false;
  }

  public on_button_grade_create_submit(grade_name: string): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_grade_create_submit ${grade_name}`
    );
    this.uistate.is_button_grade_create_submit_disabled = true;
    let max_order = 0;
    this.speciality_grades.forEach((grade) => {
      if (grade.order > max_order) max_order = grade.order;
    });
    const grade_data = {
      name: grade_name,
      speciality: this.speciality.uuid,
      order: max_order + 1,
    };
    this.speciality_grade_service.create(grade_data).subscribe(
      (response) => {
        const grade = response as SpecialityGrade;
        this.speciality_grades.push(grade);
        this.logging_service.debug(
          `${this.constructor.name} created grade ${grade.uuid}`
        );
        this.alert_service.success('Грейд добавлен');
        this.uistate.is_button_grade_create_submit_disabled = false;
        this.uistate.is_create_grade_from_shown = false;
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to create grade`
        );
        this.alert_service.error(`Ошибка добавления грейда: ${err.status}`);
      }
    );
  }

  public on_button_grade_edit(grade: SpecialityGrade): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_grade_edit set grade ${grade.name}`
    );
    this.speciality_grade_currently_edited = grade;
  }

  public on_button_grade_edit_save(
    grade: SpecialityGrade,
    input_value: string
  ): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_grade_edit_save ${grade.name}, ${input_value}`
    );
    this.uistate.is_button_grade_edit_save_disabled = true;
    this.speciality_grade_service
      .update(grade.uuid, { name: input_value })
      .subscribe(
        (response) => {
          this.speciality_grades.find((g) => g.uuid === grade.uuid).name =
            response.name;
          this.logging_service.debug(
            `${this.constructor.name} updated grade name to ${input_value} for ${grade.uuid}`
          );
          this.alert_service.success(`Название грейда изменено`);
          this.uistate.is_button_grade_edit_save_disabled = false;
          this.on_button_grade_edit_cancel();
        },
        (err) => {
          this.uistate.is_button_grade_edit_save_disabled = false;
          this.logging_service.error(
            `${this.constructor.name} failed to update grade ${grade.uuid}`
          );
          this.alert_service.error(
            `Ошибка сохранения изменений: ${err.status}`
          );
        }
      );
  }

  public on_button_grade_edit_cancel(): void {
    this.logging_service.debug(`${this.constructor.name} on_grade_edit_cancel`);
    this.speciality_grade_currently_edited = null;
  }

  public on_button_grade_delete_show_tooltip(grade, $event): void {
    this.speciality_grade_currently_deleted = grade;
    const tooltip_style_left = `${
      $event.pageX - this.tooltip_delete_grade.nativeElement.offsetWidth - 240
    }px;`;
    const tooltip_style_top = `${$event.pageY - 15}px;`;
    this.tooltip_delete_grade.nativeElement.style.display = 'block';
    this.tooltip_delete_grade.nativeElement.style.opacity = 1.0;
    this.tooltip_delete_grade.nativeElement.setAttribute(
      'style',
      `top: ${tooltip_style_top}; left: ${tooltip_style_left}`
    );
  }

  public on_button_grade_delete_hide_tooltip(): void {
    this.speciality_grade_currently_deleted = null;
    this.tooltip_delete_grade.nativeElement.style.opacity = 0.0;
    this.tooltip_delete_grade.nativeElement.style.display = 'none';
  }

  public on_tooltip_grade_delete_confirm(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_tooltip_grade_delete_confirm`
    );
    const grade = this.speciality_grade_currently_deleted;
    this.uistate.is_button_grade_tooltip_delete_disabled = true;
    this.speciality_grade_service.delete(grade.uuid).subscribe(
      (response) => {
        this.speciality_grades = this.speciality_grades.filter(
          (g) => g.uuid !== grade.uuid
        );
        this.logging_service.debug(
          `${this.constructor.name} deleted grade ${grade.uuid}`
        );
        this.alert_service.success(`Грейд удален`);
        this.uistate.is_button_grade_tooltip_delete_disabled = false;
        this.on_tooltip_grade_delete_cancel();
      },
      (err) => {
        this.uistate.is_button_grade_tooltip_delete_disabled = false;
        this.logging_service.error(
          `${this.constructor.name} failed to update grade ${grade.uuid}`
        );
        this.alert_service.error(`Ошибка удаления: ${err.status}`);
      }
    );
  }

  public on_tooltip_grade_delete_cancel(): void {
    this.speciality_grade_currently_deleted = null;
    this.logging_service.debug(
      `${this.constructor.name} on_tooltip_grade_delete_cancel`
    );
    this.on_button_grade_delete_hide_tooltip();
  }
}
