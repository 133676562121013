<div *ngFor="let user of users" class="user-container" fxLayout="row">
  <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
    <mat-checkbox
      fxFlex="3rem"
      [checked]="isUserSelected(user.uuid)"
      (change)="onSelectionToggle(user)"
    ></mat-checkbox>
    <div fxLayout="column" fxFlex>
      <span class="user-name">{{
        user.first_name + ' ' + user.last_name
      }}</span>
      <span class="user-email">{{ user.email }}</span>
    </div>
  </div>
</div>
