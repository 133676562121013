import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';

export interface LibraryItemProps {
  header?: string;
  content: string;
  footer?: string;
  linkUrl: string;
  newFlag?: boolean;
  editable?: boolean;
}

@Component({
  selector: 'app-library-item',
  templateUrl: './library-item.component.html',
  styleUrls: ['./library-item.component.css'],
})
export class LibraryItemComponent {
  @Input('item') item: LibraryItemProps;
  @Input() librarySearch = false;
  @Input() specialityUuid: string = null;

  constructor(private _router: Router, private _authService: AuthService) {}

  onItemEdit(item: LibraryItemProps): void {
    this._router.navigate([`library/specialities/${this.specialityUuid}/edit`]);
  }

  isAdmin(): boolean {
    return this._authService.is_admin();
  }

  redirectToItem(link: string): void {
    if (this.librarySearch) {
      this._router.navigate([link]);
    }
  }
}
