<div class="review-progress">
  <mat-progress-spinner
    class="progress"
    mode="determinate"
    color="primary"
    [value]="percents"
  >
  </mat-progress-spinner>

  <mat-progress-spinner
    class="background-progress progress"
    mode="determinate"
    color="primary"
    [value]="100"
  >
  </mat-progress-spinner>

  <div class="progress-value">{{ percents }} %</div>
</div>
