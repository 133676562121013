<div class='dialog-container'>
  <div class='dialog-title'>{{ data.title }}</div>
  <div fxLayoutGap='8px' fxLayout='column'>
    <div
      *ngFor='let block of data.textBlocksData'
      class='text-block'
      [ngStyle]='getBlockColor(block.color)'
      fxFlex
    >
      <div class='text-block-title'>{{ block.title }}</div>
      <div class='text-block-text'>{{ block.text }}</div>
    </div>
  </div>
</div>
