import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '@models/user/user';
import { TeamMemberStatusHttpService } from '@services/http/TeamMemberStatusHttpService';
import { TeamMemberStatus } from '@models/teams/team-member-status';
import { AsyncList } from '@rest/AsyncList';

@Component({
  selector: 'app-edit-team-member-status-dialog',
  templateUrl: './edit-team-member-status-dialog.component.html',
  styleUrls: ['./edit-team-member-status-dialog.component.css'],
})
export class EditTeamMemberStatusDialogComponent implements OnInit {
  teamMemberStatus: AsyncList<TeamMemberStatus>;
  teamMemberStatusUuid: string;

  isKeyPeople = false;
  isBusFactor = false;

  keyPeopleReason = '';
  busFactorReason = '';

  constructor(
    public dialogRef: MatDialogRef<EditTeamMemberStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      user: User;
      teamUuid: string;
    },
    private _teamMemberStatusHttpService: TeamMemberStatusHttpService
  ) {}

  ngOnInit(): void {
    this.teamMemberStatus = new AsyncList<TeamMemberStatus>(
      this._teamMemberStatusHttpService
    );
    this.teamMemberStatus.setRequestParams({
      params: {
        team: this.data.teamUuid,
        member: this.data.user.uuid,
      },
    });
    this.teamMemberStatus.load().subscribe(() => {
      if (this.teamMemberStatus.state.items.length) {
        this.teamMemberStatusUuid = this.teamMemberStatus.state.items[0].uuid;
        this.isBusFactor = this.teamMemberStatus.state.items[0].bus_factor;
        this.isKeyPeople = this.teamMemberStatus.state.items[0].key_people;
        this.keyPeopleReason =
          this.teamMemberStatus.state.items[0].key_people_reason;
        this.busFactorReason =
          this.teamMemberStatus.state.items[0].bus_factor_reason;
      }
    });
  }

  submit(): void {
    if (this.teamMemberStatusUuid) {
      this._teamMemberStatusHttpService
        .update(this.teamMemberStatusUuid, {
          key_people: this.isKeyPeople,
          bus_factor: this.isBusFactor,
          is_updated_manually: true,
        })
        .subscribe(() => this.dialogRef.close());
    } else {
      this._teamMemberStatusHttpService
        .create({
          team: this.data.teamUuid,
          member: this.data.user.uuid,
          key_people: this.isKeyPeople,
          bus_factor: this.isBusFactor,
          is_updated_manually: true,
        })
        .subscribe(() => this.dialogRef.close());
    }
  }
}
