<form [formGroup]='competenciesForm'>
  <mat-form-field class='search-field'>
    <input
      matInput
      placeholder='Поиск по компетенциям'
      type='text'
      (keyup)='searchThing()'
      formControlName='search'
      autocomplete='off'
    />
  </mat-form-field>
  <div class='competencies-container'>
    <app-with-processing
      [processing]='
        (domains.state.isProcessing || specialityDomainClaims.state.isProcessing) &&
        (specialityCompetenceClaims?.state.isProcessing ||
          !libraryThingsReady) &&
        processingFilteredThings
      '
    >
      <app-domain-thing-tree
        *ngIf='(domains.state.isLoaded || specialityDomainClaims.state.isLoaded) && (specialityCompetenceClaims?.state.isLoaded || libraryThingsReady) && !processingFilteredThings'

        [domains]='domains.state.items'
        [specialityDomainsClaims]='specialityDomainClaims.state.items'
        [reorderHidden]='true'
        [things]='filteredThings'
        [selectMode]='true'
        [selectAllNodes]='selectedClaims.length === 0 && selectAllNodes'
        [selectedClaims]='selectedClaims'
        (selectedClaimsChange)='onSelectedClaimsChange($event)'
      ></app-domain-thing-tree>
      <!--      [expandNode]="filteredThings.length > 0 ? filteredThings[0].uuid : null"-->
    </app-with-processing>
  </div>
</form>
