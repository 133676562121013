<ng-container *ngIf="tree_data.length; then tmpl_subtree; else tmpl_emtpy_subtree">
</ng-container>

<ng-template #tmpl_emtpy_subtree>
    <div class="app-admin-survey-edit-subtree-nochildren-container">
    </div>
</ng-template>

<ng-template #tmpl_emtpy>
</ng-template>

<ng-template #tmpl_emtpy_subtree>
    <div class="app-admin-survey-edit-subtree-nochildren-container">
    </div>
</ng-template>

<ng-template #tmpl_subtree>
    <div class="app-admin-survey-edit-subtree-container" cdkDropList (cdkDropListDropped)="on_drop($event)">
        <div *ngFor="let node of tree_data">
            <div class="app-admin-survey-edit-subtree-node" [cdkDragDisabled]="tree_data.length <= 1 || is_reordering_disabled" cdkDrag>
                <div fxLayout="row">
                    <div fxFlex="3rem">
                        <div *ngIf="node.children.length !== 0" class="app-admin-survey-edit-subtree-node-toggle">
                            <button mat-icon-button (click)="toggle_child(node)">
                                <mat-icon>
                                    {{ node.show_children ? 'expand_more' : 'chevron_right' }}
                                </mat-icon>
                            </button>
                        </div>
                    </div>
                    <div fxFlex="2rem">
                        <div class="app-admin-survey-edit-subtree-node-question-type">
                            <mat-icon>
                                {{ node.question.question_type === 'assert_competence_level' ? 'assessment' : 'share' }}
                            </mat-icon>
                        </div>
                    </div>
                    <div fxFlex>
                        <div class="app-admin-survey-edit-subtree-node-question">
                            {{ (node.question.related_thing && node.question.related_thing.name) ? node.question.related_thing.name : node.question.title }}
                        </div>
                    </div>
                    <div fxFlex="10rem">
                        <div class="app-admin-survey-edit-subtree-node-controls" fxLayout="row-reverse">
                            <div *ngIf="!node.to_delete">
                                <button mat-icon-button (click)="on_button_create_child_question(node)">
                                    <mat-icon>add</mat-icon>
                                </button>
                                <button *ngIf="tree_data.length > 1" mat-icon-button cdkDragHandle [disabled]="is_reordering_disabled">
                                    <mat-icon>swap_vert</mat-icon>
                                </button>
                                <button mat-icon-button
                                    routerLink="/admin/surveys/question_edit/{{ node.question.uuid }}/{{survey.uuid}}">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button (click)="on_button_delete_child_question(node)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                            <div *ngIf="node.to_delete">
                                <button mat-icon-button color="warn" (click)="on_button_delete_child_question_confirm()">
                                    <mat-icon>delete</mat-icon>
                                </button>
                                <button mat-icon-button (click)="on_button_delete_child_question_cancel()">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex>
                        <app-survey-edit-subtree *ngIf="node.show_children"
                            [survey]="survey"
                            [tree_data]="node.children"
                            [node_create_request_subject]="node_create_request_subject"
                            [node_delete_request_subject]="node_delete_request_subject">
                        </app-survey-edit-subtree>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

