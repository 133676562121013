import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImportedData } from '@models/ontology/imported-data';

import { ViewSetHttpService } from './rest/ViewSetHttpService';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImportedDataHttpService extends ViewSetHttpService<ImportedData> {
  http: HttpClient;
  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'ontology/imported_data';

  public upload(formData: FormData): Observable<any> {
    return this.http.post(`${this.getUrl()}/upload`, formData);
  }

  public saveImportedData(): Observable<any> {
    return this.http.post(`${this.getUrl()}/save_imported_data`, {});
  }

  public clearImportedData(): Observable<any> {
    return this.http.post(`${this.getUrl()}/clear_imported_data`, {});
  }
}
