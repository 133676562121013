import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Domain } from '@models/ontology/domain';
import { Thing } from '@models/ontology/thing';
import { LibraryItemProps } from '@components/common/library-item/library-item.component';
import { Router } from '@angular/router';

export interface DetailFormData {
  name: string;
  description: string;
}

@Component({
  selector: 'app-domain-detail-edit-dialog',
  templateUrl: './domain-detail-edit-dialog.component.html',
  styleUrls: ['./domain-detail-edit-dialog.component.css'],
})
export class DomainDetailEditDialogComponent implements OnInit {
  form: UntypedFormGroup;

  isAdditionalSettingsShowed = false;

  constructor(
    public dialogRef: MatDialogRef<DomainDetailEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      domains: Domain[];
      things: Thing[];
      thingUuid: string;
      defaultData: { name: string; description: string; kind: number };
      isThing: boolean;
      isEdit: boolean;
      onSubmit(formData: DetailFormData): void;
    },
    private _router: Router,
    private _formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this._initForm();
  }

  private _initForm(): void {
    this.form = this._formBuilder.group({
      name: [
        this.data.defaultData.name,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      description: [
        this.data.defaultData.description,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
      kind: [this.data.defaultData.kind ? this.data.defaultData.kind : 0, []],
      levels: [[]],
    });
  }

  _isSimilarElement = (element: { name: string }): boolean => {
    return (
      this.form.value.name.length > 3 &&
      element.name.includes(this.form.value.name)
    );
  };

  getSimilarElements(): { domains: Domain[]; things: Thing[] } {
    return {
      domains: this.data.domains.filter(this._isSimilarElement),
      things: this.data.things.filter(this._isSimilarElement),
    };
  }

  isShowSimilarWarning(): boolean {
    return (
      !this.data.isEdit &&
      (this.getSimilarElements().domains.length > 0 ||
        this.getSimilarElements().things.length > 0)
    );
  }

  serializeDomain(domain: Domain): LibraryItemProps {
    return {
      content: domain.name,
      footer: domain.description,
      linkUrl: `/library/domains/${domain.uuid}`,
    };
  }

  serializeThing(thing: Thing): LibraryItemProps {
    return {
      content: thing.name,
      footer: thing.description,
      linkUrl: `/things/${thing.uuid}`,
    };
  }

  getTitleLabel(): string {
    return this.data.isEdit
      ? `${
          this.data.isThing
            ? 'Изменить компетенцию'
            : 'Изменить группу компетенций'
        }`
      : `${
          this.data.isThing ? 'Новая компетенция' : 'Новая группа компетенций'
        }`;
  }

  close(): void {
    this.data.onSubmit(this.form.value);
    this.dialogRef.close();
  }

  redirectToThingEdit(): void {
    this._router.navigate([`/things/${this.data.thingUuid}/edit`]);
    this.dialogRef.close();
  }
}
