import { Component, OnInit } from '@angular/core';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-speciality-detail-page',
  templateUrl: './speciality-detail.page.html',
  styleUrls: ['./speciality-detail.page.css'],
})
export class SpecialityDetailPage implements OnInit {
  speciality: AsyncDetail<Speciality>;
  constructor(
    private _specialityHttpService: SpecialityHttpService,
    private _activatedRoute: ActivatedRoute,
    private _titleService: TitleService
  ) {
    this.speciality = new AsyncDetail<Speciality>(
      this._activatedRoute.snapshot.params.uuid,
      this._specialityHttpService
    );
  }

  ngOnInit(): void {
    this.speciality.requestParams = {
      params: {
        expand: 'related_grades',
      },
    };
    this.speciality.load().subscribe((response) => {
      this._titleService.set_title(`Специальность ${response.name}`);
    });
  }
}
