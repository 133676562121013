import { Component, OnInit } from '@angular/core';
import { DevelopmentPlanHttpService } from '@services/http/DevelopmentPlanHttpService';
import { AsyncList } from '@rest/AsyncList';
import { DevelopmentPlan } from '@models/tracks/development-plan';
import { AuthService } from '../../../../../services/auth/auth.service';
import { Router } from '@angular/router';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-pdp-main',
  templateUrl: './pdp-main.component.html',
  styleUrls: ['./pdp-main.component.css'],
})
export class PdpMainComponent implements OnInit {
  userUuid: string;
  userDevelopmentPlans: AsyncList<DevelopmentPlan>;

  ongoingPlans: DevelopmentPlan[];
  responsiblePlans: DevelopmentPlan[];
  completedPlans: DevelopmentPlan[];
  deletedPlans: DevelopmentPlan[];

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _titleService: TitleService,
    private _developmentPlanHttpService: DevelopmentPlanHttpService
  ) {}

  ngOnInit(): void {
    this._titleService.set_title('План развития');
    this.userUuid = this._authService.get_current_user_uuid();
    this.userDevelopmentPlans = new AsyncList<DevelopmentPlan>(
      this._developmentPlanHttpService
    );
    this.userDevelopmentPlans.setRequestParams({
      params: {
        subject_user: this.userUuid,
      },
    });
    this.userDevelopmentPlans.load().subscribe(() => {
      this.ongoingPlans = this.userDevelopmentPlans.state.items.filter(
        (plan) => plan.status !== 'completed' && plan.status !== 'deleted'
      );
      this.responsiblePlans = this.userDevelopmentPlans.state.items.filter(
        (plan) =>
          plan.responsible_user === this.userUuid && plan.status === 'active'
      );
      this.completedPlans = this.userDevelopmentPlans.state.items.filter(
        (plan) => plan.status === 'completed'
      );
    });
  }

  getPrettyDevelopmentDate(development: DevelopmentPlan): string {
    if (development.date_created && development.due_date) {
      const dateFrom = new Date(development.date_created),
        dateTo = new Date(development.due_date);
      return `${dateFrom.getDate()} ${dateFrom.toLocaleString('default', {
        month: 'short',
      })} - ${dateTo.getDate()} ${dateTo.toLocaleString('default', {
        month: 'short',
      })}`;
    }
    return '';
  }

  onDevelopmentPlanAdd(): void {
    this._router.navigate([`account/pdp-create`]);
  }

  onDevelopmentPlanClick(development: DevelopmentPlan): void {
    this._router.navigate([`account/pdp/pdp-details/${development.uuid}`]);
  }
}
