import { Component, Inject, OnInit } from '@angular/core';
import { AsyncList } from '@rest/AsyncList';
import { Thing } from '@models/ontology/thing';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-speciality-competence',
  templateUrl: './add-speciality-competence.component.html',
  styleUrls: ['./add-speciality-competence.component.css'],
})
export class AddSpecialityCompetenceComponent implements OnInit {
  public things: AsyncList<Thing>;
  public selectedThing: Thing = null;
  public options: Thing[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      things: AsyncList<Thing>;
      excluded: string[];
      onSelect(thing: Thing): void;
    }
  ) {
    this.things = this.data.things;
  }

  ngOnInit(): void {
    this.search('');
  }

  search(search: string): void {
    this.things.setRequestParams({
      params: {
        ...this.things.requestParams.params,
        team__isnull: 'True',
        search,
        expand: 'levels',
      },
    });
    this.things.load().subscribe(() => {
      this.options = this.things.state.items.filter((thing) => {
        return !this.data.excluded.includes(thing.uuid);
      });
    });
  }

  onAccept(): void {
    if (this.selectedThing) {
      this.data.onSelect(this.selectedThing);
    }
  }
}
