import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { Thing } from '@models/ontology/thing';
import { AsyncList } from '@rest/AsyncList';
import { Domain } from '@models/ontology/domain';

import Tree, { TreeNode } from 'src/app/utils/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Subject } from 'rxjs';

type TreeModel = Domain | Thing;

export interface ThingStatusMap {
  [thingUuid: string]: {
    icon: string;
    disabled: boolean;
  };
}

export interface ActiveThingStatusMap {
  [thingUuid: string]: boolean;
}

export interface AngularDefaultTreeNode {
  expandable: boolean;
  name: string;
  level: number;
  uuid: string;
  children: TreeNode<TreeModel>[];
}

@Component({
  selector: 'app-review-things-tree',
  templateUrl: './review-things-tree.component.html',
  styleUrls: ['./review-things-tree.component.css'],
})
export class ReviewThingsTreeComponent implements OnInit {
  @Input() activeThingUuid = '';
  @Input() things: Thing[];
  @Input() thingStatusMap: ThingStatusMap;
  @Input() setNextThing$: Subject<any>;
  @Input() getDomainIconFunc: any;
  @Output() setActiveThing: EventEmitter<string> = new EventEmitter<string>();

  domains: AsyncList<Domain>;
  tree: Tree<TreeModel>;

  constructor(private _domainHttpService: DomainHttpService) {}

  ngOnInit(): void {
    this.domains = new AsyncList<Domain>(this._domainHttpService);
    this.domains.load().subscribe(() => {
      this._buildTree();
    });

    this.setNextThing$.subscribe(() => {
      this._setDefaultActiveResponse();
    });
  }

  private _buildTree(): void {
    const assessmentTreeThings = this._getAssessmentThings();
    const assessmentTreeDomains = this._getAssessmentDomains();

    this.tree = new Tree<TreeModel>(
      [...assessmentTreeDomains, ...assessmentTreeThings],
      'parent_domain'
    );

    this.dataSource.data = this.tree.getRoot().children;
    this._setDefaultActiveResponse();
  }

  private _getAssessmentThings(): TreeModel[] {
    return this.things.map((thing) => ({
      ...thing,
      parent_domain: thing.domain,
    }));
  }

  private _getAssessmentDomains(): TreeModel[] {
    const domainsTree = new Tree(this.domains.state.items, 'parent_domain');

    const thingParentDomainUuids: any = this.things.map(
      (thing) => thing.domain
    );

    const thingDomains = [];

    thingParentDomainUuids.forEach((domainUuid) => {
      const parentDomain = domainsTree.getNode(domainUuid);

      if (thingDomains.indexOf(parentDomain.item) === -1) {
        thingDomains.push(parentDomain.item);
      }

      domainsTree.getParents(parentDomain).forEach((node) => {
        if (thingDomains.indexOf(node.item) === -1) {
          thingDomains.push(node.item);
        }
      });
    });

    return thingDomains;
  }

  onSetActiveThing(thingUuid: string): void {
    this.setActiveThing.emit(thingUuid);
  }

  isThingDisabled(thingUuid: string): boolean {
    return this.thingStatusMap[thingUuid].disabled;
  }

  getIcon(thingUuid: string): string {
    if (this.thingStatusMap[thingUuid]) {
      return this.thingStatusMap[thingUuid].icon
    }
  }

  getDomainIcon(thingUuid: string): string {
    if (this.getDomainIconFunc) {
      return this.getDomainIconFunc(this.tree, thingUuid);
    }
  }

  getNodePadding(level: number): object {
    return {'padding-left': 15*level + 'px;'};
  }

  private _transformer = (node: TreeNode<TreeModel>, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.item.name,
      uuid: node.item.uuid,
      level: level,
      children: node.children,
    };
  };

  private _getFisrtUndisabledNode(): AngularDefaultTreeNode {
    return this.treeControl.dataNodes.find((node) => {
      return (
        this.thingStatusMap[node.uuid] &&
        !this.thingStatusMap[node.uuid].disabled
      );
    })
  }

  private _setDefaultActiveResponse(): void {
    let defaultResponse;
    if (this.activeThingUuid) {
      const activeThing = this.treeControl.dataNodes.find((node) => {
        return (
          this.activeThingUuid === node.uuid
        );
      });
      defaultResponse = this.treeControl.dataNodes.find((node, index) => {
        return (
          this.treeControl.dataNodes.indexOf(activeThing) < index
          && this.thingStatusMap[node.uuid]
          && !this.thingStatusMap[node.uuid].disabled
        )
      })
      if (!defaultResponse) {
        defaultResponse = this._getFisrtUndisabledNode()
      }
    } else {
      defaultResponse = this._getFisrtUndisabledNode()
    }


    if (defaultResponse) {
      this.treeControl.collapseAll();

      this._expandAngularTreeNode(
        defaultResponse,
        this.treeControl.dataNodes,
        defaultResponse.level - 1
      );
      this.onSetActiveThing(defaultResponse.uuid);
    }
  }

  _expandAngularTreeNode(
    node: AngularDefaultTreeNode,
    nodes: AngularDefaultTreeNode[],
    nextOpenLevel: number
  ): void {
    if (node.level !== 0) {
      const prevIndex = nodes.indexOf(node) - 1;

      if (nodes[prevIndex].level === nextOpenLevel) {
        this.treeControl.expand(nodes[prevIndex]);
      }

      this._expandAngularTreeNode(
        nodes[prevIndex],
        nodes,
        nodes[prevIndex].level === nextOpenLevel
          ? nextOpenLevel - 1
          : nextOpenLevel
      );
    }
  }

  // angular tree methods
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  hasChild = (_: number, node: AngularDefaultTreeNode): boolean => {
    return node.expandable;
  };

  treeControl = new FlatTreeControl<AngularDefaultTreeNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
}
