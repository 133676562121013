<div
  class='assessment-container'
  fxLayout='column'
  *ngIf='assessment && assessment.state.isLoaded'
>
  <div
    fxLayout='row'
    fxLayoutAlign='start center'
    fxLayoutGap='2rem'
    class='review-title'
  >
    <button (click)='onBackClick()' mat-icon-button>
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span>{{ reviewName }}</span>
  </div>
  <app-static-textfield-group
    [fields]='assessmentInfoData'
  ></app-static-textfield-group>
  <mat-divider class='divider'></mat-divider>
  <div *ngIf='!isAssessmentCompleted'>
    <app-with-processing
      [processing]='assessmentResponses?.state.isProcessing && speciality?.state.isProcessing && !treeDataReady'
    >
      <div *ngIf='assessmentResponses?.state.isLoaded && speciality.state.isLoaded && treeDataReady'>
        <span>{{ 'Завершено на ' + assessment.state.item.completion_percentage + '%' }}</span>
        <span (click)='onWhatThisMetricsDoes()'
              *ngIf='assessment.state.item.completion_percentage == 100'
              class='legend-desc-text'>Что означают Вы и Финальная?
        </span>
        <mat-progress-bar
          mode='determinate'
          class='assessment-progress-bar'
          [value]='assessment.state.item.completion_percentage'
        ></mat-progress-bar>
        <a
          *ngIf='assessment.state.item.completion_percentage == 100'
          class='dashboard-button'
          mat-raised-button
          (click)='downloadReviewExcel()'
        >
          <mat-icon>cloud_download</mat-icon>
          <p class='excel-button'>Результаты в excel</p>
        </a>
        <br><br>
        <app-domain-thing-tree
          [domains]='domains.state.items'
          [things]='things'
          [reviewMode]='reviewMode'
          [assessmentResponseThingMap]='assessmentResponseThingMap'
          [resolutionResponseThingMap]='resolutionResponseThingMap'
          [thingDesireMap]='thingDesireMap'
          (assessmentChange)='onAssessmentChange()'
        ></app-domain-thing-tree>
        <div
          fxLayout='row'
          fxLayoutAlign='end center'
          fxLayoutGap='1rem'
          class='assessment-buttons-container'
          *ngIf='assessment.state.item.completion_percentage !== 100'
        >
          <button mat-raised-button class='save-button' (click)='onBackClick()'>
            Сохранить и продолжить позже
          </button>
          <button
            mat-raised-button
            class='close-button'
            [disabled]='!speciality || !speciality.state.item'
            (click)='closeAssessmentResponse()'
          >
            Завершить ревью
          </button>
        </div>
      </div>
    </app-with-processing>
  </div>
  <div
    *ngIf='isAssessmentCompleted'
    fxLayout='column'
    fxLayoutAlign='center center'
  >
    <div class='thanks-text'>Спасибо, что приняли участие в ревью!</div>
    <div fxLayout='row' fxLayoutAlign='center center'>
      <button mat-raised-button (click)='onBackClick()'>
        Вернуться к входящим ревью
      </button>
    </div>
  </div>
</div>
