import { Component, Input, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';

export interface PieChartItem {
  color: string;
  value: number;
}

@Component({
  selector: 'app-chart-pie',
  templateUrl: './chart-pie.component.html',
  styleUrls: ['./chart-pie.component.css'],
})
export class ChartPieComponent {
  private _data: any;
  private el_container_selector = '.app-ui-chart-pie';

  @Input()
  set data(input_data: PieChartItem[]) {
    this._data = input_data;
  }

  get data(): PieChartItem[] {
    return this._data;
  }

  public svg_options: { width; height } = { width: '100%', height: '140px' };

  constructor() {}

  ngOnInit(): void {
    const svg_radar = this.draw_chart(this.el_container_selector, this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    const svg_radar = this.draw_chart(this.el_container_selector, this.data);
  }

  private draw_chart(parent_selector, data) {
    const parent = d3.select(parent_selector);
    // redraw entire element
    parent.select('svg').remove();
    const svg = parent
      .append('svg')
      .attr('width', this.svg_options.width)
      .attr('height', this.svg_options.height);

    const margin = {
      top: 15,
      right: 0,
      bottom: 5,
      left: 5,
    };
    // const width = +svg.attr('width') - margin.left - margin.right;
    // const height = +svg.attr('height') - margin.top - margin.bottom;
    const width = 60;
    const height = 60;
    const radius = 56;

    const g = svg
      .append('g')
      .attr(
        'transform',
        `translate(${width + margin.left},${height + margin.top})`
      );

    const color: any = d3.scaleOrdinal(this.data.map((item) => item.color));

    const pie: any = d3.pie().value((d: any) => d.value);

    const arc: any = d3.arc().outerRadius(radius).innerRadius(0);

    const arcs = pie(this.data);

    const pies = g
      .selectAll()
      .data(arcs)
      .join('path')
      .attr('d', (d) => {
        return arc(d);
      })
      .attr('fill', (d, index) => color(index));
  }
}
