import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LibrarySpecialityGuard implements CanActivate {
  constructor(
    protected readonly router: Router,
    protected readonly auth_service: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (route.routeConfig.path === 'library/specialities/:uuid/edit') {
      return this._checkSpecialityEditPermissions(route);
    }
    return of(true);
  }

  private _checkSpecialityEditPermissions(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.auth_service.userProfile$.subscribe((user) => {
        if (user.loaded && user.userDirectionsDomainsLoaded) {
          if (user.profile.roles.includes('admin')) {
            observer.next(true);
            return;
          }
          if (
            this.auth_service
              .getUserDirectionSpecialities()
              .includes(route.params.uuid)
          ) {
            observer.next(true);
            return;
          } else {
            observer.next(false);
            this.router.navigate(['/403']);
          }
        }
      });
    });
  }
}
