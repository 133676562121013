import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MomentModule } from 'ngx-moment';

import { CommonUIElementsModule } from '../common-ui-elements/common-ui-elements.module';

import { SurveysRoutingModule } from './surveys-routing.module';

import { SurveyMainComponent } from './components/survey-main/survey-main.component';
import { SurveyLinearComponent } from './components/survey-linear/survey-linear.component';
import { SurveyTreeComponent } from './components/survey-tree/survey-tree.component';
import { SurveyTreeVisualComponent } from './components/survey-tree-visual/survey-tree-visual.component';
import { SurveyTreeResultComponent } from './components/survey-tree-result/survey-tree-result.component';
import { SurveyTreeVisualPanComponent } from './components/survey-tree-visual-pan/survey-tree-visual-pan.component';



@NgModule({
  declarations: [
    SurveyMainComponent,
    SurveyLinearComponent,
    SurveyTreeComponent,
    SurveyTreeVisualComponent,
    SurveyTreeResultComponent,
    SurveyTreeVisualPanComponent
  ],
  imports: [
    CommonModule,

    FlexLayoutModule,
    MomentModule,

    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatDividerModule,
    MatProgressBarModule,
    MatCheckboxModule,

    CommonUIElementsModule,

    SurveysRoutingModule
  ]
})
export class SurveysModule { }
