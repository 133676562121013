import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from 'src/app/services/api.service';
import { LoggingService } from 'src/app/services/logging.service';

import { Team } from 'src/app/models/teams/team';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService
  ) {}

  public fetch_all(): Observable<any> {
    const params = { expand: 'parent' };
    return this.api_service.get(`teams/team`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} teams`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch teams`
          );
        }
      )
    );
  }

  public fetch_by_uuid(team_uuid: string): Observable<any> {
    const params = { expand: 'parent,editors,verified_by' };
    return this.api_service.get(`teams/team/${team_uuid}`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched teams with id ${team_uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch team`
          );
        }
      )
    );
  }

  public create(team_data: any): Observable<any> {
    return this.api_service.post(`teams/team`, team_data, {}).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} created team with id ${response.uuid} and name ${response.name}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to create team`
          );
        }
      )
    );
  }

  public update(team_uuid: string, team_data: any): Observable<any> {
    return this.api_service
      .patch(`teams/team/${team_uuid}`, team_data, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} updated team with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to update team`
            );
          }
        )
      );
  }

  public delete(team_uuid: string): Observable<any> {
    return this.api_service.delete(`teams/team/${team_uuid}`, {}).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} deleted team with id ${team_uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to delete team`
          );
        }
      )
    );
  }

  public search(term: string): Observable<any> {
    if (!term.trim()) {
      return of([]);
    }
    return this.api_service.get(`teams/team`, { search: term }).pipe(
      tap((response) => {
        const teams = response.results as Team[];
        this.logging_service.debug(
          `${this.constructor.name} found ${teams.length} teams for search term=${term}`
        );
      })
    );
  }
}
