<div class="router-outlet-container">
  <ng-container *ngIf="is_data_loaded; then component_content; else preloader">
  </ng-container>
</div>

<ng-template #preloader>
  <app-preloader></app-preloader>
</ng-template>

<ng-template #component_content>
  <div class="app-admin-users-dashboard-container">
    <div class="app-admin-users-dashboard-header-container">
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
        <div fxFlex>
          <div class="app-admin-users-dashboard-header-title">Пользователи</div>
          <div class="app-admin-users-dashboard-header-subtitle"></div>
        </div>
        <div fxFlex="15rem" class="app-admin-users-dashboard-header-filter">
          <form [formGroup]="usersFilterForm">
            <mat-form-field
              floatLabel="never"
              appearance="outline"
              fxFlex="100"
            >
              <input
                matInput
                placeholder="Введите имя пользователя или email"
                type="text"
                autocomplete="off"
                formControlName="search"
                (keyup)="searchSubject()"
              />
            </mat-form-field>
          </form>
        </div>
        <div fxFlex="15rem">
          <div class="app-admin-users-dashboard-header-metadata">
            Всего пользователей: {{ this.filteredUsers.length }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="app-admin-users-dashboard-user-list-container">
    <ng-container
      *ngIf="
        filteredUsers.length > 10;
        then tmpl_users_with_page;
        else tmpl_users_without_page
      "
    >
    </ng-container>
  </div>
</ng-template>

<ng-template #tmpl_users_without_page>
  <div *ngFor="let user of filteredUsers">
    <div class="app-admin-users-dashboard-user-listitem">
      <div fxLayout="row">
        <div fxFlex="5rem">
          <div class="app-admin-users-dashboard-user-listitem-avatar">
            {{ util_user_get_initials_for_avatar(user) }}
          </div>
        </div>
        <div fxFlex="29rem">
          <div class="app-admin-users-dashboard-user-listitem-name">
            <a routerLink="/admin/users/{{ user.uuid }}">
              {{ user.last_name }} {{ user.first_name }}
            </a>
          </div>
          <div class="app-admin-users-dashboard-user-listitem-email">
            {{ user.email }}
          </div>
        </div>
        <div fxFlex>
          <div class="app-admin-users-dashboard-user-listitem-dates">
            <div>
              Юзер создан:
              {{ user.date_joined | amDateFormat: 'YYYY-MM-DD HH:mm' }}
            </div>
            <div>Был активен: {{ user.last_login | amTimeAgo }}</div>
          </div>
        </div>
        <div fxFlex="5rem">
          <div class="app-admin-users-dashboard-user-listitem-onboarding">
            <div>Онбординг</div>
            <div>
              <mat-checkbox
                [checked]="user.is_onboarding_complete"
                [disabled]="is_onboarding_checkbox_disabled"
                (click)="on_user_toggle_onboarding(user, $event)"
              >
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_users_with_page>
  <div fxLayout="column">
    <div *ngFor="let user of active_users_page_data_chunk">
      <div class="app-admin-users-dashboard-user-listitem">
        <div fxLayout="row">
          <div fxFlex="5rem">
            <div class="app-admin-users-dashboard-user-listitem-avatar">
              {{ util_user_get_initials_for_avatar(user) }}
            </div>
          </div>
          <div fxFlex="30rem">
            <div class="app-admin-users-dashboard-user-listitem-name">
              <a routerLink="/admin/users/{{ user.uuid }}">
                {{ user.last_name }} {{ user.first_name }}
              </a>
            </div>
            <div class="app-admin-users-dashboard-user-listitem-email">
              {{ user.email }}
            </div>
          </div>
          <div fxFlex>
            <div class="app-admin-users-dashboard-user-listitem-dates">
              <div>
                Юзер создан:
                {{ user.date_joined | amDateFormat: 'YYYY-MM-DD HH:mm' }}
              </div>
              <div>Был активен: {{ user.last_login | amTimeAgo }}</div>
            </div>
          </div>
          <div fxFlex="5rem">
            <div class="app-admin-users-dashboard-user-listitem-onboarding">
              <div>Онбординг</div>
              <div>
                <mat-checkbox
                  [checked]="user.is_onboarding_complete"
                  [disabled]="is_onboarding_checkbox_disabled"
                  (click)="on_user_toggle_onboarding(user, $event)"
                >
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mat-paginator
      fxFlex="100%"
      [length]="length_users"
      [pageSize]="page_size"
      [pageSizeOptions]="page_size_options"
      (page)="on_users_page_changed($event)"
    >
    </mat-paginator>
  </div>
</ng-template>
