import { Component, Input, OnInit } from '@angular/core';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { TeamMembershipService } from 'src/app/services/teams/team-membership.service';

import { User } from 'src/app/models/user/user';
import { TeamMembership } from 'src/app/models/teams/team-membership';

@Component({
  selector: 'app-admin-user-tab-teams',
  templateUrl: './admin-user-tab-teams.component.html',
  styleUrls: ['./admin-user-tab-teams.component.css'],
})
export class AdminUserTabTeamsComponent implements OnInit {
  @Input() user: User;

  public memberships: TeamMembership[] = [];

  private uistate = {
    memberships_loaded: false,
  };

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private membership_service: TeamMembershipService
  ) {}

  ngOnInit(): void {
    this.load_survey_assignments();
  }

  public get is_data_loaded(): boolean {
    return this.uistate.memberships_loaded;
  }

  private load_survey_assignments(): void {
    this.membership_service.fetch_by_user_uuid(this.user.uuid).subscribe(
      (response) => {
        this.memberships = response.results as TeamMembership[];
        this.uistate.memberships_loaded = true;
        this.logging_service.debug(
          `${this.constructor.name} loaded ${response.count} team memberships`
        );
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to load team memberships`
        );
        this.alert_service.error(
          `Ошибка загрузки команд пользователя: ${err.status}`
        );
      }
    );
  }

  public on_button_delete_team_membership(membership: TeamMembership): void {
    this.membership_service.delete(membership).subscribe(
      (response) => {
        this.logging_service.debug(
          `${this.constructor.name} deleted team membership`
        );
        this.memberships = this.memberships.filter(
          (m) => m.uuid !== membership.uuid
        );
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to delete team membership`
        );
        this.alert_service.error(
          `Ошибка удаления принадлежности к команде: ${err.status}`
        );
      }
    );
  }
}
