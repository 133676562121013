import { Component, OnInit } from '@angular/core';
import { testDataAttributes } from '@test-utils';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css'],
})
export class AdminDashboardComponent implements OnInit {
  public testDataAttributes = testDataAttributes;
  constructor(private _titleService: TitleService) {}

  ngOnInit(): void {
    this._titleService.set_title('Админка');
  }

  public get is_data_loaded(): boolean {
    return true;
  }
}
