<div fxLayout="row">
  <div fxFlex>
    <div id="content-container">
      <h1>Начало работы с сервисом</h1>

      <p>
        StarsMap – сервис для наглядного управления развитием компетенций на
        каждом уровне: сотрудника, команды, компании.
      </p>

      <div class="hint-container">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex="3rem">
            <mat-icon>help</mat-icon>
          </div>
          <div fxFlex>
            Эта инструкция поможет вам подготовить все необходимое для того,
            чтобы запустить управление компетенциями в своей команде.
          </div>
        </div>
      </div>

      <h2>Зачем это всё</h2>

      Практика управления компетенциями проходит через многие процессы в
      компании:
      <ul>
        <li>Найм, онбординг и испытательный срок</li>
        <li>
          Профессиональный рост сотрудника и его продвижение по карьерной
          лестнице
        </li>
        <li>
          Управление рисками, связанными с потерей или отсутствием ключевых
          компетенций
        </li>
        <li>Формирование команд под продукты/проекты/прочие активности</li>
        <li>Делегирование задач исполнителям</li>
        <li>Внутренние переходы из команды в команду</li>
        <li>Формирование повестки и приоритетов для внутренних сообществ</li>
      </ul>

      Явно или неявно, опираясь на существующие практики или по наитию, но мы
      решаем задачи, связанные с управлением компетенциями в ходе своей работы.

      <br /><br />

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            О классических подходах и их проблемах
          </mat-panel-title>
        </mat-expansion-panel-header>

        Одним из стандартных подходов является составление матрицы компетенций.
        Подход с классической матрицей компетенций позволяет проявить
        компетенции команды и принимать решения с учетом этой информации,
        однако, тут есть сложности:
        <ul>
          <li>
            Формирование матрицы компетенций, которая не будет формальностью, а
            поможет сформировать текущую картину с достаточной для принятия
            решений детализацией – долгий и трудозатратный процесс. Если
            подступаться к нему впервые без релевантного опыта, то первые
            результаты могут быть неудовлетворительными и не помогут достичь
            целей.
          </li>
          <li>
            В каждой команде профиль компетенций чаще всего отличается и
            получившиеся в командах матрицы компетенций отличаются по структуре,
            формулировкам, уровню детализации. Мало того, что каждая команда
            тратит свои ресурсы на этот трудоемкий процесс, так и отсутствие
            синхронизации модели компетенций и данных в ней делает практически
            невозможным какое-либо кросс-командное использование получившихся
            результатов.
          </li>

          <li>
            Подобная матрица компетенций – инструмент лида команды и не
            предоставляет ценности для других участников команды. В отсутствии
            интереса от участников команд нагрузка на актуализацию данных в
            матрице компетенций ложится на плечи лида, обычно и без того
            загруженного другими обязанностями и, как результат, мы получаем
            либо матрицу компетенций с неактуальными и непригодными для
            использования данными, либо лида, который значительное количество
            времени тратит на поддержание процесса актуализации через 1:1 с
            каждым сотрудником.
          </li>

          <li>
            Табличное представление не отображает динамики. Для принятия части
            решений этих данных может быть достаточно, но для задач планирования
            или ретроспективного взгляда нужна возможность посмотреть не только
            текущую картину, но и с учетом течения времени.
          </li>
        </ul>
      </mat-expansion-panel>

      <br />
      <br />

      <div fxLayout="row" fxLayoutGap="5%">
        <div fxFlex="100%">
          <mat-card>
            <mat-card-header>
              <mat-card-title>Матрица компетенций</mat-card-title>
            </mat-card-header>
            <img
              mat-card-image
              src="/assets/docs/tutorials/quckstart/team-matrix.png"
              alt="Матрица компетенций"
            />
          </mat-card>
        </div>
      </div>

      <div class="chapter">
        <h1>Начало работы</h1>

        Перед тем, как проявить компетенции в команде нужно подготовить данные в
        Starsmap.
        <ol>
          <li>Создать команду</li>
          <li>
            Добавить в команду специальности из библиотеки и настроить их под
            вашу специфику
          </li>
          <li>Пригласить участников в команду</li>
          <li>Провести ревью компетенций для участников команды</li>
          <li>Составить планы развития для участников команды</li>
        </ol>

        Ниже приведено подробное описание каждого шага.
      </div>

      <div class="chapter">
        <h2>Создание команд</h2>

        <div fxLayout="row" fxLayoutGap="5%">
          <div fxFlex="45%">
            <p>
              Если вы не нашли своей команды на онбординге или в Структуре
              компании, то вы можете создать ее самостоятельно.
            </p>
            <p>
              В командах есть система ролей: руководители, лиды и участники.
              Руководители и лиды имеют возможность управлять командой:
              добавлять и удалять участников, создавать специальности, запускать
              процессы ревью и т.д.
            </p>
          </div>
          <div fxFlex="45%">
            <mat-card>
              <mat-card-header>
                <mat-card-title>Пример структуры команд</mat-card-title>
              </mat-card-header>
              <img
                mat-card-image
                src="/assets/docs/tutorials/quckstart/team-structure.png"
                alt="Структура команд"
              />
            </mat-card>
          </div>
        </div>
      </div>

      <div class="chapter">
        <h2>Описание модели компетенций команды</h2>

        <p>
          Набор компетенций может отличаться от команды к команде. Безусловно,
          есть и общие компетенции: большинство из нас работает с Jira и
          отличает Scrum от Kanban, многим полезно владеть навыками подготовки
          презентаций, а есть и личностные качества, такие как эмоциональный
          интеллект.
        </p>

        <p>
          В Starsmap есть общая библиотека компетенций и специальностей, которые
          вы можете использовать для своих команд.
        </p>

        <div>
          Модель компетенций компании состоит из:
          <ul>
            <li>
              Структуры доменов компетенций (области знаний) – структура
              помогает упорядочить и сгруппировать компетенции и визуализировать
              их
            </li>
            <li>Конкретные компетенции – инструменты, практики и т.д.</li>
            <li>Уровни владения каждой компетенцией</li>
          </ul>
        </div>

        <div>
          <h4>Рекомендации по описанию компетенций</h4>
          <p>
            При составлении модели компетенций очень важно уделить внимание
            формулировкам и правильно обернуть в слова то, с чем вам приходится
            сталкиваться в работе.
          </p>

          Вот несколько рекомендаций, которые могут помочь вам составить хорошую
          модель:
          <ul>
            <li>Компетенции должны быть краткими и однозначными</li>
            <li>
              Описывать реальные объекты/инструменты/практики/качества, а не то,
              что с ними можно сделать
            </li>
            <li>
              Структурировать и группировать компетенции так, как они есть в
              реальном мире, а не так, как они сгруппированы в рамках
              какого-либо процесса
            </li>
          </ul>

          Все это поможет вашим коллегам лучше понять, что от них ожидается и
          повысит качество данных в системе.
          <br />
          Вам же, в дальнейшем, будет проще развивать и расширять модель
          компетенций.
        </div>

        <br />

        <div fxLayout="row" fxLayoutGap="5%">
          <div fxFlex="45%" fxLayoutAlign="center start">
            <div fxLayout="column">
              <div fxFlex>
                <div
                  class="
                    examples-row examples-row-header examples-row-header-good
                  "
                >
                  Хорошо
                </div>
              </div>

              <div fxFlex>
                <div class="examples-row examples-row-content">
                  Atlassian Jira
                  <br />
                  +
                  <br />
                  Декомпозиция задач
                </div>
              </div>

              <div fxFlex>
                <div class="examples-row examples-row-content">
                  Scrum
                  <br />
                  <br />
                </div>
              </div>

              <div fxFlex>
                <div class="examples-row examples-row-content">
                  PostgreSQL
                  <br />
                  +
                  <br />
                  JDBC
                </div>
              </div>

              <div fxFlex>
                <div class="examples-row examples-row-content">
                  Непрерывная поставка (CI/CD)
                  <br />
                  +
                  <br />
                  Jenkins
                  <br />
                  +
                  <br />
                  Docker
                </div>
              </div>
            </div>
          </div>

          <div fxFlex="45%" fxLayoutAlign="center start">
            <div fxLayout="column">
              <div fxFlex>
                <div
                  class="
                    examples-row examples-row-header examples-row-header-bad
                  "
                >
                  Плохо
                </div>
              </div>

              <div fxFlex>
                <div class="examples-row examples-row-content">
                  Заведение тикетов
                  <br />
                  <br />
                  <br />
                </div>
              </div>

              <div fxFlex>
                <div class="examples-row examples-row-content">
                  Выполнение работ в рамках принятого в команде процесса
                </div>
              </div>

              <div fxFlex>
                <div class="examples-row examples-row-content">
                  Работа с базами данных
                  <br />
                  <br />
                  <br />
                </div>
              </div>

              <div fxFlex>
                <div class="examples-row examples-row-content">
                  Умение собирать и выкатывать приложение
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />
      </div>

      <div class="chapter">
        <h3>Уровни владения компетенцией</h3>

        <div fxLayout="row" fxLayoutGap="5%">
          <div fxFlex="100%">
            <p>
              Пользователи могут отметить свой уровень владения компетенцией
              выбрав один из вариантов.
            </p>

            <p>
              Что касается уровней, которые создаются по-умолчанию:
              <br />
              Изучив общемировые практики мы пришли к выводу, что больший выбор
              для пользователей избыточен и сбивает с толку, плюс, всегда
              появляется желание поставить себя посередине.
              <br />
              <br />
              <strong>Не владеет</strong> – Пока не приходилось сталкиваться с
              этим в реальных задачах
              <br />
              <br />
              <strong>Начинающий</strong> – Начинает изучать
              тему/область/инструмент, но еще не было возможности применить на
              практике или применялось очень мало/точечно, нужна
              консультация/помощь коллег
              <br />
              <br />
              <strong>Базовый</strong> – Знает основы, применял на практике,
              может самостоятельно пользоваться в базовых случаях, точечно
              обращается за консультацией к коллегам
              <br />
              <br />
              <strong>Уверенный</strong> – Хорошо ориентируется в
              теме/области/инструменте, может самостоятельно решить задачу,
              иногда требуется помощь эксперта в решении
              <br />
              <br />
              <strong>Экспертный</strong> – Знает тему/область/инструмент от и
              до, может самостоятельно решить любую задачу, консультирует и
              помогает коллег по этому
            </p>
          </div>
        </div>

        <div class="chapter">
          <h2>Специальности</h2>

          <p>
            Каждый пользователь может выбрать одну или более специальностей.
          </p>

          <div fxLayout="row" fxLayoutGap="5%">
            <div fxFlex="45%">
              <p>
                У каждой специальности разный набор компетенций, который нужен в
                работе. Конечно же, какие-то компетенции могут быть общими между
                специальностями.
              </p>

              <p>
                Если представить классическую продуктовую команду, то в ней
                могут быть специальности:
              </p>

              <ul>
                <li>Software developer (разработчик)</li>
                <li>QA engineer (тестировщик)</li>
                <li>Infrastructure engineer (инженер эксплуатации)</li>
                <li>Аналитик</li>
                <li>Product owner (владелец продукта)</li>
              </ul>

              <p>
                Для каждой специальности существуют уровни развития, например
                Intern/Junior/Middle/Senior/Lead/Head.
              </p>
            </div>
            <div fxFlex="45%">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Пример специальностей</mat-card-title>
                </mat-card-header>
                <img
                  mat-card-image
                  src="/assets/docs/tutorials/quckstart/specialities.png"
                  alt="Пример специальностей"
                />
              </mat-card>
            </div>
          </div>

          <h3>Профиль компетенций специальности</h3>

          <div fxLayout="row" fxLayoutGap="5%">
            <div fxFlex="45%">
              <p>
                Связать компетенции и специальности можно в меню управления
                командой
              </p>
              <p>
                На вкладке Профиль компетенций выберите нужные вам компетенции
                из библиотеки или введите название компетенции в строку поиска
              </p>
            </div>
            <div fxFlex="45%">
              <mat-card>
                <mat-card-header>
                  <mat-card-title
                    >Добавление компетенций в специальность</mat-card-title
                  >
                </mat-card-header>
                <img
                  mat-card-image
                  src="/assets/docs/tutorials/quckstart/speciality-profile-editor.png"
                  alt="Добавление компетенций в специальность"
                />
              </mat-card>
            </div>
          </div>

          <h3>Разные требования для разных специальностей</h3>

          <div fxLayout="row" fxLayoutGap="5%">
            <div fxFlex="45%">
              <p>
                Компетенции, требуемые для каждой специальности могут как
                отличаться, так и пересекаться.
              </p>
              <p>Для примера, возьмем разработчика и инженера эксплуатации.</p>
              <p>
                Они оба работают с базой данных приложения, но, если
                разработчику нужно знать базу данных на уровне использования и
                написания запросов, то инженеру эксплуатации нужно знать эту же
                базу данных на более глубоком уровне для обеспечения
                отказоустойчивости, конфигурации и оптимизации
                производительности.
              </p>
              <p>
                Таким образом, например, можно сказать, что мы ожидаем от
                Разработчика уровня владения PostgreSQL "Уверенный", а от
                инженера эксплуатации ожидаем знаний на уровене PostgreSQL
                "Эксперт".
              </p>

              <p>
                Но тут мы еще вспоминаем, что есть Intern/Junior/Middle/Senior/Lead/Head
                разработчики и было бы неправильным ожидать, что junior должен
                знать PostgreSQL на высоком уровне.
              </p>
            </div>
            <div fxFlex="45%">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Требования по уровню владения</mat-card-title>
                </mat-card-header>
                <img
                  mat-card-image
                  src="/assets/docs/tutorials/quckstart/speciality-profile-claims.png"
                  alt="Требования к уровню владения"
                />
              </mat-card>
            </div>
          </div>
        </div>

        <div class="chapter">
          <h2>Ревью компетенций</h2>

          <p>
            Для актуализации данных о компетенциях участников своих команд и их
            верификации другими пользователями вы можете использовать механику
            ревью.
          </p>

          <p>
            Навыки пользователей, которые были верифицированны на ревью
            помечаются иконкой
            <mat-icon style="color: red">done_outline</mat-icon>
          </p>

          <div fxLayout="row" fxLayoutGap="5%">
            <div fxFlex="45%">
              <p>
                В интерфейсе управления командой есть возможность запустить
                процесс ревью компетенций для участника команды.
              </p>
              <p>У ревью есть несколько ролей:</p>
              <ul>
                <li>участник – пользователь, чьи навыки оценивают</li>
                <li>
                  ревьюер – пользователь, кто может оценить уровень владения
                  навыка
                </li>
                <li>
                  ответственный – руководитель команды, который управляет
                  процессом ревью, может его завершить и разрешить конфликты в
                  оценках участика ревью и ревьеров
                </li>
              </ul>
              <p>
                Ответственным могут быть только руководители и лиды команды,
                участниками – все пользователи команды, а ревьюерами можно
                приглашать в том числе и коллеги из других команд, например,
                коллег, кто совместно работал и имеет представление об уровне
                части компетенций.
              </p>
            </div>
            <div fxFlex="45%">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Процесс ревью в команде</mat-card-title>
                </mat-card-header>
                <img
                  mat-card-image
                  src="/assets/docs/tutorials/quckstart/review-details.png"
                  alt="Ревью команды"
                />
              </mat-card>
            </div>
          </div>

          <p>
            В ходе ревью у вас будет возможность оставить комментарий к оценке
            навыка, комментарии по-умолчанию видны только ответственному за
            ревью руководителю.
          </p>
          <p>
            Ответственный за ревью, дождавшись участия всех или части
            приглашенных может завершить ревью, при этом результаты ревью
            становятся доступными для просмотра (не всем, а только участникам)
          </p>
          <p>
            Могут быть сиутации, когда мнения участников ревью расходятся, в
            этом случае ответственному нужно явно разрешить конфликты мнений на
            этапе Финазилизации.
          </p>
        </div>

        <div class="chapter">
          <h2>Индивидуальные планы развития</h2>
          <div fxLayout="row" fxLayoutGap="5%">
            <div fxFlex="45%">
              <p>
                Получив представление о текущем состоянии компетенций и навыках,
                которые пользователи отметили как "желаемые к развитию", можно
                дополнить эту картину знанием о целях команды и компании,
                дополнить контекстом (о ключевых навыках, о навыках, которые в
                текущий момент имеются только у кого-то одного в команде итд) и
                можно приступать к составлению ИПР для участников команды.
              </p>

              <p>
                Раз уж мы говорим про развитие в контексте компетенций, то стоит
                отметить, что Starsmap позволяет привязать конкретные
                компетенции к пунктам в ИПР. Таким образом можно получить
                представление о векторе развития всей команды и поддерживать
                данные об уровнях владения компетенциями в актуальном состоянии
                – Starsmap знает, какие компетенции из всего множества
                предложить актуализировать пользователю.
              </p>
              <p>Создание ИПР разделено на два этапа:</p>
              <ul>
                <li>
                  Описание цели и результатов. Важно отметить, что результаты
                  стоит сформулировать "из двух миров": 1) что даст достижение
                  цели участнику команды 2) что даст достижение цели команде или
                  компании
                </li>
                <li>
                  Описание областей для развития, в каждой из которых может быть
                  множество шагов, выполнение которых позволит достичь цели.
                  Каждый такой шаг может быть связан с одной или несколькими
                  компетенциями, а может и не привязываться к компетенциям, если
                  этого не требуется.
                </li>
              </ul>
            </div>
            <div fxFlex="45%">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Пример плана развития</mat-card-title>
                </mat-card-header>
                <img
                  mat-card-image
                  src="/assets/docs/tutorials/quckstart/personal-development-plan.png"
                  alt="План развития"
                />
              </mat-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
