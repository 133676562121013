<app-with-processing [processing]='!isUserDirectionsDomainsLoaded'>
  <div *ngIf='isUserDirectionsDomainsLoaded'>
    <div class='domain-tree-buttons' *ngIf='isCreateHighLevelDomainButtonShow'>
      <button
        mat-stroked-button
        (click)="setDetailFormConfig('root', 'create', false)"
      >
        <mat-icon>create_new_folder</mat-icon>
        Добавить домен верхнего уровня
      </button>
    </div>
    <mat-tree
      [dataSource]='dataSource'
      [treeControl]='treeControl'
      class='domains-tree'
      fxLayout='column'
    >
      <mat-tree-node
        *matTreeNodeDef='let node'
        matTreeNodePadding
        fxFlex
        fxLayout='row'
      >
        <button mat-icon-button disabled></button>
        <div
          *ngIf='node.isThing'
          class='thing-item'
          fxFlex
          fxLayout='row'
          fxLayoutAlign='start center'
        >
          <a class='thing-link' (click)='redirectToThing(node)' fxFlex='90'>{{
            node.name
            }}</a>
          <span
            *ngIf='isEditable || isDirectionDomainAdmin(node.parent_domain)'
            class='thing-icons'
            fxFlex='10'
            fxLayoutAlign='end center'
          >
            <button
              mat-icon-button
              (click)="setDetailFormConfig(node.uuid, 'update', true)"
              matTooltip='Редактировать'
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)='remove(node)' matTooltip='Удалить'>
              <mat-icon>delete</mat-icon>
            </button>
          </span>
        </div>
        <div *ngIf='!node.isThing' class='domain-item' fxLayout='row'
             fxFlex
             fxLayoutAlign='start center'>
          <a class='domain-link' fxFlex='90'>{{ node.name }}</a>
          <span
            class='domain-item__icons'
            fxFlex
            fxLayoutAlign='end center'
            *ngIf='isEditable || isDirectionDomainAdmin(node.uuid)'
          >
            <button
              mat-icon-button
              matTooltip='Добавить компетенцию в домен'
              (click)="setDetailFormConfig(node.uuid, 'create', true)"
            >
              <mat-icon>add</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="setDetailFormConfig(node.uuid, 'create', false)"
              matTooltip='Добавить дочерний домен'
            >
              <mat-icon>create_new_folder</mat-icon>
            </button>
            <button mat-icon-button [matMenuTriggerFor]='domainMenu'>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #domainMenu='matMenu'>
              <button
                mat-menu-item
                (click)="setDetailFormConfig(node.uuid, 'update', false)"
              >
                <mat-icon>edit</mat-icon>
                Редактировать
              </button>
              <button mat-menu-item (click)='remove(node)'>
                <mat-icon>delete</mat-icon>
                Удалить домен
              </button>
            </mat-menu>
          </span>
        </div>
      </mat-tree-node>
      <mat-tree-node
        *matTreeNodeDef='let node; when: hasChild'
        matTreeNodePadding
        fxFlex
        fxLayout='row'
      >
        <div
          class='domain-item'
          [ngClass]="treeControl.isExpanded(node) ? 'domain-item_expanded' : ''"
          fxLayout='row'
          fxFlex
          fxLayoutAlign='start center'
        >
          <button
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name"
          >
            <mat-icon class='mat-icon-rtl-mirror'>
              {{
              treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'
              }}
            </mat-icon>
          </button>
          <a
            class='.domain-item__name'
            fxFlex='90'
          >{{ node.name }}</a
          >
          <span
            *ngIf='isEditable || isDirectionDomainAdmin(node.uuid)'
            class='domain-item__icons'
            fxFlex='10'
            fxLayoutAlign='end center'
          >
            <button
              mat-icon-button
              (click)="setDetailFormConfig(node.uuid, 'create', true)"
              matTooltip='Добавить компетенцию в домен'
            >
              <mat-icon>add</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="setDetailFormConfig(node.uuid, 'create', false)"
              matTooltip='Добавить дочерний домен'
            >
              <mat-icon>create_new_folder</mat-icon>
            </button>
            <button mat-icon-button [matMenuTriggerFor]='domainMenu'>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #domainMenu='matMenu'>
              <button
                mat-menu-item
                (click)="setDetailFormConfig(node.uuid, 'update', false)"
              >
                <mat-icon>edit</mat-icon>
                Редактировать
              </button>
              <button mat-menu-item (click)='remove(node)'>
                <mat-icon>delete</mat-icon>
                Удалить домен
              </button>
            </mat-menu>
          </span>
        </div>
      </mat-tree-node>
    </mat-tree>
  </div>
</app-with-processing>
