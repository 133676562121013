<div *ngIf='userDevelopmentPlans.state.isLoaded' class='user-pdp-container'>
  <div class='user-pdp-title'>План развития</div>
  <mat-tab-group [animationDuration]="'0ms'" mat-stretch-tabs='false'>
    <mat-tab label='Текущие'>
      <div>
        <div
          fxLayoutAlign='start center'
          class='pdp-button-container'
          (click)='onDevelopmentPlanAdd()'
        >
          <button
            mat-raised-button
            class='add-pdp-button'
            color='primary'
          >
            <mat-icon>add_circle_outline</mat-icon>
          </button>
          <span class="add-pdp-button-label">Добавить цель</span>
        </div>
        <div fxLayout='column' fxLayoutGap='8px'>
          <div
            fxLayout='row'
            fxLayoutAlign='start center'
            class='development-container'
            *ngFor='let developmentPlan of ongoingPlans'
            (click)='onDevelopmentPlanClick(developmentPlan)'
          >
            <ng-container
              [ngTemplateOutlet]='pdp'
              [ngTemplateOutletContext]='{ pdp: developmentPlan }'
            ></ng-container>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label='Помогаю в планах'>
      <br />
      <div *ngIf='!responsiblePlans.length' class='pdp-container-empty'>
        Нет данных для отображения
      </div>
      <div fxLayout='column' fxLayoutGap='8px'>
        <div
          fxLayout='row'
          fxLayoutAlign='start center'
          class='development-container'
          *ngFor='let developmentPlan of responsiblePlans'
          (click)='onDevelopmentPlanClick(developmentPlan)'
        >
          <ng-container
            [ngTemplateOutlet]='pdp'
            [ngTemplateOutletContext]='{ pdp: developmentPlan }'
          ></ng-container>
        </div>
      </div>
    </mat-tab>
    <mat-tab label='Завершенные'>
      <br />
      <div *ngIf='!completedPlans.length' class='pdp-container-empty'>
        Нет данных для отображения
      </div>
      <div fxLayout='column' fxLayoutGap='8px'>
        <div
          fxLayout='row'
          fxLayoutAlign='start center'
          class='development-container'
          *ngFor='let developmentPlan of completedPlans'
          (click)='onDevelopmentPlanClick(developmentPlan)'
        >
          <ng-container
            [ngTemplateOutlet]='pdp'
            [ngTemplateOutletContext]='{ pdp: developmentPlan }'
          ></ng-container>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #pdp let-pdp='pdp'>
  <div fxFlex='30' fxLayout='column' fxLayoutGap='8px'>
    <span class='development-container-text'>{{ pdp.objective }}</span>
    <div fxLayout='row' fxLayoutGap='8px' fxLayoutAlign='start center'>
      <mat-progress-bar [value]='pdp.completion_percentage'></mat-progress-bar>
      <span>{{ pdp.completion_percentage + '%' }}</span>
    </div>
  </div>
  <div fxFlex='40'></div>
  <span
    fxFlex='30'
    fxLayoutAlign='center center'
    class='development-container-text'
  >{{ getPrettyDevelopmentDate(pdp) }}</span
  >
</ng-template>
