<div class="app-track-pdp-create-container">

    <div fxLayout="row">
        <div fxFlex>
            <div class="app-track-pdp-create-header">
                <div *ngIf="is_subject_is_self">
                    Ваш план развития
                </div>
                <div *ngIf="!is_subject_is_self">
                    План развития для {{ subject_user.first_name }} {{ subject_user.last_name }}
                </div>
            </div>
        </div>
    </div>

    <div fxLayout="row">
        <div fxFlex>
            <form [formGroup]="pdp_create_form">

                <div fxLayout="row">
                    <div fxFlex="50%">
                        <mat-form-field [style.width.rem]=30>
                            <mat-label>Ваша цель</mat-label>
                            <input #form_input_objective matInput formControlName="objective"
                                type="text" placeholder="Прокачать навыки найма">
                            <mat-hint>{{ 128 - form_input_objective.value.length }}/128</mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxFlex="40%" fxFlexOffset="10%">
                        <div class="app-track-pdp-create-form-input-description">
                            Коротко и ясно, чего вы хотите добиться?
                        </div>
                    </div>
                </div>

                <br>

                <div fxLayout="row">
                    <div fxFlex="50%">
                        <mat-form-field [style.width.rem]=30>
                            <mat-label>Как я пойму, что цель достигнута?</mat-label>
                            <textarea #form_input_accomplishment_indicator matInput formControlName="accomplishment_indicator"
                                type="text" placeholder="Малый отток после испыталки и хорошая обратная связь от кандидатов"></textarea>
                            <mat-hint>{{ 1024 - form_input_accomplishment_indicator.value.length }}/1024</mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxFlex="40%" fxFlexOffset="10%">
                        <div class="app-track-pdp-create-form-input-description">
                            Какой-либо измеримый результат, который вы сможете предоставить на ревью коллегам
                        </div>
                    </div>
                </div>

                <br>

                <div fxLayout="row">
                    <div fxFlex="50%">
                        <mat-form-field [style.width.rem]=30>
                            <mat-label>Как достижение цели поможет тебе лично?</mat-label>
                            <textarea #form_input_personal_value matInput formControlName="personal_value"
                                type="text" placeholder="Прокачаю soft-skills, продвинусь в сторону лида"></textarea>
                            <mat-hint>{{ 1024 - form_input_personal_value.value.length }}/1024</mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxFlex="40%" fxFlexOffset="10%">
                        <div class="app-track-pdp-create-form-input-description">
                            Персональные интересы, которые хочется реализовать
                        </div>
                    </div>
                </div>

                <br>

                <div fxLayout="row">
                    <div fxFlex="50%">
                        <mat-form-field [style.width.rem]=30>
                            <mat-label>Как достижение цели поможет компании?</mat-label>
                            <textarea #form_input_organisation_value matInput formControlName="organisation_value"
                                type="text" placeholder="Наша команда станет сильнее и сбалансированнее, будем быстрее делать задачи"></textarea>
                            <mat-hint>{{ 1024 - form_input_organisation_value.value.length }}/1024</mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxFlex="40%" fxFlexOffset="10%">
                        <div class="app-track-pdp-create-form-input-description">
                            Измеримая польза, которую команда и компания получит при достижении цели
                        </div>
                    </div>
                </div>

                <br>

                <div fxLayout="row">
                    <div fxFlex="50%">
                        <div fxLayout="row">
                            <div fxFlex="45%">
                                <mat-form-field>
                                    <mat-label>Дата достижения цели</mat-label>
                                    <input #form_input_due_date matInput formControlName="due_date"
                                            [matDatepicker]="form_input_due_date_picker"
                                            [min]="pdp_create_form_input_due_date_min" [max]="pdp_create_form_input_due_date_max"
                                            placeholder="ДД.ММ.ГГГГ">
                                    <mat-datepicker-toggle matSuffix [for]="form_input_due_date_picker"></mat-datepicker-toggle>
                                    <mat-datepicker #form_input_due_date_picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div fxFlex="45%" fxFlexOffset="10%">
                                <span class="app-track-pdp-create-form-input-due-date-shortcut"
                                    (click)="on_set_due_date(7 * 24 * 60 * 60 * 1000)">
                                    неделя,
                                </span>
                                &nbsp;
                                <span class="app-track-pdp-create-form-input-due-date-shortcut"
                                    (click)="on_set_due_date(2 * 7 * 24 * 60 * 60 * 1000)">
                                    две недели,
                                </span>
                                &nbsp;
                                <span class="app-track-pdp-create-form-input-due-date-shortcut"
                                    (click)="on_set_due_date(30 * 24 * 60 * 60 * 1000)">
                                    месяц,
                                </span>
                                <br>
                                <span class="app-track-pdp-create-form-input-due-date-shortcut"
                                    (click)="on_set_due_date(2 * 30 * 24 * 60 * 60 * 1000)">
                                    два месяца,
                                </span>
                                &nbsp;
                                <span class="app-track-pdp-create-form-input-due-date-shortcut"
                                    (click)="on_set_due_date(4 * 30 * 24 * 60 * 60 * 1000)">
                                    квартал
                                </span>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="40%" fxFlexOffset="10%">
                        <div class="app-track-pdp-create-form-input-description">
                        </div>
                    </div>
                </div>

                <br>

                <div fxLayout="row">
                    <div fxFlex="50%">
                        <ng-container *ngIf="responsible_user_search_selected_user; then tmpl_responsible_user_selected; else tmpl_responsible_user_search">
                        </ng-container>
                        <ng-template #tmpl_responsible_user_search>
                            <mat-form-field [style.width.rem]=30>
                                <mat-label>Поиск пользователей</mat-label>
                                <input #form_input_responsible_user matInput
                                    type="text"
                                    placeholder="Имя или email"
                                    [formControl]="responsible_user_search_form_control"
                                    [matAutocomplete]="auto"
                                    (input)="on_responsible_user_search(form_input_responsible_user.value)">
                                <mat-autocomplete #auto="matAutocomplete"
                                    [displayWith]="util_responsible_user_search_display"
                                    (optionSelected)="on_responsible_user_search_autocomplete_selected($event.option.value)">
                                    <mat-option *ngFor="let user of responsible_user_search_results"
                                        [value]="user" [disabled]="subject_user.uuid === user.uuid">
                                        {{ user.first_name }} {{ user.last_name }} – <small>{{ user.email }}</small>
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>

                            <div class="app-track-pdp-create-superiors-container">
                                <span *ngFor="let superior_user of superiors"
                                    class="app-track-pdp-create-superiors-listitem"
                                    (click)="on_set_responsible_user(superior_user)">
                                    {{ superior_user.first_name }} {{ superior_user.last_name }}
                                </span>
                            </div>

                        </ng-template>
                        <ng-template #tmpl_responsible_user_selected>
                            <div class="app-track-pdp-create-user-container">
                                <div fxLayout="row">
                                    <div fxFlex="5rem">
                                        <div class="app-track-pdp-create-user-avatar">
                                            {{ util_member_get_initials_for_avatar(responsible_user_search_selected_user) }}
                                        </div>
                                    </div>
                                    <div fxFlex="23rem">
                                        <div class="app-track-pdp-create-user-name">
                                            {{ responsible_user_search_selected_user.first_name }} {{ responsible_user_search_selected_user.last_name }}
                                        </div>
                                        <div class="app-track-pdp-create-user-email">
                                            {{ responsible_user_search_selected_user.email }}
                                        </div>
                                    </div>
                                    <div fxFlex="3rem">
                                        <button mat-icon-button (click)="on_responsible_user_clear()">
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <div fxFlex="40%" fxFlexOffset="10%">
                        <div class="app-track-pdp-create-form-input-description">
                            Кто будет помогать и направлять на пути к цели
                        </div>
                    </div>
                </div>

                <br><br>

                <button mat-raised-button
                    [disabled]="pdp_create_form.invalid || is_form_pdp_create_loading"
                    (click)="on_pdp_create_form_submit()">
                    Сохранить и перейти к заполнению конкретных шагов
                </button>

            </form>

        </div>
    </div>

</div>



