<div class="router-outlet-container">
    <ng-container *ngIf="is_data_loaded; then component_content; else preloader">
    </ng-container>
</div>

<ng-template #preloader>
    <app-preloader></app-preloader>
</ng-template>

<ng-template #component_content>
    <div class="app-survey-result-tree-container">

        <div class="app-survey-result-tree-header-container">
            <div fxLayout="row">
                <div fxFlex="60%" fxFlexOffset="2rem">
                    <div class="app-survey-result-tree-header-name">
                        <span class="app-survey-result-tree-header-name-prefix">
                            Опрос
                        </span>
                        {{ survey.name }}
                    </div>
                    <div class="app-survey-result-tree-header-description">
                        {{ survey.description }}
                    </div>
                    <div class="app-survey-result-tree-header-user">
                        <span class="app-survey-result-tree-header-name-prefix">
                            Сотрудник:
                        </span>
                        {{ user.first_name }} {{ user.last_name }}
                    </div>
                </div>
                <div fxFlex fxFlexOffset="2rem">
                    <div>
                        <a mat-stroked-button routerLink="/surveys/survey/{{ survey.uuid }}">
                            Пройти опрос
                        </a>
                        <br>
                        <br>
                    </div>
                    <div>
                        <mat-checkbox
                            [color]="ui_checkbox_color"
                            [checked]="visual_controls_show_noskill"
                            (click)="on_toggle_visual_controls_show_noskill($event)">
                            Скрыть компетенции без навыка
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox
                            [color]="ui_checkbox_color"
                            [checked]="visual_controls_show_groupnames"
                            (click)="on_toggle_visual_controls_show_groupnames($event)">
                            Отображать названия групп навыков
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <br>
            <br>
            <div>
                <app-survey-tree-visual
                    [questions]="survey_questions"
                    [user_competencies]="competencies"
                    [config]="svg_config"
                    [visual_control_subject]="visual_control_subject">
                </app-survey-tree-visual>
            </div>
        </div>
    </div>
</ng-template>