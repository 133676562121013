import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewSetHttpService } from './rest/ViewSetHttpService';
import { DailyActiveUsers } from '@models/stats/daily-active-users';

@Injectable({
  providedIn: 'root',
})
export class DailyActiveUsersHttpService extends ViewSetHttpService<DailyActiveUsers> {
  http: HttpClient;
  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }
  public viewSetUrl = 'stats/daily_active_users';
}
