import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { ThingHttpService } from '@services/http/ThingHttpService';
import { AsyncList } from '@rest/AsyncList';
import { Domain } from '@models/ontology/domain';

@Component({
  selector: 'app-add-library-competence',
  templateUrl: './add-library-competence.component.html',
  styleUrls: ['./add-library-competence.component.css'],
})
export class AddLibraryCompetenceComponent implements OnInit {
  form: UntypedFormGroup;
  domains: AsyncList<Domain>;

  selectedDomain: Domain;

  constructor(
    public dialogRef: MatDialogRef<AddLibraryCompetenceComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _domainHttpService: DomainHttpService,
    private _thingHttpService: ThingHttpService
  ) {
    this.domains = new AsyncList(this._domainHttpService);

    this.domains.setRequestParams({
      params: {
        team__isnull: 'True',
      },
    });

    this.domains.load();
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      search: ['', []],
      name: ['', []],
      description: ['', []],
    });
  }

  search(search: string): void {
    this.domains.setRequestParams({
      params: {
        search,
        team__isnull: 'True',
      },
    });
  }

  select(selection: Domain): void {
    this.selectedDomain = selection;
  }

  close(): void {
    this._thingHttpService
      .create({
        name: this.form.controls.name.value,
        description: this.form.controls.description.value,
        domain: this.selectedDomain.uuid,
      })
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }
}
