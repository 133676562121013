import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/models/user/user';
import { testDataAttributes } from '@test-utils';

// const testDataAttributes = {
//   accountPanelNameId: 'account-panel-name',
// };

@Component({
  selector: 'app-user-inline',
  templateUrl: './user-inline.component.html',
  styleUrls: ['./user-inline.component.css'],
})
export class UserInlineComponent implements OnInit {
  @Input() user: User;
  public testDataAttributes = testDataAttributes;

  constructor() {}

  ngOnInit(): void {}

  public get initials_for_avatar(): string {
    const first_name_initial = this.user.first_name.charAt(0);
    const last_name_initial = this.user.last_name.charAt(0);
    return `${first_name_initial}${last_name_initial}`;
  }
}
