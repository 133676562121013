import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewSetHttpService } from './rest/ViewSetHttpService';
import { User } from 'src/app/models/user/user';

@Injectable({
  providedIn: 'root',
})
export class UserHttpService extends ViewSetHttpService<User> {
  constructor(_http: HttpClient) {
    super(_http);
  }
  public viewSetUrl = 'users/user';
}
