<div class="router-outlet-container">
  <div class="app-admin-system-releasenotes-container">
    <div class="app-admin-system-releasenotes-header-container">
      <div fxLayout="row">
        <div fxFlex>
          <div class="app-admin-system-releasenotes-header-title">
            Информация о релизах
          </div>
          <div class="app-admin-system-releasenotes-header-description">
            Описание инкрементов функционала и исправлений дефектов
          </div>
        </div>
        <div fxFlex="20rem">
          <div class="app-admin-system-releasenotes-header-warning">
            <!-- Сообщить об ошибке <br> Фичреквест -->
          </div>
        </div>
      </div>
    </div>

    <div class="app-admin-system-releasenotes-content-container">
      <div fxLayout="row">
        <div fxFlex>
          <div class="app-admin-system-releasenotes-version-title">
            1.6.0 – первая версия библиотеки компетенций
          </div>

          <div class="app-admin-system-releasenotes-version-description">
            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              <p>
                <strong>Библиотека компетенций</strong>
              </p>
              <p>
                Существует множество общих для команд компетенций, таких как
                управленческие, личностные и базовые технические. До этотого
                момента управлять компенетциями и их группами можно было только
                в рамках команд или же в административном интерфейсе из-за чего
                командам приходилось дублировать сущности.
                <br />
                Помимо лишней работы это также затрудняло возможность найти
                сотрудника с требуемой компетенцией в другой команде, ведь
                технически информация была привязана к разным сущностям в разных
                командах.
                <br />
                Сейчас появилась возможность создавать компетенции любым
                пользователем системы и каждая команда может использовать
                библиотечные компетенции для описания требований к своим
                специальностям.
              </p>
              <ul>
                <li>
                  Теперь есть поиск позволяет найти компетенцию, группу
                  компетенций или специальность
                </li>
                <li>
                  Появился редактор для описания структуры компетенций в
                  библиотеке
                </li>
                <li>
                  У каждой компетенции теперь есть своя страничка, на которой
                  можно увидеть пользователей, владеющих этой компетенцией,
                  гистаграмму адаптации и график, отображающий тренд освоения
                </li>
              </ul>

              <p>
                <strong>
                  Возможность отметить свой уровень развития и желание
                  прокачаться
                </strong>
              </p>
              <ul>
                <li>
                  На странице компетенции можно отметить уровень владения
                  конкретной компетенцией без прохождения опроса
                </li>
                <li>
                  На той же страничке теперь можно отметить и свое желание
                  развиваться в этой компетенции
                </li>
              </ul>

              <p>
                <strong>
                  В интерфейсах появились ссылки на страницу компетенции
                </strong>
              </p>
              <ul>
                <li>Из списка компетенций пользователя</li>
                <li>Из редактора компетенций команды</li>
                <li>Из профиля компетенций специальности</li>
              </ul>

              <p>
                <strong> Исправления ошибок и улучшения </strong>
              </p>
              <ul>
                <li>
                  Исправлена группировка навыков желаемых к развитию в команде
                </li>
                <li>
                  Изменен интерфейс под небольшие экраны, теперь не должно быть
                  горизонтальной прокрутки
                </li>
                <li>Добавлен интерфейс администратвной статистики</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex>
          <div class="app-admin-system-releasenotes-version-title">
            1.5.4 – исправления багов
          </div>

          <div class="app-admin-system-releasenotes-version-description">
            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              <p>
                <strong> Пагинация пользователей в управлении командой </strong>
              </p>
              <ul>
                <li>
                  Теперь в управлении командой можно просматривать не только
                  первую страницу пользователей
                </li>
              </ul>

              <p>
                <strong>
                  Назначения опросов на пользователей со специальностью
                </strong>
              </p>
              <ul>
                <li>
                  Раньше в интерфейсе назначения опросов подгружался список всех
                  участников команды, вместо списка пользователей, кто выбрал
                  конкретную специальность
                </li>
              </ul>

              <p>
                <strong>
                  Отображение данных пользователей на визуализациях
                </strong>
              </p>
              <ul>
                <li>
                  В матрице компетенций изменено отображение данных по-умолчанию
                  на процент соответствия специальности
                </li>
                <li>
                  Добавлена возможность навести мышкой на вершины
                  радар-диаграммы по специальности, для отображения
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex>
          <div class="app-admin-system-releasenotes-version-title">
            1.5.3 – управление уровнями владения компетенциями
          </div>

          <div class="app-admin-system-releasenotes-version-description">
            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              <p>
                <strong>
                  Шаблоны уровней владения компетенциями для команд
                </strong>
              </p>
              <ul>
                <li>
                  В интерфейсе радактора компетенций появилась возможность
                  определить шаблоны уровней владения, которые будут применяться
                  ко всем компетенциям, созданным командой в редакторе.
                </li>
                <li>
                  Если команда захочет отказаться от использования шаблонов,
                  нужно просто удалить шаблонные уровни и тогда вновь будут
                  использоваться системные.
                </li>
              </ul>
              <p>
                <strong>
                  Возможность управлять уровнями владения для существующих
                  компетенций
                </strong>
              </p>
              <ul>
                <li>
                  В редакторе компетенций теперь можно добавлять и удалять
                  уровни владения для каждой компетенции отдельно. Это полезно,
                  если, например, по компетенции есть сертификация с несколькими
                  уровнями
                </li>
              </ul>
              <p>
                <strong>
                  Поддержка кастомизированных уровней в интерфейсах
                </strong>
              </p>
              <ul>
                <li>
                  Интерфейсы профиля специальности, списка компетенций
                  сотрудника, желаемых для изучения навыков и ревью теперь
                  поддерживают работу с кастомизированными уровнями владения
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex>
          <div class="app-admin-system-releasenotes-version-title">
            1.5.2 – улучшения юзабилити и иерархии в командах
          </div>

          <div class="app-admin-system-releasenotes-version-description">
            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              <p>
                <strong> Возможность управлять порядком отображения </strong>
              </p>
              <p>
                В любом сервисе, предоставляющем возможности аналитики, важно
                иметь возможность легкой интерпретации данных. Для этого
                связанные данные должны быть расположены удобным для этого
                образом, рядом с друг-другом и в правильном порядке. Ранее такой
                возможности не было и по обратной связи было понятно, что это
                мешает удобству.
              </p>
              <p>Теперь такие возможности есть:</p>
              <ul>
                <li>
                  На странице профиля компетенций специальности появилась
                  возможность изменять порядок доменов компетенций при помощи
                  drag&drop, а для удобства мы добавили возможность сворачивать
                  содержимое доменов кометенций и теперь вы легко можете окинуть
                  взглядом компетентностный профиль специальности.
                </li>
                <li>
                  Выбранный порядок в профиле компетенций специальности теперь
                  учитывается при автоматической генерации опросника, указанный
                  вами порядок сохраняется и там.
                </li>
                <li>
                  Порядок и структура сохраняется и в матрице компетенций
                  команды, и в треке развития сотрудника, и ... может пока не
                  везде, но мы еще не закончили :)
                </li>
              </ul>

              <p>
                <strong> Улучшения в поиске компетенций </strong>
              </p>
              <p>
                Интерфейс поиска компетенции для добавления ее в профиль
                специальности раньше не помогал пользователю, а просто отображал
                список результатов. Это было не очень удобно.
              </p>
              <ul>
                <li>
                  Улучшенный интерфейс поиска научилася отображать состояния:
                  если поиск еще в процессе, то он об этом сообщит, а если поиск
                  не вернул результатов, то вместо пустого экрана теперь
                  отображается соответствующее сообщение.
                </li>
                <li>
                  Поиск теперь отображает где именно создана компетенция,
                  которую нашел пользователь: к какой команде она принадлежит
                  или же она библиотечная, а так же из какого домена эта
                  компетенция, ведь иногда встречаются совпадения по именам и
                  аббревиатурам, а по домену можно понять, что именно вы нашли.
                </li>
              </ul>

              <p>
                <strong>
                  Поддержка наследования прав в иерархиях команд
                </strong>
              </p>
              <p>
                Это – очень важное изменение, позволяющее реализовать потенциал
                сервиса для многих сценариев его использования. Ранее в каждой
                команде был состав участников, команды можно был выстраивать в
                иерархии, но эти иерархии не несли функциональной нагрузки. Мы
                сделали большой и важный шаг и изменили многое под капотом, для
                реализации такой возможности, следующими шагами будем учить
                интерфейсы сервиса отображать данные с учетом иерархий команд.
              </p>
              <ul>
                <li>
                  Участие в дочерней команде теперь включает в себя неявное
                  участие в родительской команде, а права руководителя теперь
                  распространяются и на дочерние команды.
                </li>
                <li>
                  Для руководителей родительских команд в дочерних командах так
                  же стал доступен интерфейс управления командами.
                </li>
                <li>
                  На странице публичного профиля команды теперь есть возможность
                  показать в том числе и участников дочерхних команд. Это пока
                  единственный интерфейс с такой возможностью
                </li>
                <li>
                  На странице со всеми командами теперь есть возможность перейти
                  в том числе и в родительскую команду, ранее если у команды
                  были дочерние, то родительская становилась неактивна
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex>
          <div class="app-admin-system-releasenotes-version-title">
            1.5.1 – улучшения на основе обратной связи
          </div>

          <div class="app-admin-system-releasenotes-version-description">
            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              <p>
                <strong>
                  Упрощение создания профиля компетенций специальности
                </strong>
              </p>
              <p>
                На старте использования сервиса командам требуется занести
                данные о своих компетенциях и описать компетентностные профили
                специальностей. До этого релиза этот процесс был утомительным:
                сначала нужно было создать компетенции в редакторе, потом для
                каждого грейда каждой специальности указать уровень владения.
              </p>
              <p>Сейчас этот процесс заметно упростился:</p>
              <ul>
                <li>
                  При создании новой компетенции вы сразу можете связать ее со
                  специальностями.
                </li>
                <li>
                  Компетенция автоматически привязывается сразу ко всем грейдам
                  специальности. Если для какого-то грейда она не нужна, то вы
                  можете выбрать, что она там не требуется.
                </li>
                <li>
                  Да-да! Можете выбрать! Появилась возможность редактировать
                  требуемый уровень владения компетенцией без удаления и
                  создания с другим уровнем.
                </li>
                <li>
                  А еще, теперь вы можете связывать компетенции со
                  специальностями не только на странице компетентностного
                  профиля специальности, но и сразу из редактора компетенций.
                  Для этого там появилась новая кнопка, которая позволяет
                  связать со специальностью как конкрентную компетенцию, так и
                  целый домен компетенций и все это в два клика.
                </li>
                <li>
                  Еще одна кнопка? Уже запутались в этих иконках! Да, мы тоже.
                  Поэтому при наведении курсора на иконки в редакторе
                  компетенций теперь появляются подсказки.
                </li>
                <li>
                  При связывании компетенции со специальностью через поиск
                  теперь есть возможность ограничить поиск только своей
                  командой, а не пролистывать все библиотечные компетенции.
                </li>
              </ul>

              <p>
                <strong>
                  Новая, красивая и функциональная матрица компетенций команды
                </strong>
              </p>
              <p>
                Первая версия матрицы компетенций хоть и позволяла быстро
                окинуть взглядом состояние дел в команде, но требовалось много
                умственных усилий, чтобы интерпретировать то, что на экране, да
                и глаза потом приходилось оттирать от крови.
              </p>
              <p>Встречайте новую версию!</p>
              <ul>
                <li>
                  Сначала об очевидном: человеческое цветовое кодирование.
                  Зеленое – хорошо. Красное – плохо.
                </li>
                <li>
                  Формат теперь больше напоминает классические матрицы
                  компетенций: по вертикальной оси располагаются области знаний,
                  а по горизонтальной оси – участники команды.
                </li>
                <li>
                  С функциональной точки зрения матрица стала сильно умнее:
                  теперь учитываются специальности и грейд пользователя.
                  Представьте команду из Junior Developer, Senior Developer и QA
                  Engineer. Всем им нужно вдалеть инструментом Git, но если от
                  Junior мы ожидаем начальных знаний, то у Senior знания должны
                  быть на высоте. Если все они справляются с этим, то на матрице
                  будут отмечены зеленым цветом т.к. важно не абсолютное
                  значение, а соответствие требованиям к грейду. Если у
                  пользовтаеля несколько специальностей в команде, то будет
                  учитываться максимально требуемый уровень из них.
                </li>
                <li>
                  У матрицы компетенций теперь есть настройки отображения. Вы
                  сможете переключиться и посмотреть как общую оценку а-ля
                  "ок/норм/не ок", можно посмотреть в процентном соотношении и,
                  конечно же, в абсолютных значениях.
                </li>
                <li>
                  На матрице компетенций теперь можно увидеть и сами конкретные
                  компетенции. Так что, если у вас появился вопрос "Почему вот
                  тут красное, а тут – зеленое?", то вы сможете получить на него
                  ответ на том же экране.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex>
          <div class="app-admin-system-releasenotes-version-title">1.5.0</div>

          <div class="app-admin-system-releasenotes-version-description">
            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              <p>Планы развития</p>
              <p>
                Если задаться вопросом о том, что из себя может представлять
                процесс развития, то можно сформулировать следующие требования:
                1) он должен преследовать какие-то цели, как персональные, так и
                цели компании 2) должен быть фокус 3) измеримые результаты 4)
                конкретный дедлайн
              </p>
              <p>Похоже на OKR, не правда ли?</p>
              <ul>
                <li>
                  Пользователи теперь могут создавать для себя планы развития, а
                  лиды – для своих команд.
                </li>
                <li>
                  При создании плана нужно описать цели, как для себя, так и для
                  компании. Это добавляет контекст и сразу формулирует ценность
                  от движения по плану. После высокоуровневого описания нужно
                  заполнить ряд конкретных шагов и их результатов, приближающих
                  к достижению обозначенных целей.
                </li>
                <li>
                  Сценарии использования могут быть следующие: 1) пользователь
                  самостоятельно составляет весь план, лид корректирует или
                  одобряет 2) руководитель описывает цели, а конкретные шаги и
                  их результаты заполняет уже тот, кому этому плану следовать 3)
                  руководитель заполняет план с шагами целиком и отдает уже
                  готовый
                </li>
                <li>
                  К элементам плана могут быть привязаны компетенции. В
                  следующих версиях можно будет сгенерировать опросник по
                  компетенциям, включенным в план и после завершения спросить
                  пользователя, как он прокачался.
                </li>
                <li>
                  Для пользователя отображается его активный план, а
                  руководитель может посмотерть историю или даже заранее создать
                  план на будущее.
                </li>
              </ul>

              <p>Небольщие изменения и багфиксы</p>
              <ul>
                <li>
                  В ЛК руководителя команды теперь для специальностей можно
                  назначать сгенерированные опросы на участников команды, а так
                  же пояилась возможность посмотреть, на кого опрос уже был
                  назначен, кто его начал проходить и кто закончил
                </li>
                <li>
                  Изменения в интерфейсе редактора компетенций в команде, стал
                  красивее и юзабельнее
                </li>
                <li>
                  Матрица компетенций команды при прокрутке теперь оставляет
                  список участников на месте, так удобнее
                </li>
                <li>
                  Появилась нарисованная страничка ошибки доступа, раньше
                  интерфейс просто не грузился
                </li>
                <li>
                  В админке теперь на странице пользователя больше информации:
                  команды, специальности, опросы, планы
                </li>
                <li>Теперь можно удалять пользователей из админки</li>
                <li>
                  Исправлено поведение скрыть/развернуть на странице хотелок
                  команды, раньше работало через раз
                </li>
                <li>
                  Исправлено отображение пройденных опросов в публичном профиле
                  пользователя, был сценарий, когда оно ломалось
                </li>
                <li>
                  Исправлено отображение дерева компетенций пользователя, если в
                  каком-то из доменов нет компетенций, то он скрывается, раньше
                  отображалось и то, где "навыка нет"
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex>
          <div class="app-admin-system-releasenotes-version-title">1.4.0</div>

          <div class="app-admin-system-releasenotes-version-description">
            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Рекомендации к развитию компетеннций или даже Трек развития
              <ul>
                <li>
                  Теперь, если для специальности определен профиль компетенций,
                  мы можем показать пользователю области для развития и
                  конкретные требования для каждого грейда.
                </li>
                <li>
                  Эта механика может работать как в рамках одной специальности
                  от грейда к грейду, так и между специальностями, например:
                  "сегодня я тестировщик, а хочу быть разработчиком. что мне
                  нужно прокачать для этого?"
                </li>
                <li>
                  Для всех пользователей в личном кабинете (aka Дашборд)
                  отображается сводка и предложение перейти на этот интерфейс и
                  посмотреть
                </li>
                <li>
                  Для руководителей и лидов команд в Управлении командой &rarr;
                  Специальности &rarr; Профиль пользователей &rarr; кнопка
                  "Трек"
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Все данные по вашей команде в одном месте
              <ul>
                <li>
                  В интерфейсе управления командой теперь можно посмотреть
                  компетенции каждого из участников, матрицу компетенций по
                  областям всей команды и желаемые к развитию компетенции всех
                  участников команды
                </li>
                <li>
                  Хорошо, когда все данные в одном месте и доступны в один-два
                  клика
                </li>
                <li>
                  А вот из публичного профиля команды эти данные были убраны
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Уровни владения
              <ul>
                <li>
                  По вашим просьбам, теперь описание уровней владения чем-либо
                  привязано к этому самому чему-либо, а не только задается в
                  опроснике.
                </li>
                <li>
                  Так стало намного удобнее – опросники теперь генерируются
                  автоматически по профилю компетенций специальности и вручную
                  потом редактировать уровни владения в опроснике не нужно, при
                  генерации опросника, если Starsmap найдет описание уровней для
                  компетенции, то будет использовать их, а если не найдет –
                  подставит шаблонные.
                </li>
                <li>
                  Опросник все равно можно редактировать отдельно, такая
                  возоможность сохраняется
                </li>
                <li>
                  Как бонус, эти уровни скоро можно будет создавать не только
                  глобальные, но и на уровне команд
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Адинистративные возможности
              <ul>
                <li>
                  Теперь стало возможным изменять иерархию доменов компетенций,
                  а также изменять команду, владеющую доменом.
                </li>
                <li>
                  В интерфейсе опросников теперь отображаются не только уже
                  начатые или завершенные пользовательские сессии, но и факт
                  назначения опросника на пользователя.
                </li>
                <li>Теперь видно дату последней активности пользователя</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex>
          <div class="app-admin-system-releasenotes-version-title">1.3.0</div>

          <div class="app-admin-system-releasenotes-version-description">
            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Профили специальностей
              <ul>
                <li>Это, пожалуй, самое важное изменение в релизе.</li>
                <li>
                  Теперь в управлении командой можно описать требования к
                  компетенциям специальности. Более того, для разных грейдов
                  могут быть разные требования. Таким образом, мы определяем
                  соответствие сотрудника занимаемому грейду. Требования могут
                  быть как на уровне конкретных компетенций, так и на уровне
                  групп/классов, например: реляционные базы данных, нам может
                  быть не важно, знаешь ты PostgreSQL или MySQL, просто
                  разбирайся в какой-нибудь из них.
                </li>
                <li>
                  Эта механика так же позволит показать сотруднику, что ему
                  нужно подтянуть для того, чтобы подготовиться к переходу на
                  следующий грейд. Общий и прозрачный фреймворк роста, почти
                  игровая механика для удержания сотрудников, поиск подходящих
                  сотрудников по требованиям (можно сказать, общая механика как
                  для перехода между позициями, так и для поиска под
                  активность/проект), треки развития и вот это все берут свое
                  начало с этого функционала.
                </li>
                <li>
                  Вернулась визуализация-радарчик (паутинка), теперь она
                  привязана к специальности, что логично ведь все домены
                  компетенций на ней не разместишь, помещается примерно до 12, а
                  это, как раз, разумный предел для одной специальности.
                </li>
                <li>
                  В управлении командой можно теперь посмотреть, как и какой
                  сотрудник укладывается в профиль специальности. Пока одной
                  визуализацией, но в скором времени будет еще развертка
                  визуализации в подробное описание.
                </li>
                <li>
                  Одна из самых больших болей по текущим наблюдениям в пилоте –
                  составление опросника. И понятно почему: мало того, что это
                  искусственная деятельность не привязанная к реальной жизни,
                  так еще и нужно что-то думать, какую-то структуру опроса
                  изобретать. No more pain! Требования к специальности – штука
                  уже куда более приземленная и понятная. А потом происходит
                  магия: одной кнопкой требования превращаются в готовый
                  опросник.
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Домены компетенций и команды
              <ul>
                <li>
                  До этого момента все компетенции и их структура была общей,
                  что добавляло сложности и накладывало ограничения. К тому же,
                  приходилось всех пускать в админку, чтобы была возможность
                  заполнить свои данные.
                </li>
                <li>
                  Теперь на странице управления командой есть возможность
                  описать свою структуру компетений. Интерфейс там еще без
                  какого-либо дизайна, но это – поправимые мелочи.
                </li>
                <li>
                  Что важнее, теперь домены компетенций можно организовывать в
                  иерархии: Базы данных → Реляционные → PostgreSQL
                </li>
                <li>
                  И что еще важнее: отметив владение PostgreSQL пользователь
                  неявно сообщает Starsmap, что он владеет Базами данных, а
                  именно реляционными. Раньше для этого была искусственная
                  сущность "области компетенций" и нужно было каждую сущность к
                  областям привязывать. Теперь же просто организовав компетенции
                  в иерархию мы получаем все то же и даже больше.
                </li>
                <li>
                  Командам для использования доступны общие (глобальные) домены
                  и компетенции в них и свои "командные" компетенции.
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Интерфейс опросников
              <ul>
                <li>"Где я?", "Что это за названия заклинаний?"</li>
                <li>
                  Теперь путь до вашего текущего местоположения в опроснике
                  большой и заметный.
                </li>
                <li>
                  Под капотом он еще стал и умным, этого никому не видно, а
                  разработчику приятно.
                </li>
                <li>
                  Из мелочей: нотификация об отметке желаемного к прокачке
                  навыка больше не будет беспокоить.
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Пользовательский дашборд
              <ul>
                <li>
                  Вместо пустой и унылой страницы, теперь на главном экране
                  красуется радарчик/паутинка.
                </li>
                <li>
                  Если специальностей несколько, то можно выбрать, на какую из
                  них хочется смотреть.
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Изменения в админке
              <ul>
                <li>
                  В списке доментов теперь отображается принадлежность к домена
                  к команде. В следующем релизе можно будет переносить домены в
                  команды и, наоборот, делать их публичными.
                </li>
                <li>
                  Наконец, появилась страничка по каждому пользователю. Пока
                  почти пустая, но постепенно на ней будет отображаться все, что
                  касается кокретного пользователя, чтобы не приходилось бегать
                  по разным разделам.
                </li>
                <li>
                  Внезапно, но потребовалась возможность обнулять пользователям
                  флаг завершения онбординга. Теперь это можно сделать в
                  админке.
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Исправления ошибок
              <ul>
                <li>
                  Баг, позволяющий натыкать одну и ту же хотелку безжалостно
                  кликая по чекбоксу
                </li>
                <li>
                  При выборе большого количества команд, на странице онбординга
                  ломалась верстка.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex>
          <div class="app-admin-system-releasenotes-version-title">1.2.0</div>

          <div class="app-admin-system-releasenotes-version-description">
            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Изменения в интерфейсах
              <ul>
                <li>
                  Вместо верхней навигационной панели, которая занимала место на
                  экране, даже если его прокрутить, теперь логотип и навигация
                  просто размещены сверху. Это сделало интерфейс более легким и
                  сэкономило место на экране для небольших мониторов.
                </li>
                <li>
                  В этой же навигационной панели теперь отображается
                  нотификация, если есть активности, ожидающие внимания
                  пользователя.
                </li>
                <li>
                  Элементы списков в разных местах приложения выглядят с
                  небольшими различиями: где-то красная полоска слева от
                  элемента списка есть всегда, а где-то появляется при наведении
                  курсора. Это специально, посмотрим, что понравится
                  пользователям больше.
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Опросы по компетенциям
              <ul>
                <li>
                  Интерфейс притерпел существенные изменения: граф компетенций
                  теперь не только занимает половину места на экране, но и
                  помогает пользователям ориентироваться в многообразии
                  компетенций.
                </li>
                <li>
                  Описания уровней владения по-умолчанию скрыты и отображаются
                  при выборе уровня владения
                </li>
                <li>Долгожданаая кнопка "Назад" :)</li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Специальности в командах и их грейды
              <ul>
                <li>
                  Команда теперь может самостоятельно управлять своими
                  специальностями, назначать специальности участникам команды и
                  тому подобное
                </li>
                <li>
                  Новая штука: грейды! Теперь может быть не просто Разработчик,
                  а, например, junior, middle, senior. Использовать это не
                  обязательно, у специальностей автоматичски создается базовый
                  грейд
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Новый онбординг
              <ul>
                <li>
                  Он выглядит как старый, но работает иначе: можно выбрать не
                  все команды, а только нижнего уровня иерархии
                </li>
                <li>
                  Специальности теперь подтягиваются только из выбранных команд,
                  а не все разом
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Новая старница команд
              <ul>
                <li>Она просто в десять раз красивее и юзабельнее старой,</li>
                <li>
                  Ну и теперь она отображает саму иерархию, а не показывает
                  команды плоским списком
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Ревью
              <ul>
                <li>
                  Теперь есть возможнось приглашать пользователей для ревью
                  компетенций
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Админка
              <ul>
                <li>
                  Для команд теперь работает функционал объединения их в
                  иерархию, а в интерфейсах отображается родительская команда
                </li>
                <li>
                  Для специальностей тоже теперь отображаются принадлежность к
                  команде
                </li>
                <li>
                  Для кнопок удаления добавлены диалоги подтверждения действия,
                  не везде, но там где это вызывало проблемы: удаление сущностей
                  в домене, удаление опросов и самих вопросов в опросах
                </li>
                <li>
                  Добавлена возможность отредактировать навзание и описание
                  сущности в домене
                </li>
                <li>
                  В опросах кнопки редактирования текста вопроса и вариантов
                  ответа теперь всегда отображаются, а то не было очевидно, что
                  их можно менять
                </li>
                <li>
                  Добавлась возможность изменить роль принадлежности к команде,
                  раньше нужно было выгонять человека из команды и добавлять с
                  новой ролью
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Увеличена скорость загрузки страниц
              <ul>
                <li>
                  Страницы пользовательского профиля и профиля команды теперь не
                  подгружают все данные сразу. Применение lazy load для данных
                  во вкладках существенно сократило скорость загрузки страниц в
                  целом.
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Исправления ошибок
              <ul>
                <li>
                  Баг из-за которого страница профиля пользователя не
                  перезагружалась при переходе из профиля другого пользователя
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex>
          <div class="app-admin-system-releasenotes-version-title">1.1.0</div>

          <div class="app-admin-system-releasenotes-version-description">
            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Интерфейс управления командой
              <ul>
                <li>
                  Отдельная страница, доступная только руководителям и лидам
                  конкретной команды.
                </li>
                <li>
                  В этом интерфейсе можно управлять составом команды, создавать
                  опросы и т.д.
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Интерфейс создания и ревью компетенций пользователя
              <ul>
                <li>
                  Через страницу участников команды можно создать процесс ревью
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Опросы и их результаты
              <ul>
                <li>
                  При прохождении опроса теперь видно путь до текущей
                  компетенции (breadcrumbs).
                </li>
                <li>
                  Улучшена визуализация на странице результатов опроса:
                  добавлено цветовое кодирование, и размер кружка теперь зависит
                  от уровня владения компетенцией.
                </li>
                <li>Добавлена легенда визуализации</li>
                <li>
                  Чекбоксы, позволяющие управлять визуализацией результатов
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Улучшения при создании опросов
              <ul>
                <li>Появились тексты подсказок при создании опроса</li>
                <li>
                  При поиске компетенций для добавления в опрос отображается
                  домен, в котором находится компетенция
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Добавлены вомзможности редактирования сущностей
              <ul>
                <li>Редактирование данных о специальности</li>
                <li>Редактирование данных о компетенции</li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Страница Release notes в админке
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Вкладка "Опросы" в профиле пользователя
              <ul>
                <li>
                  Возможность просмотреть результаты опроса любого пользователя
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Возможность отметить хотелку на странице навыка
              <ul>
                <li>Добавлена кнопка</li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Поддержка matrial тем интерфейса
              <ul>
                <li>
                  Появилась возможность централизованной кастомизации цветов
                  оформления интерфейса
                </li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Механика пагинации длинных списков
              <ul>
                <li>Реализовано для списка пользователей в админке</li>
              </ul>
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Кликабельный логотип ведущий на главную страницу
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Баг с отображением [Object Object] при поиске компетенции для
              опроса
            </div>

            <div
              class="app-admin-system-releasenotes-version-description-block"
            >
              Баг при снятии принадлежности компетенции к какой-либо из областей
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
