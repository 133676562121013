<div *ngIf="formConfig.onSubmit">
  <div class="domains-form-wrapper">
    <mat-card>
      <h4>{{ formConfig.titleLabel }}</h4>
      <form>
        <div [formGroup]="form">
          <div>
            <mat-form-field [style.width.rem]="30">
              <input
                matInput
                placeholder="Название"
                type="text"
                formControlName="name"
                required
              />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field [style.width.rem]="30">
              <input
                matInput
                placeholder="Описание"
                type="text"
                formControlName="description"
                required
              />
            </mat-form-field>
          </div>
        </div>
        <div>
          <button
            mat-raised-button
            [disabled]="form.invalid"
            (click)="submit()"
          >
            {{ formConfig.submitLabel }}
          </button>
          <button
            mat-raised-button
            (click)="formConfig.onReset()"
            class="reset-button"
          >
            Отмена
          </button>
        </div>
      </form>

      <div *ngIf="isShowSimilarWarning()">
        <h2>Не создавайте дубли</h2>
        <div *ngIf="getSimilarElements().domains.length > 0">
          <label>Похожие домены</label>
          <app-library-item
            *ngFor="let domain of getSimilarElements().domains"
            [item]="serializeDomain(domain)"
          ></app-library-item>
        </div>
        <div *ngIf="getSimilarElements().things.length > 0">
          <label>Похожие навыки</label>
          <app-library-item
            *ngFor="let thing of getSimilarElements().things"
            [item]="serializeThing(thing)"
          ></app-library-item>
        </div>
      </div>
    </mat-card>
  </div>
</div>
