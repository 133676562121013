import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyTeamRelationService {

  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService,
  ) { }

  public fetch_by_team_uuid(team_uuid: string): Observable<any> {
    const params = {team: team_uuid, expand: 'survey,team'};
    return this.api_service.get(`surveys/relation/team`, params).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} fetched surveys for team uuid ${team_uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to fetch surveys for team`);
        }
    ));
  }

  public create(relation_data: any): Observable<any> {
    return this.api_service.post(`surveys/relation/team`, relation_data, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} created survey team relation with uuid ${response.uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to create survey team relation`);
        }
    ));
  }

  public delete(relation_uuid: string): Observable<any> {
    return this.api_service.delete(`surveys/relation/team/${relation_uuid}`, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} deleted survey team relation with uuid ${relation_uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to delete survey team relation`);
        }
    ));
  }
}
