<h1 mat-dialog-title>{{ data.formTitle }}</h1>
<form [formGroup]="form">
  <div mat-dialog-content>
    <div>
      <mat-form-field class="full-width-field">
        <mat-label>Название уровня владения</mat-label>
        <input matInput placeholder="Начинающий" formControlName="title" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width-field">
        <mat-label>Описание и индикаторы поведения</mat-label>
        <textarea
          matInput
          placeholder="Чем характеризуется владение компетенцией на этом уровне? Например:
          Могу выполнять простые задачи, но в сложных случаях обращаюсь за помощью к коллегам"
          formControlName="description"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button
      class="dialog-button"
      (click)="submit()"
      [disabled]="form.invalid"
      mat-raised-button
    >
      {{ data.submitTitle }}
    </button>
    <button class="dialog-button" (click)="close()" mat-raised-button>
      Отмена
    </button>
  </div>
</form>
