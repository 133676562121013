<div class='dialog-container'>
  <div class='dialog-text'>
    <div class='title'><strong>Проставить уровни владения навыком</strong></div>
    <div>Вы можете проставить стандартные уровни владения навыками по всей специальности автоматически вместо ручного
      заполнения.
    </div>
    <br>
    <div>
      Вы уверены, что хотите проставить все уровни?
    </div>
  </div>
  <div fxLayout='row'>
    <div fxFlex='92px' fxLayout='column' fxLayoutGap='11px' class='grade-names-container'>
      <div fxFlex fxLayout='row' fxLayoutAlign='end center'
           *ngFor='let competenceClaim of data.firstThingCompetenceClaims'>
        <div class='grade-name'>{{ competenceClaim.grade.name }}</div>
      </div>
    </div>
    <div fxFlex='223px' fxLayout='column' fxLayoutGap='11px' class='example-levels-container'>
      <div fxFlex fxLayout='row' fxLayoutAlign='start center' class='level-select'
           *ngFor='let competenceClaim of data.firstThingCompetenceClaims'>
        <div>{{ competenceClaim.thing_level.title }}</div>
      </div>
    </div>
    <div fxFlex='19px' fxLayout='column' fxLayoutAlign='center center'>
      <div>-></div>
    </div>
    <div fxFlex='223px' fxLayout='column' [formGroup]='gradeLevelsForm' fxLayoutGap='11px'
         class='result-levels-container'>
      <div fxFlex class='level-select' *ngFor='let gradeUuid of grades'>
        <mat-select [formControlName]='gradeUuid'>
          <mat-option *ngFor='let level of data.levels' [value]='level.order_number'>
            {{ level.title }}
          </mat-option>
        </mat-select>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <div fxFlex fxLayout='row' class='dialog-buttons-container'>
      <div fxFlex fxLayout='row' fxLayoutAlign='start center'>
        <button
          class='dialog-reject-button'
          mat-raised-button
          [mat-dialog-close]='false'
        >
          Отмена
        </button>
      </div>
      <div fxFlex fxLayoutAlign='end center'>
        <button
          class='dialog-accept-button'
          color='primary'
          [mat-dialog-close]='true'
          mat-raised-button
          (click)='submit()'
        >
          Проставить
        </button>
      </div>
    </div>
  </div>
</div>
