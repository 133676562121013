import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AsyncList } from 'src/app/core/rest/AsyncList';
import { SpecialityGroup } from 'src/app/models/specialities/speciality-group';
import { Speciality } from 'src/app/models/specialities/speciality';
import { ActivatedRoute, Router } from '@angular/router';
import { SpecialityGroupHttpService } from '@services/http/SpecialityGroupHttpService';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { AsyncDetail } from 'src/app/core/rest/AsyncDetail';

@Component({
  selector: 'app-speciality-group-edit',
  templateUrl: './speciality-group-edit.component.html',
  styleUrls: ['./speciality-group-edit.component.css'],
})
export class SpecialityGroupEditComponent implements OnInit {
  uuid: string;
  specialityGroup: AsyncDetail<SpecialityGroup>;
  specialityGroups: AsyncList<SpecialityGroup>;
  specialities: AsyncList<Speciality>;
  selectedSpecialities: string[];

  form = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    specialities: new UntypedFormArray([]),
  });

  constructor(
    private _specialityGroupHttpService: SpecialityGroupHttpService,
    private _specialityHttpService: SpecialityHttpService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    this.uuid = this._activatedRoute.snapshot.params.uuid;
    this.specialityGroups = new AsyncList(_specialityGroupHttpService);
    this.specialities = new AsyncList(_specialityHttpService);
    this.specialityGroup = new AsyncDetail<SpecialityGroup>(
      this.uuid,
      this._specialityGroupHttpService
    );
  }

  ngOnInit(): void {
    this.specialities.requestParams = {
      params: {
        team__isnull: 'True',
      },
    };

    this.specialities.load().subscribe(() => {
      this.specialityGroup.load().subscribe(() => {
        this.form.setValue({
          name: this.specialityGroup.state.item.name,
          specialities: [],
        });

        this.initFormSpecialities();
        this.initMultiSelect();
      });
    });
  }

  private initFormSpecialities() {
    const formSpecialities = this.form.get('specialities') as UntypedFormArray;
    this.specialityGroup.state.item.specialities.forEach((speciality) => {
      formSpecialities.push(new UntypedFormControl(speciality.uuid));
    });
  }

  private initMultiSelect() {
    const specialities = this.specialityGroup.state.item.specialities;
    this.selectedSpecialities = specialities.map((speciality: Speciality) => {
      return speciality.uuid;
    });
  }

  submit() {
    this.specialityGroup.update(this.form.value).subscribe(this.goToLibrary);
  }

  remove() {
    this.specialityGroup.remove().subscribe(this.goToLibrary);
  }

  addSpeciality(speciality: Speciality) {
    const formSpecialities = this.form.get('specialities') as UntypedFormArray;
    formSpecialities.push(new UntypedFormControl(speciality.uuid));
  }

  removeSpeciality($event: { value: Speciality }) {
    const formSpecialities = this.form.get('specialities') as UntypedFormArray;
    formSpecialities.removeAt(
      this.form.value.specialities.indexOf($event.value.uuid)
    );
  }

  private goToLibrary = () => {
    this._router.navigate(['library']);
  };
}
