import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

import { User } from 'src/app/models/user/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private logging_service: LoggingService,
    private api_service: ApiService
  ) {}

  public fetch_all(params: any = {}): Observable<any> {
    return this.api_service.get(`users/user`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} users`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch users`
          );
        }
      )
    );
  }

  public fetch_user_by_uuid(user_uuid: string): Observable<any> {
    return this.api_service.get(`users/user/${user_uuid}`, {}).pipe(
      tap(
        (data) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched user data`
          );
          return data;
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch user data`
          );
          return err;
        }
      )
    );
  }

  public update(user_uuid: string, user_data: any): Observable<any> {
    return this.api_service
      .patch(`users/user/${user_uuid}`, user_data, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} updated user with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to update user`
            );
          }
        )
      );
  }

  public delete(user_uuid: string): Observable<any> {
    return this.api_service.delete(`users/user/${user_uuid}`, {}).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} deleted user with uuid ${user_uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to delete user`
          );
        }
      )
    );
  }

  public search(term: string): Observable<any> {
    if (!term.trim()) {
      return of([]);
    }
    return this.api_service.get(`users/user`, { search: term }).pipe(
      tap((response) => {
        const users = response.results as User[];
        this.logging_service.debug(
          `${this.constructor.name} found ${users.length} users for search term=${term}`
        );
      })
    );
  }

  public get_edit_teams(): Observable<any> {
    return this.api_service.get(`user_edit_teams`, {}).pipe(
      tap(
        (data) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched user edit teams`
          );
          return data;
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch user edit teams`
          );
          return err;
        }
      )
    );
  }

  public get_user_team_memberships(): Observable<any> {
    return this.api_service.get(`user_team_memberships`, {}).pipe(
      tap(
        (data) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched user teams`
          );
          return data;
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch user teams`
          );
          return err;
        }
      )
    );
  }
}
