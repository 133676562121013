<div>
    <mat-form-field>
        <mat-label>Временной интервал</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="С даты">
            <input matEndDate formControlName="end" placeholder="По дату">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Неверная начальная дата</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Неверная финальная дата</mat-error>
    </mat-form-field>

    &nbsp;&nbsp;&nbsp;&nbsp;

    <mat-form-field>
        <mat-label>Агрегация</mat-label>
        <mat-select [(value)]="selected_aggregation_period" (selectionChange)="on_selected_aggregation_period()">
            <mat-option value="min">Минута</mat-option>
            <mat-option value="hour">Час</mat-option>
            <mat-option value="day">День</mat-option>
            <mat-option value="month">Месяц</mat-option>
            <mat-option value="year">Год</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div class="app-chart-competence-delta-container">
    <div *ngIf="data.length" class="{{ chart_name }}"></div>
    <div *ngIf="!data.length" class="app-chart-competence-delta-nodata">
        Нет данных для отображения
    </div>
</div>
