import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Thing } from '@models/ontology/thing';
import { Domain } from '@models/ontology/domain';
import { LibraryItemProps } from '@components/common/library-item/library-item.component';

export interface DetailFormData {
  name: string;
  description: string;
}

export interface DomainDetailFormConfig {
  titleLabel: string;
  submitLabel: string;
  onSubmit(data: DetailFormData): void;
  onReset(): void;
  defaultData: DetailFormData;
}

@Component({
  selector: 'app-domain-detail-form',
  templateUrl: './domain-detail-form.component.html',
  styleUrls: ['./domain-detail-form.component.css'],
})
export class DomainDetailFormComponent {
  @Input() formConfig: DomainDetailFormConfig;
  @Input() domains: Domain[] = [];
  @Input() things: Thing[] = [];
  form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    levels: new UntypedFormControl([]),
    description: new UntypedFormControl('', [Validators.required]),
  });
  constructor() {}

  ngOnChanges(): void {
    this.form.setValue({
      name: this.formConfig.defaultData.name,
      levels: [],
      description: this.formConfig.defaultData.description,
    });
  }

  submit(): void {
    this.formConfig.onSubmit(this.form.value);
  }

  _isSimilarElement = (element: { name: string }): boolean => {
    return (
      this.form.value.name.length > 3 &&
      element.name.includes(this.form.value.name)
    );
  };

  getSimilarElements(): { domains: Domain[]; things: Thing[] } {
    return {
      domains: this.domains.filter(this._isSimilarElement),
      things: this.things.filter(this._isSimilarElement),
    };
  }

  isShowSimilarWarning(): boolean {
    return (
      this.formConfig.submitLabel === 'Создать' &&
      (this.getSimilarElements().domains.length > 0 ||
        this.getSimilarElements().things.length > 0)
    );
  }

  serializeDomain(domain: Domain): LibraryItemProps {
    return {
      content: domain.name,
      footer: domain.description,
      linkUrl: `/library/domains/${domain.uuid}`,
    };
  }

  serializeThing(thing: Thing): LibraryItemProps {
    return {
      content: thing.name,
      footer: thing.description,
      linkUrl: `/things/${thing.uuid}`,
    };
  }
}
