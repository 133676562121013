import { Component, Input, OnInit } from '@angular/core';
import { RectData } from '@components/ui/chart-gantt/chart-gantt.component';
import { MatDialog } from '@angular/material/dialog';
import { CreateProjectDialogComponent } from '@components/common/create-project-dialog/create-project-dialog.component';
import { ProjectHttpService } from '@services/http/ProjectHttpService';
import { AsyncList } from '@rest/AsyncList';
import { Project } from '@models/projects/project';
import { User } from '@models/user/user';
import { CreateEditBookingDialogComponent } from '@components/common/create-edit-booking-dialog/create-edit-booking-dialog.component';
import { BookingHttpService } from '@services/http/BookingHttpService';
import { Booking } from '@models/projects/booking';
import { SpecialityUserRelationHttpService } from '@services/http/SpecialityUserRelationHttpService';
import { SpecialityUserRelation } from '@models/specialities/speciality-user-relation';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityGrade } from '@models/specialities/speciality-grade';
import { Company } from '@models/projects/company';

interface GradeUserRelationMap {
  [gradeUuid: string]: SpecialityUserRelation[];
}

interface SpecialityWithGrade {
  speciality: Speciality;
  grade: SpecialityGrade;
  isExpanded: boolean;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
})
export class CalendarComponent implements OnInit {
  @Input() project: Project;

  items = [];
  config = {};

  isCalendarReady = false;
  isSpecialityWithGradesReady = false;
  dataSlice: 'project' | 'speciality' = 'project';
  todayState: boolean;

  eventLog = [];

  projects: AsyncList<Project>;
  bookings: AsyncList<Booking>;
  users: User[] = [];
  uniqueUserUuids: string[] = [];

  userSpecialities: AsyncList<SpecialityUserRelation>;
  specialitiesWithGrades: SpecialityWithGrade[] = [];
  gradeUserRelationMap: GradeUserRelationMap = {};

  defaultRowHeight = 70;

  constructor(
    private _dialog: MatDialog,
    private _projectHttpService: ProjectHttpService,
    private _bookingHttpService: BookingHttpService,
    private _specialityUserRelationHttpService: SpecialityUserRelationHttpService
  ) {}

  ngOnInit(): void {
    if (this.project) {
      this._setUsers(this.project.resources as User[]);
      this._loadBookings();
    } else {
      if (this.dataSlice === 'project') {
        this._loadProjects();
      } else {
        this._loadUsersSpecialities();
      }
    }
    this.config['laneHeight'] = this.defaultRowHeight;
    this.config['tickFormat'] = 'day';
  }

  private _loadUsersSpecialities(): void {
    if (!this.userSpecialities) {
      this.userSpecialities = new AsyncList<SpecialityUserRelation>(
        this._specialityUserRelationHttpService
      );
      this.userSpecialities.setRequestParams({
        params: {
          expand: 'speciality,grade,user',
        },
      });
      this.userSpecialities.load().subscribe(() => {
        this.userSpecialities.state.items.forEach((relation) => {
          if (relation.grade) {
            const grade = relation.grade as SpecialityGrade;
            if (!Object.keys(this.gradeUserRelationMap).includes(grade.uuid)) {
              this.specialitiesWithGrades.push({
                speciality: relation.speciality as Speciality,
                grade: grade,
                isExpanded: false,
              });
              this.gradeUserRelationMap[grade.uuid] = [relation];
            } else {
              this.gradeUserRelationMap[grade.uuid].push(relation);
            }
          }
        });
        this.specialitiesWithGrades.sort(
          (a, b) =>
            a.speciality.name.localeCompare(b.speciality.name) ||
            -(b.grade.order - a.grade.order)
        );
        this._setUsers(
          this.userSpecialities.state.items.map(
            (userRelation) => userRelation.user as User
          )
        );
        this._loadBookings();
      });
    } else {
      this._setUsers(
        this.userSpecialities.state.items.map(
          (userRelation) => userRelation.user as User
        )
      );
      this._loadBookings();
    }
  }

  private _loadProjects(): void {
    if (!this.projects) {
      this.projects = new AsyncList<Project>(this._projectHttpService);
      this.projects.setRequestParams({
        params: {
          expand: 'resources',
        },
      });

      this.projects.load().subscribe(() => {
        this.projects.state.items.forEach((project) => {
          this._setUsers(project.resources as User[]);
        });
        this._loadBookings();
      });
    } else {
      this.projects.state.items.forEach((project) => {
        this._setUsers(project.resources as User[]);
      });
      this._loadBookings();
    }
  }

  private _setUsers(users: User[]): void {
    this.uniqueUserUuids = [];
    this.users = [];
    users.forEach((user) => {
      if (!this.uniqueUserUuids.includes(user.uuid)) {
        this.uniqueUserUuids.push(user.uuid);
        this.users.push(user);
      }
    });
  }

  private _setSpecialityUserRelationMap(): void {}

  private _loadBookings(): void {
    this.bookings = new AsyncList<Booking>(this._bookingHttpService);
    if (this.project) {
      this._loadProjectsBookings([this.project]);
    } else if (this.dataSlice === 'project') {
      this._loadProjectsBookings(this.projects.state.items);
    } else {
      this.bookings.setRequestParams({
        params: {
          resource__in: this.uniqueUserUuids.join(','),
          expand: 'resource,project.company,project_task',
        },
      });
      this.bookings.load().subscribe(() => {
        this._setItems();
      });
    }
  }

  private _loadProjectsBookings(projects: Project[]): void {
    this.bookings.setRequestParams({
      params: {
        project__in: projects.map((project) => project.uuid).join(','),
        expand: 'resource,project.company,project_task',
      },
    });
    this.bookings.load().subscribe(() => {
      this._setProjectsItems();
    });
  }

  private _setItems(): void {
    this.isCalendarReady = false;
    this.isSpecialityWithGradesReady = false;
    this.items = [];
    let laneCount = 0;
    this.specialitiesWithGrades.forEach((specialityWithGrade) => {
      this.items.push({
        id: specialityWithGrade.grade.uuid,
        lane: laneCount,
        dateStart: null,
        dateEnd: null,
        class: null,
        name: null,
      });
      laneCount += 1;
      if (specialityWithGrade.isExpanded) {
        laneCount = this._setRelationItems(specialityWithGrade, laneCount);
      }
    });
    setTimeout(() => {
      this.isSpecialityWithGradesReady = true;
      this.isCalendarReady = true;
    }, 50);
  }

  private _setRelationItems(
    specialityWithGrade: SpecialityWithGrade,
    laneCount: number
  ): number {
    const relations = this.gradeUserRelationMap[specialityWithGrade.grade.uuid];
    relations.forEach((relation) => {
      const userBookings = this.bookings.state.items.filter(
        (booking) =>
          (booking.resource as User).uuid === (relation.user as User).uuid
      );

      if (userBookings.length) {
        userBookings.forEach((booking) => {
          const project = booking.project as Project,
            company = project.company as Company;
          let text;
          if (company) text = `${company.name}__${project.name}`;
          else text = project.name;
          this.items.push({
            id: booking.uuid,
            lane: laneCount,
            dateStart: Date.parse(booking.booked_from),
            dateEnd: Date.parse(booking.booked_until),
            class: booking.utilization > 50 ? 'success' : 'warning',
            name: (booking.resource as User).uuid,
            text: text,
          });
          laneCount += 1;
        });
      } else {
        this.items.push({
          id: relation.uuid,
          lane: laneCount,
          dateStart: null,
          dateEnd: null,
          class: null,
          name: null,
          text: '',
        });
        laneCount += 1;
      }
    });
    return laneCount;
  }

  private _setProjectsItems(): void {
    this.isCalendarReady = false;
    this.items = [];
    let laneCount = 0;
    this.uniqueUserUuids.forEach((userUuid) => {
      this.bookings.state.items
        .filter((booking) => userUuid === (booking.resource as User).uuid)
        .forEach((booking) => {
          const project = booking.project as Project,
            company = project.company as Company;
          let text;
          if (company) text = `${company?.name}__${project.name}`;
          else text = project.name;

          this.items.push({
            id: booking.uuid,
            lane: laneCount,
            dateStart: Date.parse(booking.booked_from),
            dateEnd: Date.parse(booking.booked_until),
            class: booking.utilization > 50 ? 'success' : 'warning',
            name: (booking.resource as User).uuid,
            text: text,
          });
          laneCount += 1;
        });
    });
    this.isCalendarReady = true;
  }

  getSpecialityWithGradeRelations(
    specialityWithGrade: SpecialityWithGrade
  ): SpecialityUserRelation[] {
    return this.gradeUserRelationMap[specialityWithGrade.grade.uuid];
  }

  getUserRowHeight(userUuid: string): number {
    const height =
      this.bookings.state.items.filter(
        (booking) => userUuid === (booking.resource as User).uuid
      ).length * this.defaultRowHeight;
    return height > 0 ? height : this.defaultRowHeight;
  }

  getSpecialityRowHeight(specialityWithGrade: SpecialityWithGrade): number {
    let height = this.defaultRowHeight;
    if (specialityWithGrade.isExpanded) {
      this.gradeUserRelationMap[specialityWithGrade.grade.uuid].forEach(
        (relation) => {
          height += this.getUserRowHeight((relation.user as User).uuid);
        }
      );
    }
    return height;
  }

  toggleSpecialityExpansion(specialityWithGrade: SpecialityWithGrade): void {
    specialityWithGrade.isExpanded = !specialityWithGrade.isExpanded;
    this._setItems();
  }

  toggleTickFormat(value: string): void {
    this.isCalendarReady = false;
    setTimeout(() => {
      this.config['tickFormat'] = value;
      this.isCalendarReady = true;
    }, 50);
  }

  toggleSlice(value: 'project' | 'speciality'): void {
    this.isCalendarReady = false;
    setTimeout(() => {
      this.dataSlice = value;
      if (this.dataSlice === 'project') {
        this._loadProjects();
      } else {
        this._loadUsersSpecialities();
      }
      this.isCalendarReady = true;
    }, 50);
  }

  onTodayClick(): void {
    this.todayState = true;
    setTimeout(() => {
      this.todayState = false;
    }, 100);
  }

  addProject(): void {
    this._dialog.open(CreateProjectDialogComponent, {
      data: {},
    });
  }

  addBooking(): void {
    let data = {};
    if (this.project) {
      data = {
        project: this.project,
      };
    }
    this._dialog.open(CreateEditBookingDialogComponent, { data });
  }

  onBookingChange(data: RectData): void {
    const oldBooking = this.items.find((item) => item.id === data.id);

    this._bookingHttpService
      .update(data.id, {
        resource: data.user,
        booked_from: data.dateStart.toISOString(),
        booked_until: data.dateEnd.toISOString(),
      })
      .subscribe(() => {});
    this.items.map((item) => {
      if (item.id === data.id) {
        return {
          ...data,
          dateStart: Date.parse(data.dateStart.toISOString()),
          dateEnd: Date.parse(data.dateEnd.toISOString()),
        };
      }
    });
  }

  onBookingEdit(data: RectData): void {
    const booking = this.bookings.state.items.find(
      (booking) => booking.uuid === data.id
    );

    this._dialog.open(CreateEditBookingDialogComponent, {
      data: {
        bookingData: {
          ...booking,
          project: this.projects.state.items.find(
            (project) => project.uuid === (booking.project as Project).uuid
          ),
        },
      },
    });
  }
}
