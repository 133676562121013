<div class='dialog-container'>
  <div class='title' *ngIf='data.title'>
    {{ data.title }}
  </div>

  <div class='description'>{{ data.message }}</div>

  <div mat-dialog-actions fxLayoutAlign='end center'>
    <button class='dialog-reject-button' (click)='close()' mat-raised-button *ngIf='data.rejectLabel'>
      {{ data.rejectLabel }}
    </button>
    <button
      class='dialog-accept-button'
      color='primary'
      [mat-dialog-close]='true'
      mat-raised-button
    >
      {{ data.acceptLabel }}
    </button>
  </div>
</div>
