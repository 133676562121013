import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { Direction } from '@models/directions/direction';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DirectionHttpService extends ViewSetHttpService<Direction> {
  http: HttpClient;

  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'directions/direction';

  public getDirectionTeamSpecialities(
    directionUuid: string,
    teamUuid: string
  ): Observable<any> {
    return this.http.post<string[]>(
      `${this.getUrl()}/${directionUuid}/get_direction_team_specialities`,
      {
        team: teamUuid,
      }
    );
  }

  public updateDirectionsDomains({
    directions,
    domain,
  }: {
    directions: string[];
    domain: string;
  }): Observable<any> {
    return this.http.post<{ directions: string[]; domain: string }>(
      `${this.getUrl()}/update_directions_domains`,
      { directions, domain }
    );
  }
}
