<h3>Компетенции</h3>

<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="domains-tree"
>
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <div class="tree-item">
      <a class="tree-item-link">{{ node.name }}</a>
      <div class="thing-grades">
        <app-speciality-thing-grades
          [grades]="grades"
          [onChange]="setGradeLevel(node.uuid)"
          [getGradeValue]="getGradeValueFabric(node.uuid)"
          [levels]="node.levels"
        ></app-speciality-thing-grades>
      </div>
      <button mat-icon-button (click)="removeCompetence(node.uuid)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <div class="domain-item">
      <button
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <a class="domain-link">{{ node.name }}</a>
    </div>
  </mat-tree-node>
</mat-tree>

<div class="form-group-buttons">
  <button mat-raised-button (click)="showAddCompetenceDialog()">
    Добавить компетенцию в специальность
  </button>
</div>
