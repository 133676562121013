import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DevelopmentPlanHttpService } from '@services/http/DevelopmentPlanHttpService';
import { DevelopmentPlanActivityHttpService } from '@services/http/DevelopmentPlanActivityHttpService';
import { DevelopmentPlanStepHttpService } from '@services/http/DevelopmentPlanStepHttpService';
import { AsyncDetail } from '@rest/AsyncDetail';
import { DevelopmentPlan } from '@models/tracks/development-plan';
import { StaticTextField } from '@components/common/static-textfield-group/static-textfield-group.component';
import { AsyncList } from '@rest/AsyncList';
import { DevelopmentPlanActivity } from '@models/tracks/development-plan-activity';
import { DevelopmentPlanStep } from '@models/tracks/development-plan-step';
import { MatDialog } from '@angular/material/dialog';
import { AddCompetenceFromLibraryDialogComponent } from '../../../../teams/components/team-main/team-specialities/team-speciality-detail/add-competence-from-library-dialog/add-competence-from-library-dialog.component';
import { AddEditPdpActivityDialogComponent } from './add-edit-pdp-activity-dialog/add-edit-pdp-activity-dialog.component';
import { UntypedFormControl } from '@angular/forms';
import { get_full_parent_path } from '../../../../../utils/getFullParentUrl';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-pdp-details',
  templateUrl: './pdp-details.component.html',
  styleUrls: ['./pdp-details.component.css'],
})
export class PdpDetailsComponent implements OnInit {
  pdpUuid: string;

  pdp: AsyncDetail<DevelopmentPlan>;
  pdpActivities: AsyncList<DevelopmentPlanActivity>;
  pdpSteps: AsyncList<DevelopmentPlanStep>;

  pdpInfo: StaticTextField[];

  expandedActivity: string;

  stepControl = new UntypedFormControl('');

  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _titleService: TitleService,
    private _developmentPlanHttpService: DevelopmentPlanHttpService,
    private _developmentPlanActivityHttpService: DevelopmentPlanActivityHttpService,
    private _developmentPlanStepHttpService: DevelopmentPlanStepHttpService
  ) {}

  ngOnInit(): void {
    this._titleService.set_title('План развития');
    this.pdpUuid = this._activatedRoute.snapshot.paramMap.get('pUuid');
    this._loadPdp();
    this._loadPdpActivities();
    this._loadPdpSteps();
  }

  private _loadPdp(): void {
    this.pdp = new AsyncDetail<DevelopmentPlan>(
      this.pdpUuid,
      this._developmentPlanHttpService
    );
    this.pdp.requestParams = {
      params: {
        expand: 'responsible_user',
      },
    };
    this.pdp.load().subscribe(() => {
      this._setPdpInfo();
    });
  }

  private _loadPdpActivities(): void {
    this.pdpActivities = new AsyncList<DevelopmentPlanActivity>(
      this._developmentPlanActivityHttpService
    );
    this.pdpActivities.setRequestParams({
      params: {
        pdp: this.pdpUuid,
        expand: 'things',
      },
    });
    this.pdpActivities.load().subscribe(() => {
      const ongoingActivity = this.pdpActivities.state.items.find(
        (activity) => !activity.is_completed
      );

      if (ongoingActivity) this.onActivityExpand(ongoingActivity.uuid);
    });
  }

  private _loadPdpSteps(): void {
    this.pdpSteps = new AsyncList<DevelopmentPlanStep>(
      this._developmentPlanStepHttpService
    );
    this.pdpSteps.setRequestParams({
      params: {
        pdp: this.pdpUuid,
        expand: 'things'
      },
    });
    this.pdpSteps.load();
  }

  private _setPdpInfo(): void {
    const pdpDeadline = new Date(this.pdp.state.item.due_date);
    this.pdpInfo = [
      {
        name: 'Срок выполнения',
        text: `${pdpDeadline.getDate()} ${pdpDeadline.toLocaleString(
          'default',
          {
            month: 'short',
          }
        )}`,
      },
      {
        name: 'Помогает',
        text: `${
          this.pdp.state.item.responsible_user.last_name
        } ${this.pdp.state.item.responsible_user.first_name.slice(0, 1)}.`,
      },
    ];
  }

  get completedActivities(): DevelopmentPlanActivity[] {
    return this.pdpActivities.state.items.filter(
      (activity) => activity.is_completed
    );
  }

  get ongoingActivities(): DevelopmentPlanActivity[] {
    return this.pdpActivities.state.items.filter(
      (activity) => !activity.is_completed
    );
  }

  onActivityExpand(activityUuid: string): void {
    if (this.expandedActivity === activityUuid) {
      this.expandedActivity = null;
    } else {
      this.expandedActivity = activityUuid;
    }
  }

  onDevelopmentPlanActivityAdd(): void {
    this._dialog
      .open(AddEditPdpActivityDialogComponent, {
        data: {
          dialogType: 'activity',
          pdpUuid: this.pdpUuid,
          activity: null,
        },
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) {
          this.pdpActivities.load().subscribe(() => this.pdp.load());
        }
      });
  }

  onDevelopmentPlanActivityEdit(activity: DevelopmentPlanActivity): void {
    this._dialog
      .open(AddEditPdpActivityDialogComponent, {
        data: {
          dialogType: 'activity',
          pdpUuid: this.pdpUuid,
          activity: activity,
        },
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) {
          this.pdpActivities.load().subscribe(() => this.pdp.load());
        }
      });
  }

  onDevelopmentStepAdd(activity: DevelopmentPlanActivity): void {
    this._dialog
      .open(AddEditPdpActivityDialogComponent, {
        data: {
          dialogType: 'step',
          pdpUuid: this.pdpUuid,
          activity: activity,
        },
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) {
          this.pdpActivities.load().subscribe(() => this.pdp.load());
          this._loadPdpSteps();
        }
      });
  }

  onDevelopmentStepEdit(step: DevelopmentPlanStep): void {
    this._dialog
      .open(AddEditPdpActivityDialogComponent, {
        data: {
          dialogType: 'step',
          pdpUuid: this.pdpUuid,
          step: step,
        },
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) {
          this.pdpActivities.load().subscribe(() => this.pdp.load());
          this._loadPdpSteps();
        }
      });
  }

  getActivitySteps(activity: DevelopmentPlanActivity): DevelopmentPlanStep[] {
    return this.pdpSteps.state.items.filter(
      (step) => step.activity === activity.uuid
    );
  }

  onActivityDelete(activity: DevelopmentPlanActivity): void {
    this._developmentPlanActivityHttpService
      .delete(activity.uuid)
      .subscribe(() => {
        this.pdpActivities.state.items.splice(
          this.pdpActivities.state.items.indexOf(
            this.pdpActivities.state.items.find(
              (pdpActivity) => pdpActivity.uuid === activity.uuid
            )
          ),
          1
        );
        this.pdp.load();
      });
  }

  onStepCompletionToggle(step: DevelopmentPlanStep): void {
    this._developmentPlanStepHttpService
      .update(step.uuid, {
        is_completed: !step.is_completed,
      })
      .subscribe((response: DevelopmentPlanStep) => {
        this.pdpSteps.state.items.find(
          (pdpStep) => pdpStep.uuid === step.uuid
        ).is_completed = response.is_completed;
        this.pdpActivities.load().subscribe(() => this.pdp.load());
      });
  }

  onStepDelete(step: DevelopmentPlanStep): void {
    this._developmentPlanStepHttpService.delete(step.uuid).subscribe(() => {
      this.pdpSteps.state.items.splice(
        this.pdpSteps.state.items.indexOf(
          this.pdpSteps.state.items.find(
            (pdpStep) => pdpStep.uuid === step.uuid
          )
        ),
        1
      );
      this.pdpActivities.load().subscribe(() => this.pdp.load());
    });
  }

  onBackClick(): void {
    history.back();
  }

  onPlanEdit(): void {
    this._router.navigate([`/account/${this.pdpUuid}/pdp-edit`]);
  }

  onPlanDelete(): void {
    this.pdp
      .update({
        status: 'deleted',
      })
      .subscribe(() => {
        this._router.navigate(['/account/pdp']);
      });
  }

  getFullParentPath = get_full_parent_path;
}
