import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thing-create',
  templateUrl: './thing-create.component.html',
  styleUrls: ['./thing-create.component.scss'],
})
export class ThingCreateComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
