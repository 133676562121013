<div class="router-outlet-container">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="app-access-denied-container">
      <div class="app-access-denied-icon">
        <mat-icon style="font-size: 3rem"> lock </mat-icon>
      </div>
      <div class="app-access-denied-title">Доступ запрещен</div>
      <div class="app-access-denied-text">
        <p>Для вашего аккаунта доступ к этой странице ограничен</p>
        <p>
          Вероятно, у вас нет прав на просмотр или запрашиваемый объект удален
        </p>
      </div>
    </div>
  </div>
</div>
