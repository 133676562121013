import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyHttpService } from '@services/http/CompanyHttpService';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Company } from '@models/projects/company';
import { AsyncList } from '@rest/AsyncList';
import { Project } from '@models/projects/project';
import { ProjectHttpService } from '@services/http/ProjectHttpService';
import { get_full_parent_path } from '../../../../utils/getFullParentUrl';
import { MatDialog } from '@angular/material/dialog';
import { CreateProjectDialogComponent } from '@components/common/create-project-dialog/create-project-dialog.component';

@Component({
  selector: 'app-company-projects',
  templateUrl: './company-projects.component.html',
  styleUrls: ['./company-projects.component.css'],
})
export class CompanyProjectsComponent implements OnInit {
  companyUuid: string;

  company: AsyncDetail<Company>;
  companyProjects: AsyncList<Project>;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _dialog: MatDialog,
    private _companyHttpService: CompanyHttpService,
    private _projectHttpService: ProjectHttpService
  ) {}

  ngOnInit(): void {
    this.companyUuid = this._activatedRoute.snapshot.paramMap.get('uuid');
    this.company = new AsyncDetail<Company>(
      this.companyUuid,
      this._companyHttpService
    );
    this.company.load();
    this._loadCompanyProjects();
  }

  private _loadCompanyProjects(): void {
    this.companyProjects = new AsyncList<Project>(this._projectHttpService);
    this.companyProjects.setRequestParams({
      params: {
        company: this.companyUuid,
      },
    });
    this.companyProjects.load();
  }

  onBackClick(): void {
    history.back();
  }

  onProjectCreateClick(): void {
    this._dialog.open(CreateProjectDialogComponent, {
      data: {
        company: this.company.state.item,
        submit: (project: Project) => {
          this.companyProjects.state.items.push(project);
        },
      },
    });
  }

  onProjectClick(project: Project): void {
    this._router.navigate([
      `${this.getFullParentPath(this._activatedRoute)}/project/${project.uuid}`,
    ]);
  }

  getFullParentPath = get_full_parent_path;
}
