import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { testDataAttributes } from '@test-utils';

import { TitleService } from './services/title.service';
import { LoggingService } from './services/logging.service';
import { AuthService } from './services/auth/auth.service';
import { AppService } from './services/app/app.service';
import { SettingsService } from '@services/system/settings/settings.service';
import { ContentService } from '@services/system/settings/content.service';

import { User } from './models/user/user';

import { environment } from 'src/environments/environment';
import { TeamMembership } from '@models/teams/team-membership';
import { DirectionHttpService } from '@services/http/DirectionHttpService';
import { Domain } from '@models/ontology/domain';
import { AsyncList } from '@rest/AsyncList';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  public readonly app_name = 'Starsmap';
  public testDataAttributes = testDataAttributes;

  custom_svg_icons = ['library_add_check'];

  constructor(
    private router: Router,
    private title_service: TitleService,
    private logging_service: LoggingService,
    private app_service: AppService,
    private auth_service: AuthService,
    private _settingsService: SettingsService,
    private _contentService: ContentService,
    private _directionHttpService: DirectionHttpService,
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer
  ) {
    this._set_custom_svg_icons();
  }

  ngOnInit() {
    this.title_service.set_app_name(this.app_name);
    this.title_service.reset_title();
    this._settingsService.loadSettings().then(() => {});
    this._contentService.loadSettings().then(() => {});
    this.app_service.initialize();
  }

  private _set_custom_svg_icons(): void {
    this.custom_svg_icons.forEach((name) => {
      this._matIconRegistry.addSvgIcon(
        `custom_${name}`,
        this._domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/icons/${name}.svg`
        )
      );
    });
  }

  public get is_app_ready(): boolean {
    return this.app_service.is_app_ready();
  }

  public get useNewLayout(): boolean {
    return this.app_service.useNewLayout;
  }

  public get header_logo_url(): string {
    return environment.ui.logo_url;
  }

  public get user(): User {
    return this.auth_service.get_current_user();
  }

  public get is_logged_in(): boolean {
    return this.auth_service.is_logged_in();
  }

  public get is_user_admin() {
    return this.auth_service.is_admin();
  }

  public get is_user_owner() {
    const userMemberships: TeamMembership[] =
      this.auth_service.get_user_memberships().data;
    return !!userMemberships.find((membership) => membership.role === 'owner');
  }

  public get user_uuid() {
    return this.auth_service.get_current_user_uuid();
  }

  public get is_onboarding_complete() {
    return this.auth_service.is_onboarding_complete();
  }

  public get user_unread_counter() {
    return this.app_service.user_unread_counter;
  }

  public on_button_auth_debug(): void {
    this.logging_service.debug(
      `${this.constructor.name} debug: user figerprint`
    );
    console.log(this.auth_service.debug_get_user_data());
  }

  public on_button_auth_login(): void {
    this.logging_service.debug(`${this.constructor.name} on_button_auth_login`);
    this.auth_service.login();
  }

  public on_button_auth_logout(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_auth_logout`
    );
    this.auth_service.logout();
  }

  public on_click_header_logo(): void {
    if (this.is_logged_in) {
      if (this.is_onboarding_complete) {
        this.router.navigate(['/dashboard']);
      } else {
        this.router.navigate(['/onboarding']);
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  public on_click_header_user(): void {
    if (this.is_onboarding_complete) {
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/onboarding']);
    }
  }

  public on_click_new_header_logo(): void {
    if (this.is_logged_in) {
      if (this.is_onboarding_complete) {
        this.router.navigate([`/account/user-profile/${this.user_uuid}`]);
      } else {
        this.router.navigate(['/onboarding']);
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  public on_click_new_header_user(): void {
    if (this.is_onboarding_complete) {
      this.router.navigate(['account', 'user-profile', this.user_uuid]);
    } else {
      this.router.navigate(['/onboarding']);
    }
  }

  onLibraryNav(): void {
    localStorage.setItem('libraryCompetenceTreeExpansion', '');
    localStorage.setItem('libraryActiveTabIndex', '');
    this.router.navigate(['/library']);
  }
}
