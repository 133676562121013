import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { MatrixLoadingTime } from '@models/stats/matrix-loading-time';

@Injectable({
  providedIn: 'root',
})
export class MatrixLoadingStatsHttpService extends ViewSetHttpService<MatrixLoadingTime> {
  constructor(_http: HttpClient) {
    super(_http);
  }

  public viewSetUrl = 'stats/matrix_loading_time';
}
