import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-speciality-group-create-page',
  templateUrl: './speciality-group-create.page.html',
  styleUrls: ['./speciality-group-create.page.css'],
})
export class SpecialityGroupCreatePage implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
