import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-review-assessment-resolution-header',
  templateUrl: './review-assessment-resolution-header.component.html',
  styleUrls: ['./review-assessment-resolution-header.component.css'],
})
export class ReviewAssessmentResolutionHeaderComponent {
  @Input() title: string;
  @Input() showProgress: boolean;
  @Input() progress: number;
  @Input() reviewUuid: string;
  @Input() isCloseButtonVisible = true;
  @Input() isCloseButtonDisabled = false;
  @Input() closeButtonText: string;
  @Output() closeEntity: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _router: Router) {}

  redirectToReview(): void {
    this._router.navigate([`/review/${this.reviewUuid}`]);
  }

  onCloseClick(): void {
    this.closeEntity.emit();
  }
}
