import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { LoggingService } from 'src/app/services/logging.service';
import { TitleService } from 'src/app/services/title.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { SpecialityService } from 'src/app/services/specialities/speciality.service';
import { Speciality } from 'src/app/models/specialities/speciality';


@Component({
  selector: 'app-speciality-create',
  templateUrl: './speciality-create.component.html',
  styleUrls: ['./speciality-create.component.css']
})
export class SpecialityCreateComponent implements OnInit {

  speciality_create_form: UntypedFormGroup;

  private uistate = {
    speciality_create_form_loading: false,
  };

  constructor(
    private router: Router,
    private form_builder: UntypedFormBuilder,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private title_service: TitleService,
    private speciality_service: SpecialityService,
  ) {
    this.create_form();
  }

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.title_service.set_title('Новая специальность');
  }

  public get is_data_loaded(): boolean {
    return true;
  }

  public get is_speciality_create_form_loading(): boolean {
    return this.uistate.speciality_create_form_loading;
  }

  private create_form() {
    this.speciality_create_form = this.form_builder.group({
      speciality_name: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(128)
      ]],
      speciality_description: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1024)
      ]],
    });
  }

  public on_create(): void {
    this.uistate.speciality_create_form_loading = true;
    this.logging_service.debug(`${this.constructor.name} creating speciality...`);
    const speciality_data = {
      name: this.speciality_create_form.value.speciality_name,
      description: this.speciality_create_form.value.speciality_description,
    };
    this.speciality_service.create(speciality_data).subscribe(
      response => {
        const speciality = response as Speciality;
        this.logging_service.debug(`${this.constructor.name} successfully created speciality`);
        this.alert_service.success('Специальность создана', { keepAfterRouteChange: true });
        this.router.navigate([`/admin/specialities/speciality/${speciality.uuid}`]);
      },
      error => {
        this.logging_service.debug(`${this.constructor.name} speciality creation failed`);
        this.alert_service.error('Ошибка создания специальности');
        this.uistate.speciality_create_form_loading = false;
      }
    );
  }

}
