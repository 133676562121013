<h3>Группы компетенций</h3>

<div fxLayout="column">
  <a class="library-item" routerLink="/library/domains/root" fxFlex>
    <div class="library-item__name">Все группы компетенций</div>
  </a>
  <app-library-item fxFlex
    *ngFor="let domain of domains.state.items"
    [item]="serializeDomain(domain)"
  ></app-library-item>
</div>
