import { Component, Input, OnInit } from '@angular/core';
import { AsyncList } from '@rest/AsyncList';
import { Team } from '@models/teams/team';
import { LoggingService } from '../../../../../../services/logging.service';
import { AlertService } from '../../../../../../services/ui/ui-alert.service';
import { AuthService } from '../../../../../../services/auth/auth.service';
import { User } from '@models/user/user';

@Component({
  selector: 'app-teams-dashboard-list-unverified',
  templateUrl: './teams-dashboard-list-unverified.component.html',
  styleUrls: [
    './teams-dashboard-list-unverified.component.css',
    '../teams-dashboard.component.css',
  ],
})
export class TeamsDashboardListUnverifiedComponent implements OnInit {
  @Input() teams: AsyncList<Team>;
  @Input() unverifiedTeams: Team[];

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private auth_service: AuthService
  ) {}

  ngOnInit(): void {}

  verifyTeam(teamUuid: string): void {
    this.teams
      .update(teamUuid, {
        is_verified: 'True',
        verified_by: this.auth_service.get_current_user_uuid(),
        date_verified: new Date().toISOString(),
      })
      .subscribe(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} save new is_verified for ${teamUuid}`
          );
          this.alert_service.error(`Команда верифицирована`);
        },
        (err) => {
          this.logging_service.debug(
            `${this.constructor.name} failed save new is_verified for ${teamUuid}`
          );
          this.alert_service.error(`Ошибка верификации ${err.status}`);
        }
      );
  }

  getCreatedByText(team: Team): string {
    const user = team.created_by as User;
    if (user) return `Создана: ${user.last_name} ${user.first_name}`;
    return ``;
  }
}
