<div *ngIf='companies?.state.isLoaded' class='companies-container'>
  <div class='companies-title'>Клиенты</div>
  <div>
    <div
      fxLayoutAlign='start center'
      class='company-button-container'
      (click)='onCompanyAdd()'
    >
      <button
        mat-raised-button
        class='add-company-button'
        color='primary'
      >
        <mat-icon>add_circle_outline</mat-icon>
      </button>
      <span class='add-company-button-label'>Добавить</span>
    </div>
    <div fxLayout='column' fxLayoutGap='8px'>
      <div
        fxLayout='row'
        fxLayoutAlign='start center'
        class='company-container'
        *ngFor='let company of companies.state.items'
        (click)='onCompanyClick(company)'
      >
        <div fxFlex='30' class='company-container-text'>{{ company.name }}</div>
        <div fxFlex='40'></div>
        <span
          fxFlex='30'
          fxLayoutAlign='center center'
          class='company-container-text'
        >
          {{ company.description }}
        </span>
      </div>
    </div>
  </div>
</div>
