import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth/auth.service';
import { User } from '@models/user/user';
import { AsyncList } from '@rest/AsyncList';
import { Team } from '@models/teams/team';
import { TeamHttpService } from '@services/http/TeamHttpService';
import { AcceptDialogComponent } from '../../../common-ui-elements/components/accept-dialog/accept-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TeamMembershipHttpService } from '@services/http/TeamMembershipHttpService';
import { TeamMembership } from '@models/teams/team-membership';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { SpecialityUserRelationHttpService } from '@services/http/SpecialityUserRelationHttpService';
import { combineLatest } from 'rxjs';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityUserRelation } from '@models/specialities/speciality-user-relation';
import { SpecialityGrade } from '@models/specialities/speciality-grade';
import {
  TeamCreateDialogComponent,
  TeamData,
} from '@components/common/team-create-dialog/team-create-dialog.component';
import { AppService } from '../../../../services/app/app.service';
import { LoggingService } from '../../../../services/logging.service';
import { UserService } from '../../../../services/user/user.service';
import { AlertService } from '../../../../services/ui/ui-alert.service';
import { Router } from '@angular/router';
import { TeamEmailInvitationHttpService } from '@services/http/TeamEmailInvitationHttpService';
import { TeamEmailInvitation } from '@models/teams/team-email-invitation';
import { OnboardingTeamsDialogComponent } from '@components/rosbank/onboarding-teams-dialog/onboarding-teams-dialog.component';

interface TeamSpecialitiesMap {
  [teamUuid: string]: {
    selectedSpeciality: Speciality;
    selectedGrade: SpecialityGrade;
  };
}

@Component({
  selector: 'app-onboarding-main-new',
  templateUrl: './onboarding-main-new.component.html',
  styleUrls: ['./onboarding-main-new.component.css'],
})
export class OnboardingMainNewComponent implements OnInit {
  user: User;
  userInvitations: AsyncList<TeamEmailInvitation>;
  teams: AsyncList<Team>;
  teamMemberships: AsyncList<TeamMembership>;

  adminTeams: Team[];
  guildTeams: Team[];
  agileTeams: Team[];

  isOnboardingReady = false;

  teamsDataReady = false;

  filteredTeams: Team[];
  teamSpecialitiesMap: TeamSpecialitiesMap;
  specialities: Speciality[];
  specialityUserRelations: AsyncList<SpecialityUserRelation>;

  specialitiesReady = false;

  isOnboardingComplete = false;

  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    private _authService: AuthService,
    private _appService: AppService,
    private _loggingService: LoggingService,
    private _alertService: AlertService,
    private _userService: UserService,
    private _teamHttpService: TeamHttpService,
    private _teamMembershipHttpService: TeamMembershipHttpService,
    private _specialityHttpService: SpecialityHttpService,
    private _specialityUserRelationHttpService: SpecialityUserRelationHttpService,
    private _teamEmailInvitationHttpService: TeamEmailInvitationHttpService
  ) {}

  ngOnInit(): void {
    this.user = this._authService.get_current_user();
    this.userInvitations = new AsyncList<TeamEmailInvitation>(
      this._teamEmailInvitationHttpService
    );
    this.userInvitations.setRequestParams({
      params: {
        email: this.user.email,
      },
    });
    this.userInvitations.load().subscribe(() => {
      if (
        this.userInvitations.state.items.filter(
          (emailInvitation) => emailInvitation.status !== 3
        ).length > 0
      ) {
        this._userService
          .update(this.user.uuid, {
            is_onboarding_complete: true,
          })
          .subscribe(() => {
            this.redirectToUserProfile();
          });
      } else {
        if (!this._authService.is_admin() && this.user.is_onboarding_complete) {
          this.redirectToUserProfile();
        } else {
          this.isOnboardingReady = true;
          this.specialityUserRelations = new AsyncList<SpecialityUserRelation>(
            this._specialityUserRelationHttpService
          );
          this.specialityUserRelations.setRequestParams({
            params: {
              user: this.user.uuid,
            },
          });
          this._loadTeams();
          this._loadMemberships();
        }
      }
    });
  }

  private _loadTeams(): void {
    this.teams = new AsyncList<Team>(this._teamHttpService);
    this.teams.setRequestParams({
      params: {
        expand: 'members',
      },
    });
    this.teams.load().subscribe(() => this._filterTeams());
  }

  private _loadMemberships(): void {
    this.teamMemberships = new AsyncList<TeamMembership>(
      this._teamMembershipHttpService
    );
    this.teamMemberships.setRequestParams({
      params: {
        user: this.user.uuid,
        page_size: 1000,
      },
    });
    this.teamMemberships.load();
  }

  private _filterTeams(): void {
    // this.teams.state.items.forEach((team) => {
    //   if (!team.parent) {
    //     const firstLevelTeamData = { ...team, childrenCount: 0 };
    //     this.firstLevelTeamsData.push(firstLevelTeamData);
    //     this._countChildTeams(firstLevelTeamData, team);
    //   }
    // });
    this.adminTeams = this.teams.state.items.filter(
      (team) => team.team_type === 'admin'
    );
    this.guildTeams = this.teams.state.items.filter(
      (team) => team.team_type === 'guild'
    );
    this.agileTeams = this.teams.state.items.filter(
      (team) => team.team_type === 'agile'
    );
    this.teamsDataReady = true;
  }

  onTeamCreate(): void {
    this._dialog.open(TeamCreateDialogComponent, {
      data: {
        teams: this.teams.state.items,
        isTypeHidden: true,
        isOnboarding: true,
        onCreateTeam: (formData: TeamData) => {
          this.createTeam(formData);
        },
      },
    });
  }

  createTeam(formData: TeamData): void {
    const teamData = {
      name: formData.teamName,
      description: formData.teamDescription,
      parent: formData.teamParent.uuid,
      created_by: this._authService.get_current_user_uuid(),
    };
    this._teamHttpService.create({ ...teamData }).subscribe({
      next: (team) => {
        this._loggingService.debug(
          `${this.constructor.name} successfully created team`
        );
        const membershipData = {
          team: team.uuid,
          user: this._authService.get_current_user_uuid(),
          role: 'owner',
        };

        this._teamMembershipHttpService
          .create({ ...membershipData })
          .subscribe(() => {
            this._appService.load_user_memberships();
            this._userService.get_edit_teams().subscribe((response) => {
              this._loggingService.debug(
                `${this.constructor.name} fetched user edit teams`
              );
              this._authService.set_user_edit_teams(response);
            });
            this._loadTeams();
            this._loadMemberships();
          });
      },
      error: (error) => {
        this._loggingService.debug(
          `${this.constructor.name} team creation failed`
        );
        this._alertService.error('Ошибка создания команды');
      },
    });
  }

  onTeamSelectStep(): void {
    this._dialog.open(OnboardingTeamsDialogComponent);
  }

  onSpecialitiesSelectStep(): void {
    this.specialitiesReady = false;
    this.filteredTeams = [];
    this.teamSpecialitiesMap = {};
    this.teamMemberships.setRequestParams({
      params: {
        user: this.user.uuid,
        page_size: 1000,
        expand: 'team',
      },
    });
    this.teamMemberships.load().subscribe(() => {
      combineLatest([
        this._specialityHttpService.getTeamsSpecialities(
          this.teamMemberships.state.items.map(
            (membership) => (membership.team as Team).uuid
          ) as string[]
        ),
        this.specialityUserRelations.load(),
      ]).subscribe((result) => {
        // excluding already user related specialities
        this.specialities = result[0].filter(
          (speciality) =>
            !this.specialityUserRelations.state.items.find(
              (relation) => relation.speciality === speciality.uuid
            )
        );
        // this.specialities = result[0];
        this.teamMemberships.state.items.forEach((membership) => {
          const team = membership.team as Team;
          const teamUuid = team.uuid;
          if (this.getTeamSpecialities(teamUuid).length) {
            this.filteredTeams.push(team);
            this.teamSpecialitiesMap[teamUuid] = {
              selectedSpeciality: null,
              selectedGrade: null,
            };
          }
        });
        this.specialitiesReady = true;
      });
    });
  }

  getTeamSpecialities(teamUuid: string): Speciality[] {
    return this.specialities.filter(
      (speciality) => speciality.team === teamUuid
    );
  }

  getSelectedTeamSpeciality(teamUuid: string): Speciality {
    return this.teamSpecialitiesMap[teamUuid].selectedSpeciality;
  }

  onSpecialitySelectChange(teamUuid: string, speciality: Speciality): void {
    this.teamSpecialitiesMap[teamUuid].selectedSpeciality = speciality;
    this.teamSpecialitiesMap[teamUuid].selectedGrade = null;
  }

  onGradeSelectChange(teamUuid: string, grade: SpecialityGrade): void {
    this.teamSpecialitiesMap[teamUuid].selectedGrade = grade;
  }

  get isSpecialitiesStepCompleted(): boolean {
    let isCompleted = true;
    Object.values(this.teamSpecialitiesMap).forEach((specialityData) => {
      if (!specialityData.selectedSpeciality || !specialityData.selectedGrade)
        isCompleted = false;
    });
    return isCompleted;
  }

  isSpecialityWithGradeSelected(teamUuid: string): boolean {
    const specialityData = this.teamSpecialitiesMap[teamUuid];
    return !!(
      specialityData.selectedSpeciality && specialityData.selectedGrade
    );
  }

  onPrevStep(): void {
    this._loadMemberships();
  }

  onEndStep(): void {
    const specialities_data = Object.values(this.teamSpecialitiesMap).map(
      (item) => {
        return {
          speciality: item.selectedSpeciality.uuid,
          grade: item.selectedGrade.uuid,
        };
      }
    );
    this._specialityUserRelationHttpService
      .createMultipleWithGrades(specialities_data, this.user.uuid, true)
      .subscribe(() => {
        this._userService
          .update(this.user.uuid, { is_onboarding_complete: true })
          .subscribe(() => {
            this.isOnboardingComplete = true;
          });
      });
  }

  onDocClick(): void {
    this._router.navigate([`docs/tutorial/quickstart`]);
  }

  onSupportClick(): void {
    // route?? then change "profile" to "support"
    this._router.navigate([`account/user-profile/${this.user.uuid}`]);
  }

  onProfileClick(): void {
    this._router.navigate([`account/user-profile/${this.user.uuid}`]);
  }

  redirectToUserProfile(): void {
    window.location.replace(
      `${window.location.origin}/account/user-profile/${this.user.uuid}`
    );
  }
}
