<app-with-processing
  [processing]='
    specialities?.state.isProcessing ||
    librarySpecialities?.state.isProcessing ||
    !parentSpecialitiesReady
  '
>
  <div
    class='team-specialities'
    fxLayout='column'
    *ngIf='
      specialities?.state.isLoaded &&
      librarySpecialities?.state.isLoaded &&
      parentSpecialitiesReady
    '
  >
    <div class='title'>Специальности</div>
    <div class='specialities-container' fxLayout='column'>
      <div
        *ngFor='let speciality of specialities.state.items'
        fxLayout='row'
        fxLayoutAlign='start center'
        class='speciality-container'
      >
        <a
          class='speciality-link'
          fxFlex='90'
          (click)='onSpecialityLinkClick(speciality.uuid)'
        >
          <span class='speciality-name'>{{ speciality.name }}</span>
        </a>
        <button
          mat-icon-button
          (click)='onSpecialityEdit(speciality)'
          class='speciality-action'
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)='onSpecialityDelete(speciality)'
          class='speciality-action'
        >
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
    </div>
    <div fxLayout='row'>
      <div
        fxLayoutAlign='start center'
        class='speciality-button-container'
        (click)='onLibrarySpecialityAdd()'
      >
        <button
          mat-raised-button
          color='primary'
          class='add-speciality-button'
        >
          <mat-icon>add_circle_outline</mat-icon>
        </button>
        <span class='add-speciality-button-label'
        >Добавить новую специальность</span
        >
      </div>
    </div>
  </div>
</app-with-processing>
