import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { ThingHttpService } from '@services/http/ThingHttpService';
import { AsyncList } from '@rest/AsyncList';

import { Thing } from '@models/ontology/thing';
import { Team } from '@models/teams/team';
import { ThingLevel } from '@models/ontology/thing-level';

import { TeamHttpService } from '@services/http/TeamHttpService';
import {
  SearchResult,
  SearchResultUser,
} from '@models/teams/macth-users-search';
import { Domain } from '@models/ontology/domain';

interface ThingInfo {
  icon: 'group' | 'public';
  sourceName: string;
  domainName: string;
  hasParentDomain: boolean;
}

@Component({
  selector: 'app-team-match-users',
  templateUrl: './team-match-users.component.html',
  styleUrls: ['./team-match-users.component.css'],
})
export class TeamMatchUsersComponent implements OnInit {
  @Input() teamUuid = '0';

  libraryThings: AsyncList<Thing>;
  allTeams: AsyncList<Team>;

  searchThings: AsyncList<Thing>;
  searchTeams: AsyncList<Team>;

  things: Thing[];
  teams: Team[];

  thingsOptions: Thing[] = [];
  form: UntypedFormGroup;
  searchResultUsers: SearchResultUser[];
  searchResult: SearchResult;
  isProcessing = false;

  private readonly _expandThingFields = 'levels,team,domain';

  constructor(
    private _thingHttpService: ThingHttpService,
    private _teamHttpService: TeamHttpService,
    private _formBuilder: UntypedFormBuilder
  ) {
    this.searchTeams = new AsyncList<Team>(this._teamHttpService);
    this.searchThings = new AsyncList<Thing>(this._thingHttpService);
  }

  ngOnInit(): void {
    this._initForm();
    this.allTeams = new AsyncList<Team>(this._teamHttpService);
    this.libraryThings = new AsyncList<Thing>(this._thingHttpService);
    this.libraryThings.setRequestParams({
      params: {
        expand: this._expandThingFields,
        team__isnull: 'True',
      },
    });
    this.libraryThings
      .load()
      .subscribe(() => (this.things = this.libraryThings.state.items));

    this.allTeams
      .load()
      .subscribe(() => (this.teams = this.allTeams.state.items));
  }

  private _initForm() {
    this.form = this._formBuilder.group({
      things: [[]],
      teams: [[]],
      thingLevelsMap: [{}],
      thingIsRequiredMap: [{}],
      thingLevelMatchMap: [{}],
      searchStrictMode: [false],
    });
  }

  onSearchThings(name: string): void {
    this.searchThings.setRequestParams({
      params: { search: name, expand: this._expandThingFields },
    });
    this.searchThings.load().subscribe(() => {
      this.things = this.searchThings.state.items.filter(
        (thing) => !this.isOptionSelected(thing)
      );
    });
  }

  onSearchTeams(name: string): void {
    this.searchTeams.setRequestParams({
      params: { search: name },
    });
    this.searchTeams
      .load()
      .subscribe(() => (this.teams = this.searchTeams.state.items));
  }

  isOptionSelected(thing: Thing): boolean {
    return !!this.form.controls.things.value.find(
      (selectedThing) => thing.uuid === selectedThing.uuid
    );
  }

  updateThings($event: Thing[]): void {
    this.form.controls.things.setValue($event);
    this._clearSearchResult();
  }

  updateTeams($event: Team[]): void {
    this.form.controls.teams.setValue($event);
    this._clearSearchResult();
  }

  onSelectThingLevel(thingLevel: ThingLevel): void {
    this.form.controls.thingLevelsMap.setValue({
      ...this.form.value.thingLevelsMap,
      [thingLevel.thing]: thingLevel.uuid,
    });
    this._clearSearchResult();
  }

  onChangeThingIsRequired({
    thing,
    isThingRequired,
  }: {
    thing: Thing;
    isThingRequired: boolean;
  }): void {
    this.form.controls.thingIsRequiredMap.setValue({
      ...this.form.value.thingIsRequiredMap,
      [thing.uuid]: isThingRequired,
    });
    this._clearSearchResult();
  }

  onSelectThingLevelMatch({
    thing,
    thingLevelMatchValue,
  }: {
    thing: Thing;
    thingLevelMatchValue;
  }): void {
    this.form.controls.thingLevelMatchMap.setValue({
      ...this.form.value.thingLevelMatchMap,
      [thing.uuid]: thingLevelMatchValue,
    });
    this._clearSearchResult();
  }

  getThingOptionInfo(thing: Thing): ThingInfo {
    const thingInfo: ThingInfo = {
      icon: thing.team ? 'public' : 'group',
      hasParentDomain: Boolean((thing as Thing<Domain>).domain.parent_domain),
      domainName: (thing as Thing<Domain>).domain.name,
      sourceName: thing?.team?.name || 'Библиотека',
    };

    return thingInfo;
  }

  private _clearRemovedThingKeys<ThingMapValue>(thingMap: {
    [thingUUid: string]: ThingMapValue;
  }): {
    [thingUUid: string]: ThingMapValue;
  } {
    const thingUuidsSet = new Set(
      this.form.value.things.map((thing) => thing.uuid)
    );

    return Object.keys(thingMap).reduce((result, thingUuid: string) => {
      if (thingUuidsSet.has(thingUuid)) {
        result[thingUuid] = thingMap[thingUuid];
      }

      return result;
    }, {});
  }

  private _clearSearchResult() {
    this.searchResult = null;
    this.searchResultUsers = null;
  }

  onCopyToClipboard(): void {
    let resultStr = '';
    this.searchResultUsers.forEach((searchResultUser) => {
      resultStr += `${searchResultUser.user.last_name} ${searchResultUser.user.first_name}: `;
      searchResultUser.competencies.forEach((competence, index) => {
        if (index + 1 === searchResultUser.competencies.length) {
          resultStr += `${(competence.thing as Thing).name} (${
            (competence.thing_level as ThingLevel).title
          }). \n`;
        } else {
          resultStr += `${(competence.thing as Thing).name} (${
            (competence.thing_level as ThingLevel).title
          }), `;
        }
      });
    });
    navigator.clipboard.writeText(resultStr);
  }

  onSubmit(): void {
    this.isProcessing = true;
    this._teamHttpService
      .matchUsers(
        {
          teams: this.form.value.teams,
          things: this.form.value.things,
          thingLevelsMap: this._clearRemovedThingKeys<string>(
            this.form.value.thingLevelsMap
          ),
          thingLevelMatchMap: this._clearRemovedThingKeys<string>(
            this.form.value.thingLevelMatchMap
          ),
          thingIsRequiredMap: this._clearRemovedThingKeys<boolean>(
            this.form.value.thingIsRequiredMap
          ),
        },
        this.teamUuid
      )
      .subscribe(
        (response) => {
          this.isProcessing = false;

          this.searchResult = response.result;
          this.searchResultUsers = Object.values(this.searchResult.users).sort(
            this.sortByCompetenceCount
          );
        },
        () => {
          this.isProcessing = false;
        }
      );
  }

  sortByCompetenceCount = (
    searchResultUserA: SearchResultUser,
    searchResultUserB: SearchResultUser
  ): number => {
    if (
      searchResultUserA.competencies.length >
      searchResultUserB.competencies.length
    ) {
      return -1;
    }
    if (
      searchResultUserA.competencies.length ==
      searchResultUserB.competencies.length
    ) {
      return 0;
    }

    if (
      searchResultUserA.competencies.length <
      searchResultUserB.competencies.length
    ) {
      return 1;
    }
  };
}
