<mat-dialog-content>
  <div class='domains-form-wrapper'>
    <mat-card>
      <h4>{{ getTitleLabel() }}</h4>
      <form>
        <div [formGroup]='form'>
          <div>
            <mat-form-field
              [style.width.rem]='30'
              floatLabel='never'
              appearance='outline'
            >
              <input
                matInput
                placeholder='Название'
                type='text'
                formControlName='name'
                required
              />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field
              [style.width.rem]='30'
              floatLabel='never'
              appearance='outline'
            >
              <input
                matInput
                placeholder='Описание'
                type='text'
                formControlName='description'
                required
              />
            </mat-form-field>
          </div>
          <div *ngIf='data.isThing'>
            <div fxLayout='row'>
              <div class='show-settings'
                   (click)='isAdditionalSettingsShowed = !isAdditionalSettingsShowed' fxLayoutAlign='start center'
                   fxLayoutGap='8px'>
                <mat-icon>{{ !isAdditionalSettingsShowed ? 'expand_more' : 'expand_less' }}</mat-icon>
                <span class='show-settings-text'>Дополнительные настройки</span>
              </div>
            </div>
            <div *ngIf='isAdditionalSettingsShowed'>
              <mat-form-field
                [style.width.rem]='30'
                floatLabel='never'
                appearance='outline'
              >
                <mat-select
                  formControlName='kind'
                  placeholder='Тип'
                  [value]='data.defaultData.kind ? data.defaultData.kind : 0'
                >
                  <mat-option [value]='0'> Тип не выбран</mat-option>
                  <mat-option [value]='1'> Soft skill</mat-option>
                  <mat-option [value]='2'> Практика</mat-option>
                  <mat-option [value]='3'> Программное обеспечение</mat-option>
                  <mat-option [value]='4'> Оборудование</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>

      <div *ngIf='isShowSimilarWarning()'>
        <h2>Не создавайте дубли</h2>
        <div *ngIf='getSimilarElements().domains.length > 0'>
          <label>Похожие домены</label>
          <app-library-item
            *ngFor='let domain of getSimilarElements().domains'
            [item]='serializeDomain(domain)'
          ></app-library-item>
        </div>
        <div *ngIf='getSimilarElements().things.length > 0'>
          <label>Похожие навыки</label>
          <app-library-item
            *ngFor='let thing of getSimilarElements().things'
            [item]='serializeThing(thing)'
          ></app-library-item>
        </div>
      </div>
    </mat-card>
  </div>
</mat-dialog-content>
<div mat-dialog-actions fxLayoutAlign='end center'>
  <button
    class='dialog-button'
    mat-raised-button
    [disabled]='!data.isThing || !data.isEdit'
    (click)='redirectToThingEdit()'
  >
    Страница редактирования
  </button>
  <button class='dialog-button' [mat-dialog-close]='true' mat-raised-button>
    Отмена
  </button>
  <button
    class='dialog-button'
    mat-raised-button
    [disabled]='form.invalid'
    (click)='close()'
  >
    {{ data.isEdit ? 'Обновить' : 'Создать' }}
  </button>
</div>
