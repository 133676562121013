import { Component, Input, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LoggingService } from 'src/app/services/logging.service';
import { TitleService } from 'src/app/services/title.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { CompetenceService } from 'src/app/services/competencies/competence.service';

import { User } from 'src/app/models/user/user';
import { Competence } from 'src/app/models/competencies/competence';


@Component({
  selector: 'app-track-delta-overview',
  templateUrl: './track-delta-overview.component.html',
  styleUrls: ['./track-delta-overview.component.css']
})
export class TrackDeltaOverviewComponent implements OnInit {

  @Input() user: User;

  public user_competencies: Competence[] = [];

  private uistate = {
    user_competencies_loaded: false,
  };

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private title_service: TitleService,
    private competence_service: CompetenceService,
  ) { }

  ngOnInit(): void {
    this.title_service.set_title('Развитие');
    forkJoin([
      this.load_user_competencies(),
      // this.load_user(),
      // this.load_speciality(),
      // this.load_speciality_grades()
    ]).subscribe(() => {
      forkJoin([
        // this.load_user_relations(),
        // this.load_competencies_for_user(),
        // this.load_speciality_domain_claims(),
        // this.load_speciality_competence_claims()
      ]).subscribe(() => {
        // this.choose_default_selected_grade();
        // this.choose_virtual_competencies();
      })
    });
  }

  public get is_data_loaded(): boolean {
    return this.uistate.user_competencies_loaded;
  }

  private load_user_competencies(): Observable<any> {
    return this.competence_service.fetch_by_user_uuid(this.user.uuid).pipe(
      tap(
        response => {
          this.user_competencies = response.results as Competence[];
          this.logging_service.debug(`${this.constructor.name} loaded ${this.user_competencies.length} user competencies`);
          this.uistate.user_competencies_loaded = true;
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to load user competencies`);
          this.alert_service.error(`Ошибка загрузки компетенций: ${err.status}`);
        }
      )
    );
  }

}
