<div *ngIf="!data">loading...</div>
<div
  *ngIf="data"
  class="app-ontologies-things-thing-competence-levels-container"
>
  <div fxLayout="row">
    <div fxFlex>
      <div class="app-ontologies-things-thing-competence-level-histogram">
        <ng-template [ngIf]="competencies.state.items.length">
          <app-histogram-simple
            [chartName]="'thing-competence-level'"
            [axisNameX]="'Уровни владения'"
            [axisLabelsX]="keys"
            [axisNameY]="'Пользователи'"
            [data]="chartHistogramData"
            [sizes]="{ width: 300 }"
          >
          </app-histogram-simple>
          <app-line-simple
            [chartName]="'thing-competence-level-delta'"
            [axisNameX]="''"
            [axisNameY]="'Сумма навыков'"
            [data]="chartLineData"
            [sizes]="{ width: 300 }"
          >
          </app-line-simple>
        </ng-template>
        <div *ngIf="!competencies.state.items.length">
          <div
            class="
              app-ontologies-things-thing-competence-level-histogram-nodata
            "
          >
            Нет данных для отображения гистограммы адаптации
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
