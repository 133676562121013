import { Component, Input, OnInit } from '@angular/core';
import {
  ThingGradeMap,
  UserThingLevelMap,
} from '../speciality-detail/speciality-detail.component';

@Component({
  selector: 'app-speciality-things-tree',
  templateUrl: './speciality-things-tree.component.html',
  styleUrls: ['./speciality-things-tree.component.css'],
})
export class SpecialityThingsTreeComponent implements OnInit {
  @Input() dataSource: any;
  @Input() treeControl: any;
  @Input() hasChild: any;
  @Input() expandDomain: any;
  @Input() thingGradeMap: ThingGradeMap;
  @Input() userThingLevelMap: UserThingLevelMap;
  @Input() isEditable = false;

  constructor() {}

  ngOnInit(): void {
    this.treeControl.expandAll();
  }

  isUserLevelMatchesGrade(gradeLevel: number, thingUuid: string): boolean {
    return this.userThingLevelMap[thingUuid]?.order_number >= gradeLevel;
  }

  setDetailFormConfig(
    thingUuid: string,
    mode: string,
    isThing: boolean
  ): void {}

  remove(thingUuid: string): void {}
}
