<h2 mat-dialog-title>Удалить {{ data.thing_level.title }}?</h2>

<h3 mat-dialog-title>
  При удалении уровня компетенций сбросятся уровни специальностей и
  пользователей
</h3>

<div mat-dialog-actions>
  <button class="dialog-button" [mat-dialog-close]="true" mat-raised-button>
    Удалить
  </button>
  <button class="dialog-button" (click)="close()" mat-raised-button>
    Отмена
  </button>
</div>
