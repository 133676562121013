<ng-template #preloader>
  <app-preloader></app-preloader>
</ng-template>

<div class="app-survey-tree-container">
  <div fxLayout="row">
    <div fxFlex="50%">
      <div *ngIf="current_question">
        <div class="app-survey-tree-question-title">
          <span class="app-survey-tree-question-title-prefix">
            Оцените свой уровень
          </span>
          {{ current_question.title }}
        </div>
        <div
          class="app-survey-tree-question-description"
          *ngIf="current_question.description"
        >
          {{ current_question.description }}
        </div>
      </div>

      <div class="app-survey-tree-answer-options-container">
        <ng-container
          *ngIf="is_data_loaded; then tmpl_answer_options; else preloader"
        >
        </ng-container>
      </div>
    </div>
    <div fxFlex="50%">
      <div class="app-survey-tree-visual-component-container">
        <app-survey-tree-visual-pan
          [survey_questions]="questions"
          [visual_control_subject]="visual_control_subject"
        >
        </app-survey-tree-visual-pan>
      </div>
    </div>
  </div>
</div>

<ng-template #tmpl_answer_options>
  <!-- <div class="app-survey-tree-answer-options-hint">
        Выберите ваш уровень владения компетенцией
    </div> -->

  <div class="app-survey-tree-answer-options-controls">
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <div fxFlex>
        <button
          mat-stroked-button
          [disabled]="previous_question_sequence.length === 0"
          (click)="on_button_prev_question()"
        >
          <mat-icon>skip_previous</mat-icon>
          Назад
        </button>
      </div>
      <div fxFlex>
        <mat-checkbox
          *ngIf="current_question.question_type === 'assert_competence_level'"
          style="font-size: 0.9rem"
          [color]="ui_color"
          [disabled]="is_checkbox_current_question_thing_desired_disabled"
          [checked]="is_checkbox_current_question_thing_desired"
          (click)="on_toggle_current_thing_desired($event)"
        >
          Хочу в этом прокачаться
        </mat-checkbox>
      </div>
      <div fxFlex>
        <button
          mat-stroked-button
          color="primary"
          [disabled]="!is_button_answer_sumbit_enabled"
          (click)="on_submit_answer_option()"
        >
          <mat-icon>skip_next</mat-icon>
          Ответить и продолжить
        </button>
      </div>
    </div>
  </div>

  <div
    *ngFor="let answer_option of current_question_answer_options"
    class="app-survey-tree-answer-options-listitem"
    [class.app-survey-tree-answer-options-listitem-selected]="
      is_answer_option_selected(answer_option)
    "
    (click)="on_select_answer_option(answer_option)"
  >
    <div fxLayout="row">
      <div fxFlex>
        <div class="app-survey-tree-answer-options-listitem-title">
          {{ answer_option.title }}
        </div>
        <div
          class="app-survey-tree-answer-options-listitem-description-container"
          *ngIf="
            selected_answer_option &&
            selected_answer_option.uuid === answer_option.uuid
          "
        >
          <div
            class="app-survey-tree-answer-options-listitem-description"
            [innerHTML]="answer_option.description | newlineToBr"
          ></div>
        </div>
      </div>
      <div fxFlex="2rem">
        <div *ngIf="answer_option.is_answered">
          <mat-icon>check</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <!-- <div>
        <div *ngFor="let q of previous_question_sequence">
            <div *ngIf="q">{{q.title}}</div>
            <div *ngIf="!q">null</div>
        </div>
    </div> -->
</ng-template>
