import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap, delay } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private api_url: string;
  private api_url_base = '/api/1/';

  private api_delay_ms: number;

  private log_prefix = 'ApiService:';

  constructor(
    private http: HttpClient,
    private logging_service: LoggingService
  ) {
    this.api_url = environment.apiUrl;
    this.api_delay_ms = environment.apiDelayMs;
  }

  private get_full_url(uri: string): string {
    return this.api_url + this.api_url_base + uri;
  }

  private get_http_options(params) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // tslint:disable-next-line:object-literal-key-quotes
        // 'Authorization': 'JWT ' + this.account_service.get_token()
      }),
      params,
    };
    return httpOptions;
  }

  public get<T>(uri: string, params?: any) {
    const url = this.get_full_url(uri);
    return this.http
      .get(url, this.get_http_options(params))
      .pipe(delay(this.api_delay_ms))
      .pipe(
        tap((_) =>
          this.logging_service.debug(
            `${this.log_prefix} HTTP GET ${url} ${JSON.stringify(params)}`
          )
        ),
        catchError(this.handleError('get', []))
      );
  }

  public post(uri: string, payload: any, params: any) {
    const url = this.get_full_url(uri);
    return this.http
      .post(url, JSON.stringify(payload), this.get_http_options(params))
      .pipe(delay(this.api_delay_ms))
      .pipe(
        tap((_) =>
          this.logging_service.debug(
            `${this.log_prefix} HTTP POST ${url} ${JSON.stringify(params)}`
          )
        ),
        catchError(this.handleError('post', []))
      );
  }

  public put(uri: string, payload: any, params: any) {
    const url = this.get_full_url(uri);
    return this.http
      .put(url, JSON.stringify(payload), this.get_http_options(params))
      .pipe(delay(this.api_delay_ms))
      .pipe(
        tap((_) =>
          this.logging_service.debug(
            `${this.log_prefix} HTTP PUT ${url} ${JSON.stringify(params)}`
          )
        ),
        catchError(this.handleError('put', []))
      );
  }

  public patch(uri: string, payload: any, params: any) {
    const url = this.get_full_url(uri);
    return this.http
      .patch(url, JSON.stringify(payload), this.get_http_options(params))
      .pipe(delay(this.api_delay_ms))
      .pipe(
        tap((_) =>
          this.logging_service.debug(
            `${this.log_prefix} HTTP PATCH ${url} ${JSON.stringify(params)}`
          )
        ),
        catchError(this.handleError('patch', []))
      );
  }

  public delete(uri: string, params: any) {
    const url = this.get_full_url(uri);
    return this.http
      .delete(url, this.get_http_options(params))
      .pipe(delay(this.api_delay_ms))
      .pipe(
        tap((_) =>
          this.logging_service.debug(
            `${this.log_prefix} HTTP DELETE ${url} ${JSON.stringify(params)}`
          )
        ),
        catchError(this.handleError('delete', []))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      throw error;
    };
  }
}
