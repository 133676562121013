import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AsyncList } from '@rest/AsyncList';
import { Team } from '@models/teams/team';
import { LoggingService } from '../../../../../../services/logging.service';
import { AlertService } from '../../../../../../services/ui/ui-alert.service';
import { TeamHttpService } from '@services/http/TeamHttpService';
import { Router } from '@angular/router';
import { AcceptDialogComponent } from '../../../../../common-ui-elements/components/accept-dialog/accept-dialog.component';
import { Speciality } from '@models/specialities/speciality';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-teams-dashboard-list-all',
  templateUrl: './teams-dashboard-list-all.component.html',
  styleUrls: [
    './teams-dashboard-list-all.component.css',
    '../teams-dashboard.component.css',
  ],
})
export class TeamsDashboardListAllComponent implements OnInit {
  @Input() teams: AsyncList<Team>;

  private _uistate = {
    teamsLoaded: true,
    targetEditTeam: '',
  };

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private _router: Router,
    private _teamService: TeamHttpService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  get_team_edited_in_progress(): string {
    return this._uistate.targetEditTeam;
  }

  resetEditTeam(): void {
    this._uistate.targetEditTeam = '';
  }

  saveNewTeamName(team: Team, newName: string): void {
    this.teams.update(team.uuid, { name: newName }).subscribe(
      (response) => {
        this.logging_service.debug(
          `${this.constructor.name} save new name for ${team.uuid}`
        );
        this.teams.state.items.find((t) => t.uuid === response.uuid).name =
          response.name;
        this.alert_service.error(`Сохранено`);
        this.resetEditTeam();
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed save new name for ${team.uuid}`
        );
        this.alert_service.error(`Ошибка изменения сохранения ${err.status}`);
        this.resetEditTeam();
      }
    );
  }

  public onDeleteButtonClick(e, team: Team): void {
    this._dialog
      .open(AcceptDialogComponent, {
        data: {
          title: `Удалить команду?`,
          message: 'Все дочерние группы будут также удалены!',
          acceptLabel: 'Удалить',
          rejectLabel: 'Отмена',
        },
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) {
          this._teamService.delete(team.uuid).subscribe(
            (response) => {
              this.logging_service.debug(
                `${this.constructor.name} team ${team.uuid} is deleted`
              );
              this._uistate.teamsLoaded = false;
              this.teams.load().subscribe(
                () => {
                  this._uistate.teamsLoaded = true;
                  this.logging_service.debug(
                    `${this.constructor.name} teams loaded successfully`
                  );
                  this.alert_service.error(`Группа удалена`);
                },
                (err) => {
                  this.alert_service.error(
                    `Ошибка при загрузке команд: ${err.status}`
                  );
                }
              );
            },
            (err) => {
              this.logging_service.debug(
                `${this.constructor.name} failed to delete team ${team.uuid}`
              );
              this.alert_service.error(`Ошибка удаления группы`);
            }
          );
        }
      });
  }

  onNavigateTeam(team: Team): void {
    this._router.navigateByUrl(`/admin/teams/${team.uuid}`);
  }

  onEditButtonClick(team: Team): void {
    this._uistate.targetEditTeam = team.uuid;
  }
}
