import { Component, OnInit } from '@angular/core';
import { ImportedDataHttpService } from '@services/http/ImportedDataHttpService';
import { AsyncList } from '@rest/AsyncList';
import { ImportedData } from '@models/ontology/imported-data';
import Tree, { TreeNode } from 'src/app/utils/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { MatDialog } from '@angular/material/dialog';

import {
  AcceptDialogComponent,
  AcceptDialogData,
} from 'src/app/modules/common-ui-elements/components/accept-dialog/accept-dialog.component';

import { ImportedDataEditDialogComponent } from './imported-data-edit-dialog/imported-data-edit-dialog.component';
import { AlertService } from 'src/app/services/ui/ui-alert.service';

export interface DetailFormData {
  name: string;
}

export interface ImportedDataDetailFormConfig {
  titleLabel: string;
  submitLabel: string;
  onSubmit(data: DetailFormData): void;
  onReset(): void;
  defaultData: DetailFormData;
}

export interface AngularDefaultTreeNode {
  expandable: boolean;
  name: string;
  level: number;
  isThing: boolean;
  uuid: string;
  children: TreeNode<ImportedData>[];
}

@Component({
  selector: 'app-upload-domains',
  templateUrl: './upload-domains.component.html',
  styleUrls: ['./upload-domains.component.css'],
})
export class UploadDomainsComponent implements OnInit {
  importedDataList: AsyncList<ImportedData>;
  tree: Tree<ImportedData>;
  uploadState = {
    isProcessing: false,
  };

  formConfig: ImportedDataDetailFormConfig = {
    defaultData: {
      name: '',
    },
    submitLabel: '',
    titleLabel: 'update',
    onSubmit: null,
    onReset: null,
  };

  constructor(
    private _importedDataHttpService: ImportedDataHttpService,
    private _alertService: AlertService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.importedDataList = new AsyncList<ImportedData>(
      this._importedDataHttpService
    );
    this._loadImportedData();
  }

  private _loadImportedData(): void {
    this.importedDataList.load().subscribe((response) => {
      this.tree = new Tree(this.importedDataList.state.items);
      this.dataSource.data = this.tree.getRoot().children;
    });
  }

  public uploadInputChange($event: Event): void {
    this.uploadState.isProcessing = true;
    const file: File = ($event as any).target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('ontology', file);
      this._importedDataHttpService.upload(formData).subscribe(
        () => {
          this._loadImportedData();
          this._alertService.success('Домены загружены');
          this.uploadState.isProcessing = false;
        },
        () => {
          this.uploadState.isProcessing = false;
          this._alertService.error('Не удалось загрузить домены');
        }
      );
    } else {
      this.uploadState.isProcessing = false;
    }
  }

  saveToLibrary(): void {
    this._importedDataHttpService.saveImportedData().subscribe(
      (response) => {
        this._alertService.success('Данные загружены в библиотеку');
        this._loadImportedData();
      },
      () => {
        this._alertService.error('Не удалось загрузить данные в библиотеку');
      }
    );
  }

  clearImportedData(): void {
    this._importedDataHttpService.clearImportedData().subscribe(
      (response) => {
        this._alertService.success('Промежуточные данные удалены');
        this._loadImportedData();
      },
      () => {
        this._alertService.error('Ошибка удаления промежуточных данных');
      }
    );
  }

  // imported data tree methods
  onDialogShow = (
    uuid: string,
    mode: 'update' | 'create' = 'update',
    isThing: boolean
  ): void => {
    const item = this.tree.getNode(uuid).item;
    const isEdit = mode === 'update';

    this._dialog.open(ImportedDataEditDialogComponent, {
      data: {
        thingUuid: uuid,
        isThing: isThing,
        isEdit: isEdit,
        defaultData:
          mode === 'create'
            ? { name: '', description: '' }
            : {
                name: item.name,
              },
        onSubmit: (data: DetailFormData) => {
          if (isEdit) {
            this.importedDataList.update(uuid, data).subscribe((response) => {
              this.tree.getNode(uuid).item = {
                ...this.tree.getNode(uuid).item,
                ...response,
              };
              const node = this.treeControl.dataNodes.find(
                (node) => node.uuid === uuid
              );
              node.name = data.name;
            });
          }

          if (!isEdit) {
            const parentDomain = this.tree.getNode(uuid).item;
            this.importedDataList
              .create({
                ...data,
                parent: parentDomain.uuid,
                kind: isThing ? 'TH' : 'DM',
              })
              .subscribe((response) => {
                this.tree.insertNode(parentDomain?.uuid || 'root', response);
                this._renderTree();
              });
          }
        },
      },
    });
  };

  private _renderTree() {
    const expandedCache = (this.treeControl.dataNodes || []).reduce(
      (cache, node) => {
        return { ...cache, [node.uuid]: this.treeControl.isExpanded(node) };
      },
      {}
    );

    this.dataSource.data = this.tree.getRoot().children;

    // restore expanded nodes on rerender
    this.treeControl.dataNodes.forEach((dataNode) => {
      if (expandedCache[dataNode.uuid]) {
        this.treeControl.expand(dataNode);
      }
    });
  }

  remove = (node: AngularDefaultTreeNode): void => {
    const formConfig: AcceptDialogData = {
      title: `Удалить ${node.name}`,
      message: `Вы уверены, что хотите удалить ${
        node.isThing ? 'компетенцию' : 'домен'
      }?`,
      acceptLabel: 'Удалить',
      rejectLabel: 'Отмена',
    };

    this._dialog
      .open(AcceptDialogComponent, {
        data: formConfig,
        width: '55rem',
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) {
          this.importedDataList.remove(node.uuid).subscribe(() => {
            this.formConfig.onSubmit = null;
            this._loadImportedData();
          });
        }
      });
  };

  expandDomain(uuid: string): void {
    const angularTreeNode = this.treeControl.dataNodes.find(
      (node) => node.uuid === uuid
    );

    if (this.treeControl.isExpanded(angularTreeNode)) {
      return this.treeControl.collapse(angularTreeNode);
    }

    return this.treeControl.expand(angularTreeNode);
  }

  private _transformer = (node: TreeNode<ImportedData>, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.item.name,
      isThing: node.item.kind === 'TH',
      uuid: node.item.uuid,
      level: level,
      children: node.children,
    };
  };

  // angular tree methods
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  hasChild = (_: number, node: AngularDefaultTreeNode): boolean => {
    return node.expandable;
  };

  treeControl = new FlatTreeControl<AngularDefaultTreeNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
}
