<app-with-processing [processing]='!teamsTreeDataReady'>
  <div *ngIf='teamsTreeDataReady' class='teams-dashboard-detail-container'>
    <div fxLayout='row' class='title'>
      <div fxFlex fxLayoutAlign='start center' fxLayoutGap='2rem'>
        <button (click)='onBackClick()' mat-icon-button>
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div fxLayoutAlign='start center' fxLayout='row'>
          {{ parentTeam.name }}
        </div>
      </div>
    </div>
    <div fxLayout='row'>
      <div
        fxLayoutAlign='start center'
        class='add-team-button-container'
        (click)='onTeamAdd()'
      >
        <button
          mat-raised-button
          class='add-team-button'
          color='primary'
        >
          <mat-icon>add_circle_outline</mat-icon>
        </button>
        <span class='add-team-button-label'>Добавить команду</span>
      </div>
    </div>
    <div *ngFor='let node of teamsTreeData'>
      <ng-template
        [ngTemplateOutlet]='teamNodeTemplateRef'
        [ngTemplateOutletContext]='{ node: node }'
      >
      </ng-template>
    </div>
  </div>
</app-with-processing>

<ng-template #teamNodeTemplateRef let-node='node'>
  <div
    fxLayout='column'
    class='team-container'
    [style.background]="node.level % 2 == 0 ? '#FFFFFF' : '#F9F9F9'"
  >
    <div
      fxLayout='row'
      fxFlex
      [style.margin-bottom]="node.isExpanded ? '8px' : ''"
    >
      <div
        fxLayout='column'
        fxFlex
        (click)='navigateToTeam(node)'
        class='team-info'
      >
        <div class='team-name'>{{ node.name }}</div>
        <div class='team-desc'>{{ node.description }}</div>
      </div>
      <div fxLayoutAlign='end center' fxFlex='8rem'>
        <div
          class='count-info'
          [style.background]="node.level % 2 == 0 ? '#F9F9F9' : '#FFFFFF'"
          (click)='redirectToUserTeam(node)'
        >
          {{
          node.children.length
            ? 'Команд: ' + node.children.length
            : 'Участников: ' + node.members.length
          }}
        </div>
      </div>
      <div
        fxLayoutAlign='end center'
        fxFlex='3rem'
        *ngIf='node.children.length'
      >
        <button mat-icon-button (click)='node.isExpanded = !node.isExpanded'>
          <mat-icon>{{
            node.isExpanded ? 'expand_more' : 'chevron_right'
            }}</mat-icon>
        </button>
      </div>
    </div>

    <div>
      <div
        *ngIf='node.children.length && node.isExpanded'
        fxLayout='column'
        fxLayoutGap='8px'
        [style.margin-bottom]="node.isExpanded ? '8px' : ''"
      >
        <ng-template ngFor let-child [ngForOf]='node.children'>
          <ng-template
            [ngTemplateOutlet]='teamNodeTemplateRef'
            [ngTemplateOutletContext]='{ node: child }'
          >
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>
