<div>
  <div fxLayout="row">
    <div fxFlex fxLayoutAlign="start center">
      <span>Направления</span>
    </div>
    <div fxFlex="12rem" fxLayoutAlign="end center">
      <button fxFlex mat-stroked-button (click)="onCreateDirection()">
        Создать направление
      </button>
    </div>
  </div>
  <mat-divider class="divider"></mat-divider>
  <div>
    <div
      *ngFor="let direction of directions.state.items"
      fxLayout="row"
      fxLayoutGap="8px"
      class="direction-container"
    >
      <div
        fxFlex
        fxLayout="column"
        class="direction-data"
        (click)="onDetailsNavigate(direction.uuid)"
      >
        <div class="direction-name">{{ direction.name }}</div>
        <div class="direction-desc">{{ direction.description }}</div>
      </div>
      <div fxFlex="10rem" fxLayoutAlign="end center" fxLayoutGap="8px">
        <button
          mat-icon-button
          class="direction-action-btn"
          (click)="onEditDirection(direction)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          class="direction-action-btn"
          (click)="onDeleteDirection(direction.uuid)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
