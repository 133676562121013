import { Component, Input, OnInit } from '@angular/core';

import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { LoggingService } from 'src/app/services/logging.service';
import { SpecialityUserRelationService } from 'src/app/services/specialities/speciality-user-relation.service';

import { User } from 'src/app/models/user/user';
import { SpecialityUserRelation } from 'src/app/models/specialities/speciality-user-relation';

@Component({
  selector: 'app-admin-user-tab-specialities',
  templateUrl: './admin-user-tab-specialities.component.html',
  styleUrls: ['./admin-user-tab-specialities.component.css'],
})
export class AdminUserTabSpecialitiesComponent implements OnInit {
  @Input() user: User;

  public speciality_relations: SpecialityUserRelation[] = [];

  private uistate = {
    speciality_relations_loaded: false,
  };

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private speciality_relation_service: SpecialityUserRelationService
  ) {}

  ngOnInit(): void {
    this.load_speciality_relations();
  }

  public get is_data_loaded(): boolean {
    return this.uistate.speciality_relations_loaded;
  }

  private load_speciality_relations(): void {
    this.speciality_relation_service
      .fetch_by_user_uuid(this.user.uuid)
      .subscribe(
        (response) => {
          this.speciality_relations =
            response.results as SpecialityUserRelation[];
          this.uistate.speciality_relations_loaded = true;
          this.logging_service.debug(
            `${this.constructor.name} loaded ${response.count} speciality relations`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to load speciality relations`
          );
          this.alert_service.error(
            `Ошибка загрузки специальностей: ${err.status}`
          );
        }
      );
  }

  public on_button_delete_speciality_relation(
    speciality_relation: SpecialityUserRelation
  ): void {
    this.speciality_relation_service.delete(speciality_relation.uuid).subscribe(
      (response) => {
        this.logging_service.debug(
          `${this.constructor.name} deleted speciality relation`
        );
        this.speciality_relations = this.speciality_relations.filter(
          (r) => r.uuid !== speciality_relation.uuid
        );
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to delete speciality relation`
        );
        this.alert_service.error(
          `Ошибка удаления специальности: ${err.status}`
        );
      }
    );
  }
}
