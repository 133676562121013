import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PermissionInterceptor {
  constructor(private router: Router) {}

  private handlePermissionError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 403) {
      this.router.navigateByUrl(`/403`);
      return of(err.message);
    }
    return throwError(err);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const authReq = req.clone();
    return next
      .handle(authReq)
      .pipe(catchError((error) => this.handlePermissionError(error)));
  }
}
