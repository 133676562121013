import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { LoggingService } from 'src/app/services/logging.service';
import { TitleService } from 'src/app/services/title.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';

import { Domain } from 'src/app/models/ontology/domain';
import { DomainService } from 'src/app/services/ontology/domain.service';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { ImportedDataHttpService } from '@services/http/ImportedDataHttpService';

@Component({
  selector: 'app-ontology-dashboard',
  templateUrl: './ontology-dashboard.component.html',
  styleUrls: ['./ontology-dashboard.component.css'],
})
export class OntologyDashboardComponent implements OnInit {
  public domains: Domain[];
  public isDownloadUsersCount = false;
  public isDownloadThingLevels = false;
  public isDownLoadBySpecialities = false;

  public all_domain_treenodes: any[] = [];
  public hierarchy_root_treenodes: any[] = [];

  private uistate = {
    domains_loaded: false,
  };

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private title_service: TitleService,
    private domains_service: DomainService,
    private _domainHttpService: DomainHttpService
  ) {}

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.title_service.set_title('Онтология');
    this.load_domains();
  }

  public get is_data_loaded(): boolean {
    return this.uistate.domains_loaded;
  }

  private load_domains(): void {
    this.logging_service.debug(`${this.constructor.name} loading domains...`);
    this.domains_service.fetch_all().subscribe(
      (response) => {
        this.domains = response.results as Domain[];
        this.domains.sort((a, b) => a.name.localeCompare(b.name));
        this.build_domain_tree_data();
        this.uistate.domains_loaded = true;
        this.logging_service.debug(
          `${this.constructor.name} domains loaded successfully`
        );
      },
      (err) => {
        this.alert_service.error(`Ошибка при загрузке доменов: ${err.status}`);
      }
    );
  }

  private build_domain_tree_data(): void {
    this.logging_service.debug(
      `${this.constructor.name} build_domain_tree_data`
    );
    this.all_domain_treenodes = [];
    this.domains.forEach((domain) => {
      const treenode = {
        domain,
        parent_treenode: null,
        children: [],
        show_children: false,
      };
      this.all_domain_treenodes.push(treenode);
    });
    this.all_domain_treenodes.forEach((treenode) => {
      const child_domains = this.domains.filter((domain) => {
        return (
          domain.parent_domain &&
          domain.parent_domain.uuid === treenode.domain.uuid
        );
      });
      child_domains.forEach((child_domain) => {
        const child_treenode = this.all_domain_treenodes.find(
          (node) => node.domain.uuid === child_domain.uuid
        );
        child_treenode.parent_treenode = treenode;
        treenode.children.push(child_treenode);
      });
    });
    this.hierarchy_root_treenodes = this.all_domain_treenodes.filter(
      (node) => node.domain.parent_domain === null
    );
    // this.hierarchy_root_treenodes.forEach(treenode => treenode.show_children = true);
  }

  public on_treenode_toggle_show_children(treenode) {
    treenode.show_children = !treenode.show_children;
  }

  public downloadOntologies(): void {
    window.open(
      this._domainHttpService.getDownloadStructureLink({
        isDownloadUsersCount: this.isDownloadUsersCount,
        isDownloadThingLevels: this.isDownloadThingLevels,
        isDownLoadBySpecialities: this.isDownLoadBySpecialities,
      })
    );
  }

  public toggleDownloadUsersCount(): void {
    this.isDownloadUsersCount = !this.isDownloadUsersCount;
  }

  public toggleDownloadThingLevels(): void {
    this.isDownloadThingLevels = !this.isDownloadThingLevels;
  }

  public toggleDownLoadBySpecialities(): void {
    this.isDownLoadBySpecialities = !this.isDownLoadBySpecialities;
  }
}
