import { Component, Input } from '@angular/core';
import { ThingLevel } from '@models/ontology/thing-level';
import { Competence } from '@models/competencies/competence';
import { get_user_initials } from 'src/app/utils/template-filters/get-user-initials';
import { getVerifiedCompetenceTooltipText } from 'src/app/utils/template-filters/get-verified-competence-tooltip-text';
import { UserHttpService } from '@services/http/UserHttpService';

@Component({
  selector: 'app-thing-detail-users',
  templateUrl: './thing-detail-users.component.html',
  styleUrls: ['./thing-detail-users.component.css'],
})
export class ThingDetailUsersComponent {
  @Input() levels: ThingLevel[];
  @Input() competencies: Competence[];

  constructor(
    private _userHttpService: UserHttpService
  ) {}

  getFilteredCompetencies(level: ThingLevel): Competence[] {
    const filteredCompetencies = this.competencies
      .filter((competence) => {
        return competence.thing_level === level.uuid;
      })

    return filteredCompetencies;
  }

  getVerifiedCompetenceTooltipText = getVerifiedCompetenceTooltipText;
  getUserInitials = get_user_initials;
}
