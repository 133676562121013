import {Component, Input, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {LoggingService} from 'src/app/services/logging.service';
import {AlertService} from 'src/app/services/ui/ui-alert.service';
import {SurveyQuestionService} from 'src/app/services/survey/survey-question.service';
import {SurveyAnswerOptionService} from 'src/app/services/survey/survey-answer-option.service';

import {Survey} from 'src/app/models/survey/survey';
import {SurveyQuestion} from 'src/app/models/survey/survey-question';
import {SurveyAnswerOption} from 'src/app/models/survey/survey-answer-option';


@Component({
  selector: 'app-survey-edit-linear',
  templateUrl: './survey-edit-linear.component.html',
  styleUrls: ['./survey-edit-linear.component.css']
})
export class SurveyEditLinearComponent implements OnInit {

  @Input() survey: Survey;

  public question_create_form: UntypedFormGroup;
  public answer_option_create_form: UntypedFormGroup;
  public question_edit_form: UntypedFormGroup;
  public answer_edit_form: UntypedFormGroup;

  // public question_create_selected_type = 'save_answered_option';

  public questions: SurveyQuestion[] = [];

  public selected_question: SurveyQuestion;
  public selected_question_options: SurveyAnswerOption[] = [];

  private uistate = {
    questions_loaded: false,
    reordering_disabled: false,
    question_create_form_shown: false,
    question_create_form_loading: false,
    question_delete_button_disabled: false,

    selected_question_answer_options_loaded: false,

    answer_option_create_form_shown: false,
    answer_option_create_form_loading: false,
    answer_option_delete_button_disabled: false,

    selected_question_edit_mode: false,

    edit_answer_uuid: ''
  };

  constructor(
    private form_builder: UntypedFormBuilder,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private survey_question_service: SurveyQuestionService,
    private survey_answer_option_service: SurveyAnswerOptionService,
  ) {
    this.define_question_form();
    this.define_answer_option_form();
    this.create_question_edit_form();
    this.create_answer_edit_form();
  }

  private create_answer_edit_form() {
    this.answer_edit_form = this.form_builder.group({
      answer_title: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(255)
      ]],
      answer_description: ['', [
        Validators.maxLength(1024)
      ]]
    });
  }

  private create_question_edit_form() {
    this.question_edit_form = this.form_builder.group({
      question_title: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(255)
      ]],
      question_description: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1024)
      ]]
    });
  }

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.load_questions();
  }

  public get is_data_loaded(): boolean {
    return this.uistate.questions_loaded;
  }

  public get is_selected_question_options_loaded(): boolean {
    return this.uistate.selected_question_answer_options_loaded;
  }

  public get is_question_create_form_shown(): boolean {
    return this.uistate.question_create_form_shown;
  }

  public get is_question_create_form_loading(): boolean {
    return this.uistate.question_create_form_loading;
  }

  public get is_question_delete_button_diabled(): boolean {
    return this.uistate.question_delete_button_disabled;
  }

  public get is_answer_option_create_form_shown(): boolean {
    return this.uistate.answer_option_create_form_shown;
  }

  public get is_answer_option_create_form_loading(): boolean {
    return this.uistate.answer_option_create_form_loading;
  }

  public get is_answer_option_delete_button_disabled(): boolean {
    return this.uistate.answer_option_delete_button_disabled;
  }

  public get is_reordering_disabled(): boolean {
    return this.uistate.reordering_disabled;
  }

  public is_question_listitem_selected(question: SurveyQuestion): boolean {
    return this.selected_question && this.selected_question.uuid === question.uuid;
  }

  public get_answer_editing_uuid(): string {
    return this.uistate.edit_answer_uuid;
  }

  public on_button_answer_cancel_edit() {
    this.uistate.edit_answer_uuid = '';
  }

  public on_button_answer_option_edit(answer: SurveyAnswerOption) {
    this.uistate.edit_answer_uuid = answer.uuid;
    this.answer_edit_form.setValue({
      answer_title: answer.title,
      answer_description: answer.description
    });
  }

  private define_question_form() {
    this.question_create_form = this.form_builder.group({
      // question_type: ['', [
      //   Validators.required,
      //   Validators.minLength(1),
      //   Validators.maxLength(128)
      // ]],
      question_title: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(2048)
      ]],
      question_description: ['', [
        Validators.minLength(1),
        Validators.maxLength(2048)
      ]],
      parent_question: ['', [
        Validators.minLength(1),
        Validators.maxLength(1024)
      ]],
      related_thing: ['', [
        Validators.minLength(1),
        Validators.maxLength(1024)
      ]],
    });
  }

  private define_answer_option_form() {
    this.answer_option_create_form = this.form_builder.group({
      answer_option_title: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(2048)
      ]],
      answer_option_description: ['', [
        Validators.minLength(1),
        Validators.maxLength(2048)
      ]],
    });
  }

  private load_questions(): void {
    this.survey_question_service.fetch_all_for_survey_uuid(this.survey.uuid).subscribe(
      response => {
        this.questions = response.results as SurveyQuestion[];
        this.logging_service.debug(`${this.constructor.name} loaded ${this.questions.length} survey questions`);
        this.uistate.questions_loaded = true;
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to load survey questions`);
        this.alert_service.error(`Ошибка загрузки вопросов ${err.status}`);
      }
    );
  }

  public on_question_create_form_show(): void {
    this.logging_service.debug(`${this.constructor.name} on_question_create_form_show`);
    this.uistate.question_create_form_shown = true;
  }

  public on_drop(event: CdkDragDrop<SurveyQuestion[]>): void {
    this.logging_service.debug(`${this.constructor.name} on_drop`);
    // console.log(event);
    moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
    let min_index = event.previousIndex;
    let max_index = event.previousIndex;
    if (event.currentIndex > max_index) {
      max_index = event.currentIndex;
    } else {
      min_index = event.currentIndex;
    }
    this.reorder_questions(min_index, max_index);
  }

  private reorder_questions(min_index: number, max_index: number): void {
    this.logging_service.debug(`${this.constructor.name} reordering questions from ${min_index} to ${max_index}`);
    this.uistate.reordering_disabled = true;
    this.questions.forEach((q, index) => {
      if ((min_index <= index) && (index <= max_index)) {
        this.logging_service.debug(`${this.constructor.name} reordering ${min_index} <= ${index} <= ${max_index}`);
        this.survey_question_service.update(q.uuid, {order_number: index}).subscribe(
          response => {
            q.order_number = index;
            if (index === max_index) {
              this.uistate.reordering_disabled = false;
            }
          },
          err => {
          }
        );
      }
    });
  }

  public on_create_question(): void {
    this.uistate.question_create_form_loading = true;
    this.logging_service.debug(`${this.constructor.name} creating survey question...`);
    const question_data = {
      survey: this.survey.uuid,
      // question_type: this.question_create_form.value.question_type,
      question_type: 'save_answered_option',
      title: this.question_create_form.value.question_title,
      description: this.question_create_form.value.question_description,
      parent_question: this.question_create_form.value.parent_question,
      related_thing: this.question_create_form.value.related_thing,
      order_number: this.questions.length,
      depth: 0
    };
    this.survey_question_service.create(question_data).subscribe(
      response => {
        const question = response as SurveyQuestion;
        this.logging_service.debug(`${this.constructor.name} successfully created question`);
        this.alert_service.success('Вопрос создан');
        this.uistate.question_create_form_loading = false;
        this.questions.push(question);
        this.on_create_question_cancel();
        // this.question_create_form.setValue({
        //   // question_type: null,
        //   question_title: null,
        //   question_description: null,
        //   parent_question: null,
        //   related_thing: null
        // });
        this.on_question_select(question);
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} question creation failed`);
        this.alert_service.error(`Ошибка создания вопроса ${err.status}`);
        this.uistate.question_create_form_loading = false;
      }
    );
  }

  public on_create_question_cancel(): void {
    this.logging_service.debug(`${this.constructor.name} on_create_question_cancel`);
    this.question_create_form.setValue({
      // question_type: null,
      question_title: null,
      question_description: null,
      parent_question: null,
      related_thing: null
    });
    this.uistate.question_create_form_shown = false;
  }

  on_button_selected_question_edit() {
    this.uistate.selected_question_edit_mode = true;
    this.question_edit_form.setValue({
      question_title: this.selected_question.title,
      question_description: this.selected_question.description
    });
  }

  save_select_question_new_data() {
    const question_data = {
      title: this.question_edit_form.value.question_title,
      description: this.question_edit_form.value.question_description
    };
    this.survey_question_service.update(this.selected_question.uuid, question_data).subscribe(
      response => {
        this.alert_service.error(`Сохранено`);
        this.selected_question.title = question_data.title;
        this.selected_question.description = question_data.description;
        this.uistate.selected_question_edit_mode = false;
        this.logging_service.debug(`${this.constructor.name} updated question ${this.selected_question.uuid}`);
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to update question ${this.selected_question.uuid}`);
        this.alert_service.error(`Ошибка сохроанения ${err.status}`);
      }
    );
  }

  get_selected_question_edit_mode(): boolean {
    return this.uistate.selected_question_edit_mode;
  }

  on_button_selected_question_cancel_edit() {
    this.uistate.selected_question_edit_mode = false;
  }

  public on_button_question_delete(question: SurveyQuestion): void {
    this.uistate.question_delete_button_disabled = true;
    this.logging_service.debug(`${this.constructor.name} deleting survey question...`);
    this.survey_question_service.delete(question.uuid).subscribe(
      response => {
        this.logging_service.debug(`${this.constructor.name} successfully deleted question`);
        this.uistate.question_delete_button_disabled = false;
        this.selected_question = null;
        this.questions = this.questions.filter(q => q.uuid !== question.uuid);
        this.reorder_questions(question.order_number, this.questions.length);
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} question deletion failed`);
        this.alert_service.error(`Ошибка удаления вопроса ${err.status}`);
        this.uistate.question_delete_button_disabled = false;
      }
    );
  }

  public on_question_select(question: SurveyQuestion): void {
    this.logging_service.debug(`${this.constructor.name} on_question_select`);
    this.selected_question = question;
    this.uistate.selected_question_answer_options_loaded = false;
    this.survey_answer_option_service.fetch_all_for_question_uuid(this.selected_question.uuid).subscribe(
      response => {
        this.selected_question_options = response.results as SurveyAnswerOption[];
        this.logging_service.debug(`${this.constructor.name} successfully loaded answer options`);
        this.uistate.selected_question_answer_options_loaded = true;
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to load answer options`);
        this.alert_service.error(`Ошибка загрузки вариантов ответа ${err.status}`);
      }
    );
  }

  public on_answer_option_create_form_show(): void {
    this.logging_service.debug(`${this.constructor.name} on_answer_option_create_form_show`);
    this.uistate.answer_option_create_form_shown = true;
  }

  public on_create_answer_option(): void {
    this.uistate.answer_option_create_form_loading = true;
    this.logging_service.debug(`${this.constructor.name} creating answer option...`);
    const answer_option_data = {
      survey: this.survey.uuid,
      question: this.selected_question.uuid,
      title: this.answer_option_create_form.value.answer_option_title,
      description: this.answer_option_create_form.value.answer_option_description,
      order_number: this.selected_question_options.length,
    };
    this.survey_answer_option_service.create(answer_option_data).subscribe(
      response => {
        const answer_option = response as SurveyAnswerOption;
        this.logging_service.debug(`${this.constructor.name} successfully created answer option`);
        this.alert_service.success('Вариант ответа создан');
        this.uistate.answer_option_create_form_loading = false;
        this.selected_question_options.push(answer_option);
        this.answer_option_create_form.setValue({
          answer_option_title: null,
          answer_option_description: null,
        });
        this.uistate.answer_option_create_form_shown = false;
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} answer option creation failed`);
        this.alert_service.error(`Ошибка создания варианта ответа ${err.status}`);
        this.uistate.answer_option_create_form_loading = false;
      }
    );
  }

  public on_button_answer_option_delete(answer_option: SurveyAnswerOption): void {
    this.uistate.answer_option_delete_button_disabled = true;
    this.logging_service.debug(`${this.constructor.name} deleting answer option...`);
    this.survey_answer_option_service.delete(answer_option.uuid).subscribe(
      response => {
        this.logging_service.debug(`${this.constructor.name} successfully deleted anser option`);
        this.uistate.answer_option_delete_button_disabled = false;
        this.selected_question_options = this.selected_question_options.filter(o => o.uuid !== answer_option.uuid);
        // this.reorder_questions(question.order, this.questions.length);
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} answer option deletion failed`);
        this.alert_service.error(`Ошибка удаления варианта ответа ${err.status}`);
        this.uistate.answer_option_delete_button_disabled = false;
      }
    );
  }

  public on_create_answer_option_cancel(): void {
    this.logging_service.debug(`${this.constructor.name} on_create_answer_option_cancel`);
    this.answer_option_create_form.setValue({
      answer_option_title: null,
      answer_option_description: null,
    });
    this.uistate.answer_option_create_form_shown = false;
  }

  public save_answer_new_data(answer: SurveyAnswerOption) {
    const answer_new_data = {
      title: this.answer_edit_form.value.answer_title,
      description: this.answer_edit_form.value.answer_description
    };
    this.survey_answer_option_service.update(answer.uuid, answer_new_data).subscribe(
      response => {
        this.alert_service.error(`Сохранено`);
        const temp_answer = this.selected_question_options.find(t => t.uuid === response.uuid);
        temp_answer.title = response.title;
        temp_answer.description = response.description;
        this.uistate.edit_answer_uuid = '';
        this.logging_service.debug(`${this.constructor.name} updated answer ${response.uuid}`);
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to update answer ${answer.uuid}`);
        this.alert_service.error(`Ошибка сщхроанения ${err.status}`);
      }
    );
  }
}
