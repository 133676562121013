import { Component } from '@angular/core';
import { TitleService } from 'src/app/services/title.service';

@Component({
  templateUrl: './library-main.page.html',
  styleUrls: ['./library-main.page.css'],
})
export class LibraryMainPage {
  constructor(private _titleService: TitleService) {
    this._titleService.set_title('Библиотека компетенций');
  }
}
