<app-with-processing
  [processing]='
    specialities?.state.isProcessing &&
    newSpecialities?.state.isProcessing &&
    !directionsSpecialitiesReady
  '
>
  <div
    *ngIf='
      specialities?.state.isLoaded &&
      newSpecialities?.state.isLoaded &&
      directionsSpecialitiesReady
    '
    class='speciality-list-container'
  >
    <div class='search-container'>
      <app-library-search></app-library-search>
    </div>
    <div
      fxLayoutAlign='start center'
      class='add-speciality-template-button-container'
      (click)='onSpecialityTemplateAdd()'
      *ngIf='isUserAdmin'
    >
      <button
        mat-raised-button
        class='add-speciality-template-button'
        color='primary'
      >
        <mat-icon>add_circle_outline</mat-icon>
      </button>
      <span class='add-speciality-template-button-label'
      >Добавить шаблон специальности</span
      >
    </div>

    <div fxLayout='row' fxLayoutGap='8px'>
      <button
        (click)='onAllSpecialitiesButtonClick()'
        class='speciality-list-button'
        [class.speciality-list-button_checked]='isAllSpecialitiesChecked'
      >
        Все специальности
      </button>
      <button
        (click)='onNewSpecialitiesButtonClick()'
        class='speciality-list-button'
        [class.speciality-list-button_checked]='isNewSpecialitiesChecked'
      >
        Новые специальности
      </button>
    </div>

    <div
      class='specialities-container'
      fxLayout='column'
      fxLayoutGap='8px'
      *ngIf='isAllSpecialitiesChecked'
    >
      <div *ngFor='let speciality of specialities.state.items'>
        <app-library-item
          [item]='serializeSpeciality(speciality)'
          [specialityUuid]='speciality.uuid'
        ></app-library-item>
      </div>
    </div>
    <div
      class='specialities-container'
      fxLayout='column'
      fxLayoutGap='8px'
      *ngIf='isNewSpecialitiesChecked'
    >
      <div *ngFor='let speciality of newSpecialities.state.items'>
        <app-library-item
          [item]='serializeSpeciality(speciality)'
          [specialityUuid]='speciality.uuid'
        ></app-library-item>
      </div>
    </div>
  </div>
</app-with-processing>
