<div class="select-thing-level-wrapper">
  <div
    *ngFor="let level of thing.state.item.levels"
    class="thing-level"
    [class.selected]="isSelected(level) || isPrevSelected(level)"
    (click)="selectLevel(level.uuid)"
  >
    <div fxLayout="row">
      <div fxFlex>
        <div class="level-title">
          {{ level.title }}
        </div>
        <div
          class="level-description"
          *ngIf="isSelected(level)"
          [innerHTML]="level.description | newlineToBr"
        ></div>
      </div>
    </div>
  </div>

  <div class="level-buttons">
    <span class="level-button">
      <button
        mat-stroked-button
        color="primary"
        [disabled]="selectedLevelUuid === '' || isClosed"
        (click)="onAcceptClick()"
        type="button"
      >
        <mat-icon>save</mat-icon>
        Сохранить
      </button>
    </span>
    <span class="level-button" *ngIf="skipLabel !== ''">
      <button
        mat-stroked-button
        color="primary"
        [disabled]="isClosed"
        (click)="onSkipClick()"
        type="button"
      >
        <mat-icon>skip_next</mat-icon>
        {{ skipLabel }}
      </button>
    </span>
    <span class="level-button" *ngIf="isSelfReview">
      <app-desire-button [thingUuid]="thing.uuid"></app-desire-button>
    </span>
  </div>
</div>
