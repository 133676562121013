import { Component } from '@angular/core';

@Component({
  selector: 'app-library-domain-page',
  templateUrl: './domain.page.html',
  styleUrls: ['./domain.page.css'],
})
export class LibraryDomainPage {
  constructor() {}
}
