import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Thing } from '@models/ontology/thing';
import { ThingLevel } from '@models/ontology/thing-level';
import { AsyncDetail } from '@rest/AsyncDetail';

export interface AcceptReviewThingLevel {
  thingUuid: string;
  thingLevelUuid: string;
}

@Component({
  selector: 'app-review-thing-levels',
  templateUrl: './review-thing-levels.component.html',
  styleUrls: ['./review-thing-levels.component.css'],
})
export class ReviewThingLevelsComponent {
  @Input() thing: AsyncDetail<Thing> = null;
  @Input() isSelfReview = false;
  @Input() skipLabel = '';
  @Input() prevSelectedLevelUuid = '';
  @Input() isClosed: boolean;
  @Output() accept: EventEmitter<AcceptReviewThingLevel> =
    new EventEmitter<AcceptReviewThingLevel>();
  @Output() skip: EventEmitter<string> = new EventEmitter<string>();

  selectedLevelUuid = '';

  constructor() {}

  isSelected(level: ThingLevel): boolean {
    return level.uuid === this.selectedLevelUuid;
  }

  isPrevSelected(level: ThingLevel): boolean {
    return level.uuid === this.prevSelectedLevelUuid;
  }

  selectLevel(uuid: string): void {
    this.selectedLevelUuid = uuid;
  }

  onAcceptClick(): void {
    this.accept.emit({
      thingLevelUuid: this.selectedLevelUuid,
      thingUuid: this.thing.uuid,
    });
  }

  onSkipClick(): void {
    this.skip.emit(this.thing.uuid);
  }
}
