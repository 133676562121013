import { Component, Input, OnInit } from '@angular/core';

import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { LoggingService } from 'src/app/services/logging.service';
import { PdpService } from 'src/app/services/track/pdp.service';

import { User } from 'src/app/models/user/user';
import { DevelopmentPlan } from 'src/app/models/tracks/development-plan';

@Component({
  selector: 'app-track-user-pdps-list',
  templateUrl: './track-user-pdps-list.component.html',
  styleUrls: ['./track-user-pdps-list.component.css'],
})
export class TrackUserPdpsListComponent implements OnInit {
  @Input() user: User;

  public pdps: DevelopmentPlan[] = [];

  private uistate = {
    pdps_loaded: false,
  };

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private pdp_service: PdpService
  ) {}

  ngOnInit(): void {
    this.load_pdps();
  }

  public get is_data_loaded(): boolean {
    return this.uistate.pdps_loaded;
  }

  private load_pdps(): void {
    this.pdp_service.fetch_by_subject_user_uuid(this.user.uuid).subscribe(
      (response) => {
        this.pdps = response.results as DevelopmentPlan[];
        this.uistate.pdps_loaded = true;
        this.logging_service.debug(
          `${this.constructor.name} loaded ${response.count} pdps`
        );
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to load pdps`
        );
        this.alert_service.error(
          `Ошибка загрузки планов развития: ${err.status}`
        );
      }
    );
  }

  public on_button_delete_pdp(pdp: DevelopmentPlan): void {
    this.pdp_service.delete(pdp.uuid).subscribe(
      (response) => {
        this.logging_service.debug(`${this.constructor.name} deleted pdp`);
        this.pdps = this.pdps.filter((p) => p.uuid !== pdp.uuid);
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to delete pdp`
        );
        this.alert_service.error(
          `Ошибка удаления плана развития: ${err.status}`
        );
      }
    );
  }
}
