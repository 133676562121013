import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({name: 'newlineToBr'})
export class ReplaceLineBreaks implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {
    }

    transform(value: any): any {
        if (typeof value !== 'string') {
            return value;
        }
        let result: any;
        const text_replaced = value.replace(/(?:\r\n|\r|\n|\\n)/g, '<br />');
        result = this.sanitizer.sanitize(SecurityContext.HTML, text_replaced);
        // result = text_replaced;
        // console.log(result);
        return result;
    }
}
