import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thing-create-page',
  templateUrl: './thing-create.page.html',
  styleUrls: ['./thing-create.page.scss'],
})
export class ThingCreatePage implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
