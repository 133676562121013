<app-with-processing [processing]='direction.state.isProcessing'>
  <div *ngIf='direction.state.isLoaded'>
    <div fxLayout='row'>
      <div fxFlex fxLayoutAlign='start center'>
        <span class='title'>{{ direction.state.item.name }}</span>
      </div>
      <div fxFlex='12rem' fxLayoutAlign='end center'>
        <button fxFlex mat-stroked-button (click)='onEditDirection()'>
          Редактировать
        </button>
      </div>
    </div>
    <div class='desc'>
      {{ direction.state.item.description }}
    </div>
    <div class='mat-tab-container'>
      <mat-tab-group [animationDuration]="'0ms'" mat-stretch-tabs='false'>
        <mat-tab label='Админы направления'>
          <ng-container [ngTemplateOutlet]='adminTabTemplateRef'>
          </ng-container>
        </mat-tab>
        <mat-tab label='Команды направления'>
          <ng-container [ngTemplateOutlet]='teamTabTemplateRef'></ng-container>
        </mat-tab>
        <mat-tab label='Специальности направления'>
          <ng-container [ngTemplateOutlet]='specialityTabTemplateRef'>
          </ng-container>
        </mat-tab>
        <mat-tab label='Домены направления'>
          <div *ngFor='let node of domainsTreeData'>
            <ng-template
              [ngTemplateOutlet]='domainNodeTemplateRef'
              [ngTemplateOutletContext]='{ node: node }'
            >
            </ng-template>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</app-with-processing>

<ng-template #domainNodeTemplateRef let-node='node'>
  <div
    fxLayout='column'
    class='node-container'
    [style.background]="node.level % 2 == 0 ? '#FFFFFF' : '#F9F9F9'"
  >
    <div
      fxLayout='row'
      fxFlex
      [style.margin-bottom]="node.isExpanded ? '8px' : ''"
    >
      <div fxFlex='2rem'>
        <mat-checkbox
          [checked]='node.isChecked'
          (change)='onDomainToggle(node)'
        ></mat-checkbox>
      </div>
      <div fxLayout='column' fxFlex class='node-info'>
        <div class='node-name'>{{ node.name }}</div>
      </div>
      <div
        fxLayoutAlign='end center'
        fxFlex='3rem'
        *ngIf='node.children.length'
      >
        <button mat-icon-button (click)='node.isExpanded = !node.isExpanded'>
          <mat-icon>{{
            node.isExpanded ? 'expand_more' : 'chevron_right'
            }}</mat-icon>
        </button>
      </div>
    </div>
    <div>
      <div
        *ngIf='node.children.length && node.isExpanded'
        fxLayout='column'
        fxLayoutGap='8px'
        [style.margin-bottom]="node.isExpanded ? '8px' : ''"
      >
        <ng-template ngFor let-child [ngForOf]='node.children'>
          <ng-template
            [ngTemplateOutlet]='domainNodeTemplateRef'
            [ngTemplateOutletContext]='{ node: child }'
          >
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #specialityTabTemplateRef>
  <div
    fxLayout='row'
    fxFlex
    class='node-container'
    [style.background]="'#f9f9f9'"
    *ngFor='let speciality of specialitiesSelection'
  >
    <div fxFlex='2rem'>
      <mat-checkbox
        [checked]='speciality.isChecked'
        (change)='onSpecialityToggle(speciality)'
      ></mat-checkbox>
    </div>
    <div fxLayout='column' fxFlex class='node-info'>
      <div class='node-name'>{{ speciality.name }}</div>
    </div>
  </div>
</ng-template>

<ng-template #adminTabTemplateRef>
  <div *ngIf='isAddAdminVisible' [style.margin-top.px]='20'>
    <app-search-select-user
      [hiddenUsers]='directionAdmins'
      (userSelected)='onAdminSelected($event)'
    ></app-search-select-user>
    <div fxLayout='row' fxLayoutGap='8px'>
      <button mat-stroked-button (click)='addDirectionAdmin()'>Добавить</button>
      <button
        mat-stroked-button
        (click)='isAddAdminVisible = !isAddAdminVisible'
      >
        Отмена
      </button>
    </div>
  </div>
  <div *ngIf='!isAddAdminVisible' [style.margin-top.px]='20'>
    <div
      fxLayoutAlign='start center'
      class='add-button-container'
      (click)='isAddAdminVisible = !isAddAdminVisible'
    >
      <button
        mat-raised-button
        class='add-button'
        color='primary'
      >
        <mat-icon>add_circle_outline</mat-icon>
      </button>
      <span class='add-button-label'>Добавить админа направления</span>
    </div>
  </div>
  <div class='items-container' fxLayout='column' fxLayoutGap='8px'>
    <div
      *ngFor='let admin of direction.state.item.admins'
      fxLayout='row'
      class='item-container'
    >
      <div fxLayout='column' fxFlex class='item-data'>
        <div class='item-name'>
          {{ admin.last_name + ' ' + admin.first_name }}
        </div>
        <div class='item-desc'>{{ admin.email }}</div>
      </div>
      <div fxFlex='6rem' fxLayoutAlign='end center'>
        <button
          mat-icon-button
          class='item-action-btn'
          (click)='removeDirectionAdmin(admin.uuid)'
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #teamTabTemplateRef>
  <div *ngIf='isAddTeamVisible' [style.margin-top.px]='20'>
    <ng-select
      appearance='outline'
      (search)='searchTeam($event.term)'
      (change)='searchSelectTeam($event)'
      [loading]='searchTeams.state.isProcessing'
      notFoundText='По вашему запросу не нашлось команд'
      loadingText='Ищем...'
      appendTo='body'
      class='teams-search'
    >
      <ng-option *ngFor='let team of searchTeams.state.items' [value]='team'>{{
        team.name
        }}</ng-option>
    </ng-select>
    <div fxLayout='row' fxLayoutGap='8px'>
      <button mat-stroked-button (click)='addDirectionTeam()'>Добавить</button>
      <button mat-stroked-button (click)='isAddTeamVisible = !isAddTeamVisible'>
        Отмена
      </button>
    </div>
  </div>
  <div *ngIf='!isAddTeamVisible' [style.margin-top.px]='20'>
    <div
      fxLayoutAlign='start center'
      class='add-button-container'
      (click)='isAddTeamVisible = !isAddTeamVisible'
    >
      <button
        mat-raised-button
        class='add-button'
        color='primary'
      >
        <mat-icon>add_circle_outline</mat-icon>
      </button>
      <span class='add-button-label'>Добавить команду в направление</span>
    </div>
  </div>
  <div class='items-container' fxLayout='column' fxLayoutGap='8px'>
    <div
      *ngFor='let team of direction.state.item.teams'
      fxLayout='row'
      class='item-container'
    >
      <div fxLayout='column' fxFlex class='item-data'>
        <div class='item-name'>
          {{ team.name }}
        </div>
        <div class='item-desc'>{{ team.description }}</div>
      </div>
      <div fxFlex='6rem' fxLayoutAlign='end center'>
        <button
          mat-icon-button
          class='item-action-btn'
          (click)='removeDirectionTeam(team.uuid)'
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
