import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { DevelopmentPlanStep } from '@models/tracks/development-plan-step';

@Injectable({
  providedIn: 'root',
})
export class DevelopmentPlanStepHttpService extends ViewSetHttpService<DevelopmentPlanStep> {
  constructor(_http: HttpClient) {
    super(_http);
  }

  public viewSetUrl = 'track/step';
}
