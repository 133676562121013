<div class="app-domains-user-tab-competencies-component-container">
  <ng-container *ngIf="is_data_loaded; then component_content; else preloader">
  </ng-container>
</div>

<ng-template #preloader>
  <app-preloader-progressbar></app-preloader-progressbar>
</ng-template>

<ng-template #component_content>
  <div class="app-domains-user-tab-competencies-container">
    <div fxLayout="row">
      <div fxFlex>
        <div *ngIf="!domains.length">
          <div class="app-domains-user-tab-competencies-empty">
            У пользователя нет отмеченных компетенций
          </div>
        </div>
        <div *ngIf="domains.length">
          <div *ngFor="let domain_treenode of hierarchy_root_treenodes">
            <ng-container
              [ngTemplateOutlet]="tmpl_domain_treenode"
              [ngTemplateOutletContext]="{ treenode: domain_treenode }"
            >
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_domain_treenode let-treenode="treenode">
  <div class="app-domains-user-tab-competencies-domain-treenode-container">
    <div fxLayout="row">
      <div fxFlex fxLayoutAlign="start center">
        <div
          class="app-domains-user-tab-competencies-domain-treenode-domain-name"
        >
          {{ treenode.domain.name }}
        </div>
      </div>
    </div>

    <div fxLayout="row">
      <div fxFlex="3rem">
        <!-- chevnon offset -->
      </div>
      <div fxFlex>
        <div *ngIf="!treenode.show_competencies">
          <div
            class="app-domains-user-tab-competencies-domain-treenode-competence-bar-container"
          >
            <div
              *ngFor="
                let competence of get_treenode_children_competencies_to_display(
                  treenode
                )
              "
              class="app-domains-user-tab-competencies-domain-treenode-competence-bar"
              [class.app-domains-user-tab-competencies-domain-treenode-competence-noskill]="
                competence.level === 0
              "
              [class.app-domains-user-tab-competencies-domain-treenode-competence-junior]="
                competence.level === 1
              "
              [class.app-domains-user-tab-competencies-domain-treenode-competence-middle]="
                competence.level === 2
              "
              [class.app-domains-user-tab-competencies-domain-treenode-competence-senior]="
                competence.level === 3
              "
              [class.app-domains-user-tab-competencies-domain-treenode-competence-desired]="
                competence.is_desired
              "
            ></div>
          </div>
        </div>
        <div *ngIf="treenode.show_competencies">
          <div *ngIf="treenode.competencies.length">
            <div *ngFor="let competence of treenode.competencies">
              <div
                class="app-domains-user-tab-competencies-domain-treenode-competence-listitem-container"
              >
                <div fxLayout="row">
                  <div fxFlex>
                    <div
                      class="app-domains-user-tab-competencies-domain-treenode-competence-listitem-name"
                    >
                      <a
                        [routerLink]="[
                          '/things/',
                          getCompetenceThingUuid(competence)
                        ]"
                      >
                        {{ competence.thing.name }}
                      </a>
                    </div>
                  </div>
                  <div fxFlex="7rem">
                    <div
                      class="app-domains-user-tab-competencies-domain-treenode-competence-listitem-desire"
                    >
                      <div *ngIf="competence.is_desired">На прокачку</div>
                    </div>
                  </div>

                  <div fxFlex="10rem">
                    {{
                      competence.thing.levels[
                        competence.thing_level.order_number
                      ].title
                    }}
                  </div>
                  <div fxFlex="10rem">
                    <div
                      *ngIf="!competence.is_verified"
                      class="app-domains-user-tab-competencies-domain-treenode-competence-listitem-noconfidence"
                    >
                      Не подтверждено
                    </div>
                    <div
                      *ngIf="competence.is_verified"
                      class="app-domains-user-tab-competencies-domain-treenode-competence-listitem-confidence"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                    >
                      <span fxFlex class="verified-text">Подтверждено</span>
                      <mat-icon
                        fxFlex
                        class="verified-icon"
                        matTooltip="{{
                          getVerifiedCompetenceTooltipText(
                            competence.verified_by
                          ) +
                            '\n' +
                            (competence.date_verified
                              | date: 'dd-MM-yyyy hh:mm')
                        }}"
                        matTooltipClass="verified-tooltip"
                      >
                        done_outline
                      </mat-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="row">
      <div fxFlex="3rem">
        <!-- chevnon offset -->
      </div>
      <div fxFlex>
        <div *ngIf="treenode.show_children">
          <div *ngIf="treenode.children.length">
            <div *ngFor="let domain_treenode of treenode.children">
              <ng-container
                [ngTemplateOutlet]="tmpl_domain_treenode"
                [ngTemplateOutletContext]="{ treenode: domain_treenode }"
              >
              </ng-container>
            </div>
          </div>
          <div *ngIf="!treenode.children.length">
            <!-- <div class="app-domains-user-tab-competencies-domain-treenode-nochildren">
                            Нет дочерних доменов компетенций
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
