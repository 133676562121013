import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-progress-bar',
  templateUrl: './custom-progress-bar.component.html',
  styleUrls: ['./custom-progress-bar.component.css'],
})
export class CustomProgressBarComponent implements OnInit {
  @Input() value = 0;
  @Input() maxValue = 100;
  @Input() completeColor = '#20a200';
  @Input() incompleteColor = '';
  @Input() barHeight = 10;

  constructor() {}

  ngOnInit(): void {}
}
