import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { CompetenceAssertion } from '@models/competencies/competence-assertion';

@Injectable({
  providedIn: 'root',
})
export class CompetenceAssertionHttpService extends ViewSetHttpService<CompetenceAssertion> {
  constructor(_http: HttpClient) {
    super(_http);
  }

  public viewSetUrl = 'competencies/assertion';
}
