import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'any'
})
export class LoggingService {

  log_prefix = 'LoggingService:';

  private log_enabled = true;

  private debug_enabled: boolean = !environment.production;
  private noop = (): any => undefined;

  constructor() {
    this.debug(`${this.log_prefix} initialized with debug_enabled: ${this.debug_enabled}`);
  }

  get debug() {
    if (this.debug_enabled) {
      // tslint:disable-next-line:no-console
      return console.debug.bind(console);
    } else {
      return this.noop;
    }
  }

  get info() {
    if (this.log_enabled) {
      // tslint:disable-next-line:no-console
      return console.info.bind(console);
    } else {
      return this.noop;
    }
  }

  get warn() {
    if (this.log_enabled) {
      return console.warn.bind(console);
    } else {
      return this.noop;
    }
  }

  get error() {
    if (this.log_enabled) {
      return console.error.bind(console);
    } else {
      return this.noop;
    }
  }
}
