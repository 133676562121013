import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ServiceEmailSettingsHttpService } from '@services/http/ServiceEmailSettingsHttpService';
import { ServiceEmailSettings } from '@models/settings/service-email-settings';
import { AsyncList } from '@rest/AsyncList';

@Component({
  selector: 'app-edit-email-service-settings',
  templateUrl: './edit-email-service-settings.component.html',
  styleUrls: ['./edit-email-service-settings.component.css'],
})
export class EditEmailServiceSettingsComponent implements OnInit {
  form: UntypedFormGroup;
  serviceEmailSettings: AsyncList<ServiceEmailSettings>;

  constructor(
    private _serviceEmailSettingsHttpService: ServiceEmailSettingsHttpService,
    private _formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<EditEmailServiceSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { settings: ServiceEmailSettings }
  ) {}

  ngOnInit(): void {
    this.serviceEmailSettings = new AsyncList<ServiceEmailSettings>(
      this._serviceEmailSettingsHttpService
    );

    this.form = this._formBuilder.group({
      name: [
        this.data.settings.name,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      host: [
        this.data.settings.host,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],

      default_from_name: [
        this.data.settings.default_from_name,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],

      default_from_email: [
        this.data.settings.default_from_email,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
          Validators.email,
        ],
      ],

      host_user: [
        this.data.settings.host_user,
        [Validators.minLength(1), Validators.maxLength(128)],
      ],

      host_password: [
        this.data.settings.host_password,
        [Validators.minLength(1), Validators.maxLength(128)],
      ],

      host_secure: [
        this.data.settings.host_secure,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],

      port: [
        this.data.settings.port,
        [Validators.required, Validators.minLength(1), Validators.maxLength(5)],
      ],
    });
  }

  onSubmitForm(): void {
    this.serviceEmailSettings.update(this.data.settings.uuid, this.form.value);
    this.dialogRef.close();
  }
}
