<app-with-processing
  [processing]='
    review?.state.isProcessing &&
    reviewSpeciality?.state.isProcessing &&
    reviewSpecialityClaims?.state.isProcessing &&
    reviewTeamMemberships?.state.isProcessing
  '
>
  <div
    class='reviews-container'
    fxLayout='column'
    *ngIf='
      review?.state.isLoaded &&
      reviewSpeciality?.state.isLoaded &&
      reviewSpecialityClaims?.state.isLoaded &&
      reviewTeamMemberships?.state.isLoaded
    '
  >
    <div
      fxLayout='row'
      fxLayoutAlign='start center'
      fxLayoutGap='2rem'
      class='reviews-preview-title'
    >
      <button (click)='onBackClick()' mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span
      >Редактирование ревью
        {{ reviewSubject.first_name + ' ' + reviewSubject.last_name }}</span
      >
    </div>
    <div fxLayout='row' fxLayoutGap='2rem'>
      <form [formGroup]='reviewForm' fxLayoutGap='2rem'>
        <div fxLayout='column' fxFlex class='reviews-preview-data-card'>
          <span>Название</span>
          <mat-form-field appearance='outline' fxFlex>
            <input matInput formControlName='name' />
          </mat-form-field>
        </div>
        <div fxLayout='column' fxFlex class='reviews-preview-data-card'>
          <span>Срок ревью</span>
          <mat-form-field appearance='outline' fxFlex>
            <mat-date-range-input [rangePicker]='picker'>
              <input matStartDate formControlName='dateFrom' />
              <input matEndDate formControlName='dateTo' />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]='picker'
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </form>
      <div fxLayout='column' fxFlex class='reviews-preview-data-card'>
        <span>Специальность</span>
        <span>{{ reviewSpeciality.state.item.name }}</span>
      </div>
    </div>
    <div fxLayout='row' class='reviews-preview-container-header'>
      <span fxFlex='33'>Участник</span>
      <span fxFlex='34'>Компетенции</span>
      <span fxFlex='33'>Ревьюеры</span>
    </div>
    <mat-divider class='divider'></mat-divider>
    <div class='review-data-container'>
      <span fxFlex='33'>{{
        reviewSubject.first_name + ' ' + reviewSubject.last_name
        }}</span>
      <div fxFlex='34' fxLayout='row' fxLayoutGap='4px'>
        <span class='review-data-item'>{{ getSubjectClaim() }}</span>
        <span class='review-data-item' *ngIf='reviewThings.length > 1'>{{
          'ещё ' + (reviewThings.length - 1)
          }}</span>
        <div>
          <button
            mat-icon-button
            class='review-data-edit'
            (click)='editSpecialityClaims()'
          >
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
      <div fxFlex='33' fxLayout='row' fxLayoutGap='4px'>
        <span class='review-data-item' *ngIf='reviewReviewers.length'>{{
          reviewReviewers[0].last_name +
          ' ' +
          getUserInitials(reviewReviewers[0])
          }}</span>
        <span class='review-data-item' *ngIf='reviewReviewers.length > 1'>{{
          'ещё ' + (reviewReviewers.length - 1)
          }}</span>
        <div>
          <button
            mat-icon-button
            class='review-data-edit'
            (click)='editReviewers()'
          >
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <mat-divider [style.margin]="'16px 0 24px'"></mat-divider>
    <div>Руководитель</div>
    <div
      class='review-responsible-container'
      fxLayout='row'
      fxLayoutAlign='start center'
      fxLayoutGap='24px'
    >
      <div>
        <mat-icon>person</mat-icon>
      </div>
      <div fxLayout='column'>
        <span>{{
          reviewResponsible.last_name + ' ' + reviewResponsible.first_name
          }}</span>
        <span>{{ reviewResponsible.email }}</span>
      </div>
      <button
        mat-raised-button
        class='review-back-button'
        (click)='onResponsibleChange()'
      >
        Изменить
      </button>
    </div>
    <mat-divider class='divider'></mat-divider>
    <div fxLayout='row' fxLayoutAlign='end center' fxLayoutGap='1rem'>
      <button mat-raised-button class='review-back-button'>Отменить</button>
      <button
        mat-raised-button
        class='review-create-button'
        (click)='reviewEdit()'
      >
        Сохранить
      </button>
    </div>
  </div>
</app-with-processing>
