import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '@models/user/user';

@Component({
  selector: 'app-review-create-reviewers-edit-dialog',
  templateUrl: './review-create-reviewers-edit-dialog.component.html',
  styleUrls: ['./review-create-reviewers-edit-dialog.component.css'],
})
export class ReviewCreateReviewersEditDialogComponent implements OnInit {
  reviewers: User[];
  constructor(
    public dialogRef: MatDialogRef<ReviewCreateReviewersEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reviewers: User[];
      onSubmit(reviewers: User[]): void;
    }
  ) {}

  ngOnInit(): void {
    this.reviewers = this.data.reviewers;
  }

  onSelectedReviewersChange(reviewers: User[]): void {
    this.reviewers = reviewers;
  }

  submit(): void {
    this.data.onSubmit(this.reviewers);
    this.dialogRef.close();
  }
}
