import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { SurveyAnswerOptionService } from 'src/app/services/survey/survey-answer-option.service';

import { SurveyQuestion } from 'src/app/models/survey/survey-question';
import { SurveyAnswerOption } from 'src/app/models/survey/survey-answer-option';
import { SurveyAnswer } from 'src/app/models/survey/survey-answer';

@Component({
  selector: 'app-survey-linear',
  templateUrl: './survey-linear.component.html',
  styleUrls: ['./survey-linear.component.css']
})
export class SurveyLinearComponent implements OnInit {

  @Input() questions: SurveyQuestion[];
  @Input() control_subject: BehaviorSubject<any>;
  @Input() submitted_answer_subject: Subject<SurveyAnswer>;
  @Input() skipped_question_subject: Subject<SurveyQuestion>;

  private question_sequence: SurveyQuestion[] = [];

  public current_question: SurveyQuestion;
  public current_question_answer_options: SurveyAnswerOption[];

  public selected_answer_option: SurveyAnswerOption;

  private uistate = {
    current_question_answer_options_loaded: false,
    button_answer_sumbit_disabled: false,
  };

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private answer_options_service: SurveyAnswerOptionService,
  ) { }

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.build_question_sequence();
    this.next_question();

    this.control_subject.subscribe(control_command => {
      this.logging_service.debug(`${this.constructor.name} received control command ${control_command.command}`);
      if (control_command.command === 'next_question') {
        this.uistate.button_answer_sumbit_disabled = false;
        this.next_question();
      }
      if (control_command.command === 'submission_error') {
        this.uistate.button_answer_sumbit_disabled = false;
      }
      if (control_command.command === 'skip_to_question') {
        this.skipped_question_subject.next(this.current_question);
        while (this.current_question.uuid !== control_command.question.uuid) {
          this.skipped_question_subject.next(this.current_question);
          this.skip_question();
        }
        this.next_question();
        this.alert_service.info('Продолжаем с места, где вы остановились');
      }
    });
  }

  public get is_data_loaded(): boolean {
    return this.is_answer_options_loaded;
  }

  public get is_answer_options_loaded(): boolean {
    return this.uistate.current_question_answer_options_loaded;
  }

  public get is_button_answer_sumbit_enabled(): boolean {
    return this.is_answer_options_loaded &&
      Boolean(this.selected_answer_option) &&
      !this.uistate.button_answer_sumbit_disabled;
  }

  private build_question_sequence(): void {
    this.logging_service.debug(`${this.constructor.name} building question sequence`);
    this.questions.forEach(q => {
      this.question_sequence.push(q);
    });
    this.logging_service.debug(`${this.constructor.name} question sequence length: ${this.question_sequence.length}`);
  }

  private next_question(): void {
    const next_question = this.get_next_question();
    if (next_question) {
      this.current_question = next_question;
      this.load_answer_options();
      this.logging_service.debug(`${this.constructor.name} new current question set ${this.current_question.title}`);
    } else {
      this.logging_service.debug(`${this.constructor.name} no questions left is question sequence`);
    }
  }

  private skip_question(): void {
    const next_question = this.get_next_question();
    this.current_question = next_question;
  }

  private get_next_question(): SurveyQuestion | null {
    if (this.question_sequence.length) {
      return this.question_sequence.shift()
    } else {
      return null;
    }
  }

  private load_answer_options(): void {
    this.logging_service.debug(`${this.constructor.name} loading answer options...`);
    this.uistate.current_question_answer_options_loaded = false;
    this.answer_options_service.fetch_all_for_question_uuid(this.current_question.uuid).subscribe(
      response => {
        this.current_question_answer_options = response.results as SurveyAnswerOption[];
        this.logging_service.debug(`${this.constructor.name} successfully loaded answer options`);
        this.uistate.current_question_answer_options_loaded = true;
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to load answer options`);
        this.alert_service.error(`Ошибка загрузки вариантов ответа ${err.status}`);
      }
    );
  }

  public on_select_answer_option(answer_option: SurveyAnswerOption): void {
    this.logging_service.debug(`${this.constructor.name} selected answer option ${answer_option.title}`);
    this.selected_answer_option = answer_option;
  }

  public is_answer_option_selected(answer_option: SurveyAnswerOption): boolean {
    if (this.selected_answer_option) {
      return this.selected_answer_option.uuid === answer_option.uuid;
    } else {
      return false;
    }
  }

  public on_submit_answer_option(): void {
    this.logging_service.debug(`${this.constructor.name} on_submit_answer_option`);
    this.uistate.button_answer_sumbit_disabled = true;
    const answer = {
      question: this.current_question.uuid,
      answer_option: this.selected_answer_option.uuid,
    } as SurveyAnswer;
    this.submitted_answer_subject.next(answer);
  }

}
