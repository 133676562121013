<h1>Редактировать группу специальностей</h1>

<form>
  <div [formGroup]="form">
    <mat-form-field [style.width.rem]="30">
      <input
        matInput
        placeholder="Название"
        type="text"
        formControlName="name"
        required
      />
    </mat-form-field>
  </div>
</form>

<div>
  <ng-select
    [items]="specialities.state.items"
    [multiple]="true"
    [(ngModel)]="selectedSpecialities"
    (add)="addSpeciality($event)"
    (remove)="removeSpeciality($event)"
    notFoundText="Специальности не найдены"
    placeholder="Специальности"
    bindLabel="name"
    bindValue="uuid"
  >
  </ng-select>

  <button mat-raised-button [disabled]="form.invalid" (click)="submit()">
    Обновить
  </button>
  <button class="remove-button" mat-raised-button (click)="remove()">
    Удалить
  </button>
</div>
