import { Component, OnInit, Input } from '@angular/core';
import { Competence } from '@models/competencies/competence';
import { Thing } from '@models/ontology/thing';
import { CompetenceDesire } from '@models/competencies/competence-desire';

interface CompetenciesCache {
  [thingUuid: string]: {
    isVerified: boolean;
    isDesired: boolean;
    level: string;
  };
}

@Component({
  selector: 'app-match-user-competencies',
  templateUrl: './match-user-competencies.component.html',
  styleUrls: ['./match-user-competencies.component.css'],
})
export class MatchUserCompetenciesComponent implements OnInit {
  @Input() things: Thing[] = [];
  @Input() competencies: Competence<Thing>[] = [];
  @Input() competenceDesires: CompetenceDesire[] = [];

  competenciesCache: CompetenciesCache = null;

  constructor() {}

  ngOnInit(): void {
    this.competenciesCache = this.competencies.reduce((acc, competence) => {
      return {
        ...acc,
        [competence.thing.uuid]: {
          isVerified: competence.is_verified,
          isDesired: Boolean(
            this.competenceDesires.find(
              (desire) => desire.thing === competence.thing.uuid
            )
          ),
          level: competence.thing_level,
        },
      };
    }, {});
  }

  isSelected(thing: Thing): boolean {
    return Boolean(this.competenciesCache[thing.uuid]);
  }

  isDesired(thing: Thing): boolean {
    return this.competenciesCache[thing.uuid]?.isDesired;
  }

  isVerified(thing: Thing): boolean {
    return this.competenciesCache[thing.uuid]?.isVerified;
  }

  getCompetenceTooltip(thing: Thing): string {
    const infoItems = [];
    if (this.competenciesCache[thing.uuid]?.level) {
      const title = thing.levels.find(
        (thingLevel) =>
          thingLevel.uuid === this.competenciesCache[thing.uuid]?.level
      )?.title;
      infoItems.push(title);
    }

    if (this.isVerified(thing)) {
      infoItems.push('Уровень подтвержден');
    }

    if (this.isDesired(thing)) {
      infoItems.push('Желает развивать');
    }

    return infoItems.join('\n');
  }
}
