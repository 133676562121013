<app-with-processing [processing]='!isOnboardingReady'></app-with-processing>
<div class='onboarding-container' *ngIf='isOnboardingReady'>
  <mat-horizontal-stepper
    [@.disabled]='true'
    [linear]='true'
    labelPosition='end'
  >
    <ng-template matStepperIcon='edit' let-index='index'>
      {{index + 1}}
    </ng-template>
    <mat-step
      label='Приветствие'
      [editable]='true'
      fxFlex
    >
      <div fxLayout='column' fxLayoutAlign='center center' fxLayoutGap='16px' class='hello-container'>
        <div class='hello-icon'><img src='/assets/icons/onboarding-hello.png' alt=''></div>
        <div class='hello-text'>Привет, {{ user.first_name }}!</div>
        <div>Starsmap - это система по управлению навыками сотрудника и команды.</div>
        <div fxLayout='row' fxLayoutGap='8px'>
          <div fxLayout='row' class='info-box'>
            <div fxFlex='8px' class='info-point'>‣</div>
            &nbsp;
            <div fxFlex class='info-text'>Узнаешь уровень своих навыков</div>
          </div>
          <div fxLayout='row' class='info-box'>
            <div fxFlex='8px' class='info-point'>‣</div>
            &nbsp;
            <div fxFlex class='info-text'>Отметишь навыки для прокачки</div>
          </div>
          <div fxLayout='row' class='info-box'>
            <div fxFlex='8px' class='info-point'>‣</div>
            &nbsp;
            <div fxFlex class='info-text'>Найдешь нужных тебе экспертов</div>
          </div>
        </div>
        <div>
          <button mat-raised-button matStepperNext color='primary' class='next-button' (click)='onTeamSelectStep()'
                  style="font-family: 'manrope';">
            Перейти к выбору команды
          </button>
        </div>
      </div>
    </mat-step>
    <mat-step
      label='Команды'
      [editable]='true'
      fxFlex
    >
      <app-with-processing
        [processing]='!teamsDataReady || teamMemberships?.state.isProcessing || teams?.state.isProcessing'></app-with-processing>
      <div class='teams-dashboard-container'
           *ngIf='teamsDataReady && teamMemberships?.state.isLoaded && teams?.state.isLoaded'>
        <div fxLayout='row'>
          <div fxFlex class='title'>Выберите команды или сообщества, в которых вы состоите.</div>
          <div
            fxFlex='20'
            fxLayoutAlign='end center'
            class='create-team-button-container'
            (click)='onTeamCreate()'
          >
            <button
              mat-raised-button
              class='create-team-button'
              color='primary'
            >
              <mat-icon>add_circle_outline</mat-icon>
            </button>
            <span class='create-team-button-label'>Добавить новую команду</span>
          </div>
        </div>
        <div>
          <mat-tab-group [animationDuration]="'0ms'" mat-stretch-tabs='false'>
            <mat-tab>
              <ng-template matTabLabel>
                <span
                  matBadge='{{ teams.state.items.length }}' matBadgeSize='medium' matBadgeColor=''
                >
                  Все &nbsp;&nbsp;&nbsp;
                </span>
              </ng-template>
              <app-teams-grid [teams]='teams.state.items' [teamMemberships]='teamMemberships'
                              [isOnboarding]='true'></app-teams-grid>
            </mat-tab>
            <mat-tab>
              <ng-template matTabLabel>
                <span
                  matBadge='{{ adminTeams.length }}' matBadgeSize='medium'
                >
                  Административные &nbsp;&nbsp;&nbsp;
                </span>
              </ng-template>
              <app-teams-grid [teams]='adminTeams' [teamMemberships]='teamMemberships'
                              [isOnboarding]='true'></app-teams-grid>
            </mat-tab>
            <mat-tab>
              <ng-template matTabLabel>
                <span
                  matBadge='{{ agileTeams.length }}' matBadgeSize='medium'
                >
                  Agile &nbsp;&nbsp;&nbsp;
                </span>
              </ng-template>
              <app-teams-grid [teams]='agileTeams' [teamMemberships]='teamMemberships'
                              [isOnboarding]='true'></app-teams-grid>
            </mat-tab>
            <mat-tab>
              <ng-template matTabLabel>
                <span
                  matBadge='{{ guildTeams.length }}' matBadgeSize='medium'
                >
                  Гильдии &nbsp;&nbsp;&nbsp;
                </span>
              </ng-template>
              <app-teams-grid [teams]='guildTeams' [teamMemberships]='teamMemberships'
                              [isOnboarding]='true'></app-teams-grid>
            </mat-tab>
          </mat-tab-group>
          <mat-divider class='stepper-divider'></mat-divider>
          <div fxLayoutAlign='end center' class='stepper-buttons-container'>
            <button mat-raised-button matStepperNext color='primary' class='next-button' style="font-family: 'manrope';"
                    (click)='onSpecialitiesSelectStep()'>
              Перейти к специальностям
            </button>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step
      label='Специальности'
      [editable]='true'
      fxFlex
    >
      <app-with-processing [processing]='!specialitiesReady'></app-with-processing>
      <div class='teams-specialities-container' fxLayout='column' *ngIf='specialitiesReady && filteredTeams.length'>
        <div fxFlex class='title' [style.margin-bottom.px]='24'>Специальность</div>
        <div *ngFor='let team of filteredTeams'>
          <div class='team-speciality-container'
               fxLayout='row' fxLayoutGap='5px'>
            <div fxFlex='45'>{{ team.name }}</div>
            <div fxFlex>
              <mat-select [style.width.rem]='15'
                          class='select'
                          (selectionChange)='onSpecialitySelectChange(team.uuid,$event.value)'
                          placeholder='Выберите специальность'>
                <mat-option
                  *ngFor='let speciality of getTeamSpecialities(team.uuid)'
                  [value]='speciality'
                >
                  {{ speciality.name }}
                </mat-option>
              </mat-select>
            </div>
            <div fxFlex>
              <mat-select [style.width.rem]='20'
                          class='select'
                          (selectionChange)='onGradeSelectChange(team.uuid,$event.value)'
                          placeholder='Уровень владения' required #select>
                <mat-select-trigger>
                  {{ select.value?.name }}
                </mat-select-trigger>
                <mat-option
                  *ngFor='let grade of getSelectedTeamSpeciality(team.uuid)?.related_grades'
                  [value]='grade'
                >
                  <div><span class='grade-name'>{{ grade.name }}</span>
                    <br /> <span
                      class='grade-desc'>{{ grade.description }}</span></div>
                </mat-option>
              </mat-select>
            </div>
            <div fxFlex='5' fxLayoutAlign='center center'>
              <div *ngIf='isSpecialityWithGradeSelected(team.uuid)'>
                <mat-icon color='primary'>done-outline</mat-icon>
              </div>
            </div>
          </div>

        </div>

        <mat-divider class='stepper-divider'></mat-divider>
        <div fxLayout='row' class='stepper-buttons-container'>
          <div fxFlex fxLayoutAlign='start center'>
            <button mat-raised-button matStepperPrevious class='next-button'
                    (click)='onPrevStep()'>
              Вернуться назад
            </button>
          </div>
          <div fxFlex fxLayoutAlign='end center'>
            <button mat-raised-button matStepperNext color='primary' class='next-button'
                    [disabled]='!isSpecialitiesStepCompleted'
                    (click)='onEndStep()'>
              Далее
            </button>
          </div>
        </div>
      </div>
      <div fxLayout='column' fxLayoutAlign='stretch' fxLayoutGap='16px'
           *ngIf='specialitiesReady && !filteredTeams.length'
           class='empty-specialities-container'>
        <div fxLayout='column' fxLayoutAlign='center center' fxFlex='90'>
          <div class='hello-icon'><img src='/assets/icons/onboarding-empty.png' alt=''></div>
          <br>
          <div class='hello-text'>Упс! Что-то не так...</div>
          <br>
          <div>Похоже, что руководитель команды еще не добавил специальности в команду</div>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout='row' fxLayoutAlign='start end' fxFlex='10'>
          <div fxLayout='row' fxFlex class='stepper-buttons-container'>
            <div fxFlex fxLayoutAlign='start start'>
              <button mat-raised-button matStepperPrevious class='next-button'
                      (click)='onPrevStep()'>
                Назад
              </button>
            </div>
            <div fxFlex fxLayoutAlign='end start'>
              <button mat-raised-button matStepperNext color='primary' class='next-button' (click)='onEndStep()'>
                Пропустить этот шаг
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step
      label='Поехали'
      [editable]='true'
      fxFlex
      [completed]='false'
    >
      <app-with-processing [processing]='!isOnboardingComplete'></app-with-processing>
      <div fxLayout='column' fxLayoutAlign='center center' fxLayoutGap='16px' *ngIf='isOnboardingComplete'
           class='end-container'>
        <div class='hello-icon'><img src='/assets/icons/onboarding-complete.png' alt=''></div>
        <div class='hello-text'>Готово!</div>
        <div>Теперь ты можешь</div>
        <div fxLayout='row' fxLayoutGap='8px'>
          <div fxLayout='row' class='info-box'>
            <div fxFlex='8px' class='info-point'>‣</div>
            &nbsp;
            <div fxFlex class='info-text'>пройти оценку своих навыков</div>
          </div>
          <div fxLayout='row' class='info-box'>
            <div fxFlex='8px' class='info-point'>‣</div>
            &nbsp;
            <div fxFlex class='info-text'>оценить компетенции своих коллег</div>
          </div>
          <div fxLayout='row' class='info-box'>
            <div fxFlex='8px' class='info-point'>‣</div>
            &nbsp;
            <div fxFlex class='info-text'>посмотреть, какие навыки есть</div>
          </div>
        </div>
        <div>Если у тебя возникнет вопрос, то вот <a (click)='onDocClick()' class='completed-onboarding-link'><strong>инструкция
          по системе</strong></a> или
          <strong>запрос в техподдержку</strong>
        </div>
        <div>
          <button mat-raised-button matStepperNext color='primary' class='next-button' style="font-family: 'manrope';"
                  (click)='onProfileClick()'>
            Перейти в мой профиль
          </button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>

