import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { TitleService } from 'src/app/services/title.service';
import { SurveyService } from 'src/app/services/survey/survey.service';

import { User } from 'src/app/models/user/user';
import { Survey } from 'src/app/models/survey/survey';


@Component({
  selector: 'app-survey-details',
  templateUrl: './survey-details.component.html',
  styleUrls: ['./survey-details.component.css']
})
export class SurveyDetailsComponent implements OnInit {

  @ViewChild('tooltip_delete_survey') tooltip_delete_survey: ElementRef;

  private survey_uuid: string;
  public survey: Survey;

  private uistate = {
    survey_loaded: false,

    is_button_survey_tooltip_delete_disabled: false,
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private title_service: TitleService,
    private survey_service: SurveyService,
  ) { }

  ngOnInit(): void {
    this.title_service.set_title('Опрос');
    this.survey_uuid = this.route.snapshot.paramMap.get('survey_uuid');
    this.logging_service.debug(`${this.constructor.name} init`);
    this.load_survey();
  }

  public get is_data_loaded(): boolean {
    return this.uistate.survey_loaded;
  }

  public get is_button_survey_tooltip_delete_disabled(): boolean {
    return this.uistate.is_button_survey_tooltip_delete_disabled;
  }

  private load_survey(): void {
    this.survey_service.fetch_by_uuid(this.survey_uuid).subscribe(
      response => {
        this.survey = response as Survey;
        this.logging_service.debug(`${this.constructor.name} loaded survey ${this.survey.name}`);
        this.title_service.set_title(this.survey.name);
        this.uistate.survey_loaded = true;
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to load survey`);
        this.alert_service.error(`Ошибка загрузки опроса ${err.status}`);
      }
    );
  }


  public on_button_survey_delete_show_tooltip($event): void {
    const tooltip_style_left = `${$event.pageX - this.tooltip_delete_survey.nativeElement.offsetWidth + 1}px;`
    const tooltip_style_top = `${$event.pageY - 15}px;`
    this.tooltip_delete_survey.nativeElement.style.display = 'block';
    this.tooltip_delete_survey.nativeElement.style.opacity = 1.0;
    this.tooltip_delete_survey.nativeElement.setAttribute('style', `left: ${tooltip_style_left}`);
    this.tooltip_delete_survey.nativeElement.setAttribute('style', `top: ${tooltip_style_top}`);
  }

  public on_button_survey_delete_hide_tooltip(): void {
    this.tooltip_delete_survey.nativeElement.style.opacity = 0.0;
    this.tooltip_delete_survey.nativeElement.style.display = 'none';
  }

  public on_tooltip_survey_delete_confirm(): void {
    this.logging_service.debug(`${this.constructor.name} on_tooltip_survey_delete_confirm`);
    this.uistate.is_button_survey_tooltip_delete_disabled = true;
    this.survey_service.delete(this.survey.uuid).subscribe(
      response => {
        this.uistate.is_button_survey_tooltip_delete_disabled = false;
        this.logging_service.debug(`${this.constructor.name} survey deleted`);
        this.router.navigate([`/admin/surveys`]);
      },
      err => {
        this.uistate.is_button_survey_tooltip_delete_disabled = false;
        this.logging_service.error(`${this.constructor.name} failed to delete survey ${this.survey.uuid}`);
        this.alert_service.error(`Ошибка удаления: ${err.status}`);
      }
    );
  }

  public on_tooltip_survey_delete_cancel(): void {
    this.logging_service.debug(`${this.constructor.name} on_tooltip_survey_delete_cancel`);
    this.on_button_survey_delete_hide_tooltip();
  }
}
