import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root',
})
export class ThingLevelTemplateService {
  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService
  ) {}

  public fetch_all(params = {}): Observable<any> {
    return this.api_service.get(`ontology/thing_level_template`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} thing level templates`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch thing level templates`
          );
        }
      )
    );
  }

  public fetch_by_uuid(thing_uuid: string): Observable<any> {
    const params = { expand: 'domain' };
    return this.api_service
      .get(`ontology/thing_level_template/${thing_uuid}`, params)
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} fetched thing with uuid ${thing_uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to fetch thing`
            );
          }
        )
      );
  }

  public create(data: any): Observable<any> {
    return this.api_service
      .post(`ontology/thing_level_template`, data, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} created with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to create thing level template`
            );
          }
        )
      );
  }

  public update(thing_uuid: string, thing_data: any): Observable<any> {
    return this.api_service
      .patch(`ontology/thing_level_template/${thing_uuid}`, thing_data, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} updated thing with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to update thing`
            );
          }
        )
      );
  }

  public delete(uuid: string): Observable<any> {
    return this.api_service
      .delete(`ontology/thing_level_template/${uuid}`, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} deleted template with uuid ${uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to delete template`
            );
          }
        )
      );
  }
}
