<div fxLayout="row" class="assessment-header">
  <div fxFlex="100%">
    <mat-card>
      <div fxLayout="column">
        <div fxLayout="row" fxFlex="50%">
          <div fxFlex="5%" fxLayoutAlign="start center">
            <button mat-mini-fab (click)="redirectToReview()">
              <mat-icon>arrow_back</mat-icon>
            </button>
          </div>
          <div fxFlex="55%">
            <h3>{{ title }}</h3>
          </div>
        </div>
        <div fxLayout="row" fxFlex="50%">
          <div fxLayout="column" fxFlex="25%" *ngIf="showProgress">
            <p>Прогресс: {{ progress + '%' }}</p>
            <mat-progress-bar [value]="progress"></mat-progress-bar>
          </div>
          <div
            fxFlex="75%"
            fxLayoutAlign="end center"
            *ngIf="isCloseButtonVisible"
          >
            <button
              mat-raised-button
              type="button"
              color="accent"
              (click)="onCloseClick()"
              [disabled]="isCloseButtonDisabled"
            >
              {{ closeButtonText }}
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
