<div fxLayout='row' fxLayoutGap='8px'>
  <div class='thing-chip'
       fxLayout='row'
       fxLayoutAlign='center center'

       *ngFor='let thing of things'
       [class.thing-chip_selected]='isSelected(thing)'
       [matTooltip]='getCompetenceTooltip(thing)'
  >{{ thing.name
    }}<span class='thing-is-desired-icon' *ngIf='isDesired(thing)'>
      <mat-icon color='primary'>favorite</mat-icon>
    </span>

    <span class='thing-is-verified-icon' *ngIf='isVerified(thing)'>
      <mat-icon color='primary'>done_outline</mat-icon>
    </span>
  </div>
</div>
