<mat-form-field appearance='outline' class='search-field'>
  <mat-label>Поиск</mat-label>
  <input
    class='search-input'
    matInput
    placeholder='Введите название для поиска'
    autocomplete='off'
    [formControl]='searchFormInput'
    (input)='onSearchInput()'
  />
  <mat-icon *ngIf='!isClearable' matSuffix>search</mat-icon>
  <button *ngIf='isClearable' mat-icon-button matSuffix (click)='clearSearch()'>
    <mat-icon>close</mat-icon>
  </button>

</mat-form-field>

<div *ngIf="searchFormInput.value !== ''" fxLayoutGap='5px'>
  <app-library-item
    *ngFor='let speciality of slicedSpecialities'
    [item]='serializeSpeciality(speciality)'
    [librarySearch]='true'
  ></app-library-item>
  <app-library-item
    *ngFor='let thing of slicedThings'
    [item]='serializeThing(thing)'
    [librarySearch]='true'
  ></app-library-item>

  <app-library-item
    *ngFor='let domain of domains.state.items.slice(0, 2)'
    [item]='serializeDomain(domain)'
    [librarySearch]='true'
  ></app-library-item>

  <div *ngIf='resultLength > 10 && !isFullResultShown'>
    <span>Найдено 10 из {{ resultLength }}, показать <strong [style.cursor]='"pointer"'
                                                             (click)='showFullResult()'>ещё</strong></span>
  </div>
</div>

<div *ngIf='isSearchResultEmpty()' class='search-no-results-hint'>
  По вашему запросу ничего не найдено
</div>
