import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PdpService } from 'src/app/services/track/pdp.service';
import { PdpActivityService } from 'src/app/services/track/pdp-activity.service';
import { PdpStepService } from 'src/app/services/track/pdp-step.service';
import { ThingService } from 'src/app/services/ontology/thing.service';
import { UserService } from 'src/app/services/user/user.service';
import { TeamMembershipService } from 'src/app/services/teams/team-membership.service';
import { PdpStepThingRelationService } from 'src/app/services/track/pdp-step-thing-relation.service';

import { DevelopmentPlan } from 'src/app/models/tracks/development-plan';
import { DevelopmentPlanActivity } from 'src/app/models/tracks/development-plan-activity';
import { DevelopmentPlanStep } from 'src/app/models/tracks/development-plan-step';
import { DevelopmentPlanStepThingRelation } from 'src/app/models/tracks/development-plan-step-thing-relation';
import { Thing } from 'src/app/models/ontology/thing';
import { Domain } from 'src/app/models/ontology/domain';
import { User } from 'src/app/models/user/user';

import { TrackUserPdpDialogCompleteComponent } from '../track-user-pdp-dialog-complete/track-user-pdp-dialog-complete.component';

@Component({
  selector: 'app-track-pdp-details',
  templateUrl: './track-pdp-details.component.html',
  styleUrls: ['./track-pdp-details.component.css'],
})
export class TrackPdpDetailsComponent implements OnInit {
  @Input() pdp: DevelopmentPlan;

  public activities: DevelopmentPlanActivity[] = [];
  public activity_with_step_form: DevelopmentPlanActivity;

  public steps: DevelopmentPlanStep[] = [];
  public step_thing_relations: DevelopmentPlanStepThingRelation[] = [];

  public superiors: User[] = [];

  public responsible_user_search_form_control = new UntypedFormControl('', []);
  private responsible_user_search_terms = new Subject<string>();
  private responsible_user_search$: Observable<User[] | null>;
  public responsible_user_search_results: User[] = [];
  public responsible_user_search_selected_user: User;

  public thing_search_form_control = new UntypedFormControl('', []);
  private thing_search_terms = new Subject<string>();
  private thing_search$: Observable<{ results: Thing[] } | null>;
  public thing_search_results: Thing[] = [];
  public step_things_selected: Thing[] = [];

  public currently_editing_activity: DevelopmentPlanActivity;
  public currently_editing_step: DevelopmentPlanStep;

  public pdp_edit_form: UntypedFormGroup;
  public activity_form: UntypedFormGroup;
  public step_form: UntypedFormGroup;

  private uistate = {
    activities_loaded: false,
    steps_loaded: false,
    thing_relations_loaded: false,
    superiors_loaded: false,

    is_header_in_edit_mode: false,
    is_header_edit_form_loading: false,
    is_header_lock_diabled: false,

    is_create_activity_form_shown: false,
    is_form_activity_loading: false,
    is_button_activity_delete_disabled: false,
    is_form_step_loading: false,
    is_step_completed_checkbox_disabled: false,
  };

  constructor(
    private form_builder: UntypedFormBuilder,
    private date_adapter: DateAdapter<Date>,
    private dialog: MatDialog,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private auth_service: AuthService,
    private thing_service: ThingService,
    private user_service: UserService,
    private membership_service: TeamMembershipService,
    private pdp_service: PdpService,
    private pdp_activity_service: PdpActivityService,
    private pdp_step_service: PdpStepService,
    private pdp_step_thing_relation_service: PdpStepThingRelationService
  ) {
    this.date_adapter.setLocale('ru-RU'); // dd/MM/yyyy
    this.define_pdp_edit_form();
    this.define_activity_form();
    this.define_step_form();
  }

  ngOnInit(): void {
    this.responsible_user_search$ = this.responsible_user_search_terms.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap((term: string) => this.user_service.search(term))
    );
    this.responsible_user_search$.subscribe(
      (users) => (this.responsible_user_search_results = users)
    );

    this.thing_search$ = this.thing_search_terms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) => this.thing_service.search(term))
    );
    this.thing_search$.subscribe(
      (response) => (this.thing_search_results = response.results)
    );
    this.load_activities();
  }

  private define_pdp_edit_form() {
    this.pdp_edit_form = this.form_builder.group({
      objective: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      accomplishment_indicator: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
      personal_value: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
      organisation_value: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
      due_date: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(128),
        ],
      ],
      responsible_user: [
        '',
        [
          Validators.required,
          Validators.minLength(32),
          Validators.maxLength(36),
        ],
      ],
    });
  }

  private define_activity_form() {
    this.activity_form = this.form_builder.group({
      activity_name: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
    });
  }

  private define_step_form() {
    this.step_form = this.form_builder.group({
      step_name: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
    });
  }

  public get is_data_loaded(): boolean {
    return (
      this.uistate.activities_loaded &&
      this.uistate.steps_loaded &&
      this.uistate.thing_relations_loaded
    );
  }

  public get is_user_self_subject(): boolean {
    return (
      this.pdp.subject_user.uuid === this.auth_service.get_current_user_uuid()
    );
  }

  public get is_user_self_responsible(): boolean {
    return (
      this.pdp.responsible_user.uuid ===
      this.auth_service.get_current_user_uuid()
    );
  }

  public get is_header_in_edit_mode(): boolean {
    return this.uistate.is_header_in_edit_mode;
  }

  public get is_header_edit_form_loading(): boolean {
    return this.uistate.is_header_edit_form_loading;
  }

  public get is_header_accomplishment_indicator_shown(): boolean {
    return this.overall_progress === 100 && this.pdp.status !== 'completed';
  }

  public get is_header_lock_diabled(): boolean {
    return this.uistate.is_header_lock_diabled;
  }

  public get is_can_create_activity(): boolean {
    return this.activities.length < 3;
  }

  public get is_create_activity_form_shown(): boolean {
    return this.uistate.is_create_activity_form_shown;
  }

  public get is_form_activity_loading(): boolean {
    return this.uistate.is_form_activity_loading;
  }

  public get is_button_activity_delete_disabled(): boolean {
    return this.uistate.is_button_activity_delete_disabled;
  }

  public get is_form_step_loading(): boolean {
    return this.uistate.is_form_step_loading;
  }

  public get is_step_completed_checkbox_disabled(): boolean {
    return this.uistate.is_step_completed_checkbox_disabled;
  }

  public is_activity_open_step_form(
    activity: DevelopmentPlanActivity
  ): boolean {
    return (
      this.activity_with_step_form &&
      this.activity_with_step_form.uuid === activity.uuid
    );
  }

  public get pdp_edit_form_input_due_date_min(): Date {
    return new Date();
  }

  public get pdp_edit_form_input_due_date_max(): Date {
    const today = new Date();
    const max_date = new Date();
    // max: today + quarter + month
    max_date.setTime(
      today.getTime() + 4 * 30 * 24 * 60 * 60 * 1000 + 30 * 24 * 60 * 60 * 1000
    );
    return max_date;
  }

  public get overall_progress(): number {
    const total_steps_count = this.steps.length;
    if (total_steps_count === 0) {
      return 0;
    }
    const completed_steps = this.steps.filter((s) => s.is_completed);
    const completed_steps_count = completed_steps.length;
    return Math.round((completed_steps_count * 100) / total_steps_count);
  }

  private load_activities(): void {
    this.pdp_activity_service.fetch_by_pdp_uuid(this.pdp.uuid).subscribe(
      (response) => {
        this.activities = response.results as DevelopmentPlanActivity[];
        this.activities.forEach((activity) => {
          activity.steps = [];
        });
        this.uistate.activities_loaded = true;
        this.logging_service.debug(
          `${this.constructor.name} loaded ${this.activities.length} pdp activities`
        );
        this.load_steps();
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to load pdp activities`
        );
        this.alert_service.error(
          `Ошибка загрузки направлений деятельности: ${err.status}`
        );
      }
    );
  }

  private load_steps(): void {
    this.pdp_step_service.fetch_by_pdp_uuid(this.pdp.uuid).subscribe(
      (response) => {
        this.steps = response.results as DevelopmentPlanStep[];
        this.steps.forEach((step) => {
          step.thing_relations = [];
          const activity = this.activities.find(
            (a) => a.uuid === step.activity
          );
          activity.steps.push(step);
        });
        this.uistate.steps_loaded = true;
        this.logging_service.debug(
          `${this.constructor.name} loaded ${this.steps.length} pdp activity steps`
        );
        this.load_thing_relations();
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to load pdp activity steps`
        );
        this.alert_service.error(`Ошибка загрузки шагов: ${err.status}`);
      }
    );
  }

  private load_thing_relations(): void {
    this.pdp_step_thing_relation_service
      .fetch_by_pdp_uuid(this.pdp.uuid)
      .subscribe(
        (response) => {
          this.step_thing_relations =
            response.results as DevelopmentPlanStepThingRelation[];
          this.step_thing_relations.forEach((relation) => {
            const step = this.steps.find((s) => s.uuid === relation.step);
            step.thing_relations.push(relation);
          });
          this.uistate.thing_relations_loaded = true;
          this.logging_service.debug(
            `${this.constructor.name} loaded ${this.step_thing_relations.length} pdp steps thing relations`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to load pdp step thing relations`
          );
          this.alert_service.error(
            `Ошибка загрузки связанных компетенций: ${err.status}`
          );
        }
      );
  }

  private load_superiors(): void {
    this.membership_service
      .fetch_superiors_by_user_uuid(this.pdp.subject_user.uuid)
      .subscribe(
        (response) => {
          const superiors = response.results as User[];
          this.superiors = superiors.filter(
            (u) => u.uuid !== this.auth_service.get_current_user_uuid()
          );
          this.uistate.superiors_loaded = true;
          this.logging_service.debug(
            `${this.constructor.name} loaded ${this.superiors.length} superiors`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to load superiors`
          );
          this.alert_service.error(
            `Ошибка загрузки руководителей: ${err.status}`
          );
        }
      );
  }

  public set_pdp_status(status: string): void {
    this.pdp_service.update(this.pdp.uuid, { status }).subscribe(
      (response) => {
        this.pdp.status = status;
        this.logging_service.debug(
          `${this.constructor.name} updated pdp status: ${status}`
        );
        if (status === 'active')
          this.alert_service.success(`Цель еще не достигнута`);
        if (status === 'completed')
          this.alert_service.success(`Цель достигнута`);
        if (status === 'future')
          this.alert_service.success(`План отложен на будущее`);
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to update pdp status: ${status}`
        );
        this.alert_service.error(
          `Ошибка изменения статуса плана: ${err.status}`
        );
      }
    );
  }

  public on_set_responsible_user(user: User): void {
    this.logging_service.debug(
      `${this.constructor.name} on_set_responsible_user ${user.first_name} ${user.last_name}`
    );
    this.responsible_user_search_selected_user = user;
    this.pdp_edit_form.controls.responsible_user.setValue(user.uuid);
  }

  public on_set_due_date(increment_mills: number): void {
    this.logging_service.debug(
      `${this.constructor.name} on_set_due_date ${increment_mills}`
    );
    const today = new Date();
    const due_date = new Date();
    due_date.setTime(today.getTime() + increment_mills);
    this.pdp_edit_form.controls.due_date.setValue(due_date);
  }

  public on_responsible_user_clear(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_responsible_user_clear`
    );
    this.responsible_user_search_selected_user = null;
    this.pdp_edit_form.controls.responsible_user.setValue(null);
    this.responsible_user_search_form_control.setValue(null);
  }

  public on_responsible_user_search(term: string): void {
    this.responsible_user_search_terms.next(term);
  }

  public on_responsible_user_search_autocomplete_selected(user: User): void {
    this.logging_service.debug(
      `${this.constructor.name} on_responsible_user_search_autocomplete_selected`
    );
    this.responsible_user_search_selected_user = user;
    this.pdp_edit_form.controls.responsible_user.setValue(
      this.responsible_user_search_selected_user.uuid
    );
    this.responsible_user_search_results = [];
  }

  public on_edit_header(): void {
    this.logging_service.debug(`${this.constructor.name} on_edit_header`);
    this.load_superiors();
    this.responsible_user_search_selected_user = this.pdp.responsible_user;
    this.pdp_edit_form.controls.objective.setValue(this.pdp.objective);
    this.pdp_edit_form.controls.accomplishment_indicator.setValue(
      this.pdp.accomplishment_indicator
    );
    this.pdp_edit_form.controls.personal_value.setValue(
      this.pdp.personal_value
    );
    this.pdp_edit_form.controls.organisation_value.setValue(
      this.pdp.organisation_value
    );
    this.pdp_edit_form.controls.due_date.setValue(this.pdp.due_date);
    this.pdp_edit_form.controls.responsible_user.setValue(
      this.pdp.responsible_user.uuid
    );
    this.uistate.is_header_in_edit_mode = true;
  }

  public on_edit_header_cancel(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_edit_header_cancel`
    );
    this.uistate.is_header_in_edit_mode = false;
  }

  public on_pdp_edit_form_submit(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_pdp_edit_form_submit`
    );
    this.uistate.is_header_edit_form_loading = true;
    const pdp_data = {
      responsible_user: this.pdp_edit_form.controls.responsible_user.value,
      objective: this.pdp_edit_form.controls.objective.value,
      accomplishment_indicator:
        this.pdp_edit_form.controls.accomplishment_indicator.value,
      personal_value: this.pdp_edit_form.controls.personal_value.value,
      organisation_value: this.pdp_edit_form.controls.organisation_value.value,
      due_date: this.pdp_edit_form.controls.due_date.value,
    };
    this.pdp_service.update(this.pdp.uuid, pdp_data).subscribe(
      (response) => {
        this.pdp = response as DevelopmentPlan;
        this.logging_service.debug(`${this.constructor.name} updated pdp`);
        this.uistate.is_header_edit_form_loading = false;
        this.uistate.is_header_in_edit_mode = false;
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to update pdp`
        );
        this.alert_service.error(`Ошибка сохранения: ${err.status}`);
        this.uistate.is_header_edit_form_loading = false;
      }
    );
  }

  public on_button_toggle_locked(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_toggle_locked`
    );
    this.uistate.is_header_lock_diabled = true;
    const new_value = !this.pdp.is_locked;
    this.pdp_service.update(this.pdp.uuid, { is_locked: new_value }).subscribe(
      (response) => {
        this.pdp.is_locked = new_value;
        this.logging_service.debug(`${this.constructor.name} updated pdp`);
        this.uistate.is_header_lock_diabled = false;
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to update pdp`
        );
        this.alert_service.error(`Ошибка сохранения: ${err.status}`);
        this.uistate.is_header_lock_diabled = false;
      }
    );
  }

  public on_button_create_activity(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_create_activity`
    );
    this.uistate.is_create_activity_form_shown = true;
  }

  public on_button_create_step(activity: DevelopmentPlanActivity): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_create_step`
    );
    this.activity_with_step_form = activity;
  }

  public on_activity_form_cancel(): void {
    this.uistate.is_create_activity_form_shown = false;
    this.activity_form.reset();
  }

  public on_activity_form_submit(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_activity_form_submit`
    );
    this.uistate.is_form_activity_loading = true;
    const pdp_activity_data = {
      name: this.activity_form.controls.activity_name.value,
      pdp: this.pdp.uuid,
    };
    this.pdp_activity_service.create(pdp_activity_data).subscribe(
      (response) => {
        const pdp_activity = response as DevelopmentPlanActivity;
        pdp_activity.steps = [];
        this.activities.push(pdp_activity);
        this.logging_service.debug(
          `${this.constructor.name} created pdp activity`
        );
        this.uistate.is_form_activity_loading = false;
        this.uistate.is_create_activity_form_shown = false;
        this.activity_with_step_form = pdp_activity;
        this.activity_form.reset();
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to create pdp activity`
        );
        this.alert_service.error(`Ошибка сохранения: ${err.status}`);
        this.uistate.is_form_activity_loading = false;
      }
    );
  }

  public on_button_activity_edit(activity: DevelopmentPlanActivity): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_activity_edit`
    );
    this.currently_editing_activity = activity;
    this.activity_form.controls.activity_name.setValue(activity.name);
  }

  public on_button_activity_edit_cancel(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_activity_edit_cancel`
    );
    this.currently_editing_activity = null;
    this.activity_form.reset();
  }

  public on_button_activity_edit_submit(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_activity_edit_submit`
    );
    this.uistate.is_form_activity_loading = true;
    const pdp_activity_data = {
      name: this.activity_form.controls.activity_name.value,
    };
    this.pdp_activity_service
      .update(this.currently_editing_activity.uuid, pdp_activity_data)
      .subscribe(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} updated pdp activity`
          );
          this.currently_editing_activity.name =
            this.activity_form.controls.activity_name.value;
          this.uistate.is_form_activity_loading = false;
          this.currently_editing_activity = null;
          this.activity_form.reset();
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to update pdp activity`
          );
          this.alert_service.error(`Ошибка сохранения: ${err.status}`);
          this.uistate.is_form_activity_loading = false;
        }
      );
  }

  public on_button_activity_delete(activity: DevelopmentPlanActivity): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_activity_delete`
    );
    this.uistate.is_button_activity_delete_disabled = true;
    this.pdp_activity_service.delete(activity.uuid).subscribe(
      (response) => {
        this.logging_service.debug(
          `${this.constructor.name} deleted pdp activity`
        );
        this.activities = this.activities.filter(
          (a) => a.uuid !== activity.uuid
        );
        this.steps = this.steps.filter((s) => s.activity !== activity.uuid);
        this.uistate.is_button_activity_delete_disabled = false;
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to delete pdp activity`
        );
        this.alert_service.error(`Ошибка удаления: ${err.status}`);
        this.uistate.is_button_activity_delete_disabled = false;
      }
    );
  }

  public on_step_form_cancel(): void {
    this.activity_with_step_form = null;
    this.step_form.reset();
  }

  public on_step_form_submit(): void {
    this.logging_service.debug(`${this.constructor.name} on_step_form_submit`);
    this.uistate.is_form_step_loading = true;
    const pdp_step_data = {
      name: this.step_form.controls.step_name.value,
      pdp: this.pdp.uuid,
      activity: this.activity_with_step_form.uuid,
    };
    this.pdp_step_service.create(pdp_step_data).subscribe(
      (response) => {
        const pdp_step = response as DevelopmentPlanStep;
        pdp_step.thing_relations = [];
        this.steps.push(pdp_step);
        this.activity_with_step_form.steps.push(pdp_step);
        this.activity_with_step_form = null;
        this.uistate.is_form_step_loading = false;
        this.step_form.reset();
        this.logging_service.debug(
          `${this.constructor.name} created pdp activity step`
        );
        if (this.step_things_selected.length) {
          const step_thing_relations_obs = [];
          this.step_things_selected.forEach((thing) => {
            const obs = this.create_step_thing_relation(pdp_step, thing);
            step_thing_relations_obs.push(obs);
          });
          forkJoin(step_thing_relations_obs).subscribe(() => {
            this.logging_service.debug(
              `${this.constructor.name} created step thing relations`
            );
            this.step_things_selected = [];
          });
        }

        if (this.pdp.status === 'completed') {
          this.set_pdp_status('active');
        }
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to create pdp activity step`
        );
        this.alert_service.error(`Ошибка сохранения: ${err.status}`);
        this.uistate.is_form_step_loading = false;
      }
    );
  }

  public on_button_step_edit(step: DevelopmentPlanStep): void {
    this.logging_service.debug(`${this.constructor.name} on_button_step_edit`);
    this.activity_with_step_form = null;
    this.currently_editing_step = step;
    step.thing_relations.forEach((relation) => {
      this.step_things_selected.push(relation.thing);
    });
    this.step_form.controls.step_name.setValue(step.name);
  }

  public on_button_step_edit_cancel(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_step_edit_cancel`
    );
    this.currently_editing_step = null;
    this.step_things_selected = [];
    this.step_form.reset();
  }

  public on_button_step_edit_submit(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_step_edit_submit`
    );
    this.uistate.is_form_step_loading = true;
    const pdp_step_data = {
      name: this.step_form.controls.step_name.value,
    };
    this.pdp_step_service
      .update(this.currently_editing_step.uuid, pdp_step_data)
      .subscribe(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} updated pdp activity step`
          );
          this.currently_editing_step.name =
            this.step_form.controls.step_name.value;
          const step_thing_relations_obs = [];
          if (this.step_things_selected.length) {
            this.step_things_selected.forEach((thing) => {
              this.logging_service.debug(
                `${this.constructor.name} sync step thing relations: selected ${thing.name}`
              );
              const exising_relation =
                this.currently_editing_step.thing_relations.find(
                  (r) => r.thing.uuid === thing.uuid
                );
              if (!exising_relation) {
                this.logging_service.debug(
                  `${this.constructor.name} sync step thing relations: will create ${thing.name}`
                );
                const obs = this.create_step_thing_relation(
                  this.currently_editing_step,
                  thing
                );
                step_thing_relations_obs.push(obs);
              }
            });
          }
          if (this.currently_editing_step.thing_relations.length) {
            this.currently_editing_step.thing_relations.forEach(
              (existing_relation) => {
                this.logging_service.debug(
                  `${this.constructor.name} sync step thing relations: existing ${existing_relation.thing.name}`
                );
                const nonmissing_thing = this.step_things_selected.find(
                  (t) => t.uuid === existing_relation.thing.uuid
                );
                if (!nonmissing_thing) {
                  this.logging_service.debug(
                    `${this.constructor.name} sync step thing relations: delete missing ${existing_relation.thing.name}`
                  );
                  const obs =
                    this.delete_step_thing_relation(existing_relation);
                  step_thing_relations_obs.push(obs);
                }
              }
            );
          }
          forkJoin(step_thing_relations_obs).subscribe(() => {
            this.logging_service.debug(
              `${this.constructor.name} sync step thing relations`
            );
            this.step_things_selected = [];
          });
          this.uistate.is_form_step_loading = false;
          this.currently_editing_step = null;
          this.step_form.reset();
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to update pdp activity step`
          );
          this.alert_service.error(`Ошибка сохранения: ${err.status}`);
          this.uistate.is_form_step_loading = false;
        }
      );
  }

  public on_button_delete_step(step: DevelopmentPlanStep): void {
    this.pdp_step_service.delete(step.uuid).subscribe(
      (response) => {
        this.logging_service.debug(`${this.constructor.name} deleted step`);
        this.steps = this.steps.filter((s) => s.uuid !== step.uuid);
        const activity = this.activities.find((a) => a.uuid === step.activity);
        activity.steps = activity.steps.filter((s) => s.uuid !== step.uuid);
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to delete pdp activity step`
        );
        this.alert_service.error(`Ошибка удаления: ${err.status}`);
      }
    );
  }

  public on_toggle_step_completed(step, $event): void {
    this.logging_service.debug(
      `${this.constructor.name} on_toggle_step_completed`
    );
    $event.preventDefault();
    if (!this.uistate.is_step_completed_checkbox_disabled) {
      this.pdp_step_service
        .update(step.uuid, { is_completed: !step.is_completed })
        .subscribe(
          (response) => {
            this.logging_service.debug(
              `${
                this.constructor.name
              } successfully set is_completed: ${!step.is_completed}`
            );
            step.is_completed = response.is_completed;
            if (this.steps.every((s) => s.is_completed)) {
              const dialog_cfg = {
                // height: '400px',
                // width: '600px',
                data: {
                  pdp: this.pdp,
                },
              };
              const dialog_ref = this.dialog.open(
                TrackUserPdpDialogCompleteComponent,
                dialog_cfg
              );

              dialog_ref.afterClosed().subscribe((result) => {
                this.logging_service.debug(
                  `${this.constructor.name} dialog closed`
                );
              });
            } else {
              if (this.pdp.status === 'completed') {
                this.set_pdp_status('active');
              }
            }
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to update step is_completed`
            );
            this.alert_service.error(
              `Ошибка изменения статуса шага: ${err.status}`
            );
          }
        );
    }
  }

  private create_step_thing_relation(
    step: DevelopmentPlanStep,
    thing: Thing
  ): Observable<any> {
    const relation_data = {
      pdp: this.pdp.uuid,
      step: step.uuid,
      thing: thing.uuid,
    };
    return this.pdp_step_thing_relation_service.create(relation_data).pipe(
      tap(
        (response) => {
          const step_thing_relation =
            response as DevelopmentPlanStepThingRelation;
          this.logging_service.debug(
            `${this.constructor.name} created step thing relation ${step_thing_relation.thing.name}`
          );
          step.thing_relations.push(step_thing_relation);
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to create step thing relation`
          );
          this.alert_service.error(
            `Ошибка создания связи с компетенцией: ${err.status}`
          );
        }
      )
    );
  }

  private delete_step_thing_relation(
    step_thing_relation: DevelopmentPlanStepThingRelation
  ): Observable<any> {
    return this.pdp_step_thing_relation_service
      .delete(step_thing_relation.uuid)
      .pipe(
        tap(
          (response) => {
            const step = this.steps.find(
              (s) => s.uuid === step_thing_relation.step
            );
            step.thing_relations = step.thing_relations.filter(
              (r) => r.uuid !== step_thing_relation.uuid
            );
            this.logging_service.debug(
              `${this.constructor.name} deleted step thing relation ${step_thing_relation.thing.name}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to delete step thing relation`
            );
            this.alert_service.error(
              `Ошибка удаления связи с компетенцией: ${err.status}`
            );
          }
        )
      );
  }

  public on_thing_search(term: string): void {
    this.thing_search_terms.next(term);
  }

  public on_thing_search_autocomplete_selected(thing: Thing): void {
    this.logging_service.debug(
      `${this.constructor.name} on_thing_search_autocomplete_selected`
    );
    if (this.step_things_selected.find((t) => t.uuid === thing.uuid)) {
      this.alert_service.warn('Эта компетенция уже была выбрана');
    } else {
      this.step_things_selected.push(thing);
    }
    this.thing_search_form_control.reset();
    this.thing_search_results = [];
  }

  public on_button_remove_related_thing(thing: Thing): void {
    this.step_things_selected = this.step_things_selected.filter(
      (t) => t.uuid !== thing.uuid
    );
  }

  public util_thing_search_display(thing: Thing): string {
    return thing ? `${thing.name} – ${this.get_thing_domain_name(thing)}` : '';
  }

  public get_thing_domain_name(thing: Thing): string {
    return thing ? (thing.domain as Domain).name : '';
  }

  public util_responsible_user_search_display(user: User): string {
    return user ? `${user.first_name} ${user.last_name} – ${user.email}` : '';
  }

  public util_member_get_initials_for_avatar(user: User): string {
    const first_name_initial = user.first_name.charAt(0);
    const last_name_initial = user.last_name.charAt(0);
    return `${first_name_initial}${last_name_initial}`;
  }
}
