import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewSetHttpService } from './rest/ViewSetHttpService';
import { ContentSettings } from '@models/settings/content-settings';

@Injectable({
  providedIn: 'root',
})
export class ContentSettingsHttpService extends ViewSetHttpService<ContentSettings> {
  http: HttpClient;
  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }
  public viewSetUrl = 'settings/content';
}
