import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { SpecialityDomainClaimService } from 'src/app/services/specialities/speciality-domain-claim.service';
import { SpecialityCompetenceClaimService } from 'src/app/services/specialities/speciality-competence-claim.service';

import { Thing } from 'src/app/models/ontology/thing';
import { Speciality } from 'src/app/models/specialities/speciality';
import { SpecialityGrade } from 'src/app/models/specialities/speciality-grade';
import { SpecialityDomainClaim } from 'src/app/models/specialities/speciality-domain-claim';
import { SpecialityCompetenceClaim } from 'src/app/models/specialities/speciality-competence-claim';

import { SpecialityProfileDialogClaimCreateComponent } from '../speciality-profile-dialog-claim-create/speciality-profile-dialog-claim-create.component';
import { SpecialityProfileDialogVisualComponent } from '../speciality-profile-dialog-visual/speciality-profile-dialog-visual.component';
import { ThingLevel } from '@models/ontology/thing-level';

@Component({
  selector: 'app-admin-speciality-profile',
  templateUrl: './speciality-profile.component.html',
  styleUrls: ['./speciality-profile.component.css'],
})
export class SpecialityProfileComponent implements OnInit {
  @Input() speciality: Speciality;
  @Input() grades: SpecialityGrade[];

  public domain_claims: SpecialityDomainClaim[] = [];
  public competence_claims: SpecialityCompetenceClaim[] = [];

  public all_domain_treenodes: any[] = [];
  public hierarchy_root_treenodes: any[] = [];

  private uistate = {
    speciality_domain_claims_loaded: false,
    speciality_competence_claims_loaded: false,
  };

  constructor(
    private dialog: MatDialog,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private domain_claim_service: SpecialityDomainClaimService,
    private competence_claim_service: SpecialityCompetenceClaimService
  ) {}

  ngOnInit(): void {
    this.load_domain_claims();
  }

  public get is_data_loaded(): boolean {
    return (
      this.uistate.speciality_domain_claims_loaded &&
      this.uistate.speciality_competence_claims_loaded
    );
  }

  public get is_claims_empty(): boolean {
    return (
      this.domain_claims.length === 0 && this.competence_claims.length === 0
    );
  }

  private reload_all_claims_data(): void {
    this.uistate.speciality_competence_claims_loaded = false;
    this.uistate.speciality_domain_claims_loaded = false;
    this.domain_claims = [];
    this.competence_claims = [];
    this.all_domain_treenodes = [];
    this.hierarchy_root_treenodes = [];
    this.load_domain_claims();
  }

  private load_domain_claims(): void {
    this.logging_service.debug(
      `${this.constructor.name} loading speciality domain claims...`
    );
    this.domain_claim_service
      .fetch_by_speciality_uuid(this.speciality.uuid)
      .subscribe(
        (response) => {
          this.domain_claims = response.results as SpecialityDomainClaim[];
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} speciality domain claims`
          );
          this.build_domain_tree_data();
          this.load_competence_claims();
          this.uistate.speciality_domain_claims_loaded = true;
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch speciality domain claims`
          );
          this.alert_service.error(
            `Ошибка загрузки доменов специальности: ${err.status}`
          );
        }
      );
  }

  private load_competence_claims(): void {
    this.logging_service.debug(
      `${this.constructor.name} loading speciality competence claims...`
    );
    this.competence_claim_service
      .fetch_by_speciality_uuid(this.speciality.uuid)
      .subscribe(
        (response) => {
          this.competence_claims =
            response.results as SpecialityCompetenceClaim[];
          this.competence_claims.forEach((competence_claim) =>
            this.add_competence_claim_to_domain_claim_treenode(competence_claim)
          );
          this.all_domain_treenodes.forEach((treenode) =>
            this.group_treenode_competence_claims_by_thing(treenode)
          );
          this.uistate.speciality_competence_claims_loaded = true;
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} speciality competence claims`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch speciality competence claims`
          );
          this.alert_service.error(
            `Ошибка загрузки компетенций специальности: ${err.status}`
          );
        }
      );
  }

  private build_domain_tree_data(): void {
    this.logging_service.debug(
      `${this.constructor.name} build_domain_tree_data`
    );
    this.all_domain_treenodes = [];
    this.domain_claims.forEach((domain_claim) => {
      const treenode = {
        domain_claim,
        children: [],
        competence_claims: [],
        things: [],
        things_to_claims_map: new Map(),
      };
      this.all_domain_treenodes.push(treenode);
    });
    this.all_domain_treenodes.forEach((treenode) => {
      const child_domain_claims = this.domain_claims.filter((domain_claim) => {
        return (
          domain_claim.domain.parent_domain &&
          domain_claim.domain.parent_domain ===
            treenode.domain_claim.domain.uuid
        );
      });
      child_domain_claims.forEach((child_domain_claim) => {
        const child_treenode = this.all_domain_treenodes.find(
          (node) =>
            node.domain_claim.domain.uuid === child_domain_claim.domain.uuid
        );
        treenode.children.push(child_treenode);
      });
    });
    this.hierarchy_root_treenodes = this.all_domain_treenodes.filter(
      (node) => node.domain_claim.domain.parent_domain === null
    );
  }

  private add_competence_claim_to_domain_claim_treenode(
    competence_claim: SpecialityCompetenceClaim
  ): void {
    const node = this.all_domain_treenodes.find(
      (treenode) =>
        treenode.domain_claim.domain.uuid === competence_claim.thing.domain
    );
    if (node) {
      // dont know what happend here, i deleted grade and something broke on this line
      node.competence_claims.push(competence_claim);
    } else {
      console.log(
        `try to add competence claim for domain claim treenode. thing: ${competence_claim.thing.name}`
      );
    }
  }

  private group_treenode_competence_claims_by_thing(treenode) {
    const things_map = new Map();
    treenode.competence_claims.forEach((claim) => {
      if (!things_map.has(claim.thing.uuid)) {
        things_map.set(claim.thing.uuid, claim.thing);
      }

      if (treenode.things_to_claims_map.has(claim.thing.uuid)) {
        treenode.things_to_claims_map.get(claim.thing.uuid).push(claim);
      } else {
        treenode.things_to_claims_map.set(claim.thing.uuid, [claim]);
      }
    });

    treenode.things = Array.from(things_map.values());
  }

  public get_treenode_claims_for_thing(
    treenode,
    thing: Thing
  ): SpecialityCompetenceClaim[] {
    return treenode.things_to_claims_map.get(thing.uuid);
  }

  public on_domain_claim_delete(
    treenode,
    domain_claim: SpecialityDomainClaim
  ): void {
    this.logging_service.debug(
      `${this.constructor.name} on_domain_claim_delete`
    );
    this.domain_claim_service.delete(domain_claim.uuid).subscribe(
      (response) => {
        this.logging_service.debug(
          `${this.constructor.name} deleted domain claim`
        );
        this.reload_all_claims_data();
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to delete domain claim`
        );
        this.alert_service.error(`Ошибка удаления: ${err.status}`);
      }
    );
  }

  public on_dialog_claim_create(): void {
    const dialog_cfg = {
      data: {
        speciality: this.speciality,
        grades: this.grades,
      },
    };
    const dialog_ref = this.dialog.open(
      SpecialityProfileDialogClaimCreateComponent,
      dialog_cfg
    );

    dialog_ref.afterClosed().subscribe((result) => {
      if (result) {
        this.logging_service.debug(
          `${this.constructor.name} dialog claim create after closed, created claim`
        );
        this.reload_all_claims_data();
      } else {
        this.logging_service.debug(
          `${this.constructor.name} dialog claim create after closed without result`
        );
      }
    });
  }

  public on_dialog_visual_show(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_dialog_visual_show`
    );

    const dialog_cfg = {
      data: {
        speciality: this.speciality,
        grades: this.grades,
        domain_claims: this.domain_claims,
        competence_claims: this.competence_claims,
        all_domain_treenodes: this.all_domain_treenodes,
      },
    };
    console.log(dialog_cfg);
    const dialog_ref = this.dialog.open(
      SpecialityProfileDialogVisualComponent,
      { ...dialog_cfg }
    );
  }

  public on_update_speciality_grade(
    { value: thing_level_uuid }: { value: string },
    competence_claim: SpecialityCompetenceClaim
  ): void {
    const nextThingLevel = competence_claim.thing.levels.find(
      (thing_level: ThingLevel) => {
        return thing_level.uuid === thing_level_uuid;
      }
    );

    this.competence_claim_service
      .update(competence_claim.uuid, {
        expected_level: nextThingLevel.order_number,
        thing_level: thing_level_uuid,
      })
      .subscribe(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} update competence claim grade success`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to update competence claim grade`
          );
          this.alert_service.error(`Ошибка обновления: ${err.status}`);
        }
      );
  }

  public on_speciality_thing_delete(thing, treenode): void {
    if (treenode.things_to_claims_map.get(thing.uuid).length > 0) {
      const speciality = treenode.things_to_claims_map.get(thing.uuid)[0]
        .speciality;

      this.competence_claim_service
        .on_speciality_thing_delete({
          speciality: speciality.uuid,
          thing: thing.uuid,
        })
        .subscribe(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} remove speciality ${speciality.uuid} competence claims thing ${thing.uuid}`
            );
            this.reload_all_claims_data();
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to remove speciality ${speciality.uuid} competence claims thing ${thing.uuid}`
            );
            this.alert_service.error(`Ошибка обновления: ${err.status}`);
          }
        );
    }
  }
}
