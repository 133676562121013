<div class="router-outlet-container">
    <ng-container *ngIf="is_data_loaded; then component_content; else preloader">
    </ng-container>
</div>

<ng-template #preloader>
    <app-preloader></app-preloader>
</ng-template>

<ng-template #component_content>
    <div class="app-admin-survey-edit-tree-container">
        <div *ngIf="!questions.length && !is_question_create_form_shown" 
            class="app-admin-survey-edit-tree-create-question-panel"
            (click)="on_question_create_form_show()">
            <div fxLayout="row">
                <div fxFlex="5rem">
                    <div class="app-admin-survey-edit-tree-create-question-panel-circle">
                        +
                    </div>
                </div>
                <div fxFlex>
                    <div class="app-admin-survey-edit-tree-create-question-panel-text">
                        Добавить первый вопрос
                    </div>
                </div>
            </div>
        </div>
        <div>
            <ng-container *ngIf="is_question_create_form_shown; then tmpl_question_create; else tmpl_tree">
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #tmpl_question_create>
    <div class="app-admin-survey-edit-tree-question-create-container">
        <div fxLayout="row">
            <div fxFlex>
                <div class="app-admin-survey-edit-tree-question-create-header-title">
                    Новый вопрос
                </div>
                <div class="app-admin-survey-edit-tree-question-create-header-hint">
                    <div>
                        Добавить компетенцию в опрос можно набрав ее название.
                        <br>
                        Если вы хотите сгруппировать компетенции, то напишите название группы.
                    </div>
                    <div *ngIf="node_create_request_initiator">
                        <br>
                        Родительский узел: {{ node_create_request_initiator.question.title }}
                    </div>
                    <div *ngIf="!node_create_request_initiator">
                        Подсказочка
                    </div>
                </div>

                <form [formGroup]="question_create_form">
                    <!-- <div>
                        <mat-form-field [style.width.rem]=30>
                            <mat-label>Тип вопроса</mat-label>
                            <mat-select required [(value)]="question_create_selected_type" formControlName="question_type">
                                <mat-option value="save_answered_option">Простой вопрос</mat-option>
                                <mat-option value="assert_competence_level">Уровень компетенции</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> -->

                    <!-- <div *ngIf="question_create_selected_type === 'save_answered_option'">
                        <mat-form-field [style.width.rem]=30>
                            <input matInput required placeholder="Название" type="text" formControlName="question_title">
                        </mat-form-field>
                    </div>

                    <div *ngIf="question_create_selected_type === 'save_answered_option'">
                        <mat-form-field [style.width.rem]=30>
                            <input matInput placeholder="Описание" type="text" formControlName="question_description">
                        </mat-form-field>
                    </div> -->

                    <div *ngIf="!related_thing_search_selected">
                        <mat-form-field [style.width.rem]=30>
                            <mat-label>Поиск компетенции</mat-label>
                            <input type="text"
                                    placeholder="Ansible"
                                    aria-label="Thing search"
                                    matInput
                                    [formControl]="related_thing_search_input"
                                    [matAutocomplete]="auto"
                                    (input)="on_related_thing_search(related_thing_search_input.value)">
                            <mat-autocomplete #auto="matAutocomplete"
                                            (optionSelected)="on_related_thing_search_autocomplete_selected($event.option.value)">
                                <mat-option *ngFor="let thing of related_thing_search_results" [value]="thing">
                                    <strong>{{ thing.name }}</strong> из домена {{ get_thing_domain_name(thing) }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div *ngIf="related_thing_search_selected">
                        <div class="app-admin-survey-edit-tree-question-create-selected-thing">
                            Выбранный навык: <strong>{{ related_thing_search_selected.name }}</strong>
                            <br>
                            Домен навыка: {{ get_thing_domain_name(related_thing_search_selected) }}
                        </div>
                    </div>

                    <button mat-raised-button
                            [disabled]="question_create_form.invalid || is_question_create_form_loading"
                            (click)="on_create_question()">
                        Добавить вопрос
                    </button>

                    &nbsp;

                    <button mat-raised-button
                            *ngIf="!related_thing_search_selected"
                            [disabled]="is_question_create_form_loading || related_thing_search_selected"
                            (click)="on_create_dummy_node_question()">
                        Добавить узел группировки
                    </button>

                    &nbsp;

                    <button mat-raised-button (click)="on_create_question_cancel()">
                        Отмена
                    </button>

                </form>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #tmpl_tree>
    <div *ngIf="questions.length">
        <app-survey-edit-subtree
            [survey]="survey"
            [tree_data]="tree_data"
            [node_create_request_subject]="node_create_request_subject"
            [node_delete_request_subject]="node_delete_request_subject">
        </app-survey-edit-subtree>
    </div>
</ng-template>
