import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CompanyHttpService } from '@services/http/CompanyHttpService';

@Component({
  selector: 'app-create-company-dialog',
  templateUrl: './create-company-dialog.component.html',
  styleUrls: ['./create-company-dialog.component.css'],
})
export class CreateCompanyDialogComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<CreateCompanyDialogComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _companyHttpService: CompanyHttpService
  ) {}

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
    });
  }

  submit(): void {
    this._companyHttpService
      .create({
        ...this.form.value,
      })
      .subscribe(() => {
        this.dialogRef.close();
      });
  }
}
