import { Component, Input, OnInit } from '@angular/core';
import { ThingLevelHttpService } from '@services/http/ThingLevelHttpService';
import { AsyncList } from '@rest/AsyncList';
import { ThingLevel } from '@models/ontology/thing-level';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AcceptDialogComponent } from 'src/app/modules/common-ui-elements/components/accept-dialog/accept-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ThingLevelFormComponent,
  ThingLevelFormData,
} from '../thing-level-form/thing-level-form.component';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Thing } from '@models/ontology/thing';

@Component({
  selector: 'app-thing-edit-levels',
  templateUrl: './thing-edit-levels.component.html',
  styleUrls: ['./thing-edit-levels.component.css'],
})
export class ThingEditLevelsComponent implements OnInit {
  @Input() thingLevels: AsyncList<ThingLevel>;
  @Input() thing: AsyncDetail<Thing>;
  editUuid = '';
  public thingLevelEditForm: UntypedFormGroup;
  public formBuilder: UntypedFormBuilder;

  constructor(
    private _thingLevelsHttpService: ThingLevelHttpService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.formBuilder = new UntypedFormBuilder();
    this.thingLevelEditForm = this.formBuilder.group({
      title: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      description: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
    });
  }

  onSubmitCreateLevel = (
    formValue: { title: string; description: string },
    dialogRef: MatDialogRef<any>
  ): void => {
    this.thingLevels
      .create({
        ...formValue,
        team: this.thing.state.item.team,
        domain: this.thing.state.item.domain,
        thing: this.thing.state.item.uuid,
      })
      .subscribe(() => {
        dialogRef.close();
      });
  };

  openCreateDialog(): void {
    const formConfig: ThingLevelFormData = {
      submitTitle: 'Создать',
      formTitle: 'Создать новый уровень',
      onSubmit: this.onSubmitCreateLevel,
      initialValues: {
        title: '',
        description: '',
      },
    };

    this.dialog.open(ThingLevelFormComponent, {
      data: formConfig,
      width: '55rem',
    });
  }

  startUpdate(thingLevel: ThingLevel): void {
    this.editUuid = thingLevel.uuid;
    this.thingLevelEditForm.controls.title.setValue(thingLevel.title);
    this.thingLevelEditForm.controls.description.setValue(
      thingLevel.description
    );
  }

  cancelUpdate(): void {
    this.editUuid = '';
  }

  save(): void {
    this.thingLevels
      .update(this.editUuid, this.thingLevelEditForm.value)
      .subscribe((_) => {
        this.editUuid = '';
      });
  }

  public openRemoveDialog = (thingLevel: ThingLevel): void => {
    this.dialog
      .open(AcceptDialogComponent, {
        data: {
          title: `Удалить ${thingLevel.title}?`,
          message:
            'При удалении уровня компетенций сбросятся уровни специальностей и пользователей',
          acceptLabel: 'Удалить',
          rejectLabel: 'Отмена',
        },
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) {
          this.thingLevels.remove(thingLevel.uuid);
        }
      });
  };

  catDelete(thingLevel: ThingLevel): boolean {
    return this.thingLevels.state.items.indexOf(thingLevel) > 0;
  }
}
