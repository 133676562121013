<div fxLayout="column" class="calendar-container">
  <div fxLayout="row">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxFlex="50"
      fxLayoutGap="8px"
      class="buttons-container"
    >
      <button mat-raised-button (click)="addProject()" *ngIf="!project">
        Добавить проект
      </button>
      <button mat-raised-button (click)="addBooking()">
        Добавить бронирование
      </button>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxFlex="20"
      class="button-group-container"
    >
      <mat-button-toggle-group (change)="toggleSlice($event.value)">
        <mat-button-toggle [value]="'project'" checked
          >Проекты</mat-button-toggle
        >
        <mat-button-toggle [value]="'speciality'"
          >Специальности</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxFlex="30">
      <div class="button-group-container" fxLayoutGap="8px">
        <button mat-raised-button (click)="onTodayClick()">Сегодня</button>
        <mat-button-toggle-group (change)="toggleTickFormat($event.value)">
          <mat-button-toggle [value]="'day'" checked>День</mat-button-toggle>
          <mat-button-toggle [value]="'week'">Неделя</mat-button-toggle>
          <mat-button-toggle [value]="'month'">Месяц</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
  <div fxLayout="row" *ngIf="bookings?.state.items.length">
    <div
      fxFlex="150px"
      fxLayout="column"
      class="users-container"
      *ngIf="bookings && bookings.state.isLoaded"
    >
      <div *ngIf="dataSlice === 'project'" fxFlex>
        <div
          *ngFor="let user of users"
          class="user-container"
          fxLayoutAlign="center center"
          [ngStyle]="{ 'height.px': getUserRowHeight(user.uuid) }"
        >
          <span>{{ user.last_name }} {{ user.first_name }}</span>
        </div>
      </div>
      <div *ngIf="dataSlice === 'speciality' && isSpecialityWithGradesReady">
        <div
          *ngFor="let specialityWithGrade of specialitiesWithGrades"
          class="user-container"
          fxLayout="column"
          [ngStyle]="{
            'height.px': getSpecialityRowHeight(specialityWithGrade)
          }"
        >
          <div fxLayout="row" fxFlex>
            <button
              mat-icon-button
              (click)="toggleSpecialityExpansion(specialityWithGrade)"
            >
              <mat-icon>{{
                specialityWithGrade.isExpanded ? 'expand_more' : 'chevron_right'
              }}</mat-icon>
            </button>
            <span
              >{{ specialityWithGrade.speciality.name }} ({{
                specialityWithGrade.grade.name
              }})</span
            >
          </div>
          <div *ngIf="specialityWithGrade.isExpanded" fxLayout="column">
            <div
              *ngFor="
                let relation of getSpecialityWithGradeRelations(
                  specialityWithGrade
                )
              "
              class="relation-container"
              fxLayout="row"
              fxLayoutAlign="center center"
              [ngStyle]="{ 'height.px': getUserRowHeight(relation.user.uuid) }"
            >
              <span
                >{{ relation.user.last_name }}
                {{ relation.user.first_name }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex>
      <div *ngIf="isCalendarReady">
        <app-chart-gantt
          [items]="items"
          [config]="config"
          [todayTrigger]="todayState"
          (rectChange)="onBookingChange($event)"
          (rectEdit)="onBookingEdit($event)"
        ></app-chart-gantt>
      </div>
    </div>
  </div>
  <div *ngIf="!bookings?.state.items.length" fxLayoutAlign="center center">
    Бронирования отсутствуют
  </div>
  <!--  <div>-->
  <!--    <div *ngFor="let e of eventLog">-->
  <!--      <strong>изменено бронирование {{ e.name }} </strong>, <strong>c: </strong-->
  <!--      >{{ e.prevDateStart }} - {{ e.prevDateEnd }} <strong>на: </strong-->
  <!--      >{{ e.dateStart }} - {{ e.dateEnd }}-->
  <!--    </div>-->
  <!--  </div>-->
</div>
