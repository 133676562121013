<p>Комментарий:</p>
<form [formGroup]="form">
  <div class="comment-field">
    <mat-form-field class="comment">
      <textarea matInput formControlName="comment"></textarea>
    </mat-form-field>
  </div>
  <button mat-raised-button
          [disabled]="!form.valid || !isCommentButtonsActive"
          (click)="save()"
  >
    Сохранить
  </button>

  <button mat-raised-button
          [disabled]="!isCommentButtonsActive"
          class="clear-button"
          (click)="clear()"
  >
    Очистить
  </button>
</form>
