<div class="dialog-container">
  <mat-dialog-content>
    <app-with-processing [processing]="!isDataReady"></app-with-processing>
    <div *ngIf="isDataReady">
      <div *ngIf="keyPeopleConfirmList.length">
        <h3>Starsmap обновил данные и предлагает добавить в Key People</h3>
        <div
          *ngFor="let member of keyPeopleConfirmList"
          class="member-container"
          fxLayout="row"
          matTooltip="{{ member.key_people_reason }}"
        >
          <div
            fxFlex="60"
            class="user-container"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <mat-icon fxFlex="3rem">account_circle</mat-icon>
            <div fxLayout="column" fxFlex>
              <span class="user-name">{{ getMemberName(member) }}</span>
              <span class="status-reason-text"
                >Наведите курсор для просмотра навыков</span
              >
            </div>
          </div>
          <div
            fxFlex="17"
            class="btn-container"
            (click)="onKeyPeopleAccept(member)"
          >
            <button
              fxLayout="row"
              fxLayoutGap="8px"
              class="status-action-btn"
              fxLayoutAlign="center center"
            >
              <mat-icon class="green-icon">check_circle</mat-icon>
              <span>Согласен</span>
            </button>
          </div>
          <div
            fxFlex="20"
            class="btn-container"
            (click)="onKeyPeopleReject(member)"
            [style.margin-right.px]="20"
          >
            <button
              fxLayout="row"
              fxLayoutGap="8px"
              class="status-action-btn"
              fxLayoutAlign="center center"
            >
              <mat-icon class="red-icon">cancel</mat-icon>
              <span>Не согласен</span>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="busFactorConfirmList.length">
        <h3>Starsmap обновил данные и предлагает добавить в Bus Factor</h3>
        <div
          *ngFor="let member of busFactorConfirmList"
          class="member-container"
          fxLayout="row"
          fxLayoutGap="8px"
          matTooltip="{{ member.bus_factor_reason }}"
        >
          <div
            fxFlex="60"
            class="user-container"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <mat-icon fxFlex="3rem">account_circle</mat-icon>
            <div fxLayout="column" fxFlex>
              <span class="user-name">{{ getMemberName(member) }}</span>
              <span class="status-reason-text"
                >Наведите курсор для просмотра навыков</span
              >
            </div>
          </div>
          <div
            fxFlex="17"
            class="btn-container"
            (click)="onBusFactorAccept(member)"
          >
            <button
              fxLayout="row"
              fxLayoutGap="8px"
              class="status-action-btn"
              fxLayoutAlign="center center"
            >
              <mat-icon class="green-icon">check_circle</mat-icon>
              <span>Согласен</span>
            </button>
          </div>
          <div
            fxFlex="20"
            class="btn-container"
            (click)="onBusFactorReject(member)"
            [style.margin-right.px]="20"
          >
            <button
              fxLayout="row"
              fxLayoutGap="8px"
              class="status-action-btn"
              fxLayoutAlign="center center"
            >
              <mat-icon class="red-icon">cancel</mat-icon>
              <span>Не согласен</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
