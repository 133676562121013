<div *ngIf='isAdminTeams' class='admin-teams-hint' fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='8px'>
  <div fxFlex='24px' fxLayoutAlign='center center'>
    <mat-icon>info_outline</mat-icon>
  </div>
  <div fxFlex>
    <span>Административная команда - команды, которые закреплены в структуре компании. Эта команда проставляется автоматически, ее нельзя выбрать.</span>
  </div>

</div>
<div class='teams-grid' *ngIf='teamsReady'>
  <div
    *ngFor='let team of teams'
    class='team-container'
    (click)='navigateToTeamDashboardDetail(team.uuid)'
  >
    <div
      fxLayout='row'
      fxLayoutAlign='start center'
      *ngIf='isOnboarding && team.team_type !== "admin"'
    >
      <div fxFlex>
        <mat-checkbox
          [checked]='isTeamMember(team.uuid)'
          [disabled]='getTeamSettings(team.uuid).join_type_enum === 2'
          (change)='onSelectionToggle($event.checked, team.uuid)'></mat-checkbox>
      </div>
      <div fxFlex fxLayoutAlign='end center' *ngIf='getTeamSettings(team.uuid).join_type_enum === 2'>
        <mat-icon matTooltip='В закрытую команду можно попасть только по приглашению'>lock</mat-icon>
      </div>
      <div fxFlex fxLayoutAlign='end center' *ngIf='getTeamSettings(team.uuid).join_type_enum === 3'>
        <mat-icon matTooltip='В команду с верификацией можно попасть только после подтверждения принятия руководителем'>
          check_circle_outline
        </mat-icon>
      </div>
    </div>
    <div class='team-text-container'>
      <div class='team-name'>{{ team.name }}</div>
      <div class='team-desc'>{{ team.description }}</div>
    </div>
    <div
      fxLayout='row'
      fxLayoutAlign='start center'
      fxLayoutGap='8px'
      *ngIf='!isOnboarding'
    >
      <div fxFlex>
        <div *ngIf='team.childrenCount' class='child-teams-count'>
          Дочерних команд: {{ team.childrenCount }}
        </div>
      </div>
      <div fxFlex='3rem'>
        <img [src]='team.icon_url' alt='' />
      </div>
    </div>
  </div>
</div>
