import { Component, OnInit } from '@angular/core';
import { ReviewHttpService } from '@services/http/ReviewHttpService';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Review } from '@models/reviews/review';
import { ActivatedRoute, Router } from '@angular/router';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { User } from '@models/user/user';
import { UserHttpService } from '@services/http/UserHttpService';
import { ReviewCreateReviewersEditDialogComponent } from '../../../teams/components/team-main/team-reviews/team-review-create/review-create-reviewers-edit-dialog/review-create-reviewers-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { get_user_initials } from '../../../../utils/template-filters/get-user-initials';
import { ReviewCreateCompetenciesEditDialogComponent } from '../../../teams/components/team-main/team-reviews/team-review-create/review-create-competencies-edit-dialog/review-create-competencies-edit-dialog.component';
import { SpecialityCompetenceClaimHttpService } from '@services/http/SpecialityCompetenceClaimHttpService';
import { AsyncList } from '@rest/AsyncList';
import { SpecialityCompetenceClaim } from '@models/specialities/speciality-competence-claim';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { get_full_parent_path } from '../../../../utils/getFullParentUrl';
import { ReviewCreateResponsibleEditDialogComponent } from '../../../teams/components/team-main/team-reviews/team-review-create/review-create-responsible-edit-dialog/review-create-responsible-edit-dialog.component';
import { TeamMembershipHttpService } from '@services/http/TeamMembershipHttpService';
import { TeamMembership } from '@models/teams/team-membership';

@Component({
  selector: 'app-new-review-edit',
  templateUrl: './new-review-edit.component.html',
  styleUrls: ['./new-review-edit.component.css'],
})
export class NewReviewEditComponent implements OnInit {
  reviewUuid: string;

  review: AsyncDetail<Review>;
  reviewSubject: User;
  reviewResponsible: User;

  reviewSpecialityUuid: string;
  reviewSpeciality: AsyncDetail<Speciality>;
  reviewSpecialityClaims: AsyncList<SpecialityCompetenceClaim>;

  reviewTeamMemberships: AsyncList<TeamMembership>;

  reviewThings: string[];
  reviewReviewers: User[];

  reviewForm: UntypedFormGroup;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _dialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
    private _dateAdapter: DateAdapter<Date>,
    private _reviewHttpService: ReviewHttpService,
    private _userHttpService: UserHttpService,
    private _specialityHttpService: SpecialityHttpService,
    private _specialityClaimsHttpService: SpecialityCompetenceClaimHttpService,
    private _teamMembershipHttpService: TeamMembershipHttpService
  ) {
    this._dateAdapter.setLocale('ru-RU'); // dd/MM/yyyy
  }

  ngOnInit(): void {
    this.reviewUuid = this._activatedRoute.snapshot.paramMap.get('rUuid');

    this.review = new AsyncDetail<Review>(
      this.reviewUuid,
      this._reviewHttpService
    );
    this.review.requestParams = {
      params: {
        expand: 'reviewers,subjects,responsible_user',
      },
    };
    this.review.load().subscribe(() => {
      this._setReviewVars();
      this._buildDateForm();
      this._loadReviewSpeciality();
      this._loadReviewSpecialityClaims();
      this._loadReviewTeamMemberships();
    });
  }

  private _setReviewVars(): void {
    const reviewItem = this.review.state.item;

    this.reviewSubject = reviewItem.subjects[0] as User;
    this.reviewResponsible = reviewItem.responsible_user as User;
    this.reviewReviewers = reviewItem.reviewers as User[];
    this.reviewThings = reviewItem.config.subject_things_map[
      this.reviewSubject.uuid
    ] as string[];

    this.reviewSpecialityUuid =
      reviewItem.config.subject_speciality_map[this.reviewSubject.uuid][0];
  }

  private _buildDateForm(): void {
    this.reviewForm = this._formBuilder.group({
      name: ['', [Validators.required]],
      dateFrom: ['', [Validators.required]],
      dateTo: ['', [Validators.required]],
    });

    this.reviewForm.setValue({
      name: this.review.state.item.name,
      dateFrom: new Date(this.review.state.item.deadline_start),
      dateTo: new Date(this.review.state.item.deadline_end),
    });
  }

  private _loadReviewSpeciality(): void {
    this.reviewSpeciality = new AsyncDetail<Speciality>(
      this.reviewSpecialityUuid,
      this._specialityHttpService
    );
    this.reviewSpeciality.load();
  }

  private _loadReviewSpecialityClaims(): void {
    this.reviewSpecialityClaims = new AsyncList<SpecialityCompetenceClaim>(
      this._specialityClaimsHttpService
    );
    this.reviewSpecialityClaims.setRequestParams({
      params: {
        speciality: this.reviewSpecialityUuid,
        expand: 'thing',
      },
    });
    this.reviewSpecialityClaims.load().subscribe(() => {
      if (!this.reviewThings) {
        this.reviewThings = [];
        this.reviewSpecialityClaims.state.items.forEach((specialityClaim) => {
          if (
            !this.reviewThings.find(
              (thingUuid) => specialityClaim.thing.uuid === thingUuid
            )
          ) {
            this.reviewThings.push(specialityClaim.thing.uuid);
          }
        });
      }
    });
  }

  private _loadReviewTeamMemberships(): void {
    this.reviewTeamMemberships = new AsyncList<TeamMembership>(
      this._teamMembershipHttpService
    );
    this.reviewTeamMemberships.setRequestParams({
      params: {
        team: this.review.state.item.team,
        expand: 'user',
        page_size: 100,
      },
    });
    this.reviewTeamMemberships.load();
  }

  getSubjectClaim(): string {
    return this.reviewSpecialityClaims.state.items.find(
      (specialityClaim) => specialityClaim.thing.uuid === this.reviewThings[0]
    )?.thing.name;
  }

  editSpecialityClaims(): void {
    this._dialog.open(ReviewCreateCompetenciesEditDialogComponent, {
      data: {
        specialityUuid: this.reviewSpeciality.uuid,
        selectedClaims: this.reviewThings,
        onSubmit: (selectedClaims: string[]) => {
          this.reviewThings = selectedClaims;
        },
      },
    });
  }

  editReviewers(): void {
    this._dialog.open(ReviewCreateReviewersEditDialogComponent, {
      data: {
        reviewers: this.review.state.item.reviewers,
        onSubmit: (reviewers: User[]) => {
          this.reviewReviewers = reviewers;
        },
      },
    });
  }

  private _membershipsToUsers(memberships: TeamMembership[]): User[] {
    return memberships.map((membership) => membership.user) as User[];
  }

  onResponsibleChange(): void {
    const teamOwners = this._membershipsToUsers(
      this.reviewTeamMemberships.state.items.filter(
        (membership) => membership.role === 'owner'
      )
    );
    this._dialog.open(ReviewCreateResponsibleEditDialogComponent, {
      data: {
        owners: teamOwners,
        onSubmit: (responsible: User) => {
          this.reviewResponsible = responsible;
        },
      },
    });
  }

  onBackClick(): void {
    history.back();
  }

  reviewEdit(): void {
    this.review.state.item.config.subject_things_map[this.reviewSubject.uuid] =
      this.reviewThings;

    const updatedData: any = {
      name: this.reviewForm.controls.name.value,
      reviewers: this.reviewReviewers.map((reviewer) => reviewer.uuid),
      responsible_user: this.reviewResponsible.uuid,
      deadline_start: this.reviewForm.controls.dateFrom.value.toISOString(),
      deadline_end: this.reviewForm.controls.dateTo.value.toISOString(),
      config: this.review.state.item.config,
    };

    this.review.update(updatedData).subscribe(() => {
      this.onBackClick();
    });
  }

  getFullParentPath = get_full_parent_path;
  getUserInitials = get_user_initials;
}
