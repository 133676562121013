import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyAssignmentService {

  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService,
  ) { }

  public fetch_by_user_uuid(user_uuid: string): Observable<any> {
    const params = {user: user_uuid, expand: 'survey,user'};
    return this.api_service.get(`surveys/assignment`, params).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} fetched survey assignments for user ${user_uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to fetch survey assignments`);
        }
    ));
  }

  public fetch_by_survey_uuid(survey_uuid: string): Observable<any> {
    const params = {survey: survey_uuid, expand: 'survey,user'};
    return this.api_service.get(`surveys/assignment`, params).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} fetched survey assignments for survey ${survey_uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to fetch survey assignments`);
        }
    ));
  }

  public create(assignment_data: any): Observable<any> {
    const params = {expand: 'survey,user'};
    return this.api_service.post(`surveys/assignment`, assignment_data, params).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} created survey assignment with uuid ${response.uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to create survey assignment`);
        }
    ));
  }


  public delete(assignment_uuid: string): Observable<any> {
    return this.api_service.delete(`surveys/assignment/${assignment_uuid}`, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} deleted survey assignments ${assignment_uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to delete survey assignment`);
        }
    ));
  }

}
