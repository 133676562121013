import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  templateUrl: './homepage.page.html',
  styleUrls: ['./homepage.page.css'],
})
export class HomePage implements OnInit {
  constructor(
    private _titleService: TitleService,
    private _router: Router,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this._titleService.set_title('Starsmap');
    const userUuid = this._authService.get_current_user_uuid();
    this._router.navigate([`/account/user-profile/${userUuid}`]);
  }
}
