import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LoggingService } from 'src/app/services/logging.service';

import { Speciality } from 'src/app/models/specialities/speciality';
import { SpecialityCompetenceClaim } from 'src/app/models/specialities/speciality-competence-claim';
import { SpecialityDomainClaim } from 'src/app/models/specialities/speciality-domain-claim';
import { SpecialityGrade } from 'src/app/models/specialities/speciality-grade';

@Component({
  selector: 'app-speciality-profile-dialog-visual',
  templateUrl: './speciality-profile-dialog-visual.component.html',
  styleUrls: ['./speciality-profile-dialog-visual.component.css'],
})
export class SpecialityProfileDialogVisualComponent implements OnInit {
  public hierarchy_root_treenodes: any[] = [];

  public selected_visual: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      speciality: Speciality;
      grades: SpecialityGrade[];
      domain_claims: SpecialityDomainClaim[];
      competence_claims: SpecialityCompetenceClaim[];
      all_domain_treenodes: any[];
    },
    public dialogRef: MatDialogRef<SpecialityProfileDialogVisualComponent>,
    private logging_service: LoggingService
  ) {}

  ngOnInit(): void {
    this.hierarchy_root_treenodes = this.data.all_domain_treenodes.filter(
      (node) => node.domain_claim.domain.parent_domain === null
    );

    this.select_layout();
  }

  private select_layout(): void {
    // if (this.hierarchy_root_treenodes.length < 3) {
    //   this.selected_visual = 'progressbar';
    // } else {
    //   this.selected_visual = 'radar';
    // }
    this.selected_visual = 'radar';
    this.logging_service.debug(
      `${this.constructor.name} selected layout ${this.selected_visual}`
    );
  }
}
