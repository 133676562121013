import { Component, Input, OnInit } from '@angular/core';
import { Team } from '@models/teams/team';
import { Router } from '@angular/router';
import { AsyncList } from '@rest/AsyncList';
import { TeamSettings } from '@models/teams/team-settings';
import { TeamSettingsHttpService } from '@services/http/TeamSettingsHttpService';
import { TeamMembership } from '@models/teams/team-membership';
import { AuthService } from '../../../services/auth/auth.service';
import { TeamMembershipHttpService } from '@services/http/TeamMembershipHttpService';

export interface FirstLevelTeamData extends Team {
  childrenCount: number;
}

@Component({
  selector: 'app-teams-grid',
  templateUrl: './teams-grid.component.html',
  styleUrls: ['./teams-grid.component.css'],
})
export class TeamsGridComponent implements OnInit {
  @Input() teams: FirstLevelTeamData[] | Team[];
  @Input() teamMemberships: AsyncList<TeamMembership>;
  @Input() isOnboarding = false;

  teamsSettings: AsyncList<TeamSettings>;

  teamsReady = false;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _teamSettingsHttpService: TeamSettingsHttpService,
    private _teamMembershipHttpService: TeamMembershipHttpService
  ) {}

  ngOnInit(): void {
    if (this.isOnboarding) {
      this.teamsSettings = new AsyncList<TeamSettings>(
        this._teamSettingsHttpService
      );
      this.teamsSettings.load().subscribe(() => (this.teamsReady = true));
    } else {
      this.teamsReady = true;
    }
  }

  get isAdminTeams(): boolean {
    return (
      this.teams.length ===
      this.teams.filter((team) => team.team_type === 'admin').length
    );
  }

  hasChildTeams(teamUuid: string): boolean {
    return (
      (this.teams as FirstLevelTeamData[]).find(
        (team) => team.uuid === teamUuid
      )?.childrenCount > 0
    );
  }

  navigateToTeamDashboardDetail(teamUuid: string): void {
    if (!this.isOnboarding) {
      if (this.hasChildTeams(teamUuid)) {
        this._router.navigate([`teams/${teamUuid}`]);
      } else {
        this._router.navigate([`teams/${teamUuid}/members`]);
      }
    }
  }

  isTeamMember(teamUuid: string): boolean {
    return !!this.teamMemberships.state.items.find(
      (membership) => membership.team === teamUuid
    );
  }

  getTeamSettings(teamUuid: string): TeamSettings {
    return this.teamsSettings.state.items.find(
      (teamSettings) => teamSettings.team === teamUuid
    );
  }

  onSelectionToggle(checked: boolean, teamUuid: string): void {
    if (checked) {
      const membership_data = {
        team: teamUuid,
        user: this._authService.get_current_user_uuid(),
        role: 'member',
      };

      this.teamMemberships.create(membership_data).subscribe();
    } else {
      this.teamMemberships
        .remove(
          this.teamMemberships.state.items.find(
            (membership) => membership.team === teamUuid
          ).uuid
        )
        .subscribe();
    }
  }
}
