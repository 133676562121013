import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-text-input-dialog',
  templateUrl: './text-input-dialog.component.html',
  styleUrls: ['./text-input-dialog.component.css'],
})
export class TextInputDialogComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<TextInputDialogComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      submit(comment: string): void;
    }
  ) {}

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      comment: ['', [Validators.required]],
    });
  }

  submit(): void {
    this.data.submit(this.form.controls.comment.value);
    this.dialogRef.close(true);
  }
}
