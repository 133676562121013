<form [formGroup]="form">
  <div fxLayout="row" class="select-container">
    <ng-select
      appearance="outline"
      (search)="search($event.term)"
      (change)="searchSelect($event)"
      [loading]="searchUsers.state.isProcessing"
      notFoundText="По вашему запросу не нашлось пользователей"
      loadingText="Ищем..."
      appendTo="body"
      class="users-search"
      formControlName="search"
    >
      <ng-option *ngFor="let user of searchUsers.state.items" [value]="user"
        >{{ user.first_name }} {{ user.last_name }} ({{
          user.email
        }})</ng-option
      >
    </ng-select>
  </div>
</form>
