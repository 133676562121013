import {
  GetDetailRequest,
  GetListRequest,
  ViewSetHttpService,
} from '@services/http/rest/ViewSetHttpService';
import { Observable, Subject } from 'rxjs';

interface BaseViewSetModel {
  uuid: string;
}

export interface AsyncDetailState<ViewSetModel> {
  item: ViewSetModel;
  isLoaded: boolean;
  isProcessing: boolean;
}

export class AsyncDetail<ViewSetModel extends BaseViewSetModel> {
  public state: AsyncDetailState<ViewSetModel> = {
    isProcessing: false,
    isLoaded: false,
    item: null,
  };

  public api: ViewSetHttpService<ViewSetModel>;
  public uuid: string;
  public requestParams: GetDetailRequest = {};

  constructor(uuid: string, api: ViewSetHttpService<ViewSetModel>) {
    this.api = api;
    this.uuid = uuid;
    this.requestParams = {};
  }

  public load(): Observable<ViewSetModel> {
    const responseSubject$ = new Subject<ViewSetModel>();
    this.state.isProcessing = true;

    this.api.detail(this.uuid, this.requestParams).subscribe(
      (response: ViewSetModel) => {
        this.state = {
          item: response,
          isLoaded: true,
          isProcessing: false,
        };
        responseSubject$.next(response);
      },
      (error) => {
        this.state.isProcessing = false;
        // TODO: add logger service
        responseSubject$.error(error);
      }
    );

    return responseSubject$;
  }

  remove(): Observable<null> {
    const responseSubject$ = new Subject<null>();
    this.api.delete(this.uuid).subscribe(
      () => {
        responseSubject$.next();
        this.state.item = null;
      },
      (error) => {
        // TODO: add logger service
        responseSubject$.error(error);
      }
    );

    return responseSubject$;
  }

  update(data: Partial<ViewSetModel>): Observable<ViewSetModel> {
    const responseSubject$ = new Subject<ViewSetModel>();
    this.api.update(this.uuid, data).subscribe(
      (response) => {
        responseSubject$.next(response);
        this.state.item = {
          ...this.state.item,
          ...data,
        };
      },
      (error) => {
        // TODO: add logger service
        responseSubject$.error(error);
      }
    );

    return responseSubject$;
  }
}
