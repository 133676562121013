<div fxLayout="row">
  <div fxFlex>
    <div class="speciality-groups-title">Специальности</div>
  </div>
  <div fxFlex="10rem">
    <a
      *ngIf="isAdmin()"
      mat-raised-button
      routerLink="/library/speciality-groups/create"
    >
      <mat-icon>add</mat-icon>
      Добавить
    </a>
  </div>
</div>

<div fxLayout="row">
  <div fxFlex>
    <div class="speciality-groups-hint">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="3rem">
          <mat-icon>help</mat-icon>
        </div>
        <div fxFlex>
          Используйте готовые шаблоны специальностей для быстрого старта
          <br />
          Скопируйте специальность в команду и кастомизируйте их под свою
          специфику
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container
  *ngIf="
    specialityGroups.state.items.length === 0;
    then tmplGroupsEmpty;
    else tmplGroups
  "
>
</ng-container>

<ng-template #tmplGroupsEmpty>
  <div class="speciality-groups-empty">
    Шаблонов специальностей пока не создано
  </div>
</ng-template>

<ng-template #tmplGroups>
  <div fxLayout="row" class="speciality-groups-wrapper">
    <div
      fxFlex="30rem"
      class="flex-item"
      *ngFor="let specialityGroup of specialityGroups.state.items"
    >
      <mat-card>
        <div fxLayout="row">
          <div fxFlex>
            <span class="speciality-name">
              {{ specialityGroup.name }}
            </span>
          </div>
          <div fxFlex="3rem" fxLayoutAlign="end start">
            <button
              *ngIf="isAdmin()"
              mat-icon-button
              [routerLink]="[
                '/library/speciality-groups',
                specialityGroup.uuid,
                'edit'
              ]"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
        <app-library-item
          *ngFor="
            let speciality of specialityGroup.specialities.slice(
              0,
              showMoreMap[specialityGroup.uuid] ? 100 : 3
            )
          "
          [item]="serializeSpeciality(speciality)"
        ></app-library-item>
        <p class="library-specialities-more">
          <a
            *ngIf="isShowMore(specialityGroup)"
            (click)="showMore(specialityGroup.uuid)"
            class="library-specialities-more-link"
            >и ещё
            {{ specialityGroup.specialities.length - 3 }} специальностей</a
          >
        </p>
      </mat-card>
    </div>
  </div>
</ng-template>
