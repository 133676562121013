import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from 'src/app/services/api.service';
import { LoggingService } from 'src/app/services/logging.service';

@Injectable({
  providedIn: 'root'
})
export class SpecialityDomainClaimService {

  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService,
  ) { }

  public fetch_by_speciality_uuid(speciality_uuid: string): Observable<any> {
    const params = {speciality: speciality_uuid, expand: 'speciality,domain'};
    return this.api_service.get(`specialities/domainclaim`, params).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} fetched domain claims for speciality ${speciality_uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to fetch speciality domain claims`);
        }
    ));
  }

  public update(claim_uuid: string, claim_data: any): Observable<any> {
    return this.api_service.patch(`specialities/domainclaim/${claim_uuid}`, claim_data, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} updated speciality domain claim with uuid ${response.uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to update speciality domain claim`);
        }
      ));
  }

  public delete(claim_uuid: string): Observable<any> {
    return this.api_service.delete(`specialities/domainclaim/${claim_uuid}`, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} deleted speciality domain claim with uuid ${claim_uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to delete speciality domain claim`);
        }
      ));
  }

  public create_claims_for_domain_recursively(domain_claim_data): Observable<any> {
    return this.api_service.post(`specialities/domainclaim/create_claims_for_domain_recursively`, domain_claim_data, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} created claims for domain`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to create claims for domain`);
        }
      ));
  }

  public reorder({moved_uuid, target_uuid}: {moved_uuid: string, target_uuid: string}) {
    return this.api_service.post(`specialities/domainclaim/reorder`, {moved_uuid, target_uuid}, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} reorder speciality domain claims`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to reorder speciality domain claims`);
        }
      ));
  }

}
