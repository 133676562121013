<div
  class='company-container'
  fxLayout='column'
  *ngIf='company?.state.isLoaded && companyProjects?.state.isLoaded'
>
  <div fxLayout='row' class='company-title'>
    <div fxFlex='95' fxLayoutAlign='start center' fxLayoutGap='2rem'>
      <button (click)='onBackClick()' mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span>{{ company.state.item.name }}</span>
    </div>
  </div>
  <div fxLayout='row'>
    <div
      fxLayoutAlign='start center'
      class='add-project-button-container'
      (click)='onProjectCreateClick()'
    >
      <button
        mat-raised-button
        class='add-project-button'
        color='primary'
      >
        <mat-icon>add_circle_outline</mat-icon>
      </button>
      <span class='add-project-button-label'>Добавить новый проект</span>
    </div>
  </div>
  <div fxLayout='column' fxLayoutGap='8px'>
    <div
      fxLayout='row'
      fxLayoutAlign='start center'
      class='project-container'
      *ngFor='let project of companyProjects.state.items'
      (click)='onProjectClick(project)'
    >
      <div fxFlex='30' class='project-container-text'>{{ project.name }}</div>
      <div fxFlex='40'></div>
      <span
        fxFlex='30'
        fxLayoutAlign='center center'
        class='project-container-text'
      >
        {{ project.description }}
      </span>
    </div>
  </div>
</div>
