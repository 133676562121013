import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReviewHttpService } from '@services/http/ReviewHttpService';
import { AsyncList } from '@rest/AsyncList';
import { Review } from '@models/reviews/review';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { User } from '@models/user/user';
import { TitleService } from 'src/app/services/title.service';
import { AuthService } from '../../../../../services/auth/auth.service';

@Component({
  selector: 'app-team-reviews',
  templateUrl: './team-reviews.component.html',
  styleUrls: ['./team-reviews.component.css'],
})
export class TeamReviewsComponent implements OnInit, OnDestroy {
  teamUuid: string;
  teamReviews: AsyncList<Review>;
  teamSpecialities: AsyncList<Speciality>;

  ongoingReviews: Review[] = [];
  responsibleReviews: Review[] = [];
  completedReviews: Review[] = [];

  navigationSubscription;
  componentReused = false;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    private _reviewHttpService: ReviewHttpService,
    private _specialityHttpService: SpecialityHttpService,
    private _titleService: TitleService
  ) {
    this.navigationSubscription = this._router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (this.componentReused && e instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    this.componentReused = true;
    this._titleService.set_title('Ревью команды');
    this.ongoingReviews = [];
    this.completedReviews = [];
    this.teamUuid = this._activatedRoute.snapshot.paramMap.get('uuid');
    this.teamReviews = new AsyncList<Review>(this._reviewHttpService);
    this.teamReviews.setRequestParams({
      params: {
        team: this.teamUuid,
        expand: 'subjects',
      },
    });
    this.teamReviews.load().subscribe(() => {
      this.teamReviews.state.items.forEach((review) => {
        if (review.status === 'CREATED' || review.status === 'IN_PROGRESS') {
          this.ongoingReviews.push(review);
          if (
            review.responsible_user ===
            this._authService.get_current_user_uuid()
          ) {
            this.responsibleReviews.push(review);
          }
        } else {
          this.completedReviews.push(review);
        }
      });
    });
    this.teamSpecialities = new AsyncList<Speciality>(
      this._specialityHttpService
    );
    this.teamSpecialities.setRequestParams({
      params: {
        team: this.teamUuid,
      },
    });
    this.teamSpecialities.load().subscribe();
  }

  getReviewSpeciality(review: Review): string {
    if (
      review.config &&
      review.config.subject_speciality_map &&
      review.subjects.length
    ) {
      const reviewSubject = review.subjects[0] as User,
        reviewSpecialityUuid =
          review.config?.subject_speciality_map[reviewSubject.uuid][0];

      return this.teamSpecialities.state.items.find(
        (speciality) => speciality.uuid === reviewSpecialityUuid
      )?.name;
    }
    return '';
  }

  getPrettyReviewDate(review: Review): string {
    if (review.deadline_start && review.deadline_end) {
      const dateFrom = new Date(review.deadline_start),
        dateTo = new Date(review.deadline_end);
      return `${dateFrom.getDate()} ${dateFrom.toLocaleString('default', {
        month: 'short',
      })} - ${dateTo.getDate()} ${dateTo.toLocaleString('default', {
        month: 'short',
      })}`;
    }
    return '';
  }

  ngOnDestroy(): void {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  onReviewCreateClick(): void {
    this._router.navigate([`team/${this.teamUuid}/review/create`]);
  }

  onReviewClick(review: Review): void {
    this._router.navigate([`team/${this.teamUuid}/reviews/${review.uuid}`]);
  }
}
