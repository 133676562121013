<div class="dialog-container">
  <div class="dialog-title">Комментарий</div>
  <div>
    <form [formGroup]="form">
      <div fxLayout="column">
        <mat-label class="textfield-label">Текст комментария</mat-label>
        <mat-form-field floatLabel="never" appearance="outline">
          <input matInput type="text" formControlName="comment" />
        </mat-form-field>
      </div>
    </form>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button
      class="dialog-reject-button"
      [mat-dialog-close]="false"
      mat-raised-button
    >
      Отменить
    </button>
    <button
      class="dialog-accept-button"
      [disabled]="form.invalid"
      mat-raised-button
      (click)="submit()"
    >
      Сохранить
    </button>
  </div>
</div>
