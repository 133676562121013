import { Component, OnInit } from '@angular/core';
import { TeamHttpService } from '@services/http/TeamHttpService';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-matrix-v3',
  templateUrl: './matrix-v3.page.html',
  styleUrls: ['./matrix-v3.page.css'],
})
export class MatrixV3Page implements OnInit {
  teamUuid = '';
  matrixData = null;

  constructor(
    private _teamHttpService: TeamHttpService,
    private _activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.teamUuid = this._activatedRoute.snapshot.params.uuid;
  }
}
