<div fxLayout="column" class="dashboard-container">
  <div class="title">
    {{ title }}
  </div>
  <mat-divider></mat-divider>
  <div fxLayout="column" fxLayoutGap="8px">
    <div *ngFor="let entity of optionsMap" fxLayout="column">
      <div
        class="entity-container"
        [class.selected-parent]="
          isSelected(entity.url) || isAnyChildSelected(entity.options)
        "
      >
        <div fxLayout="row" (click)="onEntityClick(entity)">
          <span fxFlex class="entity-name">{{ entity.name }}</span>
          <!--        <div fxLayoutAlign="center center" *ngIf="entity.options">-->
          <!--          <mat-icon fxFlex="20">{{-->
          <!--            entity.isExpanded ? 'expand_less' : 'expand_more'-->
          <!--          }}</mat-icon>-->
          <!--        </div>-->
        </div>
        <div *ngIf="entity.options && entity.isExpanded" fxLayout="row">
          <div fxLayout="column" fxLayoutGap="4px" fxFlex>
            <div
              class="menu-option"
              [class.selected-child]="isSelected(option.url)"
              *ngFor="let option of entity.options"
              fxFlex="70"
              (click)="onOptionClick(option)"
            >
              {{ option.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
