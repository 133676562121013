import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  GetDetailRequest,
  ViewSetHttpService,
} from './rest/ViewSetHttpService';
import { SpecialityCompetenceClaim } from '@models/specialities/speciality-competence-claim';
import { Observable } from 'rxjs';
import { ThingCompetenceClaimMap } from '../../../utils/build-thing-competenceclaim-map';

@Injectable({
  providedIn: 'root',
})
export class SpecialityCompetenceClaimHttpService extends ViewSetHttpService<SpecialityCompetenceClaim> {
  http: HttpClient;

  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'specialities/competenceclaim';

  public addClaimsWithGrades(
    data: {
      speciality: string;
      thing: string;
    }[]
  ): Observable<any> {
    return this.http.post<{ speciality: string; thing: string }[]>(
      `${this.getUrl()}/add_claims_with_grades`,
      data
    );
  }

  public setSpecialityCompetenceClaimsLevel(data: {
    speciality: string;
    things_competence_claims: ThingCompetenceClaimMap;
    grade_levels: {
      [gradeUuid: string]: number;
    };
  }): Observable<any> {
    return this.http.post<{
      speciality: string;
      things_competence_claims: ThingCompetenceClaimMap;
      grade_levels: {
        [gradeUuid: string]: number;
      };
    }>(`${this.getUrl()}/set_speciality_competence_claims_level`, data);
  }

  public updateClaimsIsKey(data: {
    speciality: string;
    thing: string;
    is_key: boolean;
  }): Observable<any> {
    return this.http.post<{
      speciality: string;
      thing: string;
      is_key: boolean;
    }>(`${this.getUrl()}/update_thing_claims_is_key`, data);
  }

  public onSpecialityThingDelete(data: {
    speciality: string;
    thing: string;
  }): Observable<any> {
    return this.http.post<{ speciality: string; thing: string }>(
      `${this.getUrl()}/on_speciality_thing_delete`,
      data
    );
  }
}
