<ng-container *ngIf="is_data_loaded; then tmpl_tab_content; else tmpl_preloader">
</ng-container>

<ng-template #tmpl_preloader>
    <app-preloader-progressbar></app-preloader-progressbar>
</ng-template>

<ng-template #tmpl_tab_content>
    <div fxLayout="row">
        <div fxFlex>

            <div *ngIf="memberships.length === 0">
                <div class="app-admin-users-user-tab-memberships-empty">
                    Пользователь не состоит в командах
                </div>
            </div>

            <div *ngIf="memberships.length > 0">

                <div *ngFor="let membership of memberships">
                    <div class="app-admin-users-user-tab-memberships-listitem-container">

                        <div fxLayout="row">
                            <div fxFlex fxLayoutAlign="start center">
                                <div class="app-admin-users-user-tab-memberships-listitem-name">
                                    <a routerLink="/admin/teams/{{ membership.team.uuid }}">
                                        {{ membership.team.name }}
                                    </a>
                                </div>
                            </div>
                            <div fxFlex="20rem" fxLayoutAlign="start center">
                                <div class="app-admin-users-user-tab-memberships-listitem-role">
                                    роль: {{ membership.role }}
                                </div>
                            </div>
                            <div fxFlex="3rem">
                                <button mat-icon-button (click)="on_button_delete_team_membership(membership)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</ng-template>