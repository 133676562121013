// TODO https://stackoverflow.com/questions/12115691/svg-d3-js-rounded-corners-on-one-side-of-a-rectangle
import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import * as d3 from 'd3';

import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-histogram-simple',
  templateUrl: './histogram-simple.component.html',
  styleUrls: ['./histogram-simple.component.scss'],
})
export class HistogramSimpleComponent
  implements OnInit, OnChanges, AfterViewInit {
  @Input()
  set chartName(htmlElementClassName: string) {
    this._htmlElementSelectorClass = htmlElementClassName;
  }
  get chartName(): string {
    return this._htmlElementSelectorClass;
  }

  @Input() horizontal: boolean = false;
  @Input() axisNameX: string;
  @Input() axisLabelsX: string[];
  @Input() axisNameY: string;
  @Input() sizes: SizesHistogram = {};
  @Input()
  set data(inputData: ChartHistogramData[]) {
    this._data = inputData;
  }
  get data(): ChartHistogramData[] {
    return this._data;
  }

  private _htmlElementSelectorClass: string;
  private _data: ChartHistogramData[];

  private _svgOptions: SizesHistogram = {
    width: 400,
    height: 100,
    margin: {
      top: 30,
      right: 10,
      bottom: 50,
      left: 70,
    },
    colors: {
      bar: '#eee',
    },
    xLabelsFont: { size: '10px' },
  };

  constructor(private _loggingService: LoggingService) {}

  ngOnInit(): void {
    if (this.horizontal) {
      this._svgOptions.margin.top = 16;
      this._svgOptions.margin.bottom = 16;
      this._svgOptions.height = 128;
    }

    this._loggingService.debug(`${this.constructor.name} init`);
    this._svgOptions = { ...this._svgOptions, ...this.sizes };
  }

  ngAfterViewInit(): void {
    if (this.horizontal) {
      this._drawHorizontalChart();
    } else {
      this._drawChart();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.horizontal) {
      this._drawHorizontalChart();
    } else {
      this._drawChart();
    }
  }

  private get _svgWidth(): number {
    return (
      this._svgOptions.width +
      this._svgMarginLeft +
      this._svgMarginRight +
      50 +
      (this.horizontal ? 50 : 0)
    );
  }
  private get _svgHeight(): number {
    return this._svgOptions.height + this._svgMarginTop + this._svgMarginBottom;
  }
  private get _svgMarginTop(): number {
    return this._svgOptions.margin.top;
  }
  private get _svgMarginBottom(): number {
    return this._svgOptions.margin.bottom;
  }
  private get _svgMarginLeft(): number {
    return this._svgOptions.margin.left;
  }
  private get _svgMarginRight(): number {
    return this._svgOptions.margin.right;
  }
  private get _svgColorBar(): string {
    return this._svgOptions.colors.bar;
  }

  private get _svgEffectiveWidth(): number {
    return this._svgWidth - this._svgMarginLeft - this._svgMarginRight;
  }
  private get _svgEffectiveHeight(): number {
    return this._svgHeight - this._svgMarginTop - this._svgMarginBottom;
  }

  private _getAxisLabelX(index: number): string {
    if (this.axisLabelsX) {
      return this.axisLabelsX[index];
    } else {
      return `${index}`;
    }
  }


  private _setOpacity(d, i) {
    const availableTicksInLine = 15;
    const totalTicks = this.data.length;
    const target_metki = Math.ceil(totalTicks / availableTicksInLine);

    if (totalTicks > availableTicksInLine) {
      return i % target_metki === 0 ? 1 : 0;
    }
  }

  // это горизонтальная гистограмма она тут ничего не делает и не задействована
  private _drawHorizontalChart() {
    this._loggingService.debug(`${this.constructor.name} draw chart`);
    const parent = d3.select(`.${this.chartName}`);

    // redraw entire element
    parent.select('svg').remove();

    const svg = parent
      .append('svg')
      .attr('width', this._svgWidth)
      .attr('height', this._svgHeight);

    svg
      .append('rect')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('ry', '12')
      .attr('fill', '#FFFFFF');

    const g = svg
      .append('g')
      .attr(
        'transform',
        'translate(' + this._svgMarginLeft + ',' + this._svgMarginTop + ')'
      );

    const x_scale = d3.scaleLinear().range([0, this._svgEffectiveWidth - 20]);

    const y_scale = d3
      .scaleBand()
      .rangeRound([0, this._svgEffectiveHeight])
      .padding(0.1);

    const max_x = d3.max(this.data, (d) => d.y);
    x_scale.domain([0, max_x + parseInt((max_x / 10).toString())]).nice();
    y_scale.domain(this.data.map((d) => String(d.x)));

    g.append('g')
      .attr('transform', 'translate(0,' + this._svgEffectiveHeight + ')')
      .call(d3.axisBottom(x_scale).ticks(4))
      .selectAll('text')
      .attr('y', 0)
      .attr('x', 9)
      .attr('dy', '.3em')
      .attr('transform', 'rotate(25)')
      .style('font-size', this._svgOptions.xLabelsFont.size)
      .style('text-anchor', 'start');

    g.append('g')
      .call(d3.axisLeft(y_scale).tickSize(0))
      .selectAll('text')
      .attr('opacity', 0);

    const bars = g.append('g');

    const barContainer = bars
      .selectAll('g')
      .data(this.data)
      .enter()
      .append('g');

    barContainer
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d) => x_scale(0) + 1)
      .attr('y', (d) => y_scale(String((d as ChartHistogramData).x)))
      .attr('title', (d) => Number((d as ChartHistogramData).y))
      .attr('ry', '8')
      .attr('width', (d) => x_scale(Number((d as ChartHistogramData).y)))
      .attr('height', y_scale.bandwidth())
      .attr('fill', (d) => d.color);

    barContainer
      .append('text')
      .attr('x', (d) => x_scale(d.y) + 10)
      .attr(
        'y',
        (d) =>
          y_scale(String((d as ChartHistogramData).x)) +
          150 / this.data.length / 2
      )
      .attr('opacity', 1)
      .attr('font-weight', 500)
      .attr('font-size', '10px')
      .attr('line-height', '16px')
      .attr('fill', (d) => d.color)
      .text((d) => d.y);

    g.selectAll('.bar').data(this.data).exit().remove();
  }

  private _drawChart() {
    this._loggingService.debug(`${this.constructor.name} draw chart`);
    const parent = d3.select(`.${this.chartName}`);
    parent.select('svg').remove();

    const svg = parent
      .append('svg')
      .attr('width', this._svgWidth)
      .attr('height', this._svgHeight);

    const g = svg
      .append('g')
      .attr(
        'transform',
        'translate(' + this._svgMarginLeft + ',' + this._svgMarginTop + ')'
      );

    const x_scale = d3
      .scaleBand()
      .rangeRound([0, this._svgEffectiveWidth])
      .padding(0.1);

    const y_scale = d3.scaleLinear().rangeRound([this._svgEffectiveHeight, 0]);

    x_scale.domain(this.data.map((d) => String(d.x)));
    y_scale.domain([
      0,
      d3.max(this.data, (d) => Number((d as ChartHistogramData).y)),
    ]);

    g.append('g')
      .attr('transform', 'translate(0,' + this._svgEffectiveHeight + ')')
      .call(d3.axisBottom(x_scale)
        .tickFormat((d, i) => this._getAxisLabelX(i)),
      )
      .selectAll('line')
      .attr('opacity', (d, i) => this._setOpacity(d, i));

    g.selectAll('text')
      .attr('y', 0)
      .attr('x', 9)
      .attr('dy', '.3em')
      .attr('transform', 'rotate(25)')
      .style('font-size', this._svgOptions.xLabelsFont.size)
      .style('text-anchor', 'start')
      .attr('opacity', (d, i) => this._setOpacity(d, i));

    const max_y_value = d3.max(this.data, (d) => Number((d as any).y));
    const max_y_ticks = max_y_value < 10 ? max_y_value : 10;

    g.append('g')
      .call(
        d3.axisLeft(y_scale).ticks(max_y_ticks).tickFormat(d3.format('.0f')),
      )
      .append('text')
      .attr('fill', '#000')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('dx', '0.71em')
      .attr('text-anchor', 'end')
      .text(this.axisNameY);

    g.selectAll('.bar')
      .data(this.data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d) => x_scale(String((d as ChartHistogramData).x)))
      .attr('y', (d) => y_scale(Number((d as ChartHistogramData).y)))
      .attr('title', (d) => Number((d as ChartHistogramData).y))
      .attr('ry', '4')
      .attr('width', x_scale.bandwidth())
      .attr(
        'height',
        (d) =>
          this._svgEffectiveHeight -
          y_scale(Number((d as ChartHistogramData).y)),
      )
      .attr('fill', this._svgColorBar);

    g.selectAll('.bar')
      .data(this.data)
      .append('title')
      .text((d) => `${(d as ChartHistogramData).x} = ${(d as ChartHistogramData).y}`);

    g.selectAll('.bar').data(this.data).exit().remove();
  }
}

interface ChartHistogramData {
  x: number;
  y: number;
  color?: string;
}

interface SizesHistogram {
  width?: number;
  height?: number;
  margin?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  colors?: {
    bar?: string;
  };
  xLabelsFont?: {
    size?: string;
  };
}
