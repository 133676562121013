import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

import { Thing } from 'src/app/models/ontology/thing';
import { ThingLevel } from 'src/app/models/ontology/thing-level';
import { ThingLevelsService } from 'src/app/services/ontology/thing-levels.service';
import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';

interface CreateThingLevelData {
  levels: ThingLevel[];
  service: ThingLevelsService;
  formData: any;
}

@Component({
  selector: 'app-create-thing-level',
  templateUrl: './create-thing-level.component.html',
  styleUrls: ['./create-thing-level.component.css'],
})
export class CreateThingLevelComponent {
  form: UntypedFormGroup;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private loggingServie: LoggingService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<CreateThingLevelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateThingLevelData
  ) {
    this.form = this.formBuilder.group({
      title: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      description: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  submit() {
    this.data.service
      .create({
        ...this.form.value,
        ...this.data.formData,
      })
      .subscribe({
        next: (level) => {
          this.loggingServie.debug(
            `${this.constructor.name} successfully created thing level`
          );
          this.alertService.success('Уровень создан');
          this.data.levels.push(level);
          this.dialogRef.close();
        },
        error: (error) => {
          this.loggingServie.debug(
            `${this.constructor.name} thing level creation failed`
          );
          this.alertService.error('Ошибка создания уровня компетенции');
        },
      });
  }
}
