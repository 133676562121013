<app-with-processing
  [processing]='
    validation?.state.isProcessing ||
    domains?.state.isProcessing ||
    specialityCompetenceClaims?.state.isProcessing ||
    validationComments?.state.isProcessing
  '
>
  <div
    class='validation-container'
    fxLayout='column'
    *ngIf='
      validation?.state.isLoaded &&
      domains?.state.isLoaded &&
      specialityCompetenceClaims?.state.isLoaded &&
      validationComments?.state.isLoaded
    '
  >
    <div
      fxLayout='row'
      class='validation-title'
      fxLayoutAlign='start center'
      fxLayoutGap='8px'
    >
      <div fxFlex fxLayoutAlign='start center' fxLayoutGap='2rem'>
        <button (click)='onBackClick()' mat-icon-button>
          <mat-icon>arrow_back</mat-icon>
        </button>
        <span>{{ speciality.name }}</span>
      </div>
      <button
        mat-stroked-button
        fxFlex='15rem'
        [disabled]='isCopyBtnDisabled'
        *ngIf='validation.state.item.status === 1 && isAdmin'
        (click)='onCopySpecialityToLibrary()'
      >
        Скопировать в библиотеку
      </button>
      <span
        *ngIf='validation.state.item.status === 3'
        class='status-text'
        fxFlex='15rem'
      >Добавлена в библиотеку</span
      >
      <button
        mat-icon-button
        *ngIf='isCreator || isAdmin'
        (click)='openCreateValidationDialog()'
      >
        <mat-icon fxFlex='5'>edit</mat-icon>
      </button>
    </div>
    <app-static-textfield-group
      [fields]='validationInfoData'
    ></app-static-textfield-group>
    <div [style.margin-top.px]='20'>Навыки</div>
    <mat-divider [style.margin-bottom.px]='10'></mat-divider>
    <app-domain-thing-tree
      *ngIf='treeDataReady'
      [specialityUuid]='speciality.uuid'
      [domains]='domains.state.items'
      [things]='things'
      [specialityCompetenceClaims]='specialityCompetenceClaims'
    ></app-domain-thing-tree>
    <mat-tab-group [animationDuration]="'0ms'" mat-stretch-tabs='false'>
      <mat-tab label='Одобрение'>
        <div>&nbsp;</div>
        <div
          *ngFor='let reviewer of validation.state.item.related_reviewers'
          class='reviewer-container'
          fxLayout='row'
        >
          <div fxFlex='80' fxLayout='column' fxLayoutAlign='start start'>
            <span class='reviewer-name'
            >{{ reviewer.user.last_name + ' ' + reviewer.user.first_name }}
            </span>
            <span class='reviewer-email'>{{ reviewer.user.email }} </span>
          </div>
          <div fxFlex='20' fxLayoutAlign='center center'>
            <div
              class='reviewer-status-container'
              fxLayout='row'
              [ngStyle]="{
                background:
                  reviewer.status === 1
                    ? '#DDF5C5'
                    : reviewer.status === 2
                    ? '#FFE8E1'
                    : '#F9F9F9'
              }"
            >
              {{
              reviewer.status === 1
                ? 'Одобрено'
                : reviewer.status === 2
                  ? 'Отклонено'
                  : 'В процессе'
              }}
            </div>
          </div>
        </div>
        <div
          fxLayout='row'
          fxLayoutAlign='end center'
          fxLayoutGap='8px'
          [style.margin-bottom.px]='20'
          *ngIf='validation.state.item.status !== 3'
        >
          <button
            class='reject-button'
            mat-raised-button
            [disabled]='!isReviewer()'
            (click)='createValidationComment(true)'
          >
            Отменить
          </button>
          <button
            class='accept-button'
            mat-raised-button
            [disabled]='!isReviewer()'
            (click)='acceptValidation()'
          >
            Одобрить
          </button>
        </div>
      </mat-tab>
      <mat-tab label='Обсуждение'>
        <div>&nbsp;</div>
        <div
          *ngFor='let comment of validationComments.state.items'
          class='reviewer-container'
          fxLayout='row'
        >
          <div fxFlex='25' fxLayout='column' fxLayoutAlign='start start'>
            <span class='reviewer-name'
            >{{
              comment.speciality_reviewer.user.last_name +
              ' ' +
              comment.speciality_reviewer.user.first_name
              }}
            </span>
            <span class='reviewer-email'
            >{{ comment.speciality_reviewer.user.email }}
            </span>
          </div>
          <div fxFlex='15' fxLayoutAlign='start center'>
            {{ getPrettyDate(comment.date_created) }}
          </div>
          <div fxFlex='60' fxLayoutAlign='start center'>
            {{ comment.message }}
          </div>
        </div>
        <div
          fxLayout='row'
          fxLayoutAlign='end center'
          fxLayoutGap='8px'
          [style.margin-bottom.px]='20'
        >
          <button
            class='accept-button'
            mat-raised-button
            (click)='createValidationComment(false)'
          >
            Оставить комментарий
          </button>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</app-with-processing>
