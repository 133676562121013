<form [formGroup]='form'>
  <mat-card>
    <div class='form-group'>
      <div fxLayout='row' fxLayoutGap='5rem'>
        <div fxFlex='50%'>
          <h3>Редактирование специальности {{ speciality.state.item.name }}</h3>
          <mat-form-field [style.width.rem]='30'>
            <input
              matInput
              placeholder='Название'
              type='text'
              formControlName='name'
              required
            />
          </mat-form-field>
          <mat-form-field [style.width.rem]='30'>
            <input
              matInput
              placeholder='Описание'
              type='text'
              formControlName='description'
              required
            />
          </mat-form-field>
        </div>
        <div fxFlex='50%'>
          <mat-icon>help</mat-icon>
          <p>
            Специальности описывают комплекс компетенций, навыков и опыта,
            необходимых для выполнения задач.
          </p>
          <p>
            Например, это может быть Разработчик, Архитектор, Дизайнер или
            Менеджер проектов.
          </p>
        </div>
      </div>

      <div [style.width.rem]='30' fxLayoutAlign='end center'>
        <button mat-raised-button color='primary' [disabled]='form.invalid' (click)='update()'>
          Сохранить
        </button>
      </div>

    </div>
    <mat-divider></mat-divider>
    <mat-tab-group [animationDuration]="'0ms'" mat-stretch-tabs='false'>
      <mat-tab label='Профиль компетенций'>
        <div class='form-group'>
          <h3>Профиль компетенций специальности</h3>
          <div fxLayout='row'>
            <div
              fxLayoutAlign='start center'
              class='add-competence-button-container'
              (click)='onCompetenceFromLibraryAdd()'
            >
              <button
                mat-raised-button
                class='add-competence-button'
                color='primary'
              >
                <mat-icon>add_circle_outline</mat-icon>
              </button>
              <span class='add-competence-button-label'
              >Добавить компетенции в профиль</span
              >
            </div>
          </div>
          <app-domain-thing-tree
            *ngIf='
          specialityDomainClaims?.state.isLoaded &&
          specialityCompetenceClaims?.state.isLoaded &&
          thingGradeLevelsMapReady
        '
            [specialityUuid]='speciality.state.item.uuid'
            [specialityDomainsClaims]='specialityDomainClaims.state.items'
            [things]='things'
            [libraryEdit]='true'
            [thingGradeLevelsMap]='thingGradeLevelsMap'
            [specialityCompetenceClaims]='specialityCompetenceClaims'
            [expandFirstNode]='true'
          ></app-domain-thing-tree>
        </div>
      </mat-tab>
      <mat-tab label='Уровни развития'>
        <div *ngIf='specialityGrades?.state.isLoaded'>
          <div *ngIf='editingGrade' [style.width.rem]='30' fxLayout='column'>
            <div fxFlex>
              <form [formGroup]='editGradeForm' fxLayout='column'>
                <mat-form-field fxFlex>
                  <mat-label>Название уровня</mat-label>
                  <input
                    matInput
                    formControlName='name'
                    type='text'
                    required
                  />
                </mat-form-field>
                <mat-form-field fxFlex>
                  <mat-label>Описание уровня</mat-label>
                  <input
                    matInput
                    formControlName='description'
                    type='text'
                    required
                  />
                </mat-form-field>
              </form>
            </div>
            <div fxFlex fxLayoutAlign='end center'>
              <button mat-raised-button color='primary' (click)='onSaveGradeChanges()'>Сохранить</button>
            </div>
          </div>
          <div fxLayout='column' fxLayoutGap='8px' class='list-container'>
            <div fxLayout='row'>
              <span fxFlex='30'>Уровень</span>
              <span fxFlex>Описание</span>
            </div>
            <div fxLayout='row' *ngFor='let grade of specialityGrades.state.items' class='list-item-container'>
              <span fxFlex='30'>{{ grade.name }}</span>
              <span fxFlex>{{ grade.description }}</span>
              <button mat-icon-button fxFlex='48px' (click)='onSpecialityGradeEdit(grade)' class='list-item-edit'>
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</form>
