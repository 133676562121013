<div class="invite-member-dialog">
  <h2>Заявка на вступление в команду</h2>
  <div class="invite-params">
    <div class="select-specialities">
      <ng-select
        multiple="true"
        bindLabel="name"
        placeholder="Выбрать специальности"
        [(ngModel)]="selectedSpecialities"
        [items]="specialities.state.items"
        (change)="onSelectSpecialities($event)"
        appendTo="body"
      ></ng-select>

      <div
        class="speciality-grade"
        *ngFor="let speciality of selectedSpecialities"
      >
        <ng-select
          [placeholder]="getGradePlaceholder(speciality.name)"
          bindLabel="name"
          bindValue="uuid"
          [clearable]="false"
          [(ngModel)]="selectedGrades[speciality.uuid]"
          [items]="speciality.related_grades"
          appendTo="body"
        ></ng-select>
      </div>
    </div>
    <div class="add-buttons">
      <button
        mat-raised-button
        color="accent"
        [disabled]="!selectedSpecialities?.length"
        (click)="createTeamJoinRequest()"
      >
        Отправить заявку на вступление
      </button>
    </div>
  </div>
</div>
