<div class="router-outlet-container">
    <ng-container *ngIf="is_data_loaded; then component_content; else preloader">
    </ng-container>
</div>

<ng-template #preloader>
    <app-preloader></app-preloader>
</ng-template>

<ng-template #component_content>
    <div class="app-admin-survey-create-container">
        <div fxLayout="row">
            <div fxFlex="60" fxFlexOffset="20">
                <div class="app-admin-survey-create-main-container">
                    <div class="app-admin-survey-create-main-header-title">
                        Новый опрос
                    </div>
                    <div class="app-admin-survey-create-main-header-hint">
                        Подсказочка
                    </div>

                    <form [formGroup]="survey_create_form">

                        <div>
                            <mat-form-field [style.width.rem]=30>
                                <input matInput placeholder="Название" type="text" formControlName="survey_name" required>
                                <!-- <mat-error>
                                    {{errors.ou_name}}
                                </mat-error> -->
                            </mat-form-field>
                        </div>

                        <div>
                            <mat-form-field [style.width.rem]=30>
                                <input matInput placeholder="Описание" type="text" formControlName="survey_description" required>
                                <!-- <mat-error>
                                    {{errors.ou_description}}
                                </mat-error> -->
                            </mat-form-field>
                        </div>

                        <div>
                            <mat-form-field [style.width.rem]=30>
                                <mat-label>Тип опроса</mat-label>
                                <mat-select formControlName="survey_type" required>
                                    <mat-option value="linear">
                                        Линейный
                                    </mat-option>
                                    <mat-option value="tree">
                                        Древовидный
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
 
                        <button mat-raised-button
                                [disabled]="survey_create_form.invalid || uistate.survey_create_form_loading"
                                (click)="on_create()">
                            Создать
                        </button>

                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>


