import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TrackSpecialityUserCompareComponent } from './components/speciality/track-speciality-user-compare/track-speciality-user-compare.component';


const routes: Routes = [
  { path: 'speciality/:speciality_uuid/user/:user_uuid', component: TrackSpecialityUserCompareComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TrackRoutingModule { }
