import { Component, OnInit } from '@angular/core';
import { StatsHttpService } from '@services/http/StatsHttpService';
import { TitleService } from 'src/app/services/title.service';
import { DailyActiveUsersHttpService } from '@services/http/DailyActiveUsersHttpService';
import { DateAdapter } from '@angular/material/core';
import { take } from 'rxjs/operators';
import { TeamDateRangeHttpService } from '@services/http/TeamDateRangeHttpService';
import { TeamsDateRange } from '@models/stats/teams-date-range';
import { GetListResponse } from '@services/http/rest/ViewSetHttpService';
import { StatisticInterface } from '@models/stats/statistic-interface';
import { ReviewHttpService } from '@services/http/ReviewHttpService';
import { AsyncList } from '@rest/AsyncList';
import { Review } from '@models/reviews/review';
import { MatrixLoadingTime } from '@models/stats/matrix-loading-time';
import { MatrixLoadingStatsHttpService } from '@services/http/MatrixLoadingStatsHttpService';
import { combineLatest } from 'rxjs';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActiveUsersHttpService } from '@services/http/ActiveUsersHttpService';

interface ActiveUsersHistData {
  [day: string]: number;
}

@Component({
  selector: 'app-statistics-dashboard',
  templateUrl: './statistics-dashboard.component.html',
  styleUrls: ['./statistics-dashboard.component.css'],
})
export class StatisticsDashboardComponent implements OnInit {
  dailyActiveUsers: ActiveUsersHistData = {};
  totalActiveUsers = 0;
  totalUsersDaysStats = 30;
  activeUsersMonth = '';
  totalUsers = 0;
  teamCount = 0;
  startDateUsersTeams: Date;
  endDateUsersTeams: Date;
  startDateVisits: Date;
  endDateVisits: Date;
  startDateAnalitycs: Date = new Date(new Date().setDate(1));
  endDateAnalitycs: Date = new Date();

  wauMauDatesForm: UntypedFormGroup;
  wauMauUsers;
  totalWauMau = 0;
  sliceLength = 7;
  sliceType = 'WAU';
  startDateWauMau: Date = new Date(
    new Date().setDate(new Date().getDate() - 60)
  );
  endDateWauMau: Date = new Date();
  wauMauLoading = false;

  reviews: AsyncList<Review>;
  matrixLoadingStats: AsyncList<MatrixLoadingTime>;

  matrixLoadingData: any = {};
  completedReviewsData: any = {};
  manyReviewUsersData: any = {};
  analitycsStatsReady = false;

  minMatrixLoadingTime: any = 0;
  averageMatrixLoadingTime: any = 0;
  maxMatrixLoadingTime: any = 0;
  totalCompletedReviews = 0;
  totalManyReviewsUsers = 0;

  constructor(
    private _dateAdapter: DateAdapter<Date>,
    private _formBuilder: FormBuilder,
    private _titleService: TitleService,
    private _statsHttpService: StatsHttpService,
    private _activeUsersHttpService: ActiveUsersHttpService,
    private _dailyActiveUsersHttpService: DailyActiveUsersHttpService,
    private _teamsDateRangeHttpService: TeamDateRangeHttpService,
    private _reviewHttpService: ReviewHttpService,
    private _matrixLoadingStatsHttpService: MatrixLoadingStatsHttpService
  ) {
    this._dateAdapter.setLocale('ru-RU');
  }

  public data: any;
  public userTeamData: any;
  public chartHistBarColor = '#999';

  ngOnInit(): void {
    this._titleService.set_title('Статистика');
    this._statsHttpService.models().subscribe((response) => {
      this.data = StatisticsDashboardComponent._updateTeamUsersData(response);
      this.teamCount = this.data.teams.total;
      this.totalUsers = this.data.users.total;
    });
    this.loadWauMauUsers();
    this._loadDailyActiveUsers();
    this.dateRangeAnalitycsChange();
  }

  private static _updateTeamUsersData(
    data: StatisticInterface
  ): StatisticInterface {
    const newData = {};
    for (const i in data.users.hist) {
      const [year, month] = i.split('-').map(Number);
      const date = new Date(year, month - 1);
      const formatter = new Intl.DateTimeFormat('ru', {
        month: 'short',
        year: '2-digit',
      });
      const formattedDate = formatter.format(date).replace(' г.', '');
      newData[formattedDate] = data.users.hist[i];
    }
    data.users.hist = newData;
    return data;
  }

  onActiveUsersSliceChange(type: string): void {
    this.sliceType = type;
    switch (this.sliceType) {
      case 'DAU':
        this.sliceLength = 1;
        break;
      case 'WAU':
        this.sliceLength = 7;
        break;
      case 'MAU':
        this.sliceLength = 30;
        break;
    }
    this.loadWauMauUsers();
  }

  loadWauMauUsers(): void {
    if (!this.startDateWauMau || !this.endDateWauMau) {
      return;
    }
    this.wauMauLoading = true;
    this._activeUsersHttpService
      .getActiveUsersByPeriod({
        params: {
          date_start: this.startDateWauMau.toISOString().split('T')[0],
          date_end: this.endDateWauMau.toISOString().split('T')[0],
          slice_length: this.sliceLength,
        },
      })
      .subscribe((response) => {
        this.wauMauUsers = response.active_users_count;
        this.totalWauMau = 0;
        Object.values(this.wauMauUsers).forEach((count: number) => {
          this.totalWauMau += count;
        });
        this.wauMauLoading = false;
      });
  }

  private _loadDailyActiveUsers(
    dateStart: Date = new Date(
      new Date().setDate(new Date().getDate() - this.totalUsersDaysStats)
    ),
    dateEnd: Date = new Date()
  ): void {
    this.dailyActiveUsers = {};
    const params = {
      date_created__gte: dateStart.toISOString(),
      date_created__lte: dateEnd.toISOString(),
    };

    this._dailyActiveUsersHttpService.list({ params }).subscribe((response) => {
      if (response.count) {
        this.totalUsersDaysStats = response.count;
        this.totalActiveUsers = 0;
        this.activeUsersMonth = new Date(
          response.results[0].date_created
        ).toLocaleString('default', {
          month: 'long',
        });
        this.activeUsersMonth = `${this.activeUsersMonth
          .slice(0, 1)
          .toUpperCase()}${this.activeUsersMonth.slice(1)}`;

        response.results.forEach((item) => {
          this.totalActiveUsers += item.user_count;
          if (response.count > 90) {
            const key = this.getMMYYKey(item.date_created);
            if (this.dailyActiveUsers[key]) {
              this.dailyActiveUsers[key] += item.user_count;
            } else {
              this.dailyActiveUsers[key] = item.user_count;
            }
          } else {
            this.dailyActiveUsers[this.getDDMMKey(item.date_created)] =
              item.user_count;
          }
        });
      }
    });
  }

  dateUsersRangeChange(): void {
    if (!this.startDateVisits || !this.endDateVisits) {
      return;
    }
    this._loadDailyActiveUsers(this.startDateVisits, this.endDateVisits);
  }

  private _getDateRangedTeamCount(): void {
    const params = {
      date_created__gte: this.startDateUsersTeams.toISOString(),
      date_created__lte: this.endDateUsersTeams.toISOString(),
    };
    this._teamsDateRangeHttpService
      .list({ params })
      .subscribe((response: GetListResponse<TeamsDateRange>) => {
        this.teamCount = response['team_count'];
      });
  }

  filterByDate(
    newData: StatisticInterface,
    startDate: Date,
    endDate: Date
  ): StatisticInterface {
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth() + 1;
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth() + 1;
    let totalValue = 0;
    for (const key in newData.users.hist) {
      if (Object.prototype.hasOwnProperty.call(newData.users.hist, key)) {
        const [year, month] = key.split('-').map((value) => +value);
        if (
          year < startYear ||
          year > endYear ||
          (year == startYear && month < startMonth) ||
          (year == endYear && month > endMonth)
        ) {
          delete newData.users.hist[key];
        } else {
          totalValue += newData.users.hist[key];
        }
      }
    }
    this.totalUsers = totalValue;
    return StatisticsDashboardComponent._updateTeamUsersData(newData);
  }

  dateRangeUsersTeamChange(): void {
    if (!this.startDateUsersTeams || !this.endDateUsersTeams) {
      return;
    }
    this.data = false;
    this._statsHttpService
      .models()
      .pipe(take(1))
      .subscribe((newData) => {
        this.data = this.filterByDate(
          newData,
          this.startDateUsersTeams,
          this.endDateUsersTeams
        );
      });
    this._getDateRangedTeamCount();
  }

  dateRangeAnalitycsChange(): void {
    if (!this.startDateAnalitycs || !this.endDateAnalitycs) {
      return;
    }
    this.analitycsStatsReady = false;
    this.matrixLoadingData = {};
    this.completedReviewsData = {};
    this.manyReviewUsersData = {};
    this.minMatrixLoadingTime = 0;
    this.averageMatrixLoadingTime = 0;
    this.maxMatrixLoadingTime = 0;
    this.totalCompletedReviews = 0;
    this.totalManyReviewsUsers = 0;

    this.startDateAnalitycs.setMinutes(-new Date().getTimezoneOffset());
    this.endDateAnalitycs.setMinutes(-new Date().getTimezoneOffset());

    this.reviews = new AsyncList<Review>(this._reviewHttpService);
    this.reviews.setRequestParams({
      params: {
        ordering: 'date_updated',
        date_updated__gte: this.startDateAnalitycs.toISOString(),
        date_updated__lte: this.endDateAnalitycs.toISOString(),
        completion_percentage: 100,
      },
    });

    this.matrixLoadingStats = new AsyncList<MatrixLoadingTime>(
      this._matrixLoadingStatsHttpService
    );
    this.matrixLoadingStats.setRequestParams({
      params: {
        date_created__gte: this.startDateAnalitycs.toISOString(),
        date_created__lte: this.endDateAnalitycs.toISOString(),
      },
    });
    combineLatest([
      this.reviews.load(),
      this.matrixLoadingStats.load(),
    ]).subscribe(() => {
      const reviewSubjectsDates = [];
      const countedSubjects = [];
      const totalCountedSubjects = [];
      this.reviews.state.items.forEach((review) => {
        const reviewKey = this.getMMYYKey(review.date_updated);
        this.totalCompletedReviews += 1;

        if (Object.keys(this.completedReviewsData).includes(reviewKey)) {
          this.completedReviewsData[reviewKey] += 1;
        } else {
          this.completedReviewsData[reviewKey] = 1;
        }

        const reviewSubjectsMatch = reviewSubjectsDates.find((r) => {
          if (r.subject === review.subjects[0]) {
            if (!totalCountedSubjects.includes(r.subject)) {
              totalCountedSubjects.push(r.subject);
              this.totalManyReviewsUsers += 1;
            }
            return r.date === reviewKey;
          } else {
            return false;
          }
        });

        if (reviewSubjectsMatch) {
          if (
            !countedSubjects.find(
              (s) =>
                s.date === reviewSubjectsMatch.date &&
                s.subject === reviewSubjectsMatch.subject
            )
          ) {
            countedSubjects.push({
              subject: reviewSubjectsMatch.subject,
              date: reviewSubjectsMatch.date,
            });
            if (Object.keys(this.manyReviewUsersData).includes(reviewKey)) {
              this.manyReviewUsersData[reviewKey] += 1;
            } else {
              this.manyReviewUsersData[reviewKey] = 1;
            }
          }
        } else {
          reviewSubjectsDates.push({
            subject: review.subjects[0],
            date: reviewKey,
          });
        }
      });

      this.minMatrixLoadingTime =
        this.matrixLoadingStats.state.items[0]?.loading_time;

      this.maxMatrixLoadingTime =
        this.matrixLoadingStats.state.items[0]?.loading_time;

      this.matrixLoadingStats.state.items.forEach((item) => {
        const matrixKey = this.getMMYYKey(item.date_created);
        this.averageMatrixLoadingTime += item.loading_time;

        if (Object.keys(this.matrixLoadingData).includes(matrixKey)) {
          if (item.loading_time > this.matrixLoadingData[matrixKey])
            this.matrixLoadingData[matrixKey] = item.loading_time;
        } else {
          this.matrixLoadingData[matrixKey] = item.loading_time;
        }

        if (item.loading_time > this.maxMatrixLoadingTime) {
          this.maxMatrixLoadingTime = item.loading_time;
        }

        if (item.loading_time < this.minMatrixLoadingTime) {
          this.minMatrixLoadingTime = item.loading_time;
        }
      });

      this.minMatrixLoadingTime = this.minMatrixLoadingTime
        ? this.minMatrixLoadingTime.toFixed(3)
        : 0;
      this.maxMatrixLoadingTime = this.maxMatrixLoadingTime
        ? this.maxMatrixLoadingTime.toFixed(3)
        : 0;

      this.averageMatrixLoadingTime = this.averageMatrixLoadingTime
        ? (
            this.averageMatrixLoadingTime /
            this.matrixLoadingStats.state.items.length
          ).toFixed(3)
        : 0;

      this.analitycsStatsReady = true;
    });
  }

  getMMYYKey(dateStr: string): string {
    const date = new Date(dateStr);
    const formatter = new Intl.DateTimeFormat('ru', {
      month: 'short',
      year: '2-digit',
    });
    const formattedDate = formatter.format(date);
    return formattedDate.replace(' г.', '');
  }

  getDDMMKey(dateStr: string): string {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${day}.${month}`;
  }

  getXAxisLabelsHist(data: any): string[] {
    return Object.keys(data);
  }

  getHistChartData(data: any): any[] {
    const result = [];
    for (const month of Object.keys(data)) {
      result.push({ x: month, y: data[month] });
    }
    return result;
  }
}
