import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TeamMemberStatusHttpService } from '@services/http/TeamMemberStatusHttpService';
import { AsyncList } from '@rest/AsyncList';
import { TeamMemberStatus } from '@models/teams/team-member-status';
import { User } from '@models/user/user';

@Component({
  selector: 'app-team-members-status-confirm-dialog',
  templateUrl: './team-members-status-confirm-dialog.component.html',
  styleUrls: ['./team-members-status-confirm-dialog.component.css'],
})
export class TeamMembersStatusConfirmDialogComponent implements OnInit {
  busFactorConfirmList;
  keyPeopleConfirmList;

  isDataReady = false;

  constructor(
    public dialogRef: MatDialogRef<TeamMembersStatusConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      teamMembersStatus: AsyncList<TeamMemberStatus>;
      users: User[];
    },
    private _teamMemberStatusHttpService: TeamMemberStatusHttpService
  ) {}

  ngOnInit(): void {
    this.busFactorConfirmList = this.data.teamMembersStatus.state.items.filter(
      (status) => status.bus_factor
    );
    this.keyPeopleConfirmList = this.data.teamMembersStatus.state.items.filter(
      (status) => status.key_people
    );
    this.isDataReady = true;
  }

  getMemberName(memberStatus: TeamMemberStatus): string {
    const member = this.data.users.find(
      (user) => memberStatus.member === user.uuid
    );
    return `${member.last_name} ${member.first_name}`;
  }

  onKeyPeopleAccept(memberStatus: TeamMemberStatus): void {
    this._teamMemberStatusHttpService
      .update(memberStatus.uuid, {
        key_people: true, //for reason field correct update, check backend
        is_verified_by_owner: true,
        is_updated_manually: true,
      })
      .subscribe(() => {
        this.keyPeopleConfirmList = this.keyPeopleConfirmList.filter(
          (item) => item.uuid !== memberStatus.uuid
        );
        this.emptyDialogDataCheck();
      });
  }

  onKeyPeopleReject(memberStatus: TeamMemberStatus): void {
    this._teamMemberStatusHttpService
      .update(memberStatus.uuid, {
        key_people: false,
        is_verified_by_owner: true,
        is_updated_manually: true,
      })
      .subscribe(() => {
        this.keyPeopleConfirmList = this.keyPeopleConfirmList.filter(
          (item) => item.uuid !== memberStatus.uuid
        );
        this.emptyDialogDataCheck();
      });
  }

  onBusFactorAccept(memberStatus: TeamMemberStatus): void {
    this._teamMemberStatusHttpService
      .update(memberStatus.uuid, {
        bus_factor: true, //for reason field correct update, check backend
        is_verified_by_owner: true,
        is_updated_manually: true,
      })
      .subscribe(() => {
        this.busFactorConfirmList = this.busFactorConfirmList.filter(
          (item) => item.uuid !== memberStatus.uuid
        );
        this.emptyDialogDataCheck();
      });
  }

  onBusFactorReject(memberStatus: TeamMemberStatus): void {
    this._teamMemberStatusHttpService
      .update(memberStatus.uuid, {
        bus_factor: false,
        is_verified_by_owner: true,
        is_updated_manually: true,
      })
      .subscribe(() => {
        this.busFactorConfirmList = this.busFactorConfirmList.filter(
          (item) => item.uuid !== memberStatus.uuid
        );
        this.emptyDialogDataCheck();
      });
  }

  emptyDialogDataCheck(): void {
    if (
      !this.busFactorConfirmList.length &&
      !this.keyPeopleConfirmList.length
    ) {
      this.dialogRef.close();
    }
  }
}
