import { Component, Input, OnInit } from '@angular/core';
import { AsyncList } from '@rest/AsyncList';
import { Thing } from '@models/ontology/thing';
import { ThingHttpService } from '@services/http/ThingHttpService';
import { LibraryItemProps } from '@components/common/library-item/library-item.component';

@Component({
  selector: 'app-library-things',
  templateUrl: './things.component.html',
  styleUrls: ['./things.component.css'],
})
export class ThingsComponent implements OnInit {
  @Input() title = '';
  @Input() recordsNumber = '5';
  @Input() mode: string;
  public things: AsyncList<Thing>;
  constructor(private _thingHttpService: ThingHttpService) {}

  ngOnInit(): void {
    this.things = new AsyncList(this._thingHttpService);
    this.things.setRequestParams({
      params: {
        [this.mode]: this.recordsNumber,
      },
    });
    this.things.load();
  }

  serializeThing(thing: Thing): LibraryItemProps {
    return {
      content: thing.name,
      footer: thing.description,
      linkUrl: `/things/${thing.uuid}`,
    };
  }
}
