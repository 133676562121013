import { Observable } from 'rxjs';
import { RestHttpService } from '@services/http/rest/RestHttpService';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface GetListResponse<ViewSetModel> {
  results: ViewSetModel[];
  count: number;
}

interface GetListRequestObject {
  [key: string]: string | number | boolean;
}

export interface GetListRequest {
  [key: string]: string | number | boolean | GetListRequestObject | any;
}

export interface GetDetailRequest {
  [key: string]: string | number | boolean | any;
}

@Injectable({
  providedIn: 'root',
})
export class ViewSetHttpService<ViewSetModel> extends RestHttpService {
  constructor(private _http: HttpClient) {
    super();
  }
  public viewSetUrl = '';
  public getUrl(): string {
    return this.baseUrl + this.viewSetUrl;
  }

  public list(
    params: GetListRequest = {}
  ): Observable<GetListResponse<ViewSetModel>> {
    return this._http.get<GetListResponse<ViewSetModel>>(`${this.getUrl()}`, {
      // ```responseType: json``` allow set Response Type
      responseType: 'json',
      ...params,
    });
  }

  public detail(uuid: string, params: GetDetailRequest = {}): Observable<any> {
    return this._http.get(`${this.getUrl()}/${uuid}`, {
      responseType: 'json',
      ...params,
    });
  }

  public delete(uuid: string): Observable<any> {
    return this._http.delete(`${this.getUrl()}/${uuid}`);
  }

  public create(data: Partial<ViewSetModel>, params = {}): Observable<any> {
    return this._http.post<ViewSetModel>(`${this.getUrl()}`, data, {
      responseType: 'json',
      ...params,
    });
  }

  public update(
    uuid: string,
    data: Partial<ViewSetModel>,
    params = {}
  ): Observable<any> {
    return this._http.patch<ViewSetModel>(`${this.getUrl()}/${uuid}`, data, {
      // ```responseType: json``` allow set Response Type
      responseType: 'json',
      ...params,
    });
  }
}
