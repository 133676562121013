<div
  class='pdp-create-container'
  fxLayout='column'
  *ngIf='users?.state.isLoaded && pdp?.state.isLoaded'
>
  <div
    fxLayout='row'
    fxLayoutAlign='start center'
    fxLayoutGap='2rem'
    class='pdp-create-title'
  >
    <button (click)='onBackClick()' mat-icon-button>
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span>Добавить цель</span>
  </div>
  <div>
    <!-- <span
      >Дизайнер интерфейсов будет решать задачи коммуникации между системой
      ввода данных и пользователем. Описание в несколько строчек.</span
    > -->
  </div>
  <!-- <mat-divider class="divider"></mat-divider> -->
  <form [formGroup]='form' class='pdp-create-form'>
    <div fxLayout='column'>
      <mat-label class='textfield-label'>Название цели</mat-label>
      <mat-form-field floatLabel='never' appearance='outline'>
        <input matInput type='text' formControlName='objective'
               placeholder='Например, провести презентацию проекта заказчику в Q1' />
      </mat-form-field>
    </div>
    <div fxLayout='column'>
      <mat-label class='textfield-label'
      >Почему достижение этой цели важно для тебя?
      </mat-label
      >
      <mat-form-field floatLabel='never' appearance='outline'>
        <input matInput type='text' formControlName='personal_value'
               placeholder='Например, хочу научиться убедительно и лаконично рассказывать о проекте' />
      </mat-form-field>
    </div>
    <div fxLayout='column'>
      <mat-label class='textfield-label'
      >Как я пойму что цель достигнута?
      </mat-label
      >
      <mat-form-field floatLabel='never' appearance='outline'>
        <input
          matInput
          type='text'
          formControlName='accomplishment_indicator'
          placeholder='Например, я получу положительную ОС по своему выступлению от коллег и заказчика'
        />
      </mat-form-field>
    </div>
    <div fxLayout='row' fxLayoutGap='16px'>
      <div fxLayout='column' fxFlex>
        <mat-label class='textfield-label'>Выбери помощника</mat-label>
        <app-search-select-user
          (userSelected)='onUserSelection($event)'
        ></app-search-select-user>
      </div>
      <div fxLayout='column' fxFlex>
        <mat-label class='textfield-label'
        >Желаемая дата достижения цели
        </mat-label
        >
        <mat-form-field appearance='outline' floatLabel='never'>
          <input
            matInput
            [matDatepicker]='dueDate'
            formControlName='due_date'
          />
          <mat-datepicker-toggle
            matSuffix
            [for]='dueDate'
          ></mat-datepicker-toggle>
          <mat-datepicker #dueDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div fxLayout='row' fxLayoutAlign='end center' fxLayoutGap='8px'>
    <button class='reject-button' mat-raised-button (click)='onBackClick()'>
      Отменить
    </button>
    <button
      class='accept-button'
      [disabled]='form.invalid'
      mat-raised-button
      (click)='onPdpCreate()'
    >
      Сохранить цель
    </button>
  </div>
</div>
