import { Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-with-processing',
  templateUrl: './with-processing.component.html',
  styleUrls: ['./with-processing.component.css'],
})
export class WithProcessingComponent {
  @Input() processing = true;

  color: ThemePalette = 'warn';
  mode: ProgressSpinnerMode = 'indeterminate';
}
