import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { TeamJoinRequest } from '@models/teams/team-join-request';

@Injectable({
  providedIn: 'root',
})
export class TeamJoinRequestHttpService extends ViewSetHttpService<TeamJoinRequest> {
  constructor(_http: HttpClient) {
    super(_http);
  }

  public viewSetUrl = 'teams/join_requests';
}
