import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThingHttpService } from '@services/http/ThingHttpService';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Thing } from '@models/ontology/thing';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TitleService } from '../../../../services/title.service';
import { Domain } from '@models/ontology/domain';
import { AsyncList } from '@rest/AsyncList';
import { AcceptDialogComponent } from '../../../common-ui-elements/components/accept-dialog/accept-dialog.component';
import { MatDialog } from '@angular/material/dialog';

interface ThingRelations {
  reviews: number;
  development_plans: number;
  desires: number;
  specialities: number;
  teams: number;
  users: number;
}

@Component({
  selector: 'app-thing-duplicates',
  templateUrl: './thing-duplicates.component.html',
  styleUrls: ['./thing-duplicates.component.css'],
})
export class ThingDuplicatesComponent {
  thingUuid: string;
  firstThing: AsyncDetail<Thing>;

  secondThing: Thing;

  searchThings: AsyncList<Thing>;
  resultThings: Thing[];

  firstThingRelations: ThingRelations;
  secondThingRelations: ThingRelations;

  form: UntypedFormGroup;

  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    private _titleService: TitleService,
    private _thingHttpService: ThingHttpService
  ) {
    this.thingUuid = this._activatedRoute.snapshot.paramMap.get('uuid');
    this.firstThing = new AsyncDetail<Thing>(
      this.thingUuid,
      this._thingHttpService
    );
    this.firstThing.requestParams = {
      params: {
        expand: 'domain,levels',
      },
    };
    this.searchThings = new AsyncList<Thing>(this._thingHttpService);
  }

  ngOnInit(): void {
    this._buildFirstThingData();
  }

  private _buildFirstThingData(): void {
    this.firstThing.load().subscribe(() => {
      this._titleService.set_title(
        `Объединение дублей ${this.firstThing.state.item.name}`
      );
    });
    this._thingHttpService
      .getThingRelationsCount(this.thingUuid)
      .subscribe((response) => (this.firstThingRelations = response));
    this.form = this._formBuilder.group({
      name: ['', []],
      description: ['', []],
      domain: ['', []],
      change_levels: ['', []],
    });
  }

  onSearchThings(name: string): void {
    this.searchThings.setRequestParams({
      params: { search: name, expand: 'domain,levels' },
    });
    this.searchThings.load().subscribe(() => {
      this.resultThings = this.searchThings.state.items.filter(
        (thing) => thing.uuid !== this.firstThing.uuid
      );
    });
  }

  onSelectSecondThing(thing: Thing): void {
    this.secondThing = thing;
    this._thingHttpService
      .getThingRelationsCount(this.secondThing.uuid)
      .subscribe((response) => (this.secondThingRelations = response));
  }

  getDomainName(thing: Thing): string {
    return (thing.domain as Domain).name;
  }

  getDomainUuid(thing: Thing): string {
    return (thing.domain as Domain).uuid;
  }

  onBackClick(): void {
    history.back();
  }

  squashThings(): void {
    this._thingHttpService
      .squashThings(
        this.firstThing.state.item.uuid,
        this.secondThing.uuid,
        this.form.value
      )
      .subscribe(() => {
        this.secondThing = null;
        this._buildFirstThingData();
        this._dialog.open(AcceptDialogComponent, {
          data: {
            message: 'Навыки были успешно объединены',
            acceptLabel: 'Понятно',
          },
        });
      });
  }
}
