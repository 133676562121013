import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewSetHttpService } from './rest/ViewSetHttpService';
import { TeamsDateRange } from '@models/stats/teams-date-range';

@Injectable({
  providedIn: 'root',
})
export class TeamDateRangeHttpService extends ViewSetHttpService<TeamsDateRange> {
  http: HttpClient;
  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }
  public viewSetUrl = 'stats/teams';
}
