import { Component, Input, OnInit } from '@angular/core';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { UserEventsService } from 'src/app/services/events/user-event.service';

import { User } from 'src/app/models/user/user';
import { UserEvent } from 'src/app/models/events/user-event';

@Component({
  selector: 'app-admin-user-tab-events',
  templateUrl: './admin-user-tab-events.component.html',
  styleUrls: ['./admin-user-tab-events.component.css'],
})
export class AdminUserTabEventsComponent implements OnInit {
  @Input() user: User;

  public events: UserEvent[] = [];

  private uistate = {
    events_loaded: false,
  };

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private userevent_service: UserEventsService
  ) {}

  ngOnInit(): void {
    this.load_events();
  }

  public get is_data_loaded(): boolean {
    return this.uistate.events_loaded;
  }

  private load_events(): void {
    this.userevent_service.fetch_by_user_uuid(this.user.uuid).subscribe(
      (response) => {
        this.events = response.results as UserEvent[];
        this.uistate.events_loaded = true;
        this.logging_service.debug(
          `${this.constructor.name} loaded ${response.count} user events`
        );
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to load user events`
        );
        this.alert_service.error(
          `Ошибка загрузки событий пользователя: ${err.status}`
        );
      }
    );
  }
}
