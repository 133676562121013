import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityGradeHttpService } from '@services/http/SpecialityGradeHttpService';
import { SpecialityGrade } from '@models/specialities/speciality-grade';
import { SpecialityUserRelationHttpService } from '@services/http/SpecialityUserRelationHttpService';
import { AsyncList } from '@rest/AsyncList';
import { SpecialityUserRelation } from '@models/specialities/speciality-user-relation';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TeamMembershipHttpService } from '@services/http/TeamMembershipHttpService';

interface Role {
  label: string;
  value: string;
}

@Component({
  selector: 'app-edit-team-member-dialog',
  templateUrl: './edit-team-member-dialog.component.html',
  styleUrls: ['./edit-team-member-dialog.component.css'],
})
export class EditTeamMemberDialogComponent implements OnInit {
  form: UntypedFormGroup;

  specialityGrades: AsyncList<SpecialityGrade>;
  specialityGradesMap: Map<string, SpecialityGrade[]>;
  specialityFormMap: Map<string, UntypedFormGroup>;
  userSpecialityGradesMap: Map<string, SpecialityGrade>;
  userSpecialityCurrentGrade: any;
  allSpecialityGradesMap: Map<string, SpecialityGrade[]>;

  isDataLoading = true;

  roleOptions: Role[] = [
    { label: 'Руководитель', value: 'owner' },
    { label: 'Лид', value: 'lead' },
    { label: 'Участник', value: 'member' },
  ];

  constructor(
    public dialogRef: MatDialogRef<EditTeamMemberDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      userUuid: string;
      teamUuid: string;
      userMembershipUuid: string;
      userRole: string;
      teamSpecialities: Speciality[];
      teamSpecialityGradesMap: Map<string, SpecialityGrade[]>;
      userSpecialitiesRelations: SpecialityUserRelation<
        string,
        Speciality,
        SpecialityGrade
      >[];
    },
    private _formBuilder: UntypedFormBuilder,
    private _specialityUserRelationHttpService: SpecialityUserRelationHttpService,
    private _specialityGradeHttpService: SpecialityGradeHttpService,
    private _teamMembershipHttpService: TeamMembershipHttpService
  ) {
    this.specialityGradesMap = new Map();
    this.userSpecialityGradesMap = new Map();
    this.allSpecialityGradesMap = new Map();
    this.userSpecialityCurrentGrade = {};
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      role: ['', []],
    });
    this.form.controls.role.setValue(
      this.roleOptions.find((role) => role.value === this.data.userRole)
    );

    this.data.teamSpecialities.forEach((speciality) => {
      console.log(`speciality ${speciality.uuid} ${speciality.name}`);
      speciality.related_grades?.forEach((grade) => {
        if (this.allSpecialityGradesMap.has(speciality.uuid)) {
          this.allSpecialityGradesMap.get(speciality.uuid).push(grade);
        } else {
          this.allSpecialityGradesMap.set(speciality.uuid, [grade]);
        }
      });
    });
    this.data.userSpecialitiesRelations.forEach((userSpecialityRelation) => {
      this.userSpecialityCurrentGrade[userSpecialityRelation.speciality.uuid] =
        userSpecialityRelation.grade;
    });
    this.isDataLoading = false;
    // console.log(this.allSpecialityGradesMap);
  }

  selectSpeciality(speciality: Speciality): void {
    if (
      !this.data.userSpecialitiesRelations
        .map((item) => item.speciality.uuid)
        .includes(speciality.uuid)
    ) {
      const grade = this.allSpecialityGradesMap.get(speciality.uuid)[0];
      this.userSpecialityGradesMap.set(speciality.uuid, grade);
      this.userSpecialityCurrentGrade[speciality.uuid] = grade;
      this._specialityUserRelationHttpService
        .create({
          user: this.data.userUuid,
          speciality: speciality.uuid,
          grade: grade.uuid,
        })
        .subscribe((response) => {
          const relation = response;
          relation.speciality = speciality;
          relation.grade = grade;
          this.data.userSpecialitiesRelations.push(relation);
        });
    }
  }

  deselectSpeciality(relation: SpecialityUserRelation): void {
    this._specialityUserRelationHttpService
      .delete(relation.uuid)
      .subscribe(() => {
        this.data.userSpecialitiesRelations.splice(
          this.data.userSpecialitiesRelations.indexOf(
            this.data.userSpecialitiesRelations.find(
              (r) => r.uuid === relation.uuid
            )
          ),
          1
        );
        delete this.userSpecialityCurrentGrade[
          (relation.speciality as Speciality).uuid
        ];
      });
  }

  getSpecialityGrades(specialityUuid: string): SpecialityGrade[] {
    return this.allSpecialityGradesMap.get(specialityUuid);
  }

  getSelectedSpecialityGrade(specialityUuid: string): SpecialityGrade {
    return this.userSpecialityGradesMap.get(specialityUuid);
  }

  onSpecialityGradeChange(
    relation: SpecialityUserRelation,
    grade: SpecialityGrade
  ): void {
    console.log(relation);
    console.log(grade);
    this.userSpecialityGradesMap.set(
      (relation.speciality as Speciality).uuid,
      grade
    );
    this.userSpecialityCurrentGrade[(relation.speciality as Speciality).uuid] =
      grade;
    this._specialityUserRelationHttpService
      .update(relation.uuid, {
        grade: grade.uuid,
      })
      .subscribe((response) => {
        relation.grade = grade;
      });
  }

  removeFromTeam(): void {
    this._teamMembershipHttpService
      .delete(this.data.userMembershipUuid)
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  close(): void {
    this._teamMembershipHttpService
      .update(this.data.userMembershipUuid, {
        role: this.form.controls.role.value.value,
      })
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }
}
