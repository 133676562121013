<ng-container *ngIf="is_data_loaded; then tmpl_tab_content; else tmpl_preloader">
</ng-container>

<ng-template #tmpl_preloader>
    <app-preloader-progressbar></app-preloader-progressbar>
</ng-template>

<ng-template #tmpl_tab_content>
    <div fxLayout="row">
        <div fxFlex>

            <div *ngIf="pdps.length === 0">
                <div class="app-track-user-pdps-list-pdps-empty">
                    У пользователя нет планов разития
                </div>
            </div>

            <div *ngIf="pdps.length > 0">

                <div *ngFor="let pdp of pdps">
                    <div class="app-track-user-pdps-list-pdps-listitem-container">

                        <div fxLayout="row">
                            <div fxFlex fxLayoutAlign="start center">
                                <div class="app-track-user-pdps-list-pdps-listitem-name">
                                    <a routerLink="/track/pdp/{{ pdp.uuid }}">
                                        {{ pdp.objective }}
                                    </a>
                                </div>
                            </div>
                            <div fxFlex="3rem">
                                <button mat-icon-button (click)="on_button_delete_pdp(pdp)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</ng-template>