<h2 mat-dialog-title>
    План выполнен!
</h2>
<mat-dialog-content>

    <div fxLayout="row">
        <div fxFlex>
            <div class="app-track-pdp-dialog-complete-question">
                Проверьте себя. Верно ли утрверждение?
            </div>
            <div class="app-track-pdp-dialog-complete-accomplishment-indicator">
                {{ data.pdp.accomplishment_indicator }}
            </div>
        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button
                [disabled]="is_button_complete_loading"
                (click)="on_complete()">
            Все верно, план выполнен
    </button>
    <button mat-button mat-dialog-close>Нет, вернусь к плану</button>
</mat-dialog-actions>