<mat-dialog-content>
  <div>
    <div fxLayout="row">
      <div>
        <div class="header-title">Вступление в команду</div>
        <div class="header-hint">Выберите специальность и ее уровень</div>
        <br />

        <form [formGroup]="form">
          <div>
            <mat-form-field [style.width.rem]="30">
              <mat-label>Специальность</mat-label>
              <mat-select
                formControlName="speciality"
                (selectionChange)="onSelectDefaultGrade($event.value)"
              >
                <mat-option
                  *ngFor="let speciality of data.specialities"
                  [value]="speciality"
                >
                  {{ speciality.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field [style.width.rem]="30">
              <mat-label>Уровень</mat-label>
              <mat-select formControlName="specialityGrade">
                <mat-option
                  *ngFor="let specialityGrade of selectedSpecialityGrades"
                  [value]="specialityGrade"
                >
                  {{ specialityGrade.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </div>
</mat-dialog-content>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button class="dialog-button" [mat-dialog-close]="true" mat-raised-button>
    Отмена
  </button>
  <button
    class="dialog-button"
    mat-raised-button
    [disabled]="form.invalid"
    (click)="close()"
  >
    Завершить
  </button>
</div>
