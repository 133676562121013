<div class="dialog-container">
  <div>Выбрать ответственного</div>
  <div class="user-list-container">
    <app-user-list
      *ngIf="data.owners.length"
      [users]="data.owners"
      [selectMode]="'single'"
      (selectUsers)="onSelectResponsible($event[0])"
    ></app-user-list>
  </div>
  <div
    *ngIf="!data.owners.length"
    fxLayoutAlign="center center"
    [style.padding]="'20px 0'"
  >
    Невозможно изменить ответственного
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button
      class="dialog-reject-button"
      [mat-dialog-close]="true"
      mat-raised-button
    >
      Отменить
    </button>
    <button
      class="dialog-accept-button"
      [mat-dialog-close]="true"
      [disabled]="!this.responsible"
      mat-raised-button
      (click)="submit()"
    >
      Сохранить
    </button>
  </div>
</div>
