<app-with-processing [processing]='directions.state.isProcessing'></app-with-processing>
<div class='dashboard-container' *ngIf='directions.state.isLoaded'>
  <div *ngIf='!isAllCompetenciesFitnessVisible && !isAllSpecialityDesiresVisible && !isAllExpectedThingsVisible'>
    <span class='title'>Дашборд</span>
    <mat-divider class='divider'></mat-divider>
    <form [formGroup]='mainForm'>
      <div fxLayout='row' fxLayoutGap='10px' class='param-select'>
        <mat-select
          formControlName='direction'
          (selectionChange)='onChangeSelectedDirection($event.value)'
          [style.width.px]='280'
          placeholder='Выберите направление'
        >
          <mat-option *ngIf='!directions.state.items.length' [disabled]='true'>
            Направления не найдены
          </mat-option>
          <mat-option
            *ngFor='let direction of directions.state.items'
            [value]='direction'
          >
            {{ direction.name }}
          </mat-option>
        </mat-select>
        <mat-select
          formControlName='team'
          (selectionChange)='onChangeSelectedTeam($event.value)'
          [style.width.px]='280'
          placeholder='Выберите команду'
        >
          <mat-option *ngIf='!teams.length' [disabled]='true'>
            Команды не найдены
          </mat-option>
          <mat-option [value]=''>Не выбрано</mat-option>
          <mat-option *ngFor='let team of teams' [value]='team'>
            {{ team.name }}
          </mat-option>
        </mat-select>
        <mat-select
          formControlName='speciality'
          (selectionChange)='onChangeSelectedSpeciality($event.value)'
          [style.width.px]='280'
          placeholder='Выберите специальность'
        >
          <mat-option *ngIf='!specialities.length' [disabled]='true'>
            Специальности не найдены
          </mat-option>
          <mat-option [value]=''>Не выбрано</mat-option>
          <mat-option
            *ngFor='let speciality of specialities'
            [value]='speciality'
          >
            {{ speciality.name }}
          </mat-option>
        </mat-select>
      </div>
    </form>
    <div [style.margin-top.px]='20' [style.margin-bottom.px]='10'>
      <app-progress-loader [dataReadySubject]='isDataLoadedSubject'></app-progress-loader>
    </div>
    <div fxLayout='row' fxLayoutGap='16px'>
      <div fxLayout='row' fxFlex='272px' *ngIf='isDataLoadedMap.competenciesFitnessDataReady.isLoaded'>
        <div fxLayout='row' class='data-container' fxFlex>
          <div fxLayout='column' fxLayoutAlign='space-between start' fxFlex>
            <span class='data-name'>Cоответствие участников команды профилям специальности</span>
            <span class='data-count'>{{ teamSpecialityFitUsersCount }}</span>
          </div>
          <div fxFlex='125px'>
            <app-chart-pie [data]='pieData'></app-chart-pie>
          </div>
        </div>
      </div>
      <div fxFlex>
        <div
          *ngIf='isDataLoadedMap.gradeDataReady.isLoaded'
          fxLayout='row'
          fxLayoutGap='16px'
          class='grade-list-container'
        >
          <div *ngFor='let grade of gradeData' fxLayout='row' fxFlex>
            <div fxLayout='column' class='data-container' fxFlex>
              <span class='data-count'>{{ grade.specialityGradeCount }}</span>
              <span class='data-name'>{{ grade.gradeName }}</span>
            </div>
          </div>
        </div>
        <div
          *ngIf='isDataLoadedMap.teamStatusDataReady.isLoaded'
          fxLayout='row'
          fxLayoutGap='16px'
          class='team-status-container'
        >
          <div fxLayout='row' fxFlex>
            <div fxLayout='column' class='data-container' fxFlex>
              <span class='data-count'>{{ teamSpecialityKeyPeopleCount }}</span>
              <span class='data-name'>Key People</span>
            </div>
          </div>
          <div fxLayout='row' fxFlex>
            <div fxLayout='column' class='data-container' fxFlex>
              <span class='data-count'>{{ teamSpecialityBusFactorCount }}</span>
              <span class='data-name'>Bus Factor</span>
            </div>
          </div>
          <div fxLayout='row' fxFlex>
            <div fxLayout='column' class='data-container' fxFlex>
        <span class='data-count'>{{
          teamSpecialityPdp?.state.items?.length
          }}</span>
              <span class='data-name'>ИПР</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout='row' fxLayoutGap='25px' [style.margin-top.px]='20'>
      <div
        class='metric-block'
        *ngIf='isDataLoadedMap.competenceClaimsLoaded.isLoaded'
        fxFlex
      >
        <ng-container
          [ngTemplateOutlet]='expectedThings'
          [ngTemplateOutletContext]='{ things: expectedThingsCount.slice(0, 10) }'
        >
        </ng-container>
        <div *ngIf='expectedThingsCount.length > 10'>
          <span (click)='showAllExpectedThings()' class='show-more-text'><strong>ещё</strong></span>
        </div>
      </div>

      <div class='metric-block' *ngIf='isDataLoadedMap.specialityDesiresDataReady.isLoaded' fxFlex>
        <ng-container
          [ngTemplateOutlet]='specialityDesires'
          [ngTemplateOutletContext]='{ things: specialityDesiresCount.slice(0, 10) }'
        >
        </ng-container>
        <div *ngIf='specialityDesiresCount.length > 10'>
          <span (click)='showAllSpecialityDesires()' class='show-more-text'><strong>ещё</strong></span>
        </div>
      </div>


      <div class='metric-block' *ngIf='isDataLoadedMap.competenciesFitnessDataReady.isLoaded' fxFlex>
        <ng-container
          [ngTemplateOutlet]='competenciesFitness'
          [ngTemplateOutletContext]='{ things: unfitCompetenciesCount.slice(0, 10) }'
        >
        </ng-container>
        <div *ngIf='unfitCompetenciesCount.length > 10'>
          <span (click)='showAllCompetenciesFitness()' class='show-more-text'><strong>ещё</strong></span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf='isAllExpectedThingsVisible' fxLayout='column'>
    <div fxLayout='row' fxLayoutAlign='start center'>
      <button (click)='hideAllExpectedThings()' mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span class='title'>Прокачанные навыки</span>
    </div>
    <mat-divider class='divider'></mat-divider>
    <div
      class='metric-block'
      [style.width.%]='30'
    >
      <ng-container
        [ngTemplateOutlet]='expectedThings'
        [ngTemplateOutletContext]='{ things: expectedThingsCount }'
      >
      </ng-container>
    </div>
  </div>
  <div *ngIf='isAllSpecialityDesiresVisible' fxLayout='column'>
    <div fxLayout='row' fxLayoutAlign='start center'>
      <button (click)='hideAllSpecialityDesires()' mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span class='title'>Запросы на обучение</span>
    </div>
    <mat-divider class='divider'></mat-divider>
    <div
      class='metric-block'
      [style.width.%]='30'
    >
      <ng-container
        [ngTemplateOutlet]='specialityDesires'
        [ngTemplateOutletContext]='{ things: specialityDesiresCount }'
      >
      </ng-container>
    </div>
  </div>
  <div *ngIf='isAllCompetenciesFitnessVisible' fxLayout='column'>
    <div fxLayout='row' fxLayoutAlign='start center'>
      <button (click)='hideAllCompetenciesFitness()' mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span class='title'>Не соответствующие навыки</span>
    </div>
    <mat-divider class='divider'></mat-divider>
    <div
      class='metric-block'
      [style.width.%]='30'
    >
      <ng-container
        [ngTemplateOutlet]='competenciesFitness'
        [ngTemplateOutletContext]='{ things: unfitCompetenciesCount }'
      >
      </ng-container>
    </div>
  </div>
</div>

<ng-template #expectedThings let-things='things'>
  <div fxLayout='row' fxLayoutGap='20px' class='header'>
    <span fxFlex>Компетенции</span>
    <form fxFlex [formGroup]='gradeForm' class='param-select'>
      <mat-select
        formControlName='grade'
        (selectionChange)='onChangeSelectedSpecialityGrade($event.value)'
        [style.width.px]='128'
        [style.background]="'#f9f9f9'"
        placeholder='Выберите уровень'
      >
        <mat-option
          *ngFor='let specialityGrade of selectedSpecialityGrades'
          [value]='specialityGrade'
        >
          {{ specialityGrade.name }}
        </mat-option>
      </mat-select>
    </form>
  </div>
  <mat-divider [style.margin]="'16px 0'"></mat-divider>
  <div
    fxLayout='row'
    fxLayoutAlign='start center'
    fxLayoutGap='15px'
    *ngFor='let thing of things'
  >
    <div fxFlex class='expected-thing-text'>{{ thing.name }}</div>
    <div fxFlex>
      <app-custom-progress-bar
        [value]='thing.count'
        [maxValue]='specialityRelations.state.items.length'
        [completeColor]="'#9B82FF'"
        [incompleteColor]="'rgba(155,130,255,0.5)'"
        [barHeight]='15'
      ></app-custom-progress-bar>
    </div>
    <div fxFlex='10px' class='expected-thing-count'>
      {{ thing.count }}
    </div>
  </div>
</ng-template>

<ng-template #specialityDesires let-things='things'>
  <div fxLayout='row' fxLayoutGap='20px' class='header'>
    <span>Запросы на обучение</span>
  </div>
  <mat-divider [style.margin]="'16px 0'"></mat-divider>
  <div
    fxLayout='row'
    fxLayoutAlign='start center'
    fxLayoutGap='15px'
    *ngFor='let thing of things'
  >
    <div fxFlex class='expected-thing-text'>{{ thing.name }}</div>
    <div fxFlex='10px' class='expected-thing-count'>
      {{ thing.count }}
    </div>
  </div>
</ng-template>

<ng-template #competenciesFitness let-things='things'>
  <div fxLayout='row' fxLayoutGap='20px' class='header'>
    <span>Не соответствующие навыки</span>
  </div>
  <mat-divider [style.margin]="'16px 0'"></mat-divider>
  <div
    fxLayout='row'
    fxLayoutAlign='start center'
    fxLayoutGap='15px'
    *ngFor='let thing of things'
  >
    <div fxFlex class='expected-thing-text'>{{ thing.name }}</div>
    <div fxFlex='10px' class='expected-thing-count'>
      {{ thing.count }}
    </div>
  </div>
</ng-template>
