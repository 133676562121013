import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from './components/calendar/calendar.component';
import { UiComponents } from '@components/ui/ui.module';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { CompaniesComponent } from './components/companies/companies.component';
import { MatIconModule } from '@angular/material/icon';
import { CreateCompanyDialogComponent } from './components/companies/create-company-dialog/create-company-dialog.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { CompanyProjectsComponent } from './components/company-projects/company-projects.component';
import { ProjectDetailsComponent } from './components/project-details/project-details.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommonComponentsModule } from '@components/common/common.module';
import { ProjectEditComponent } from './components/project-edit/project-edit.component';

@NgModule({
  declarations: [
    CalendarComponent,
    CompaniesComponent,
    CreateCompanyDialogComponent,
    CompanyProjectsComponent,
    ProjectDetailsComponent,
    ProjectEditComponent,
  ],
  imports: [
    CommonModule,
    UiComponents,
    FlexModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgSelectModule,
    MatDatepickerModule,
    CommonComponentsModule,
  ],
  exports: [],
})
export class ProjectsModule {}
