import { Component } from '@angular/core';

import { TitleService } from 'src/app/services/title.service';

@Component({
  templateUrl: './tutorial-quickstart.page.html',
  styleUrls: ['./tutorial-quickstart.page.css'],
})
export class DocsTutorialQuickstartPage {
  constructor(private _titleService: TitleService) {
    this._titleService.set_title('Как начать? Инструкция');
  }
}
