import { Component, OnInit } from '@angular/core';
import { Review } from '@models/reviews/review';
import { User } from '@models/user/user';
import { AsyncDetail } from '@rest/AsyncDetail';
import { ReviewHttpService } from '@services/http/ReviewHttpService';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentHttpService } from '@services/http/AssessmentHttpService';
import { AsyncList } from '@rest/AsyncList';
import { Assessment } from '@models/reviews/assessment';
import { ResolutionHttpService } from '@services/http/ResolutionHttpService';
import { Resolution } from '@models/reviews/resolution';
import { StaticTextField } from '@components/common/static-textfield-group/static-textfield-group.component';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { AuthService } from '../../../../services/auth/auth.service';
import { get_full_parent_path } from '../../../../utils/getFullParentUrl';
import { TitleService } from 'src/app/services/title.service';
import { AcceptDialogComponent } from '../../../common-ui-elements/components/accept-dialog/accept-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-new-review-detail',
  templateUrl: './new-review-detail.component.html',
  styleUrls: ['./new-review-detail.component.css'],
})
export class NewReviewDetailComponent implements OnInit {
  review: AsyncDetail<Review>;
  reviewAssessments: AsyncList<Assessment>;
  reviewResolutions: AsyncList<Resolution>;
  speciality: AsyncDetail<Speciality>;
  selfAssessment: Assessment;

  sortedAssessments: Assessment[] = [];
  resolution: Resolution;

  reviewInfoData: StaticTextField[];

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _dialog: MatDialog,
    private _specialityHttpService: SpecialityHttpService,
    private _reviewHttpService: ReviewHttpService,
    private _assessmentHttpService: AssessmentHttpService,
    private _resolutionHttpService: ResolutionHttpService,
    private _titleService: TitleService
  ) {}

  ngOnInit(): void {
    this._titleService.set_title('Ревью');
    this.review = new AsyncDetail<Review>(
      this._activatedRoute.snapshot.paramMap.get('rUuid'),
      this._reviewHttpService
    );
    this.review.requestParams = {
      params: {
        expand: 'responsible_user',
      },
    };
    this.review.load().subscribe(() => {
      this._titleService.set_title(this.review.state.item.name);
      this._getReviewInfoData();
      this._loadReviewAssessments();
      this._loadReviewResolution();
    });
  }

  private _getReviewInfoData(): void {
    const specialityUuid = Object.values(
      this.review.state.item.config?.subject_speciality_map
    )[0][0];

    this.speciality = new AsyncDetail<Speciality>(
      specialityUuid,
      this._specialityHttpService
    );
    this.speciality.load().subscribe(
      () => {
        this.reviewInfoData = [
          { name: 'Специальность', text: this.speciality.state.item.name },
          {
            name: 'Срок ревью',
            text: this.getPrettyReviewDate(this.review.state.item),
          },
          {
            name: 'Ответственный',
            text: `${this.responsibleUser.last_name} ${this.responsibleUser.first_name}`,
          },
        ];
      },
      () => {
        this.reviewInfoData = [
          { name: 'Специальность', text: 'Удалена' },
          {
            name: 'Срок ревью',
            text: this.getPrettyReviewDate(this.review.state.item),
          },
          {
            name: 'Ответственный',
            text: `${this.responsibleUser.last_name} ${this.responsibleUser.first_name}`,
          },
        ];
      }
    );
  }

  private _loadReviewAssessments(): void {
    this.reviewAssessments = new AsyncList<Assessment>(
      this._assessmentHttpService
    );
    this.reviewAssessments.setRequestParams({
      params: {
        review: this.review.state.item.uuid,
        expand: 'reviewer_user,',
      },
    });
    this.reviewAssessments.load().subscribe(() => {
      this.selfAssessment = this.reviewAssessments.state.items.find(
        (assessment) =>
          assessment.reviewer_user.uuid === assessment.subject_user
      );
      this.sortedAssessments = this.reviewAssessments.state.items.sort(
        (a, b) => +new Date(a.date_created) - +new Date(b.date_created)
      );
      if (this.selfAssessment) {
        this.sortedAssessments = this.sortedAssessments.sort((a, b) => {
          return a.uuid === this.selfAssessment.uuid ? -1 : 1;
        });
      }
    });
  }

  private _loadReviewResolution(): void {
    this.reviewResolutions = new AsyncList<Resolution>(
      this._resolutionHttpService
    );
    this.reviewResolutions.setRequestParams({
      params: {
        review: this.review.state.item.uuid,
      },
    });
    this.reviewResolutions.load().subscribe(() => {
      this.resolution = this.reviewResolutions.state.items[0];
    });
  }

  get responsibleUser(): User {
    return this.review.state.item.responsible_user as User;
  }

  //
  // getReviewSpeciality(review: Review): string {
  //   if (review.config && review.config.subject_speciality_map) {
  //     const reviewSubject = review.subjects[0] as User,
  //       reviewSpecialityUuid =
  //         review.config?.subject_speciality_map[reviewSubject.uuid][0];
  //
  //     return this.teamSpecialities.state.items.find(
  //       (speciality) => speciality.uuid === reviewSpecialityUuid
  //     )?.name;
  //   }
  //   return '';
  // }

  getPrettyReviewDate(review: Review): string {
    if (review.deadline_start && review.deadline_end) {
      const dateFrom = new Date(review.deadline_start),
        dateTo = new Date(review.deadline_end);
      return `${dateFrom.getDate()} ${dateFrom.toLocaleString('default', {
        month: 'short',
      })} - ${dateTo.getDate()} ${dateTo.toLocaleString('default', {
        month: 'short',
      })}`;
    }
    return '';
  }

  onBackClick(): void {
    history.back();
  }

  onReviewEdit(review: Review): void {
    this._router.navigate([
      `${this.getFullParentPath(this._activatedRoute)}/edit`,
    ]);
  }

  onReviewDelete(): void {
    this._dialog
      .open(AcceptDialogComponent, {
        data: {
          title: `Удалить ревью ${this.review.state.item.name}`,
          message: 'Вы действительно хотите удалить ревью?',
          acceptLabel: 'Удалить',
          rejectLabel: 'Отмена',
        },
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) {
          this.review.remove().subscribe(() => {
            this.review = null;
            this.onBackClick();
          });
        }
      });
  }

  isReviewer(assessment: Assessment): boolean {
    return (
      assessment.reviewer_user.uuid ===
      this._authService.get_current_user_uuid()
    );
  }

  isResponsible(): boolean {
    return (
      (this.review.state.item.responsible_user as User).uuid ===
      this._authService.get_current_user_uuid()
    );
  }

  isAdmin(): boolean {
    return this._authService.is_admin();
  }

  onAssessmentClick(assessment: Assessment): void {
    if (this.isReviewer(assessment)) {
      this._router.navigate([
        `${this.getFullParentPath(this._activatedRoute)}/assessment/${
          assessment.uuid
        }`,
      ]);
    }
  }

  onResolutionClick(resolution: Resolution): void {
    if (this.isResponsible()) {
      this._router.navigate([
        `${this.getFullParentPath(this._activatedRoute)}/resolution/${
          resolution.uuid
        }`,
      ]);
    }
  }

  getFullParentPath = get_full_parent_path;
}
