import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/models/user/user';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.css'],
})
export class UserSearchComponent implements OnInit {
  @Input() optionSelected: any;
  public config = {
    title: 'Поиск пользователей',
    placeholder: 'Имя или фамилия',
    ariaLabel: 'User search',
    displayWith: (user: User) => {
      return user ? `${user.first_name} ${user.last_name} – ${user.email}` : '';
    },
  };

  constructor(public service: UserService) {}
  ngOnInit(): void {}
}
