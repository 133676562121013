import { Component, OnInit } from '@angular/core';
import { List } from 'src/app/components/base/list';

import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { MatDialog } from '@angular/material/dialog';
import { ThingLevelTemplateService } from 'src/app/services/ontology/thing-level-template.service';
import {
  ThingLevelFormComponent,
  ThingLevelFormData,
} from 'src/app/modules/ontologies/components/things/tabs/thing-tab-levels/thing-level-form/thing-level-form.component';
import { ThingLevelTemplate } from 'src/app/models/ontology/thing-level-template';
import { AcceptDialogComponent } from 'src/app/modules/common-ui-elements/components/accept-dialog/accept-dialog.component';

@Component({
  selector: 'app-thing-level-templates',
  templateUrl: './thing-level-templates.component.html',
  styleUrls: ['./thing-level-templates.component.css'],
})
export class TeamManageThingLevelsComponent extends List implements OnInit {
  public teamUUID = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public loggingService: LoggingService,
    public alertService: AlertService,
    public thingLevelsTemplateService: ThingLevelTemplateService,
    public dialog: MatDialog
  ) {
    super(loggingService, alertService, thingLevelsTemplateService);
  }

  ngOnInit(): void {
    this.teamUUID = this.route.parent.snapshot.paramMap.get('team_uuid');
    this.requestParams = this.teamUUID
      ? {
          team: this.teamUUID,
        }
      : {
          team__isnull: 'True',
        };
    this.load();
  }

  openCreateDialog() {
    const formConfig: ThingLevelFormData = {
      submitTitle: 'Создать',
      formTitle: 'Создать новый уровень',
      onSubmit: this.onSubmitCreateLevel,
      initialValues: {
        title: '',
        description: '',
      },
    };

    this.dialog.open(ThingLevelFormComponent, {
      data: formConfig,
      width: '55rem',
    });
  }

  openEditDialog(level: ThingLevelTemplate) {
    const formConfig: ThingLevelFormData = {
      submitTitle: 'Обновить уровень',
      formTitle: 'Обновить уровень',
      onSubmit: this.onSubmitUpdateLevel(level),
      initialValues: {
        title: level.title,
        description: level.description,
      },
    };

    this.dialog.open(ThingLevelFormComponent, {
      data: formConfig,
      width: '55rem',
    });
  }

  onSubmitCreateLevel = (formValue, dialogRef: any) => {
    this.thingLevelsTemplateService
      .create({
        ...formValue,
        team: this.teamUUID,
      })
      .subscribe({
        next: (level) => {
          this.alertService.success('Уровень создан');
          this.state.items.push(level);
          dialogRef.close();
        },
        error: () => {
          this.alertService.error('Не удалось создать уровень');
        },
      });
  };

  onSubmitUpdateLevel =
    (level: ThingLevelTemplate) => (formValue, dialogRef: any) => {
      this.update(level.uuid, formValue).subscribe(() => {
        dialogRef.close();
        this.alertService.success('Уровень успешно обновлен');
      });
    };

  openRemoveDialog(level: ThingLevelTemplate) {
    this.dialog
      .open(AcceptDialogComponent, {
        data: {
          title: `Удалить ${level.title}`,
          message: 'Вы действительно хотите удалить уровень?',
          acceptLabel: 'Удалить',
          rejectLabel: 'Отмена',
        },
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) {
          this.remove(level.uuid);
        }
      });
  }
}
