<div>
    <ng-container *ngIf="is_data_loaded; then component_content; else preloader">
    </ng-container>
</div>

<ng-template #preloader>
    <app-preloader-progressbar></app-preloader-progressbar>
</ng-template>

<ng-template #component_content>
    <div class="app-admin-survey-details-tab-container">
        <div fxLayout="row">
            <div fxFlex>
                <div *ngIf="!survey_sessions.length">
                    <div class="app-admin-survey-details-sessions-empty">
                        Еще никто не начинал проходить этот опрос
                    </div>
                </div>
                <div *ngIf="survey_sessions.length">
                    <div *ngFor="let survey_session of survey_sessions">
                        <div class="app-admin-survey-details-sessions-listitem">
                            <div fxLayout="row">
                                <div fxFlex="5rem">
                                    <div class="app-admin-survey-details-sessions-listitem-status">
                                        <div *ngIf="survey_session.is_finished">
                                            <mat-icon>check</mat-icon>
                                        </div>
                                        <div *ngIf="!survey_session.is_finished">
                                            <mat-icon>hourglass_top</mat-icon>
                                        </div>
                                    </div>
                                </div>
                                <div fxFlex="5rem">
                                    <div class="app-admin-survey-details-sessions-listitem-user-avatar">
                                        {{ util_user_get_initials_for_avatar(survey_session.user) }}
                                    </div>
                                </div>
                                <div fxFlex="30rem">
                                    <div class="app-admin-survey-details-sessions-listitem-user-name">
                                        <a routerLink="/profile/{{ survey_session.user.uuid }}">
                                            {{ survey_session.user.first_name }} {{ survey_session.user.last_name }}
                                        </a>
                                    </div>
                                    <div class="app-admin-survey-details-sessions-listitem-user-email">
                                        {{ survey_session.user.email }}
                                    </div>
                                </div>
                                <div fxFlex>
                                    <div class="app-admin-survey-details-sessions-listitem-dates">
                                        <div *ngIf="survey_session.is_finished">
                                            <div fxLayout="row">
                                                <div fxFlex="7rem">
                                                    Начало
                                                    <br>
                                                    Завершение
                                                </div>
                                                <div fxFlex>
                                                    {{ survey_session.date_created | amDateFormat: 'YYYY-MM-DD HH:mm' }}
                                                    <br>
                                                    {{ survey_session.date_finished | amDateFormat: 'YYYY-MM-DD HH:mm' }}
                                                </div>
                                            </div>
                                        </div>
                
                                        <div *ngIf="!survey_session.is_finished">
                                            <div fxLayout="row">
                                                <div fxFlex="7rem">
                                                    Начало
                                                    <br>
                                                    Прогресс
                                                </div>
                                                <div fxFlex>
                                                    {{ survey_session.date_created | amDateFormat: 'YYYY-MM-DD HH:mm' }}
                                                    <br>
                                                    <div class="app-admin-survey-details-sessions-listitem-status-progress">
                                                        <mat-progress-bar
                                                            mode="determinate"
                                                            [value]="survey_session.current_percentage"></mat-progress-bar>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
                
            </div>
        </div>
    </div>
</ng-template>


