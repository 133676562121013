import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AcceptDialogData {
  title: string;
  message: string;
  acceptLabel: string;
  rejectLabel: string;
}

@Component({
  selector: 'app-accept-dialog',
  templateUrl: './accept-dialog.component.html',
  styleUrls: ['./accept-dialog.component.css'],
})
export class AcceptDialogComponent {
  form: UntypedFormGroup;
  constructor(
    public dialogRef: MatDialogRef<AcceptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AcceptDialogData
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
