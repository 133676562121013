import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTreeModule } from '@angular/material/tree';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';

import { MomentModule } from 'ngx-moment';

import { AdminRoutingModule } from './admin-routing.module';

import { CommonUIElementsModule } from 'src/app/modules/common-ui-elements/common-ui-elements.module';
import { CommonD3ChartsModule } from 'src/app/modules/common-d3-charts/common-d3-charts.module';

import { OntologiesModule } from 'src/app/modules/ontologies/ontologies.module';
import { TrackModule } from 'src/app/modules/track/track.module';

import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { AdminMainComponent } from './components/admin-main/admin-main.component';

import { UsersDashboardComponent } from './components/users/users-dashboard/users-dashboard.component';

import { TeamsDashboardComponent } from './components/teams/teams-dashboard/teams-dashboard.component';
import { TeamDetailsComponent } from './components/teams/team-details/team-details.component';

import { OntologyDashboardComponent } from './components/ontology/ontology-dashboard/ontology-dashboard.component';
import { DomainCreateComponent } from './components/ontology/domains/domain-create/domain-create.component';
import { DomainDetailsComponent } from './components/ontology/domains/domain-details/domain-details.component';
import { ThingDetailsComponent } from './components/ontology/things/thing-details/thing-details.component';

import { SurveysDashboardComponent } from './components/surveys/surveys-dashboard/surveys-dashboard.component';
import { SurveyCreateComponent } from './components/surveys/survey-create/survey-create.component';
import { SurveyEditComponent } from './components/surveys/survey-edit/survey-edit.component';
import { SurveyDetailsComponent } from './components/surveys/survey-details/survey-details.component';
import { SurveyEditLinearComponent } from './components/surveys/survey-edit-linear/survey-edit-linear.component';
import { SurveyEditTreeComponent } from './components/surveys/survey-edit-tree/survey-edit-tree.component';
import { SurveyEditSubtreeComponent } from './components/surveys/survey-edit-subtree/survey-edit-subtree.component';
import { SurveyEditAnswerComponent } from './components/surveys/survey-edit-answer/survey-edit-answer.component';

import { SpecialitiesDashboardComponent } from './components/specialities/specialities-dashboard/specialities-dashboard.component';
import { SpecialityDetailsComponent } from './components/specialities/speciality-details/speciality-details.component';
import { SpecialityCreateComponent } from './components/specialities/speciality-create/speciality-create.component';

import { StatisticsDashboardComponent } from './components/statistics/statistics-dashboard/statistics-dashboard.component';
import { DebuggingDashboardComponent } from './components/system/debugging-dashboard/debugging-dashboard.component';
import { ReleasenotesComponent } from './components/system/releasenotes/releasenotes.component';
import { UserMainComponent } from './components/users/user-main/user-main/user-main.component';
import { AdminUserCompetenciesTabComponent } from './components/users/user-tabs/admin-user-tab-competencies/admin-user-competencies-tab.component';
import { SurveyDetailsTabSessionsComponent } from './components/surveys/survey-details-tabs/survey-details-tab-sessions/survey-details-tab-sessions.component';
import { SurveyDetailsTabAssignmentsComponent } from './components/surveys/survey-details-tabs/survey-details-tab-assignments/survey-details-tab-assignments.component';
import { AdminUserTabTeamsComponent } from './components/users/user-tabs/admin-user-tab-teams/admin-user-tab-teams.component';
import { AdminUserTabSpecialitiesComponent } from './components/users/user-tabs/admin-user-tab-specialities/admin-user-tab-specialities.component';
import { AdminUserTabEventsComponent } from './components/users/user-tabs/admin-user-tab-events/admin-user-tab-events.component';
import { AdminUserTabPdpsComponent } from './components/users/user-tabs/admin-user-tab-pdps/admin-user-tab-pdps.component';
import { SpecialityProfileComponent } from './components/specialities/speciality-details-tabs/speciality-profile/speciality-profile.component';
import { SpecialityProfileDialogClaimCreateComponent } from './components/specialities/speciality-details-tabs/speciality-profile-dialog-claim-create/speciality-profile-dialog-claim-create.component';
import { SpecialityProfileDialogVisualComponent } from './components/specialities/speciality-details-tabs/speciality-profile-dialog-visual/speciality-profile-dialog-visual.component';
import { CommonComponentsModule } from '@components/common/common.module';
import { MatMenuModule } from '@angular/material/menu';
import { AdminSettingsComponent } from './components/admin-settings/admin-settings.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CreateEmailServiceSettingsComponent } from './components/admin-settings/create-email-service-settings/create-email-service-settings.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { EditEmailServiceSettingsComponent } from './components/admin-settings/edit-email-service-settings/edit-email-service-settings.component';
import { SendEmailFormComponent } from './components/admin-settings/send-email-form/send-email-form.component';
import { MatBadgeModule } from '@angular/material/badge';
import { UiComponents } from '@components/ui/ui.module';
import { TeamsDashboardListAllComponent } from './components/teams/teams-dashboard/teams-dashboard-list-all/teams-dashboard-list-all.component';
import { TeamsDashboardListUnverifiedComponent } from './components/teams/teams-dashboard/teams-dashboard-list-unverified/teams-dashboard-list-unverified.component';
import { UploadDomainsComponent } from './components/ontology/upload-domains/upload-domains.component';
import { LibraryModule } from 'src/app/modules/library/library.module';
import { ImportedDataEditDialogComponent } from './components/ontology/upload-domains/imported-data-edit-dialog/imported-data-edit-dialog.component';
import { DirectionsDashboardComponent } from './components/directions/directions-dashboard/directions-dashboard.component';
import { DirectionCreateDialogComponent } from './components/directions/direction-create-dialog/direction-create-dialog.component';
import { DirectionDetailsComponent } from './components/directions/direction-details/direction-details.component';
import { DashboardComponent } from './components/analytics/dashboard/dashboard.component';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  declarations: [
    AdminDashboardComponent,
    TeamsDashboardComponent,
    TeamDetailsComponent,
    UsersDashboardComponent,
    OntologyDashboardComponent,
    DomainCreateComponent,
    DomainDetailsComponent,
    SurveysDashboardComponent,
    SurveyCreateComponent,
    SurveyEditComponent,
    SurveyDetailsComponent,
    SurveyEditLinearComponent,
    DebuggingDashboardComponent,
    SurveyEditTreeComponent,
    SpecialitiesDashboardComponent,
    SpecialityDetailsComponent,
    SpecialityCreateComponent,
    SurveyEditSubtreeComponent,
    SurveyEditAnswerComponent,
    ThingDetailsComponent,
    StatisticsDashboardComponent,
    AdminMainComponent,
    ReleasenotesComponent,
    UserMainComponent,
    AdminUserCompetenciesTabComponent,
    SurveyDetailsTabSessionsComponent,
    SurveyDetailsTabAssignmentsComponent,
    AdminUserTabTeamsComponent,
    AdminUserTabSpecialitiesComponent,
    AdminUserTabEventsComponent,
    AdminUserTabPdpsComponent,
    SpecialityProfileComponent,
    SpecialityProfileDialogClaimCreateComponent,
    SpecialityProfileDialogVisualComponent,
    AdminSettingsComponent,
    CreateEmailServiceSettingsComponent,
    EditEmailServiceSettingsComponent,
    SendEmailFormComponent,
    TeamsDashboardListAllComponent,
    TeamsDashboardListUnverifiedComponent,
    UploadDomainsComponent,
    ImportedDataEditDialogComponent,
    DirectionsDashboardComponent,
    DirectionCreateDialogComponent,
    DirectionDetailsComponent,
    DashboardComponent,
  ],
  imports: [
    MatNativeDateModule,
    MatDatepickerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    DragDropModule,
    FlexLayoutModule,
    MomentModule,

    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatOptionModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatTreeModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatDialogModule,
    MatCardModule,

    AdminRoutingModule,

    CommonUIElementsModule,
    CommonD3ChartsModule,

    OntologiesModule,
    TrackModule,
    CommonComponentsModule,
    MatMenuModule,
    NgSelectModule,
    MatRadioModule,
    MatChipsModule,
    MatBadgeModule,
    UiComponents,
    LibraryModule,
  ],
})
export class AdminModule {}
