import { Component, Input, OnInit } from '@angular/core';
import { AsyncList } from '@rest/AsyncList';
import { Speciality } from '@models/specialities/speciality';
import { LibraryItemProps } from '@components/common/library-item/library-item.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { Direction } from '@models/directions/direction';
import { DirectionHttpService } from '@services/http/DirectionHttpService';

@Component({
  selector: 'app-speciality-list',
  templateUrl: './speciality-list.component.html',
  styleUrls: ['./speciality-list.component.css'],
})
export class SpecialityListComponent implements OnInit {
  specialities: AsyncList<Speciality>;
  newSpecialities: AsyncList<Speciality>;
  userDirections: AsyncList<Direction>;

  directionsSpecialities: string[] = [];

  newSpecialityDate = new Date(new Date().setDate(new Date().getDate() - 30));

  isAllSpecialitiesChecked = true;
  isNewSpecialitiesChecked = false;

  directionsSpecialitiesReady = false;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _specialityHttpService: SpecialityHttpService,
    private _directionHttpService: DirectionHttpService
  ) {}

  ngOnInit(): void {
    this.specialities = new AsyncList<Speciality>(this._specialityHttpService);
    this.specialities.setRequestParams({
      params: { team__isnull: 'True' },
    });
    this.specialities.load().subscribe(() => {
      this.specialities.state.items = this.specialities.state.items.sort(
        (a, b) => a.name.localeCompare(b.name)
      );
    });

    this.newSpecialities = new AsyncList<Speciality>(
      this._specialityHttpService
    );
    this.newSpecialities.setRequestParams({
      params: {
        team__isnull: 'True',
        date_created__gt: this.newSpecialityDate.toISOString(),
      },
    });
    this.newSpecialities.load().subscribe(() => {
      this.newSpecialities.state.items = this.newSpecialities.state.items.sort(
        (a, b) => a.name.localeCompare(b.name)
      );
    });

    this.userDirections = new AsyncList<Direction>(this._directionHttpService);
    this.userDirections.setRequestParams({
      params: {
        admins: this._authService.get_current_user_uuid(),
      },
    });
    this.userDirections.load().subscribe(() => {
      this.userDirections.state.items.forEach((direction) => {
        direction.specialities.forEach((specialityUuid) => {
          if (!this.directionsSpecialities.includes(specialityUuid)) {
            this.directionsSpecialities.push(specialityUuid);
          }
        });
      });
      this.directionsSpecialitiesReady = true;
    });
  }

  get isUserAdmin(): boolean {
    return this._authService.is_admin();
  }

  serializeSpeciality(speciality: Speciality): LibraryItemProps {
    return {
      header: speciality.name,
      content: speciality.description,
      linkUrl: `/library/specialities/${speciality.uuid}`,
      newFlag: new Date(speciality.date_created) > this.newSpecialityDate,
      editable: this.isDirectionSpeciality(speciality.uuid),
    };
  }

  isDirectionSpeciality(specialityUuid: string): boolean {
    return this.directionsSpecialities.includes(specialityUuid);
  }

  onSpecialityTemplateAdd(): void {
    this._router.navigate([`library/specialities/create`]);
  }

  onAllSpecialitiesButtonClick(): void {
    this.isAllSpecialitiesChecked = !this.isAllSpecialitiesChecked;
    this.isNewSpecialitiesChecked = false;
  }

  onNewSpecialitiesButtonClick(): void {
    this.isNewSpecialitiesChecked = !this.isNewSpecialitiesChecked;
    this.isAllSpecialitiesChecked = false;
  }
}
