import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiComponents } from '@components/ui/ui.module';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommonComponentsModule } from '@components/common/common.module';
import { VerificationsComponent } from './components/verifications/verifications.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { VerificationDetailComponent } from './components/verification-detail/verification-detail.component';

@NgModule({
  declarations: [VerificationsComponent, VerificationDetailComponent],
  imports: [
    CommonModule,
    UiComponents,
    FlexModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgSelectModule,
    MatDatepickerModule,
    CommonComponentsModule,
    MatTabsModule,
    MatDividerModule,
  ],
  exports: [VerificationsComponent],
})
export class SpecialityVerificationModule {}
