import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestHttpService } from '@services/http/rest/RestHttpService';
import { GetListResponse } from '@services/http/rest/ViewSetHttpService';

@Injectable({
  providedIn: 'root',
})
export class StatsHttpService extends RestHttpService {
  constructor(private _http: HttpClient) {
    super();
  }

  // public baseUrl = `${environment.apiUrl}/api/1/`;
  // private _httpHeaders = new HttpHeaders({
  //   // eslint-disable-next-line @typescript-eslint/naming-convention
  //   'Content-Type': 'application/json',
  // });

  public getUrl(): string {
    return this.baseUrl + 'stats';
  }

  public models(): Observable<any> {
    return this._http.get(`${this.getUrl()}/models`);
  }

  public setMatrixLoadingTime(loading_time: number): Observable<any> {
    return this._http.post(
      `${this.getUrl()}/matrix_loading_time`,
      {
        loading_time,
      },
      {
        responseType: 'json',
      }
    );
  }

  public getMatrixLoadingTime(params: any): Observable<any> {
    return this._http.get(`${this.getUrl()}/matrix_loading_time`, {
      // ```responseType: json``` allow set Response Type
      responseType: 'json',
      ...params,
    });
  }
}
