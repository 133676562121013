<ng-container *ngIf='is_app_ready; then tmpl_app; else tmpl_loading_screen'>
</ng-container>

<ng-template #tmpl_loading_screen>
  <app-preloader></app-preloader>
</ng-template>

<ng-template #tmpl_app>
  <div *ngIf='!is_logged_in'>
    <ng-container *ngIf='true; then tmpl_header'></ng-container>

    <div class='app-router-outlet'>
      <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>

    <!-- <div style="text-align: center;">
          <mat-slide-toggle [(ngModel)]="new_header">Новый хедер</mat-slide-toggle>
      </div> -->

    <app-alert></app-alert>
  </div>
  <div *ngIf='is_logged_in'>
    <div *ngIf='!useNewLayout'>
      <ng-container *ngIf='true; then tmpl_header'></ng-container>

      <div class='app-router-outlet'>
        <router-outlet></router-outlet>
      </div>

      <app-footer></app-footer>

      <!-- <div style="text-align: center;">
            <mat-slide-toggle [(ngModel)]="new_header">Новый хедер</mat-slide-toggle>
        </div> -->

      <app-alert></app-alert>
    </div>
    <div *ngIf='useNewLayout'>
      <div fxLayout='row'>
        <div
          fxFlex='5'
          fxLayout='column'
          fxLayoutAlign='start center'
          class='nav-bar'
        >
          <div class='logo'>
            <div class='app-sidebar-logo-container'>
              <img
                class='app-sidebar-logo-image'
                src='{{ header_logo_url }}'
                (click)='on_click_new_header_logo()'
              />
            </div>
          </div>
          <div *ngIf='is_logged_in'>
            <div
              fxLayoutAlign='center center'
              class='nav-link'
              (click)='on_click_new_header_user()'
              matBadgeOverlap='true'
              matTooltip='Личный кабинет'
              matTooltipPosition='right'
            >
              <mat-icon class='nav-icon'>account_circle</mat-icon>
            </div>
          </div>
          <div *ngIf='!is_logged_in'>
            <button
              class='nav-link'
              mat-button
              (click)='on_button_auth_login()'
              [attr.data-test-id]='testDataAttributes.loginButtonId'
            >
              <mat-icon class='nav-icon'>exit_to_app</mat-icon>
              Войти
            </button>
          </div>
          <div
            *ngIf='is_logged_in && is_onboarding_complete'
            matTooltip='Библиотека компетенций'
            matTooltipPosition='right'
          >
            <a
              class='nav-link'
              (click)='onLibraryNav()'
              fxLayoutAlign='center center'
            >
              <mat-icon class='nav-icon'>folder_open</mat-icon>
            </a
            >
          </div>
          <div
            *ngIf='is_logged_in && is_onboarding_complete'
            matTooltip='Структура'
            matTooltipPosition='right'
          >
            <a
              class='nav-link'
              routerLink='/teams'
              fxLayoutAlign='center center'
            >
              <mat-icon class='nav-icon'>list</mat-icon>
            </a
            >
          </div>
          <div
            *ngIf='is_logged_in && is_onboarding_complete && is_user_owner'
            matTooltip='Команды'
            matTooltipPosition='right'
          >
            <a class='nav-link' routerLink='/team' fxLayoutAlign='center center'
            >
              <mat-icon class='nav-icon'>group</mat-icon>
            </a
            >
          </div>
          <div
            *ngIf='is_logged_in && is_user_admin'
            matTooltip='Админка'
            matTooltipPosition='right'
          >
            <a
              class='nav-link'
              routerLink='/admin/main'
              fxLayoutAlign='center center'
            >
              <mat-icon class='nav-icon'>settings</mat-icon>
            </a
            >
          </div>
          <div fxFlex fxLayout='column' fxLayoutAlign='end center'>
            <div fxLayoutAlign='center center'>
              <a
                class='nav-link'
                href='https://support.rosbank.rus.socgen/calls/create/R2707?complete_user=1'
                fxLayoutAlign='center center'
                matTooltip='Обратиться в поддержку'
                matTooltipPosition='right'
                target='_blank'
              >
                <mat-icon class='nav-icon'>contact_support</mat-icon>
              </a>
            </div>
            <div *ngIf='is_logged_in'>
              <div fxLayoutAlign='center center' class='nav-link' (click)='on_button_auth_logout()'>
                <mat-icon class='nav-icon'>exit_to_app</mat-icon>
              </div>
            </div>
            <div fxLayoutAlign='center center'>
              <a
                class='nav-link'
                routerLink='/docs/tutorial/quickstart'
                fxLayoutAlign='center center'
                matTooltip='Документация'
                matTooltipPosition='right'
              >
                <mat-icon class='nav-icon'>info</mat-icon>
              </a>
            </div>
          </div>
        </div>
        <div fxFlex='95' class='app-router-outlet-new'>
          <router-outlet></router-outlet>
        </div>
      </div>
      <app-alert></app-alert>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_header>
  <div class='app-header-container'>
    <div fxLayout='row' fxLayoutAlign='center center'>
      <div fxFlex>
        <div fxLayout='row'>
          <div fxFlex='250px'>
            <div class='app-header-logo-container'>
              <img
                class='app-header-logo-image'
                src='{{ header_logo_url }}'
                (click)='on_click_header_logo()'
              />
            </div>
          </div>
          <div fxFlex='10rem' fxLayoutAlign='center center'>
            <div class='app-header-appname-container'>
              <div (click)='on_click_header_logo()'>
                {{ app_name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div fxFlex>
        <div fxLayout='row' fxLayoutAlign='space-between center'>
          <div fxFlex *ngIf='is_logged_in && is_onboarding_complete'>
            <div class='app-header-nav-link-container'>
              <a class='app-header-nav-link' routerLink='/dashboard'>
                Дашборд
              </a>
            </div>
          </div>
          <div fxFlex *ngIf='is_logged_in && is_onboarding_complete'>
            <div class='app-header-nav-link-container'>
              <a class='app-header-nav-link' routerLink='/library'>
                Компетенции
              </a>
            </div>
          </div>
          <div fxFlex *ngIf='is_logged_in && is_onboarding_complete'>
            <div class='app-header-nav-link-container'>
              <a class='app-header-nav-link' routerLink='/teams'> Команды </a>
            </div>
          </div>
          <div fxFlex *ngIf='is_logged_in && is_user_admin'>
            <div class='app-header-nav-link-container'>
              <a
                class='app-header-nav-link'
                routerLink='/admin/main'
                [attr.data-test-id]='testDataAttributes.topMenuAdminLinkId'
              >
                Админка
              </a>
            </div>
          </div>
        </div>
      </div>

      <div fxFlex='25rem'>
        <div fxLayout='row'>
          <div fxFlex fxLayoutAlign='end center'>
            <ng-container
              *ngIf='
                is_logged_in;
                then tmpl_header_user_authenticated;
                else tmpl_header_user_unauthenticated
              '
            >
            </ng-container>
          </div>

          <ng-template #tmpl_header_user_unauthenticated>
            <button
              mat-button
              (click)='on_button_auth_login()'
              [attr.data-test-id]='testDataAttributes.loginButtonId'
            >
              <mat-icon>exit_to_app</mat-icon>
              Войти
            </button>
          </ng-template>

          <ng-template #tmpl_header_user_authenticated>
            <div
              class='app-header-user-container'
              (click)='on_click_header_user()'
              matBadge='{{ user_unread_counter }}'
              matBadgeSize='small'
              [matBadgeHidden]='user_unread_counter === 0'
              matBadgeOverlap='true'
            >
              <app-user-inline [user]='user'></app-user-inline>
            </div>
            <div>
              <button mat-icon-button (click)='on_button_auth_logout()'>
                <mat-icon>exit_to_app</mat-icon>
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-template>
