import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamMembershipHttpService } from '@services/http/TeamMembershipHttpService';
import { TeamMembership } from '@models/teams/team-membership';
import { AsyncList } from '@rest/AsyncList';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Team } from '@models/teams/team';
import { TeamHttpService } from '@services/http/TeamHttpService';
import { AuthService } from '../../../../../../services/auth/auth.service';
import { User } from '@models/user/user';
import { SpecialityUserRelation } from '@models/specialities/speciality-user-relation';
import { SpecialityUserRelationHttpService } from '@services/http/SpecialityUserRelationHttpService';
import { Speciality } from '@models/specialities/speciality';
import { TitleService } from 'src/app/services/title.service';
import { SpecialityGrade } from '@models/specialities/speciality-grade';
import {
  TeamJoinDialogComponent,
  UserTeamSpecialityData,
} from '@components/common/team-join-dialog/team-join-dialog.component';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { SpecialityGradeHttpService } from '@services/http/SpecialityGradeHttpService';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '../../../../../../services/app/app.service';
import { UserService } from '../../../../../../services/user/user.service';
import { LoggingService } from '../../../../../../services/logging.service';
import { AcceptDialogComponent } from '../../../../../common-ui-elements/components/accept-dialog/accept-dialog.component';
import { TeamSettings } from '@models/teams/team-settings';
import { TeamSettingsHttpService } from '@services/http/TeamSettingsHttpService';
import { TeamJoinRequestDialogComponent } from '@components/common/team-join-request-dialog/team-join-request-dialog.component';
import { TeamJoinRequest } from '@models/teams/team-join-request';
import { TeamJoinRequestHttpService } from '@services/http/TeamJoinRequestHttpService';

@Component({
  selector: 'app-user-team',
  templateUrl: './user-team.component.html',
  styleUrls: ['./user-team.component.css'],
})
export class UserTeamComponent implements OnInit {
  teamUuid: string;
  team: AsyncDetail<Team>;
  teamSettings: AsyncList<TeamSettings>;
  teamMembers: AsyncList<TeamMembership>;

  selfJoinRequest: AsyncList<TeamJoinRequest>;

  specialityUsersRelations: AsyncList<SpecialityUserRelation>;

  usersSpecialitiesMap;

  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _appService: AppService,
    private _userService: UserService,
    private _loggingService: LoggingService,
    private _titleService: TitleService,
    private _teamHttpService: TeamHttpService,
    private _teamMembershipHttpService: TeamMembershipHttpService,
    private _teamJoinRequestHttpService: TeamJoinRequestHttpService,
    private _teamSettingsHttpService: TeamSettingsHttpService,
    private _specialityHttpService: SpecialityHttpService,
    private _specialityGradeHttpService: SpecialityGradeHttpService,
    private _specialityUserRelationHttpService: SpecialityUserRelationHttpService
  ) {}

  ngOnInit(): void {
    this._titleService.set_title('Команда');
    this.teamUuid = this._activatedRoute.snapshot.paramMap.get('uuid');

    this.team = new AsyncDetail<Team>(this.teamUuid, this._teamHttpService);
    this.team.load().subscribe(() => {
      this._titleService.set_title(`Команда ${this.team.state.item.name}`);
      this._loadSelfJoinRequest();
    });

    this.teamSettings = new AsyncList<TeamSettings>(
      this._teamSettingsHttpService
    );
    this.teamSettings.setRequestParams({
      params: {
        team: this.teamUuid,
      },
    });
    this.teamSettings.load();

    this._loadTeamMembers();
  }

  private _loadSelfJoinRequest(): void {
    this.selfJoinRequest = new AsyncList<TeamJoinRequest>(
      this._teamJoinRequestHttpService
    );
    this.selfJoinRequest.setRequestParams({
      params: {
        user: this._authService.get_current_user_uuid(),
        team: this.teamUuid,
        status__in: '0,1',
      },
    });
    this.selfJoinRequest.load().subscribe(() => {});
  }

  isSelfJoinRequest(): boolean {
    return !!this.selfJoinRequest.state.items.length;
  }

  isSelfJoinRequestRejected(): boolean {
    return this.selfJoinRequest.state.items[0].status === 3;
  }

  private _loadTeamMembers(): void {
    this.teamMembers = new AsyncList<TeamMembership>(
      this._teamMembershipHttpService
    );
    this.teamMembers.setRequestParams({
      params: {
        team: this.teamUuid,
        expand: 'user.team_memberships.team',
        page_size: 1000,
      },
    });
    this.teamMembers.load().subscribe(() => {
      this._loadMembersSpecialities();
    });
  }

  private _loadMembersSpecialities(): void {
    this.specialityUsersRelations = new AsyncList<SpecialityUserRelation>(
      this._specialityUserRelationHttpService
    );
    this.specialityUsersRelations.setRequestParams({
      params: {
        user__in: this.teamMembers.state.items
          .map((member) => (member.user as User).uuid)
          .join(','),
        expand: 'speciality',
      },
    });
    this.specialityUsersRelations.load().subscribe((response) => {
      this.usersSpecialitiesMap = {};
      response.results.forEach(
        (relation: SpecialityUserRelation<string, Speciality, string>) => {
          const userUuid = relation.user as string;
          if (relation.speciality.team === this.teamUuid) {
            if (this.usersSpecialitiesMap[userUuid]) {
              this.usersSpecialitiesMap[userUuid].push(relation.speciality);
            } else {
              this.usersSpecialitiesMap[userUuid] = [relation.speciality];
            }
          }
        }
      );
    });
  }

  getUserMembership(): TeamMembership {
    const memberships = this._authService.get_user_memberships();

    if (memberships.loaded) {
      return memberships.data.find(
        (teamMembership) => teamMembership.team.uuid === this.teamUuid
      );
    }
  }

  public get joinType(): number {
    return this.teamSettings.state.items[0].join_type_enum;
  }

  leaveTeam(): void {
    const userRole = this.getUserMembership().role;
    let message = 'Вы уверены, что хотите покинуть команду?',
      acceptLabel = 'Да',
      rejectLabel = 'Нет',
      isLeaveAllowed = true;
    if (
      userRole === 'owner' &&
      this.teamMembers.state.items.filter((member) => member.role === 'owner')
        .length < 2
    ) {
      message =
        'Вы являетесь единственным руководителем команды, назначьте эту роль кому-либо еще, перед тем как покинуть команду';
      acceptLabel = 'Ок';
      rejectLabel = 'Отмена';
      isLeaveAllowed = false;
    }
    this._dialog
      .open(AcceptDialogComponent, {
        data: {
          title: `Покинуть команду?`,
          message: message,
          acceptLabel: acceptLabel,
          rejectLabel: rejectLabel,
        },
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept && isLeaveAllowed) {
          this._teamMembershipHttpService
            .delete(this.getUserMembership().uuid)
            .subscribe((response) => {
              this._appService.load_user_memberships();
              this._userService.get_edit_teams().subscribe((response) => {
                this._loggingService.debug(
                  `${this.constructor.name} fetched user edit teams`
                );
                this._authService.set_user_edit_teams(response);
              });
              this._loadTeamMembers();
            });
        } else {
          console.log('owner cant leave');
        }
      });
  }

  joinTeam(): void {
    const teamSpecialities = new AsyncList<Speciality>(
      this._specialityHttpService
    );
    teamSpecialities.setRequestParams({
      params: {
        team: this.teamUuid,
      },
    });
    teamSpecialities.load().subscribe(() => {
      const teamSpecialitiesGrades = new AsyncList<SpecialityGrade>(
        this._specialityGradeHttpService
      );
      teamSpecialitiesGrades.setRequestParams({
        params: {
          speciality__in: teamSpecialities.state.items
            .map((speciality) => speciality.uuid)
            .join(','),
        },
      });
      teamSpecialitiesGrades.load().subscribe(() => {
        const teamMembershipData = {
          team: this.teamUuid,
          user: this._authService.get_current_user_uuid(),
          role: 'member',
        };
        if (teamSpecialities.state.items.length > 0) {
          this._dialog.open(TeamJoinDialogComponent, {
            data: {
              specialities: teamSpecialities.state.items,
              specialitiesGrades: teamSpecialitiesGrades.state.items,
              onSubmit: (formData: UserTeamSpecialityData) => {
                this._teamMembershipHttpService
                  .createWithSpecialities({
                    teamMembership: teamMembershipData,
                    specialitiesData: [
                      {
                        speciality_uuid: formData.speciality.uuid,
                        speciality_grade_uuid: formData.specialityGrade.uuid,
                      },
                    ],
                  })
                  .subscribe((response) => {
                    this._appService.load_user_memberships();
                    this._userService.get_edit_teams().subscribe((response) => {
                      this._loggingService.debug(
                        `${this.constructor.name} fetched user edit teams`
                      );
                      this._authService.set_user_edit_teams(response);
                    });
                    this._loadTeamMembers();
                  });
              },
            },
          });
        } else {
          this._teamMembershipHttpService
            .create({ ...teamMembershipData })
            .subscribe(() => {
              this._appService.load_user_memberships();
              this._loadTeamMembers();
            });
        }
      });
    });
  }

  createTeamJoinRequest(): void {
    this._dialog
      .open(TeamJoinRequestDialogComponent, {
        data: {
          teamUuid: this.teamUuid,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this._loadSelfJoinRequest();
      });
  }

  getUserSpecialitiesCount(userUuid: string): number {
    return this.usersSpecialitiesMap[userUuid]?.length;
  }

  get isUserTeamOwner(): boolean {
    return !!this.teamMembers.state.items.find(
      (member) =>
        (member.user as User).uuid ===
          this._authService.get_current_user_uuid() && member.role === 'owner'
    );
  }

  get isUserAdmin(): boolean {
    return this._authService.is_admin();
  }

  getMemberRoleName(role: string): string {
    let name = '';
    switch (role) {
      case 'member':
        name = 'Участник';
        break;
      case 'owner':
        name = 'Руководитель';
        break;
      case 'lead':
        name = 'Лид';
        break;
    }
    return name;
  }

  onTeamManage(): void {
    this._router.navigate([`team/${this.teamUuid}/members`]);
  }

  onBackClick(): void {
    history.back();
  }

  onMemberClick(member: TeamMembership): void {
    this._router.navigate([
      `account/user-profile/${(member.user as User).uuid}`,
    ]);
  }
}
