import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  private log_prefix = 'TitleService:';

  private app_name: string;

  constructor(
    private title_service: Title,
    private logging_service: LoggingService
  ) { }

  public set_app_name(app_name: string) {
    this.app_name = app_name;
  }

  public set_title(component_title: string): void {
    let title_text;
    if (!component_title) {
      title_text = `${this.app_name}`;
    } else {
      title_text = `${component_title} | ${this.app_name}`;
    }
    this.logging_service.debug(`${this.log_prefix} setting page title to '${title_text}'`);
    this.title_service.setTitle(title_text);
  }

  public reset_title(): void {
    this.set_title(null);
  }

}
