import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService
  ) {}

  public fetch_all(): Observable<any> {
    return this.api_service.get(`surveys/survey`, {}).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} surveys`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch surveys`
          );
        }
      )
    );
  }

  public fetch_by_uuid(survey_uuid: string): Observable<any> {
    return this.api_service.get(`surveys/survey/${survey_uuid}`, {}).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched survey with id ${survey_uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch survey`
          );
        }
      )
    );
  }

  public create(survey_data: any): Observable<any> {
    return this.api_service.post(`surveys/survey`, survey_data, {}).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} created survey with uuid ${response.uuid} and name ${response.name}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to create survey`
          );
        }
      )
    );
  }

  public delete(survey_uuid: string): Observable<any> {
    return this.api_service.delete(`surveys/survey/${survey_uuid}`, {}).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} deleted survey with uuid ${survey_uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to delete survey`
          );
        }
      )
    );
  }

  public update(survey_uuid: string, survey_data: any): Observable<any> {
    return this.api_service
      .patch(`surveys/survey/${survey_uuid}`, survey_data, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} updated survey with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to update survey`
            );
          }
        )
      );
  }
}
