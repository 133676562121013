import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTreeModule } from '@angular/material/tree';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiComponents } from '@components/ui/ui.module';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonComponentsModule } from '@components/common/common.module';
import { RouterModule } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NewReviewDetailComponent } from './components/new-review-detail/new-review-detail.component';
import { NewReviewResolutionDetailComponent } from './components/new-review-resolution-detail/new-review-resolution-detail.component';
import { NewReviewResultDetailComponent } from './components/new-review-result-detail/new-review-result-detail.component';
import { NewReviewAssessmentDetailComponent } from './components/new-review-assessment-detail/new-review-assessment-detail.component';
import { NewReviewEditComponent } from './components/new-review-edit/new-review-edit.component';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  declarations: [
    NewReviewDetailComponent,
    NewReviewResolutionDetailComponent,
    NewReviewResultDetailComponent,
    NewReviewAssessmentDetailComponent,
    NewReviewEditComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatTreeModule,
    MatInputModule,
    MatDialogModule,
    NgSelectModule,
    FlexLayoutModule,
    MatButtonModule,
    ReactiveFormsModule,
    UiComponents,
    MatCardModule,
    MatCheckboxModule,
    FormsModule,
    MatGridListModule,
    MatProgressBarModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    CommonComponentsModule,
    RouterModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatMenuModule,
    MatTooltipModule,
    MatDatepickerModule,
  ],
  exports: [NewReviewDetailComponent],
})
export class ReviewModule {}
