import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { LoggingService } from 'src/app/services/logging.service';
import { DomainService } from 'src/app/services/ontology/domain.service';
import { TitleService } from 'src/app/services/title.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';

import { Domain } from 'src/app/models/ontology/domain';

@Component({
  selector: 'app-domain-create',
  templateUrl: './domain-create.component.html',
  styleUrls: ['./domain-create.component.css']
})
export class DomainCreateComponent implements OnInit {

  domain_create_form: UntypedFormGroup;

  uistate = {
    domain_create_form_submitted: false,
    domain_create_form_loading: false,
  };

  constructor(
    private router: Router,
    private form_builder: UntypedFormBuilder,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private title_service: TitleService,
    private domain_service: DomainService,
  ) {
    this.create_form();
  }

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.title_service.set_title('Новый домен');
  }

  public get is_data_loaded(): boolean {
    return true;
  }

  private create_form() {
    this.domain_create_form = this.form_builder.group({
      domain_name: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(128)
      ]],
      domain_description: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1024)
      ]],
    });
  }

  public on_create(): void {
    this.uistate.domain_create_form_submitted = true;
    this.uistate.domain_create_form_loading = true;
    this.logging_service.debug(`${this.constructor.name} creating domain...`);
    const domain_data = {
      name: this.domain_create_form.value.domain_name,
      description: this.domain_create_form.value.domain_description,
    };
    this.domain_service.create(domain_data)
      .pipe(first())
      .subscribe({
          next: (response_domain) => {
            const domain = response_domain as Domain;
            this.logging_service.debug(`${this.constructor.name} successfully created domain`);
            this.alert_service.success('Домен создан', { keepAfterRouteChange: true });
            // this.router.navigate([`/admin/teams/${team.id}/edit`]);
            this.router.navigate([`/admin/ontologies/domains/${domain.uuid}`]);
          },
          error: error => {
            this.logging_service.debug(`${this.constructor.name} domain creation failed`);
            this.alert_service.error('Ошибка создания домена');
            this.uistate.domain_create_form_loading = false;
          }
      });
  }

}
