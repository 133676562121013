import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  GetListRequest,
  GetListResponse,
  ViewSetHttpService,
} from './rest/ViewSetHttpService';
import { Speciality } from 'src/app/models/specialities/speciality';
import { Observable } from 'rxjs';
import { ChildSpecialitiesUpdateConfig } from '../../../modules/library/speciality/speciality-detail/speciality-detail.component';

@Injectable({
  providedIn: 'root',
})
export class SpecialityHttpService extends ViewSetHttpService<Speciality> {
  http: HttpClient;

  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'specialities/speciality';

  list(params: GetListRequest = {}): Observable<GetListResponse<Speciality>> {
    if (!params.params.is_hidden) params.params.is_hidden = false;
    return super.list(params);
  }

  public getChildSpecialities(specialityUuid: string): Observable<any> {
    return this.http.get<string[]>(
      `${this.getUrl()}/${specialityUuid}/get_child_specialities`,
      {
        responseType: 'json',
      }
    );
  }

  public getChildSpecialitiesDiff(specialityUuid: string): Observable<any> {
    return this.http.get<string[]>(
      `${this.getUrl()}/${specialityUuid}/get_child_specialities_diff`,
      {
        responseType: 'json',
      }
    );
  }

  public getSpecialitiesThings(specialities: string[]): Observable<any> {
    return this.http.post<string[]>(
      `${this.getUrl()}/get_specialities_things`,
      {
        responseType: 'json',
        specialities: specialities,
      }
    );
  }

  public updateChildSpecialities(
    librarySpecialityUuid: string,
    specialities_uuid: string[],
    update_config: ChildSpecialitiesUpdateConfig
  ): Observable<any> {
    return this.http.post<string[]>(
      `${this.getUrl()}/${librarySpecialityUuid}/update_child_specialities`,
      {
        update_config,
        update_specialities: specialities_uuid,
      }
    );
  }

  public getTeamsSpecialities(teams: string[]): Observable<any> {
    return this.http.post<string[]>(`${this.getUrl()}/get_teams_specialities`, {
      teams,
    });
  }
}
