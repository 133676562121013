<ng-container *ngIf='is_data_loaded; then component_content; else preloader'>
</ng-container>

<ng-template #preloader>
  <app-preloader-progressbar></app-preloader-progressbar>
</ng-template>

<ng-template #component_content>
  <div class='app-track-pdp-details-container'>

    <ng-container [ngTemplateOutlet]='is_header_in_edit_mode ? tmpl_header_edit : tmpl_header'>
    </ng-container>

    <br><br>

    <div fxLayout='row'>
      <div fxFlex>
        <div *ngFor='let activity of activities' class='app-track-pdp-details-activity-listitem-container'>
          <div fxLayout='row'>
            <div fxFlex='20rem'>
              <ng-container
                [ngTemplateOutlet]='currently_editing_activity && currently_editing_activity.uuid === activity.uuid ? tmpl_activity_edit_form : tmpl_activity_listitem'
                [ngTemplateOutletContext]=' {activity:activity} '>
              </ng-container>
            </div>
            <div fxFlex fxFlexOffset='2rem'>
              <ng-container
                [ngTemplateOutlet]='tmpl_activity_steps_list'
                [ngTemplateOutletContext]=' {activity:activity} '>
              </ng-container>
              <ng-container
                [ngTemplateOutlet]='is_activity_open_step_form(activity) ? tmpl_activity_create_step_form : tmpl_activity_step_create_button'
                [ngTemplateOutletContext]=' {activity:activity} '>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout='row'>
      <div fxFlex>
        <div *ngIf='is_can_create_activity'>
          <ng-container
            *ngIf='is_create_activity_form_shown; then tmpl_create_activity_form; else tmpl_create_activity_button'>
          </ng-container>
        </div>
      </div>
    </div>

  </div>
</ng-template>


<ng-template #tmpl_header>
  <div fxLayout='row'>
    <div fxFlex fxLayoutAlign='start center'>
      <div class='app-track-pdp-details-header-title'>
        <div *ngIf='is_user_self_subject'>
          <div fxLayout='row'>
            <div fxFlex='3rem' fxLayoutAlign='start center'>
              <mat-icon style='color: #999;'>emoji_events</mat-icon>
            </div>
            <div fxFlex='10rem' fxLayoutAlign='center center'>
              Ваш план развития
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex fxLayoutAlign='end center'>
      <div class='app-track-pdp-details-header-controls'>
        <div *ngIf='is_user_self_subject || is_user_self_responsible'>
          <button mat-button *ngIf='!pdp.is_locked' style='font-size: 0.9rem;'
                  [disabled]='pdp.is_locked'
                  (click)='on_edit_header()'>
            <mat-icon>edit</mat-icon>
          </button>
          &nbsp;
          <button mat-button *ngIf='is_user_self_responsible && !pdp.is_locked' style='font-size: 0.9rem;'
                  [disabled]='is_header_lock_diabled'
                  (click)='on_button_toggle_locked()'>
            <mat-icon>lock</mat-icon>
            Зафиксировать
          </button>
          <button mat-button *ngIf='is_user_self_responsible && pdp.is_locked' style='font-size: 0.9rem;'
                  [disabled]='is_header_lock_diabled'
                  (click)='on_button_toggle_locked()'>
            <mat-icon>lock_open</mat-icon>
            Разблокировать
          </button>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout='row'>
    <div fxFlex>
      <div class='app-track-pdp-details-header-objective'>
                <span class='app-track-pdp-details-header-objective-prefix'>
                    Цель:
                </span>
        {{ pdp.objective }}
      </div>
      <div class='app-track-pdp-details-header-value'>
        <div class='app-track-pdp-details-header-value-prefix'>
          Польза для себя:
        </div>
        {{ pdp.personal_value }}
      </div>
      <div *ngIf='is_header_accomplishment_indicator_shown'
           class='app-track-pdp-details-header-accomplishment-indicator'>
        <div class='app-track-pdp-details-header-accomplishment-indicator-prefix'>
          Достигнута ли цель?
        </div>
        <div style='margin-bottom: 0.5rem;'>
          {{ pdp.accomplishment_indicator }}
        </div>
        <div>
          <button mat-stroked-button (click)="set_pdp_status('completed')">
            Да, цель достигнута
          </button>
          &nbsp;
          &nbsp;
          Если нет, добавьте недостающие шаги
        </div>
      </div>
    </div>
    <div fxFlex='20rem' fxFlexOffset='5rem'>

      <div class='app-track-pdp-details-header-progress'>
        <div fxLayout='row' fxLayoutAlign='center start'>
          <div fxFlex='7rem'>
            <div class='app-track-pdp-details-header-progress-spinner-container'>
              <mat-spinner class='app-track-pdp-details-header-progress-spinner-track'
                           mode='determinate' value='100'
                           [diameter]='70'
                           [strokeWidth]='2'>
              </mat-spinner>
              <mat-spinner class='app-track-pdp-details-header-progress-spinner-value'
                           mode='determinate' [value]='overall_progress'
                           [diameter]='70'
                           [strokeWidth]='2'>
              </mat-spinner>
            </div>
          </div>
          <div fxFlex='7rem'>
            <div class='app-track-pdp-details-header-progress-numeric-value'>
              {{ overall_progress }}%
            </div>
          </div>
        </div>
      </div>

      <div class='app-track-pdp-details-header-duedate'>
                <span class='app-track-pdp-details-header-duedate-prefix'>
                    Планирую закончить к
                </span>
        {{ pdp.due_date | amDateFormat: 'DD.MM.YYYY' }}
      </div>

      <div class='app-track-pdp-details-header-responsible-user'>
        <div class='app-track-pdp-details-header-responsible-user-prefix'>
          Помогает в достижении цели
        </div>
        <app-user-inline [user]='pdp.responsible_user'></app-user-inline>
      </div>

    </div>
  </div>
</ng-template>

<ng-template #tmpl_header_edit>
  <div fxLayout='row'>
    <div fxFlex>
      <form [formGroup]='pdp_edit_form'>

        <div fxLayout='row'>
          <div fxFlex='50%'>
            <mat-form-field [style.width.rem]=30>
              <mat-label>Ваша цель</mat-label>
              <input #form_input_objective matInput formControlName='objective'
                     type='text' placeholder='Прокачать навыки найма'>
              <mat-hint>{{ 128 - form_input_objective.value.length }}/128</mat-hint>
            </mat-form-field>
          </div>
          <div fxFlex='40%' fxFlexOffset='10%'>
            <div class='app-track-pdp-details-edit-form-input-description'>
              Коротко и ясно, чего вы хотите добиться?
            </div>
          </div>
        </div>

        <br>

        <div fxLayout='row'>
          <div fxFlex='50%'>
            <mat-form-field [style.width.rem]=30>
              <mat-label>Как я пойму, что цель достигнута?</mat-label>
              <textarea #form_input_accomplishment_indicator matInput formControlName='accomplishment_indicator'
                        type='text'
                        placeholder='Малый отток после испыталки и хорошая обратная связь от кандидатов'></textarea>
              <mat-hint>{{ 1024 - form_input_accomplishment_indicator.value.length }}/1024</mat-hint>
            </mat-form-field>
          </div>
          <div fxFlex='40%' fxFlexOffset='10%'>
            <div class='app-track-pdp-details-edit-form-input-description'>
              Какой-либо измеримый результат, который вы сможете предоставить на ревью коллегам
            </div>
          </div>
        </div>

        <br>

        <div fxLayout='row'>
          <div fxFlex='50%'>
            <mat-form-field [style.width.rem]=30>
              <mat-label>Как достижение цели поможет тебе лично?</mat-label>
              <textarea #form_input_personal_value matInput formControlName='personal_value'
                        type='text' placeholder='Прокачаю soft-skills, продвинусь в сторону лида'></textarea>
              <mat-hint>{{ 1024 - form_input_personal_value.value.length }}/1024</mat-hint>
            </mat-form-field>
          </div>
          <div fxFlex='40%' fxFlexOffset='10%'>
            <div class='app-track-pdp-details-edit-form-input-description'>
              Персональные интересы, которые хочется реализовать
            </div>
          </div>
        </div>

        <br>

        <div fxLayout='row'>
          <div fxFlex='50%'>
            <mat-form-field [style.width.rem]=30>
              <mat-label>Как достижение цели поможет компании?</mat-label>
              <textarea #form_input_organisation_value matInput formControlName='organisation_value'
                        type='text'
                        placeholder='Наша команда станет сильнее и сбалансированнее, будем быстрее делать задачи'></textarea>
              <mat-hint>{{ 1024 - form_input_organisation_value.value.length }}/1024</mat-hint>
            </mat-form-field>
          </div>
          <div fxFlex='40%' fxFlexOffset='10%'>
            <div class='app-track-pdp-details-edit-form-input-description'>
              Измеримая польза, которую команда и компания получит при достижении цели
            </div>
          </div>
        </div>

        <br>

        <div fxLayout='row'>
          <div fxFlex='50%'>
            <div fxLayout='row'>
              <div fxFlex='45%'>
                <mat-form-field>
                  <mat-label>Дата достижения цели</mat-label>
                  <input #form_input_due_date matInput formControlName='due_date'
                         [matDatepicker]='form_input_due_date_picker'
                         [min]='pdp_edit_form_input_due_date_min' [max]='pdp_edit_form_input_due_date_max'
                         placeholder='ДД.ММ.ГГГГ'>
                  <mat-datepicker-toggle matSuffix [for]='form_input_due_date_picker'></mat-datepicker-toggle>
                  <mat-datepicker #form_input_due_date_picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div fxFlex='45%' fxFlexOffset='10%'>
                                <span class='app-track-pdp-details-edit-form-input-due-date-shortcut'
                                      (click)='on_set_due_date(7 * 24 * 60 * 60 * 1000)'>
                                    неделя,
                                </span>
                &nbsp;
                <span class='app-track-pdp-details-edit-form-input-due-date-shortcut'
                      (click)='on_set_due_date(2 * 7 * 24 * 60 * 60 * 1000)'>
                                    две недели,
                                </span>
                &nbsp;
                <span class='app-track-pdp-details-edit-form-input-due-date-shortcut'
                      (click)='on_set_due_date(30 * 24 * 60 * 60 * 1000)'>
                                    месяц,
                                </span>
                <br>
                <span class='app-track-pdp-details-edit-form-input-due-date-shortcut'
                      (click)='on_set_due_date(2 * 30 * 24 * 60 * 60 * 1000)'>
                                    два месяца,
                                </span>
                &nbsp;
                <span class='app-track-pdp-details-edit-form-input-due-date-shortcut'
                      (click)='on_set_due_date(4 * 30 * 24 * 60 * 60 * 1000)'>
                                    квартал
                                </span>
              </div>
            </div>
          </div>
          <div fxFlex='40%' fxFlexOffset='10%'>
            <div class='app-track-pdp-details-edit-form-input-description'>
            </div>
          </div>
        </div>

        <br>

        <div fxLayout='row'>
          <div fxFlex='50%'>
            <ng-container
              *ngIf='responsible_user_search_selected_user; then tmpl_responsible_user_selected; else tmpl_responsible_user_search'>
            </ng-container>
            <ng-template #tmpl_responsible_user_search>
              <mat-form-field [style.width.rem]=30>
                <mat-label>Поиск пользователей</mat-label>
                <input #form_input_responsible_user matInput
                       type='text'
                       placeholder='Имя или email'
                       [formControl]='responsible_user_search_form_control'
                       [matAutocomplete]='auto'
                       (input)='on_responsible_user_search(form_input_responsible_user.value)'>
                <mat-autocomplete #auto='matAutocomplete'
                                  [displayWith]='util_responsible_user_search_display'
                                  (optionSelected)='on_responsible_user_search_autocomplete_selected($event.option.value)'>
                  <mat-option *ngFor='let user of responsible_user_search_results'
                              [value]='user' [disabled]='pdp.subject_user.uuid === user.uuid'>
                    {{ user.first_name }} {{ user.last_name }} – <small>{{ user.email }}</small>
                  </mat-option>
                </mat-autocomplete>
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>

              <div class='app-track-pdp-details-edit-superiors-container'>
                                <span *ngFor='let superior_user of superiors'
                                      class='app-track-pdp-details-edit-superiors-listitem'
                                      (click)='on_set_responsible_user(superior_user)'>
                                    {{ superior_user.first_name }} {{ superior_user.last_name }}
                                </span>
              </div>

            </ng-template>
            <ng-template #tmpl_responsible_user_selected>
              <div class='app-track-pdp-details-edit-user-container'>
                <div fxLayout='row'>
                  <div fxFlex='5rem'>
                    <div class='app-track-pdp-details-edit-user-avatar'>
                      {{ util_member_get_initials_for_avatar(responsible_user_search_selected_user) }}
                    </div>
                  </div>
                  <div fxFlex='23rem'>
                    <div class='app-track-pdp-details-edit-user-name'>
                      {{ responsible_user_search_selected_user.first_name }} {{ responsible_user_search_selected_user.last_name }}
                    </div>
                    <div class='app-track-pdp-details-edit-user-email'>
                      {{ responsible_user_search_selected_user.email }}
                    </div>
                  </div>
                  <div fxFlex='3rem'>
                    <button mat-icon-button (click)='on_responsible_user_clear()'>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <div fxFlex='40%' fxFlexOffset='10%'>
            <div class='app-track-pdp-details-edit-form-input-description'>
              Кто будет помогать и направлять на пути к цели
            </div>
          </div>
        </div>

        <br><br>

        <button mat-raised-button type='button'
                [disabled]='pdp_edit_form.invalid || is_header_edit_form_loading'
                (click)='on_pdp_edit_form_submit()'>
          Сохранить
        </button>

        &nbsp;
        &nbsp;

        <button mat-raised-button type='button'
                (click)='on_edit_header_cancel()'>
          Отмена
        </button>

      </form>

    </div>
  </div>
</ng-template>

<ng-template #tmpl_create_activity_button>
  <div *ngIf='!activity_with_step_form && !pdp.is_locked' class='app-track-pdp-details-activity-create-button'
       (click)='on_button_create_activity()'>
    <div fxLayout='row' fxLayoutAlign='center center'>
      <div fxFlex='2rem'>
        <div class='app-track-pdp-details-activity-create-button-icon'>
          <mat-icon>add</mat-icon>
        </div>
      </div>
      <div fxFlex='15rem'>
        <div class='app-track-pdp-details-activity-create-button-text'>
          Добавить активность
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_create_activity_form>
  <div fxLayout='row'>
    <div fxFlex>

      <form [formGroup]='activity_form'>
        <div fxLayout='row'>
          <div fxFlex='25rem'>
            <mat-form-field [style.width.rem]=20>
              <mat-label>Направление деятельности</mat-label>
              <input #form_activity_input_name matInput formControlName='activity_name'
                     type='text' placeholder='Интервью кандидатов'>
              <mat-hint>{{ 128 - form_activity_input_name.value.length }}/128</mat-hint>
            </mat-form-field>

            <br><br>

            <button mat-raised-button
                    [disabled]='activity_form.invalid || is_form_activity_loading'
                    (click)='on_activity_form_submit()'>
              Продолжить
            </button>

            &nbsp;
            &nbsp;

            <button mat-raised-button (click)='on_activity_form_cancel()'>
              Отмена
            </button>

          </div>
          <div fxFlex fxFlexOffset='5rem'>
            <div class='app-track-pdp-details-activity-create-form-input-description'>
              <p>
                Назовите область, в которой вы собираетесь развиваться.
              </p>
              <p>
                Для каждого направления деятельности может быть ряд конкретных результатов
                и связанных компетенций, например:
              </p>
              Направление деятельности: Интервью кандидатов
              <ul>
                <li>Изучены записи примеров интервью</li>
                <li>Составлен список вопросов для кандидата</li>
                <li>Подготовлено письмо с рекомендациями для кандидата</li>
                <li>Проведено десять интервью</li>
                <li>Собрана и проанализирована обратная связь</li>
              </ul>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</ng-template>

<ng-template #tmpl_activity_listitem let-activity='activity'>
  <div class='app-track-pdp-details-activity-listitem-name'>
    {{ activity.name }}
  </div>
  <div class='app-track-pdp-details-activity-listitem-controls'>
    <button mat-icon-button *ngIf='!pdp.is_locked'
            (click)='on_button_activity_edit(activity)'>
      <mat-icon class='app-track-pdp-details-activity-listitem-controls-edit'>
        edit
      </mat-icon>
    </button>
    <button mat-icon-button *ngIf='!pdp.is_locked'
            (click)='on_button_activity_delete(activity)'
            [disabled]='is_button_activity_delete_disabled'>
      <mat-icon class='app-track-pdp-details-activity-listitem-controls-delete'>
        delete
      </mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #tmpl_activity_edit_form let-activity='activity'>
  <form [formGroup]='activity_form'>
    <div fxLayout='row'>
      <div fxFlex='25rem'>
        <mat-form-field [style.width.rem]=20>
          <mat-label>Направление деятельности</mat-label>
          <input #form_activity_input_name matInput formControlName='activity_name'
                 type='text' placeholder='Интервью кандидатов'>
          <mat-hint>{{ 128 - form_activity_input_name.value.length }}/128</mat-hint>
        </mat-form-field>

        <br><br>

        <button mat-raised-button type='button'
                [disabled]='activity_form.invalid || is_form_activity_loading'
                (click)='on_button_activity_edit_submit()'>
          Сохранить
        </button>

        &nbsp;
        &nbsp;

        <button mat-raised-button type='button' (click)='on_button_activity_edit_cancel()'>
          Отмена
        </button>

      </div>
    </div>
  </form>
</ng-template>

<ng-template #tmpl_activity_create_step_form let-activity='activity'>
  <div fxLayout='row'>
    <div fxFlex>
      <div class='app-track-pdp-details-activity-listitem-step-create-form'>

        <form [formGroup]='step_form'>
          <div fxLayout='row'>
            <div fxFlex='100%'>
              <mat-form-field [style.width.rem]=25>
                <mat-label>Что ожидаете получить?</mat-label>
                <input #form_step_input_name matInput formControlName='step_name'
                       type='text' placeholder='Подготовлена сравнительная таблица'>
                <mat-hint>{{ 128 - form_step_input_name.value.length }}/128</mat-hint>
              </mat-form-field>

              <br><br>

              <mat-form-field [style.width.rem]=25>
                <mat-label>Связать результат с компетенцией</mat-label>
                <input matInput type='text'
                       placeholder='Ansible'
                       [formControl]='thing_search_form_control'
                       [matAutocomplete]='auto'
                       (input)='on_thing_search(thing_search_form_control.value)'>
                <mat-autocomplete #auto='matAutocomplete'
                                  (optionSelected)='on_thing_search_autocomplete_selected($event.option.value)'>
                  <mat-option *ngFor='let thing of thing_search_results' [value]='thing' class='multiline-mat-option'>
                    <div class='option-name'><b>{{ thing.name }}</b></div>
                    <div class='option-meta'>{{ get_thing_domain_name(thing) }}</div>
                  </mat-option>
                </mat-autocomplete>
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>

              <br>

              <div class='app-track-pdp-details-activity-listitem-step-create-related-things-container'>
                <div *ngFor='let thing of step_things_selected'>
                  <div class='app-track-pdp-details-activity-listitem-step-create-related-things-listitem'>
                    <div fxLayout='row' fxLayoutAlign='start center'>
                      <div fxFlex='3rem'>
                        <mat-icon style='color: #999;'>
                          school
                        </mat-icon>
                      </div>
                      <div fxFlex>
                        {{ thing.name }}
                      </div>
                      <div fxFlex='3rem'>
                        <button mat-icon-button (click)='on_button_remove_related_thing(thing)'>
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br><br>

              <button mat-raised-button
                      [disabled]='step_form.invalid || is_form_step_loading'
                      (click)='on_step_form_submit()'>
                Сохранить
              </button>

              &nbsp;
              &nbsp;

              <button mat-raised-button (click)='on_step_form_cancel()'>
                Отмена
              </button>

            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_activity_steps_list let-activity='activity'>
  <div fxLayout='row'>
    <div fxFlex>
      <div *ngIf='!activity.steps.length'>
        <div class='app-track-pdp-details-activity-listitem-steps-empty'>
          Для активности пока не определено результатов.
          <br>
          Добавьте конкретный и измеримый результат этой автивности
        </div>
      </div>
      <div *ngIf='activity.steps.length'>
        <div *ngFor='let step of activity.steps'>
          <ng-container
            [ngTemplateOutlet]='currently_editing_step && currently_editing_step.uuid === step.uuid ? tmpl_activity_step_listitem_edit : tmpl_activity_step_listitem'
            [ngTemplateOutletContext]=' {step:step} '>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_activity_step_listitem let-step='step'>
  <div class='app-track-pdp-details-activity-listitem-step-listitem-container'>
    <div fxLayout='row' fxLayoutAlign='start center'>
      <div fxFlex='3rem'>
        <mat-checkbox
          [disabled]='is_step_completed_checkbox_disabled'
          [checked]='step.is_completed'
          (click)='on_toggle_step_completed(step, $event)'>
        </mat-checkbox>
      </div>
      <div fxFlex>
        <div class='app-track-pdp-details-activity-listitem-step-listitem-name'>
          {{ step.name }}
        </div>
        <div class='app-track-pdp-details-activity-listitem-step-listitem-thing-relations-container'>
          <div *ngFor='let thing_relation of step.thing_relations'>
            <div class='app-track-pdp-details-activity-listitem-step-listitem-thing-relation-listitem'>
              <div fxLayout='row' fxLayoutAlign='start center'>
                <div fxFlex='2rem'>
                  <mat-icon style='color: #999; font-size: 1.2rem; padding-top: 0.2rem'>
                    school
                  </mat-icon>
                </div>
                <div fxFlex>
                  {{ thing_relation.thing.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex='5rem' fxFlexOffset='1rem'>
        <button mat-icon-button *ngIf='!pdp.is_locked' (click)='on_button_step_edit(step)'>
          <mat-icon class='app-track-pdp-details-activity-step-listitem-button-edit'>
            edit
          </mat-icon>
        </button>
        <button mat-icon-button *ngIf='!pdp.is_locked' (click)='on_button_delete_step(step)'>
          <mat-icon class='app-track-pdp-details-activity-step-listitem-button-delete'>
            delete
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_activity_step_listitem_edit let-step='step'>
  <div fxLayout='row'>
    <div fxFlex>
      <div class='app-track-pdp-details-activity-listitem-step-create-form'>

        <form [formGroup]='step_form'>
          <div fxLayout='row'>
            <div fxFlex='100%'>
              <mat-form-field [style.width.rem]=25>
                <mat-label>Что ожидаете получить?</mat-label>
                <input #form_step_input_name matInput formControlName='step_name'
                       type='text' placeholder='Подготовлена сравнительная таблица'>
                <mat-hint>{{ 128 - form_step_input_name.value.length }}/128</mat-hint>
              </mat-form-field>

              <br><br>

              <mat-form-field [style.width.rem]=25>
                <mat-label>Связать результат с компетенцией</mat-label>
                <input matInput type='text'
                       placeholder='Ansible'
                       [formControl]='thing_search_form_control'
                       [matAutocomplete]='auto'
                       (input)='on_thing_search(thing_search_form_control.value)'>
                <mat-autocomplete #auto='matAutocomplete'
                                  (optionSelected)='on_thing_search_autocomplete_selected($event.option.value)'>
                  <mat-option *ngFor='let thing of thing_search_results' [value]='thing'>
                    <strong>{{ thing.name }}</strong>
                    &nbsp;&nbsp;
                    <small>( {{ get_thing_domain_name(thing) }} )</small>
                  </mat-option>
                </mat-autocomplete>
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>

              <br>

              <div class='app-track-pdp-details-activity-listitem-step-create-related-things-container'>
                <div *ngFor='let thing of step_things_selected'>
                  <div class='app-track-pdp-details-activity-listitem-step-create-related-things-listitem'>
                    <div fxLayout='row' fxLayoutAlign='start center'>
                      <div fxFlex='3rem'>
                        <mat-icon style='color: #999;'>
                          school
                        </mat-icon>
                      </div>
                      <div fxFlex>
                        {{ thing.name }}
                      </div>
                      <div fxFlex='3rem'>
                        <button mat-icon-button (click)='on_button_remove_related_thing(thing)'>
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br><br>

              <button mat-raised-button
                      [disabled]='step_form.invalid || is_form_step_loading'
                      (click)='on_button_step_edit_submit()'>
                Сохранить
              </button>

              &nbsp;
              &nbsp;

              <button mat-raised-button (click)='on_button_step_edit_cancel()'>
                Отмена
              </button>

            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_activity_step_create_button let-activity='activity'>
  <div fxLayout='row' *ngIf='!pdp.is_locked'>
    <div fxFlex>
      <div class='app-track-pdp-details-activity-create-step-button' (click)='on_button_create_step(activity)'>
        <div fxLayout='row' fxLayoutAlign='center center'>
          <div fxFlex='2rem'>
            <div class='app-track-pdp-details-activity-create-step-button-icon'>
              <mat-icon>add</mat-icon>
            </div>
          </div>
          <div fxFlex='10rem'>
            <div class='app-track-pdp-details-activity-create-step-button-text'>
              Добавить результат
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
