import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AsyncList } from '@rest/AsyncList';
import { ThingHttpService } from '@services/http/ThingHttpService';
import { Thing } from '@models/ontology/thing';

@Component({
  selector: 'app-match-users',
  templateUrl: './match-users.page.html',
  styleUrls: ['./match-users.page.css'],
})
export class MatchUsersPage implements OnInit {
  things: AsyncList<Thing>;
  teamUuid = '';

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _thingHttpService: ThingHttpService<Thing>
  ) {}

  ngOnInit(): void {
    this.teamUuid = this._activatedRoute.snapshot.params.uuid;
  }
}
