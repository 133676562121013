import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';

import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatTreeModule } from '@angular/material/tree';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { CommonUIElementsModule } from 'src/app/modules/common-ui-elements/common-ui-elements.module';
import { CommonD3ChartsModule } from 'src/app/modules/common-d3-charts/common-d3-charts.module';
import { UiComponents } from '@components/ui/ui.module';

import { OntologiesModule } from 'src/app/modules/ontologies/ontologies.module';
import { TrackModule } from 'src/app/modules/track/track.module';

import { TeamsRoutingModule } from './teams-routing.module';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { GetUserInitials } from '../../utils/template-filters/get-user-initials';
import { TeamManageThingLevelsComponent } from 'src/app/modules/teams/components/team-manage/thing-level-templates/thing-level-templates.component';
import { ReviewModule } from '../review/review.module';
import { CommonComponentsModule } from '@components/common/common.module';
import { TeamMatchUsersComponent } from './components/team-match-users/team-match-users.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatchUsersThingComponent } from './components/team-match-users/match-users-thing/match-users-thing.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatchUserCompetenciesComponent } from './components/team-match-users/match-user-competencies/match-user-competencies.component';
import { TeamManageMatrixV3Component } from './components/team-manage/team-manage-matrix-v3/team-manage-matrix-v3.component';
import { TeamMainComponent } from './components/team-main/team-main.component';
import { TeamSpecialitiesComponent } from './components/team-main/team-specialities/team-specialities.component';
import { AddLibrarySpecialityDialogComponent } from './components/team-main/team-specialities/add-library-speciality-dialog/add-library-speciality-dialog.component';
import { TeamMembersComponent } from './components/team-main/team-members/team-members.component';
import { AddTeamMemberDialogComponent } from './components/team-main/team-members/add-team-member-dialog/add-team-member-dialog.component';
import { TeamSpecialityDetailComponent } from './components/team-main/team-specialities/team-speciality-detail/team-speciality-detail.component';
import { AddCompetenceFromLibraryDialogComponent } from './components/team-main/team-specialities/team-speciality-detail/add-competence-from-library-dialog/add-competence-from-library-dialog.component';
import { TeamReviewsComponent } from './components/team-main/team-reviews/team-reviews.component';
import { TeamReviewCreateComponent } from './components/team-main/team-reviews/team-review-create/team-review-create.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ReviewCreateCompetenciesEditDialogComponent } from './components/team-main/team-reviews/team-review-create/review-create-competencies-edit-dialog/review-create-competencies-edit-dialog.component';
import { ReviewCreateReviewersEditDialogComponent } from './components/team-main/team-reviews/team-review-create/review-create-reviewers-edit-dialog/review-create-reviewers-edit-dialog.component';
import { ReviewCreateResponsibleEditDialogComponent } from './components/team-main/team-reviews/team-review-create/review-create-responsible-edit-dialog/review-create-responsible-edit-dialog.component';
import { TeamDesiresComponent } from './components/team-main/team-desires/team-desires.component';
import { AddLibraryCompetenceComponent } from './components/team-main/team-specialities/team-speciality-detail/add-competence-from-library-dialog/add-library-competence/add-library-competence.component';
import { EditTeamMemberDialogComponent } from './components/team-main/team-members/edit-team-member-dialog/edit-team-member-dialog.component';
import { TeamsDashboardNewComponent } from './components/teams-dashboard-new/teams-dashboard-new.component';
import { TeamsDashboardNewDetailComponent } from './components/teams-dashboard-new/teams-dashboard-new-detail/teams-dashboard-new-detail.component';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    GetUserInitials,
    TeamManageThingLevelsComponent,
    TeamMatchUsersComponent,
    MatchUsersThingComponent,
    MatchUserCompetenciesComponent,
    TeamManageMatrixV3Component,
    TeamMainComponent,
    TeamSpecialitiesComponent,
    AddLibrarySpecialityDialogComponent,
    TeamMembersComponent,
    AddTeamMemberDialogComponent,
    TeamSpecialityDetailComponent,
    AddCompetenceFromLibraryDialogComponent,
    TeamReviewsComponent,
    TeamReviewCreateComponent,
    ReviewCreateCompetenciesEditDialogComponent,
    ReviewCreateReviewersEditDialogComponent,
    ReviewCreateResponsibleEditDialogComponent,
    TeamDesiresComponent,
    AddLibraryCompetenceComponent,
    EditTeamMemberDialogComponent,
    TeamsDashboardNewComponent,
    TeamsDashboardNewDetailComponent,
  ],
  imports: [
    CommonModule,
    UiComponents,
    FormsModule,
    ReactiveFormsModule,

    FlexLayoutModule,
    MomentModule,

    MatDividerModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatListModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatDialogModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatBadgeModule,
    MatTreeModule,
    MatDatepickerModule,

    CommonUIElementsModule,
    CommonD3ChartsModule,

    OntologiesModule,
    TrackModule,
    ReviewModule,

    TeamsRoutingModule,
    DragDropModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatCardModule,
    CommonComponentsModule,
    NgSelectModule,
    MatChipsModule,
    MatStepperModule,
    MatLegacyChipsModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    GetUserInitials,
    TeamMatchUsersComponent,
    TeamManageMatrixV3Component,
    TeamMainComponent,
  ],
})
export class TeamsModule {}
