import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { Booking } from '@models/projects/booking';

@Injectable({
  providedIn: 'root',
})
export class BookingHttpService extends ViewSetHttpService<Booking> {
  constructor(_http: HttpClient) {
    super(_http);
  }

  public viewSetUrl = 'projects/booking';
}
