<div class="config-wrapper" *ngIf="serviceSettings.state.isLoaded">
  <div>
    <button
      mat-raised-button
      *ngIf="serviceSettings.state.items.length === 0"
      (click)="createServiceSettingsConfiguration()"
    >
      Создать базовую конфигурацию
    </button>
  </div>

  <mat-tab-group
    *ngIf="serviceSettings.state.items.length > 0"
    animationDuration="0ms"
  >
    <mat-tab label="Базовая конфигурация">
      <h2>Базовая конфигурация</h2>

      <form
        class="review-form"
        [formGroup]="form"
        (ngSubmit)="updateServiceSettings()"
      >
        <div class="form-field">
          <mat-form-field>
            <mat-label>Название конфигурации</mat-label>
            <input
              matInput
              formControlName="name"
              placeholder="Service name"
              type="text"
              required
            />
          </mat-form-field>
        </div>

        <div class="form-field">
          <mat-form-field>
            <mat-label>Базовый URL</mat-label>
            <input
              matInput
              formControlName="service_url"
              placeholder="Service URL"
              type="text"
            />
          </mat-form-field>
        </div>

        <div class="form-buttons">
          <button mat-raised-button type="submit">Обновить конфигурацию</button>
        </div>
      </form>
    </mat-tab>
    <mat-tab label="Настройки почтового сервиса">
      <div fxLayout="columns">
        <div fxFlex>
          <form
            [formGroup]="form"
            class="email-settings-form"
            *ngIf="currentServiceEmailSettings"
          >
            <p>Текущие настройки почтового сервиса</p>
            <div>
              <h3>{{ currentServiceEmailSettings.name }}</h3>
            </div>

            <div class="form-field">
              <mat-form-field>
                <mat-label>Хост</mat-label>
                <input
                  matInput
                  formControlName="host"
                  placeholder="Host"
                  type="text"
                  required
                />
              </mat-form-field>
            </div>

            <div class="form-field">
              <mat-form-field>
                <mat-label>Порт</mat-label>
                <input
                  matInput
                  formControlName="port"
                  placeholder="Port"
                  type="text"
                  required
                />
              </mat-form-field>
            </div>

            <div class="form-field">
              <mat-form-field>
                <mat-label>Почта</mat-label>
                <input
                  matInput
                  formControlName="default_from_email"
                  placeholder="Email"
                  type="text"
                  required
                />
              </mat-form-field>
            </div>

            <div class="form-field">
              <mat-form-field>
                <mat-label>Оптравитель</mat-label>
                <input
                  matInput
                  formControlName="default_from_name"
                  placeholder="From name"
                  type="text"
                  required
                />
              </mat-form-field>
            </div>

            <div *ngIf="currentServiceEmailSettings.host_user === ''">
              Авторизация не используется
            </div>

            <div
              class="form-field"
              *ngIf="currentServiceEmailSettings.host_user !== ''"
            >
              <mat-form-field>
                <mat-label>Пользователь</mat-label>
                <input
                  matInput
                  formControlName="host_user"
                  placeholder="Пользователь"
                  type="text"
                />
              </mat-form-field>
            </div>

            <div class="form-field">
              <mat-radio-group
                aria-label="host secure"
                formControlName="host_secure"
              >
                <div class="secure-item">
                  <mat-radio-button value="insecure">
                    Без шифрования
                  </mat-radio-button>
                </div>
                <div class="secure-item">
                  <mat-radio-button value="use_ssl">SSL</mat-radio-button>
                </div>
                <div class="secure-item">
                  <mat-radio-button value="use_tls">TLS</mat-radio-button>
                </div>
              </mat-radio-group>
            </div>
            <div class="form-buttons">
              <button mat-raised-button (click)="showSendTestEmailForm()">
                Отправить тестовое письмо
              </button>
            </div>
          </form>
        </div>
        <div fxFlex>
          <div class="email-settings-wrapper">
            <h2>Все созданные конфигурации</h2>

            <div class="form-field">
              <mat-card
                *ngFor="let settings of serviceEmailSettings.state.items"
                class="email-settings-item"
              >
                <mat-card-title
                  *ngIf="currentServiceEmailSettings?.uuid === settings.uuid"
                >
                  {{ settings.name }} <mat-icon>check</mat-icon>
                </mat-card-title>
                <mat-card-content>
                  Хост {{ settings.host }}:{{ settings.port }} <br />
                  Почта: {{ settings.default_from_email }} <br />
                  Отправитель: {{ settings.default_from_name }} <br />
                  Пользователь для авторизации: {{ settings.host_user }} <br />
                </mat-card-content>
                <mat-card-actions>
                  <button
                    *ngIf="currentServiceEmailSettings?.uuid !== settings.uuid"
                    mat-raised-button
                    (click)="setCurrentEmailServiceSettings(settings)"
                  >
                    Использовать эти настройки
                  </button>
                  <button
                    mat-raised-button
                    type="button"
                    (click)="showEditEmailServiceForm(settings)"
                  >
                    <mat-icon>edit</mat-icon> Редактировать
                  </button>
                  <button
                    mat-raised-button
                    color="warn"
                    (click)="removeEmailServiceSettings(settings.uuid)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </mat-card-actions>
              </mat-card>
            </div>

            <div class="form-buttons">
              <button mat-raised-button (click)="showCreateServiceForm()">
                Добавить настройку для почтового сервиса
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Настройки команд">
      <div class="team-settings">
        <div class="form-field">
          <mat-checkbox
            type="checkbox"
            (change)="toggleUseTeamValidation()"
            [checked]="currentServiceSettings.use_team_validation"
          >
            Использовать валидацию для команд</mat-checkbox
          >
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Редактирование контента">
      <div class="user-settings">
        <div class="form-field">
          <mat-checkbox
            type="checkbox"
            (change)="toggleAllowContentEdit()"
            [checked]="currentContentSettings.allow_content_edit"
          >
            Разрешить редактирование всем</mat-checkbox
          >
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
