import { Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SurveyAnswerOptionService } from 'src/app/services/survey/survey-answer-option.service';
import { CompetenceDesireService } from 'src/app/services/competencies/competence-desire.service';

import { SurveyQuestion } from 'src/app/models/survey/survey-question';
import { SurveyAnswerOption } from 'src/app/models/survey/survey-answer-option';
import { SurveyAnswer } from 'src/app/models/survey/survey-answer';
import { CompetenceDesire } from 'src/app/models/competencies/competence-desire';

@Component({
  selector: 'app-survey-tree',
  templateUrl: './survey-tree.component.html',
  styleUrls: ['./survey-tree.component.css'],
})
export class SurveyTreeComponent implements OnInit {
  @Input() questions: SurveyQuestion[];
  @Input() control_subject: BehaviorSubject<any>;
  @Input() visual_control_subject: BehaviorSubject<any>;
  @Input() submitted_answer_subject: Subject<SurveyAnswer>;
  @Input() skipped_question_subject: Subject<SurveyQuestion>;
  @Input() returned_question_subject: Subject<SurveyQuestion>;
  @Input() breadcrumbs_subject: Subject<string[]>;

  public question_sequence: SurveyQuestion[] = [];
  public previous_question_sequence: SurveyQuestion[] = [];

  public current_question: SurveyQuestion;
  public current_question_answer_options: SurveyAnswerOption[];
  public current_question_thing_desire: CompetenceDesire;

  public selected_answer_option: SurveyAnswerOption;

  public ui_color: ThemePalette = 'warn';

  private uistate = {
    current_question_answer_options_loaded: false,
    button_answer_sumbit_disabled: false,
    checkbox_current_question_thing_desired_disabled: false,
  };

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private auth_service: AuthService,
    private answer_options_service: SurveyAnswerOptionService,
    private competence_desire_service: CompetenceDesireService
  ) {}

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.build_question_sequence();
    this.next_question();

    this.control_subject.subscribe((control_command) => {
      this.logging_service.debug(
        `${this.constructor.name} received control command ${control_command.command}`
      );
      if (control_command.command === 'next_question') {
        this.uistate.button_answer_sumbit_disabled = false;
        this.selected_answer_option = null;
        this.next_question();
      }
      if (control_command.command === 'submission_error') {
        this.uistate.button_answer_sumbit_disabled = false;
      }
      if (control_command.command === 'skip_to_question') {
        this.skipped_question_subject.next(this.current_question);
        while (this.current_question.uuid !== control_command.question.uuid) {
          this.skipped_question_subject.next(this.current_question);
          this.skip_to_next_question();
        }
        this.next_question();
        this.previous_question_sequence.pop(); // to eliminate doubles
        this.alert_service.info('Продолжаем с места, где вы остановились');
      }
    });
  }

  public get is_data_loaded(): boolean {
    return this.is_answer_options_loaded;
  }

  public get is_answer_options_loaded(): boolean {
    return this.uistate.current_question_answer_options_loaded;
  }

  public get is_checkbox_current_question_thing_desired(): boolean {
    return Boolean(this.current_question_thing_desire);
  }

  public get is_checkbox_current_question_thing_desired_disabled(): boolean {
    return this.uistate.checkbox_current_question_thing_desired_disabled;
  }

  public get is_button_answer_sumbit_enabled(): boolean {
    return (
      this.is_answer_options_loaded &&
      Boolean(this.selected_answer_option) &&
      !this.uistate.button_answer_sumbit_disabled
    );
  }

  private build_question_sequence(): void {
    this.logging_service.debug(
      `${this.constructor.name} building question sequence`
    );

    // depth fisrt
    const add_children_to_sequence = (parent) => {
      this.question_sequence.push(parent);
      const children = this.questions.filter(
        (q) =>
          q.parent_question &&
          (q.parent_question as SurveyQuestion).uuid === parent.uuid
      );
      children.sort((a, b) => (a.order_number > b.order_number ? 1 : -1));
      children.forEach((child) => add_children_to_sequence(child));
    };

    const root_question = this.questions.find(
      (q) => q.parent_question === null
    );
    add_children_to_sequence(root_question);

    this.logging_service.debug(
      `${this.constructor.name} question sequence length: ${this.question_sequence.length}`
    );
  }

  private next_question(): void {
    const next_question = this.get_next_question();
    if (this.current_question) {
      this.previous_question_sequence.push(this.current_question);
    }
    this.visual_control_subject.next({
      command: 'set_current',
      question: next_question,
    });
    if (next_question) {
      if (next_question.question_type === 'dummy_node') {
        this.skipped_question_subject.next(next_question);
        this.next_question();
        return;
      }
      this.current_question = next_question;
      this.current_question_thing_desire = null;
      this.load_answer_options();
      this.load_competence_desire(
        this.current_question.related_thing as string
      );
      const breadcrumbs = [];
      this.get_breadcrumb_path(this.current_question).forEach((q) =>
        breadcrumbs.push(q.title)
      );
      this.breadcrumbs_subject.next(breadcrumbs);
      this.logging_service.debug(
        `${this.constructor.name} new current question set ${this.current_question.title}`
      );
    } else {
      this.logging_service.debug(
        `${this.constructor.name} no questions left is question sequence`
      );
    }
  }

  private prev_question(): void {
    const prev_question = this.get_prev_question();
    this.question_sequence.unshift(this.current_question);
    this.visual_control_subject.next({
      command: 'set_current',
      question: prev_question,
    });
    this.returned_question_subject.next(prev_question);
    if (prev_question) {
      if (prev_question.question_type === 'dummy_node') {
        this.prev_question();
        return;
      }
      this.current_question = prev_question;
      this.current_question_thing_desire = null;
      this.load_answer_options();
      this.load_competence_desire(
        this.current_question.related_thing as string
      );
      const breadcrumbs = [];
      this.get_breadcrumb_path(this.current_question).forEach((q) =>
        breadcrumbs.push(q.title)
      );
      this.breadcrumbs_subject.next(breadcrumbs);
      this.logging_service.debug(
        `${this.constructor.name} new current question set ${this.current_question.title}`
      );
    } else {
      this.logging_service.debug(
        `${this.constructor.name} no questions left is prev question sequence`
      );
    }
  }

  private skip_to_next_question(): void {
    const next_question = this.get_next_question();
    this.previous_question_sequence.push(this.current_question);
    this.current_question = next_question;
    this.visual_control_subject.next({
      command: 'set_current',
      question: next_question,
    });
  }

  private get_next_question(): SurveyQuestion | null {
    if (this.question_sequence.length) {
      const question = this.question_sequence.shift();
      return question;
    } else {
      return null;
    }
  }

  private get_prev_question(): SurveyQuestion | null {
    if (this.previous_question_sequence.length) {
      const question = this.previous_question_sequence.pop();
      return question;
    } else {
      return null;
    }
  }

  private load_answer_options(): void {
    this.logging_service.debug(
      `${this.constructor.name} loading answer options...`
    );
    this.uistate.current_question_answer_options_loaded = false;
    this.answer_options_service
      .fetch_all_for_question_uuid(this.current_question.uuid)
      .subscribe(
        (response) => {
          this.current_question_answer_options =
            response.results as SurveyAnswerOption[];
          this.logging_service.debug(
            `${this.constructor.name} successfully loaded answer options`
          );
          this.uistate.current_question_answer_options_loaded = true;
        },
        (err) => {
          this.logging_service.debug(
            `${this.constructor.name} failed to load answer options`
          );
          this.alert_service.error(
            `Ошибка загрузки вариантов ответа ${err.status}`
          );
        }
      );
  }

  private load_competence_desire(thing_uuid: string): void {
    this.logging_service.debug(
      `${this.constructor.name} loading user desires for thing ${thing_uuid}...`
    );
    const user_uuid = this.auth_service.get_current_user_uuid();
    this.competence_desire_service
      .fetch_by_thing_and_user(user_uuid, thing_uuid)
      .subscribe(
        (response) => {
          const desires = response.results as CompetenceDesire[];
          this.logging_service.debug(
            `${this.constructor.name} successfully loaded ${desires.length} user desires`
          );
          if (desires.length > 0) {
            this.current_question_thing_desire = desires[0];
          }
        },
        (err) => {
          this.logging_service.debug(
            `${this.constructor.name} failed to load user desires`
          );
          this.alert_service.error(`Ошибка загрузки хотелок ${err.status}`);
        }
      );
  }

  public on_select_answer_option(answer_option: SurveyAnswerOption): void {
    this.logging_service.debug(
      `${this.constructor.name} selected answer option ${answer_option.title}`
    );
    this.selected_answer_option = answer_option;
  }

  public is_answer_option_selected(answer_option: SurveyAnswerOption): boolean {
    if (!this.selected_answer_option) {
      return false;
    }
    return this.selected_answer_option.uuid === answer_option.uuid;
  }

  public on_submit_answer_option(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_submit_answer_option`
    );
    this.uistate.button_answer_sumbit_disabled = true;
    const answer = {
      question: this.current_question.uuid,
      answer_option: this.selected_answer_option.uuid,
    } as SurveyAnswer;
    this.submitted_answer_subject.next(answer);
  }

  public on_toggle_current_thing_desired($event: any): void {
    $event.preventDefault();
    if (this.current_question_thing_desire) {
      this.desire_delete();
    } else {
      this.desire_create();
    }
  }

  private desire_create(): void {
    if (this.uistate.checkbox_current_question_thing_desired_disabled) {
      return;
    }
    this.uistate.checkbox_current_question_thing_desired_disabled = true;
    const user_uuid = this.auth_service.get_current_user_uuid();
    const desire_data = {
      user: user_uuid,
      thing: this.current_question.related_thing,
    };
    this.competence_desire_service.create(desire_data).subscribe(
      (response) => {
        const desire = response as CompetenceDesire;
        this.logging_service.debug(
          `${this.constructor.name} successfully created desire uuid ${desire.uuid}`
        );
        // this.alert_service.success('Хотелка сохранена');
        this.current_question_thing_desire = desire;
        this.uistate.checkbox_current_question_thing_desired_disabled = false;
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to create desire`
        );
        this.alert_service.error(`Ошибка создания хотелки ${err.status}`);
        this.uistate.checkbox_current_question_thing_desired_disabled = false;
      }
    );
  }

  private desire_delete(): void {
    if (this.uistate.checkbox_current_question_thing_desired_disabled) {
      return;
    }
    this.uistate.checkbox_current_question_thing_desired_disabled = true;
    this.competence_desire_service
      .delete(this.current_question_thing_desire)
      .subscribe(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} successfully deleted desire`
          );
          this.current_question_thing_desire = null;
          this.uistate.checkbox_current_question_thing_desired_disabled = false;
        },
        (err) => {
          this.logging_service.debug(
            `${this.constructor.name} failed to delete desire`
          );
          this.alert_service.error(`Ошибка удаления хотелки ${err.status}`);
          this.uistate.checkbox_current_question_thing_desired_disabled = false;
        }
      );
  }

  public get_breadcrumb_path(question: SurveyQuestion): SurveyQuestion[] {
    const breadcrumb_path = [];
    if (!question.parent_question) {
      return breadcrumb_path;
    }
    const build_breadcrumb_path = (q) => {
      if (!q.parent_question) {
        return;
      }
      if (
        typeof q.parent_question === 'string' ||
        q.parent_question instanceof String
      ) {
        const parent = this.questions.find((p) => p.uuid === q.parent_question);
        breadcrumb_path.push(parent);
        build_breadcrumb_path(parent);
      } else {
        breadcrumb_path.push(q.parent_question);
        build_breadcrumb_path(q.parent_question);
      }
    };
    build_breadcrumb_path(question);
    // console.log(breadcrumb_path);
    return breadcrumb_path.reverse();
  }

  public on_button_next_question(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_next_question `
    );
    this.selected_answer_option = null;
    this.next_question();
  }

  public on_button_prev_question(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_prev_question `
    );
    this.selected_answer_option = null;
    this.prev_question();
  }
}
