import { Component, OnInit } from '@angular/core';

import { AsyncList } from '@rest/AsyncList';

import { AuthService } from 'src/app/services/auth/auth.service';

import { TeamMembershipHttpService } from '@services/http/TeamMembershipHttpService';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';

import { Team } from '@models/teams/team';
import { TeamMembership } from '@models/teams/team-membership';

@Component({
  selector: 'app-team-main',
  templateUrl: './team-main.component.html',
  styleUrls: ['./team-main.component.css'],
})
export class TeamMainComponent implements OnInit {
  userMemberships: AsyncList<TeamMembership>;
  optionsMap = [];

  constructor(
    private _authService: AuthService,
    private _teamMembershipHttpService: TeamMembershipHttpService,
    private _specialityHttpService: SpecialityHttpService
  ) {}

  ngOnInit(): void {
    this.userMemberships = new AsyncList<TeamMembership>(
      this._teamMembershipHttpService
    );
    this.userMemberships.setRequestParams({
      params: {
        user: this._authService.get_current_user_uuid(),
        role__in: 'lead,owner',
        expand: 'team',
        page_size: 1000,
      },
    });
    this.userMemberships.load().subscribe((response) => {
      response.results.forEach((teamMembership, index) => {
        const team = teamMembership.team as Team;
        this.optionsMap.push({
          name: team.name,
          uuid: team.uuid,
          isExpanded: index === 0,
          options: [
            { name: 'Участники', url: `${team.uuid}/members` },
            { name: 'Специальности', url: `${team.uuid}/specialities` },
            { name: 'Ревью команды', url: `${team.uuid}/reviews` },
            { name: 'Запросы на обучение', url: `${team.uuid}/desires` },
            { name: 'Настройки', url: `${team.uuid}/settings` },
          ],
        });
      });
    });
  }
}
