<div class="app-ontologies-things-tab-levels-component-container">
  <ng-container *ngIf="is_data_loaded; then component_content; else preloader">
  </ng-container>
</div>

<ng-template #preloader>
  <app-preloader-progressbar></app-preloader-progressbar>
</ng-template>

<ng-template #component_content>
  <div class="app-ontologies-things-tab-levels-container">
    <div *ngIf="!levels.length">
      <!-- Временная заглушка, которую никто не должен увидеть -->
      Ошибка: по какой-то причине для компетенции не описано уровней владения (а
      должны быть)
    </div>
    <div *ngIf="levels.length">
      <div *ngFor="let level of levels">
        <ng-container
          [ngTemplateOutlet]="
            level_edit_uuid == level.uuid ? tmpl_level_edit_mode : tmpl_level
          "
          [ngTemplateOutletContext]="{ level: level }"
        >
        </ng-container>
      </div>
      <button
        mat-stroked-button
        *ngIf="editable"
        (click)="open_new_thing_level_dialog()"
      >
        Добавить уровень
      </button>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_level let-level="level">
  <div class="app-ontologies-things-tab-levels-listitem-container">
    <div fxLayout="row">
      <div fxFlex="89%">
        <div class="app-ontologies-things-tab-levels-listitem-title">
          {{ level.title }}
        </div>
        <div
          class="app-ontologies-things-tab-levels-listitem-description"
          [innerHTML]="level.description | newlineToBr"
        ></div>
      </div>
      <div fxFlex="5%" fxFlexOffset="15%">
        <button
          mat-icon-button
          *ngIf="editable"
          (click)="on_button_level_edit(level)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          *ngIf="!isBaseLevel(level) && editable"
          mat-icon-button
          (click)="openRemoveDialog(level)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_level_edit_mode let-level="level">
  <div
    class="
      app-ontologies-things-tab-levels-listitem-container
      app-ontologies-things-tab-levels-listitem-container-selected
    "
  >
    <div fxLayout="row">
      <div fxFlex="80%">
        <form
          [formGroup]="level_edit_form"
          fxLayout="column"
          fxLayoutGap="1rem"
        >
          <mat-form-field>
            <mat-label>Уровень владения</mat-label>
            <input
              #input_field_title
              matInput
              required
              placeholder="Уровень владения"
              formControlName="level_title"
            />

            <mat-hint>{{ 128 - input_field_title.value.length }}/128</mat-hint>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Описание</mat-label>
            <textarea
              matInput
              #input_field_description
              required
              formControlName="level_description"
              placeholder="Описание*"
            >
            </textarea>
            <mat-hint
              >{{ 1024 - input_field_description.value.length }}/1024</mat-hint
            >
          </mat-form-field>
        </form>
      </div>
      <div fxFlex="5%" fxFlexOffset="15%">
        <button
          mat-icon-button
          (click)="on_button_level_edit_save(level)"
          [disabled]="is_button_level_edit_save_disabled"
        >
          <mat-icon>save</mat-icon>
        </button>
        <br />
        <br />
        <button mat-icon-button (click)="on_button_level_edit_cancel()">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
