import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import * as d3 from 'd3';

import { LoggingService } from 'src/app/services/logging.service';

import { SurveyQuestion } from 'src/app/models/survey/survey-question';
import { Competence } from 'src/app/models/competencies/competence';
import { Thing } from 'src/app/models/ontology/thing';
import { ThingLevel } from '@models/ontology/thing-level';

@Component({
  selector: 'app-survey-tree-visual',
  templateUrl: './survey-tree-visual.component.html',
  styleUrls: ['./survey-tree-visual.component.css'],
})
export class SurveyTreeVisualComponent implements OnInit, AfterViewInit {
  private _questions: SurveyQuestion[];

  private _user_competencies: Competence[];
  private _thing_to_level_map = new Map();

  private _svg_config: any;
  private _svg_el: any;
  private _svg_g: any;

  private _d3_treemap: any;

  private _tree_data: any;

  private _i: any;
  // private _node_horizontal_padding = 50;
  private _duration: any;
  private _root: any;
  public _zoom: any;

  private _current_question: SurveyQuestion;

  private result_show_noskill = true;
  private result_show_groupnames = false;

  @Input()
  set questions(input_questions: SurveyQuestion[]) {
    this._questions = input_questions;
    this._tree_data = this.build_tree_data();
  }
  get questions(): SurveyQuestion[] {
    return this._questions;
  }

  @Input() visual_control_subject: BehaviorSubject<any>;

  @Input()
  set user_competencies(input_competencies: Competence[]) {
    this._user_competencies = input_competencies;

    if (this._user_competencies && this._user_competencies.length) {
      this._user_competencies.forEach((c) => {
        this._thing_to_level_map.set(
          (c.thing as Thing).uuid,
          (c.thing_level as ThingLevel).order_number
        );
      });
    }
  }
  get user_competencies(): Competence[] {
    return this._user_competencies;
  }

  @Input()
  set config(input_config: any) {
    if (input_config) {
      this._svg_config = input_config;
    } else {
      this._svg_config = this.get_default_config();
    }
  }
  get config(): any {
    if (this._svg_config) {
      return this._svg_config;
    } else {
      return this.get_default_config();
    }
  }

  constructor(private logging_service: LoggingService) {}

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init_svg();
      this.visual_control_subject.subscribe((control_command) => {
        this.logging_service.debug(
          `${this.constructor.name} received command: ${control_command.command}`
        );
        if (control_command.command === 'set_current') {
          this._current_question = control_command.question;

          const node = this.find_node_by_question(this._current_question);
          // console.log(node);
          // console.log();
          const width = document
            .querySelector('.app-surveys-survey-tree-visual-svg-container')
            .getBoundingClientRect().width;
          const height = document
            .querySelector('.app-surveys-survey-tree-visual-svg-container')
            .getBoundingClientRect().height;

          const scale = 3;
          this._svg_el
            .transition()
            .duration(500)
            .call(
              this._zoom.transform,
              d3.zoomIdentity
                .translate((width / 2) * scale, (height / 2) * scale)
                // .scale(40).translate(-x, -y)
                .scale(scale)
                .translate(-node.y, -node.x)
            );

          this.tree_update(this._root);
        }
        if (control_command.command === 'result_toggle_show_noskill') {
          this.result_show_noskill = !this.result_show_noskill;
          this.tree_update(this._root);
        }
        if (control_command.command === 'result_toggle_show_groupnames') {
          this.result_show_groupnames = !this.result_show_groupnames;
          this.tree_update(this._root);
        }
      });
    }, 100);
  }

  private get_default_config(): any {
    return {
      width: 500,
      height: 400,
      margin: {
        top: 10,
        right: 70,
        bottom: 10,
        left: 50,
      },
      tree: {
        node_horizontal_padding: 50,
        show_node_text: false,
        show_competencies: false,
      },
      colors: {
        nodefill: ['#fff', '#ffcaca', '#ff7e7e', '#ff1f1f'],
        nodestroke: ['#ccc', '#ffcaca', '#ff7e7e', '#ff1f1f'],
      },
    };
  }

  private build_tree_data(): void {
    this.logging_service.debug(`${this.constructor.name} building tree data`);

    const root_questions = this.questions.filter((q) => q.depth === 0);
    if (root_questions.length > 1) {
      this.logging_service.error(
        `${this.constructor.name} multiple top level questions`
      );
      this._tree_data = {};
      return;
    }
    const root_question = root_questions[0];

    const treenodes = [];
    this.questions.forEach((q) => {
      const treenode = {
        id: q.uuid,
        uuid: q.uuid,
        related_thing: q.related_thing,
        value: 0,
        question_type: q.question_type,
        name: q.title,
        children: [],
      };
      treenodes.push(treenode);
    });
    treenodes.forEach((treenode) => {
      const child_questions = this.questions.filter((q) => {
        if (q.parent_question && q.parent_question === treenode.uuid) {
          return true;
        }
        if (
          q.parent_question &&
          (q.parent_question as SurveyQuestion).uuid === treenode.uuid
        ) {
          return true;
        }
      });
      // console.log(`child questions ${child_questions.length}`);
      // find treenodes for this questions
      const child_treenodes = [];
      child_questions.forEach((q) => {
        const child_treenode = treenodes.find((n) => n.uuid === q.uuid);
        treenode.children.push(child_treenode);
      });
      child_treenodes.sort((a, b) =>
        a.order_number > b.order_number ? 1 : -1
      );
    });
    // console.log(treenodes);
    const root_treenode = treenodes.find((n) => n.uuid === root_question.uuid);
    return root_treenode;
  }

  private get svg_width(): any {
    return (
      this.config.width + this.svg_margin_left + this.svg_margin_right + 50
    );
  }
  private get svg_height(): any {
    return (
      this.config.height + this.svg_margin_top + this.svg_margin_bottom + 50
    );
  }
  private get svg_margin_top(): any {
    return this.config.margin.top;
  }
  private get svg_margin_bottom(): any {
    return this.config.margin.bottom;
  }
  private get svg_margin_left(): any {
    return this.config.margin.left;
  }
  private get svg_margin_right(): any {
    return this.config.margin.right;
  }

  private get svg_effective_width(): any {
    return this.svg_width - this.svg_margin_left - this.svg_margin_right;
  }
  private get svg_effective_height(): any {
    return this.svg_height - this.svg_margin_top - this.svg_margin_bottom;
  }

  private init_svg(): void {
    // https://blockbuilder.org/tejaser/55c43b4a9febca058363a5e58edbce81
    this.logging_service.debug(`${this.constructor.name} draw chart`);

    const parent = d3.select(`.app-surveys-survey-tree-visual-svg-container`);
    parent.select('svg').remove();

    // from zoom example https://observablehq.com/@d3/programmatic-zoom
    // const svg = d3.create('svg').attr("viewBox", [0, 0, width, height]).on("click", reset);

    const zoomed = () => {
      // this.logging_service.debug(` -------> zoomed ${transform}`);
      // this._svg_g.attr('transform', transform);
      this._svg_el.attr('transform', d3.event.transform);
    };

    this._zoom = d3.zoom().scaleExtent([2, 3]).on('zoom', zoomed);

    if (this.config.tree.show_competencies) {
      this._svg_el = parent
        .append('svg')
        .attr('width', this.svg_width)
        .attr('height', this.svg_height);
    } else {
      this._svg_el = parent
        .append('svg')
        .attr('width', this.svg_width)
        .attr('height', this.svg_height)
        .call(this._zoom);
    }
    this._svg_g = this._svg_el
      .append('g')
      .attr(
        'transform',
        'translate(' + this.svg_margin_left + ',' + this.svg_margin_top + ')'
      );

    // this._d3_scale_color = d3.scaleLinear().domain([0, 1]).range([1, 10]); // CHANGED RANGE from 'green', 'red'
    // this._d3_scale_width = d3.scaleLinear().domain([1,80]).range([1, 10]);
    this._i = 0;
    this._duration = 750;
    this._root = null;
    this._d3_treemap = d3
      .tree()
      .size([this.svg_effective_height, this.svg_effective_width]);
    // this may be used to make spacing between nodes
    // const node_size = {
    //   width: 75,
    //   height: 15,
    //   horizontal_padding: 15,
    //   vertical_padding: 5
    // }
    // this._d3_treemap.nodeSize([node_size.height + node_size.vertical_padding, node_size.width + node_size.horizontal_padding])
    // .separation((a, b) => {
    //     return a.parent === b.parent ? 1 : 1.25;
    // });

    // Assigns parent, children, height, depth
    // console.log(this._tree_data);
    this._root = d3.hierarchy(this._tree_data, (d) => d.children);
    this._root.x0 = this.svg_effective_height / 2;
    this._root.y0 = 0;

    // this._root.children.forEach(this.tree_collapse_recursive);
    this.tree_update(this._root);

    // console.log(this._root);
  }

  private find_node_by_question(question: SurveyQuestion): any {
    const find_node_recursive = (current_node: any) => {
      if (current_node.data.uuid === question.uuid) return current_node;
      if (current_node.children) {
        for (const child of current_node.children) {
          const res = find_node_recursive(child);
          if (res) {
            return res;
          }
        }
      }
    };
    const q = find_node_recursive(this._root);
    return q;
  }

  public tree_get_node_fill_color(d): string {
    if (this.config.tree.show_competencies) {
      const level = this._thing_to_level_map.get(d.data.related_thing);
      if (level === 0) {
        return this.get_default_config().colors.nodefill[0];
      }
      if (level === 1) {
        return this.get_default_config().colors.nodefill[1];
      }
      if (level === 2) {
        return this.get_default_config().colors.nodefill[2];
      }
      if (level === 3) {
        return this.get_default_config().colors.nodefill[3];
      }
      return '#fff';
    } else {
      if (
        this._current_question &&
        this._current_question.uuid === d.data.uuid
      ) {
        return 'red';
      } else {
        return d._children ? 'lightsteelblue' : '#eee';
      }
    }
  }

  public tree_get_node_stroke_color(d): string {
    if (this.config.tree.show_competencies) {
      const level = this._thing_to_level_map.get(d.data.related_thing);
      if (level === 0) {
        return this.get_default_config().colors.nodestroke[0];
      }
      if (level === 1) {
        return this.get_default_config().colors.nodestroke[1];
      }
      if (level === 2) {
        return this.get_default_config().colors.nodestroke[2];
      }
      if (level === 3) {
        return this.get_default_config().colors.nodestroke[3];
      }
      return '#ccc';
    } else {
      if (
        this._current_question &&
        this._current_question.uuid === d.data.uuid
      ) {
        return 'red';
      } else {
        return d._children ? 'lightsteelblue' : '#ccc';
      }
    }
  }

  public tree_get_node_circle_radius(d): number {
    const level = this._thing_to_level_map.get(d.data.related_thing);
    if (level === 0) {
      return 3;
    }
    if (level === 1) {
      return 3;
    }
    if (level === 2) {
      return 4;
    }
    if (level === 3) {
      return 5;
    }
    return 3;
  }

  public tree_get_node_text_fill_color(d): string {
    if (this.result_show_noskill) {
      return '#222';
    } else {
      const level = this._thing_to_level_map.get(d.data.related_thing);
      // this.logging_service.debug(`${this.constructor.name} tree_get_node_text_fill_color level ${level}`);
      if (level >= 1) {
        return '#222';
      } else {
        return '#ccc';
      }
    }
  }

  public tree_get_node_text(d): string {
    if (this.config.tree.show_node_text) {
      if (this.result_show_groupnames) {
        return d.data.name;
      } else {
        if (d.data.question_type === 'dummy_node') {
          return '';
        } else {
          return d.data.name;
        }
      }
    } else {
      return '';
    }
  }

  public tree_get_link_stroke_color(d): string {
    if (this.result_show_noskill) {
      return '#ccc';
    } else {
      if (d.data.question_type === 'dummy_node') {
        return '#ccc';
      }
      const level = this._thing_to_level_map.get(d.data.related_thing);
      // this.logging_service.debug(`${this.constructor.name} tree_get_node_text_fill_color level ${level}`);
      if (level >= 1) {
        return '#ccc';
      } else {
        return '#eee';
      }
    }
  }

  public tree_collapse_recursive(datum: any): void {
    const _collapse_recursive = (d) => {
      if (d.children) {
        d._children = d.children;
        d._children.forEach(_collapse_recursive);
        d.children = null;
      }
    };
    _collapse_recursive(datum);
  }

  public tree_diagonal(s: any, d: any): string {
    const path = `M ${s.y} ${s.x}
            C ${(s.y + d.y) / 2} ${s.x},
              ${(s.y + d.y) / 2} ${d.x},
              ${d.y} ${d.x}`;
    return path;
  }

  public tree_update(source: any): void {
    this.logging_service.debug(`${this.constructor.name} tree update`);
    // Assigns the x and y position for the nodes
    const tree_data = this._d3_treemap(this._root);

    // Compute the new tree layout.
    const nodes = tree_data.descendants();
    const links = tree_data.descendants().slice(1);

    const tree_on_click = (d: any) => {
      if (d.children) {
        d._children = d.children;
        d.children = null;
      } else {
        d.children = d._children;
        d._children = null;
      }
      this.tree_update(d);
    };

    // const tree_on_mouseover = (d: any, i: any) => {
    // }

    // const tree_on_mouseout = (d: any, i: any) => {
    // }

    // draw legend
    if (this.config.tree.show_competencies) {
      this._svg_g
        .append('circle')
        .attr('cx', 10)
        .attr('cy', 10)
        .attr('r', 3)
        .style('fill', this.get_default_config().colors.nodefill[0])
        .style('stroke', this.get_default_config().colors.nodestroke[0]);
      this._svg_g
        .append('circle')
        .attr('cx', 10)
        .attr('cy', 30)
        .attr('r', 3)
        .style('fill', this.get_default_config().colors.nodefill[1])
        .style('stroke', this.get_default_config().colors.nodestroke[1]);
      this._svg_g
        .append('circle')
        .attr('cx', 10)
        .attr('cy', 50)
        .attr('r', 4)
        .style('fill', this.get_default_config().colors.nodefill[2])
        .style('stroke', this.get_default_config().colors.nodestroke[2]);
      this._svg_g
        .append('circle')
        .attr('cx', 10)
        .attr('cy', 70)
        .attr('r', 5)
        .style('fill', this.get_default_config().colors.nodefill[3])
        .style('stroke', this.get_default_config().colors.nodestroke[3]);
      this._svg_g
        .append('text')
        .attr('x', 30)
        .attr('y', 10)
        .text('Навыка нет')
        .style('font', 'sans-serif')
        .style('font-size', '12px')
        .attr('alignment-baseline', 'middle');
      this._svg_g
        .append('text')
        .attr('x', 30)
        .attr('y', 30)
        .text('Начинающий')
        .style('font', 'sans-serif')
        .style('font-size', '12px')
        .attr('alignment-baseline', 'middle');
      this._svg_g
        .append('text')
        .attr('x', 30)
        .attr('y', 50)
        .text('Уверенный')
        .style('font', 'sans-serif')
        .style('font-size', '12px')
        .attr('alignment-baseline', 'middle');
      this._svg_g
        .append('text')
        .attr('x', 30)
        .attr('y', 70)
        .text('Эксперт')
        .style('font', 'sans-serif')
        .style('font-size', '12px')
        .attr('alignment-baseline', 'middle');
    }

    // Normalize for fixed-depth.
    nodes.forEach(
      (d) => (d.y = d.depth * this.config.tree.node_horizontal_padding)
    );

    // Update the nodes...
    const node = this._svg_g
      .selectAll('g.app-surveys-survey-tree-visual-node')
      // .data(nodes, (d) => d.uuid); // check, that we use uuid, not numbers
      .data(nodes, (d) => d.id || (d.id = ++this._i)); // check, that we use uuid, not numbers

    // Enter any new modes at the parent's previous position.
    const node_enter = node
      .enter()
      .append('g')
      .attr('class', 'app-surveys-survey-tree-visual-node')
      .attr(
        'transform',
        (d) => 'translate(' + source.y0 + ',' + source.x0 + ')'
      );
    // .on('mouseover', tree_on_mouseover)
    // .on('mouseout', tree_on_mouseout);
    // .on('click', tree_on_click);

    // Add Circle for the nodes
    node_enter
      .append('circle')
      .attr('class', 'app-surveys-survey-tree-visual-node')
      .attr('r', 1e-6)
      .style('fill', (d) => this.tree_get_node_fill_color(d))
      .style('stroke', (d) => this.tree_get_node_stroke_color(d));

    // Add labels for the nodes
    node_enter
      .append('text')
      .attr('dy', '.35em')
      .attr('x', (d) => (d.children || d._children ? -13 : 13))
      .attr('text-anchor', (d) => (d.children || d._children ? 'end' : 'start')) // so, color of node with and without children
      .text((d) => this.tree_get_node_text(d))
      // .text((d) => d.data.name)
      .style('fill', (d) => this.tree_get_node_text_fill_color(d));

    // UPDATE
    const node_update = node_enter.merge(node);

    // Transition to the proper position for the node
    node_update
      .transition()
      .duration(this._duration)
      .attr('transform', (d) => 'translate(' + d.y + ',' + d.x + ')');

    // Update the node attributes and style
    node_update
      .select('circle.app-surveys-survey-tree-visual-node')
      .attr('r', (d) => this.tree_get_node_circle_radius(d))
      .style('fill', (d) => this.tree_get_node_fill_color(d));
    // .attr('cursor', 'pointer');

    node_update
      .select('text')
      .transition()
      .duration(this._duration)
      .text((d) => this.tree_get_node_text(d))
      .style('fill', (d) => this.tree_get_node_text_fill_color(d));

    // Remove any exiting nodes
    const node_exit = node
      .exit()
      .transition()
      .duration(this._duration)
      .attr('transform', (d) => 'translate(' + source.y + ',' + source.x + ')')
      .remove();

    // On exit reduce the node circles size to 0
    node_exit.select('circle').attr('r', 1e-6);

    // On exit reduce the opacity of text labels
    node_exit.select('text').style('fill-opacity', 1e-6);

    // Update the links...
    const link = this._svg_g
      .selectAll('path.app-surveys-survey-tree-visual-link')
      // .data(links, (d) => d.uuid)
      .data(links, (d) => d.id)
      .style('stroke-width', (d) => '2px');

    // Enter any new links at the parent's previous position.
    const link_enter = link
      .enter()
      .insert('path', 'g')
      .attr('class', 'app-surveys-survey-tree-visual-link')
      .attr('d', (d) => {
        const o = { x: source.x0, y: source.y0 };
        return this.tree_diagonal(o, o);
      })
      .style('stroke', (d) => this.tree_get_link_stroke_color(d))
      .style('stroke-width', (d) => '2px');

    // UPDATE
    const link_update = link_enter.merge(link);

    // Transition back to the parent element position
    link_update
      .transition()
      .duration(this._duration)
      .style('stroke', (d) => this.tree_get_link_stroke_color(d))
      .attr('d', (d) => this.tree_diagonal(d, d.parent));

    // Remove any exiting links
    const link_exit = link
      .exit()
      .transition()
      .duration(this._duration)
      .attr('d', (d) => {
        const o = { x: source.x, y: source.y };
        return this.tree_diagonal(o, o);
      })
      .style('stroke-width', (d) => '2px')
      .remove();

    // Store the old positions for transition.
    nodes.forEach((d) => {
      d.x0 = d.x;
      d.y0 = d.y;
    });
  }
}
