<div class="router-outlet-container">
  <ng-container *ngIf="is_data_loaded; then component_content; else preloader">
  </ng-container>
</div>

<ng-template #preloader>
  <app-preloader></app-preloader>
</ng-template>

<ng-template #component_content>
  <div class="app-admin-dashboard-container">
    <div fxLayout="row">
      <div fxFlex="15rem">
        <div class="app-admin-dashboard-navigation-sidebar">
          <mat-nav-list>
            <h3 matSubheader>Навигация</h3>
            <a mat-list-item routerLink="/admin/users">
              <mat-icon>perm_identity</mat-icon>
              &nbsp; Пользователи
            </a>
            <a
              mat-list-item
              routerLink="/admin/teams"
              [attr.data-test-id]="testDataAttributes.adminMenuTeamsLinkId"
            >
              <mat-icon>group</mat-icon>
              &nbsp; Команды
            </a>
            <a mat-list-item routerLink="/admin/specialities">
              <mat-icon>engineering</mat-icon>
              &nbsp; Специальности
            </a>
            <a mat-list-item routerLink="/admin/directions">
              <mat-icon>directions</mat-icon>
              &nbsp; Направления
            </a>
            <h3 matSubheader>Компетенции</h3>
            <a mat-list-item routerLink="/admin/ontologies">
              <mat-icon>account_tree</mat-icon>
              &nbsp; Домены
            </a>
            <a mat-list-item routerLink="/admin/surveys">
              <mat-icon>poll</mat-icon>
              &nbsp; Опросы
            </a>
            <h3 matSubheader>Системное</h3>
            <a mat-list-item routerLink="/admin/system/stats">
              <mat-icon>insights</mat-icon>
              &nbsp; Статистика
            </a>
            <a mat-list-item routerLink="/admin/system/debug">
              <mat-icon>code_off</mat-icon>
              &nbsp; Отладка
            </a>
            <a mat-list-item routerLink="/admin/system/settings">
              <mat-icon>settings</mat-icon>
              &nbsp; Настройки
            </a>
          </mat-nav-list>
        </div>
      </div>
      <div fxFlex fxFlexOffset="2rem">
        <div class="app-admin-dashboard-main">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</ng-template>
