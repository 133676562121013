import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../../../services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-domain-detail-tree',
  templateUrl: './domain-detail-tree.component.html',
  styleUrls: ['./domain-detail-tree.component.css'],
})
export class DomainDetailTreeComponent implements OnInit {
  @Input() dataSource: any;
  @Input() treeControl: any;
  @Input() hasChild: any;
  @Input() expandDomain: any;
  @Input() setDetailFormConfig: any;
  @Input() remove: any;
  @Input() isCreateHighLevelDomainButtonShow: boolean;
  @Input() isEditable: boolean;

  userDirectionDomains: string[];

  constructor(private _authService: AuthService, private _router: Router) {}

  ngOnInit(): void {
    const domainUuid = localStorage.getItem('libraryCompetenceTreeExpansion');
    if (domainUuid) this.expandDomain(domainUuid);
  }

  get isUserDirectionsDomainsLoaded(): boolean {
    return this._authService.userProfile$.value.userDirectionsDomainsLoaded;
  }

  isDirectionDomainAdmin(domainUuid): boolean {
    return !!this._authService.getUserDirectionsDomains().includes(domainUuid);
  }

  redirectToThing(node: any): void {
    localStorage.setItem('libraryCompetenceTreeExpansion', node.parent_domain);
    this._router.navigate([`/things/${node.uuid}`]);
  }
}
