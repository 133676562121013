<div class='page-container' fxLayout='column' fxFlex>
  <app-static-textfield-group
    [fields]='matrixInfoData'
  ></app-static-textfield-group>
  <div fxLayout='row' class='matrix-utils-container'>
    <div *ngIf='isMatrixLoaded'>
      <div
        *ngIf='!matrixData.is_actual'
        [style.opacity]='0.3'
        fxLayoutAlign='start center'
        fxFlex
      >
        Недавно было изменение в команде, матрица скоро обновится
      </div>
    </div>
    <div fxLayoutAlign='end center' fxLayoutGap='10px' fxFlex>
      <div class='ui-settings-select'>
        <mat-select
          [multiple]='true'
          placeholder='Настройки отображения'
        >
          <mat-option (onSelectionChange)='stickyUsers = !stickyUsers'>Закрепить пользователей</mat-option>
          <mat-option (onSelectionChange)='stickyCompetencies = !stickyCompetencies'>Закрепить навыки</mat-option>
        </mat-select>
      </div>
      <div *ngIf='specialities?.state.isLoaded' class='specialities-select'>
        <mat-select
          [disabled]='!isMatrixExpansionComplete'
          [value]="'Все специальности'"
          (selectionChange)='
                onFilterSpecialityChange(
                  $event.value
                )
              '
        >
          <mat-option [value]="'Все специальности'">Все специальности</mat-option>
          <mat-option
            *ngFor='let speciality of specialities.state.items'
            [value]='speciality.uuid'
          >
            {{ speciality.name }}
          </mat-option>
        </mat-select>
      </div>

      <button
        class='matrix-download-btn'
        mat-stroked-button
        (click)='downloadMatrix()'
        fxLayoutAlign='start center'
      >
        <mat-icon [style.margin-right.px]='5'>get_app</mat-icon>
        Выгрузить матрицу
      </button>
    </div>
  </div>
  <div class='matrix-container' fxLayout='column'>
    <div
      fxLayout='row'
      fxLayoutAlign='start center'
      class='matrix-desc-container'
    >
      <div class='matrix-desc-color' [style.background]="'#E10D34'"></div>
      <span>Bus factor</span>
      <div
        class='matrix-desc-color'
        [style.background]="'#13B810'"
        [style.margin-left]="'24px'"
      ></div>
      <span>Key people</span>
      <div
        class='matrix-desc-color'
        [style.background]="'#cae2f6'"
        [style.margin-left]="'24px'"
      ></div>
      <span>Не требуется, но владеет</span>
      <div
        class='matrix-desc-color'
        [style.background]="'#DDF5C5'"
        [style.margin-left]="'24px'"
      ></div>
      <span>Соответствует</span>
      <div
        class='matrix-desc-color'
        [style.background]="'#FFE8E1'"
        [style.margin-left]="'24px'"
      ></div>
      <span>Не соответствует</span>
    </div>
    <!--    <div-->
    <!--      class='matrix-header'-->
    <!--      fxLayout='row'-->
    <!--      fxLayoutAlign='start center'-->
    <!--      fxFlex-->
    <!--      fxLayoutGap='2rem'-->
    <!--      *ngIf='teams.length > 0 && specialities.state.isLoaded'-->
    <!--    ></div>-->
    <app-with-processing
      [processing]='!isMatrixLoaded'
    ></app-with-processing>
    <div
      *ngIf='
        isMatrixLoaded &&
        isMatrixTabSelected &&
        !isConfirmDialogOpened &&
        isAdmin() &&
        isOwner()
      '
    >
      {{ openTeamMembersStatusConfirmDialog() }}
    </div>
    <div *ngIf='!isMatrixExpansionComplete' class='loading-mask' fxLayout='row' fxLayoutAlign='center center'
         fxFill>
      <mat-progress-spinner
        [diameter]='70'
        [color]="'warn'"
        [mode]="'indeterminate'"
        [strokeWidth]='3'
      ></mat-progress-spinner>
    </div>
    <div class='matrix-content' *ngIf='matrixData && isMatrixLoaded'>
      <div class='header' [ngClass]="{'header_sticky': stickyUsers}" fxLayout='row' fxLayoutGap='8px'>
        <div fxFlex='529px' class='header-cell' fxLayout='row'>
          <div fxFlex='70' fxLayoutAlign='start center' class='header-cell-title'>
            <span>Компетенции</span>
            <!-- &nbsp;
            <a
              *ngIf="embedded"
              target="_blank"
              routerLink="/teams/{{ teamUuid }}/manage/matrix-v3"
            >
              <mat-icon matTooltip="Открыть на полный экран">
                fullscreen
              </mat-icon>
            </a> -->
          </div>
          <div fxFlex='30' fxLayoutAlign='end center' class='header-cell-expansion'>
            <span
              class='toggle-tree-expansion-btn'
              (click)='toggleTreeExpansion()'
            >
              {{ isMatrixExpanded ? 'Закрыть все' : 'Раскрыть все' }}
            </span>
          </div>
        </div>
        <div
          *ngFor='let teamUuid of getMatrixTeamUuids()'
          fxLayout='column'
          fxLayoutAlign='center center'
          class='user-cells'
        >
          <div fxLayout='row' *ngIf='teamStatusMap[teamUuid]' fxLayoutGap='8px'>
            <div *ngIf='!filteredUsers.length'>Нет участников с выбранной специальностью</div>
            <div
              *ngFor='let user of filteredUsers'
              fxFlex
            >
              <div
                class='user-cell'
                fxLayout='row'
                fxLayoutAlign='start start'
                fxFlex
              >
                <div
                  fxLayout='column'
                  fxLayoutAlign='start space-between'
                  [style.height]="'100%'"
                  fxFlex
                >
                  <div fxFlex>
                    <div
                      *ngIf='isAdmin() || isOwner()'
                      class='user-cell_clickable'
                      (click)='onMatrixUserClick(user)'
                    >
                      <div>{{ user.last_name }}</div>
                      <div>{{ user.first_name }}</div>
                    </div>
                    <div *ngIf='!isAdmin() && !isOwner()'>
                      <div>{{ user.last_name }}</div>
                      <div>{{ user.first_name }}</div>
                    </div>
                  </div>
                  <div fxFlex fxLayoutAlign='start end' class='user-level-text'>
                    <span>{{ getUserGradeName(teamUuid, user.uuid) }}</span>
                  </div>
                </div>
                <div fxLayoutAlign='start end' fxFlex='20px'>
                  <div
                    class='team-member-icon-container'
                    *ngIf='
                      teamMembersStatusMap[user.uuid] &&
                      (isAdmin() || isOwner())
                    '
                  >
                    <div
                      class='team-member-bus-icon'
                      *ngIf='teamMembersStatusMap[user.uuid].bus_factor'

                      matTooltipPosition='below'
                    > <!--                      matTooltip='-->
                      <!--                    {{ teamMembersStatusMap[user.uuid].bus_factor_reason }}-->
                      <!--                    '--></div>
                    <div
                      class='team-member-key-icon'
                      *ngIf='teamMembersStatusMap[user.uuid].key_people'

                      matTooltipPosition='below'
                    > <!--                      matTooltip='{{-->
                      <!--                        teamMembersStatusMap[user.uuid].key_people_reason-->
                      <!--                      }}'--></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout='row' fxLayoutGap='8px'>
        <div fxLayout='column' fxFlex='377px' fxLayoutGap='8px' class='competencies-tree'
             [ngClass]="{'competencies-tree_sticky': stickyCompetencies}">
          <div *ngFor='let node of dataSource.data'>
            <ng-template
              [ngIf]='dataSource'
              [ngTemplateOutlet]='skillNodeTemplateRef'
              [ngTemplateOutletContext]='{ node: node }'
            >
            </ng-template>
          </div>
        </div>
        <div fxLayout='column' fxFlex='144px' fxLayoutGap='8px'>
          <div *ngFor='let node of dataSource.data'>
            <ng-template
              [ngIf]='dataSource'
              [ngTemplateOutlet]='summaryNodeTemplateRef'
              [ngTemplateOutletContext]='{ node: node }'
            >
            </ng-template>
          </div>
        </div>
        <div fxLayout='column' fxLayoutGap='8px'>
          <div *ngFor='let node of dataSource.data'>
            <ng-template
              [ngIf]='dataSource'
              [ngTemplateOutlet]='matrixNodeTemplateRef'
              [ngTemplateOutletContext]='{ node: node }'
            >
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #skillNodeTemplateRef let-node='node'>
  <div
    class='tree-cell'
    [class.gray-background]='!isNodeUneven(node)'
    [class.white-background]='isNodeUneven(node)'
    fxLayout='column'
    [style.padding-bottom]="node.item.isExpanded ? '8px' : ''"
    fxFlex
  >
    <div
      fxLayout='row'
      [style.margin-bottom]="node.item.isExpanded ? '8px' : ''"
    >
      <button
        mat-icon-button
        *ngIf='node.children.length'
        (click)='node.item.isExpanded = !node.item.isExpanded'
        [style.padding]="'4px 0 4px'"
        [ngStyle]='getNodeMargin(node)'
      >
        <mat-icon>
          {{ node.item.isExpanded ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <div *ngIf='!node.children.length' class='empty-icon'></div>
      <span fxLayoutAlign='start center' class='node-name'>
        {{ node.item.name }}
      </span>
    </div>

    <div
      *ngIf='node.children.length && node.item.isExpanded'
      fxLayout='column'
      fxLayoutGap='8px'
    >
      <ng-template ngFor let-child [ngForOf]='node.children'>
        <ng-template
          [ngTemplateOutlet]='skillNodeTemplateRef'
          [ngTemplateOutletContext]='{ node: child }'
        >
        </ng-template>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #summaryNodeTemplateRef let-node='node'>
  <div fxLayout='column'>
    <div
      fxLayoutAlign='start center'
      [style.margin-bottom]="node.item.isExpanded ? '8px' : ''"
    >
      <div *ngIf='!node.children.length'>
        <div *ngFor='let teamUuid of getMatrixTeamUuids()'>
          <div *ngIf='teamStatusMap[teamUuid]' fxLayoutGap='8px'>
            <div
              *ngIf='
                getThingSummary(
                  getTeamUsersValues(teamUuid),
                  node.item.uuid
                ) as thingSummary
              '
              fxLayout='row'
              fxLayoutAlign='space-between center'
              class='summary-cell'
            >
              <span
                [style.color]="'#82C4FF'"
                fxFlex
                fxLayoutAlign='center center'
              >{{ thingSummary.additionalCount }}</span
              >
              <span
                [style.color]="'#8EDE3F'"
                fxFlex
                fxLayoutAlign='center center'
              >{{ thingSummary.matchedCount }}</span
              >
              <span
                [style.color]="'#FFA184'"
                fxFlex
                fxLayoutAlign='center center'
              >{{ thingSummary.unmatchedCount }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div *ngIf='node.children.length'>
        <div *ngFor='let teamUuid of getMatrixTeamUuids()'>
          <div *ngIf='teamStatusMap[teamUuid]' fxLayoutGap='8px'>
            <div
              *ngIf='
                getDomainSummary(
                  getTeamUsersValues(teamUuid),
                  node.item.uuid
                ) as domainSummary
              '
              fxLayout='row'
              fxLayoutAlign='space-between center'
              class='summary-cell'
            >
              <span
                [style.color]="'#82C4FF'"
                fxFlex
                fxLayoutAlign='center center'
              >{{ domainSummary.additionalCount }}</span
              >
              <span
                [style.color]="'#8EDE3F'"
                fxFlex
                fxLayoutAlign='center center'
              >{{ domainSummary.matchedCount }}</span
              >
              <span
                [style.color]="'#FFA184'"
                fxFlex
                fxLayoutAlign='center center'
              >{{ domainSummary.unmatchedCount }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div
        *ngIf='node.children.length && node.item.isExpanded'
        class='matrix-child'
        fxLayout='column'
        fxLayoutGap='8px'
        [style.margin-bottom]="node.item.isExpanded ? '8px' : ''"
      >
        <ng-template ngFor let-child [ngForOf]='node.children'>
          <ng-template
            [ngTemplateOutlet]='summaryNodeTemplateRef'
            [ngTemplateOutletContext]='{ node: child }'
          >
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #matrixNodeTemplateRef let-node='node'>
  <div fxLayout='column' class='matrix-group'>
    <div
      fxLayoutAlign='start center'
      [style.margin-bottom]="node.item.isExpanded ? '8px' : ''"
    >
      <div *ngIf='!node.children.length'>
        <div *ngFor='let teamUuid of getMatrixTeamUuids()'>
          <div *ngIf='teamStatusMap[teamUuid]' fxLayoutGap='8px'>
            <div
              *ngFor='let userUuid of getTeamUsers(teamUuid)'
              fxFlex
              fxLayout='row'
              fxLayoutAlign='start center'
              class='cell'
              matTooltipPosition='right'
              [style.background]='thingCellsDataMap[userUuid][node.item.uuid].color'
            >
              <div fxFlex>
                <span>{{thingCellsDataMap[userUuid][node.item.uuid].text}}</span>
              </div>

              <div fxFlex='10' fxLayoutAlign='end start'>
                <mat-icon
                  *ngIf='thingCellsDataMap[userUuid][node.item.uuid].desired'
                  class='desired-icon'

                >
                  favorite <!--                  matTooltip='Желаемый навык'-->
                </mat-icon>
              </div>
              <!--              matTooltip='{{-->
              <!--                getCellTooltip(userData.things[node.item.uuid]?.compliance)-->
              <!--              }}'-->
            </div>
          </div>
        </div>
      </div>
      <div *ngIf='node.children.length'>
        <div *ngFor='let teamUuid of getMatrixTeamUuids()'>
          <div *ngIf='teamStatusMap[teamUuid]' fxLayoutGap='8px'>
            <div
              *ngFor='let userUuid of getTeamUsers(teamUuid)'
              fxFlex
              class='cell'
              fxLayoutAlign='start center'
              [style.background]='domainCellsDataMap[userUuid][node.item.uuid].color'
            >
              <span>
                {{ domainCellsDataMap[userUuid][node.item.uuid].text }}
              </span>
              <!--              <span *ngIf='!userData.domains[node.item.uuid]'-->
              <!--              >Не требуется</span-->
              <!--              >-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div
        *ngIf='node.children.length && node.item.isExpanded'
        class='matrix-child'
        fxLayout='column'
        fxLayoutGap='8px'
        [style.margin-bottom]="node.item.isExpanded ? '8px' : ''"
      >
        <ng-template ngFor let-child [ngForOf]='node.children'>
          <ng-template
            [ngTemplateOutlet]='matrixNodeTemplateRef'
            [ngTemplateOutletContext]='{ node: child }'
          >
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<!--<form [formGroup]="toggleShowTeamsForm">-->
<!--  <mat-form-field fxFill class="mat-select-container">-->
<!--    <mat-label>Показать команды:</mat-label>-->
<!--    <mat-select-->
<!--      formControlName="teams"-->
<!--      multiple-->
<!--      style="max-height: 3rem"-->
<!--    >-->
<!--      <mat-option-->
<!--        *ngFor="let team of matrixData.teams"-->
<!--        [value]="team"-->
<!--        (onSelectionChange)="toggleTeamStatus($event)"-->
<!--      >-->
<!--        {{ team.name }}-->
<!--      </mat-option>-->
<!--    </mat-select>-->
<!--  </mat-form-field>-->
<!--</form>-->

<!--<div [style.width.rem]="30" fxLayout="column">-->
<!--  <ng-select-->
<!--    fxFlex-->
<!--    placeholder="Загрузить команды:"-->
<!--    [items]="teams"-->
<!--    [(ngModel)]="defaultTeam"-->
<!--    [multiple]="true"-->
<!--    [closeOnSelect]="false"-->
<!--    (change)="updateTeams($event)"-->
<!--    notFoundText="Команды не найдены"-->
<!--    bindLabel="name"-->
<!--  ></ng-select>-->
<!--  <mat-checkbox (click)="onToggleLoadChildTeams()" fxFlex>-->
<!--    Загружать дочерние команды-->
<!--  </mat-checkbox>-->
<!--</div>-->
<!--<form [formGroup]="selectInputDataForm">-->
<!--  <mat-form-field-->
<!--    [style.width.rem]="30"-->
<!--    class="mat-select-container"-->
<!--    fxFlex-->
<!--  >-->
<!--    <mat-label>Загрузить специальности:</mat-label>-->
<!--    <mat-select formControlName="specialities" multiple>-->
<!--      <mat-option-->
<!--        *ngFor="let speciality of specialities.state.items"-->
<!--        [value]="speciality"-->
<!--      >-->
<!--        {{ speciality.name }}-->
<!--      </mat-option>-->
<!--    </mat-select>-->
<!--  </mat-form-field>-->
<!--</form>-->
<!--<button mat-raised-button (click)="loadMatrixData()" class="load-button">-->
<!--  Загрузить-->
<!--</button>-->
