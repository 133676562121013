import { Component, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';

import * as d3 from 'd3';

import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-simple-radar-chart',
  templateUrl: './simple-radar-chart.component.html',
  styleUrls: ['./simple-radar-chart.component.css']
})
export class SimpleRadarChartComponent implements OnInit, OnChanges, AfterViewInit {

  @Input()
  set chart_name(html_el_class_name: string) {
    this._html_element_selector_class = html_el_class_name;
  }
  get chart_name(): string { return this._html_element_selector_class; }

  @Input()
  set data(input_data: any[]) {
    this._data = input_data;
  }
  get data(): any[] { return this._data; }

  private _html_element_selector_class: string;
  private _data: any;

  private _svg_options = {
    width: 400,
    height: 100,
    margin: {
      top: 30,
      right: 10,
      bottom: 50,
      left: 30
    },
    colors: {
      bar: '#eee'
    }
  };

  constructor(private logging_service: LoggingService) { }

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.draw_chart(), 300);
    // this.draw_chart();
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => this.draw_chart(), 300);
    // this.draw_chart();
  }

  private get svg_width(): any { return this._svg_options.width + this.svg_margin_left + this.svg_margin_right + 50; }
  private get svg_height(): any { return this._svg_options.height + this.svg_margin_top + this.svg_margin_bottom + 50; }
  private get svg_margin_top(): any { return this._svg_options.margin.top; }
  private get svg_margin_bottom(): any { return this._svg_options.margin.bottom; }
  private get svg_margin_left(): any { return this._svg_options.margin.left; }
  private get svg_margin_right(): any { return this._svg_options.margin.right; }
  private get svg_color_bar(): any { return this._svg_options.colors.bar; }

  private get svg_effective_width(): any { return this.svg_width - this.svg_margin_left - this.svg_margin_right }
  private get svg_effective_height(): any { return this.svg_height - this.svg_margin_top - this.svg_margin_bottom }

  private draw_chart() {
    this.logging_service.debug(`${this.constructor.name} draw chart`);

    const data = [
      {
        name: 'Random person',
        axes: [
          {axis: 'Проектное управление', name: 'Проектное управление', value: 6},
          {axis: 'Управление командой', name: 'Управление командой', value: 4},
          {axis: 'Архитектура', name: 'Архитектура', value: 6},
          {axis: 'Разработка', name: 'Разработка', value: 4},
          {axis: 'Контроль качества', name: 'Контроль качества', value: 5},
          {axis: 'Эксплуатация', name: 'Эксплуатация', value: 10},
          {axis: 'Инфобез', name: 'Информационная безопасность', value: 5},
          {axis: 'Мобильная разработка', name: 'Мобильная разработка', value: 2}
        ]
      }
    ];

    const max = Math.max;
    const sin = Math.sin;
    const cos = Math.cos;
    const HALF_PI = Math.PI / 2;

    // Wraps SVG text - Taken from http://bl.ocks.org/mbostock/7555321
    const wrap = (text, width) => {
      text.each(function() {
        // BUT MAYBE?
        // tslint:disable-next-line:no-shadowed-variable
        const text = d3.select(this);
        const words = text.text().split(/\s+/).reverse();
        let word;
        let line = [];
        let lineNumber = 0;
        const lineHeight = 1.4; // ems
        const y = text.attr('y');
        const x = text.attr('x');
        const dy = parseFloat(text.attr('dy'));
        let tspan = text.text(null).append('tspan').attr('x', x).attr('y', y).attr('dy', dy + 'em');

        // tslint:disable-next-line:no-conditional-assignment
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(' '));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            tspan = text.append('tspan').attr('x', x).attr('y', y).attr('dy', ++lineNumber * lineHeight + dy + 'em').text(word);
          }
        }
      });
    };

    const cfg = {
      w: 300, // Width of the circle
      h: 300, // Height of the circle
      margin: {top: 70, right: 70, bottom: 70, left: 70}, // The margins of the SVG
      levels: 3, // How many levels or inner circles should there be drawn
      maxValue: 0, // What is the value that the biggest circle will represent
      labelFactor: 1.25, // How much farther than the radius of the outer circle should the labels be placed
      wrapWidth: 60, // The number of pixels after which a label needs to be given a new line
      opacityArea: 0.75, // The opacity of the area of the blob
      dotRadius: 4, // The size of the colored circles of each blog
      opacityCircles: 0.1, // The opacity of the circles of each blob
      // gridCirclesFill: '#CDCDCD',
      gridCirclesFill: '#FFF',
      strokeWidth: 1.5, // The width of the stroke around each blob
      roundStrokes: true, // If true the area and stroke will follow a round path (cardinal-closed)
      color: d3.scaleOrdinal(d3.schemeCategory10), // Color function,
      // color: d3.scaleOrdinal().range(['#6e43ba', '#5e27bf', '#985dff']),
      format: '.2%',
      unit: '',
      legend: false,
      title: false
    };

    // If the supplied maxValue is smaller than the actual one, replace by the max in the data
    // var maxValue = max(cfg.maxValue, d3.max(data, function(i){return d3.max(i.map(function(o){return o.value;}))}));
    let maxValue = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let j = 0; j < data.length; j++) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < data[j].axes.length; i++) {
        // tslint:disable-next-line:no-string-literal
        data[j].axes[i]['id'] = data[j].name;
        // tslint:disable-next-line:no-string-literal
        if (data[j].axes[i]['value'] > maxValue) {
          // tslint:disable-next-line:no-string-literal
          maxValue = data[j].axes[i]['value'];
        }
      }
    }
    maxValue = max(cfg.maxValue, maxValue);

    const allAxis = data[0].axes.map((i, j) => i.axis); // Names of each axis
    const total = allAxis.length; // The number of different axes
    const radius = Math.min(cfg.w / 2, cfg.h / 2); // Radius of the outermost circle
    // tslint:disable-next-line:variable-name
    const Format = d3.format(cfg.format); // Formatting
    const angleSlice = Math.PI * 2 / total; // The width in radians of each "slice"

    // Scale for the radius
    const rScale = d3.scaleLinear().range([0, radius]).domain([0, maxValue]);

    /////////////////////////////////////////////////////////
    //////////// Create the container SVG and g /////////////
    /////////////////////////////////////////////////////////
    const parent = d3.select(`.${this.chart_name}`);

    // redraw entire element
    parent.select('svg').remove();

    // Initiate the radar chart SVG
    const svg = parent.append('svg')
      .attr('width',  cfg.w + cfg.margin.left + cfg.margin.right)
      .attr('height', cfg.h + cfg.margin.top + cfg.margin.bottom)
      .attr('class', 'radar');

    // Append a g element
    const g = svg.append('g')
      .attr('transform', 'translate(' + (cfg.w / 2 + cfg.margin.left) + ',' + (cfg.h / 2 + cfg.margin.top) + ')');

    // Filter for the outside glow
    const filter = g.append('defs').append('filter').attr('id', 'glow');
    const feGaussianBlur = filter.append('feGaussianBlur').attr('stdDeviation', '2.5').attr('result', 'coloredBlur');
    const feMerge = filter.append('feMerge');
    const feMergeNode_1 = feMerge.append('feMergeNode').attr('in', 'coloredBlur');
    const feMergeNode_2 = feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

    /////////////////////////////////////////////////////////
    /////////////// Draw the Circular grid //////////////////
    /////////////////////////////////////////////////////////

    // Wrapper for the grid & axes
    const axisGrid = g.append('g').attr('class', 'axisWrapper');

    // Draw the background circles
    axisGrid.selectAll('.levels')
      .data(d3.range(1, (cfg.levels + 1)).reverse())
      .enter()
      .append('circle')
      .attr('class', 'gridCircle')
      .attr('r', d => radius / cfg.levels * d)
      .style('fill', cfg.gridCirclesFill)
      .style('stroke', '#CDCDCD')
      .style('fill-opacity', cfg.opacityCircles)
      .style('filter' , 'url(#glow)');

    // Text indicating at what % each level is
    axisGrid.selectAll('.axisLabel')
      .data(d3.range(1, (cfg.levels + 1)).reverse())
      .enter().append('text')
      .attr('class', 'axisLabel')
      .attr('x', 4)
      .attr('y', d => -d * radius / cfg.levels)
      .attr('dy', '0.4em')
      .style('font-size', '10px')
      .attr('fill', '#737373')
      .text('');
      // .text(d => Format(maxValue * d / cfg.levels) + cfg.unit);

    // Create the straight lines radiating outward from the center
    const axis = axisGrid.selectAll('.axis')
      .data(allAxis)
      .enter()
      .append('g')
      .attr('class', 'axis');

    // Append the lines
    axis.append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', (d, i) => rScale(maxValue * 1.1) * cos(angleSlice * i - HALF_PI))
      .attr('y2', (d, i) => rScale(maxValue * 1.1) * sin(angleSlice * i - HALF_PI))
      .attr('class', 'line')
      .style('stroke', '#aaa')
      .style('stroke-width', '2px');

    // Append the labels at each axis
    axis.append('text')
      .attr('class', 'legend')
      .style('font-size', '11px')
      .attr('text-anchor', 'middle')
      .attr('dy', '0em')
      .attr('x', (d, i) => rScale(maxValue * cfg.labelFactor) * cos(angleSlice * i - HALF_PI))
      .attr('y', (d, i) => rScale(maxValue * cfg.labelFactor) * sin(angleSlice * i - HALF_PI))
      .text(d => d as any)
      .call(wrap, cfg.wrapWidth);

    /////////////////////////////////////////////////////////
    ///////////// Draw the radar chart blobs ////////////////
    /////////////////////////////////////////////////////////

    // The radial line function
    const radarLine = d3.radialLine()
      .curve(d3.curveLinearClosed)
      .radius(d => rScale((d as any).value))
      .angle((d, i) => i * angleSlice);

    if (cfg.roundStrokes) {
      radarLine.curve(d3.curveCardinalClosed);
    }

    // Create a wrapper for the blobs
    const blobWrapper = g.selectAll('.radarWrapper')
      .data(data)
      .enter().append('g')
      .attr('class', 'radarWrapper');

    // Append the backgrounds
    blobWrapper
      .append('path')
      .attr('class', 'radarArea')
      .attr('d', d => radarLine((d as any).axes))
      // .style('fill', (d, i) => cfg.color(i as any))
      .style('fill', 'var(--accent-color-light)')
      .style('fill-opacity', cfg.opacityArea)
      .on('mouseover', function(d, i) {
        // Dim all blobs
        parent.selectAll('.radarArea')
          .transition().duration(200)
          .style('fill-opacity', 0.1);
        // Bring back the hovered over blob
        d3.select(this)
          .transition().duration(200)
          .style('fill-opacity', 0.7);
      })
      .on('mouseout', () => {
        // Bring back all blobs
        parent.selectAll('.radarArea')
          .transition().duration(200)
          .style('fill-opacity', cfg.opacityArea);
      });

    // Create the outlines
    blobWrapper.append('path')
      .attr('class', 'radarStroke')
      .attr('d', (d, i) => radarLine((d as any).axes))
      .style('stroke-width', cfg.strokeWidth + 'px')
      .style('stroke', 'var(--accent-color)')
      // .style('stroke', (d, i) => cfg.color(i as any))
      .style('fill', 'none')
      .style('filter' , 'url(#glow)');

    // Append the circles
    blobWrapper.selectAll('.radarCircle')
      .data(d => (d as any).axes)
      .enter()
      .append('circle')
      .attr('class', 'radarCircle')
      .attr('r', cfg.dotRadius)
      .attr('cx', (d, i) => rScale((d as any).value) * cos(angleSlice * i - HALF_PI))
      .attr('cy', (d, i) => rScale((d as any).value) * sin(angleSlice * i - HALF_PI))
      // .style('fill', (d) => cfg.color((d as any).id))
      .style('fill', 'var(--accent-color)')
      .style('fill-opacity', 0.8);

    /////////////////////////////////////////////////////////
    //////// Append invisible circles for tooltip ///////////
    /////////////////////////////////////////////////////////

    // Wrapper for the invisible circles on top
    const blobCircleWrapper = g.selectAll('.radarCircleWrapper')
      .data(data)
      .enter().append('g')
      .attr('class', 'radarCircleWrapper');

    // Append a set of invisible circles on top for the mouseover pop-up
    blobCircleWrapper.selectAll('.radarInvisibleCircle')
      .data(d => (d as any).axes)
      .enter().append('circle')
      .attr('class', 'radarInvisibleCircle')
      .attr('r', cfg.dotRadius * 1.5)
      .attr('cx', (d, i) => rScale((d as any).value * cos(angleSlice * i - HALF_PI)))
      .attr('cy', (d, i) => rScale((d as any).value) * sin(angleSlice * i - HALF_PI))
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mouseover', function(d, i) {
        tooltip
          .attr('x', this.cx.baseVal.value - 10)
          .attr('y', this.cy.baseVal.value - 10)
          .transition()
          .style('display', 'block')
          .text(Format((d as any).value) + cfg.unit);
      })
      .on('mouseout', _ => {
        tooltip.transition()
          .style('display', 'none').text('');
    });

    const tooltip = g.append('text')
      .attr('class', 'tooltip')
      .attr('x', 0)
      .attr('y', 0)
      .style('font-size', '12px')
      .style('display', 'none')
      .attr('text-anchor', 'middle')
      .attr('dy', '0.35em');

    if (cfg.legend !== false && typeof cfg.legend === 'object') {
      const legendZone = svg.append('g');
      const names = data.map(el => el.name);
      // if (cfg.legend.title) {
      //   const title = legendZone.append('text')
      //     .attr('class', 'title')
      //     .attr('transform', `translate(${cfg.legend.translateX},${cfg.legend.translateY})`)
      //     .attr('x', cfg.w - 70)
      //     .attr('y', 10)
      //     .attr('font-size', '12px')
      //     .attr('fill', '#404040')
      //     .text(cfg.legend.title);
      // }
      // const legend = legendZone.append('g')
      //   .attr('class', 'legend')
      //   .attr('height', 100)
      //   .attr('width', 200)
      //   .attr('transform', `translate(${cfg.legend.translateX},${cfg.legend.translateY + 20})`);
      // Create rectangles markers
      // legend.selectAll('rect')
      //   .data(names)
      //   .enter()
      //   .append("rect")
      //   .attr("x", cfg.w - 65)
      //   .attr("y", (d,i) => i * 20)
      //   .attr("width", 10)
      //   .attr("height", 10)
      //   .style("fill", (d,i) => cfg.color(i));
      // // Create labels
      // legend.selectAll('text')
      //   .data(names)
      //   .enter()
      //   .append("text")
      //   .attr("x", cfg.w - 52)
      //   .attr("y", (d,i) => i * 20 + 9)
      //   .attr("font-size", "11px")
      //   .attr("fill", "#737373")
      //   .text(d => d);
    }
    return svg;
  }

}
