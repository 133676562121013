import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';

import { UiComponents } from '@components/ui/ui.module';
import { CommonUIElementsModule } from 'src/app/modules/common-ui-elements/common-ui-elements.module';
import { CommonD3ChartsModule } from 'src/app/modules/common-d3-charts/common-d3-charts.module';

import { TrackRoutingModule } from './track-routing.module';
import { TrackSpecialityUserCompareComponent } from './components/speciality/track-speciality-user-compare/track-speciality-user-compare.component';
import { TrackPdpCreateComponent } from './components/pdp/track-pdp-create/track-pdp-create.component';
import { TrackPdpDetailsComponent } from './components/pdp/track-pdp-details/track-pdp-details.component';
import { TrackUserPdpsListComponent } from './components/pdp/track-user-pdps-list/track-user-pdps-list.component';
import { TrackUserPdpDialogCompleteComponent } from './components/pdp/track-user-pdp-dialog-complete/track-user-pdp-dialog-complete.component';
import { TrackDeltaOverviewComponent } from './components/delta/track-delta-overview/track-delta-overview.component';
import { PdpMainComponent } from './components/pdp/pdp-main/pdp-main.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PdpDetailsComponent } from './components/pdp/pdp-details/pdp-details.component';
import { CommonComponentsModule } from '@components/common/common.module';
import { AddEditPdpActivityDialogComponent } from './components/pdp/pdp-details/add-edit-pdp-activity-dialog/add-edit-pdp-activity-dialog.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PdpCreateComponent } from './components/pdp/pdp-create/pdp-create.component';
import { PdpEditComponent } from './components/pdp/pdp-edit/pdp-edit.component';

@NgModule({
  declarations: [
    TrackSpecialityUserCompareComponent,
    TrackPdpCreateComponent,
    TrackPdpDetailsComponent,
    TrackUserPdpsListComponent,
    TrackUserPdpDialogCompleteComponent,
    TrackDeltaOverviewComponent,
    PdpMainComponent,
    PdpDetailsComponent,
    AddEditPdpActivityDialogComponent,
    PdpCreateComponent,
    PdpEditComponent,
  ],
  imports: [
    CommonModule,
    UiComponents,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MomentModule,

    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatOptionModule,
    MatDividerModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatDialogModule,

    CommonUIElementsModule,
    CommonD3ChartsModule,

    TrackRoutingModule,
    MatTabsModule,
    MatProgressBarModule,
    CommonComponentsModule,
    NgSelectModule,
  ],
  exports: [
    TrackPdpCreateComponent,
    TrackPdpDetailsComponent,
    TrackUserPdpsListComponent,
    TrackDeltaOverviewComponent,
  ],
})
export class TrackModule {}
