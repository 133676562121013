import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewSetHttpService } from './rest/ViewSetHttpService';
import { ResolutionResponse } from '@models/reviews/resolution-response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResolutionResponseHttpService extends ViewSetHttpService<ResolutionResponse> {
  http: HttpClient;
  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }
  public viewSetUrl = 'reviews/resolution-response';

  public accept({
    resolution,
    level,
  }: {
    resolution: string;
    level: string;
  },
  options: { params?: { expand: string } } = {}
  ): Observable<any> {
    return this.http.post<{ resolution: string; level: string }>(
      `${this.getUrl()}/${resolution}/accept`,
      {
        level
      },
      options
    );
  }
}
