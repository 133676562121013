import { Component, Input, OnInit } from '@angular/core';

export interface StaticTextField {
  name: string;
  text: string;
}

@Component({
  selector: 'app-static-textfield-group',
  templateUrl: './static-textfield-group.component.html',
  styleUrls: ['./static-textfield-group.component.css'],
})
export class StaticTextfieldGroupComponent implements OnInit {
  @Input() fields: StaticTextField[];

  constructor() {}

  ngOnInit(): void {}
}
