import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-review-create-competencies-edit-dialog',
  templateUrl: './review-create-competencies-edit-dialog.component.html',
  styleUrls: ['./review-create-competencies-edit-dialog.component.css'],
})
export class ReviewCreateCompetenciesEditDialogComponent implements OnInit {
  selectedClaims: string[];
  constructor(
    public dialogRef: MatDialogRef<ReviewCreateCompetenciesEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      specialityUuid: string;
      selectedClaims: string[];
      onSubmit(selectedClaims: string[]): void;
    }
  ) {}

  ngOnInit(): void {
    this.selectedClaims = this.data.selectedClaims;
  }

  onSelectedClaimsChange(selectedClaims: string[]): void {
    this.selectedClaims = selectedClaims;
  }

  submit(): void {
    this.data.onSubmit(this.selectedClaims);
    this.dialogRef.close();
  }
}
