import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';

import { MomentModule } from 'ngx-moment';

/*
  Base app functionality
*/
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './guards/auth.guard';
import { PermissionInterceptor } from './core/interceptors/PermissionInterceptor';
import { AlertComponent } from '@components/ui/alert/alert.component';
import { PageNotFoundComponent } from '@components/ui/page-not-found/page-not-found.component';
import { ApplicationErrorComponent } from '@components/ui/application-error/application-error.component';
import { FooterComponent } from '@components/ui/footer/footer.component';

/*
  Feature modules
*/
import { CommonUIElementsModule } from './modules/common-ui-elements/common-ui-elements.module';
import { UiComponents } from '@components/ui/ui.module';
import { CommonFormModule } from '@components/form/form.module';

import { CommonD3ChartsModule } from './modules/common-d3-charts/common-d3-charts.module';

import { AdminModule } from './modules/admin/admin.module';
import { UserProfileModule } from './modules/user-profile/user-profile.module';
import { OnboardingModule } from './modules/onboarding/onboarding.module';
import { TeamsModule } from './modules/teams/teams.module';
import { SurveysModule } from './modules/surveys/surveys.module';
import { OntologiesModule } from './modules/ontologies/ontologies.module';
import { TrackModule } from './modules/track/track.module';
import { LibraryModule } from 'src/app/modules/library/library.module';
import { ReviewModule } from 'src/app/modules/review/review.module';
import { ProjectsModule } from './modules/projects/projects.module';

import { environment } from 'src/environments/environment';
import { AccessDeniedComponent } from '@components/ui/access-denied/access-denied.component';
import { getRussianPaginatorIntl } from 'src/app/utils/paginator_translate';
import { LibraryMainPage } from 'src/app/pages/library/library-main.page';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { SpecialityGroupCreatePage } from 'src/app/pages/library/speciality-groups/speciality-group-create/speciality-group-create.page';
import { SpecialityGroupEditPage } from '@pages/library/speciality-groups/speciality-group-edit/speciality-group-edit.page';

import { ThingPage } from '@pages/library/thing/thing.page';
import { ThingEditPage } from '@pages/library/thing/thing-edit/thing-edit.page';
import { ThingCreatePage } from '@pages/library/thing/thing-create/thing-create.page';
import { LibraryDomainPage } from '@pages/library/domain/domain.page';
import { SpecialityEditPage } from '@pages/library/speciality/speciality-edit/speciality-edit.page';
import { SpecialityCreatePage } from '@pages/library/speciality/speciality-create/speciality-create.page';
import { SpecialityDetailPage } from './pages/library/speciality/speciality-detail/speciality-detail.page';
import { SpecialityListPage } from './pages/library/speciality/speciality-list/speciality-list.page';

import { DocsTutorialQuickstartPage } from '@pages/docs/tutorial-quickstart.page';
import { MatchUsersPage } from '@pages/teams/match-users/match-users.page';
import { MatrixV3Page } from '@pages/teams/matrix-v3/matrix-v3.page';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MainTeamsPage } from '@pages/teams/main/main.page';
import { SpecialityVerificationModule } from './modules/speciality-verification/speciality-verification.module';
import { OnboardingTeamsDialogComponent } from './components/rosbank/onboarding-teams-dialog/onboarding-teams-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { WysiwigExampleComponent } from './pages/wysiwig-example/wysiwig-example.component';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ApplicationErrorComponent,
    FooterComponent,
    AlertComponent,
    AccessDeniedComponent,
    LibraryMainPage,
    SpecialityGroupCreatePage,
    SpecialityGroupEditPage,
    ThingPage,
    ThingEditPage,
    ThingCreatePage,
    LibraryDomainPage,
    SpecialityEditPage,
    SpecialityCreatePage,
    SpecialityDetailPage,
    SpecialityListPage,
    DocsTutorialQuickstartPage,
    MatchUsersPage,
    MatrixV3Page,
    MainTeamsPage,
    OnboardingTeamsDialogComponent,
    WysiwigExampleComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,

    KeycloakAngularModule,
    FlexLayoutModule,
    MomentModule,

    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,
    MatExpansionModule,

    // shared modules
    CommonUIElementsModule,
    CommonD3ChartsModule,

    // feature modules
    UserProfileModule,
    OnboardingModule,
    TeamsModule,
    AdminModule,
    SurveysModule,
    OntologiesModule,
    TrackModule,
    LibraryModule,
    CommonUIElementsModule,
    UiComponents,
    CommonFormModule,
    ReviewModule,
    ProjectsModule,
    SpecialityVerificationModule,
    MatTooltipModule,
    MatDialogModule,
    AngularEditorModule,
  ],
  exports: [LibraryModule, CommonFormModule, MatchUsersPage],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PermissionInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },

    { provide: MatPaginatorIntl, useValue: getRussianPaginatorIntl() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
