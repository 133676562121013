import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { TeamSettings } from '@models/teams/team-settings';

@Injectable({
  providedIn: 'root',
})
export class TeamSettingsHttpService extends ViewSetHttpService<TeamSettings> {
  http: HttpClient;
  constructor(_http: HttpClient) {
    super(_http);
    this.http = _http;
  }

  public viewSetUrl = 'teams/settings';
}
