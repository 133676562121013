import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Team } from '@models/teams/team';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

export interface TeamData {
  teamName: string;
  teamDescription: string;
  teamParent: Team;
  teamType: 'agile' | 'admin' | 'guild';
}

@Component({
  selector: 'app-team-create-dialog',
  templateUrl: './team-create-dialog.component.html',
  styleUrls: ['./team-create-dialog.component.css'],
})
export class TeamCreateDialogComponent implements OnInit {
  form: UntypedFormGroup;
  teamTypes = ['agile', 'admin', 'guild'];

  constructor(
    public dialogRef: MatDialogRef<TeamCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      teams: Team[];
      parentTeam: Team;
      isTypeHidden: boolean;
      isOnboarding: boolean;
      onCreateTeam(formData: TeamData): void;
    },
    private _formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this._initForm();
  }

  private _initForm(): void {
    this.form = this._formBuilder.group({
      teamName: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      teamDescription: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
      teamParent: ['', []],
      teamType: ['agile', []],
    });
    if (!this.isParentTeamEmpty) {
      this.form.controls.teamParent.setValue(this.data.parentTeam);
      this.form.controls.teamParent.disable();
      this.form.controls.teamType.disable();
    }
  }

  get isParentTeamEmpty(): boolean {
    return !this.data.parentTeam;
  }

  close(): void {
    if (!this.isParentTeamEmpty) {
      this.form.controls.teamParent.enable();
      this.form.controls.teamType.enable();
    }
    if (!this.form.controls.teamParent.value) {
      this.form.controls.teamParent.setValue('');
    }
    this.data.onCreateTeam(this.form.value);
    this.dialogRef.close();
  }
}
