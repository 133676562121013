<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="domains-tree"
>
  <mat-tree-node *matTreeNodeDef="let node"
                 [ngStyle]="getNodePadding(treeFlattener.getLevel(node))"
  >
    <button mat-icon-button disabled></button>
      <div
        class="thing-level mat-tree-node__thing"
        [class.selected]="node.uuid === activeThingUuid"
        [class.thing-level_disabled]="isThingDisabled(node.uuid)"
        (click)="onSetActiveThing(node.uuid)"
      >
        <div class="thing-level__name">
          {{ node.name }}
        </div>
        <div class="red-icon">
          <mat-icon>{{ getIcon(node.uuid) }}</mat-icon>
        </div>
      </div>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
                 [ngStyle]="getNodePadding(treeFlattener.getLevel(node))"
  >
    <div class="domain-level mat-tree-node__domain">
      <button
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <a class='domain-level__link'>
        {{ node.name }}
      </a>
      <mat-icon class='red-icon'>
        {{ getDomainIcon(node.uuid) }}
      </mat-icon>
    </div>
  </mat-tree-node>
</mat-tree>
