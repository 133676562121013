import { Component, OnInit } from '@angular/core';
import { TeamMembershipHttpService } from '@services/http/TeamMembershipHttpService';
import { AsyncList } from '@rest/AsyncList';
import { TeamMembership } from '@models/teams/team-membership';
import { User } from '@models/user/user';
import { ActivatedRoute, Router } from '@angular/router';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityCompetenceClaimHttpService } from '@services/http/SpecialityCompetenceClaimHttpService';
import { DomainHttpService } from '@services/http/DomainHttpService';
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ReviewCreateCompetenciesEditDialogComponent } from './review-create-competencies-edit-dialog/review-create-competencies-edit-dialog.component';
import { ReviewCreateReviewersEditDialogComponent } from './review-create-reviewers-edit-dialog/review-create-reviewers-edit-dialog.component';
import { AuthService } from '../../../../../../services/auth/auth.service';
import { ReviewHttpService } from '@services/http/ReviewHttpService';
import { SpecialityCompetenceClaim } from '@models/specialities/speciality-competence-claim';
import { get_user_initials } from 'src/app/utils/template-filters/get-user-initials';
import { ReviewCreateResponsibleEditDialogComponent } from './review-create-responsible-edit-dialog/review-create-responsible-edit-dialog.component';
import { TitleService } from 'src/app/services/title.service';

interface ReviewSubjectData {
  subject: User;
  reviewers: User[];
  specialityClaims: string[];
}

interface ReviewData {
  [subjectUuid: string]: ReviewSubjectData;
}

@Component({
  selector: 'app-team-review-create',
  templateUrl: './team-review-create.component.html',
  styleUrls: ['./team-review-create.component.css'],
})
export class TeamReviewCreateComponent implements OnInit {
  teamUuid: string;
  preview = false;

  //for step 1
  teamMemberships: AsyncList<TeamMembership>;
  teamSubjects: User[];

  reviewInfoForm: UntypedFormGroup;

  //for step 2
  isSelfReview = true; //параметр-заглушка, будем смотреть в общую структуру данных

  //for step 3
  teamSpecialities: AsyncList<Speciality>;
  selectedSpeciality: Speciality;

  //for step 4
  specialityClaims: AsyncList<SpecialityCompetenceClaim>;
  selectedClaims: string[] = [];

  //for step 5
  teamReviewers: User[];
  selectedReviewers: User[] = [];

  //for final step
  reviewData: ReviewData = {};
  reviewResponsible: User = null;
  isCreateButtonDisabled = false;

  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    private _dateAdapter: DateAdapter<Date>,
    private _authService: AuthService,
    private _reviewHttpService: ReviewHttpService,
    private _teamMembershipHttpService: TeamMembershipHttpService,
    private _specialitiesHttpService: SpecialityHttpService,
    private _specialityCompetenceClaimHttpService: SpecialityCompetenceClaimHttpService,
    private _domainHttpService: DomainHttpService,
    private _titleService: TitleService
  ) {
    this._dateAdapter.setLocale('ru-RU'); // dd/MM/yyyy
  }

  ngOnInit(): void {
    this._titleService.set_title('Новое ревью');
    this.teamUuid = this._activatedRoute.snapshot.paramMap.get('uuid');
    this.reviewInfoForm = this._formBuilder.group({
      dateFrom: ['', [Validators.required]],
      dateTo: ['', [Validators.required]],
      name: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      search: ['', []],
    });
    this.reviewInfoForm.controls.dateFrom.setValue(new Date());
    const dateTo = new Date(new Date().setMonth(new Date().getMonth() + 2));
    this.reviewInfoForm.controls.dateTo.setValue(
      new Date(
        `${dateTo.getMonth()}/${dateTo.getDate()}/${dateTo.getFullYear()}`
      )
    );
    this._loadMemberships();
  }

  private _loadMemberships(): void {
    this.teamMemberships = new AsyncList<TeamMembership>(
      this._teamMembershipHttpService
    );
    this.teamMemberships.setRequestParams({
      params: {
        team: this.teamUuid,
        expand: 'user',
        page_size: 100,
      },
    });
    this.teamMemberships.load().subscribe(() => {
      this.teamSubjects = this._membershipsToUsers(
        this.teamMemberships.state.items
      );
    });
  }

  onNextMatStep(step: any): void {
    switch (step.selectedIndex) {
      case 2:
        this.onSpecialitiesStep();
        break;
      case 3:
        this.onCompetenciesStep();
        break;
      case 4:
        this.onReviewersStep();
        break;
    }
  }

  isFirstStepCompleted(): boolean {
    return !(this.reviewInfoForm && this.reviewInfoForm.invalid);
  }

  isCompetenciesStepCompleted(): boolean {
    return this.selectedClaims?.length > 0;
  }

  searchSubject(): void {
    this.teamSubjects = this._membershipsToUsers(
      this.teamMemberships.state.items.filter((membership) => {
        const user = membership.user as User,
          searchValue = this.reviewInfoForm.controls.search.value;
        return (
          !!user.email.toLowerCase().includes(searchValue.toLowerCase()) ||
          !!user.last_name.toLowerCase().includes(searchValue.toLowerCase()) ||
          !!user.first_name.toLowerCase().includes(searchValue.toLowerCase())
        );
      })
    );
  }

  private _membershipsToUsers(memberships: TeamMembership[]): User[] {
    return memberships.map((membership) => membership.user) as User[];
  }

  onSelectUsers(users: User[]): void {
    this.reviewInfoForm.controls.subject.setValue(users);
  }

  onSpecialitiesStep(): void {
    this.teamSpecialities = new AsyncList<Speciality>(
      this._specialitiesHttpService
    );
    this.teamSpecialities.setRequestParams({
      params: {
        team: this.teamUuid,
      },
    });
    this.teamSpecialities.load().subscribe();
  }

  selectSpeciality(speciality: Speciality): void {
    if (this.isSpecialitySelected(speciality.uuid)) {
      this.selectedSpeciality = null;
    } else {
      this.selectedSpeciality = speciality;
    }
  }

  isSpecialitySelected(specialityUuid: string): boolean {
    return (
      this.selectedSpeciality && this.selectedSpeciality.uuid === specialityUuid
    );
  }

  onCompetenciesStep(): void {
    this.specialityClaims = new AsyncList<SpecialityCompetenceClaim>(
      this._specialityCompetenceClaimHttpService
    );
    this.specialityClaims.setRequestParams({
      params: {
        speciality: this.selectedSpeciality.uuid,
        expand: 'thing',
      },
    });
    this.specialityClaims.load().subscribe();
  }

  onSelectedClaimsChange(selectedClaims: string[]): void {
    this.selectedClaims = selectedClaims;
  }

  onReviewersStep(): void {
    this.teamReviewers = this._membershipsToUsers(
      this.teamMemberships.state.items.filter(
        (membership) =>
          membership.role === 'owner' || membership.role === 'lead'
      )
    );
  }

  onSelectedReviewersChange(reviewers: User[]): void {
    this.selectedReviewers = reviewers;
  }

  onFinalStep(): void {
    this.reviewInfoForm.controls.subject.value.forEach((subject) => {
      this.reviewData[subject.uuid] = {
        subject: subject,
        reviewers: this.selectedReviewers,
        specialityClaims: this.selectedClaims,
      };
    });
    if (!this.reviewResponsible) {
      this.reviewResponsible = this._authService.get_current_user();
    }
    this.preview = true;
  }

  get PrettyReviewDate(): string {
    const dateFrom: Date = this.reviewInfoForm.controls.dateFrom.value,
      dateTo: Date = this.reviewInfoForm.controls.dateTo.value;
    return `${dateFrom.getDate()} ${dateFrom.toLocaleString('default', {
      month: 'short',
    })} - ${dateTo.getDate()} ${dateTo.toLocaleString('default', {
      month: 'short',
    })}`;
  }

  getReviewSubjectsData(): ReviewSubjectData[] {
    return Object.values(this.reviewData);
  }

  getSubjectClaim(claimUuid: string): string {
    return this.specialityClaims.state.items.find(
      (specialityClaim) => specialityClaim.thing.uuid === claimUuid
    )?.thing.name;
  }

  editSpecialityClaims(subjectData: ReviewSubjectData): void {
    this._dialog.open(ReviewCreateCompetenciesEditDialogComponent, {
      data: {
        specialityUuid: this.selectedSpeciality.uuid,
        selectedClaims: subjectData.specialityClaims,
        onSubmit: (selectedClaims: string[]) => {
          this.reviewData[subjectData.subject.uuid].specialityClaims =
            selectedClaims;
        },
      },
    });
  }

  editReviewers(subjectDataUuid: string): void {
    this._dialog.open(ReviewCreateReviewersEditDialogComponent, {
      data: {
        reviewers: this.reviewData[subjectDataUuid].reviewers,
        onSubmit: (reviewers: User[]) => {
          this.reviewData[subjectDataUuid].reviewers = reviewers;
        },
      },
    });
  }

  onResponsibleChange(): void {
    const teamOwners = this._membershipsToUsers(
      this.teamMemberships.state.items.filter(
        (membership) => membership.role === 'owner'
      )
    );
    this._dialog.open(ReviewCreateResponsibleEditDialogComponent, {
      data: {
        owners: teamOwners,
        onSubmit: (responsible: User) => {
          this.reviewResponsible = responsible;
        },
      },
    });
  }

  reviewCreate(): void {
    this.isCreateButtonDisabled = true;
    const reviewsData = [];
    Object.values(this.reviewData).forEach((subjectData) => {
      const reviewData = {
          description: '',
          name: this.reviewInfoForm.controls.name.value,
          responsible_user: this.reviewResponsible.uuid,
          reviewers: subjectData.reviewers.map((reviewer) => reviewer.uuid),
          subjects: [subjectData.subject.uuid],
          team: this.teamUuid,
          deadline_start:
            this.reviewInfoForm.controls.dateFrom.value.toISOString(),
          deadline_end: this.reviewInfoForm.controls.dateTo.value.toISOString(),
        },
        reviewConfig = {
          show_user_answers: true,
          update_review_answer: true,
          subject_self_review_map: {
            [subjectData.subject.uuid]: this.isSelfReview,
          },
          subject_speciality_map: {
            [subjectData.subject.uuid]: [this.selectedSpeciality.uuid],
          },
          subject_things_map: {
            [subjectData.subject.uuid]: subjectData.specialityClaims,
          },
        };
      reviewsData.push({ ...reviewData, config: reviewConfig });
    });
    this._reviewHttpService.createMultiple(reviewsData).subscribe(() => {
      this._router.navigate([`/team/${this.teamUuid}/reviews`]);
    });
  }

  getUserInitials = get_user_initials;
}
