<div class="router-outlet-container">
    <ng-container *ngIf="is_data_loaded; then component_content; else preloader">
    </ng-container>
</div>

<ng-template #preloader>
    <app-preloader></app-preloader>
</ng-template>

<ng-template #component_content>
    <div class="app-admin-survey-details-container">
        <div class="app-admin-survey-details-header-container">
            <div fxLayout="row">
                <div fxFlex>
                    <div class="app-admin-survey-details-header-name">
                        {{ survey.name }}
                    </div>
                    <div class="app-admin-survey-details-header-description">
                        {{ survey.description }}
                    </div>
                </div>
                <div fxFlex="21rem">
                    <div>
                        <div class="app-admin-survey-details-header-controls">
                            <a mat-stroked-button routerLink="/surveys/survey/{{ survey.uuid }}">
                                <mat-icon>poll</mat-icon>
                                Страница опроса
                            </a>
                            &nbsp;
                            <a mat-stroked-button routerLink="/admin/surveys/survey/{{ survey.uuid }}/edit">
                                <mat-icon>edit</mat-icon>
                            </a>
                            &nbsp;
                            <button mat-stroked-button color="warn"
                                (click)="on_button_survey_delete_show_tooltip($event)">
                                <mat-icon>delete_forever</mat-icon>
                            </button>
                        </div>
                        <div class="app-admin-survey-details-header-metadata">
                            Cоздан: {{ survey.date_created | amDateFormat: 'YYYY-MM-DD HH:mm' }}
                        </div>
                        <div #tooltip_delete_survey
                            class="app-admin-survey-details-header-delete-tooltip" style="display: none; opacity: 0;"
                            (mouseleave)="on_button_survey_delete_hide_tooltip()">
                            <div class="app-admin-survey-details-header-delete-tooltip-header">
                                Удалить опрос?
                            </div>
                            <div class="app-admin-survey-details-header-delete-tooltip-description">
                                Будут потеряны все связанные компетенции
                            </div>
                            <div fxLayout="row">
                                <div fxFlex fxLayoutAlign="space-around center">
                                    <button mat-raised-button color="warn"
                                        [disabled]="is_button_survey_tooltip_delete_disabled"
                                        (click)="on_tooltip_survey_delete_confirm()">
                                        Удалить
                                    </button>

                                    <button mat-raised-button (click)="on_tooltip_survey_delete_cancel()">
                                        Отмена
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="app-admin-survey-details-content-container">

            <mat-tab-group animationDuration="0ms">

                <mat-tab label="Пользовательские сессии">
                    <ng-template matTabContent>
                        <div class="app-admin-survey-details-content-tab-container">

                            <app-survey-details-tab-sessions [survey]="survey"></app-survey-details-tab-sessions>

                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Кому опрос был назначен">
                    <ng-template matTabContent>
                        <div class="app-admin-survey-details-content-tab-container">

                            <app-survey-details-tab-assignments [survey]="survey"></app-survey-details-tab-assignments>

                        </div>
                    </ng-template>
                </mat-tab>

            </mat-tab-group>

        </div>
    </div>
</ng-template>