import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { TitleService } from 'src/app/services/title.service';
import { DomainService } from 'src/app/services/ontology/domain.service';
import { ThingService } from 'src/app/services/ontology/thing.service';
import { TeamService } from 'src/app/services/teams/team.service';

import { Domain } from 'src/app/models/ontology/domain';
import { Thing } from 'src/app/models/ontology/thing';
import { Team } from 'src/app/models/teams/team';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { AsyncList } from '@rest/AsyncList';

@Component({
  selector: 'app-domain-details',
  templateUrl: './domain-details.component.html',
  styleUrls: ['./domain-details.component.css'],
})
export class DomainDetailsComponent implements OnInit {
  @ViewChild('tooltip_delete_domain') tooltip_delete_domain: ElementRef;
  @ViewChild('tooltip_delete_thing_listitem')
  tooltip_delete_thing_listitem: ElementRef;

  private domain_uuid: string;
  public domain: Domain;
  public domains: Domain[] = [];

  searchDomains: AsyncList<Domain>;

  public things: Thing[] = [];

  private thing_to_delete: Thing;

  public domain_edit_form: UntypedFormGroup;
  public thing_create_form: UntypedFormGroup;

  public team_transfer_search_form_control = new UntypedFormControl('', []);
  private team_transfer_search_terms = new Subject<string>();
  private team_transfer_search$: Observable<{ results: Team[] } | null>;
  public team_transfer_search_results: Team[] = [];
  public team_transfer_search_selected: Team;

  private uistate = {
    domain_loaded: false,
    domains_loaded: false,
    things_loaded: false,

    is_header_edit_mode: false,
    is_header_edit_loading: false,
    is_button_domain_tooltip_delete_disabled: false,

    thing_create_form_shown: false,
    thing_create_form_loading: false,
    is_button_thing_tooltip_delete_disabled: false,

    is_button_team_transfer_submit_disabled: false,
    is_button_team_transfer_public_disabled: false,
  };

  isSearchDomainsLoaded = false;

  selectedDomain: Domain;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private form_builder: UntypedFormBuilder,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private title_service: TitleService,
    private domain_service: DomainService,
    private thing_service: ThingService,
    private team_service: TeamService,
    private _domainHttpService: DomainHttpService
  ) {
    this.defile_domain_edit_form();
    this.define_thing_form();
  }

  ngOnInit(): void {
    this.title_service.set_title('Домен');
    this.route.params.subscribe((route_params) => {
      this.domain_uuid = route_params.domain_uuid;
      this.logging_service.debug(
        `${this.constructor.name} loading domain ${this.domain_uuid}`
      );
      this.uistate.domain_loaded = false;
      this.uistate.things_loaded = false;
      this.load_domain();
      this.load_domains(); // needed to change parent team on edit
    });
    this.logging_service.debug(`${this.constructor.name} init`);
    this.team_transfer_search$ = this.team_transfer_search_terms.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap((term: string) => this.team_service.search(term))
    );
    this.team_transfer_search$.subscribe(
      (response) => (this.team_transfer_search_results = response.results)
    );
  }

  public get is_data_loaded(): boolean {
    return (
      this.uistate.domain_loaded &&
      this.uistate.domains_loaded &&
      this.uistate.things_loaded
    );
  }

  public get in_edit_mode(): boolean {
    return this.uistate.is_header_edit_mode;
  }

  public get in_edit_mode_loading(): boolean {
    return this.uistate.is_header_edit_loading;
  }

  public get is_thing_create_form_shown(): boolean {
    return this.uistate.thing_create_form_shown;
  }

  public get is_thing_create_form_loading(): boolean {
    return this.uistate.thing_create_form_loading;
  }

  public get is_button_domain_tooltip_delete_disabled(): boolean {
    return this.uistate.is_button_domain_tooltip_delete_disabled;
  }

  public get is_button_thing_tooltip_delete_disabled(): boolean {
    return this.uistate.is_button_thing_tooltip_delete_disabled;
  }

  public get is_button_team_transfer_submit_disabled(): boolean {
    return (
      !this.team_transfer_search_selected ||
      this.uistate.is_button_team_transfer_submit_disabled
    );
  }

  public get is_button_team_transfer_public_disabled(): boolean {
    return (
      !this.domain.team || this.uistate.is_button_team_transfer_public_disabled
    );
  }

  private defile_domain_edit_form(): void {
    this.domain_edit_form = this.form_builder.group({
      domain_name: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      domain_description: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
      domain_parent: ['', []],
    });
  }

  private define_thing_form() {
    this.thing_create_form = this.form_builder.group({
      thing_name: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      thing_description: [
        '',
        [
          // Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
      thing_kind: [0, []],
    });
  }

  private load_domain(): void {
    this.domain_service.fetch_by_uuid(this.domain_uuid).subscribe(
      (response) => {
        this.domain = response as Domain;
        this.logging_service.debug(
          `${this.constructor.name} loaded domain ${this.domain.name}`
        );
        this.title_service.set_title(this.domain.name);
        this.uistate.domain_loaded = true;
        this.load_things();
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to load domain`
        );
        this.alert_service.error(`Ошибка загрузки домена ${err.status}`);
      }
    );
  }

  private load_domains(): void {
    this.domain_service.fetch_all().subscribe(
      (response) => {
        const domains = response.results as Domain[];
        this.domains = domains.filter((d) => d.uuid !== this.domain_uuid);
        this.logging_service.debug(
          `${this.constructor.name} loaded ${this.domains.length} domains`
        );
        this.uistate.domains_loaded = true;
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to load domains`
        );
        this.alert_service.error(`Ошибка загрузки доменов ${err.status}`);
      }
    );

    this.searchDomains = new AsyncList<Domain>(this._domainHttpService);
    this.searchDomains.setRequestParams({
      params: {
        ordering: '-name',
      },
    });
    this.searchDomains.load().subscribe(() => {
      this.searchDomains.state.items = this.searchDomains.state.items.filter(
        (domain) => domain.uuid !== this.domain_uuid
      );
      this.isSearchDomainsLoaded = true;
    });
  }

  private load_things(): void {
    this.thing_service.fetch_by_domain_uuid(this.domain.uuid).subscribe(
      (response) => {
        this.things = response.results as Thing[];
        this.uistate.things_loaded = true;
        this.logging_service.debug(
          `${this.constructor.name} loaded ${this.things.length} things`
        );
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to load things`
        );
        this.alert_service.error(`Ошибка загрузки сущностей ${err.status}`);
      }
    );
  }

  onDomainsSearch(name: string): void {
    this.searchDomains.setRequestParams({
      params: { search: name, ordering: '-name' },
    });
    this.searchDomains.load().subscribe(() => {
      this.searchDomains.state.items = this.searchDomains.state.items.filter(
        (domain) => domain.uuid !== this.domain_uuid
      );
    });
  }

  getParentDomain(domain: Domain): Domain {
    if (typeof domain.parent_domain === 'string') {
      return this.domains.find((d) => d.uuid === domain.parent_domain);
    }
    return domain.parent_domain;
  }

  onSelectDomainChange($event: string): void {
    this.domain_edit_form.controls.domain_parent.setValue(
      this.domains.find((domain) => domain.uuid === $event)
    );
  }

  public on_thing_show_create_form(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_thing_show_create_form`
    );
    this.uistate.thing_create_form_shown = true;
  }

  public on_button_thing_create_submit(): void {
    this.uistate.thing_create_form_loading = true;
    this.logging_service.debug(`${this.constructor.name} creating thing...`);
    const thing_data = {
      domain: this.domain.uuid,
      name: this.thing_create_form.value.thing_name,
      description: this.thing_create_form.value.thing_description
        ? this.thing_create_form.value.thing_description
        : 'нет описания',
      kind: this.thing_create_form.value.thing_kind,
    };
    this.thing_service.create(thing_data).subscribe(
      (response) => {
        this.logging_service.debug(
          `${this.constructor.name} successfully created thing`
        );
        const thing = response as Thing;
        this.things.push(thing);
        this.alert_service.success('Сущность создана');
        this.uistate.thing_create_form_loading = false;
        this.uistate.thing_create_form_shown = false;
        this.thing_create_form.setValue({
          thing_name: null,
          thing_description: null,
          thing_kind: 0,
        });
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} thing creation failed`
        );
        this.alert_service.error('Ошибка создания сущности');
        this.uistate.thing_create_form_loading = false;
      }
    );
  }

  public on_button_thing_create_cancel(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_button_thing_create_cancel`
    );
    this.uistate.thing_create_form_shown = false;
    this.thing_create_form.setValue({
      thing_name: null,
      thing_description: null,
      thing_kind: 0,
    });
  }

  public on_button_thing_delete_show_tooltip(thing, $event): void {
    this.thing_to_delete = thing;
    // const tooltip_style_left = `${$event.pageX - this.tooltip_delete_thing_listitem.nativeElement.offsetWidth + 1}px;`
    const tooltip_style_left = `${$event.pageX - 240 + 1}px;`;
    const tooltip_style_top = `${$event.pageY - 15}px;`;
    this.logging_service.debug(
      `${this.constructor.name} show delete tooltip ${$event.pageX} ${$event.pageY}`
    );
    this.logging_service.debug(
      `${this.constructor.name} left:${tooltip_style_left} top:${tooltip_style_top}`
    );
    this.tooltip_delete_thing_listitem.nativeElement.style.display = 'block';
    this.tooltip_delete_thing_listitem.nativeElement.style.opacity = 1.0;
    this.tooltip_delete_thing_listitem.nativeElement.setAttribute(
      'style',
      `top: ${tooltip_style_top} left: ${tooltip_style_left}`
    );
  }

  public on_button_thing_delete_hide_tooltip(): void {
    this.tooltip_delete_thing_listitem.nativeElement.style.opacity = 0.0;
    this.tooltip_delete_thing_listitem.nativeElement.style.display = 'none';
  }

  public on_tooltip_thing_delete_confirm(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_tooltip_delete_thing_confirm`
    );
    this.uistate.is_button_thing_tooltip_delete_disabled = true;
    this.thing_service.delete(this.thing_to_delete).subscribe(
      (response) => {
        this.uistate.is_button_thing_tooltip_delete_disabled = false;
        this.things = this.things.filter(
          (t) => t.uuid !== this.thing_to_delete.uuid
        );
        this.thing_to_delete = null;
        this.on_button_thing_delete_hide_tooltip();
        this.logging_service.debug(`${this.constructor.name} thing deleted`);
      },
      (err) => {
        this.uistate.is_button_thing_tooltip_delete_disabled = false;
        this.logging_service.error(
          `${this.constructor.name} failed to delete thing ${this.thing_to_delete.uuid}`
        );
        this.alert_service.error(`Ошибка удаления: ${err.status}`);
      }
    );
  }

  public on_tooltip_thing_delete_cancel(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_tooltip_thing_delete_cancel`
    );
    this.on_button_thing_delete_hide_tooltip();
  }

  public on_button_edit(): void {
    this.uistate.is_header_edit_mode = true;
    this.domain_edit_form.controls.domain_name.setValue(this.domain.name);
    this.domain_edit_form.controls.domain_description.setValue(
      this.domain.description
    );
    if (this.domain.parent_domain)
      this.selectedDomain = this.domain.parent_domain.uuid;

    this.domain_edit_form.controls.domain_parent.setValue(
      this.getParentDomain(this.domain)
    );
  }

  public on_button_edit_cancel(): void {
    this.uistate.is_header_edit_mode = false;
  }

  public on_button_save_edit_form(): void {
    if (this.domain_edit_form.value.domain_parent) {
      // check that we dont have a circular team hierarchy
      const selected_parent_domain_uuid =
        this.domain_edit_form.value.domain_parent.uuid;
      let found_circle = false;

      const check_domain_hierarchy_resursive = (
        current_depth,
        current_domain_uuid
      ) => {
        if (current_depth > 50) {
          found_circle = true;
          return;
        }
        if (current_domain_uuid === this.domain.uuid) {
          const selected_parent = this.domains.find(
            (d) => d.uuid === selected_parent_domain_uuid
          );
          check_domain_hierarchy_resursive(
            current_depth + 1,
            selected_parent.uuid
          );
        } else {
          const current_domain = this.domains.find(
            (d) => d.uuid === current_domain_uuid
          );
          if (current_domain.parent_domain) {
            if (
              typeof current_domain.parent_domain === 'string' ||
              current_domain.parent_domain instanceof String
            ) {
              check_domain_hierarchy_resursive(
                current_depth + 1,
                current_domain.parent_domain
              );
            } else {
              check_domain_hierarchy_resursive(
                current_depth + 1,
                current_domain.parent_domain.uuid
              );
            }
          }
        }
      };

      check_domain_hierarchy_resursive(0, this.domain.uuid);

      if (found_circle) {
        this.alert_service.error(`Ошибка: иерархия доменов замкнется в кольцо`);
        return;
      }
    }

    this.uistate.is_header_edit_loading = true;

    interface DomainPartialData {
      name;
      description;
      parent_domain?;
    }

    const domain_data = {
      name: this.domain_edit_form.value.domain_name,
      description: this.domain_edit_form.value.domain_description,
    } as DomainPartialData;
    // only send parent_domain attribute if it was changed
    const current_parent_uuid = this.domain.parent_domain
      ? this.domain.parent_domain.uuid
      : null;
    const new_parent_uuid = this.domain_edit_form.value.domain_parent
      ? this.domain_edit_form.value.domain_parent.uuid
      : null;
    if (current_parent_uuid !== new_parent_uuid) {
      domain_data.parent_domain = this.domain_edit_form.value.domain_parent
        ? this.domain_edit_form.value.domain_parent.uuid
        : null;
    }

    this.domain_service.update(this.domain.uuid, domain_data).subscribe(
      (response) => {
        this.domain = response as Domain;
        this.logging_service.debug(
          `${this.constructor.name} save new data for ${this.domain.uuid}`
        );
        this.alert_service.success(`Сохранено`);
        this.uistate.is_header_edit_mode = false;
        this.uistate.is_header_edit_loading = false;
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed save data for domain ${this.domain.uuid}`
        );
        this.alert_service.error(`Ошибка сохранения изменений: ${err.status}`);
        this.uistate.is_header_edit_loading = false;
      }
    );
  }

  public on_team_transfer_search(term: string): void {
    this.team_transfer_search_terms.next(term);
  }

  public on_team_transfer_search_autocomplete_selected(team: Team): void {
    this.logging_service.debug(
      `${this.constructor.name} on_team_transfer_search_autocomplete_selected`
    );
    this.team_transfer_search_selected = team;
    this.team_transfer_search_results = [];
  }

  public on_button_team_transfer_public(): void {
    this.team_transfer_search_selected = null;
    this.uistate.is_button_team_transfer_submit_disabled = true;
    this.domain_service
      .update(this.domain.uuid, {
        team: null,
      })
      .subscribe(
        (response) => {
          this.domain = response as Domain;
          this.logging_service.debug(
            `${this.constructor.name} domain ${this.domain.uuid} made public`
          );
          this.alert_service.success(`Домен теперь публичный`);
          this.uistate.is_button_team_transfer_submit_disabled = false;
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to make domain public ${this.domain.uuid}`
          );
          this.alert_service.error(
            `Ошибка изменения публичности: ${err.status}`
          );
          this.uistate.is_button_team_transfer_submit_disabled = false;
        }
      );
  }

  public on_button_team_transfer_submit(): void {
    this.uistate.is_button_team_transfer_submit_disabled = true;
    this.domain_service
      .update(this.domain.uuid, {
        team: this.team_transfer_search_selected.uuid,
      })
      .subscribe(
        (response) => {
          this.domain = response as Domain;
          this.logging_service.debug(
            `${this.constructor.name} domain ${this.domain.uuid} transferred to team`
          );
          this.alert_service.success(`Домен перенесен`);
          this.team_transfer_search_selected = null;
          this.uistate.is_button_team_transfer_submit_disabled = false;
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to transfer domain ${this.domain.uuid}`
          );
          this.alert_service.error(`Ошибка переноса домена: ${err.status}`);
          this.uistate.is_button_team_transfer_submit_disabled = false;
        }
      );
  }

  public util_team_transfer_search_display(team: Team): string {
    return team ? `${team.name}` : '';
  }

  public on_button_domain_delete_show_tooltip($event): void {
    const tooltip_style_left = `${$event.pageX - 240 + 1}px;`;
    const tooltip_style_top = `${$event.pageY - 15}px;`;
    this.logging_service.debug(
      `${this.constructor.name} show delete tooltip ${$event.pageX} ${$event.pageY}`
    );
    // this.logging_service.debug(`${this.constructor.name} left:${tooltip_style_left} top:${tooltip_style_top}`);
    this.tooltip_delete_domain.nativeElement.style.display = 'block';
    this.tooltip_delete_domain.nativeElement.style.opacity = 1.0;
    this.tooltip_delete_domain.nativeElement.setAttribute(
      'style',
      `top: ${tooltip_style_top} left: ${tooltip_style_left}`
    );
  }

  public on_button_domain_delete_hide_tooltip(): void {
    this.tooltip_delete_domain.nativeElement.style.opacity = 0.0;
    this.tooltip_delete_domain.nativeElement.style.display = 'none';
  }

  public on_tooltip_domain_delete_confirm(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_tooltip_domain_delete_confirm`
    );
    this.uistate.is_button_domain_tooltip_delete_disabled = true;
    this.domain_service.delete(this.domain.uuid).subscribe(
      (response) => {
        this.logging_service.debug(
          `${this.constructor.name} deleted domain ${this.domain.uuid}`
        );
        this.router.navigate(['/admin/ontologies']);
      },
      (err) => {
        this.uistate.is_button_domain_tooltip_delete_disabled = false;
        this.logging_service.error(
          `${this.constructor.name} failed to delete domain ${this.thing_to_delete.uuid}`
        );
        this.alert_service.error(`Ошибка удаления: ${err.status}`);
      }
    );
  }

  public on_tooltip_domain_delete_cancel(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_tooltip_domain_delete_cancel`
    );
    this.on_button_domain_delete_hide_tooltip();
  }
}
