import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { TeamEvent } from '@models/events/team-event';

@Injectable({
  providedIn: 'root',
})
export class TeamEventHttpService extends ViewSetHttpService<TeamEvent> {
  constructor(_http: HttpClient) {
    super(_http);
  }

  public viewSetUrl = 'events/team';
}
