import { Component, Input } from '@angular/core';
import { SettingsService } from 'src/app/core/services/system/settings/settings.service';

type AllowedSettings = 'use_team_validation' | 'email_settings';

@Component({
  selector: 'app-show-on-settings',
  templateUrl: './show-on-settings.component.html',
  styleUrls: ['./show-on-settings.component.css'],
})
export class ShowOnSettingsComponent {
  @Input() settingName: AllowedSettings;
  @Input() showOnActiveSetting: boolean;
  @Input() showOnDisabledSetting: boolean;

  constructor(private _settingsService: SettingsService) {}

  get isShowing(): boolean {
    const setting = this._settingsService.getSettings()[this.settingName];
    return setting ? this.showOnActiveSetting : this.showOnDisabledSetting;
  }

  get isSettingsLoaded(): boolean {
    return this._settingsService.serviceSettings.state.isLoaded;
  }
}
