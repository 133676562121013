import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompetenceDesireHttpService } from '@services/http/CompetenceDesireHttpService';
import { AsyncList } from '@rest/AsyncList';
import { CompetenceDesire } from '@models/competencies/competence-desire';
import { Thing } from '@models/ontology/thing';
import { Domain } from '@models/ontology/domain';
import { DomainHttpService } from '@services/http/DomainHttpService';
import {
  ThingDesireMap,
  ThingDesireWithUsersMap,
} from '@components/common/domain-thing-tree/domain-thing-tree.component';
import { User } from '@models/user/user';

@Component({
  selector: 'app-team-desires',
  templateUrl: './team-desires.component.html',
  styleUrls: ['./team-desires.component.css'],
})
export class TeamDesiresComponent implements OnInit {
  domains: AsyncList<Domain>;

  teamDesires: CompetenceDesire[];
  things: Thing[] = [];

  thingDesireWithUsersMap: ThingDesireWithUsersMap = {};

  treeDataReady = false;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _domainHttpService: DomainHttpService,
    private _competenceDesireHttpService: CompetenceDesireHttpService
  ) {}

  ngOnInit(): void {
    this.domains = new AsyncList<Domain>(this._domainHttpService);
    this.domains.load();

    this._competenceDesireHttpService
      .getTeamDesires(this._activatedRoute.snapshot.paramMap.get('uuid'))
      .subscribe((response) => {
        response.results.forEach((desire: CompetenceDesire) => {
          const thing = desire.thing as Thing,
            user = desire.user as User;
          if (this.thingDesireWithUsersMap[thing.uuid]) {
            this.thingDesireWithUsersMap[thing.uuid].push({
              date: this.getPrettyDesireDate(desire),
              user: `${user.last_name} ${user.first_name}`,
            });
          } else {
            this.things.push(thing);
            this.thingDesireWithUsersMap[thing.uuid] = [
              {
                date: this.getPrettyDesireDate(desire),
                user: `${user.last_name} ${user.first_name}`,
              },
            ];
          }
        });
        this.treeDataReady = true;
      });
  }

  getPrettyDesireDate(desire: CompetenceDesire): string {
    if (desire.date_updated) {
      const date = new Date(desire.date_updated);
      return `${date.getDate()} ${date.toLocaleString('default', {
        month: 'short',
      })} ${date.getFullYear()}`;
    }
    return '';
  }
}
