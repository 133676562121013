import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TreeNode } from '../../../../utils/tree';
import {
  Level,
  ThingGradeLevels,
  ThingGradeLevelsMap,
} from '../../../../modules/teams/components/team-main/team-specialities/team-speciality-detail/team-speciality-detail.component';
import { SpecialityCompetenceClaimHttpService } from '@services/http/SpecialityCompetenceClaimHttpService';
import { AssessmentResponse } from '@models/reviews/assessment-response';
import { AssessmentResponseThingMap } from '../../../../modules/review/components/new-review-assessment-detail/new-review-assessment-detail.component';
import { CompetenceAssertion } from '@models/competencies/competence-assertion';
import { ThingLevel } from '@models/ontology/thing-level';
import { UntypedFormControl } from '@angular/forms';
import {
  DomainTreeModel,
  ThingCurrentDesiredLevelMap,
  ThingDesireMap,
  ThingDesireWithUsersMap,
  ThingTreeModel,
} from '@components/common/domain-thing-tree/domain-thing-tree.component';
import { ResolutionResponseThingMap } from '../../../../modules/review/components/new-review-resolution-detail/new-review-resolution-detail.component';
import { ResolutionResponse } from '@models/reviews/resolution-response';
import { CompetenceDesireHttpService } from '@services/http/CompetenceDesireHttpService';
import { AuthService } from '../../../../services/auth/auth.service';
import { Competence } from '@models/competencies/competence';
import { AlertService } from '../../../../services/ui/ui-alert.service';
import { ThingUserCompetenceMap } from '../../../../modules/admin/components/users/user-tabs/admin-user-tab-competencies/admin-user-competencies-tab.component';
import { Router } from '@angular/router';
import { BlockTextHelpDialogComponent } from '@components/common/block-text-help-dialog/block-text-help-dialog.component';
import { MatDialog } from '@angular/material/dialog';

type TreeModel = DomainTreeModel | ThingTreeModel;

export interface AngularDefaultTreeNode {
  expandable: boolean;
  name: string;
  level: number;
  uuid: string;
  children: TreeNode<TreeModel>[];
}

interface nodeThingLevel {
  isSelected: boolean;
  isExpanded: boolean;
  name: string;
  description: string;
  level: number;
  uuid: string;
}

@Component({
  selector: 'app-domain-thing-tree-node',
  templateUrl: './domain-thing-tree-node.component.html',
  styleUrls: ['./domain-thing-tree-node.component.css'],
})
export class DomainThingTreeNodeComponent implements OnInit {
  @ViewChild('levelsPopup') levelsPopup: ElementRef;
  @ViewChild('levelsPopupMask') levelsPopupMask: ElementRef;
  showPopUp = false;

  @Input() userUuid: string; // if permission control needed

  // base
  @Input() node: TreeNode<TreeModel>;
  @Input() angularNodes: AngularDefaultTreeNode[];
  @Input() profileLevels = false;
  angularNode: AngularDefaultTreeNode;
  isExpanded = false;

  isDesireBtnDisabled = false;

  // user competencies node
  @Input() thingUserCompetenceMap: ThingUserCompetenceMap;

  // reviewMode
  @Input() reviewMode:
    | 'self-assessment'
    | 'assessment'
    | 'resolution'
    | 'result';
  @Input() assessmentResponseThingMap: AssessmentResponseThingMap;
  // reviewMode === 'assessment' || 'self-assessment'
  assessmentResponse: AssessmentResponse;
  thingLevels: nodeThingLevel[] = [];
  @Output() acceptAssessmentResponse: EventEmitter<{
    thingNode: TreeNode<TreeModel>;
    assessmentResponseUuid: string;
    selectedLevelUuid: string;
    comment: string;
  }> = new EventEmitter<{
    thingNode: TreeNode<TreeModel>;
    assessmentResponseUuid: string;
    selectedLevelUuid: string;
    comment: string;
  }>();
  // reviewMode === 'assessment'
  isCommentFieldVisible = false;
  comment = new UntypedFormControl('', []);
  // reviewMode === 'self-assessment'

  // reviewMode === 'resolution'
  @Input() resolutionResponseThingMap: ResolutionResponseThingMap;
  resolutionResponse: ResolutionResponse;
  resolutionThingLevels: ThingLevel[];
  selectedResolutionResponseLevel: string;
  @Output() acceptResolutionResponse: EventEmitter<{
    thingNode: TreeNode<TreeModel>;
    resolutionResponseUuid: string;
    selectedLevelUuid: string;
  }> = new EventEmitter<{
    thingNode: TreeNode<TreeModel>;
    resolutionResponseUuid: string;
    selectedLevelUuid: string;
  }>();

  // true if node should be expanded on init
  @Input() expandNode = false;

  // node that should be expanded if not null
  @Input() firstNode: TreeNode<TreeModel>;

  @Input() isEditable = true;
  // selectable node
  @Input() selectMode = false;
  @Output() selectThing: EventEmitter<{
    selectedThing: TreeNode<TreeModel>;
    remove: boolean;
  }> = new EventEmitter<{
    selectedThing: TreeNode<TreeModel>;
    remove: boolean;
  }>();
  @Output() selectDomain: EventEmitter<TreeNode<TreeModel>> = new EventEmitter<
    TreeNode<TreeModel>
  >();

  // node with thing levels
  @Input() libraryEdit: boolean;
  @Input() thingGradeLevelsMap: ThingGradeLevelsMap = null;
  @Output() isKeyCompetenceToggle: EventEmitter<{
    thingUuid: string;
    isKey: boolean;
  }> = new EventEmitter<{
    thingUuid: string;
    isKey: boolean;
  }>();
  @Output() thingDelete: EventEmitter<string> = new EventEmitter<string>();
  @Output() domainDelete: EventEmitter<string> = new EventEmitter<string>();
  @Output() changeDomainOrder: EventEmitter<{
    domain: TreeNode<TreeModel>;
    mode: string;
  }> = new EventEmitter<{
    domain: TreeNode<TreeModel>;
    mode: string;
  }>();
  @Output() changeGradeLevel: EventEmitter<{
    thingUuid: string;
    gradeUuid: string;
    nextThingLevelUuid: string;
  }> = new EventEmitter<{
    thingUuid: string;
    gradeUuid: string;
    nextThingLevelUuid: string;
  }>();
  isDeleted = false;
  lastNode = false;
  popupLevelDescShow: string;

  //thing nodes with desire and its date
  @Input() thingDesireMap: ThingDesireMap = null;

  //thing nodes with desire, its users and dates
  @Input() thingDesireWithUsersMap: ThingDesireWithUsersMap = null;

  //node with current and desired thing level
  @Input() thingCurrentDesiredLevelMap: ThingCurrentDesiredLevelMap = null;
  @Output() changeUserCompetenceLevel: EventEmitter<{
    competenceUuid: string;
    levelUuid: string;
    thingUuid: string;
  }> = new EventEmitter<{
    competenceUuid: string;
    levelUuid: string;
    thingUuid: string;
  }>();

  constructor(
    private _dialog: MatDialog,
    private _authService: AuthService,
    private _alertService: AlertService,
    private _router: Router,
    private _specialityCompetenceClaimHttpService: SpecialityCompetenceClaimHttpService,
    private _competenceDesireHttpService: CompetenceDesireHttpService
  ) {}

  ngOnInit(): void {
    this.angularNode = this.angularNodes.find(
      (dataNode) => dataNode.uuid === this.node.item.uuid
    );

    if (this.expandNode && this.angularNode.expandable) {
      this.node.item.isExpanded = true;
    }

    if (this.node.item.isThing && this.reviewMode) {
      this._buildReviewModeData();
    }

    if (this.firstNode && this.angularNode.expandable) {
      this._expandFirstNode();
    }
    this.findLastNode();
  }

  findLastNode(): void {
    if (
      this.profileLevels &&
      this.angularNode.children[0]?.item !== undefined &&
      'levels' in this.angularNode.children[0].item
    ) {
      this.lastNode = true;
    }
  }

  private _buildReviewModeData(): void {
    if (
      this.reviewMode === 'assessment' ||
      this.reviewMode === 'self-assessment'
    ) {
      this.assessmentResponse =
        this.assessmentResponseThingMap[this.node.item.uuid][0];

      const competenceAssertion = this.assessmentResponse
          .competence_assertion as CompetenceAssertion,
        competenceAssertionLevel =
          competenceAssertion?.thing_level as ThingLevel;

      this.thingLevels = this.assessmentResponse.thing.levels.map((level) => {
        return {
          isSelected:
            competenceAssertionLevel &&
            level.order_number === competenceAssertionLevel.order_number,
          isExpanded: true,
          name: level.title,
          description: level.description,
          level: level.order_number,
          uuid: level.uuid,
        };
      });
    } else if (this.reviewMode === 'resolution') {
      this.assessmentResponse =
        this.assessmentResponseThingMap[this.node.item.uuid][0];
      this.resolutionResponse =
        this.resolutionResponseThingMap[this.node.item.uuid];
      this.resolutionThingLevels = this.resolutionResponse.thing.levels;
    } else {
      this.assessmentResponse =
        this.assessmentResponseThingMap[this.node.item.uuid][0];
      this.resolutionResponse =
        this.resolutionResponseThingMap[this.node.item.uuid];
    }
  }

  get isThingDesired(): boolean {
    return !!this.thingDesireMap[this.node.item.uuid];
  }

  get isCurrentUser(): boolean {
    return this.userUuid
      ? this.userUuid === this._authService.get_current_user_uuid()
      : false;
  }

  onDesireToggle(): void {
    this.isDesireBtnDisabled = true;
    if (!this.isThingDesired) {
      this._competenceDesireHttpService
        .create({
          thing: this.node.item.uuid,
          user: this._authService.get_current_user_uuid(),
        })
        .subscribe(
          (response) => {
            this.thingDesireMap[this.node.item.uuid] = {
              desire: response.uuid,
              date: response.date_updated,
            };
            this.isDesireBtnDisabled = false;
          },
          (error) => {
            this._alertService.error(`Ошибка`);
            this.isDesireBtnDisabled = false;
          }
        );
    } else {
      this._competenceDesireHttpService
        .delete(this.thingDesireMap[this.node.item.uuid].desire)
        .subscribe(
          () => {
            delete this.thingDesireMap[this.node.item.uuid];
            this.isDesireBtnDisabled = false;
          },
          (error) => {
            this._alertService.error(`Ошибка`);
            this.isDesireBtnDisabled = false;
          }
        );
    }
  }

  private _expandFirstNode(): void {
    if (
      this.node.item.uuid === this.firstNode.item.uuid ||
      this.isChildOf(this.firstNode)
    ) {
      this.node.item.isExpanded = true;
    }
  }

  get isNodeUneven(): boolean {
    return this.angularNode.level % 2 !== 0;
  }

  isChildOf(parentNode: TreeNode<TreeModel>): boolean {
    let matchFound = false;
    parentNode.children.forEach((childNode) => {
      if (matchFound) return;
      if (childNode.item.uuid === this.node.item.uuid) {
        matchFound = true;
      } else if (childNode.children.length > 0) {
        matchFound = this.isChildOf(childNode);
      }
    });
    return matchFound;
  }

  isKeyCompetence(thingUuid: string): boolean {
    return this.thingGradeLevelsMap[thingUuid]?.['isKey'];
  }

  countKeyChildren(children: TreeNode<TreeModel>[]): number {
    let count = 0;
    children.forEach((childNode) => {
      if (
        childNode.item.isThing &&
        this.thingGradeLevelsMap[childNode.item.uuid]['isKey']
      ) {
        count += 1;
      }
    });
    return count;
  }

  getCountKeyChildrenText(children: TreeNode<TreeModel>[]): string {
    const count = this.countKeyChildren(children);
    if (count === 1) {
      return `Есть 1 ключевая`;
    } else if (count > 1) {
      return `Есть ${count} ключевые`;
    }
    return '';
  }

  onToggleKeyCompetence(thingUuid: string, isKey: boolean): void {
    this.isKeyCompetenceToggle.emit({ thingUuid: thingUuid, isKey: isKey });
  }

  showLevelsPopup(e, currentDesiredLevel): void {
    if (this.isCurrentUser) {
      this.showPopUp = true;
      this.levelsPopup.nativeElement.setAttribute(
        'style',
        'left: ' +
          (e.pageX - this.levelsPopup.nativeElement.offsetWidth / 2) +
          'px; top: ' +
          (e.pageY - this.levelsPopup.nativeElement.offsetHeight - 22) +
          'px;'
      );
    }
  }

  hideLevelsPopup(): void {
    this.showPopUp = false;
  }

  onShowLevelDescription(levelUuid: string): void {
    this.popupLevelDescShow === levelUuid
      ? (this.popupLevelDescShow = '')
      : (this.popupLevelDescShow = levelUuid);
  }

  onUserCompetenceLevelChange(
    competenceUuid: string,
    levelUuid: string,
    thingUuid: string
  ): void {
    this.hideLevelsPopup();
    this.changeUserCompetenceLevel.emit({
      competenceUuid: competenceUuid,
      levelUuid: levelUuid,
      thingUuid: thingUuid,
    });
  }

  /**
   functions for editable thing levels are placed below
   */

  getThingFromMap(thingUuid: string): ThingGradeLevels[] {
    return this.thingGradeLevelsMap[thingUuid]?.['thingGradeLevels'];
  }

  onGradeLevelChange(
    thingUuid: string,
    gradeUuid: string,
    nextThingLevelUuid: string
  ): void {
    this.changeGradeLevel.emit({
      thingUuid: thingUuid,
      gradeUuid: gradeUuid,
      nextThingLevelUuid: nextThingLevelUuid,
    });
  }

  onDeleteThingClick(thingUuid: string): void {
    this.isDeleted = !this.isDeleted;
    this.onDeleteThing(thingUuid);
  }

  onDeleteThing(thingUuid: string): void {
    if (
      !this.node.item.isThing &&
      this.node.children.length == 1 &&
      this.node.children.map((node) => node.item.uuid).includes(thingUuid)
    ) {
      this.isDeleted = true;
      this.domainDelete.emit(this.node.item.uuid);
    } else {
      this.thingDelete.emit(thingUuid);
    }
  }

  onDeleteDomain(domainUuid: string): void {
    if (
      this.node.children.length == 1 &&
      this.node.children.map((node) => node.item.uuid).includes(domainUuid)
    ) {
      this.domainDelete.emit(this.node.item.uuid);
    } else {
      this.domainDelete.emit(domainUuid);
    }
  }

  onChangeDomainOrder(domain: TreeNode<TreeModel>, mode: string): void {
    this.changeDomainOrder.emit({ domain: domain, mode: mode });
  }

  /**
   functions for ReviewMode are placed below
   */

  isThingLevelSelected(): boolean {
    return !!this.thingLevels.find((level) => level.isSelected);
  }

  get selectedThingLevel(): nodeThingLevel {
    return this.thingLevels.find((level) => level.isSelected);
  }

  get competenceAssertionLevel(): ThingLevel {
    return (
      this.assessmentResponse?.competence_assertion as CompetenceAssertion
    )?.thing_level as ThingLevel;
  }

  get assessmentResponseLevel(): string {
    return `Вы: ${
      this.competenceAssertionLevel?.title
        ? this.competenceAssertionLevel.title
        : 'Пропущено'
    }`;
  }

  get resolutionResponseLevel(): string {
    return `Финальная: ${
      this.resolutionResponse?.resolved_thing_level?.title
        ? this.resolutionResponse.resolved_thing_level.title
        : 'Не оценено'
    }`;
  }

  onYourThingLevelClick(): void {
    this._dialog.open(BlockTextHelpDialogComponent, {
      data: {
        title: 'Что означает оценка - Вы?',
        textBlocksData: [
          {
            title: 'Оценка "Вы"',
            text: 'Это оценка, которая была проставлена вами.',
            color: 'rgba(130,196,255,0.16)',
          },
        ],
      },
    });
  }

  onFinalThingLevelClick(): void {
    this._dialog.open(BlockTextHelpDialogComponent, {
      data: {
        title: 'Что означают оценка - Финальная?',
        textBlocksData: [
          {
            title: 'Оценка "Финальная"',
            text: 'Это оценка, которая была проставлена руководителем. Эта оценка считается финальной, так как она отображается в профиле у сотрудника. ',
            color: 'rgba(155,130,255,0.16)',
          },
        ],
      },
    });
  }

  get ThingBackgroundColor(): string {
    const levels = [
      { title: 'Не оценено', value: -1 },
      { title: 'Не владеет', value: 0 },
      { title: 'Начинающий', value: 1 },
      { title: 'Базовый', value: 2 },
      { title: 'Уверенный', value: 3 },
      { title: 'Экспертный', value: 4 },
    ];

    const resolvedLevelTitle =
      this.resolutionResponse?.resolved_thing_level?.title || 'Не оценено';
    const assessmentLevelTitle =
      this.competenceAssertionLevel?.title || 'Не оценено';

    const resolvedLevelValue =
      levels.find((level) => level.title === resolvedLevelTitle)?.value || -1;
    const assessmentLevelValue =
      levels.find((level) => level.title === assessmentLevelTitle)?.value || -1;

    if (resolvedLevelValue > assessmentLevelValue) {
      return 'green-background';
    } else if (resolvedLevelValue < assessmentLevelValue) {
      return 'yellow-background';
    } else {
      return this.isNodeUneven ? 'white-background' : 'gray-background';
    }
  }

  // onThingLevelSelectionToggle(thingLevel: nodeThingLevel): void {
  //   if (!thingLevel.isSelected) {
  //     this.thingLevels.forEach((level) => (level.isSelected = false));
  //   }
  //   thingLevel.isSelected = !thingLevel.isSelected;
  // }

  onThingLevelSelectionToggle(
    thingLevel: nodeThingLevel,
    thingNode: TreeNode<TreeModel>,
    assessmentResponseUuid: string,
    comment: string
  ): void {
    if (!thingLevel.isSelected) {
      this.thingLevels.forEach((level) => (level.isSelected = false));
    }
    thingLevel.isSelected = !thingLevel.isSelected;
    this.acceptAssessmentResponse.emit({
      thingNode: thingNode,
      assessmentResponseUuid: assessmentResponseUuid,
      selectedLevelUuid: this.selectedThingLevel.uuid,
      comment: comment,
    });
  }

  onResolutionResponseLevelSelect(levelUuid: string): void {
    this.selectedResolutionResponseLevel = levelUuid;
  }

  onAcceptAssessmentResponse(
    thingNode: TreeNode<TreeModel>,
    assessmentResponseUuid: string,
    selectedLevelUuid: string,
    comment: string
  ): void {
    this.acceptAssessmentResponse.emit({
      thingNode: thingNode,
      assessmentResponseUuid: assessmentResponseUuid,
      selectedLevelUuid: selectedLevelUuid,
      comment: comment,
    });
  }

  isCanBeAccepted(thingNode: TreeNode<TreeModel>): boolean {
    const competenceAssertion = this.assessmentResponseThingMap[
      thingNode.item.uuid
    ].find((assessmentResponse) => assessmentResponse.competence_assertion)
      ?.competence_assertion as CompetenceAssertion;

    if (this.selectedResolutionResponseLevel) {
      return true;
    }
    if (competenceAssertion && !thingNode.item.isConflicted) {
      const thingLevel = competenceAssertion.thing_level as ThingLevel;
      this.selectedResolutionResponseLevel = thingLevel.uuid;
      return true;
    }
    return false;
  }

  onAcceptResolutionResponse(
    thingNode: TreeNode<TreeModel>,
    resolutionResponseUuid: string,
    selectedLevelUuid: string
  ): void {
    this.acceptResolutionResponse.emit({
      thingNode: thingNode,
      resolutionResponseUuid: resolutionResponseUuid,
      selectedLevelUuid: selectedLevelUuid,
    });
  }

  /**
   functions for SelectMode are placed below
   */

  onSelectionToggle(): void {
    this.node.item.isSelected = !this.node.item.isSelected;
    if (!this.node.item.isThing) {
      this.node.item.isExpanded = true;
      this.onToggleDomainSelection(this.node);
    } else {
      if (this.node.item.isSelected) {
        this.onToggleThingSelection(this.node, false);
      } else {
        this.onToggleThingSelection(this.node, true);
      }
    }
  }

  onToggleDomainSelection(domain: TreeNode<TreeModel>): void {
    this.selectDomain.emit(domain);
  }

  onToggleThingSelection(
    selectedThing: TreeNode<TreeModel>,
    remove: boolean
  ): void {
    this.selectThing.emit({ selectedThing: selectedThing, remove: remove });
  }

  /**
   functions for userCompetenceNode are placed below
   */

  getUserCompetenceThingLevel(thingUuid: string): ThingLevel {
    return this.thingUserCompetenceMap[thingUuid].thing_level as ThingLevel;
  }

  redirectToThing(thingUuid: string): void {
    this._router.navigate([`things/${thingUuid}`]);
  }
}
