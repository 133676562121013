import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';
import { TeamMembership } from 'src/app/models/teams/team-membership';

interface FetchTeamMembershipListResponse {
  results: TeamMembership[];
  count: number;
}

interface FetchTeamMembershipDetailResponse {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class TeamMembershipService {
  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService
  ) {}

  public create(membership_data: any): Observable<any> {
    const params = { expand: 'team,user' };
    return this.api_service
      .post(`teams/membership`, membership_data, params)
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} created team membership with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to create team membership`
            );
          }
        )
      );
  }

  public update(
    membership_uuid: string,
    membership_data: any
  ): Observable<any> {
    const params = { expand: 'team,user' };
    return this.api_service
      .patch(`teams/membership/${membership_uuid}`, membership_data, params)
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} updated membership with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to update membership`
            );
          }
        )
      );
  }

  public delete(membership: TeamMembership): Observable<any> {
    return this.api_service
      .delete(`teams/membership/${membership.uuid}`, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} deleted team membership with id ${membership.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to delete team membership`
            );
          }
        )
      );
  }

  public fetch_by_team_uuid(
    team_uuid: string,
    additional_params: any = {}
  ): Observable<any> {
    const params = {
      team: team_uuid,
      expand: 'team,user',
      ...additional_params,
    };

    return this.api_service.get(`teams/membership`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} memberships`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch memberships`
          );
        }
      )
    );
  }

  public fetch_by_user_uuid(user_uuid: string): Observable<any> {
    const params = { user: user_uuid, expand: 'team,user' };
    return this.api_service.get(`teams/membership`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} memberships`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch memberships`
          );
        }
      )
    );
  }

  public fetch_superiors_by_user_uuid(user_uuid: string): Observable<any> {
    const params = { user: user_uuid };
    return this.api_service.get(`teams/superiors`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} superiors`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch superiors`
          );
        }
      )
    );
  }

  public fetch_by_team_and_user(
    team_uuid: string,
    user_uuid: string
  ): Observable<any> {
    const params = { team: team_uuid, user: user_uuid, expand: 'team,user' };
    return this.api_service.get(`teams/membership`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} memberships`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch memberships`
          );
        }
      )
    );
  }

  public fetch_all(request_params) {
    return this.api_service
      .get<FetchTeamMembershipListResponse>(`teams/membership`, request_params)
      .pipe(
        tap(
          (response: FetchTeamMembershipListResponse) => {
            this.logging_service.debug(
              `${this.constructor.name} fetched ${response.count} memberships`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to fetch memberships`
            );
          }
        )
      );
  }

  public fetch_by_uuid(team_membership_uuid) {
    return this.api_service
      .get<FetchTeamMembershipDetailResponse>(
        `teams/membership/${team_membership_uuid}`
      )
      .pipe(
        tap(
          (data) => {
            this.logging_service.debug(
              `${this.constructor.name} fetched user data`
            );
            return data;
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to fetch user data`
            );
            return err;
          }
        )
      );
  }
}
