import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root'
})
export class SurveySpecialityRelationService {

  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService,
  ) { }

  public fetch_by_speciality_uuid(speciality_uuid: string): Observable<any> {
    const params = {speciality: speciality_uuid, expand: 'survey,speciality'};
    return this.api_service.get(`surveys/relation/speciality`, params).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} fetched surveys for speciality uuid ${speciality_uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to fetch surveys for speciality`);
        }
    ));
  }

  public create(relation_data: any): Observable<any> {
    return this.api_service.post(`surveys/relation/speciality`, relation_data, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} created survey speciality relation with uuid ${response.uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to create survey speciality relation`);
        }
    ));
  }

  public delete(relation_uuid: string): Observable<any> {
    return this.api_service.delete(`surveys/relation/speciality/${relation_uuid}`, {}).pipe(
      tap(
        response => {
          this.logging_service.debug(`${this.constructor.name} deleted survey speciality relation with uuid ${relation_uuid}`);
        },
        err => {
          this.logging_service.error(`${this.constructor.name} failed to delete survey speciality relation`);
        }
    ));
  }
}
