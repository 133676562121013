<div
  class='team-container'
  *ngIf='
    team?.state.isLoaded &&
    teamMembers?.state.isLoaded &&
    specialityUsersRelations?.state.isLoaded
  '
>
  <div fxLayout='row'>
    <div
      fxLayout='row'
      fxLayoutAlign='start center'
      fxLayoutGap='2rem'
      fxFlex
      class='team-title'
    >
      <button (click)='onBackClick()' mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span>{{ team.state.item.name }}</span>
    </div>
    <div
      fxFlex='400px'
      fxLayout='row'
      fxLayoutAlign='end center'
      fxLayoutGap='8px'
    >
      <button
        fxFlex
        *ngIf='getUserMembership()'
        (click)='leaveTeam()'
        mat-stroked-button
      >
        Покинуть команду
      </button>
      <button
        mat-stroked-button
        *ngIf='!getUserMembership() && joinType === 1'
        (click)='joinTeam()'
      >
        Вступить в команду
      </button>
      <button
        mat-stroked-button
        *ngIf='!getUserMembership() && joinType === 3'
        [disabled]='isSelfJoinRequest()'
        (click)='createTeamJoinRequest()'
      >
        {{
        isSelfJoinRequest()
          ? 'Вы уже подали заявку'
          : 'Заявка на вступление'
        }}
      </button>
      <button
        mat-stroked-button
        *ngIf='isUserTeamOwner || isUserAdmin'
        (click)='onTeamManage()'
      >
        <mat-icon>admin_panel_settings</mat-icon>
        Управление командой
      </button>
    </div>
  </div>

  <div class='team-desc'></div>

  <div *ngIf='!teamMembers.state.items.length'>
    <div class='members-container-empty'>В команде пока нет участников</div>
  </div>
  <div class='members-container' *ngIf='teamMembers.state.items.length'>
    <div
      class='member-container'
      fxLayout='row'
      fxLayoutAlign='start center'
      fxLayoutGap='2rem'
      *ngFor='let member of teamMembers.state.items'
      (click)='onMemberClick(member)'
    >
      <div fxLayout='column' fxFlex='40'>
        <div fxLayout='row' fxFlex fxLayoutGap='10px'>
          <div fxLayout='column'>
            <span class='member-text'>{{
              member.user.first_name + ' ' + member.user.last_name
              }}</span>
            <span class='member-desc'>{{ member.user.email }}</span>
          </div>
          <div
            fxLayout='row'
            fxLayoutGap='2px'
          >
            <div *ngFor='let membership of member.user.team_memberships'>
              <div
                *ngIf='membership.team.icon_url'
                fxFlex='3rem'
                [style.cursor]="'pointer'"
                matTooltip='{{ membership.team.name }}'
              >
                <img [src]='membership.team.icon_url' [style.height.px]='30' alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex='30'>
        <div fxLayout='row' fxLayoutAlign='center center'>
          <div class='member-role'>{{ getMemberRoleName(member.role) }}</div>
        </div>
      </div>
      <div fxFlex='30'>
        <div fxLayout='row' fxLayoutAlign='center center'>
          <div
            class='member-role'
            *ngIf='getUserSpecialitiesCount(member.user.uuid) > 0'
          >
            {{ usersSpecialitiesMap[member.user.uuid][0].name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
