<div class='speciality-detail'>
  <div fxLayout='row' fxLayoutAlign='start center'>
    <div fxFlex>
      <div class='main'>
        <div class='main-content'>
          <div fxLayout='row' fxLayoutAlign='start center'>
            <div class='main-content-title'>
              {{ speciality.state.item.name }}
              <mat-icon>verify</mat-icon>
            </div>
            <button mat-icon-button *ngIf='isEditModeOn' (click)='onSpecialityEdit()'>
              <mat-icon>edit</mat-icon>
            </button>
            <div fxFlex fxLayoutAlign='end center'>
              <button
                class='team-add-button'
                mat-raised-button
                (click)='showAddTeamDialog()'
              >
                <mat-icon>add</mat-icon>
                Добавить в команду
              </button>
            </div>
          </div>
          <div class='main-content-description'>
            {{ speciality.state.item.description }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-tab-group [animationDuration]="'0ms'" mat-stretch-tabs='false'>
    <mat-tab label='Профиль компетенций'>
      <div fxFlex>
        <app-with-processing
          [processing]='
          specialityDomainClaims?.state.isProcessing &&
          specialityCompetenceClaims?.state.isProcessing
        '
        >
          <div fxLayout='row'>
            <div fxFlex fxLayout='row' fxLayoutAlign='start center' *ngIf='isEditModeOn'>
              <div
                fxLayoutAlign='start center'
                class='add-competence-button-container'
                (click)='onCompetenceFromLibraryAdd()'
              >
                <button
                  mat-raised-button
                  class='add-competence-button'
                  color='primary'
                >
                  <mat-icon>add_circle_outline</mat-icon>
                </button>
                <span class='add-competence-button-label'
                >Добавить компетенции в профиль</span
                >
              </div>
            </div>
            <div fxFlex fxLayout='row' fxLayoutAlign='end center' *ngIf='isEditModeOn'>
              <div
                fxLayoutAlign='start center'
                class='add-competence-button-container'
                (click)='onCompetenciesAutoLevel()'
              >
                <button
                  mat-raised-button
                  class='add-competence-button'
                  color='primary'
                >
                  <mat-icon svgIcon='custom_library_add_check'></mat-icon>
                </button>
                <span class='add-competence-button-label'
                >Проставить уровни владения навыком</span
                >
              </div>
            </div>
          </div>
          <app-domain-thing-tree
            *ngIf='
            specialityDomainClaims?.state.isLoaded &&
            specialityCompetenceClaims?.state.isLoaded &&
            thingGradeLevelsMapReady
          '
            [specialityUuid]='speciality.state.item.uuid'
            [specialityDomainsClaims]='specialityDomainClaims.state.items'
            [things]='things'
            [thingGradeLevelsMap]='thingGradeLevelsMap'
            [specialityCompetenceClaims]='specialityCompetenceClaims'
            [expandFirstNode]='true'
            [isEditable]='isEditModeOn'
            [libraryEdit]='isEditModeOn'
            [reorderHidden]='!isEditModeOn'
          ></app-domain-thing-tree>
        </app-with-processing>
      </div>
    </mat-tab>
    <mat-tab label='Уровни развития'>
      <div *ngIf='specialityGrades?.state.isLoaded'>
        <div fxLayout='column' fxLayoutGap='8px' class='list-container'>
          <div fxLayout='row'>
            <span fxFlex='30'>Уровень</span>
            <span fxFlex>Описание</span>
          </div>
          <div fxLayout='row' fxLayoutAlign='start center' *ngFor='let grade of specialityGrades.state.items'
               class='list-item-container'>
            <div fxFlex='30' fxLayoutAlign='start center'>
              <span *ngIf='!editableGradeName || editableGradeName !== grade.uuid'>{{ grade.name }}</span>
              <form fxFlex *ngIf='editableGradeName === grade.uuid' [formGroup]='gradeForm'>
                <mat-form-field fxFlex>
                  <input
                    matInput
                    formControlName='name'
                    type='text'
                    required
                  />
                </mat-form-field>
              </form>
              <!--              <button mat-icon-button class='hover-edit' (click)='editGradeName(grade)'-->
              <!--                      *ngIf='isEditModeOn && (!editableGradeName || editableGradeName !== grade.uuid)'>-->
              <!--                <mat-icon>edit</mat-icon>-->
              <!--              </button>-->
              <!--              <button mat-icon-button (click)='updateGrade(grade.uuid)' *ngIf='editableGradeName === grade.uuid'>-->
              <!--                <mat-icon>save</mat-icon>-->
              <!--              </button>-->
              <!--              <button mat-icon-button (click)='cancelGradeUpdate()' *ngIf='editableGradeName === grade.uuid'>-->
              <!--                <mat-icon>cancel</mat-icon>-->
              <!--              </button>-->
            </div>
            <div fxFlex fxLayoutAlign='start center'>
              <span *ngIf='!editableGradeDesc || editableGradeDesc !== grade.uuid'>{{ grade.description }}</span>
              <form fxFlex *ngIf='editableGradeDesc === grade.uuid' [formGroup]='gradeForm'>
                <mat-form-field fxFlex>
                  <input
                    matInput
                    formControlName='description'
                    type='text'
                    required
                  />
                </mat-form-field>
              </form>
              <button mat-icon-button class='hover-edit' (click)='editGradeDesc(grade)'
                      *ngIf='isEditModeOn && (!editableGradeDesc || editableGradeDesc !== grade.uuid)'>
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button (click)='updateGrade(grade.uuid)' *ngIf='editableGradeDesc === grade.uuid'>
                <mat-icon>save</mat-icon>
              </button>
              <button mat-icon-button (click)='cancelGradeUpdate()' *ngIf='editableGradeDesc === grade.uuid'>
                <mat-icon>cancel</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label='Специальность в командах'>
      <div *ngIf='childSpecialities?.state.isLoaded && isChildSpecialitiesDiffLoaded'>
        <div fxLayoutGap='8px' fxLayoutAlign='end center'>
          <!--          <mat-button-toggle (click)='isCopyModeOn = !isCopyModeOn'>-->
          <!--            Режим копирования-->
          <!--          </mat-button-toggle>-->
          <button mat-raised-button *ngIf='isEditModeOn' [disabled]='!selectedSpecialities.length'
                  (click)='updateChildSpecialities()'>
            Копировать в команды
          </button>
          <mat-icon matTooltipPosition='above' [matTooltip]='"Копирование происходит во все выбранные команды"'>info
          </mat-icon>
        </div>
        <div *ngIf='!childSpecialities.state.items.length' fxLayoutAlign='center center'>Специальность не используется в
          командах
        </div>
        <div *ngIf='childSpecialities.state.items.length' fxLayout='row' fxLayoutGap='20px' [style.margin-top.px]='20'>
          <div fxLayout='column' fxFlex fxLayoutGap='8px' class='list-container'>
            <div fxLayout='row' fxLayoutAlign='start center' class='list-header-container'>
              <mat-checkbox fxFlex='5' *ngIf='isEditModeOn'
                            (change)='onSelectionToggleAll($event.checked)'
                            [checked]='isSelectedAll'
              ></mat-checkbox>
              <span fxFlex='30'>Команда</span>
              <span fxFlex>Специальность</span>
              <span fxFlex>Кол-во изменений</span>
              <span fxFlex>Изменения</span>
              <span fxFlex='5'></span>
            </div>
            <div fxLayout='column'
                 *ngFor='let childSpeciality of sortedSpecialitiesByDiffCount'
                 class='list-item-container'>
              <div fxLayout='row' fxLayoutAlign='start center'>
                <mat-checkbox fxFlex='5' *ngIf='isEditModeOn'
                              (change)='onSelectionToggle($event.checked, childSpeciality.uuid)'
                              [checked]='isSelectedAll'></mat-checkbox>
                <span
                  fxFlex='30'>{{ childSpeciality.team.name }}</span>
                <span fxFlex>{{ childSpeciality.name }} <span
                  [style.color]='"#ff4f4f"'>{{getChildSpecialityDiffName(childSpeciality.uuid)}}</span> </span>
                <span fxFlex>{{ getDiffCount(childSpeciality.uuid) }}</span>
                <span
                  fxFlex>{{ getGradesDiffCount(childSpeciality.uuid) ? 'Уровни развития,' : '' }} {{ getCompetenciesDiffCount(childSpeciality.uuid) ? 'Компетенции' : '' }}</span>
                <div fxFlex='5' *ngIf='isEditModeOn'>
                  <button mat-icon-button
                          (click)='expandedSpeciality === childSpeciality.uuid ? expandedSpeciality = "" : expandedSpeciality = childSpeciality.uuid'>
                    <mat-icon>{{ expandedSpeciality === childSpeciality.uuid ? 'expand_less' : 'expand_more'  }}</mat-icon>
                  </button>
                </div>
              </div>
              <div fxLayout='row' *ngIf='expandedSpeciality === childSpeciality.uuid'>
                <mat-tab-group fxFlex animationDuration='0ms' mat-stretch-tabs='false'>
                  <mat-tab label='Компетенции из библиотеки'>
                    <div [style.padding.px]='15'>
                      <div fxLayoutAlign='center center'
                           *ngIf='isMissingClaimsEmpty(childSpeciality.uuid)'>Компетенции
                        совпадают с
                        библиотечной специальностью
                      </div>
                      <div fxLayout='row'
                           *ngFor='let competenceClaims of getChildSpecialityCompetenciesDiff(childSpeciality.uuid).missing_claims'
                           class='list-item-container' [style.background]='"#FFFFFF"'>
                        <span fxFlex='30'>{{ competenceClaims[0].thing.name }}</span>
                      </div>
                    </div>
                  </mat-tab>
                  <mat-tab label='Компетенции из команды'>
                    <div [style.padding.px]='15'>
                      <div fxLayoutAlign='center center'
                           *ngIf='isAddedClaimsEmpty(childSpeciality.uuid)'>Компетенции
                        совпадают с
                        библиотечной специальностью
                      </div>
                      <div fxLayout='row'
                           *ngFor='let competenceClaims of getChildSpecialityCompetenciesDiff(childSpeciality.uuid).added_claims'
                           class='list-item-container' [style.background]='"#FFFFFF"'>
                        <span fxFlex='30'>{{ competenceClaims[0].thing.name }}</span>
                      </div>
                    </div>
                  </mat-tab>
                  <mat-tab label='Уровни развития'>
                    <div [style.padding.px]='15'>
                      <div fxLayoutAlign='center center'
                           *ngIf='isChildSpecialityGradesDiffEmpty(childSpeciality.uuid)'>Уровни развития
                        совпадают с
                        библиотечной специальностью
                      </div>
                      <div fxLayout='row'
                           *ngFor='let grade of getChildSpecialityGradesDiff(childSpeciality.uuid).added_grades'
                           class='list-item-container' [style.background]='"#ffb6b6"'>
                        <span fxFlex='30'>{{ grade.name }}</span>
                        <span fxFlex>{{ grade.description }}</span>
                      </div>
                      <div fxLayout='row'
                           *ngFor='let grade of getChildSpecialityGradesDiff(childSpeciality.uuid).missing_grades'
                           class='list-item-container' [style.background]='"#ddfbab"'>
                        <span fxFlex='30'>{{ grade.name }}</span>
                        <span fxFlex>{{ grade.description }}</span>
                      </div>
                      <div fxLayout='row'
                           *ngFor='let gradeDiff of getChildSpecialityGradesDiff(childSpeciality.uuid).diff_grades'
                           class='list-item-container'>
                        <span fxFlex='30'>{{ gradeDiff.grade.name }}
                        </span>
                        <span fxFlex>{{ gradeDiff.grade.description }}
                          <span *ngIf='gradeDiff.diff.description'>(будет изменено на: {{gradeDiff.diff.description}}
                            )</span>
                        </span>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>
                <div class='info-speciality-text-container'>
                  <div fxLayoutAlign='start center' class='info-speciality-text' (click)='onInfoSpecialityTextClick()'>
                    <span>Что означают эти разделы?</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--          <div fxLayout='column' fxFlex='30' fxLayoutGap='8px' *ngIf='isEditModeOn' class='list-container'>-->
          <!--            <mat-slide-toggle-->
          <!--              color='warn'-->
          <!--              [(ngModel)]='updateConfig.update_textfields'-->
          <!--            >-->
          <!--              Копировать название и описание-->
          <!--            </mat-slide-toggle>-->
          <!--            <mat-slide-toggle-->
          <!--              color='warn'-->
          <!--              [(ngModel)]='updateConfig.update_grades'-->
          <!--              (ngModelChange)='onUpdateGradesToggle($event)'-->
          <!--            >-->
          <!--              Копировать уровни развития-->
          <!--            </mat-slide-toggle>-->
          <!--            <mat-slide-toggle-->
          <!--              color='warn'-->
          <!--              [(ngModel)]='updateConfig.update_competencies'-->
          <!--              [disabled]='!updateConfig.update_grades'-->
          <!--            >-->
          <!--              Копировать навыки-->
          <!--            </mat-slide-toggle>-->
          <!--          </div>-->
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
