import { Injectable } from '@angular/core';
import { AsyncList } from '@rest/AsyncList';
import { ContentSettingsHttpService } from '@services/http/ContentSettingsHttpService';
import { ContentSettings } from '@models/settings/content-settings';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  private _settings: ContentSettings;

  contentSettings: AsyncList<ContentSettings>;

  constructor(
    private _contentSettingsHttpService: ContentSettingsHttpService
  ) {}

  public async loadSettings(): Promise<void> {
    this.contentSettings = new AsyncList<ContentSettings>(
      this._contentSettingsHttpService
    );
    this.contentSettings.load().subscribe((response) => {
      if (response.results.length) {
        this._settings = response.results[0];
      } else {
        // create defaults if there is no settings created yet
        this._contentSettingsHttpService
          .create({
            allow_content_edit: false,
          })
          .subscribe((r) => {
            this._settings = r;
          });
      }
    });
  }

  public getSettings(): ContentSettings {
    return this._settings;
  }

  public setSettings(settings: ContentSettings): void {
    this._settings = settings;
  }
}
