import { Domain } from '@models/ontology/domain';
import { Thing } from '@models/ontology/thing';
import Tree from '../tree';

export const getSpecialityDomains = (
  domains: Domain[],
  things: Thing[]
): Domain[] => {
  const domainsTree = new Tree(domains, 'parent_domain');

  const thingParentDomainUuids: any = things.map((thing) => thing.domain);

  const thingDomains = [];

  thingParentDomainUuids.forEach((domainUuid) => {
    const parentDomain = domainsTree.getNode(domainUuid);

    if (thingDomains.indexOf(parentDomain.item) === -1) {
      thingDomains.push(parentDomain.item);
    }

    domainsTree.getParents(parentDomain).forEach((node) => {
      if (thingDomains.indexOf(node.item) === -1) {
        thingDomains.push(node.item);
      }
    });
  });

  return thingDomains.sort((a, b) => a.name.localeCompare(b.name));
};
