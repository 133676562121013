<app-search-select-user
  [hiddenUsers]="newUsers"
  [clearAfterSelection]="true"
  (userSelected)="onUserSelected($event)"
></app-search-select-user>
<app-user-list
  [users]="newUsers"
  [selectMode]="'multiple'"
  [checkedUsers]="checkedUsers"
  [addedUser]="addedUser"
  (selectUsers)="onSelectUsers($event)"
></app-user-list>
