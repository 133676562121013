import { Domain } from '@models/ontology/domain';

interface ChartThing {
  parent_domain: string;
  name: string;
}

export const selectDomainsForAxes = (
  domains: Domain[],
  things: ChartThing[],
  domainsToExpand: Domain[] = null,
  recursionCount = 0
): Domain[] => {
  let selectedDomains = [];
  const maxDomainsCount = 12;

  //if called recursive to expand selected domains deeper
  if (domainsToExpand) {
    domainsToExpand.forEach((p_domain) => {
      if (!checkThingsInDomain(p_domain, things)) {
        selectedDomains.push(
          ...domains.filter((domain) => domain.parent_domain === p_domain.uuid)
        );
      } else {
        // there is dome thing for this top level, cant skip it
        selectedDomains.push(p_domain);
      }
    });
  } else {
    selectedDomains = domains.filter((domain) => domain.parent_domain === null);
    return selectDomainsForAxes(
      domains,
      things,
      selectedDomains,
      (recursionCount += 1)
    );
  }
  // few domains, going recursive again, recursionCount to prevent endless loop if there is nothing to expand
  if (selectedDomains.length < maxDomainsCount && recursionCount < 3) {
    return selectDomainsForAxes(
      domains,
      things,
      selectedDomains,
      (recursionCount += 1)
    );
  }
  //if got too many domains when using recursion, return previous list of domains
  if (selectedDomains.length > maxDomainsCount && domainsToExpand) {
    return domainsToExpand;
  }
  return selectedDomains;
};

//checking if can expand domain further (domains with things cannot be expanded)
function checkThingsInDomain(domain: Domain, things: any): boolean {
  return things.find((thing) => {
    return thing.domain === domain.uuid || thing.parent_domain === domain.uuid;
  });
}
