import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { SurveyQuestionService } from 'src/app/services/survey/survey-question.service';
import { ThingService } from 'src/app/services/ontology/thing.service';

import { Survey } from 'src/app/models/survey/survey';
import { SurveyQuestion } from 'src/app/models/survey/survey-question';
import { Thing } from 'src/app/models/ontology/thing';
import { Domain } from 'src/app/models/ontology/domain';
import { SurveyQuestionTreeNode } from 'src/app/models/survey/survey-question-treenode';


@Component({
  selector: 'app-survey-edit-tree',
  templateUrl: './survey-edit-tree.component.html',
  styleUrls: ['./survey-edit-tree.component.css'],
})
export class SurveyEditTreeComponent implements OnInit {

  @Input() survey: Survey;

  public questions: SurveyQuestion[] = [];
  public node_create_request_subject: Subject<SurveyQuestionTreeNode>;
  public node_create_request_initiator: SurveyQuestionTreeNode;
  public node_delete_request_subject: Subject<SurveyQuestionTreeNode>;

  public question_create_form: UntypedFormGroup;
  public question_create_selected_type: string;

  private related_thing_search_terms = new Subject<string>();
  private related_thing_search_terms_search$: Observable<Thing[] | null>;
  public related_thing_search_results: Thing[] = [];
  public related_thing_search_selected: Thing;
  public related_thing_search_input = new UntypedFormControl('');

  public tree_data: SurveyQuestionTreeNode[] = [];
  public root_treenode: SurveyQuestionTreeNode;

  private uistate = {
    questions_loaded: false,
    question_create_form_shown: false,
    question_create_form_loading: false,
  }

  constructor(
    private form_builder: UntypedFormBuilder,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private survey_question_service: SurveyQuestionService,
    private thing_service: ThingService,
  ) {
    this.define_question_form();
  }

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.load_questions();

    this.related_thing_search_terms_search$ = this.related_thing_search_terms.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap((term: string) => this.thing_service.search(term)),
    );
    this.related_thing_search_terms_search$.subscribe(things => this.related_thing_search_results = things);

    // we use this to get created questions from subtree components
    this.node_create_request_subject = new Subject<SurveyQuestionTreeNode>();
    this.node_create_request_subject.subscribe(subtree_node => {
      this.logging_service.debug(`${this.constructor.name} received create request`);
        this.uistate.question_create_form_shown = true;
        this.node_create_request_initiator = subtree_node;
    });
    this.node_delete_request_subject = new Subject<SurveyQuestionTreeNode>();
    this.node_delete_request_subject.subscribe(subtree_node => {
      this.logging_service.debug(`${this.constructor.name} received delete request`);
        this.survey_question_service.delete(subtree_node.question.uuid).subscribe(
          response => {
            this.logging_service.debug(`${this.constructor.name} deleted question`);
            this.load_questions();
          },
          err => {
            this.alert_service.error(`Ошибка удаления вопроса: ${err.status}`);
          }
        );
    });
  }

  public get is_data_loaded(): boolean {
    return this.uistate.questions_loaded;
  }

  public get is_question_create_form_shown(): boolean {
    return this.uistate.question_create_form_shown;
  }

  public get is_question_create_form_loading(): boolean {
    return this.uistate.question_create_form_loading;
  }

  private define_question_form() {
    this.question_create_form = this.form_builder.group({
      // question_type: ['', [
      //   Validators.required,
      //   Validators.minLength(1),
      //   Validators.maxLength(128)
      // ]],
      question_title: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(128)
      ]],
      question_description: ['', [
        Validators.minLength(1),
        Validators.maxLength(1024)
      ]],
      related_thing: ['', [
        Validators.minLength(1),
        Validators.maxLength(1024)
      ]],
    });
  }

  private load_questions(): void {
    this.survey_question_service.fetch_all_for_survey_uuid(this.survey.uuid).subscribe(
      response => {
        this.questions = response.results as SurveyQuestion[];
        this.logging_service.debug(`${this.constructor.name} loaded ${this.questions.length} survey questions`);
        this.build_tree_data();
        this.uistate.questions_loaded = true;
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to load survey questions`);
        this.alert_service.error(`Ошибка загрузки вопросов ${err.status}`);
      }
    );
  }

  private build_tree_data(): void {
    this.logging_service.debug(`${this.constructor.name} build_tree_data`);

    const root_questions = this.questions.filter(q => q.depth === 0);
    if (root_questions.length > 1) {
      this.alert_service.error('На верхнем уровне должен быть только один вопрос');
      this.tree_data = [];
      return;
    }
    const root_question = root_questions[0];

    const treenodes = [];
    this.questions.forEach(q => {
      const treenode = {
        question: q,
        children: [],
        show_children: true
      } as SurveyQuestionTreeNode;
      treenodes.push(treenode)
    });
    treenodes.forEach(treenode => {
      // find questions, where our node.question is parent
      // console.log(`look at ${treenode.question.title}`);
      const child_questions = this.questions.filter(q => {
        if ((q.parent_question) && q.parent_question === treenode.question.uuid) {
          return true
        }
        if (q.parent_question && ((q.parent_question as SurveyQuestion).uuid === treenode.question.uuid)) {
          return true;
        }
      });
      // console.log(`child questions ${child_questions.length}`);
      // find treenodes for this questions
      const child_treenodes = [];
      child_questions.forEach(q => {
        const child_treenode = treenodes.find(n => n.question.uuid === q.uuid);
        treenode.children.push(child_treenode);
      });
      child_treenodes.sort((a, b) => (a.order_number > b.order_number) ? 1 : -1);

    });
    // console.log(treenodes);
    this.root_treenode = treenodes.find(n => n.question.uuid === root_question.uuid);
    // console.log(this.root_treenode);
    if (this.root_treenode) {
      this.tree_data = [this.root_treenode];
    } else {
      this.tree_data = [];
    }
    // console.log(this.tree_data);
  }

  public on_question_create_form_show(): void {
    this.logging_service.debug(`${this.constructor.name} on_question_create_form_show`);
    this.uistate.question_create_form_shown = true;
  }

  public on_create_question(): void {
    this.uistate.question_create_form_loading = true;
    this.logging_service.debug(`${this.constructor.name} creating survey question...`);
    let order_number = 0;
    let depth = 0;
    let parent_question = null;
    if (this.node_create_request_initiator) {
      this.logging_service.debug(`${this.constructor.name} using initiator node data`);
      order_number = this.node_create_request_initiator.children.length;
      depth = this.node_create_request_initiator.question.depth + 1;
      parent_question = this.node_create_request_initiator.question.uuid;
    }
    const question_data = {
      survey: this.survey.uuid,
      // question_type: this.question_create_form.value.question_type,
      question_type: 'assert_competence_level',
      title: this.question_create_form.value.question_title,
      description: this.question_create_form.value.question_description,
      related_thing: this.question_create_form.value.related_thing,
      parent_question,
      order_number,
      depth
    };
    this.survey_question_service.create(question_data).subscribe(
      response => {
        const question = response as SurveyQuestion;
        this.logging_service.debug(`${this.constructor.name} successfully created question`);
        this.alert_service.success('Вопрос создан');
        this.uistate.question_create_form_loading = false;
        this.questions.push(question);
        this.on_create_question_cancel();
        this.build_tree_data();
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} question creation failed`);
        this.alert_service.error(`Ошибка создания вопроса ${err.status}`);
        this.uistate.question_create_form_loading = false;
      }
    );
  }

  public on_create_dummy_node_question(): void {
    this.uistate.question_create_form_loading = true;
    this.logging_service.debug(`${this.constructor.name} creating survey dummy question...`);
    let order_number = 0;
    let depth = 0;
    let parent_question = null;
    if (this.node_create_request_initiator) {
      this.logging_service.debug(`${this.constructor.name} using initiator node data`);
      order_number = this.node_create_request_initiator.children.length;
      depth = this.node_create_request_initiator.question.depth + 1;
      parent_question = this.node_create_request_initiator.question.uuid;
    }
    const question_data = {
      survey: this.survey.uuid,
      question_type: 'dummy_node',
      title: this.related_thing_search_input.value ? this.related_thing_search_input.value : 'dummy_node',
      description: 'dummy_node',
      related_thing: null,
      parent_question,
      order_number,
      depth
    };
    this.survey_question_service.create(question_data).subscribe(
      response => {
        const question = response as SurveyQuestion;
        this.logging_service.debug(`${this.constructor.name} successfully created dummy node question`);
        this.alert_service.success('Вопрос создан');
        this.uistate.question_create_form_loading = false;
        this.questions.push(question);
        this.on_create_question_cancel();
        this.build_tree_data();
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} dummy question creation failed`);
        this.alert_service.error(`Ошибка создания ноды ${err.status}`);
        this.uistate.question_create_form_loading = false;
      }
    );
  }

  public on_create_question_cancel(): void {
    this.logging_service.debug(`${this.constructor.name} on_create_question_cancel`);
    // this.question_create_form.setValue({
    //   // question_type: null,
    //   question_title: null,
    //   question_description: null,
    //   related_thing: null
    // });
    this.question_create_form.reset();
    this.related_thing_search_input.reset();
    this.uistate.question_create_form_shown = false;
    this.related_thing_search_selected = null;
    this.node_create_request_initiator = null;
  }

  public on_related_thing_search(term: string): void {
    this.related_thing_search_terms.next(term);
  }

  public on_related_thing_search_autocomplete_selected(thing: Thing): void {
    this.logging_service.debug(`${this.constructor.name} on_related_thing_search_autocomplete_selected`);
    this.related_thing_search_selected = thing;
    this.related_thing_search_results = [];
    this.question_create_form.setValue({
      // question_type: 'assert_competence_level',
      question_title: thing.name,
      question_description: null,
      related_thing: thing.uuid
    });
  }

  public util_thing_search_display(thing: Thing): string {
    return thing ? `${thing.name} – ${this.get_thing_domain_name(thing)}` : '';
  }

  public get_thing_domain_name(thing: Thing): string {
    return thing ? (thing.domain as Domain).name : '';
  }

}

