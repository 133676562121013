import { Component, OnInit } from '@angular/core';

import { LoggingService } from 'src/app/services/logging.service';
import { TitleService } from 'src/app/services/title.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { UserService } from 'src/app/services/user/user.service';

import { User } from 'src/app/models/user/user';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-users-dashboard',
  templateUrl: './users-dashboard.component.html',
  styleUrls: ['./users-dashboard.component.css'],
})
export class UsersDashboardComponent implements OnInit {
  public users: User[];

  filteredUsers: User[];

  public active_users_page_data_chunk: User[];
  usersFilterForm: UntypedFormGroup;

  length_users = 0;
  page_size = 10;
  page_size_options: number[] = [10, 25, 50];

  private uistate = {
    users_loaded: false,
    is_onboarding_checkbox_disabled: false,
  };

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private title_service: TitleService,
    private user_service: UserService,
    private _formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.title_service.set_title('Пользователи');
    this.usersFilterForm = this._formBuilder.group({
      search: ['', []],
    });
    this.load_users();
  }

  public get is_data_loaded(): boolean {
    return this.uistate.users_loaded;
  }

  public get is_onboarding_checkbox_disabled(): boolean {
    return this.uistate.is_onboarding_checkbox_disabled;
  }

  private load_users(): void {
    this.logging_service.debug(`${this.constructor.name} loading users...`);
    this.user_service.fetch_all().subscribe(
      (response) => {
        this.users = response.results as User[];
        this.users.sort(
          (a, b) =>
            a.last_name.localeCompare(b.last_name) ||
            b.last_login - a.last_login
        );
        this.uistate.users_loaded = true;
        this.length_users = this.users.length;
        this.active_users_page_data_chunk = this.users.slice(0, this.page_size);
        this.filteredUsers = this.users;
        this.logging_service.debug(
          `${this.constructor.name} users loaded successfully`
        );
      },
      (err) => {
        this.alert_service.error(
          `Ошибка при загрузке пользователей: ${err.status}`
        );
      }
    );
  }

  searchSubject(): void {
    this.filteredUsers = this.users.filter((user) => {
      const searchValue = this.usersFilterForm.controls.search.value;
      return (
        !!user.email.toLowerCase().includes(searchValue.toLowerCase()) ||
        !!user.last_name.toLowerCase().includes(searchValue.toLowerCase()) ||
        !!user.first_name.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
  }

  public on_user_toggle_onboarding(user: User, $event: any): void {
    this.uistate.is_onboarding_checkbox_disabled = true;
    this.user_service
      .update(user.uuid, {
        is_onboarding_complete: !user.is_onboarding_complete,
      })
      .subscribe(
        (response) => {
          user.is_onboarding_complete = !user.is_onboarding_complete;
          this.logging_service.debug(
            `${this.constructor.name} changed onboarding flag for user ${user.uuid}`
          );
          this.uistate.is_onboarding_checkbox_disabled = false;
        },
        (err) => {
          this.alert_service.error(
            `Ошибка изменения флага онбординга: ${err.status}`
          );
        }
      );
  }

  public util_user_get_initials_for_avatar(user: User): string {
    const first_name_initial = user.first_name.charAt(0);
    const last_name_initial = user.last_name.charAt(0);
    return `${first_name_initial}${last_name_initial}`;
  }

  public on_users_page_changed(e) {
    const firstCut = e.pageIndex * e.pageSize;
    const secondCut = firstCut + e.pageSize;
    this.active_users_page_data_chunk = this.filteredUsers.slice(
      firstCut,
      secondCut
    );
    this.page_size = e.pageSize;
  }
}
