import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root',
})
export class PdpService {
  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService
  ) {}

  public create(pdp_data: any): Observable<any> {
    const params = { expand: 'subject_user,responsible_user' };
    return this.api_service.post(`track/pdp`, pdp_data, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} created pdp with uuid ${response.uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to create pdp`
          );
        }
      )
    );
  }

  public update(pdp_uuid: string, pdp_data: any): Observable<any> {
    const params = { expand: 'subject_user,responsible_user' };
    return this.api_service
      .patch(`track/pdp/${pdp_uuid}`, pdp_data, params)
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} updated pdp with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to update pdp`
            );
          }
        )
      );
  }

  public delete(pdp_uuid: string): Observable<any> {
    return this.api_service.delete(`track/pdp/${pdp_uuid}`, {}).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} deleted pdp with uuid ${pdp_uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to delete pdp`
          );
        }
      )
    );
  }

  public fetch_by_subject_user_uuid(
    subject_user_uuid: string
  ): Observable<any> {
    const params = {
      subject_user: subject_user_uuid,
      expand: 'subject_user,responsible_user',
    };
    return this.api_service.get(`track/pdp`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} pdps`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch pdps`
          );
        }
      )
    );
  }
}
