<h2 mat-dialog-title>Добавить специальность в команду</h2>
<mat-dialog-content>
  <div fxLayout="row" fxLayoutGap="3rem">
    <div fxFlex="35rem">
      <div class="help">
        Выберите команду, для добавления в нее специальности
        <br />
      </div>
      <div class="ng-select-wrapper">
        <ng-select
          [(ngModel)]="selectedTeam"
          [items]="editTeams"
          placeholder="Выберите команду"
          bindLabel="name"
          notFoundText="Вы можете попросить руководителя команды добавить специальность в вашу команду"
        >
        </ng-select>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-raised-button
    (click)="addSpecialityToTeam()"
    [disabled]="!selectedTeam"
    mat-dialog-close
  >
    Добавить
  </button>
  <button mat-raised-button mat-dialog-close>Отмена</button>
</mat-dialog-actions>
