import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { LoggingService } from 'src/app/services/logging.service';
import { TitleService } from 'src/app/services/title.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { SurveyService } from 'src/app/services/survey/survey.service';

import { Survey } from 'src/app/models/survey/survey';


@Component({
  selector: 'app-survey-create',
  templateUrl: './survey-create.component.html',
  styleUrls: ['./survey-create.component.css']
})
export class SurveyCreateComponent implements OnInit {

  survey_create_form: UntypedFormGroup;

  uistate = {
    survey_create_form_submitted: false,
    survey_create_form_loading: false,
  };

  constructor(
    private router: Router,
    private form_builder: UntypedFormBuilder,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private title_service: TitleService,
    private survey_service: SurveyService,
  ) {
    this.create_form();
  }

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.title_service.set_title('Новый опрос');
  }

  public get is_data_loaded(): boolean {
    return true;
  }

  private create_form() {
    this.survey_create_form = this.form_builder.group({
      survey_name: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(128)
      ]],
      survey_description: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1024)
      ]],
      survey_type: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1024)
      ]],
    });
  }

  public on_create(): void {
    this.uistate.survey_create_form_submitted = true;
    this.uistate.survey_create_form_loading = true;
    this.logging_service.debug(`${this.constructor.name} creating survey...`);
    const survey_data = {
      name: this.survey_create_form.value.survey_name,
      description: this.survey_create_form.value.survey_description,
      survey_type: this.survey_create_form.value.survey_type,
    };
    this.survey_service.create(survey_data)
      .pipe(first())
      .subscribe({
          next: (response_survey) => {
            const survey = response_survey as Survey;
            this.logging_service.debug(`${this.constructor.name} successfully created survey`);
            this.alert_service.success('Опрос создан', { keepAfterRouteChange: true });
            // this.router.navigate([`/admin/teams/${team.id}/edit`]);
            this.router.navigate([`/admin/surveys/survey/${survey.uuid}/edit`]);
          },
          error: error => {
            this.logging_service.debug(`${this.constructor.name} survey creation failed`);
            this.alert_service.error('Ошибка создания опроса');
            this.uistate.survey_create_form_loading = false;
          }
      });
  }

}
