import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { TeamEmailInvitation } from '@models/teams/team-email-invitation';

@Injectable({
  providedIn: 'root',
})
export class TeamEmailInvitationHttpService extends ViewSetHttpService<TeamEmailInvitation> {
  constructor(_http: HttpClient) {
    super(_http);
  }

  public viewSetUrl = 'teams/invitations';
}
