<div class='container'>
  <div class='title'>Библиотека компетенций и специальностей</div>
  <div class='tabs'>
    <mat-tab-group
      [color]="'accent'"
      animationDuration='0ms'
      mat-stretch-tabs='false'
      (selectedIndexChange)='saveActiveTabIndex($event)'
      [selectedIndex]='activeTabIndex ? activeTabIndex : 0'
    >
      <mat-tab label='Специальности'>
        <div
          class='empty-specialities-text'
          fxLayoutAlign='center start'
          *ngIf='
            specialities.state.isLoaded && specialities.state.items.length === 0
          '
        >
          Пока шаблонов специальностей не создано
        </div>
        <app-speciality-list *ngIf='loadSpecialityList'></app-speciality-list>
      </mat-tab>
      <mat-tab label='Компетенции'>
        <app-library-domain-detail *ngIf='loadLibraryDomainDetail'></app-library-domain-detail>
      </mat-tab>
      <mat-tab label='Поиск пользователей'
      >
        <app-team-match-users *ngIf='loadTeamMatchUsers'></app-team-match-users
        >
      </mat-tab>
      <mat-tab
        *ngIf='
          selfCreatedValidations.state.isLoaded &&
          selfSpecialityReviewer.state.isLoaded &&
          (selfCreatedValidations.state.items.length ||
            selfSpecialityReviewer.state.items.length)
        '
        label='Верификации'
      >
        <app-verifications
          [selfCreatedValidations]='selfCreatedValidations'
          [selfSpecialityReviewer]='selfSpecialityReviewer'
        ></app-verifications>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
