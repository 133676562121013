import { ActivatedRoute } from '@angular/router';

export const get_full_parent_path = (currentRoute: ActivatedRoute): string => {
  let route = '';
  currentRoute.snapshot.url.forEach((urlSegment) => {
    route += `/${urlSegment.path}`;
  });
  if (currentRoute.parent.routeConfig) {
    route = get_full_parent_path(currentRoute.parent) + route;
  }
  return route;
};
