import { Component, OnInit } from '@angular/core';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { AsyncList } from '@rest/AsyncList';
import { Speciality } from '@models/specialities/speciality';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-speciality-list-age',
  templateUrl: './speciality-list.page.html',
  styleUrls: ['./speciality-list.page.css'],
})
export class SpecialityListPage implements OnInit {
  specialities: AsyncList<Speciality>;
  constructor(private _titleService: TitleService) {
    this._titleService.set_title('Специальности');
  }

  ngOnInit(): void {}
}
