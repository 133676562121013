<app-with-processing
  [processing]='
    teamMemberships?.state.isProcessing &&
    specialityUsersRelations?.state.isProcessing &&
    teamJoinRequests?.state.isProcessing
  '
>
  <div
    class='members-container'
    *ngIf='
      teamMemberships &&
      teamMemberships.state.isLoaded &&
      specialityUsersRelations &&
      specialityUsersRelations.state.isLoaded &&
      teamJoinRequests?.state.isLoaded
    '
  >
    <div class='title'>Участники</div>
    <div>
      <mat-tab-group
        [animationDuration]="'0ms'"
        mat-stretch-tabs='false'
        (selectedTabChange)='onMatrixSelect($event)'
        mat-stretch-tabs='false'
      >
        <mat-tab label='Список участников'>
          <div fxLayout='row'>
            <div
              fxLayoutAlign='start center'
              class='add-member-button-container'
              (click)='onMemberAdd()'
            >
              <button
                mat-raised-button
                class='add-member-button'
                color='primary'
              >
                <mat-icon>add_circle_outline</mat-icon>
              </button>
              <span class='add-member-button-label'>Добавить участника</span>
            </div>
          </div>
          <div fxLayout='row'>
            <span fxFlex='40'>Участник</span>
            <span fxFlex='20'>Роль</span>
            <span fxFlex='40'>Специальность</span>
          </div>
          <mat-divider class='divider'></mat-divider>
          <div
            *ngFor='let membership of teamMemberships.state.items'
            class='member-container'
            fxLayout='row'
            (click)='redirectToMember(membership.user, $event)'
          >
            <div
              fxFlex='40'
              class='user-container'
              fxLayout='row'
              fxLayoutAlign='start center'
            >
              <mat-icon fxFlex='40px'>account_circle</mat-icon>
              <div fxLayout='column' fxFlex>
                <span class='user-name'>{{
                  membership.user.first_name + ' ' + membership.user.last_name
                  }}</span>
                <span class='user-email'>{{ membership.user.email }}</span>
              </div>
              <div
                fxLayout='row'
                fxLayoutGap='2px'
                fxFlex
              >
                <div *ngFor='let userMembership of membership.user.team_memberships'>
                  <div
                    *ngIf='userMembership.team.icon_url'
                    fxFlex='3rem'
                    [style.cursor]="'pointer'"
                    matTooltip='{{ userMembership.team.name }}'
                  >
                    <img [src]='userMembership.team.icon_url' [style.height.px]='30' alt='' />
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex='20' fxLayoutAlign='start center'>
              <span class='user-role'>{{ rolesMap[membership.role] }}</span>
            </div>
            <div fxFlex='35' fxLayout='row' fxLayoutAlign='start center'>
              <div
                class='user-role'
                *ngIf='getUserSpecialitiesCount(membership.user.uuid) > 0'
              >
                {{ getSpecialityName(membership.user.uuid) }}
              </div>
              <div
                class='user-role'
                *ngIf='getUserSpecialitiesCount(membership.user.uuid) > 1'
              >
                {{ getSpecialitiesCountText(membership.user.uuid) }}
              </div>
            </div>
            <div fxFlex='5' class='edit-btn' (click)='onMemberEdit(membership)'>
              <div fxLayoutAlign='center center'>
                <button mat-icon-button>
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab
          *ngIf='
            teamEmailInvitations?.state.items.length ||
            teamJoinRequests?.state.items.length
          '
        >
          <ng-template matTabLabel>
            <span
              matBadge='{{
                teamEmailInvitations?.state.items.length +
                  teamJoinRequests?.state.items.length
              }}'
              matBadgeSize='small'
            >
              Ожидают подтверждения &nbsp;
            </span>
          </ng-template>
          <div
            *ngFor='let request of teamJoinRequests.state.items'
            class='member-container'
            fxLayout='row'
            (click)='redirectToMember(request.user, $event)'
            [style.margin-top.px]='30'
          >
            <div
              fxFlex='80'
              class='user-container'
              fxLayout='row'
              fxLayoutAlign='start center'
            >
              <mat-icon fxFlex='40px'>account_circle</mat-icon>
              <div fxLayout='column' fxFlex='50'>
                <span class='user-name'>{{
                  request.user.first_name + ' ' + request.user.last_name
                  }}</span>
                <span class='user-email'>{{ request.user.email }}</span>
              </div>
              <div fxFlex='30' fxLayout='row' fxLayoutAlign='start center'>
                <div class='user-role'>
                  {{ request.specialities[0]?.name }}
                  {{ request.speciality_grades[0]?.name }}
                </div>
              </div>
            </div>
            <div
              fxFlex='10'
              class='edit-btn'
              (click)='onTeamJoinAccept(request)'
            >
              <div fxLayoutAlign='center center'>
                <button mat-stroked-button>Принять</button>
              </div>
            </div>
            <div
              fxFlex='10'
              class='edit-btn'
              (click)='onTeamJoinReject(request)'
            >
              <div fxLayoutAlign='center center'>
                <button mat-stroked-button>Отклонить</button>
              </div>
            </div>
          </div>
          <div *ngIf='teamEmailInvitations.state.items.length'>
            <h3>Приглашенные пользователи</h3>
            <mat-divider [style.margin-bottom.px]='8'></mat-divider>
            <div
              *ngFor='let invitation of teamEmailInvitations.state.items'
              class='member-container'
              fxLayout='row'
            >
              <div
                fxFlex='90'
                class='user-container'
                fxLayout='row'
                fxLayoutAlign='start center'
              >
                <mat-icon fxFlex='3rem'>account_circle</mat-icon>
                <div fxLayout='column' fxFlex>
                  <span class='user-name'>{{ invitation.email }}</span>
                </div>
              </div>
              <div
                fxFlex='10'
                class='edit-btn'
                (click)='onEmailInvitationDelete(invitation)'
              >
                <div fxLayoutAlign='center center'>
                  <button mat-stroked-button>Удалить</button>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label='Матрица компетенций' *ngIf='specialityUsersRelations.state.isLoaded'>
          <div [style.margin-top]="'24px'">
            <app-team-manage-matrix-v3
              [teamUuid]='teamUuid'
              [isMatrixTabSelected]='isMatrixTabSelected'
              [specialityUsersMap]='specialityUsersMap'
              [specialitiesThingsDomainsMap]='specialitiesThingsDomainsMap'
            ></app-team-manage-matrix-v3>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</app-with-processing>
