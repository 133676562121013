<div class='dialog-container'>
  <div class='title' *ngIf="data.dialogType === 'activity' && data.activity">Редактировать активность</div>
  <div class='title' *ngIf="data.dialogType === 'activity' && !data.activity">Добавить активность</div>
  <div class='title' *ngIf="data.dialogType === 'step' && data.step">Редактировать действие</div>
  <div class='title' *ngIf="data.dialogType === 'step' && !data.step">Добавить действие</div>
  <form [formGroup]='form' class='dialog-form'>
    <div fxLayout='column'>
      <mat-label class='textfield-label'>{{
        data.dialogType === 'activity'
          ? 'Название активности'
          : 'Название действия'
        }}</mat-label>
      <mat-form-field floatLabel='never' [style.width.rem]='30'>
        <input matInput type='text' formControlName='name' />
      </mat-form-field>
    </div>
  </form>
  <div fxLayout='row'>
    <div class='show-settings'
         (click)='isAdditionalSettingsShowed = !isAdditionalSettingsShowed' fxLayoutAlign='start center'
         fxLayoutGap='8px'>
      <mat-icon>{{ !isAdditionalSettingsShowed ? 'expand_more' : 'expand_less' }}</mat-icon>
      <span class='show-settings-text'>Связать с компетенциями</span>
    </div>
  </div>
  <div class='select-container' *ngIf='isAdditionalSettingsShowed'>
    <app-select-library-things
      [selectedClaims]='selectedClaims'
      [selectAllNodes]='false'
      (selectedClaimsChange)='onSelectedClaimsChange($event)'
    ></app-select-library-things>
  </div>

  <mat-dialog-actions fxLayoutAlign='end center'>
    <button
      mat-raised-button
      [mat-dialog-close]='false'
      class='dialog-reject-button'
    >
      Отмена
    </button>
    <button
      mat-raised-button
      class='dialog-accept-button'
      [disabled]='form.invalid'
      (click)='onAccept()'
    >
      Добавить
    </button>
  </mat-dialog-actions>
</div>
