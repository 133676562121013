<ng-container *ngIf="is_data_loaded; then tmpl_tab_content; else tmpl_preloader">
</ng-container>

<ng-template #tmpl_preloader>
    <app-preloader-progressbar></app-preloader-progressbar>
</ng-template>

<ng-template #tmpl_tab_content>
    <div fxLayout="row">
        <div fxFlex>

            <div *ngIf="events.length === 0">
                <div class="app-admin-users-user-tab-events-empty">
                    Для пользователя почему-то нет событий
                </div>
            </div>

            <div *ngIf="events.length > 0">

                <div *ngFor="let event of events">
                    <div class="app-admin-users-user-tab-events-listitem-container">

                        <div fxLayout="row">
                            <div fxFlex="10rem" fxLayoutAlign="start center">
                                <div class="app-admin-users-user-tab-events-listitem-date">
                                    {{ event.date_created | amDateFormat: 'YYYY-MM-DD HH:mm' }}
                                </div>
                            </div>
                            <div fxFlex fxLayoutAlign="start center">
                                <div class="app-admin-users-user-tab-events-listitem-text">
                                    {{ event.text }}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</ng-template>