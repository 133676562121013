<div *ngIf="reviewList && reviewList.length == 0">
  Пользователь не участвует ни в одном ревью
</div>
<div *ngFor="let review of reviewList">
  <div class="review-item">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="100%">
        <mat-card (click)="reviewRedirect(review)">
          {{ review.name }}
        </mat-card>
      </div>
    </div>
  </div>
</div>
