import { Component, Input, OnInit } from '@angular/core';
import { TeamSettingsHttpService } from '@services/http/TeamSettingsHttpService';
import { AsyncList } from '@rest/AsyncList';
import { TeamSettings } from '@models/teams/team-settings';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TeamHttpService } from '@services/http/TeamHttpService';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Team } from '@models/teams/team';
import { AlertService } from '../../../services/ui/ui-alert.service';

@Component({
  selector: 'app-team-settings',
  templateUrl: './team-settings.component.html',
  styleUrls: ['./team-settings.component.css'],
})
export class TeamSettingsComponent implements OnInit {
  teamUuid: string;

  team: AsyncDetail<Team>;

  teamSettings: AsyncList<TeamSettings>;

  joinType: number;
  keyCompetenciesMemberStatus: boolean;

  joinTypes;

  form: UntypedFormGroup;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    private _alertService: AlertService,
    private _teamHttpService: TeamHttpService,
    private _teamSettingsHttpService: TeamSettingsHttpService
  ) {}

  ngOnInit(): void {
    this.teamUuid = this._activatedRoute.snapshot.paramMap.get('uuid');

    this.team = new AsyncDetail<Team>(this.teamUuid, this._teamHttpService);
    this.team.load().subscribe(() => {
      this.form = this._formBuilder.group({
        name: ['', [Validators.required]],
        description: ['', []],
      });

      this.form.setValue({
        name: this.team.state.item.name,
        description: this.team.state.item.description,
      });
    });

    this.teamSettings = new AsyncList<TeamSettings>(
      this._teamSettingsHttpService
    );
    this.teamSettings.setRequestParams({
      params: {
        team: this.teamUuid,
      },
    });
    this.teamSettings.load().subscribe(() => {
      this.joinType = this.teamSettings.state.items[0].join_type_enum;
      this.keyCompetenciesMemberStatus =
        this.teamSettings.state.items[0].key_competencies_member_status;
    });

    this.joinTypes = [
      { type: 'Открытое', num: 1 },
      { type: 'Закрытое', num: 2 },
      { type: 'С верификацией', num: 3 },
    ];
  }

  editTeamInfo(): void {
    this.team
      .update({
        name: this.form.controls.name.value,
        description: this.form.controls.description.value,
      })
      .subscribe(() => {
        this._alertService.success('Изменения сохранены');
      });
  }

  onJoinTypeSelect(joinType: number): void {
    this.joinType = joinType;
    this.changeJoinType();
  }

  onKeyCompetenciesMemberStatus(val: boolean): void {
    this.keyCompetenciesMemberStatus = val;
    this.teamSettings.update(this.teamSettings.state.items[0].uuid, {
      key_competencies_member_status: val,
    });
  }

  changeJoinType(): void {
    this.teamSettings.update(this.teamSettings.state.items[0].uuid, {
      join_type_enum: this.joinType,
    });
  }
}
