import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { ThingHttpService } from '@services/http/ThingHttpService';
import { AsyncList } from '@rest/AsyncList';
import { Speciality } from '@models/specialities/speciality';
import { Thing } from '@models/ontology/thing';
import { Domain } from '@models/ontology/domain';
import { LibraryItemProps } from '@components/common/library-item/library-item.component';
import { DomainHttpService } from '@services/http/DomainHttpService';

@Component({
  selector: 'app-library-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit {
  specialities: AsyncList<Speciality>;
  things: AsyncList<Thing>;
  domains: AsyncList<Domain>;
  slicedThings: Thing[];
  slicedSpecialities: Speciality[];
  showResultCount = 10;

  isClearable = false;

  isFullResultShown = false;

  constructor(
    private _specialityHttpService: SpecialityHttpService,
    private _thingHttpService: ThingHttpService,
    private _domainHttpService: DomainHttpService
  ) {}

  private _searchInput$ = new Subject<string>();
  public searchFormInput = new UntypedFormControl('');

  ngOnInit(): void {
    this.specialities = new AsyncList(this._specialityHttpService);
    this.things = new AsyncList(this._thingHttpService);
    this.domains = new AsyncList(this._domainHttpService);

    this._searchInput$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter((searchInput) => {
          if (searchInput.length > 0) {
            this.isClearable = true;
            return true;
          } else {
            this.isClearable = false;
            return false;
          }
        })
      )
      .subscribe((searchInput) => {
        this.showResultCount = 10;
        this.isFullResultShown = false;
        this._loadSearchResult(searchInput);
      });
  }

  private _loadSearchResult(searchInput: string): void {
    const requestParams = {
      params: {
        search: searchInput,
        team__isnull: 'True',
      },
    };
    this.specialities.requestParams = requestParams;
    this.things.requestParams = requestParams;
    this.domains.requestParams = requestParams;

    combineLatest([this.things.load(), this.specialities.load()]).subscribe(
      () => {
        const specialitiesLength = this.specialities.state.items.length;
        if (10 - specialitiesLength < 0) {
          this.slicedSpecialities = this.specialities.state.items.slice(0, 10);
        } else {
          this.slicedSpecialities = this.specialities.state.items;
        }
        this.showResultCount -= specialitiesLength;

        if (this.showResultCount > 0) {
          this.slicedThings = this.things.state.items.slice(
            0,
            this.showResultCount
          );
        }
      }
    );
    // this.domains.load();
  }

  get resultLength(): number {
    return (
      this.specialities.state.items.length + this.things.state.items.length
    );
  }

  showFullResult(): void {
    this.slicedSpecialities = this.specialities.state.items;
    this.slicedThings = this.things.state.items;
    this.isFullResultShown = true;
  }

  onSearchInput(): void {
    this._searchInput$.next(this.searchFormInput.value);
  }

  clearSearch(): void {
    this.searchFormInput.setValue('');
    this.isClearable = false;
  }

  serializeSpeciality(speciality: Speciality): LibraryItemProps {
    return {
      header: 'Специальность',
      content: speciality.name,
      footer: speciality.description,
      linkUrl: `/library/specialities/${speciality.uuid}`,
    };
  }

  serializeThing(thing: Thing): LibraryItemProps {
    return {
      header: 'Компетенция',
      content: thing.name,
      footer: thing.description,
      linkUrl: `/things/${thing.uuid}`,
    };
  }

  serializeDomain(domain: Domain): LibraryItemProps {
    return {
      header: 'Категория компетенций',
      content: domain.name,
      footer: domain.description,
      linkUrl: '',
    };
  }

  isSearchResultEmpty(): boolean {
    return (
      this.specialities.state.items.length === 0 &&
      this.specialities.state.isLoaded &&
      this.things.state.items.length === 0 &&
      this.things.state.isLoaded &&
      this.domains.state.items.length === 0 &&
      this.domains.state.isLoaded
    );
  }
}
