import { Component, Input, OnInit } from '@angular/core';

import { User } from 'src/app/models/user/user';


@Component({
  selector: 'app-admin-user-tab-pdps',
  templateUrl: './admin-user-tab-pdps.component.html',
  styleUrls: ['./admin-user-tab-pdps.component.css']
})
export class AdminUserTabPdpsComponent implements OnInit {

  @Input() user: User;

  constructor() { }

  ngOnInit(): void {
  }
}
