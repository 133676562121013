<app-search
  [title]="config.title"
  [placeholder]="config.placeholder"
  [ariaLabel]="config.ariaLabel"
  [displayWith]="config.displayWith"
  [optionSelected]="optionSelected"
  [service]="service"
  [optionTemplate]="userSearchOption"
>
  <ng-template #userSearchOption let-option>
    {{ option.first_name }} {{ option.last_name }} – <small>{{ option.email }}</small>
  </ng-template>
</app-search>
