import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AssessmentResponse } from '@models/reviews/assessment-response';
import { AssessmentResponseHttpService } from '@services/http/AssessmentResponseHttpService';
import { AsyncList } from '@rest/AsyncList';
import { AlertService } from '../../../services/ui/ui-alert.service';

@Component({
  selector: 'app-review-thing-comment',
  templateUrl: './review-thing-comment.component.html',
  styleUrls: ['./review-thing-comment.component.css'],
})
export class ReviewThingCommentComponent implements OnInit {
  @Input() assessmentResponse: AssessmentResponse;
  assessmentResponses: AsyncList<AssessmentResponse>;
  form: UntypedFormGroup;

  isCommentButtonsActive = true;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _assessmentResponseHttpService: AssessmentResponseHttpService,
    private _alertService: AlertService,
  ) {
    this.assessmentResponses = new AsyncList<AssessmentResponse>(
      this._assessmentResponseHttpService
    );

    this.form = this._formBuilder.group({
      comment: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
    });
  }

  ngOnInit(): void {
    this.form.controls.comment.setValue(this.assessmentResponse.comment);
  }

  save(): void {
    this.isCommentButtonsActive = false;
    const comment: any = this.form.value.comment;
    this.assessmentResponses.update(this.assessmentResponse.uuid, {
      comment,
    }).subscribe(
      (response) => {
        this._alertService.success('Комментарий сохранен');
        this.isCommentButtonsActive = true;
      },
      () => {
        this._alertService.error('Не удалось сохранить комментарий');
        this.isCommentButtonsActive = true;
      });
  }

  clear(): void {
    this.isCommentButtonsActive = false;
    this.assessmentResponses.update(this.assessmentResponse.uuid, {
      comment: '',
    }).subscribe(
      (response) => {
        this._alertService.success('Комментарий удален');
        this.isCommentButtonsActive = true;
      },
      () => {
        this._alertService.error('Не удалось удалить комментарий');
        this.isCommentButtonsActive = true;
      }
    );

    this.form.controls.comment.setValue('');
  }
}
