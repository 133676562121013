import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ThingLevel } from 'src/app/models/ontology/thing-level';

interface CreateThingLevelData {
  thing_level: ThingLevel;
}

@Component({
  selector: 'app-delete-thing-level',
  templateUrl: './delete-thing-level.component.html',
  styleUrls: ['./delete-thing-level.component.css'],
})
export class DeleteThingLevelComponent {
  form: UntypedFormGroup;
  constructor(
    public dialogRef: MatDialogRef<DeleteThingLevelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateThingLevelData
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
