import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AlertService } from 'src/app/services/ui/ui-alert.service';

import { SpecialityHttpService } from '@services/http/SpecialityHttpService';

@Component({
  selector: 'app-library-speciality-create',
  templateUrl: './speciality-create.component.html',
  styleUrls: ['./speciality-create.component.css'],
})
export class SpecialityCreateComponent {
  form: UntypedFormGroup;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _specialityHttpService: SpecialityHttpService,
    private _router: Router,
    private _alertService: AlertService
  ) {
    this.form = this._formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      description: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
    });
  }

  create(): void {
    this._specialityHttpService
      .create({
        ...this.form.value,
      })
      .subscribe(
        (response) => {
          this._router.navigate([
            `/library/specialities/${response.uuid}/edit`,
          ]);
        },
        (error) => {
          this._alertService.error('Не удалось создать специальность');
        }
      );
  }
}
