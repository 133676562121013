<app-with-processing [processing]="!domains.state.isLoaded || !treeDataReady">
  <div
    class="team-desires-container"
    *ngIf="domains.state.isLoaded && treeDataReady"
  >
    <div class="desires-title">Запросы на обучение</div>
    <!-- <div class="desires-desc">
      Дизайнер интерфейсов будет решать задачи коммуникации между системой ввода
      данных и пользователем. Описание в несколько строчек.
    </div>
    <mat-divider class="divider"></mat-divider> -->
    <div>
      <app-domain-thing-tree
        [domains]="domains.state.items"
        [things]="things"
        [thingDesireWithUsersMap]="thingDesireWithUsersMap"
        [expandFirstNode]="true"
      ></app-domain-thing-tree>
    </div>
  </div>
</app-with-processing>
