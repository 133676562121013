import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from 'src/app/services/api.service';
import { LoggingService } from 'src/app/services/logging.service';

@Injectable({
  providedIn: 'root',
})
export class DomainService {
  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService
  ) {}

  public fetch_all(): Observable<any> {
    const params = { expand: 'parent_domain,team' };
    return this.api_service.get(`ontology/domain`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} domains`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch domains`
          );
        }
      )
    );
  }

  public fetch_by_uuid(domain_uuid: string): Observable<any> {
    const params = { expand: 'parent_domain,team' };
    return this.api_service.get(`ontology/domain/${domain_uuid}`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched domain with uuid ${domain_uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch domain`
          );
        }
      )
    );
  }

  public fetch_by_team_uuid(team_uuid: string): Observable<any> {
    const params = { team: team_uuid, expand: 'parent_domain,team' };
    return this.api_service.get(`ontology/domain`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched domains for team ${team_uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch domains`
          );
        }
      )
    );
  }

  public fetch_domains_for_user_competencies(
    user_uuid: string
  ): Observable<any> {
    const params = { user: user_uuid, expand: 'parent_domain,team' };
    return this.api_service.get(`ontology/domain/user`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched domains for user ${user_uuid} competencies`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch domains for user competencies`
          );
        }
      )
    );
  }

  public fetch_domains_for_team_desires(team_uuid: string): Observable<any> {
    const params = { team: team_uuid, expand: 'parent_domain,team' };
    return this.api_service.get(`ontology/domain/teamdesire`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched domains for team desires ${team_uuid} competencies`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch domains for team desires`
          );
        }
      )
    );
  }

  public create(domain_data: any): Observable<any> {
    const params = { expand: 'parent_domain,team' };
    return this.api_service.post(`ontology/domain`, domain_data, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} created domain with uuid ${response.uuid} and name ${response.name}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to create domain`
          );
        }
      )
    );
  }

  public update(domain_uuid: string, domain_data: any): Observable<any> {
    return this.api_service
      .patch(`ontology/domain/${domain_uuid}`, domain_data, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} updated domain with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to update domain`
            );
          }
        )
      );
  }

  public delete(domain_uuid: string): Observable<any> {
    return this.api_service.delete(`ontology/domain/${domain_uuid}`, {}).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} deleted domain with id ${domain_uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to delete domain`
          );
        }
      )
    );
  }
}
