import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// material-ui modules
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';

// common ui modules
import { ReplaceLineBreaks } from './pipes/newline-to-br.pipe';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { PreloaderProgressbarComponent } from './components/preloader-progressbar/preloader-progressbar.component';
import { InDevelopmentStubComponent } from './components/in-development-stub/in-development-stub.component';
import { UserInlineComponent } from './components/user-inline/user-inline.component';
import { SearchComponent } from './components/search/search.component';
import { UserSearchComponent } from './components/search/user-search/user-search.component';
import { AcceptDialogComponent } from './components/accept-dialog/accept-dialog.component';
import { TextfieldDialogComponent } from './components/textfield-dialog/textfield-dialog.component';
import { MatDividerModule } from '@angular/material/divider';
import { CustomProgressBarComponent } from './custom-progress-bar/custom-progress-bar.component';

@NgModule({
  declarations: [
    PreloaderComponent,
    ReplaceLineBreaks,
    PreloaderProgressbarComponent,
    InDevelopmentStubComponent,
    UserInlineComponent,
    SearchComponent,
    UserSearchComponent,
    AcceptDialogComponent,
    TextfieldDialogComponent,
    CustomProgressBarComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,

    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatDividerModule,
  ],
  exports: [
    PreloaderComponent,
    PreloaderProgressbarComponent,
    InDevelopmentStubComponent,
    UserInlineComponent,
    ReplaceLineBreaks,
    SearchComponent,
    UserSearchComponent,
    CustomProgressBarComponent,
  ],
})
export class CommonUIElementsModule {}
