import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { ThingHttpService } from '@services/http/ThingHttpService';
import { AsyncList } from '@rest/AsyncList';
import { Domain } from '@models/ontology/domain';
import { Thing } from '@models/ontology/thing';
import { SpecialityCompetenceClaimHttpService } from '@services/http/SpecialityCompetenceClaimHttpService';
import { AddLibraryCompetenceComponent } from './add-library-competence/add-library-competence.component';

@Component({
  selector: 'app-add-competence-from-library-dialog',
  templateUrl: './add-competence-from-library-dialog.component.html',
  styleUrls: ['./add-competence-from-library-dialog.component.css'],
})
export class AddCompetenceFromLibraryDialogComponent implements OnInit {
  form: UntypedFormGroup;
  domains: AsyncList<Domain>;
  things: AsyncList<Thing>;

  processingFilteredThings = false;
  filteredThings: Thing[];

  selectedClaims = [];
  constructor(
    public dialogRef: MatDialogRef<AddCompetenceFromLibraryDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      onSubmit(formData: string[]): void;
    },
    private _dialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
    private _domainHttpService: DomainHttpService,
    private _thingHttpService: ThingHttpService,
    private _specialityCompetenceClaimHttpService: SpecialityCompetenceClaimHttpService
  ) {
    this.domains = new AsyncList(this._domainHttpService);
    this.things = new AsyncList(this._thingHttpService);

    this.things.setRequestParams({
      params: {
        team__isnull: 'True',
      },
    });

    this.domains.load();
  }

  ngOnInit(): void {
    this._loadThings();

    this.form = this._formBuilder.group({
      search: ['', []],
    });
  }

  private _loadThings(): void {
    this.processingFilteredThings = true;
    this.things.load().subscribe(() => {
      this.filteredThings = this.things.state.items;
      this.processingFilteredThings = false;
    });
  }

  search(): void {
    this.processingFilteredThings = true;
    this.filteredThings = this.things.state.items.filter((thing) =>
      thing.name
        .toLowerCase()
        .includes(this.form.controls.search.value.toLowerCase())
    );
    setTimeout(() => {
      this.processingFilteredThings = false;
    }, 100);
  }

  onSelectedClaimsChange(selectedClaims: string[]): void {
    this.selectedClaims = selectedClaims;
  }

  openCreateDialog(): void {
    this._dialog
      .open(AddLibraryCompetenceComponent)
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) this._loadThings();
      });
  }

  close(): void {
    this.data.onSubmit(this.selectedClaims);
  }
}
