import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root',
})
export class SurveySessionService {
  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService
  ) {}

  public fetch_all_for_survey(survey_uuid: string): Observable<any> {
    const params = { survey: survey_uuid, expand: 'survey,user' };
    return this.api_service.get(`surveys/session`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} survey sessions`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch survey sessions`
          );
        }
      )
    );
  }

  public fetch_user_session_survey(
    user_uuid: string,
    survey_uuid: string
  ): Observable<any> {
    const params = {
      user: user_uuid,
      survey: survey_uuid,
      expand: 'survey,user,current_question',
    };
    return this.api_service.get(`surveys/session`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched user survey session`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch user survey session`
          );
        }
      )
    );
  }

  public fetch_unfinished_user_sessions(user_uuid: string): Observable<any> {
    const params = { user: user_uuid, is_finished: 0, expand: 'survey,user' };
    return this.api_service.get(`surveys/session`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched unfinished user survey sessions`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch unfinished user survey sessions`
          );
        }
      )
    );
  }

  public fetch_finished_user_sessions(user_uuid: string): Observable<any> {
    const params = { user: user_uuid, is_finished: 1, expand: 'survey,user' };
    return this.api_service.get(`surveys/session`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched finished user survey sessions`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch finished user survey sessions`
          );
        }
      )
    );
  }

  public create(survey_session_data: any): Observable<any> {
    return this.api_service
      .post(`surveys/session`, survey_session_data, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} created user survey session with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to create user survey session`
            );
          }
        )
      );
  }

  public update(
    survey_session_uuid: string,
    survey_session_data: any
  ): Observable<any> {
    return this.api_service
      .patch(`surveys/session/${survey_session_uuid}`, survey_session_data, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} updated user survey session with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to update user survey session`
            );
          }
        )
      );
  }
}
