import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject, BehaviorSubject } from 'rxjs';
import { User } from 'src/app/models/user/user';

import { LoggingService } from 'src/app/services/logging.service';
import { AsyncList } from '@rest/AsyncList';
import { Direction } from '@models/directions/direction';
import { Domain } from '@models/ontology/domain';
import { DirectionHttpService } from '@services/http/DirectionHttpService';

interface UserProfileSubject {
  loaded: boolean;
  profile: {
    uuid: string;
    data: any;
    roles: any;
    server_model: any;
  } | null;
  userDirectionsDomainsLoaded: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth_events_subject: Subject<any>;
  public check_can_user_edit_team_subject: BehaviorSubject<any>;
  public userProfile$: BehaviorSubject<UserProfileSubject>;

  private auth_state = {
    is_logged_in: false,
    is_data_fetched: false,
  };

  private user_profile = {
    uuid: null,
    data: null,
    roles: null,
    server_model: null,
  };

  private user_edit_teams = {
    data: [],
    loaded: false,
  };

  private user_memberships = {
    data: [],
    loaded: false,
  };

  private userDirections: AsyncList<Direction>;
  private userDirectionsDomains: string[] = [];
  private userDirectionsSpecialities: string[] = [];

  constructor(
    protected readonly keycloak_service: KeycloakService,
    private logging_service: LoggingService,
    private _directionHttpService: DirectionHttpService
  ) {
    this.check_can_user_edit_team_subject = new BehaviorSubject<any>({
      value: { teams: [], loaded: false },
    });
    this.userProfile$ = new BehaviorSubject<any>({
      loaded: false,
      userDirectionsDomainsLoaded: false,
      profile: null,
    });
  }

  public set_auth_events_subject(subject: Subject<any>): void {
    this.auth_events_subject = subject;
  }

  public set_current_user_data(user_data: any): void {
    this.logging_service.debug(
      `${this.constructor.name} set_current_user_data`
    );
    this.user_profile.server_model = user_data;
    this.auth_state.is_data_fetched = true;
  }

  public is_logged_in(): boolean {
    return this.auth_state.is_logged_in && this.auth_state.is_data_fetched;
  }

  public is_admin(): boolean {
    return this.is_logged_in() && this.user_profile.roles.includes('admin');
  }

  public is_onboarding_complete(): boolean {
    return (
      this.user_profile.server_model &&
      this.user_profile.server_model.is_onboarding_complete
    );
  }

  public async auth(): Promise<any> {
    this.logging_service.debug(
      `${this.constructor.name} auth flow, check status`
    );
    this.keycloak_service.isLoggedIn().then(
      (is_logged_in) => {
        this.auth_state.is_logged_in = is_logged_in;
        this.auth_events_subject.next({
          event: 'logged_in_status_changed',
          value: is_logged_in,
        });
        if (is_logged_in) {
          this.keycloak_service.loadUserProfile().then((result) => {
            this.logging_service.debug(
              `${this.constructor.name} auth flow, status positive`
            );
            this.user_profile.data = result;
            this.user_profile.uuid =
              this.keycloak_service.getKeycloakInstance().subject;
            this.user_profile.roles = this.keycloak_service.getUserRoles();
            this.userProfile$.next({
              loaded: true,
              profile: this.user_profile,
              userDirectionsDomainsLoaded: false,
            });
            this.auth_events_subject.next({
              event: 'auth_profile_loaded',
              value: true,
            });
            this.setUserDirectionsDomains();
            return true;
          });
        } else {
          return false;
        }
      },
      (err) => {
        console.log('auth service flow err');
        console.log(err);
      }
    );
  }

  public get_current_user_uuid(): string | null {
    if (this.auth_state.is_logged_in) return this.user_profile.uuid;
    return null;
  }

  public get_current_user(): User | null {
    if (this.is_logged_in()) return this.user_profile.server_model;
    return null;
  }

  public debug_get_user_data(): any {
    return {
      auth_state: this.auth_state,
      uuid: this.user_profile.uuid,
      keycloak_data: this.user_profile.data,
      backend_data: this.user_profile.server_model,
      roles: this.user_profile.roles,
    };
  }

  public login(): void {
    this.keycloak_service.login({ redirectUri: window.location.origin });
  }

  public logout(): void {
    this.logging_service.debug(
      `${this.constructor.name} logout, calling keycloak logout`
    );
    this.keycloak_service.logout().then((result) => {
      this.auth_state.is_logged_in = false;
      this.auth_events_subject.next({
        event: 'logged_in_status_changed',
        value: false,
      });
    });
  }

  public getUserDirectionSpecialities(): string[] {
    return this.userDirectionsSpecialities;
  }

  public getUserDirections(): AsyncList<Direction> {
    return this.userDirections;
  }

  public updateUserDirectionsDomains(domainUuid: string): void {
    this.userDirectionsDomains.push(domainUuid);
  }

  public getUserDirectionsDomains(): string[] {
    return this.userDirectionsDomains;
  }

  public setUserDirectionsDomains(): void {
    this.userDirections = new AsyncList(this._directionHttpService);
    this.userDirections.setRequestParams({
      params: {
        admins: this.get_current_user_uuid(),
      },
    });
    this.userDirections.load().subscribe(() => {
      this.userDirections.state.items.forEach((direction) => {
        (direction.domains as string[]).forEach((domain) => {
          if (!this.userDirectionsDomains.includes(domain)) {
            this.userDirectionsDomains.push(domain);
          }
        });
        (direction.specialities as string[]).forEach((speciality) => {
          if (!this.userDirectionsSpecialities.includes(speciality)) {
            this.userDirectionsSpecialities.push(speciality);
          }
        });
      });
      this.userProfile$.next({
        loaded: this.userProfile$.value.loaded,
        profile: this.user_profile,
        userDirectionsDomainsLoaded: true,
      });
    });
  }

  public set_user_edit_teams(teams) {
    this.user_edit_teams = {
      data: teams,
      loaded: true,
    };

    this.check_can_user_edit_team_subject.next({
      event: 'teams loaded',
      value: { teams: this.user_edit_teams.data, loaded: true },
    });
  }

  public get_user_edit_teams_subject() {
    return this.check_can_user_edit_team_subject;
  }

  public set_user_memberships(memberships) {
    this.user_memberships = {
      loaded: true,
      data: memberships,
    };
  }

  public get_user_memberships() {
    return this.user_memberships;
  }
}
