import { Component, Input, OnInit } from '@angular/core';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { SurveyAssignmentService } from 'src/app/services/survey/survey-assignment.service';

import { User } from 'src/app/models/user/user';
import { SurveyAssignment } from 'src/app/models/survey/survey-assignment';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { AsyncList } from '@rest/AsyncList';
import { Domain } from '@models/ontology/domain';
import { Competence } from '@models/competencies/competence';
import { Thing } from '@models/ontology/thing';
import { CompetenceHttpService } from '@services/http/CompetenceHttpService';

export interface ThingUserCompetenceMap {
  [thingUuid: string]: Competence;
}

@Component({
  selector: 'app-admin-user-tab-competencies',
  templateUrl: './admin-user-competencies-tab.component.html',
  styleUrls: ['./admin-user-competencies-tab.component.css'],
})
export class AdminUserCompetenciesTabComponent implements OnInit {
  @Input() user: User;

  domains: AsyncList<Domain>;
  userCompetencies: AsyncList<Competence>;

  things: Thing[] = [];

  thingUserCompetenceMap: ThingUserCompetenceMap = {};

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private _domainHttpService: DomainHttpService,
    private _competenceHttpService: CompetenceHttpService
  ) {}

  ngOnInit(): void {
    this._loadDomains();
    this._loadUserCompetencies();
  }

  private _loadDomains(): void {
    this.domains = new AsyncList<Domain>(this._domainHttpService);
    this.domains.load().subscribe();
  }

  private _loadUserCompetencies(): void {
    this.userCompetencies = new AsyncList<Competence>(
      this._competenceHttpService
    );
    this.userCompetencies.setRequestParams({
      params: {
        user: this.user.uuid,
        expand: 'thing,thing_level',
      },
    });
    this.userCompetencies.load().subscribe(() => {
      this.userCompetencies.state.items.forEach((competence) => {
        const thing = competence.thing as Thing;
        this.things.push(thing);
        this.thingUserCompetenceMap[thing.uuid] = competence;
      });
    });
  }
}
