<div class="router-outlet-container">
    <ng-container *ngIf="is_data_loaded; then component_content; else preloader">
    </ng-container>
</div>

<ng-template #preloader>
    <app-preloader></app-preloader>
</ng-template>

<ng-template #component_content>
    <div class="app-survey-main-container">

        <div *ngIf="is_header_shown" class="app-survey-main-header-container">
            <div fxLayout="row">
                <div fxFlex="100%" fxFlexOffset="1rem">
                    <!-- <div class="app-survey-main-header-name">
                        <span class="app-survey-main-header-name-prefix">
                            Опрос
                        </span>
                        {{ survey.name }}
                    </div> -->
                    <div *ngIf="survey.survey_type === 'linear'">
                        <div class="app-survey-main-header-description">
                            {{ survey.description }}
                        </div>
                    </div>
                    <div *ngIf="survey.survey_type === 'tree'">
                        <div class="app-survey-main-header-breadcrumbs-flex-container">
                            <ul class="app-survey-main-header-breadcrumbs-flex-breadcrumb">
                                <li *ngFor="let breadcrumb of breadcrumbs">
                                    {{ breadcrumb }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div>
            <mat-progress-bar
                (click)="on_toggle_survey_header()"
                mode="determinate"
                [color]="ui_color"
                [value]="current_percentage"></mat-progress-bar>
        </div>


        <ng-container *ngIf="is_survey_finished; then tmpl_survey_finish; else (is_survey_started && tmpl_child_component) || (true && tmpl_survey_start)">
        </ng-container>

    </div>
</ng-template>

<ng-template #tmpl_child_component>
    <div class="app-survey-main-child-component-container">
        <div *ngIf="survey.survey_type === 'linear'">
            <app-survey-linear
                [questions]="survey_questions"
                [control_subject]="control_subject"
                [submitted_answer_subject]="submitted_answer_subject"
                [skipped_question_subject]="skipped_question_subject">
            </app-survey-linear>
        </div>
        <div *ngIf="survey.survey_type === 'tree'">
            <app-survey-tree
                [questions]="survey_questions"
                [control_subject]="control_subject"
                [visual_control_subject]="visual_control_subject"
                [submitted_answer_subject]="submitted_answer_subject"
                [skipped_question_subject]="skipped_question_subject"
                [returned_question_subject]="returned_question_subject"
                [breadcrumbs_subject]="breadcrumbs_subject">
            </app-survey-tree>
        </div>
    </div>
</ng-template>

<ng-template #tmpl_survey_start>
    <div class="app-survey-main-start-container">
        <div class="app-survey-main-start-header-container">
            <div class="app-survey-main-start-header-name">
                {{ survey.name }}
            </div>
            <div class="app-survey-main-start-header-description">
                {{ survey.description }}
            </div>
        </div>
        <div class="app-survey-main-start-hint">
            <p>Вам предстоит указать свой текущий уровень владения по ряду навыков</p>
            <p>Вы можете отмечать навыки в которых вам хотелось бы развиваться – это учитывается</p>
        </div>
        <div class="app-survey-main-start-estimated-time">
            Прохождение опроса займет приблизительно {{ get_survey_estimated_time_minutes }} мин.
        </div>
        <div class="app-survey-main-start-controls">
            <button mat-raised-button
                color="warn"
                (click)="on_start()">
                <mat-icon>play_arrow</mat-icon>
                Поехали
            </button>
        </div>
    </div>
</ng-template>

<ng-template #tmpl_survey_finish>
    <div class="app-survey-main-finish-container">
        <div class="app-survey-main-finish-text-congratulations">
            🎉 Ура, закончили!
        </div>

        <div *ngIf="survey.survey_type === 'linear'">
            <button mat-raised-button
                color="warn"
                (click)="on_finish()">
                Вернуться в профиль
            </button>
        </div>
        <div *ngIf="survey.survey_type === 'tree'">
            <a mat-raised-button color="warn" routerLink="/surveys/result/tree/{{ survey.uuid }}/{{ survey_session.user }}">
                Посмотреть результаты
            </a>

            <!-- &nbsp;
            <button mat-raised-button
                (click)="on_finish()">
                Вернуться в профиль
            </button> -->
        </div>

    </div>
</ng-template>
