<div class="router-outlet-container">
  <ng-container *ngIf="is_data_loaded; then component_content; else preloader">
  </ng-container>
</div>

<ng-template #preloader>
  <app-preloader></app-preloader>
</ng-template>

<ng-template #component_content>
  <div class="app-admin-surveys-dashboard-container">
    <div fxLayout="row">
      <div fxFlex>
        <div class="app-admin-surveys-dashboard-main-container">

          <div class="app-admin-surveys-dashboard-main-header-container">
            <div fxLayout="row">
              <div fxFlex=70>
                <div class="app-admin-surveys-dashboard-main-header-text">
                  Опросы
                </div>
              </div>
              <div fxFlex>
                <div class="app-admin-surveys-dashboard-main-header-controls">
                  <a mat-raised-button routerLink="/admin/surveys/create" style="float: right">
                    <mat-icon>group_add</mat-icon>
                    Создать опрос
                  </a>
                </div>
              </div>
            </div>
          </div>

          <mat-divider></mat-divider>

          <div>
            <div *ngFor="let survey of surveys">
              <div class="app-admin-surveys-dashboard-survey-listitem" fxLayout="row" fxLayoutAlign="space-between">
                <a routerLink="/admin/surveys/survey/{{ survey.uuid }}" class="app-admin-surveys-dashboard-listitem-link"
                   fxFlex>
                  {{ survey.name }}
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-template>

