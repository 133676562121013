import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root',
})
export class PdpActivityService {
  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService
  ) {}

  public create(activity_data: any): Observable<any> {
    const params = {};
    return this.api_service.post(`track/activity`, activity_data, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} created pdp activity with uuid ${response.uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to create pdp activity`
          );
        }
      )
    );
  }

  public update(activity_uuid: string, activity_data: any): Observable<any> {
    const params = {};
    return this.api_service
      .patch(`track/activity/${activity_uuid}`, activity_data, params)
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} updated pdp activity with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to update pdp activity`
            );
          }
        )
      );
  }

  public delete(activity_uuid: string): Observable<any> {
    return this.api_service.delete(`track/activity/${activity_uuid}`, {}).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} deleted pdp activity with uuid ${activity_uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to delete pdp activity`
          );
        }
      )
    );
  }

  public fetch_by_pdp_uuid(pdp_uuid: string): Observable<any> {
    const params = { pdp: pdp_uuid };
    return this.api_service.get(`track/activity`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} pdp activities`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch pdp activities`
          );
        }
      )
    );
  }
}
