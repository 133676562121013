import { Component, OnInit } from '@angular/core';

import { LoggingService } from 'src/app/services/logging.service';
import { TitleService } from 'src/app/services/title.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';

import { Survey } from 'src/app/models/survey/survey';
import { SurveyService } from 'src/app/services/survey/survey.service';


@Component({
  selector: 'app-surveys-dashboard',
  templateUrl: './surveys-dashboard.component.html',
  styleUrls: ['./surveys-dashboard.component.css']
})
export class SurveysDashboardComponent implements OnInit {

  public surveys: Survey[];

  private uistate = {
    surveys_loaded: false,
  };

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private title_service: TitleService,
    private survey_service: SurveyService,
  ) { }

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.title_service.set_title('Опросы');
    this.load_surveys();
  }

  public get is_data_loaded(): boolean {
    return this.uistate.surveys_loaded;
  }

  private load_surveys(): void {
    this.logging_service.debug(`${this.constructor.name} loading surveys...`);
    this.survey_service.fetch_all().subscribe(
      response => {
        this.surveys = response.results as Survey[];
        this.uistate.surveys_loaded = true;
        this.logging_service.debug(`${this.constructor.name} surveys loaded successfully`);
      },
      err => {
        this.alert_service.error(`Ошибка при загрузке опросов: ${err.status}`);
      }
    );
  }

}
