<app-with-processing
  [processing]='allTeams.state.isProcessing || libraryThings.state.isProcessing'></app-with-processing>
<mat-card class='team-match-users-container' *ngIf='libraryThings.state.isLoaded && allTeams.state.isLoaded'>
  <div class='title'>Подбор специалистов</div>

  <form (ngSubmit)='onSubmit()' [formGroup]='form'>
    <div class='form-fields'>
      <div fxLayout='row' fxLayoutGap='5rem' fxLayoutAlign='baseline center'>
        <div fxFlex>
          <ng-select
            class='thing-input-field'
            [multiple]='true'
            placeholder='Выбрать навык'
            (search)='onSearchThings($event.term)'
            (change)='updateThings($event)'
            [closeOnSelect]='false'
            dropdownPosition='bottom'
          >
            <ng-option
              class='thing-option'
              *ngFor='let thing of things'
              [value]='thing'
            >
              <div *ngIf='isOptionSelected(thing)'>{{ thing.name }}</div>
              <div *ngIf='!isOptionSelected(thing)'>
                <strong>{{ thing.name }}</strong> <br />
                <mat-icon> {{ getThingOptionInfo(thing).icon }} </mat-icon>
                {{ getThingOptionInfo(thing).sourceName }} >
                {{ getThingOptionInfo(thing).hasParentDomain ? '... >' : '' }}
                {{ getThingOptionInfo(thing).domainName }}
              </div>
            </ng-option>
          </ng-select>
        </div>
        <div fxFlex>
          <ng-select
            placeholder='Команды'
            [items]='teams'
            (search)='onSearchTeams($event.term)'
            [multiple]='true'
            [closeOnSelect]='false'
            (change)='updateTeams($event)'
            notFoundText='Команды не найдены'
            placeholder='Выбрать команды'
            bindLabel='name'
            dropdownPosition='bottom'
          >
          </ng-select>
        </div>
      </div>

      <div class='things'>
        <div class='thing-item' *ngFor='let thing of form.value.things'>
          <app-match-users-thing
            [thing]='thing'
            (selectThingLevel)='onSelectThingLevel($event)'
            (changeThingIsRequired)='onChangeThingIsRequired($event)'
            (selectThingLevelMatch)='onSelectThingLevelMatch($event)'
            [searchResult]='searchResult'
          ></app-match-users-thing>
        </div>
      </div>
    </div>

    <div class='form-buttons' fxLayout='row' fxLayoutGap='8px'>
      <button mat-raised-button>Подобрать специалистов</button>
      <button mat-raised-button (click)='onCopyToClipboard()'>
        Скопировать данные
      </button>
    </div>

    <app-with-processing [processing]='isProcessing'>
      <div class='search-result'>
        <div fxFlex *ngIf='!searchResultUsers && !isProcessing'>
          Выберите необходимые навыки и нажмите кнопку "Подобрать специалистов"
        </div>

        <div class='search-result-users' *ngIf='searchResultUsers'>
          <div *ngIf='searchResultUsers.length === 0'>
            Нет подходящих специалистов
          </div>

          <div *ngFor='let searchResultUser of searchResultUsers'>
            <div class='match-user'>
              <div fxLayout='row'>
                <div fxFlex='5rem'>
                  <div class='match-user-avatar'>
                    {{ searchResultUser.user | getUserInitials }}
                  </div>
                </div>
                <div fxFlex='30rem'>
                  <div class='match-user-name'>
                    <a
                      routerLink='/account/user-profile/{{
                        searchResultUser.user.uuid
                      }}'
                      class='match-user-link'
                    >
                      {{ searchResultUser.user.first_name }}
                      {{ searchResultUser.user.last_name }}
                    </a>
                  </div>
                  <div class='match-user-email'>
                    {{ searchResultUser.user.email }}
                  </div>
                </div>
                <app-match-user-competencies
                  [competencies]='searchResultUser.competencies'
                  [competenceDesires]='searchResultUser.competence_desires'
                  [things]='form.value.things'
                ></app-match-user-competencies>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-with-processing>
  </form>
</mat-card>
