<div fxLayout="row" [style.height.px]="barHeight">
  <div
    [style.background]="completeColor"
    fxFlex="{{ (value / maxValue) * 100 + '%' }}"
  ></div>
  <div
    [style.background]="incompleteColor"
    fxFlex="{{ 100 - (value / maxValue) * 100 + '%' }}"
  ></div>
</div>
