<div class='router-outlet-container'>
  <ng-container *ngIf='is_data_loaded; then component_content; else preloader'>
  </ng-container>
</div>

<ng-template #preloader>
  <app-preloader></app-preloader>
</ng-template>

<ng-template #component_content>
  <div class='app-admin-teams-details-container'>
    <ng-container
      [ngTemplateOutlet]='
        in_edit_mode() ? tmpl_header_in_edit_mode : tmpl_header
      '
    >
    </ng-container>

    <div class='app-admin-teams-dashboard-team-details-content-container'>
      <mat-tab-group [color]='ui_tabs_color' animationDuration='0ms' mat-stretch-tabs='false'>
        <mat-tab label='Участники'>
          <div
            class='app-admin-teams-dashboard-team-details-content-tab-container'
          >
            <ng-container
              *ngIf='
                is_member_add_form_shown;
                then tmpl_member_add;
                else tmpl_member_list
              '
            >
            </ng-container>
          </div>
        </mat-tab>

        <mat-tab label='Приглашения'>
          <app-with-processing
            [processing]='teamInvitations.state.isProcessing || teamJoinRequests.state.isProcessing'>
          </app-with-processing>
          <div>
            <h3>Приглашения</h3>
            <div *ngFor='let invitation of teamInvitations.state.items' fxLayout='row' fxLayoutAlign='start center'
                 fxLayoutGap='8px'
                 class='li-container'>
              <div fxFlex>{{ invitation.email }}</div>
              <div fxFlex fxLayout='column'>
                <div *ngFor='let speciality of invitation.specialities'>
                  {{ speciality.name + ': ' + getSpecialityGradeName(invitation, speciality) }}
                </div>
              </div>
              <div fxFlex>
                {{ 'Отправлено: ' + getPrettyInvitationDate(invitation.date_updated) }}
              </div>
              <div fxFlex>Статус: {{ statusMap[invitation.status] + 'о' }}</div>
              <div fxFlex='48px'>
                <button mat-icon-button (click)='deleteTeamInvitation(invitation.uuid)'>
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
            <h3>Запросы на присоединение</h3>
            <div *ngFor='let request of teamJoinRequests.state.items' fxLayout='row' fxLayoutAlign='start center'
                 fxLayoutGap='8px'
                 class='li-container'>
              <div fxFlex>{{ request.user.last_name + ' ' + request.user.first_name }}</div>
              <div fxFlex fxLayout='column'>
                <div *ngFor='let speciality of request.specialities'>
                  {{ speciality.name + ': ' + getSpecialityGradeName(request, speciality) }}
                </div>
              </div>
              <div fxFlex>
                {{ 'Отправлено: ' + getPrettyInvitationDate(request.date_updated) }}
              </div>
              <div fxFlex>Статус: {{ statusMap[request.status] }}</div>
              <div fxFlex='48px'>
                <button mat-icon-button (click)='deleteTeamJoinRequest(request.uuid)'>
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label='Метрики команды'>
          <app-with-processing
            [processing]='teamMembersStatus.state.isProcessing'
          >
            <div
              class='app-admin-teams-dashboard-team-details-content-tab-container'
            >
              <div
                [style.margin-bottom.px]='10'
                fxLayout='row'
                fxLayoutGap='8px'
              >
                <button
                  mat-stroked-button
                  (click)='startUpdateTeamMatrixCacheTask()'
                >
                  Перерасчет матрицы
                </button>
                <button
                  mat-stroked-button
                  (click)='startCalculateTeamMembersStatusTask()'
                >
                  Перерасчет метрик
                </button>
                <button mat-stroked-button (click)='deleteAllMemberStatus()'>
                  Удалить метрики
                </button>
              </div>
              <div *ngIf='teamMembersStatus.state.items.length'>
                <ng-container
                  [ngTemplateOutlet]='metricList'
                  [ngTemplateOutletContext]="{
                    title: 'Key People',
                    metricKey: 'key_people'
                  }"
                >
                </ng-container>
                <ng-container
                  [ngTemplateOutlet]='metricList'
                  [ngTemplateOutletContext]="{
                    title: 'Bus factor',
                    metricKey: 'bus_factor'
                  }"
                >
                </ng-container>
              </div>
              <div
                *ngIf='!teamMembersStatus.state.items.length'
                fxLayoutAlign='center center'
              >
                Отсутствуют данные метрик
              </div>
              <!--            <ng-container-->
              <!--              *ngIf="-->
              <!--                is_survey_create_relation_form_shown;-->
              <!--                then tmpl_survey_relation_create;-->
              <!--                else tmpl_survey_relation_list-->
              <!--              "-->
              <!--            >-->
              <!--            </ng-container>-->
            </div>
          </app-with-processing>
        </mat-tab>
        <mat-tab label='События'>
          <app-with-processing
            [processing]='events.state.isProcessing'
          ></app-with-processing>
          <div fxLayout='row' *ngIf='events.state.isLoaded'>
            <div fxFlex>
              <div *ngIf='events.state.items.length === 0'>
                <div class='tab-events-empty'>
                  Для пользователя почему-то нет событий
                </div>
              </div>

              <div *ngIf='events.state.items.length > 0'>
                <div *ngFor='let event of events.state.items'>
                  <div class='tab-events-container'>
                    <div fxLayout='row'>
                      <div fxFlex='10rem' fxLayoutAlign='start center'>
                        <div class='tab-events-item-date'>
                          {{
                          event.date_created
                            | amDateFormat: 'YYYY-MM-DD HH:mm'
                          }}
                        </div>
                      </div>
                      <div fxFlex fxLayoutAlign='start center'>
                        <div class='tab-events-item-text'>
                          {{ event.text }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_member_add>
  <!-- <div class="app-admin-teams-dashboard-team-details-member-add-title">
        Добавить участника
    </div> -->

  <div>
    <ng-container
      *ngIf='
        member_search_selected_user;
        then tmpl_member_add_user_selected;
        else tmpl_member_add_search
      '
    >
    </ng-container>
  </div>

  <button
    mat-stroked-button
    (click)='on_button_member_add_submitted()'
    [disabled]='!is_button_membership_add_enabled'
  >
    <mat-icon>person_add</mat-icon>
    Добавить
  </button>

  &nbsp;

  <button mat-stroked-button (click)='on_close_member_add_form()'>
    <mat-icon>close</mat-icon>
    Отмена
  </button>
</ng-template>

<ng-template #tmpl_member_add_user_selected>
  <div class='app-admin-teams-dashboard-team-details-member-listitem'>
    <div fxLayout='row'>
      <div fxFlex='5rem'>
        <div class='app-admin-teams-dashboard-team-details-member-avatar'>
          {{ util_member_get_initials_for_avatar(member_search_selected_user) }}
        </div>
      </div>
      <div fxFlex='30rem'>
        <div class='app-admin-teams-dashboard-team-details-member-name'>
          {{ member_search_selected_user.first_name }}
          {{ member_search_selected_user.last_name }}
        </div>
        <div class='app-admin-teams-dashboard-team-details-member-email'>
          {{ member_search_selected_user.email }}
        </div>
      </div>
      <div fxFlex>
        <mat-form-field>
          <mat-label>Выберите роль</mat-label>
          <mat-select required [(value)]='member_search_selected_role'>
            <mat-option value='member'>Участник</mat-option>
            <mat-option value='lead'>Лид</mat-option>
            <mat-option value='owner'>Руководитель</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_member_add_search>
  <app-search-select-user
    (userSelected)='on_member_search_selected($event)'
  ></app-search-select-user>
</ng-template>

<ng-template #tmpl_member_list>
  <div
    class='app-admin-teams-dashboard-team-details-member-add-container'
    (click)='on_show_member_add_form()'
  >
    <div fxLayout='row'>
      <div fxFlex='5rem'>
        <div class='app-admin-teams-dashboard-team-details-member-add-avatar'>
          +
        </div>
      </div>
      <div fxFlex='30rem'>
        <div class='app-admin-teams-dashboard-team-details-member-add-text'>
          Добавить участника
        </div>
      </div>
    </div>
  </div>
  <div class='app-admin-teams-dashboard-team-details-member-list-container'>
    <div *ngFor='let membership of memberships'>
      <div class='app-admin-teams-dashboard-team-details-member-listitem'>
        <div fxLayout='row'>
          <div fxFlex='5rem'>
            <div class='app-admin-teams-dashboard-team-details-member-avatar'>
              {{ util_member_get_initials_for_avatar(membership.user) }}
            </div>
          </div>
          <div fxFlex='25rem'>
            <div class='app-admin-teams-dashboard-team-details-member-name'>
              <a routerLink='/account/user-profile/{{ membership.user.uuid }}'>
                {{ membership.user.first_name }} {{ membership.user.last_name }}
              </a>
            </div>
            <div class='app-admin-teams-dashboard-team-details-member-email'>
              {{ membership.user.email }}
            </div>
          </div>
          <div fxFlex *ngIf='!is_membership_role_change_form_shown(membership)'>
            <div class='app-admin-teams-dashboard-team-details-member-role'>
              Роль: {{ util_member_get_role_display_name(membership.role) }}
              <button
                mat-icon-button
                (click)='on_show_change_role_form(membership)'
              >
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </div>

          <div fxFlex *ngIf='is_membership_role_change_form_shown(membership)'>
            <mat-form-field>
              <mat-label>Выберите роль</mat-label>
              <mat-select [(value)]='membership_role_change_selected_role'>
                <mat-option *ngIf="membership.role !== 'member'" value='member'
                >Участник
                </mat-option
                >
                <mat-option *ngIf="membership.role !== 'lead'" value='lead'
                >Лид
                </mat-option
                >
                <mat-option *ngIf="membership.role !== 'owner'" value='owner'
                >Руководитель
                </mat-option
                >
              </mat-select>
            </mat-form-field>
            &nbsp;
            <button
              mat-stroked-button
              (click)='on_change_role_submit(membership)'
              [disabled]='is_membership_role_change_button_disabled'
            >
              <mat-icon>save</mat-icon>
            </button>
            &nbsp;
            <button mat-stroked-button (click)='on_change_role_cancel()'>
              <mat-icon>cancel</mat-icon>
            </button>
          </div>

          <div
            fxFlex='10rem'
            *ngIf='!is_membership_role_change_form_shown(membership)'
          >
            <div class='app-admin-teams-dashboard-team-details-member-joined'>
              Присоединился
              <br />
              {{ membership.date_created | amDateFormat: 'YYYY-MM-DD HH:mm' }}
            </div>
          </div>
          <div fxFlex='2rem'>
            <button
              mat-icon-button
              aria-label='Delete user from team'
              (click)='on_button_member_delete_submitted(membership)'
              [disabled]='!is_button_membership_delete_enabled'
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_survey_relation_list>
  <div
    class='app-admin-teams-dashboard-team-details-surveys-relation-create-show'
    (click)='on_show_create_survey_relation_form()'
  >
    <div fxLayout='row'>
      <div fxFlex='5rem'>
        <div class='app-admin-teams-dashboard-team-details-member-add-avatar'>
          +
        </div>
      </div>
      <div fxFlex='30rem'>
        <div class='app-admin-teams-dashboard-team-details-member-add-text'>
          Связать с опросом
        </div>
      </div>
    </div>
  </div>

  <div *ngIf='!survey_relations.length'>
    <div class='app-admin-teams-dashboard-team-details-surveys-empty'>
      Нет связанных опросов
    </div>
  </div>

  <div *ngIf='survey_relations.length'>
    <div class='app-admin-teams-dashboard-team-details-surveys-list'>
      <div *ngFor='let relation of survey_relations'>
        <div class='app-admin-teams-dashboard-team-details-surveys-listitem'>
          <div fxLayout='row'>
            <div fxFlex>
              <a routerLink='/admin/surveys/survey/{{ relation.survey.uuid }}'>
                {{ relation.survey.name }}
              </a>
            </div>
            <div fxFlex='3rem'>
              <button
                mat-icon-button
                (click)='on_button_delete_survey_relation(relation)'
              >
                <mat-icon color='warn'>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_survey_relation_create>
  <div class='app-admin-teams-dashboard-team-details-surveys-relation-create'>
    <mat-form-field [style.width.rem]='30'>
      <mat-label>Выберите опрос для специальности</mat-label>
      <mat-select [(ngModel)]='selected_relation_selected_survey'>
        <mat-option *ngFor='let survey of surveys' [value]='survey'>{{
          survey.name
          }}</mat-option>
      </mat-select>
    </mat-form-field>

    <br />

    <button
      mat-stroked-button
      (click)='on_button_create_survey_relation()'
      [disabled]='
        !selected_relation_selected_survey ||
        is_survey_create_relation_button_disabled
      '
    >
      <mat-icon>add_link</mat-icon>
      Связать
    </button>
  </div>
</ng-template>

<ng-template #tmpl_header>
  <div class='app-admin-teams-dashboard-team-details-header-container'>
    <div fxLayout='row' fxLayoutAlign='space-between'>
      <div fxFlex='30rem' style='padding-right: 30px'>
        <div class='app-admin-teams-dashboard-team-details-header-name'>
          <span>{{ team.name }}</span>
        </div>
        <div
          class='app-admin-teams-dashboard-team-details-header-description'
          [attr.data-test-id]='testDataAttributes.createdTeamDescriptionId'
        >
          {{ team.description }}
        </div>
      </div>
      <div fxFlex='25rem'>
        <div>
          <a
            mat-stroked-button
            routerLink='/account/user-teams/{{ team.uuid }}'
          >
            <mat-icon>badge</mat-icon>
            Команда
          </a>
          &nbsp;
          <button
            mat-stroked-button
            [disabled]='!is_button_toggle_hidden_enabled'
            (click)='on_button_toggle_team_hidden()'
          >
            <mat-icon>
              {{ team.is_hidden ? 'visibility_off' : 'visibility' }}
            </mat-icon>
          </button>
          &nbsp;
          <a mat-stroked-button (click)='on_button_toggle_edit_mode()'>
            <mat-icon>edit</mat-icon>
            Редактировать
          </a>
        </div>
        <div>
          <div class='app-admin-teams-dashboard-team-details-header-metadata'>
            Количество участников: {{ this.memberships.length }}, создана:
            {{ team.date_created | amDateFormat: 'YYYY-MM-DD HH:mm' }}
            <p>
              {{ getVerifiedText(team) }}
              {{ team.date_verified | amDateFormat: 'YYYY-MM-DD HH:mm' }}
            </p>

            <div
              class='app-admin-teams-dashboard-team-details-header-parent-container'
            >
              <div *ngIf='!team.parent'>Команда верхнего уровня иерархии</div>
              <div *ngIf='team.parent'>
                <span
                  class='app-admin-teams-dashboard-team-details-header-parent-prefix'
                >
                  Родительская команда:
                </span>
                <a
                  routerLink='/admin/teams/{{ getTeamParent(team).uuid }}'
                  class='app-admin-teams-dashboard-team-details-header-parent-link'
                >
                  {{ getTeamParent(team).name }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_header_in_edit_mode>
  <div class='app-admin-teams-dashboard-team-details-header-container'>
    <div fxLayout='row'>
      <div fxFlex>
        <form [formGroup]='team_edit_form' fxLayout='column' fxLayoutGap='1rem'>
          <mat-form-field>
            <mat-label>Название</mat-label>
            <input
              #input_field_name
              matInput
              placeholder='Название*'
              autocomplete='off'
              [value]='team.name'
              formControlName='team_name'
            />
            <mat-hint>{{ 128 - input_field_name.value.length }}/128</mat-hint>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Описание</mat-label>
            <textarea
              #input_field_description
              matInput
              placeholder='Описание*'
              formControlName='team_description'
            >{{ team.description }}</textarea
            >
            <mat-hint
            >{{ 1024 - input_field_description.value.length }}/1024
            </mat-hint
            >
          </mat-form-field>

          <div fxLayout='row' fxLayoutGap='20px'>
            <mat-form-field [style.width.rem]='30'>
              <mat-label>Родительская команда</mat-label>
              <mat-select formControlName='team_parent' [value]='team.parent'>
                <mat-option
                  *ngIf='team.parent'
                  [value]='team.parent'
                  disabled='disabled'
                >
                  {{ getTeamParent(team).name }}
                </mat-option>
                <mat-option *ngIf='team.parent' [value]='null'>
                  Переместить на верхний уровень
                </mat-option>
                <mat-option *ngFor='let parent of teams' [value]='parent'>
                  {{ parent.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field [style.width.rem]='20'>
              <mat-label>Иконка команды</mat-label>
              <mat-select
                formControlName='team_icon'
                [value]='getTeamIconOption(team.icon_url)'
              >
                <div class='icon-option'>
                  <mat-option
                    *ngFor='let teamIcon of teamIconsOptions'
                    [value]='teamIcon'
                    fxLayout='row'
                    fxLayoutAlign='start center'
                  >
                    <div fxFlex='4rem'><img [src]='teamIcon.url' alt='' /></div>
                    <div fxFlex>{{ teamIcon.name }}</div>
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>
            <mat-form-field [style.width.rem]='20'>
              <mat-label>Тип команды</mat-label>
              <mat-select
                formControlName='team_type'
                [value]='getTeamIconOption(team.icon_url)'
              >
                <div class='icon-option'>
                  <mat-option
                    *ngFor='let teamType of teamTypes'
                    [value]='teamType'
                    fxLayout='row'
                    fxLayoutAlign='start center'
                  >
                    <div fxFlex>{{ teamType }}</div>
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
    <div fxLayoutAlign='end center' fxLayoutGap='8px'>
      <a mat-raised-button (click)='reset_edit_mode()'> Отмена </a>
      <a
        mat-raised-button
        [disabled]='team_edit_form.invalid'
        (click)='save_edit_form()'
      >
        Сохранить
      </a>
    </div>
  </div>
</ng-template>

<ng-template #metricList let-title='title' let-metricKey='metricKey'>
  <div class='users-status-container'>
    <span class='status-title'>{{ title }}</span>
    <div fxLayoutGap='8px'>
      <div *ngFor='let memberStatus of teamMembersStatus.state.items'>
        <div
          *ngIf='memberStatus[metricKey]'
          class='status-container'
          fxLayout='row'
        >
          <div fxLayout='column' fxFlex>
            <span>
              {{ memberStatus.member.last_name }}
              {{ memberStatus.member.first_name }}
            </span>
            <span class='reason-text'>{{
              memberStatus[metricKey + '_reason']
              }}</span>
          </div>
          <div fxLayoutAlign='end center'>
            <div
              fxFlex='3rem'
              *ngIf='memberStatus.is_verified_by_owner'
              [matTooltip]="'Подтверждено руководителем'"
            >
              <mat-icon>check</mat-icon>
            </div>
            <div
              fxFlex='3rem'
              *ngIf='!memberStatus.is_verified_by_owner'
              [matTooltip]="'Ожидает подтверждения руководителем'"
            >
              <mat-icon>hourglass_empty</mat-icon>
            </div>
            <div
              fxFlex='3rem'
              *ngIf='memberStatus.is_updated_manually'
              [matTooltip]="'Вручную'"
            >
              <mat-icon>person</mat-icon>
            </div>
            <div
              fxFlex='3rem'
              *ngIf='!memberStatus.is_updated_manually'
              [matTooltip]="'Автоматически'"
            >
              <mat-icon>android</mat-icon>
            </div>
            <div fxFlex='3rem'>
              <button
                mat-icon-button
                (click)='deleteMemberStatus(memberStatus.uuid)'
                class='status-delete'
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
