import { FlatTreeControl } from '@angular/cdk/tree';

interface TreeRelations {
  // ключ - uuid родителя
  // значение - массив uuid потомков
  [uuid: string]: string[];
}

interface TreeUuidMap<Model> {
  // ключ - uuid элемента
  // значение - TreeNode элемента
  [uuid: string]: TreeNode<Model>;
}

interface BaseViewSetModel {
  uuid?: string;
}

export class TreeNode<Model> {
  item?: Model;
  children?: TreeNode<Model>[] = [];

  constructor(item: Model = null) {
    this.item = item;
  }
}

export interface AngularDefaultTreeNode {
  expandable: boolean;
  name: string;
  level: number;
}

export default class Tree<Model extends BaseViewSetModel> {
  private _treeRelations: TreeRelations = { root: [] };
  private _treeUuidMap: TreeUuidMap<Model> = {};
  private readonly _parentField: string;
  private readonly _node: TreeNode<Model> = null;

  constructor(items: Model[], parentField = 'parent') {
    // Поле, по которому можно найти родительский элемент у модели
    this._parentField = parentField;

    // Базовой узел дерева
    this._node = new TreeNode();

    this._treeUuidMap['root'] = this._node;
    this._build(items);
  }

  public getRoot(): TreeNode<Model> {
    return this._node;
  }

  public getNode(uuid: string): TreeNode<Model> {
    return this._treeUuidMap[uuid];
  }

  public removeNode(uuid: string): void {
    const node = this.getNode(uuid);

    const parentUuid = node.item[this._parentField] || 'root';
    this._treeRelations[parentUuid] = this._treeRelations[parentUuid].filter(
      (relationUuid) => {
        return relationUuid !== uuid;
      }
    );

    this._treeUuidMap[parentUuid].children = this._treeUuidMap[
      parentUuid
    ].children.filter((child) => {
      return child.item.uuid !== uuid;
    });

    this._treeUuidMap = Object.keys(this._treeUuidMap).reduce(
      (treeUuidMap, mapUuid) => {
        return mapUuid === uuid
          ? treeUuidMap
          : { ...treeUuidMap, [mapUuid]: this._treeUuidMap[mapUuid] };
      },
      {}
    );
  }

  public getParents(
    node: TreeNode<Model>,
    parents: TreeNode<Model>[] = []
  ): TreeNode<Model>[] {
    const hasParent = node?.item && node.item[this._parentField];

    if (hasParent) {
      const parent = this._treeUuidMap[node.item[this._parentField]];
      return this.getParents(parent, [parent, ...parents]);
    }

    return parents;
  }

  public insertNode(parentUuid: string, model: Model): TreeNode<Model> {
    const parentNode = this.getNode(parentUuid);

    const childNode = new TreeNode<Model>(model);
    this._treeUuidMap[childNode.item.uuid] = childNode;

    this._treeRelations[parentUuid] = [
      ...(this._treeRelations[parentUuid] || []),
      model.uuid,
    ];

    parentNode.children.push(childNode);
    return childNode;
  }

  private _build(items: Model[]): void {
    items.forEach((item) => {
      this._buildTreeRelation(item);
      this._buildTreeUuidMap(item);
    });

    this._insertChildren();
  }

  private _insertChildren() {
    // Построение иерархии, прикрепление TreeNode к родителям
    Object.keys(this._treeRelations).forEach((uuid) => {
      this._treeRelations[uuid].forEach((childUuid) => {
        try {
          this._treeUuidMap[uuid].children.push(this._treeUuidMap[childUuid]);
        } catch (e) {
          console.log(
            `exception, something wrong with parent: ${uuid} or child: ${childUuid}`
          );
        }
      });
    });
  }

  private _buildTreeRelation(item: Model) {
    // Карта отношений родитель:uuid -> потомки:uuid в массиве
    // {
    //    '<root:uuid>': [
    //      <Программирование:uuid>,
    //      <Управление:uuid>,
    //    ],
    //    <Программирование:uuid>: [
    //       <Системное программирование:uuid>,
    //       <Функциональное программирование:uuid>,
    //       <ООП:uuid>,
    //    ],
    //    <Управление:uuid>: [
    //      <Управление людьми:uuid>,
    //      <Управление проектами:uuid>,
    //    ],
    //    <Управление людьми:uuid>: [
    //      <Управление большими командами:uuid>,
    //      <Управление малыми командами:uuid>,
    //    ],
    // }
    const parentUuid = item[this._parentField] || 'root';
    this._treeRelations[parentUuid] = [
      ...(this._treeRelations[parentUuid] || []),
      item.uuid,
    ];
  }

  private _buildTreeUuidMap(item: Model) {
    // Карта uuid и элементов дерева
    // {
    //    <Программирование:uuid>: <Программирование:TreeNode>,
    //    <Программирование:uuid>: <Программирование:TreeNode>,
    //    <Системное программирование:uuid>: <Системное программирование:TreeNode>,
    //    <Функционральное программирование:uuid>: <Функционральное программирование:TreeNode>,
    //    <ООП:uuid>: <ООП:TreeNode>,
    //    <Управление:uuid>: <Управление:TreeNode>,
    //    <Управление людьми:uuid>: <Управление людьми:TreeNode>,
    //    <Управление большими командами:uuid>: <Управление большими командами:TreeNode>,
    //    <Управление малыми командами:uuid>: <Управление малыми командами:TreeNode>,
    // }
    this._treeUuidMap[item.uuid] = new TreeNode(item);
  }
}

export class AngularTreeMethods {
  static hasChild = (_: number, node: AngularDefaultTreeNode): boolean => {
    return node.expandable;
  };

  static treeControl = new FlatTreeControl<AngularDefaultTreeNode>(
    (node) => node.level,
    (node) => node.expandable
  );
}
