<ng-container *ngIf="is_data_loaded; then component_content; else preloader">
</ng-container>

<ng-template #preloader>
  <app-preloader-progressbar></app-preloader-progressbar>
</ng-template>

<ng-template #component_content>
  <div class="app-admin-speciality-details-tab-profile-container">
    <ng-container *ngIf="is_claims_empty; then tmpl_empty_profile; else tmpl_profile">
    </ng-container>
  </div>
</ng-template>

<ng-template #tmpl_empty_profile>
  <div fxLayout="row">
    <div fxFlex>
      <ng-container [ngTemplateOutlet]="tmpl_button_claim_create">
      </ng-container>
    </div>
    <div fxFlex="20rem" fxFlexOffset="2rem">
      <div class="app-admin-speciality-details-tab-profile-empty-hint-container">
        <div class="app-admin-speciality-details-tab-profile-empty-hint-icon">
          <mat-icon>help</mat-icon>
        </div>
        <div class="app-admin-speciality-details-tab-profile-empty-hint-text">
          Профиль специальности позволяет описать навыки, требуемые для данной специальности.
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_button_claim_create>
  <div class="app-admin-speciality-details-tab-profile-claim-create-container" (click)="on_dialog_claim_create()">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="2rem">
        <mat-icon>add</mat-icon>
      </div>
      <div fxFlex="15rem" fxFlexOffset="2rem">
        Добавить компетенцию в профиль
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_profile>
  <div fxLayout="row">
    <div fxFlex>
      <ng-container [ngTemplateOutlet]="tmpl_button_claim_create">
      </ng-container>
    </div>
    <div fxFlex="15rem">
      <div class="app-admin-speciality-details-tab-profile-controls-container">
        <button mat-stroked-button (click)="on_dialog_visual_show()">
          Показать радарчик
        </button>
      </div>
    </div>
  </div>
  <div fxLayout="row">
    <div fxFlex>
      <div>
        <div *ngFor="let domain_claim_treenode of hierarchy_root_treenodes">
          <ng-container
            [ngTemplateOutlet]="tmpl_domain_claim_treenode"
            [ngTemplateOutletContext]="{treenode: domain_claim_treenode}">
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #tmpl_domain_claim_treenode let-treenode="treenode">
  <div class="app-admin-speciality-profile-domain-claim-treenode-container">
    <div fxLayout="row">
      <div fxFlex fxFlexOffset="1rem">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex>
            <div class="app-admin-speciality-profile-domain-claim-treenode-domain">
              {{ treenode.domain_claim.domain.name }}
            </div>
          </div>
          <div fxFlex="2rem" fxFlexOffset="1rem">
            <div class="app-admin-speciality-profile-domain-claim-treenode-domain-delete"
                 (click)="on_domain_claim_delete(treenode, treenode.domain_claim)">
              X
            </div>
          </div>
        </div>

        <div fxLayout="row">
          <div fxFlex>
            <div class="app-admin-speciality-profile-domain-claim-treenode-claims-container">
              <div *ngFor="let thing of treenode.things">
                <div class="app-admin-speciality-profile-domain-claim-treenode-claims-listitem">
                  <div fxLayout="row">
                    <div fxFlex>
                      <div class="app-admin-speciality-profile-domain-claim-treenode-claims-thing">
                        {{ thing.name }}
                      </div>
                    </div>
                    <div fxFlex="20rem">
                      <div *ngFor="let competence_claim of get_treenode_claims_for_thing(treenode, thing)">
                        <div class="app-admin-speciality-profile-domain-claim-treenode-claims-expectations">
                          <div fxLayout="row">
                            <div fxFlex class="app-admin-speciality-profile-domain-treenode-claims-grade-name">
                              {{ competence_claim.grade.name }}
                            </div>
                            <div fxFlex="8rem">
                              <mat-select
                                [(value)]="competence_claim.thing_level.uuid"
                                (selectionChange)="on_update_speciality_grade($event, competence_claim)">
                                <mat-option
                                  *ngFor="let option of thing.levels"
                                  [value]="option.uuid"
                                >{{ option.title }}</mat-option>
                              </mat-select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="app-admin-speciality-profile-domain-claim-treenode-claims-thing-remove"
                         fxFlex="1.5rem" (click)="on_speciality_thing_delete(thing, treenode)">
                      X
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div fxLayout="row">
          <div fxFlex>
            <div *ngFor="let domain_claim_treenode of treenode.children">
              <ng-container
                [ngTemplateOutlet]="tmpl_domain_claim_treenode"
                [ngTemplateOutletContext]="{treenode: domain_claim_treenode}">
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
