import { Component, OnInit } from '@angular/core';
import { AsyncDetail } from '@rest/AsyncDetail';
import { Thing } from '@models/ontology/thing';
import { AsyncList } from '@rest/AsyncList';
import { Competence } from '@models/competencies/competence';
import { ThingLevel } from '@models/ontology/thing-level';
import { CompetenceDesire } from '@models/competencies/competence-desire';
import { ActivatedRoute, Router } from '@angular/router';
import { ThingHttpService } from '@services/http/ThingHttpService';
import { DomainHttpService } from '@services/http/DomainHttpService';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CompetenceHttpService } from '@services/http/CompetenceHttpService';
import { CompetenceDesireHttpService } from '@services/http/CompetenceDesireHttpService';
import { ThingLevelHttpService } from '@services/http/ThingLevelHttpService';
import { TitleService } from 'src/app/services/title.service';
import { get_full_parent_path } from '../../../../utils/getFullParentUrl';
import { combineLatest } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '@models/user/user';

@Component({
  selector: 'app-thing-detail',
  templateUrl: './thing-detail.component.html',
  styleUrls: ['./thing-detail.component.scss'],
})
export class ThingDetailComponent implements OnInit {
  form: UntypedFormGroup;

  thing: AsyncDetail<Thing>;

  competencies: AsyncList<Competence> = new AsyncList<Competence>(
    this._competenceHttpService
  );
  thingLevels: AsyncList<ThingLevel> = new AsyncList<ThingLevel>(
    this._thingLevelHttpService
  );
  competenciesDesire: AsyncList<CompetenceDesire> =
    new AsyncList<CompetenceDesire>(this._competenceDesireHttpService);

  filteredByUserCompetencies: Competence[];

  lowestLevelCount: number;
  thingLevelsInfo: {
    count: number;
    text: string;
    color: string;
  }[] = [];

  histogramData = [];
  histogramReady = false;
  isHistogramEmpty = true;

  activeTab: number;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _thingHttpService: ThingHttpService,
    private _domainHttpService: DomainHttpService,
    private _authService: AuthService,
    private _competenceHttpService: CompetenceHttpService,
    private _competenceDesireHttpService: CompetenceDesireHttpService,
    private _thingLevelHttpService: ThingLevelHttpService,
    private _titleService: TitleService
  ) {
    this.thing = new AsyncDetail<Thing>(
      this._activatedRoute.snapshot.paramMap.get('uuid'),
      this._thingHttpService
    );
    this.thing.requestParams = {
      params: {
        expand: 'domain',
      },
    };
  }

  ngOnInit(): void {
    this._load();
    this._titleService.set_title('Навык');

    this.form = this._formBuilder.group({
      user: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
    });
  }

  toggleDesire(): void {
    if (this.competenciesDesire.state.items.length) {
      this._removeDesire();
    } else {
      this._addDesire();
    }
  }

  get isUserAdmin(): boolean {
    return this._authService.is_admin();
  }

  private _removeDesire(): void {
    this.competenciesDesire.remove(this.competenciesDesire.state.items[0].uuid);
  }

  private _addDesire(): void {
    const competenceDesire = new CompetenceDesire();
    competenceDesire.user = this._authService.get_current_user_uuid();
    competenceDesire.thing = this._activatedRoute.snapshot.paramMap.get('uuid');
    this.competenciesDesire.create(competenceDesire);
  }

  isProcessing(): boolean {
    return !(
      this.thing.state.isLoaded &&
      this.thingLevels.state.isLoaded &&
      this.competencies.state.isLoaded &&
      this.competenciesDesire.state.isLoaded
    );
  }

  private _load() {
    this.thing.load().subscribe(() => {
      this._titleService.set_title(this.thing.state.item.name);
    });

    this.thingLevels.requestParams = {
      params: {
        thing: this._activatedRoute.snapshot.paramMap.get('uuid'),
      },
    };
    this.thingLevels.load();
    this.competencies.requestParams = {
      params: {
        thing: this._activatedRoute.snapshot.paramMap.get('uuid'),
        expand: 'user.team_memberships.team,verified_by',
      },
    };
    this.competenciesDesire.requestParams = {
      params: {
        thing: this._activatedRoute.snapshot.paramMap.get('uuid'),
        user: this._authService.get_current_user_uuid(),
      },
    };

    combineLatest([
      this.thingLevels.load(),
      this.competencies.load(),
    ]).subscribe(() => {
      this.filteredByUserCompetencies = this.competencies.state.items;
      this._setThingInfo();
    });
    this.competenciesDesire.load();
  }

  private _setThingInfo(): void {
    const colors = { 1: '#82C4FF', 2: '#9B82FF', 3: '#F8ACA8', 4: '#B0D9AA' };

    this.thingLevels.state.items.slice(0, 5).forEach((level) => {
      if (level.order_number === 0) {
        this.lowestLevelCount = this.getFilteredCompetencies(level).length;
      } else {
        const levelCount = this.getFilteredCompetencies(level).length;
        if (levelCount > 0) {
          this.isHistogramEmpty = false;
        }
        this.thingLevelsInfo.push({
          count: levelCount,
          text: level.title,
          color: colors[level.order_number],
        });
        this.histogramData.push({
          x: level.order_number,
          y: levelCount,
          color: colors[level.order_number],
        });
      }
    });
    this.histogramReady = true;
  }

  getFilteredCompetencies(level: ThingLevel): Competence[] {
    return this.filteredByUserCompetencies?.filter(
      (competence) => competence.thing_level === level.uuid
    );
  }

  getLevelTitle(competence: Competence): string {
    return this.thingLevels.state.items.find(
      (level) => level.uuid === competence.thing_level
    ).title;
  }

  setActiveTabIndex(index: number): void {
    this.form.controls.user.setValue('');
    this.tabDataFilter();
    this.activeTab = index;
  }

  tabDataFilter(): void {
    const userFilter = this.form.controls.user.value.toLowerCase();
    this.filteredByUserCompetencies = this.competencies.state.items.filter(
      (competence) =>
        (competence.user as User).first_name
          .toLowerCase()
          .includes(userFilter) ||
        (competence.user as User).last_name.toLowerCase().includes(userFilter)
    );
  }

  onBackClick(): void {
    history.back();
  }

  navigateToUser(user: User): void {
    this._router.navigate([`account/user-profile/${user.uuid}`]);
  }

  getFullParentPath = get_full_parent_path;
}
