<form [formGroup]="form" (ngSubmit)="onSubmitForm()">
  <h2>Создание конфигурации почтового сервиса</h2>
  <div class="form-field">
    <mat-form-field [style.width.rem]="20">
      <mat-label>Название конфигурации</mat-label>
      <input
        matInput
        formControlName="name"
        placeholder="Корпоративный сервер"
        type="text"
        required
      />
    </mat-form-field>
  </div>

  <div class="form-field">
    <mat-form-field [style.width.rem]="15">
      <mat-label>Хост</mat-label>
      <input
        matInput
        formControlName="host"
        placeholder="smtp.example.com"
        type="text"
        required
      />
    </mat-form-field>

    &nbsp;

    <mat-form-field [style.width.rem]="7">
      <mat-label>Порт</mat-label>
      <input
        type="number"
        matInput
        formControlName="port"
        placeholder="587"
        type="number"
        required
      />
    </mat-form-field>
  </div>

  <div class="form-field">
    <mat-form-field [style.width.rem]="15">
      <mat-label>Почта отправителя</mat-label>
      <input
        matInput
        formControlName="default_from_email"
        placeholder="starsmap@example.com"
        type="text"
        required
      />
    </mat-form-field>

    &nbsp;

    <mat-form-field [style.width.rem]="15">
      <mat-label>Имя отправителя</mat-label>
      <input
        matInput
        formControlName="default_from_name"
        placeholder="From name"
        type="text"
        required
      />
    </mat-form-field>
  </div>

  <div>
    <small>
      Если авторизация на почтовом сервере не используется, оставьте поля
      пустыми
    </small>
  </div>

  <div class="form-field">
    <mat-form-field [style.width.rem]="15">
      <mat-label>Пользователь для авторизации</mat-label>
      <input
        matInput
        formControlName="host_user"
        placeholder="User"
        type="text"
      />
    </mat-form-field>
  </div>

  <div class="form-field">
    <mat-form-field [style.width.rem]="15">
      <mat-label>Пароль для авторизации</mat-label>
      <input
        matInput
        formControlName="host_password"
        placeholder="Pa5$w0rd"
        type="password"
      />
    </mat-form-field>
  </div>

  <div class="form-field">
    <mat-radio-group aria-label="host secure" formControlName="host_secure">
      <div class="secure-item">
        <mat-radio-button value="use_tls">TLS</mat-radio-button>
        &nbsp;
        <mat-radio-button value="use_ssl">SSL</mat-radio-button>
        &nbsp;
        <mat-radio-button value="insecure">Без шифрования</mat-radio-button>
      </div>
    </mat-radio-group>
  </div>

  <div class="form-buttons">
    <button mat-raised-button type="submit" [disabled]="!form.valid">
      Создать
    </button>
  </div>
</form>
