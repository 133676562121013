import {
  GetListRequest,
  GetListResponse,
  ViewSetHttpService,
} from '../services/http/rest/ViewSetHttpService';
import { Observable, Subject } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';

interface BaseViewSetModel {
  uuid: string;
}

export interface AsyncListState<ViewSetModel> {
  items: ViewSetModel[];
  isLoaded: boolean;
  isProcessing: boolean;
}

interface AsyncListPagination {
  page: number;
  pageSize: number;
  length: number;
}

export class AsyncList<ViewSetModel extends BaseViewSetModel> {
  public state: AsyncListState<ViewSetModel> = {
    isProcessing: false,
    isLoaded: false,
    items: [],
  };
  public pagination: AsyncListPagination = {
    pageSize: 1000,
    page: 1,
    length: 0,
  };
  public requestParams: GetListRequest = {};
  public api: ViewSetHttpService<ViewSetModel>;

  constructor(api: ViewSetHttpService<ViewSetModel>) {
    this.api = api;
    this.requestParams = {
      page: this.pagination.page,
    };
  }

  public setRequestParams(requestParams: GetListRequest): void {
    this.requestParams = { ...this.requestParams, ...requestParams };
  }

  public load(): Observable<GetListResponse<ViewSetModel>> {
    const responseSubject$ = new Subject<GetListResponse<ViewSetModel>>();
    this.state.isProcessing = true;

    this.api.list(this.requestParams).subscribe(
      (response: GetListResponse<ViewSetModel>) => {
        this.state = {
          items: response.results,
          isLoaded: true,
          isProcessing: false,
        };

        this.pagination = {
          ...this.pagination,
          length: response.count,
        };
        responseSubject$.next(response);
      },
      (error) => {
        this.state.isProcessing = false;
        // TODO: add logger service
        console.error('AsyncList load failed:');
        console.error(error);
        responseSubject$.next(error);
      }
    );

    return responseSubject$;
  }

  remove(uuid: string): Observable<null> {
    const responseSubject$ = new Subject<null>();
    this.state.isProcessing = true;
    this.api.delete(uuid).subscribe(
      () => {
        responseSubject$.next();
        this.state.items = this.state.items.filter((detail: ViewSetModel) => {
          return detail.uuid !== uuid;
        });
        this.state.isProcessing = false;
        this.pagination.length = this.pagination.length - 1;
      },
      (error) => {
        responseSubject$.next(error);
        this.state.isProcessing = false;
      }
    );

    return responseSubject$;
  }

  create(data: Partial<ViewSetModel>): Observable<ViewSetModel> {
    const responseSubject$ = new Subject<ViewSetModel>();
    this.state.isProcessing = true;

    this.api.create(data, this.requestParams).subscribe(
      (response) => {
        responseSubject$.next(response);
        this.state.items.push(response);
        this.state.isProcessing = false;
        this.pagination.length = this.pagination.length + 1;
      },
      (error) => {
        responseSubject$.error(error);
        this.state.isProcessing = false;
      }
    );

    return responseSubject$;
  }

  update(uuid: string, data: Partial<ViewSetModel>): Observable<ViewSetModel> {
    const responseSubject$ = new Subject<ViewSetModel>();

    this.state.isProcessing = true;
    this.api.update(uuid, data, this.requestParams).subscribe(
      (response) => {
        responseSubject$.next(response);
        const replacePosition = this.state.items.findIndex(
          (item) => item.uuid === response.uuid
        );
        this.state.items.splice(replacePosition, 1, response);
        this.state.isProcessing = false;
      },
      (error) => {
        responseSubject$.next(error);
        this.state.isProcessing = false;
      }
    );

    return responseSubject$;
  }

  onChangePage($event: PageEvent): PageEvent {
    this.pagination.page = $event.pageIndex + 1;
    this.setRequestParams({
      params: {
        ...this.requestParams.params,
        page: this.pagination.page,
      },
    });
    this.load();
    return $event;
  }
}
