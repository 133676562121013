import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { AuthService } from 'src/app/services/auth/auth.service';
import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { UserService } from 'src/app/services/user/user.service';
import { TeamMembershipService } from 'src/app/services/teams/team-membership.service';
import { PdpService } from 'src/app/services/track/pdp.service';

import { User } from 'src/app/models/user/user';
import { DevelopmentPlan } from 'src/app/models/tracks/development-plan';

@Component({
  selector: 'app-track-pdp-create',
  templateUrl: './track-pdp-create.component.html',
  styleUrls: ['./track-pdp-create.component.css'],
})
export class TrackPdpCreateComponent implements OnInit {
  @Input() subject_user: User;
  @Input() responsible_user: User;

  public pdp: DevelopmentPlan;

  public superiors: User[] = [];

  public responsible_user_search_form_control = new UntypedFormControl('', []);
  private responsible_user_search_terms = new Subject<string>();
  private responsible_user_search$: Observable<{ results: User[] } | null>;
  public responsible_user_search_results: User[] = [];
  public responsible_user_search_selected_user: User;

  public pdp_create_form: UntypedFormGroup;

  private uistate = {
    superiors_loaded: false,

    is_form_pdp_create_loading: false,
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private date_adapter: DateAdapter<Date>,
    private form_builder: UntypedFormBuilder,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private auth_service: AuthService,
    private user_service: UserService,
    private membership_service: TeamMembershipService,
    private pdp_service: PdpService
  ) {
    this.date_adapter.setLocale('ru-RU'); // dd/MM/yyyy
    this.define_pdp_create_form();
  }

  ngOnInit(): void {
    this.responsible_user_search$ = this.responsible_user_search_terms.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap((term: string) => this.user_service.search(term))
    );
    this.responsible_user_search$.subscribe(
      (response) => (this.responsible_user_search_results = response.results)
    );

    if (this.subject_user) {
      this.pdp_create_form.controls.subject_user.setValue(
        this.subject_user.uuid
      );
    }
    if (this.responsible_user) {
      this.pdp_create_form.controls.responsible_user.setValue(
        this.responsible_user.uuid
      );
      this.responsible_user_search_selected_user = this.responsible_user;
    }
    this.load_superiors();
  }

  public get is_subject_is_self(): boolean {
    return this.subject_user.uuid === this.auth_service.get_current_user_uuid();
  }

  public get is_form_pdp_create_loading(): boolean {
    return this.uistate.is_form_pdp_create_loading;
  }

  private define_pdp_create_form() {
    this.pdp_create_form = this.form_builder.group({
      objective: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      accomplishment_indicator: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
      personal_value: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
      organisation_value: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
      due_date: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(128),
        ],
      ],
      subject_user: [
        '',
        [
          Validators.required,
          Validators.minLength(32),
          Validators.maxLength(36),
        ],
      ],
      responsible_user: [
        '',
        [
          Validators.required,
          Validators.minLength(32),
          Validators.maxLength(36),
        ],
      ],
    });
  }

  public get pdp_create_form_input_due_date_min(): Date {
    return new Date();
  }

  public get pdp_create_form_input_due_date_max(): Date {
    const today = new Date();
    const max_date = new Date();
    // max: today + quarter + month
    max_date.setTime(
      today.getTime() + 4 * 30 * 24 * 60 * 60 * 1000 + 30 * 24 * 60 * 60 * 1000
    );
    return max_date;
  }

  private load_superiors(): void {
    this.membership_service
      .fetch_superiors_by_user_uuid(this.subject_user.uuid)
      .subscribe(
        (response) => {
          const superiors = response as User[];
          this.superiors = superiors.filter(
            (u) => u.uuid !== this.auth_service.get_current_user_uuid()
          );
          this.uistate.superiors_loaded = true;
          this.logging_service.debug(
            `${this.constructor.name} loaded ${this.superiors.length} superiors`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to load superiors`
          );
          this.alert_service.error(
            `Ошибка загрузки руководителей: ${err.status}`
          );
        }
      );
  }

  public on_set_responsible_user(user: User): void {
    this.logging_service.debug(
      `${this.constructor.name} on_set_responsible_user ${user.first_name} ${user.last_name}`
    );
    this.responsible_user_search_selected_user = user;
    this.pdp_create_form.controls.responsible_user.setValue(user.uuid);
  }

  public on_set_due_date(increment_mills: number): void {
    this.logging_service.debug(
      `${this.constructor.name} on_set_due_date ${increment_mills}`
    );
    const today = new Date();
    const due_date = new Date();
    due_date.setTime(today.getTime() + increment_mills);
    this.pdp_create_form.controls.due_date.setValue(due_date);
  }

  public on_responsible_user_clear(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_responsible_user_clear`
    );
    this.responsible_user_search_selected_user = null;
    this.pdp_create_form.controls.responsible_user.setValue(null);
    this.responsible_user_search_form_control.setValue(null);
  }

  public on_responsible_user_search(term: string): void {
    this.responsible_user_search_terms.next(term);
  }

  public on_responsible_user_search_autocomplete_selected(user: User): void {
    this.logging_service.debug(
      `${this.constructor.name} on_responsible_user_search_autocomplete_selected`
    );
    this.responsible_user_search_selected_user = user;
    this.pdp_create_form.controls.responsible_user.setValue(
      this.responsible_user_search_selected_user.uuid
    );
    this.responsible_user_search_results = [];
  }

  public on_pdp_create_form_submit(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_pdp_create_form_submit`
    );
    this.uistate.is_form_pdp_create_loading = true;
    const pdp_data = {
      subject_user: this.pdp_create_form.controls.subject_user.value,
      responsible_user: this.pdp_create_form.controls.responsible_user.value,
      objective: this.pdp_create_form.controls.objective.value,
      accomplishment_indicator:
        this.pdp_create_form.controls.accomplishment_indicator.value,
      personal_value: this.pdp_create_form.controls.personal_value.value,
      organisation_value:
        this.pdp_create_form.controls.organisation_value.value,
      due_date: this.pdp_create_form.controls.due_date.value,
    };
    this.pdp_service.create(pdp_data).subscribe(
      (response) => {
        this.pdp = response as DevelopmentPlan;
        this.logging_service.debug(`${this.constructor.name} created pdp`);
        this.uistate.is_form_pdp_create_loading = false;
        if (this.is_subject_is_self) {
          this.router.navigate(['/dashboard/pdp']);
        } else {
          const team_uuid =
            this.route.parent.snapshot.paramMap.get('team_uuid');
          const user_uuid = this.route.snapshot.paramMap.get('user_uuid');
          // dunno how to force a parent page to reload otherwise
          this.router.navigate([`/teams/${team_uuid}/manage`]);
        }
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to create pdp`
        );
        this.alert_service.error(`Ошибка сохранения: ${err.status}`);
        this.uistate.is_form_pdp_create_loading = false;
      }
    );
  }

  public util_responsible_user_search_display(user: User): string {
    return user ? `${user.first_name} ${user.last_name} – ${user.email}` : '';
  }

  public util_member_get_initials_for_avatar(user: User): string {
    const first_name_initial = user.first_name.charAt(0);
    const last_name_initial = user.last_name.charAt(0);
    return `${first_name_initial}${last_name_initial}`;
  }
}
