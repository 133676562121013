import { Thing } from '../ontology/thing';
import { User } from '../user/user';

export class CompetenceDesire {
  uuid: string;
  thing: Thing | string;
  user: User | string;
  date_created: string;
  date_updated: string;

  users: any; // used in team profile component for grouping of desires
}
