import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatDividerModule } from '@angular/material/divider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CommonUIElementsModule } from '../common-ui-elements/common-ui-elements.module';
import { CommonD3ChartsModule } from '../common-d3-charts/common-d3-charts.module';

import { DomainsUserTabCompetenciesComponent } from './components/domains/user/domains-user-tab-competencies/domains-user-tab-competencies.component';
import { ThingTabLevelsComponent } from './components/things/tabs/thing-tab-levels/thing-tab-levels.component';
import { CreateThingLevelComponent } from './components/things/tabs/thing-tab-levels/create-thing-level/create-thing-level.component';
import { DeleteThingLevelComponent } from './components/things/tabs/thing-tab-levels/delete-thing-level/delete-thing-level.component';
import { ThingLevelFormComponent } from './components/things/tabs/thing-tab-levels/thing-level-form/thing-level-form.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    DomainsUserTabCompetenciesComponent,
    ThingTabLevelsComponent,
    CreateThingLevelComponent,
    DeleteThingLevelComponent,
    ThingLevelFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,

    MatDividerModule,
    MatButtonToggleModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatTooltipModule,

    CommonUIElementsModule,
    CommonD3ChartsModule,

    MatDialogModule,
    RouterModule,
  ],
  exports: [DomainsUserTabCompetenciesComponent, ThingTabLevelsComponent],
})
export class OntologiesModule {}
