import { Component, Inject, OnInit } from '@angular/core';
import { AsyncList } from '@rest/AsyncList';
import { User } from '@models/user/user';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { ProjectHttpService } from '@services/http/ProjectHttpService';
import { UserHttpService } from '@services/http/UserHttpService';
import { ProjectTaskHttpService } from '@services/http/ProjectTaskHttpService';
import { BookingHttpService } from '@services/http/BookingHttpService';
import { Project } from '@models/projects/project';
import { ProjectTask } from '@models/projects/project-task';

@Component({
  selector: 'app-create-edit-booking-dialog',
  templateUrl: './create-edit-booking-dialog.component.html',
  styleUrls: ['./create-edit-booking-dialog.component.css'],
})
export class CreateEditBookingDialogComponent implements OnInit {
  form: UntypedFormGroup;
  project: Project;
  projectUsers: User[] = [];
  combinedUsers: User[] = [];
  searchUsers: AsyncList<User>;

  projects: Project[] = [];
  projectList: AsyncList<Project>;
  tasks: AsyncList<ProjectTask>;

  constructor(
    public dialogRef: MatDialogRef<CreateEditBookingDialogComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _dateAdapter: DateAdapter<Date>,
    private _projectHttpService: ProjectHttpService,
    private _projectTaskHttpService: ProjectTaskHttpService,
    private _bookingHttpService: BookingHttpService,
    private _userHttpService: UserHttpService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      project: Project;
      bookingData: {
        uuid: string;
        project: Project;
        resource: User;
        project_task: ProjectTask | null;
        kind: string;
        utilization: number;
        booked_from: string;
        booked_until: string;
      } | null;
    }
  ) {
    this._dateAdapter.setLocale('ru-RU'); // dd/MM/yyyy
  }

  ngOnInit(): void {
    this.searchUsers = new AsyncList<User>(this._userHttpService);
    this.tasks = new AsyncList<ProjectTask>(this._projectTaskHttpService);

    this.form = this._formBuilder.group({
      project: ['', [Validators.required]],
      resource: ['', [Validators.required]],
      project_task: [''],
      kind: ['', [Validators.required]],
      utilization: ['', [Validators.required]],
      dateFrom: ['', [Validators.required]],
      dateTo: ['', [Validators.required]],
    });

    if (this.data?.project) {
      this.project = this.data.project;
      this.projects.push(this.project);
      this.setCombinedUsers();
      this.form.controls.project.setValue(this.data.project.uuid);
      this.form.controls.dateFrom.setValue(
        new Date(this.data.project.planned_start)
      );
      this.form.controls.dateTo.setValue(
        new Date(this.data.project.planned_finish)
      );
      this.form.controls.utilization.setValue(50);
      this.form.controls.kind.setValue('H');
    } else if (this.data?.bookingData) {
      this.project = this.data.bookingData.project;
      this.projects.push(this.project);
      this.setCombinedUsers();
      this.form.controls.project.setValue(this.data.bookingData.project.uuid);
      this.form.controls.resource.setValue(this.data.bookingData.resource.uuid);
      this.form.controls.project_task.setValue(
        this.data.bookingData.project_task?.uuid
      );
      this.form.controls.kind.setValue(this.data.bookingData.kind);
      this.form.controls.utilization.setValue(
        this.data.bookingData.utilization
      );
      this.form.controls.dateFrom.setValue(
        new Date(this.data.bookingData.booked_from)
      );
      this.form.controls.dateTo.setValue(
        new Date(this.data.bookingData.booked_until)
      );
    } else {
      this.form.controls.utilization.setValue(50);
      this.form.controls.kind.setValue('H');
      this._loadProjects();
    }
    this.loadProjectTasks(this.project.uuid);
  }

  private _loadProjects(): void {
    this.projectList = new AsyncList<Project>(this._projectHttpService);
    this.projectList.setRequestParams({
      params: {
        expand: 'resources',
      },
    });
    this.projectList.load().subscribe(() => {
      this.projects.push(...this.projectList.state.items);
    });
  }

  onProjectSelect(project: Project): void {
    this.form.controls.resource.setValue('');
    this.form.controls.project_task.setValue('');

    this.setCombinedUsers();

    this.loadProjectTasks(project.uuid);

    this.form.controls.dateFrom.setValue(new Date(project.planned_start));
    this.form.controls.dateTo.setValue(new Date(project.planned_finish));
  }

  search(search: string): void {
    this.searchUsers.setRequestParams({
      params: {
        search: search,
      },
    });
    this.searchUsers.load().subscribe(() => {
      this.setCombinedUsers();
    });
  }

  setCombinedUsers(): void {
    if (this.project) {
      this.projectUsers = [];
      this.projectUsers.push(...(this.project.resources as User[]));
    }

    this.combinedUsers = [];
    this.combinedUsers.push(...this.projectUsers);
    this.combinedUsers.push(...this.searchUsers.state.items);
  }

  loadProjectTasks(projectUuid: string): void {
    this.tasks.setRequestParams({
      params: {
        project: projectUuid,
      },
    });
    this.tasks.load();
  }

  dateStringSerializer(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}T19:00:00Z`;
  }

  submit(): void {
    if (this.data?.bookingData) {
      this._bookingHttpService
        .update(this.data.bookingData.uuid, {
          utilization: this.form.controls.utilization.value,
          kind: this.form.controls.kind.value,
          resource: this.form.controls.resource.value,
          project: this.form.controls.project.value,
          project_task: this.form.controls.project_task.value,
          booked_from: this.form.controls.dateFrom.value.toISOString(),
          booked_until: this.form.controls.dateTo.value.toISOString(),
        })
        .subscribe(() => {
          this.dialogRef.close();
        });
    } else {
      this._bookingHttpService
        .create({
          utilization: this.form.controls.utilization.value,
          kind: this.form.controls.kind.value,
          resource: this.form.controls.resource.value,
          project: this.form.controls.project.value,
          project_task: this.form.controls.project_task.value,
          booked_from: this.form.controls.dateFrom.value.toISOString(),
          booked_until: this.form.controls.dateTo.value.toISOString(),
        })
        .subscribe(() => {
          this.dialogRef.close();
        });
    }
  }
}
