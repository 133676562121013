import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { TitleService } from 'src/app/services/title.service';
import { ThingService } from 'src/app/services/ontology/thing.service';
import { DomainService } from 'src/app/services/ontology/domain.service';

import { Thing } from 'src/app/models/ontology/thing';
import { Domain } from 'src/app/models/ontology/domain';

@Component({
  selector: 'app-thing-details',
  templateUrl: './thing-details.component.html',
  styleUrls: ['./thing-details.component.css'],
})
export class ThingDetailsComponent implements OnInit {
  @ViewChild('tooltip_delete_thing') tooltip_delete_thing: ElementRef;

  private thing_uuid: string;
  public thing: Thing<Domain>;

  public domains: Domain[] = [];

  public thing_edit_form: UntypedFormGroup;

  private uistate = {
    thing_loaded: false,
    domains_loaded: false,

    is_header_edit_mode: false,

    is_button_thing_edit_save_disabled: false,
    is_button_thing_tooltip_delete_disabled: false,
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private form_builder: UntypedFormBuilder,
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private title_service: TitleService,
    private thing_service: ThingService,
    private domain_service: DomainService
  ) {
    this.define_thing_edit_form();
  }

  ngOnInit(): void {
    this.title_service.set_title('Компетенция');
    this.thing_uuid = this.route.snapshot.paramMap.get('thing_uuid');
    this.logging_service.debug(`${this.constructor.name} init`);
    this.load_thing();
  }

  public get is_data_loaded(): boolean {
    return this.uistate.thing_loaded && this.uistate.domains_loaded;
  }

  private define_thing_edit_form(): void {
    this.thing_edit_form = this.form_builder.group({
      thing_name: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
      thing_description: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
      thing_domain: ['', []],
    });
  }

  public get in_edit_mode(): boolean {
    return this.uistate.is_header_edit_mode;
  }

  public get is_button_thing_edit_save_disabled(): boolean {
    return this.uistate.is_button_thing_edit_save_disabled;
  }

  public get is_button_thing_tooltip_delete_disabled(): boolean {
    return this.uistate.is_button_thing_tooltip_delete_disabled;
  }

  private load_thing(): void {
    this.thing_service.fetch_by_uuid(this.thing_uuid).subscribe(
      (response) => {
        this.thing = response as Thing<Domain>;
        this.logging_service.debug(
          `${this.constructor.name} loaded thing ${this.thing.name}`
        );
        this.title_service.set_title(this.thing.name);
        this.uistate.thing_loaded = true;
        this.load_domains();
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to load thing`
        );
        this.alert_service.error(`Ошибка загрузки сущности ${err.status}`);
      }
    );
  }

  private load_domains(): void {
    this.domain_service.fetch_all().subscribe(
      (response) => {
        const domains = response.results as Domain[];
        this.domains = domains.filter((d) => d.uuid !== this.thing.domain.uuid);
        this.logging_service.debug(
          `${this.constructor.name} loaded ${this.domains.length} domains`
        );
        this.uistate.domains_loaded = true;
      },
      (err) => {
        this.logging_service.debug(
          `${this.constructor.name} failed to load domains`
        );
        this.alert_service.error(`Ошибка загрузки доменов ${err.status}`);
      }
    );
  }

  public on_button_edit(): void {
    this.uistate.is_header_edit_mode = true;
    this.thing_edit_form.controls.thing_name.setValue(this.thing.name);
    this.thing_edit_form.controls.thing_description.setValue(
      this.thing.description
    );
    this.thing_edit_form.controls.thing_domain.setValue(this.thing.domain);
  }

  public on_button_edit_cancel(): void {
    this.uistate.is_header_edit_mode = false;
  }

  public on_button_save_edit_form(): void {
    this.uistate.is_button_thing_edit_save_disabled = true;
    this.thing_service
      .update(this.thing.uuid, {
        name: this.thing_edit_form.value.thing_name,
        description: this.thing_edit_form.value.thing_description,
        domain: this.thing_edit_form.value.thing_domain.uuid,
      })
      .subscribe(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} updated data for ${this.thing.uuid}`
          );
          this.alert_service.success(`Сохранено`);
          this.thing = response as Thing<Domain>;
          this.uistate.is_button_thing_edit_save_disabled = false;
          this.uistate.is_header_edit_mode = false;
        },
        (err) => {
          this.uistate.is_button_thing_edit_save_disabled = false;
          this.logging_service.error(
            `${this.constructor.name} failed to update data for ${this.thing.uuid}`
          );
          this.alert_service.error(
            `Ошибка сохранения изменений: ${err.status}`
          );
        }
      );
  }

  public on_button_thing_delete_show_tooltip($event): void {
    const tooltip_style_left = `${
      $event.pageX - this.tooltip_delete_thing.nativeElement.offsetWidth + 1
    }px;`;
    const tooltip_style_top = `${$event.pageY - 15}px;`;
    this.tooltip_delete_thing.nativeElement.style.display = 'block';
    this.tooltip_delete_thing.nativeElement.style.opacity = 1.0;
    this.tooltip_delete_thing.nativeElement.setAttribute(
      'style',
      `left: ${tooltip_style_left}`
    );
    this.tooltip_delete_thing.nativeElement.setAttribute(
      'style',
      `top: ${tooltip_style_top}`
    );
  }

  public on_button_thing_delete_hide_tooltip(): void {
    this.tooltip_delete_thing.nativeElement.style.opacity = 0.0;
    this.tooltip_delete_thing.nativeElement.style.display = 'none';
  }

  public on_tooltip_thing_delete_confirm(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_tooltip_delete_thing_confirm`
    );
    this.uistate.is_button_thing_tooltip_delete_disabled = true;
    this.thing_service.delete(this.thing).subscribe(
      (response) => {
        this.uistate.is_button_thing_tooltip_delete_disabled = false;
        this.logging_service.debug(`${this.constructor.name} thing deleted`);
        this.router.navigate([
          `/admin/ontologies/domains/${this.thing.domain.uuid}`,
        ]);
      },
      (err) => {
        this.uistate.is_button_thing_tooltip_delete_disabled = false;
        this.logging_service.error(
          `${this.constructor.name} failed to delete thing ${this.thing.uuid}`
        );
        this.alert_service.error(`Ошибка удаления: ${err.status}`);
      }
    );
  }

  public on_tooltip_thing_delete_cancel(): void {
    this.logging_service.debug(
      `${this.constructor.name} on_tooltip_thing_delete_cancel`
    );
    this.on_button_thing_delete_hide_tooltip();
  }
}
