<app-with-processing
  [processing]='teamSettings.state.isProcessing || team.state.isProcessing'
>
  <div
    class='team-settings'
    fxLayout='column'
    *ngIf='teamSettings.state.isLoaded && team.state.isLoaded'
  >
    <div class='title'>Настройки команды</div>
    <div fxLayout='column' fxLayoutGap='20px' fxFlex>
      <form [formGroup]='form' class='project-create-form'>
        <div fxLayout='column'>
          <mat-label class='textfield-label'>Название команды</mat-label>
          <mat-form-field floatLabel='never' appearance='outline'>
            <input matInput type='text' formControlName='name' />
          </mat-form-field>
        </div>
        <div fxLayout='column'>
          <mat-label class='textfield-label'>Описание команды</mat-label>
          <mat-form-field floatLabel='never' appearance='outline'>
            <input matInput type='text' formControlName='description' />
          </mat-form-field>
        </div>
        <div fxLayout='row' fxLayoutAlign='end center'>
          <div>
            <button mat-raised-button (click)='editTeamInfo()'>Сохранить</button>
          </div>
        </div>
      </form>
      <div fxLayout='row' fxLayoutAlign='start center'>
        <div fxFlex='20'>Вступление в команду:</div>
        <div fxFlex='20' class='join-type-select'>
          <mat-select
            (selectionChange)='onJoinTypeSelect($event.value)'
            [value]='joinType'
          >
            <mat-option *ngFor='let joinType of joinTypes' [value]='joinType.num'>
              {{ joinType.type }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <h4>Bus factor и Key people</h4>
      <div fxLayout='row' fxLayoutAlign='start center'>
        <div fxFlex='20'>Способ расчета:</div>
        <div fxFlex='20' class='join-type-select'>
          <mat-select
            (selectionChange)='onKeyCompetenciesMemberStatus($event.value)'
            [value]='keyCompetenciesMemberStatus'
          >
            <mat-option [value]='true'>
              По ключевым навыкам
            </mat-option>
            <mat-option [value]='false'>
              По всем навыкам
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </div>
</app-with-processing>
