import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AsyncList } from '@rest/AsyncList';
import { Speciality } from '@models/specialities/speciality';
import { User } from '@models/user/user';
import { UserHttpService } from '@services/http/UserHttpService';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SpecialityGradeHttpService } from '@services/http/SpecialityGradeHttpService';
import { SpecialityGrade } from '@models/specialities/speciality-grade';

interface Role {
  label: string;
  value: string;
}

export interface AddTeamMemberFormData<UserModel = User | string> {
  user: UserModel;
  speciality: Speciality;
  grade: SpecialityGrade;
  role: Role;
  type: 'add' | 'invite';
}

@Component({
  selector: 'app-add-team-member-dialog',
  templateUrl: './add-team-member-dialog.component.html',
  styleUrls: ['./add-team-member-dialog.component.css'],
})
export class AddTeamMemberDialogComponent implements OnInit {
  users: AsyncList<User>;
  specialityGrades: AsyncList<SpecialityGrade>;

  form: UntypedFormGroup;
  userControl = new UntypedFormControl('', [Validators.required]);

  roleOptions: Role[] = [
    { label: 'Руководитель', value: 'owner' },
    { label: 'Лид', value: 'lead' },
    { label: 'Участник', value: 'member' },
  ];

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _userHttpService: UserHttpService,
    private _specialityGradeHttpService: SpecialityGradeHttpService,
    public dialogRef: MatDialogRef<AddTeamMemberDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      teamUuid: string;
      teamSpecialities: Speciality[];
      onSubmit(formData: AddTeamMemberFormData): void;
    }
  ) {}

  ngOnInit(): void {
    this.users = new AsyncList<User>(this._userHttpService);

    this.form = this._formBuilder.group({
      user: this.userControl,
      speciality: ['', [Validators.required]],
      grade: ['', [Validators.required]],
      role: ['', [Validators.required]],
    });
  }

  usersFilter(): void {
    this.users.setRequestParams({
      params: {
        exclude_team: this.data.teamUuid,
        search: this.form.controls.user.value,
      },
    });
    this.users.load();
  }

  getSpecialityGrades(speciality: Speciality): void {
    this.specialityGrades = new AsyncList<SpecialityGrade>(
      this._specialityGradeHttpService
    );
    this.specialityGrades.setRequestParams({
      params: {
        speciality: speciality.uuid,
      },
    });

    this.specialityGrades.load().subscribe(() => {
      this.form.controls.grade.setValue(this.specialityGrades.state.items[0]);
    });
  }

  close(): void {
    const existingUser = this.users.state.items.find(
      (user) => user.email === this.form.value.user
    );
    if (existingUser) {
      this.form.value['type'] = 'add';
      this.form.value['user'] = existingUser;
    } else {
      this.form.value['type'] = 'invite';
    }

    this.data.onSubmit(this.form.value);
    this.dialogRef.close();
  }
}
