import { Component, OnInit, OnDestroy } from '@angular/core';
import { Speciality } from '@models/specialities/speciality';
import { AcceptDialogComponent } from '../../../../common-ui-elements/components/accept-dialog/accept-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { TextfieldDialogComponent } from '../../../../common-ui-elements/components/textfield-dialog/textfield-dialog.component';
import { AsyncList } from '@rest/AsyncList';
import { AddLibrarySpecialityDialogComponent } from './add-library-speciality-dialog/add-library-speciality-dialog.component';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TeamHttpService } from '@services/http/TeamHttpService';
import { TitleService } from 'src/app/services/title.service';
import { combineLatest } from 'rxjs';
import { Team } from '@models/teams/team';
import { SpecialityEvent } from '@models/events/speciality-event';
import { SpecialityEventHttpService } from '@services/http/SpecialityEventHttpService';
import { AuthService } from '../../../../../services/auth/auth.service';

@Component({
  selector: 'app-team-specialities',
  templateUrl: './team-specialities.component.html',
  styleUrls: ['./team-specialities.component.css'],
})
export class TeamSpecialitiesComponent implements OnInit, OnDestroy {
  teamUuid: string;
  parentTeams: AsyncList<Team>;
  specialities: AsyncList<Speciality>;
  librarySpecialities: AsyncList<Speciality>;
  parentTeamsSpecialities: AsyncList<Speciality>;
  specialitiesEvents: AsyncList<SpecialityEvent>;

  parentSpecialitiesReady = false;

  navigationSubscription;
  componentReused = false;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _dialog: MatDialog,
    private _authService: AuthService,
    private _teamHttpService: TeamHttpService,
    private _specialityHttpService: SpecialityHttpService,
    private _specialityEventHttpService: SpecialityEventHttpService,
    private _titleService: TitleService
  ) {
    this.navigationSubscription = this._router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (this.componentReused && e instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    this.componentReused = true;
    this._titleService.set_title('Специальности');
    this.teamUuid = this._activatedRoute.snapshot.paramMap.get('uuid');
    this._loadSpecialities();
    this._loadLibrarySpecialities();
    this._loadParentTeamsSpecialities();
  }

  ngOnDestroy(): void {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  get isTeamOwner(): boolean {
    return (
      this._authService
        .get_user_memberships()
        .data.find((membership) => membership.team.uuid === this.teamUuid)
        ?.role === 'owner'
    );
  }

  private _loadSpecialities(): void {
    this.specialities = new AsyncList<Speciality>(this._specialityHttpService);
    this.specialities.setRequestParams({
      params: {
        team: this.teamUuid,
      },
    });
    this.specialities.load().subscribe(() => {
      this._loadSpecialitiesEvents();
    });
  }

  private _loadSpecialitiesEvents(): void {
    this.specialitiesEvents = new AsyncList<SpecialityEvent>(
      this._specialityEventHttpService
    );
    this.specialitiesEvents.setRequestParams({
      params: {
        speciality__in: this.specialities.state.items
          .map((speciality) => speciality.uuid)
          .join(','),
        is_viewed: false,
        archive: false,
      },
    });
    this.specialitiesEvents.load().subscribe(() => {
      if (this.isTeamOwner) {
        const specialitiesWarningMessages = [];
        this.specialities.state.items.forEach((speciality) => {
          const specialityEventsCount =
            this.specialitiesEvents.state.items.filter(
              (event) => event.speciality === speciality.uuid
            ).length;

          if (specialityEventsCount) {
            specialitiesWarningMessages.push(
              `В специальность ${speciality.name} внесено ${specialityEventsCount} изменений.`
            );
          }
        });
        if (specialitiesWarningMessages.length)
          this._dialog.open(AcceptDialogComponent, {
            data: {
              title: ``,
              message:
                specialitiesWarningMessages.join('\n') +
                '\nС подробностями просим ознакомиться в журнале изменений специальности.',
              acceptLabel: 'Закрыть',
              rejectLabel: '',
            },
          });
      }
    });
  }

  private _loadLibrarySpecialities(): void {
    this.librarySpecialities = new AsyncList<Speciality>(
      this._specialityHttpService
    );
    this.librarySpecialities.setRequestParams({
      params: {
        team__isnull: 'True',
      },
    });
    this.librarySpecialities.load();
  }

  private _loadParentTeamsSpecialities(): void {
    this.parentTeams = new AsyncList<Team>(this._teamHttpService);
    this.parentTeams.setRequestParams({
      params: {
        team: this.teamUuid,
        with_parent_teams: true,
      },
    });
    this.parentTeams.load().subscribe(() => {
      this.parentTeams.state.items = this.parentTeams.state.items.filter(
        (team) => team.uuid !== this.teamUuid
      );
      if (this.parentTeams.state.items.length) {
        this.parentTeamsSpecialities = new AsyncList<Speciality>(
          this._specialityHttpService
        );
        this.parentTeamsSpecialities.setRequestParams({
          params: {
            team__in: this.parentTeams.state.items
              .map((team) => team.uuid)
              .join(','),
          },
        });
        this.parentTeamsSpecialities.load().subscribe(() => {
          this.parentSpecialitiesReady = true;
        });
      } else {
        this.parentSpecialitiesReady = true;
      }
    });
  }

  onSpecialityLinkClick(specialityUuid: string): void {
    this._router.navigate([
      `team/${this.teamUuid}/specialities/${specialityUuid}`,
    ]);
  }

  onSpecialityCreate(): void {
    this._dialog.open(TextfieldDialogComponent, {
      data: {
        title: `Новая специальность`,
        description: `Новые специальности согласовываются в течение 3 рабочих дней`,
        acceptLabel: 'Добавить',
        rejectLabel: 'Отменить',
        floatLabel: 'never',
        fields: [
          {
            label: 'Название специальности',
            placeholder: 'Введите название специальности',
            exampleText: '',
            required: true,
            formControlName: 'name',
            value: '',
          },
          {
            label: 'Какие задачи выполняет',
            placeholder:
              'Подробно опишите какие задачи будет решать специалист',
            exampleText:
              'Например: Дизайнер интерфейсов будет решать задачи коммуникации между системой ввода данных и пользователем',
            required: true,
            formControlName: 'description',

            value: '',
          },
        ],
        onSubmit: (data: any) => {
          data['team'] = this.teamUuid;
          this.specialities.create(data);
        },
      },
    });
  }

  onSpecialityEdit(speciality: Speciality): void {
    this._dialog.open(TextfieldDialogComponent, {
      data: {
        title: `Редактирование специальности`,
        description: ``,
        acceptLabel: 'Сохранить',
        rejectLabel: 'Отменить',
        floatLabel: 'never',
        fields: [
          {
            label: 'Название специальности',
            placeholder: 'Введите название специальности',
            exampleText: '',
            required: true,
            formControlName: 'name',
            value: speciality.name,
          },
          {
            label: 'Какие задачи выполняет',
            placeholder:
              'Подробно опишите какие задачи будет решать специалист',
            exampleText:
              'Например: Дизайнер интерфейсов будет решать задачи коммуникации между системой ввода данных и пользователем',
            required: true,
            formControlName: 'description',
            value: speciality.description,
          },
        ],
        onSubmit: (data: any) => {
          data['team'] = this.teamUuid;
          this.specialities.update(speciality.uuid, data);
        },
      },
    });
  }

  onSpecialityDelete(speciality: Speciality): void {
    this._dialog
      .open(AcceptDialogComponent, {
        data: {
          title: `Удалить специальность`,
          message: `Вы уверены, что хотите удалить специальность ${speciality.name}?`,
          acceptLabel: 'Удалить',
          rejectLabel: 'Отменить',
        },
      })
      .afterClosed()
      .subscribe((isAccept) => {
        if (isAccept) {
          this.specialities.remove(speciality.uuid);
        }
      });
  }

  onLibrarySpecialityAdd(): void {
    const combineSpecialities = [...this.librarySpecialities.state.items];
    if (this.parentTeamsSpecialities) {
      combineSpecialities.push(...this.parentTeamsSpecialities.state.items);
    }
    this._dialog.open(AddLibrarySpecialityDialogComponent, {
      data: {
        specialities: combineSpecialities,
        parentSpecialities: this.parentTeamsSpecialities?.state.items,
        onSubmit: (data: Speciality[]) => {
          data.forEach((speciality) => {
            this._teamHttpService
              .addSpeciality({
                team: this.teamUuid,
                speciality: speciality.uuid,
              })
              .subscribe(() => {
                this._loadSpecialities();
              });
          });
        },
        onOpenCreateDialog: () => {
          this.onSpecialityCreate();
        },
      },
    });
  }
}
