<div
  class='pdp-container'
  fxLayout='column'
  *ngIf='
    pdp?.state.isLoaded &&
    pdpActivities?.state.isLoaded &&
    pdpSteps?.state.isLoaded
  '
>
  <div
    fxLayout='row'
    fxLayoutAlign='start center'
    fxLayoutGap='2rem'
    class='pdp-title'
  >
    <button (click)='onBackClick()' mat-icon-button>
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span>{{ pdp.state.item.objective }}</span>
    <div fxFlex>
      <div fxLayout='row' fxLayoutAlign='end center' fxLayoutGap='8px'>
        <button mat-icon-button class='pdp-icon-btn' (click)='onPlanEdit()'>
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button class='pdp-icon-btn' (click)='onPlanDelete()'>
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div fxLayout='row' fxLayoutGap='2rem'>
    <div fxFlex='33' fxLayout='column' class='data-field'>
      <span>Прогресс</span>
      <div fxLayout='row' fxLayoutGap='8px' fxLayoutAlign='start center'>
        <mat-progress-bar
          [value]='pdp.state.item.completion_percentage'
        ></mat-progress-bar>
        <span>{{ pdp.state.item.completion_percentage + '%' }}</span>
      </div>
    </div>
    <app-static-textfield-group
      [fields]='pdpInfo'
      fxFlex='67'
    ></app-static-textfield-group>
  </div>
  <div class='data-field' fxFlex='100' fxLayout='column'>
    <span>
      <strong>Польза для себя:</strong>
      {{ pdp.state.item.personal_value }}
    </span>
  </div>
  <div>
    <mat-tab-group [animationDuration]="'0ms'" mat-stretch-tabs='false'>
      <mat-tab label='Текущие активности'>
        <div
          fxLayoutAlign='start center'
          class='activity-button-container'
          (click)='onDevelopmentPlanActivityAdd()'
        >
          <button
            mat-raised-button
            class='add-activity-button'
            color='primary'
          >
            <mat-icon>add_circle_outline</mat-icon>
          </button>
          <span class='add-activity-button-label'>Добавить активность</span>
        </div>
        <div fxLayout='column' fxLayoutGap='8px'>
          <div
            *ngFor='let activity of ongoingActivities'
            class='activity-container'
            fxLayout='column'
            fxLayoutGap='8px'
          >
            <ng-container
              [ngTemplateOutlet]='pdpActivity'
              [ngTemplateOutletContext]='{ activity: activity }'
            ></ng-container>
          </div>
        </div>
      </mat-tab>
      <mat-tab label='Завершенные активности'>
        <div *ngIf='!completedActivities.length' fxLayout='row' fxLayoutAlign='center center'
             [style.padding-top.px]='15'>
          Пока нет завершенных активностей
        </div>
        <div fxLayout='column' fxLayoutGap='8px'>
          <div
            *ngFor='let activity of completedActivities'
            class='activity-container'
            fxLayout='column'
            fxLayoutGap='8px'
          >
            <ng-container
              [ngTemplateOutlet]='pdpActivity'
              [ngTemplateOutletContext]='{ activity: activity }'
            ></ng-container>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #pdpActivity let-activity='activity'>
  <div fxLayout='row'>
    <div fxLayout='column' fxFlex='34'>
      <span>{{ activity.name }}</span>
      <div fxLayout='row' fxLayoutGap='8px' fxLayoutAlign='start center'>
        <mat-progress-bar
          [value]='activity.completion_percentage'
        ></mat-progress-bar>
        <span>{{ activity.completion_percentage + '%' }}</span>
      </div>
    </div>
    <div fxFlex='33'>
      <div fxFlex fxLayoutAlign='center center' fxLayoutGap='5px' *ngIf='activity.things?.length'>
        <span class='activity-thing-info'>{{
          activity.things[0]?.name
          }}</span>
        <span
          class='activity-thing-info'
          *ngIf='activity.things.length > 1'
        >{{ 'ещё ' + (activity.things.length - 1) }}</span
        >
      </div>
    </div>
    <div
      fxFlex='33'
      fxLayout='row'
      fxLayoutAlign='end center'
      fxLayoutGap='8px'
    >
      <div *ngIf='expandedActivity === activity.uuid' fxLayoutGap='8px'>
        <button
          mat-icon-button
          class='activity-icon-btn'
          (click)='onDevelopmentStepAdd(activity)'
        >
          <mat-icon>add_circle_outline</mat-icon>
        </button>
        <button
          mat-icon-button
          class='activity-icon-btn'
          (click)='onDevelopmentPlanActivityEdit(activity)'
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          class='activity-icon-btn'
          (click)='onActivityDelete(activity)'
        >
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
      <button mat-icon-button (click)='onActivityExpand(activity.uuid)'>
        <mat-icon>{{
          expandedActivity === activity.uuid ? 'expand_less' : 'expand_more'
          }}</mat-icon>
      </button>
    </div>
  </div>
  <div
    *ngIf='expandedActivity === activity.uuid'
    fxLayout='column'
    fxLayoutGap='8px'
  >
    <div *ngIf='!getActivitySteps(activity).length'>
      <div
        fxLayoutAlign='start center'
        class='activity-button-container'
        (click)='onDevelopmentStepAdd(activity)'
      >
        <button
          mat-raised-button
          class='add-activity-button'
          color='primary'
        >
          <mat-icon>add_circle_outline</mat-icon>
        </button>
        <span class='add-activity-button-label'>Добавить действие</span>
      </div>
    </div>
    <div
      *ngFor='let step of getActivitySteps(activity)'
      fxLayout='row'
      class='step-container'
      fxLayoutAlign='start center'
    >
      <mat-checkbox
        fxFlex='32px'
        (change)='onStepCompletionToggle(step)'
        [checked]='step.is_completed'
      ></mat-checkbox>
      <span fxFlex='40'>{{ step.name }}</span>
      <div fxFlex='40'>
        <div fxFlex fxLayoutGap='5px' *ngIf='step.things?.length'>
          <span class='step-thing-info'>
          {{ step.things[0]?.name }}
        </span>
          <span
            class='step-thing-info'
            *ngIf='step.things.length > 1'
          >{{ 'ещё ' + (step.things.length - 1) }}</span
          >
        </div>
      </div>
      <div fxFlex fxLayoutAlign='end center' fxLayoutGap='8px'>
        <button
          mat-icon-button
          (click)='onDevelopmentStepEdit(step)'
          class='step-delete-btn'
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)='onStepDelete(step)'
          class='step-delete-btn'
        >
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
