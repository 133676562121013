import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AsyncList } from 'src/app/core/rest/AsyncList';
import { SpecialityGroup } from 'src/app/models/specialities/speciality-group';
import { SpecialityGroupHttpService } from '@services/http/SpecialityGroupHttpService';
import { Speciality } from 'src/app/models/specialities/speciality';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-speciality-group-create',
  templateUrl: './speciality-group-create.component.html',
  styleUrls: ['./speciality-group-create.component.scss'],
})
export class SpecialityGroupCreateComponent implements OnInit {
  form = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    specialities: new UntypedFormArray([], Validators.required),
  });

  specialityGroups: AsyncList<SpecialityGroup>;
  specialities: AsyncList<Speciality>;

  constructor(
    private _specialityGroupHttpService: SpecialityGroupHttpService,
    private _specialityHttpService: SpecialityHttpService,
    private _router: Router
  ) {
    this.specialityGroups = new AsyncList(_specialityGroupHttpService);
    this.specialities = new AsyncList(_specialityHttpService);
  }

  ngOnInit(): void {
    this.specialityGroups.load();
    this.specialities.requestParams = {
      params: {
        team__isnull: 'True',
      },
    };
    this.specialities.load();
  }

  public get isProcessing(): boolean {
    return (
      this.specialityGroups.state.isProcessing ||
      this.specialities.state.isProcessing
    );
  }

  cancel(): void {
    return;
  }

  submit(): void {
    this.specialityGroups.create(this.form.value).subscribe(() => {
      this._router.navigate(['/library']);
    });
  }

  addSpeciality($event: Speciality): void {
    const formSpecialities = this.form.get('specialities') as UntypedFormArray;
    formSpecialities.push(new UntypedFormControl($event.uuid));
  }

  removeSpeciality($event: { value: Speciality }): void {
    const formSpecialities = this.form.get('specialities') as UntypedFormArray;
    formSpecialities.removeAt(
      this.form.value.specialities.indexOf($event.value.uuid)
    );
  }
}
