import { Component, OnInit } from '@angular/core';
import { TextfieldDialogComponent } from '../../../../common-ui-elements/components/textfield-dialog/textfield-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirectionHttpService } from '@services/http/DirectionHttpService';
import { AsyncList } from '@rest/AsyncList';
import { Direction } from '@models/directions/direction';
import { Router } from '@angular/router';

@Component({
  selector: 'app-directions-dashboard',
  templateUrl: './directions-dashboard.component.html',
  styleUrls: ['./directions-dashboard.component.css'],
})
export class DirectionsDashboardComponent implements OnInit {
  directions: AsyncList<Direction>;

  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    private _directionHttpService: DirectionHttpService
  ) {}

  ngOnInit(): void {
    this._loadDirections();
  }

  private _loadDirections(): void {
    this.directions = new AsyncList<Direction>(this._directionHttpService);
    this.directions.load();
  }

  onCreateDirection(): void {
    this.openCreateEditDialog();
  }

  onEditDirection(direction: Direction): void {
    this.openCreateEditDialog(direction);
  }

  onDeleteDirection(directionUuid: string): void {
    this._directionHttpService
      .delete(directionUuid)
      .subscribe(() => this._loadDirections());
  }

  openCreateEditDialog(direction?: Direction): void {
    this._dialog.open(TextfieldDialogComponent, {
      data: {
        title: direction ? 'Редактирование направления' : `Создать направление`,
        description: ``,
        acceptLabel: 'Сохранить',
        rejectLabel: 'Отменить',
        floatLabel: 'never',
        fields: [
          {
            label: 'Название',
            placeholder: 'Введите название направления',
            exampleText: '',
            required: true,
            formControlName: 'name',
            value: direction ? direction.name : '',
          },
          {
            label: 'Описание',
            placeholder: 'Подробно опишите',
            exampleText:
              'Например: Дизайнер интерфейсов будет решать задачи коммуникации между системой ввода данных и пользователем',
            required: false,
            formControlName: 'description',
            value: direction ? direction.description : '',
          },
        ],
        onSubmit: (data: any) => {
          if (direction) {
            this._directionHttpService
              .update(direction.uuid, {
                name: data.name,
                description: data.description,
              })
              .subscribe(() => this._loadDirections());
          } else {
            this._directionHttpService
              .create({
                name: data.name,
                description: data.description,
              })
              .subscribe(() => this._loadDirections());
          }
        },
      },
    });
  }

  onDetailsNavigate(directionUuid: string): void {
    this._router.navigate([`admin/directions/${directionUuid}`]);
  }
}
