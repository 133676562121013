import { Subject } from 'rxjs';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { List } from 'src/app/components/base/list';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent extends List implements OnInit {
  @Input() optionTemplate: TemplateRef<any>;
  @Input() service: any;
  @Input() placeholder = 'placeholder';
  @Input() ariaLabel = 'label';
  @Input() title = 'Search';
  @Input() displayWith = null;
  @Input() optionSelected: any;
  @Input() isResetOnSelect = true;

  private searchInput = new Subject<string>();
  public searchFormControl = new UntypedFormControl('', []);

  constructor(logging_service: LoggingService, alert_service: AlertService) {
    super(logging_service, alert_service, null);
  }

  ngOnInit(): void {
    this.apiService = this.service;
    this.searchInput
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((inputText) => {
        this.requestParams = { search: inputText };
        this.load();
      });
  }

  onInput($event) {
    this.searchInput.next($event.target.value);
  }

  onSelectHandler($event) {
    this.optionSelected($event.option.value);

    if (this.isResetOnSelect) {
      this.searchFormControl.reset();
    }
  }
}
