import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityGrade } from '@models/specialities/speciality-grade';

export interface UserTeamSpecialityData {
  speciality: Speciality;
  specialityGrade: SpecialityGrade;
}

@Component({
  selector: 'app-team-join-dialog',
  templateUrl: './team-join-dialog.component.html',
  styleUrls: ['./team-join-dialog.component.css'],
})
export class TeamJoinDialogComponent implements OnInit {
  form: UntypedFormGroup;
  selectedSpecialityGrades: SpecialityGrade[] = [];
  constructor(
    public dialogRef: MatDialogRef<TeamJoinDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      specialities: Speciality[];
      specialitiesGrades: SpecialityGrade[];
      onSubmit(formData: UserTeamSpecialityData): void;
    },
    private _formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this._initForm();
  }

  private _initForm(): void {
    this.form = this._formBuilder.group({
      speciality: ['', [Validators.required]],
      specialityGrade: ['', [Validators.required]],
    });
  }

  onSelectDefaultGrade(speciality: Speciality): void {
    const selectedSpecialityGrades = this.data.specialitiesGrades.filter(
      (specialityGrade) => specialityGrade.speciality === speciality.uuid
    );
    this.selectedSpecialityGrades = selectedSpecialityGrades;
    this.form.controls.specialityGrade.setValue(
      selectedSpecialityGrades.sort(
        (a, b) => a.order_number - b.order_number
      )[0]
    );
  }

  close(): void {
    this.data.onSubmit(this.form.value);
    this.dialogRef.close();
  }
}
