import { Injectable } from '@angular/core';
import { ViewSetHttpService } from '@services/http/rest/ViewSetHttpService';
import { HttpClient } from '@angular/common/http';
import { Company } from '@models/projects/company';

@Injectable({
  providedIn: 'root',
})
export class CompanyHttpService extends ViewSetHttpService<Company> {
  constructor(_http: HttpClient) {
    super(_http);
  }

  public viewSetUrl = 'projects/company';
}
