import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as d3 from 'd3';
import { AxisDomain, ScaleTime, select } from 'd3';

interface Config {
  renderTo: string;
  sublanes: number;
  tickFormat: 'day' | 'week' | 'month';
  isAutoResize: boolean;
  isEnableDrag: boolean;
  isEnableItemResize: boolean;
  isEnableEdit: boolean;
  isEnableTooltip: boolean;
  isEnableZoom: boolean;
  isShowXGrid: boolean;
  isShowYGrid: boolean;
  isShowLaneLabel: boolean;
  height: number;
  width: number;
  laneHeight: number;
  margin: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
}

export interface RectData {
  id: string;
  user: string;
  lane: number;
  class: string;
  dateStart: Date;
  dateEnd: Date;
  text: string;
}

d3.timeFormatDefaultLocale({
  dateTime: '%a %b %e %X %Y',
  date: '%d.%m.%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: [
    'Воскресенье',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
  ],
  shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  months: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  shortMonths: [
    'Янв',
    'Фев',
    'Март',
    'Апр',
    'Май',
    'Июнь',
    'Июль',
    'Авг',
    'Сент',
    'Окт',
    'Ноя',
    'Дек',
  ],
});

@Component({
  selector: 'app-chart-gantt',
  templateUrl: './chart-gantt.component.html',
  styleUrls: ['./chart-gantt.component.css'],
})
export class ChartGanttComponent implements OnInit {
  @Input() items = [];
  @Input() lanes = [];
  @Input() config: Partial<Config> = {};
  @Input() todayTrigger: boolean;

  @Output() rectChange: EventEmitter<RectData> = new EventEmitter<RectData>();
  @Output() rectEdit: EventEmitter<RectData> = new EventEmitter<RectData>();

  defaultConfig = {
    renderTo: '#gantt_chart',
    sublanes: 1,
    tickFormat: 'week', // 'day'/'week'/'month'
    isAutoResize: false,
    isEnableDrag: true,
    isEnableItemResize: true,
    isEnableTooltip: false,
    isEnableEdit: true,
    isEnableZoom: true,
    isShowXGrid: true,
    isShowYGrid: true,
    isShowLaneLabel: true,
    height: null,
    width: null,
    laneHeight: 70,
    margin: {
      top: 20,
      right: 15,
      bottom: 20,
      left: 0,
    },
  };

  toStr = Object.prototype.toString;

  astr = '[object Array]';
  ostr = '[object Object]';
  chart;
  drag;
  main;
  itemRects;
  tooltipDiv;
  xAxis;
  yAxis;

  dateAxis;
  todayLine;

  xScale;
  xScaleCopy;
  yScale;
  zoom;

  resizeRectMargin = 10;

  eventType: 'resize' | 'drag' = null;
  createXAxisFunc;

  constructor() {}

  ngOnInit(): void {
    this.setConfig();

    this.lanes.length = this.getLaneLength();

    this.config.height =
      this.config.margin.top +
      this.config.margin.bottom +
      this.items.length * this.config.laneHeight;

    if (!this.config.width) {
      this.config.width =
        parseInt(d3.select(this.config.renderTo).style('width')) || 2000;
      // this.config.width = 2500;
    }

    this.build();
    this.enableAutoResize();
    this.showLaneLabel();
    this.redraw(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['todayTrigger']?.currentValue) {
      this.xScale = this.xScaleCopy.copy();
      this.createXAxisFunc(this.xScale);
      this.dateAxis.call(this.xAxis);
      this.redraw(this.xScale);
      this.chart.call(this.zoom.transform, d3.zoomIdentity);
    }
  }

  setConfig(): void {
    Object.keys(this.defaultConfig).forEach((key) => {
      if (!Object.keys(this.config).includes(key)) {
        this.config[key] = this.defaultConfig[key];
      }
    });
    switch (this.config.tickFormat) {
      case 'day':
        this.createXAxisFunc = this.createDayXAxis;
        break;
      case 'week':
        this.createXAxisFunc = this.createWeekXAxis;
        break;
      case 'month':
        this.createXAxisFunc = this.createMonthXAxis;
        break;
    }
  }

  enableAutoResize(): void {
    d3.select(window).on(
      'resize',
      this.config.isAutoResize ? this.resize : null
    );
  }

  build(): void {
    const marginWidth = this.getMarginWidth(),
      marginHeight = this.getMarginHeight();

    this.chart = d3
      .select(this.config.renderTo)
      .append('svg')
      .attr('width', this.config.width)
      .attr('height', this.config.height)
      .attr('class', 'gantt-chart');

    this.chart
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('width', marginWidth)
      .attr('height', marginHeight);

    this.drag = d3
      .drag()
      .on('start', (d, i, rects) => this.dragstart(d, i, rects, this))
      .on('drag', (d, i, rects) => this.dragmove(d, i, rects, this))
      .on('end', (d, i, rects) => this.dragend(d, i, rects, this));

    this.main = this.chart
      .append('g')
      .attr(
        'transform',
        'translate(' +
          this.config.margin.left +
          ',' +
          this.config.margin.top +
          ')'
      )
      .attr('width', marginWidth)
      .attr('height', marginHeight)
      .attr('class', 'main');

    this.itemRects = this.main.append('g').attr('clip-path', 'url(#clip)');

    this.tooltipDiv = d3
      .select('body')
      .append('div')
      .attr('class', 'gantt-tooltip')
      .style('opacity', 0);

    this.xScale = d3.scaleTime(this.getTimeDomain(), [0, marginWidth]);
    this.xScaleCopy = this.xScale.copy();

    this.yScale = d3
      .scaleLinear()
      .domain([0, this.lanes.length])
      .range([0, marginHeight]);

    this.createXAxisFunc();

    this.yAxis = d3
      .axisLeft(this.yScale)
      .ticks(this.lanes.length)
      .tickFormat((d) => {
        return '';
      });

    this.dateAxis = this.main
      .append('g')
      .attr('transform', 'translate(0,' + marginHeight + ')')
      .attr('class', 'main axis date')
      .call(this.xAxis);

    this.todayLine = this.dateAxis.append('g');

    this.main.append('g').attr('class', 'main axis lane').call(this.yAxis);

    this.main.append('g').attr('class', 'laneLabels');

    if (this.config.isEnableZoom) {
      this.zoom = d3
        .zoom()
        .scaleExtent([1, 10])
        .on('zoom', () => {
          this.xScale = d3.event.transform.rescaleX(this.xScaleCopy);
          this.createXAxisFunc();
          this.dateAxis.call(this.xAxis);
          this.redraw(null);
        });
      this.chart.call(this.zoom).on('wheel.zoom', null); // disables default zoom wheel behavior
      // .on('wheel', () => {
      //   // console.log(d3.event);
      //   this.zoom.translateBy(
      //     this.chart.transition().duration(100),
      //     d3.event.wheelDeltaX,
      //     d3.event.wheelDeltaY
      //   );
      // });
    }

    d3.select('html').on('click', (d) => {
      if (!this.config.isEnableTooltip) return;
      if (!(event.target as HTMLElement).closest('svg rect')) {
        this.hideTooltip();
      }
    });
  }

  createDayXAxis(xScale: ScaleTime<any, any>): void {
    if (!xScale) xScale = this.xScale;
    this.xAxis = d3
      .axisTop(xScale)
      .ticks(d3.timeDay.every(1))
      .tickFormat((domainValue) => this.tickFormat(domainValue, this));
  }

  createWeekXAxis(xScale: ScaleTime<any, any>): void {
    if (!xScale) xScale = this.xScale;
    this.xAxis = d3
      .axisTop(xScale)
      .ticks(d3.timeWeek.every(1))
      .tickFormat((domainValue) => this.tickFormat(domainValue, this));
  }

  createMonthXAxis(xScale: ScaleTime<any, any>): void {
    if (!xScale) xScale = this.xScale;
    this.xAxis = d3
      .axisTop(xScale)
      .ticks(d3.timeMonth.every(1))
      .tickFormat((domainValue) => this.tickFormat(domainValue, this));
  }

  redraw(xScale: ScaleTime<any, any>): void {
    this.showXGrid();
    this.showYGrid();

    if (!xScale) xScale = this.xScale;
    const itemHeight =
      this.getMarginHeight() /
      (this.lanes.length || 1) /
      (this.config.sublanes || 1);

    this.itemRects.selectAll('g').remove();
    this.todayLine.selectAll('line').remove();

    const rectContainer = this.itemRects
      .selectAll('g')
      .data(this.items)
      .enter()
      .append('g')
      .call(this.drag)
      .on('click', (d, i, rects) => {
        this.editRect(d, i, rects, this);
      })
      .on('mousemove', (d, i, rects) => this.changeCursor(d, i, rects, this));

    rectContainer
      .append('rect')
      .attr('x', (d) => {
        return xScale(d.dateStart);
      })
      .attr('y', (d) => {
        return this.config.sublanes < 2
          ? this.yScale(d.lane)
          : this.yScale(d.lane) + d.sublane * itemHeight;
      })
      .attr('width', (d) => {
        return xScale(d.dateEnd) - xScale(d.dateStart);
      })
      .attr('height', itemHeight)
      .attr('class', (d) => {
        return d.class;
      })
      .attr('opacity', 0.75);

    rectContainer
      .append('text')
      .attr('x', (d) => {
        return xScale(d.dateStart) + 10;
      })
      .attr('y', (d) => {
        return this.config.sublanes < 2
          ? this.yScale(d.lane) + itemHeight / 2
          : this.yScale(d.lane) + d.sublane * itemHeight;
      })
      .attr('opacity', 1)
      .attr('class', 'rect-text')
      .text((d) => d.text);

    this.todayLine
      .append('line')
      .attr('x1', () => this.xScale(new Date()))
      .attr('y1', () => -this.config.height)
      .attr('x2', () => this.xScale(new Date()))
      .attr('y2', () => this.yScale(this.items.length))
      .attr('class', 'line')
      .style('stroke', '#4566e3')
      .style('stroke-width', '2px');

    // this.hideTooltip();
  }

  editRect(d, i, rects, me): void {
    if (!me.config.isEnableEdit) return;
    this.rectEdit.emit(d);
  }

  changeCursor(d, i, rects, me): void {
    const selectedG = d3.select(rects[i]),
      selectedRect = selectedG.select('rect'),
      x = parseFloat(selectedRect.attr('x')) + me.resizeRectMargin,
      width = parseFloat(selectedRect.attr('width')),
      x1 = x + width - 20;
    if (
      x + me.config.margin.left >= d3.event.offsetX ||
      x1 <= d3.event.offsetX
    ) {
      selectedRect.attr(
        'class',
        d.class +
          (me.config.isEnableItemResize ? ' cursor-resize' : ' cursor-default')
      );
    } else {
      selectedRect.attr(
        'class',
        d.class + (me.config.isEnableDrag ? ' cursor-move' : ' cursor-default')
      );
    }
  }

  dragstart(d, i, rects, me): void {
    if (!me.config.isEnableDrag && !me.config.isEnableItemResize) return;
    const selectedG = d3.select(rects[i]),
      selectedRect = selectedG.select('rect'),
      x = parseFloat(selectedRect.attr('x')),
      width = parseFloat(selectedRect.attr('width')),
      x1 = x + width;
    // console.log(selectedRect);
    // console.log()
    if (
      x + me.resizeRectMargin >= d3.event.x &&
      x <= x1 - me.resizeRectMargin
    ) {
      me.eventType = 'resize';
    } else if (x1 - me.resizeRectMargin <= d3.event.x && x + 5 <= x1) {
      me.eventType = 'resize';
    } else {
      me.eventType = 'drag';
    }
    d3.event.sourceEvent.stopPropagation();
  }

  dragmove(d, i, rects, me): void {
    const selectedG = d3.select(rects[i]),
      selectedRect = selectedG.select('rect'),
      selectedText = selectedG.select('text'),
      x = parseFloat(selectedRect.attr('x')),
      y = parseFloat(selectedRect.attr('y')),
      width = parseFloat(selectedRect.attr('width')),
      x1 = x + width;

    if (me.config.isEnableItemResize && me.eventType === 'resize') {
      if (
        x + me.resizeRectMargin >= d3.event.x &&
        x <= x1 - me.resizeRectMargin
      ) {
        selectedRect
          .attr('x', x + d3.event.dx)
          .attr('width', width - d3.event.dx);
        selectedText.attr('x', x + d3.event.dx + 10);
        return;
      }
      if (x1 - me.resizeRectMargin <= d3.event.x && x + 5 <= x1) {
        selectedRect.attr('width', width + d3.event.dx);
        return;
      }
    }
    if (me.config.isEnableDrag && me.eventType === 'drag') {
      selectedRect.attr('x', x + d3.event.dx);
      selectedText.attr('x', x + d3.event.dx + 10);
      // .attr('y', y + d3.event.dy); uncomment if need crossline drag
    }
  }

  dragend(d, i, rects, me): void {
    if (!me.config.isEnableDrag && !me.config.isEnableItemResize) return;

    const g = d3.select(rects[i]),
      el = g.select('rect'),
      text = g.select('text'),
      start = el.attr('x'),
      recLeft = parseFloat(start),
      width = parseFloat(el.attr('width')),
      recRight = recLeft + width;
    let lane = Math.round(me.yScale.invert(el.attr('y')));

    const chartEl = document.getElementsByClassName('gantt-chart')[0],
      // chartX = chartEl.getBoundingClientRect().left,
      xAxis = document.getElementsByClassName('main axis date'),
      xAxisChildren = xAxis[0];

    const ticks = [];
    xAxisChildren.childNodes.forEach((node) => {
      const tick = node as HTMLElement;

      if (tick.localName != 'path') {
        // const tickRightX = tick.getBoundingClientRect().right - chartX;
        const tickLeftX = parseFloat(
          tick
            .getAttribute('transform')
            .replace(/[translate()]/g, '')
            .split(',')[0]
        );

        //37 is the average width of tick, for some reason getBoundingClientRect().left gets wrong x, if ticks width is below average
        // const inaccuracy = 37 - (tickRightX - tickLeftX);
        // if (inaccuracy > 0) {
        //   tickLeftX = tickLeftX - inaccuracy;
        // }

        ticks.push(tickLeftX);
      }
    });
    ticks.push(chartEl.getBoundingClientRect().right);

    let prevTickX,
      resultCoords = [];
    ticks.forEach((tickX) => {
      if (!prevTickX) {
        prevTickX = 0;
      }

      if (!resultCoords.length) {
        resultCoords = [prevTickX, tickX];
      } else {
        if (
          Math.abs(prevTickX - recLeft) < Math.abs(resultCoords[0] - recLeft) &&
          Math.abs(tickX - recRight) < Math.abs(resultCoords[1] - recRight)
        ) {
          resultCoords = [prevTickX, tickX];
        }
      }

      prevTickX = tickX;
    });

    if (lane >= me.lanes.length) {
      lane = me.lanes.length - 1;
    }
    if (lane < 0) {
      lane = 0;
    }

    const resultStart = me.xScale.invert(resultCoords[0]),
      resultEnd = me.xScale.invert(
        parseFloat(el.attr('width')) + parseFloat(resultCoords[0])
      );

    el.attr('y', me.yScale(lane));
    el.attr('x', me.xScale(me.xScale.invert(resultCoords[0])));
    text.attr('y', me.yScale(lane) + me.config.laneHeight / 2);
    text.attr('x', me.xScale(me.xScale.invert(resultCoords[0])) + 10);

    // d.lane = lane;
    // d.dateStart = Date.parse(resultStart);
    // d.dateEnd = Date.parse(resultEnd);

    this.rectChange.emit({
      id: d.id,
      user: this.items.find((item) => lane === item.lane).user,
      lane: lane,
      class: d.class,
      dateStart: resultStart,
      dateEnd: resultEnd,
      text: d.text,
    });
  }

  getLaneLength(): any {
    return (
      d3.max(this.items, (d) => {
        return d.lane;
      }) + 1
    );
  }

  getMarginWidth(): number {
    return (
      this.config.width - this.config.margin.right - this.config.margin.left
    );
  }

  getMarginHeight(): number {
    return (
      this.config.height - this.config.margin.top - this.config.margin.bottom
    );
  }

  getTimeDomain(): any {
    const minDate = new Date(
        d3.min(this.items, (d) => {
          return d.dateStart;
        })
      ),
      maxDate = new Date(
        d3.max(this.items, (d) => {
          return d.dateEnd;
        })
      );

    const now = new Date();

    let firstDayOfMonthTimeStamp, lastDayOfMonthTimeStamp;

    if (this.config.tickFormat === 'month') {
      firstDayOfMonthTimeStamp = Date.parse(
        new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        ).toISOString()
      );
      lastDayOfMonthTimeStamp = Date.parse(
        new Date(
          now.getFullYear(),
          now.getMonth() + 1,
          now.getDate()
        ).toISOString()
      );
    } else {
      firstDayOfMonthTimeStamp = Date.parse(
        new Date(now.getFullYear(), now.getMonth(), 1).toISOString()
      );
      lastDayOfMonthTimeStamp = Date.parse(
        new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString()
      );
    }

    return [firstDayOfMonthTimeStamp, lastDayOfMonthTimeStamp];
  }

  hideTooltip(): void {
    this.tooltipDiv
      .transition()
      .duration(500)
      .style('opacity', 0)
      .style('display', 'none');
  }

  resize(): void {
    if (this.config.isAutoResize) {
      this.config.width = parseInt(
        d3.select(this.config.renderTo).style('width')
      );
      this.config.height = parseInt(
        d3.select(this.config.renderTo).style('height')
      );
    }
    const marginWidth = this.getMarginWidth(),
      marginHeight = this.getMarginHeight();

    this.xScale.range([0, marginWidth]);
    this.yScale.range([0, marginHeight]);
    this.chart.attr('width', this.config.width);
    this.chart.attr('height', this.config.height);
    this.chart
      .select('defs')
      .select('clipPath')
      .select('rect')
      .attr('width', marginWidth);
    this.chart
      .select('defs')
      .select('clipPath')
      .select('rect')
      .attr('height', marginWidth);
    this.main.attr('width', marginWidth);
    this.main.attr('height', marginHeight);

    this.main
      .select('g.main.axis.date')
      .attr('transform', 'translate(0,' + marginHeight + ')');

    this.main
      .select('g.laneLabels')
      .selectAll('.laneText')
      .data(this.lanes)
      .attr('y', (d, i) => {
        return this.yScale(i + 0.5);
      });

    // zoom.x(xScale);

    this.showXGrid();
    this.showYGrid();

    this.redraw(null);
  }

  showLaneLabel(): void {
    if (!this.config.isShowLaneLabel) {
      this.main.selectAll('.laneText').remove();
    } else {
      this.main
        .select('g.laneLabels')
        .selectAll('.laneText')
        .data(this.lanes)
        .enter()
        .append('text')
        .text((d) => {
          return d;
        })
        .attr('x', -this.config.margin.right)
        .attr('y', (d, i) => {
          return this.yScale(i + 0.5);
        })
        .attr('dy', '.5ex')
        .attr('text-anchor', 'start')
        .attr('class', 'laneText');
    }
  }

  showTooltip(d): void {
    if (d3.event.defaultPrevented) return;
    this.tooltipDiv
      .style('display', 'block')
      .transition()
      .duration(200)
      .style('opacity', 0.9);
    this.tooltipDiv
      .html(typeof d.tooltip === 'function' ? d.tooltip() : d.tooltip)
      .style('left', d3.event.pageX + 'px')
      .style('top', d3.event.pageY + 'px');
  }

  showXGrid(): void {
    const height = this.config.isShowXGrid ? this.getMarginHeight() : -6;
    this.xAxis.tickSize(height, 0, 0);
    this.main.select('g.main.axis.date').call(this.xAxis);
  }

  showYGrid(): void {
    const width = this.config.isShowYGrid ? -this.getMarginWidth() : -6;
    this.yAxis.tickSize(width, 0, 0);
    this.main.select('g.main.axis.lane').call(this.yAxis);
  }

  tickFormat(date: AxisDomain, me: ChartGanttComponent): string {
    const formatMillisecond = d3.timeFormat('.%L'),
      formatSecond = d3.timeFormat(':%S'),
      formatMinute = d3.timeFormat('%I:%M'),
      formatHour = d3.timeFormat('%I %p'),
      formatDay = d3.timeFormat('%a %d'),
      formatMonth = d3.timeFormat('%b'),
      formatYear = d3.timeFormat('%Y');
    let formatWeek = d3.timeFormat('%a %d');

    if (me.config.tickFormat === 'week') {
      formatWeek = d3.timeFormat('%b %d');
    }

    function multiFormat(date) {
      return (
        d3.timeSecond(date) < date
          ? formatMillisecond
          : d3.timeMinute(date) < date
          ? formatSecond
          : d3.timeHour(date) < date
          ? formatMinute
          : d3.timeDay(date) < date
          ? formatHour
          : d3.timeMonth(date) < date
          ? d3.timeWeek(date) < date
            ? formatDay
            : formatWeek
          : d3.timeYear(date) < date
          ? formatMonth
          : formatYear
      )(date);
    }
    return multiFormat(date);
  }

  throwError(msg): void {
    throw TypeError(msg);
  }
}
