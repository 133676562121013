<div class="dialog-container">
  <div class="title">
    {{ data.title }}
  </div>

  <div
    class="description-container"
    *ngIf="data.description.length > 0"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <mat-icon fxFlex="2rem">info</mat-icon>
    <span fxFlex class="description">
      {{ data.description }}
    </span>
  </div>

  <form [formGroup]="form" class="dialog-form">
    <div *ngFor="let textField of data.fields" fxLayout="column">
      <mat-label class="textfield-label">{{ textField.label }}</mat-label>
      <mat-form-field
        [style.width.rem]="30"
        floatLabel="{{ data.floatLabel ? data.floatLabel : 'auto' }}"
      >
        <input
          matInput
          required="{{ textField.required }}"
          placeholder="{{ textField.placeholder }}"
          type="text"
          formControlName="{{ textField.formControlName }}"
        />
      </mat-form-field>
      <mat-hint
        class="textfield-hint"
        *ngIf="textField.exampleText.length > 0"
        >{{ textField.exampleText }}</mat-hint
      >
    </div>
  </form>

  <div mat-dialog-actions fxLayoutAlign="end center">
    <button
      class="dialog-reject-button"
      [mat-dialog-close]="true"
      mat-raised-button
    >
      {{ data.rejectLabel }}
    </button>
    <button
      class="dialog-accept-button"
      [mat-dialog-close]="true"
      mat-raised-button
      [disabled]="form.invalid"
      (click)="close()"
    >
      {{ data.acceptLabel }}
    </button>
  </div>
</div>
