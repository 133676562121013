import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

import { environment } from 'src/environments/environment';
import { SentryCheckService } from 'src/app/services/system/sentry-check.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { LoggingService } from 'src/app/services/logging.service';
import { ApiService } from '../../../../../services/api.service';
import { AppService } from '../../../../../services/app/app.service';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdoitArchitectHttpService } from '@services/http/AdoitArchitectHttpService';
import { AsyncList } from '@rest/AsyncList';
import { AdoitArchitect } from '@models/adoit/adoit-architect';
import { User } from '@models/user/user';
import { TeamHttpService } from '@services/http/TeamHttpService';

@Component({
  selector: 'app-debugging-dashboard',
  templateUrl: './debugging-dashboard.component.html',
  styleUrls: ['./debugging-dashboard.component.css'],
})
export class DebuggingDashboardComponent implements OnInit {
  public environment = environment;
  public auth_info: any;
  public config_info: any;

  adoitForm: UntypedFormGroup;

  backendVersion: string;

  architects: AsyncList<AdoitArchitect>;
  isAddArchitectVisible = false;
  selectedUser: User;

  constructor(
    private _http: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private auth_service: AuthService,
    private app_service: AppService,
    private api_service: ApiService,
    private sentry_check_service: SentryCheckService,
    private alert_service: AlertService,
    private logging_service: LoggingService,
    private _adoitArchitectHttpService: AdoitArchitectHttpService,
    private _teamHttpService: TeamHttpService
  ) {}

  ngOnInit(): void {
    this.config_info = JSON.stringify(environment, undefined, 4);
    this.auth_info = JSON.stringify(
      this.auth_service.debug_get_user_data(),
      undefined,
      4
    );
    this.api_service
      .get('get_backend_version', {})
      .subscribe((response: Object) => {
        this.backendVersion = response['version'];
      });

    this._initAdoitForm();
    this._loadAdoitArchitects();
  }

  private _initAdoitForm(): void {
    this.adoitForm = this.formBuilder.group({
      starsmap_thing_id: [
        '',
        [Validators.minLength(1), Validators.maxLength(128)],
      ],
    });
  }

  private _loadAdoitArchitects(): void {
    this.architects = new AsyncList<AdoitArchitect>(
      this._adoitArchitectHttpService
    );
    this.architects.setRequestParams({
      params: {
        expand: 'user',
      },
    });
    this.architects.load();
  }

  get serializedArchitects(): User[] {
    return this.architects.state.items.map(
      (architect) => architect.user
    ) as User[];
  }

  onUserSelected(user: User): void {
    this.selectedUser = user;
  }

  addArchitect(): void {
    this.architects
      .create({
        user: this.selectedUser.uuid,
      })
      .subscribe(() => {
        this._loadAdoitArchitects();
        this.isAddArchitectVisible = false;
      });
  }

  removeArchitect(architectUuid: string): void {
    this.architects.remove(architectUuid).subscribe(() => {
      this._loadAdoitArchitects();
    });
  }

  public get frontendVersion(): string {
    return this.app_service.version;
  }

  check_backend_sentry_error() {
    this.sentry_check_service.run().subscribe(
      (response) => {
        this.logging_service.debug(`${this.constructor.name} checked sentry`);
      },
      (err) => {
        this.logging_service.error(
          `${this.constructor.name} failed to check setnry service`
        );
        this.alert_service.success('Success check sentry error view');
      }
    );
  }

  check_frontend_sentry_error() {
    throw new Error('check sentry error');
  }

  check_celery(): void {
    this.api_service.get(`celery_check`, {}).subscribe();
  }

  all_team_matrix_cache(): void {
    this._teamHttpService.updateAllTeamMatrixCache().subscribe(() => {
      this.alert_service.success('Task is running');
    });
  }

  import_adoit(): void {
    this._http
      .post(`${environment.apiUrl}/api/1/adoit/import`, {
        // starsmap_thing_id: '5635b303-6532-4749-a140-04ae48d9a405',
        starsmap_thing_id: this.adoitForm.controls.starsmap_thing_id.value,
        name: 'корел',
        description: 'корел',
        adoit_id: '3',
        status: 'ADOPT',
      })
      .subscribe(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  patch_adoit(): void {
    this._http
      .post(`${environment.apiUrl}/api/1/adoit/things/patch_adoit_thing`, {
        adoit_id: '1',
        name: 'арабский',
        description: 'арабскийqqqqqqqq',
      })
      .subscribe(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  list_adoit(): void {
    this._http.get(`${environment.apiUrl}/api/1/adoit/things`).subscribe(
      (response) => {
        console.log(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  experts_adoit(): void {
    this._http
      .get(
        `${environment.apiUrl}/api/1/adoit/experts?starsmap_thing_id=${this.adoitForm.controls.starsmap_thing_id.value}`
      )
      .subscribe(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
