import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DetailFormData {
  name: string;
}

@Component({
  selector: 'app-imported-data-edit-dialog',
  templateUrl: './imported-data-edit-dialog.component.html',
  styleUrls: ['./imported-data-edit-dialog.component.css'],
})
export class ImportedDataEditDialogComponent implements OnInit {
  form: UntypedFormGroup;
  constructor(
    public dialogRef: MatDialogRef<ImportedDataEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      defaultData: { name: string };
      isThing: boolean;
      isEdit: boolean;
      onSubmit(formData: DetailFormData): void;
    },
    private _formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this._initForm();
  }

  private _initForm(): void {
    this.form = this._formBuilder.group({
      name: [
        this.data.defaultData.name,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(128),
        ],
      ],
    });
  }

  getTitleLabel(): string {
    return this.data.isEdit
      ? `${
          this.data.isThing
            ? 'Изменить компетенцию'
            : 'Изменить группу компетенций'
        }`
      : `${
          this.data.isThing ? 'Новая компетенция' : 'Новая группа компетенций'
        }`;
  }

  close(): void {
    this.data.onSubmit(this.form.value);
    this.dialogRef.close();
  }
}
