import { Component, OnInit } from '@angular/core';
import { SpecialityHttpService } from '@services/http/SpecialityHttpService';
import { AsyncList } from '@rest/AsyncList';
import { Speciality } from '@models/specialities/speciality';
import { SpecialityValidation } from '@models/specialities/speciality-validation';
import { AuthService } from '../../../services/auth/auth.service';
import { SpecialityValidationHttpService } from '@services/http/SpecialityValidationHttpService';
import { SpecialityReviewerHttpService } from '@services/http/SpecialityReviewerHttpService';
import { SpecialityReviewer } from '@models/specialities/speciality-reviewer';
import { combineLatest } from 'rxjs';
import { Review } from '@models/reviews/review';

@Component({
  selector: 'app-library-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {
  userUuid: string;

  selfCreatedValidations: AsyncList<SpecialityValidation>;
  selfSpecialityReviewer: AsyncList<SpecialityReviewer>;

  public specialities: AsyncList<Speciality>;
  activeTabIndex: number;

  loadLibraryDomainDetail = false;
  loadTeamMatchUsers = false;
  loadSpecialityList = false;

  constructor(
    private _specialityHttpService: SpecialityHttpService,
    private _authService: AuthService,
    private _specialityValidationHttpService: SpecialityValidationHttpService,
    private _specialityReviewerHttpService: SpecialityReviewerHttpService
  ) {}

  ngOnInit(): void {
    this.specialities = new AsyncList<Speciality>(this._specialityHttpService);
    this.specialities.requestParams = { params: { team__isnull: 'True' } };
    this.specialities.load();

    this.userUuid = this._authService.get_current_user_uuid();
    this.selfCreatedValidations = new AsyncList<SpecialityValidation>(
      this._specialityValidationHttpService
    );
    this.selfCreatedValidations.setRequestParams({
      params: {
        created_by: this.userUuid,
        status__in: '0,1,2',
        expand: 'speciality,related_reviewers.user',
      },
    });

    this.selfSpecialityReviewer = new AsyncList(
      this._specialityReviewerHttpService
    );
    this.selfSpecialityReviewer.setRequestParams({
      params: {
        user: this.userUuid,
        speciality_validation__status__in: '0,1,2',
        expand:
          'speciality_validation.speciality,speciality_validation.related_reviewers.user',
      },
    });

    combineLatest([
      this.selfCreatedValidations.load(),
      this.selfSpecialityReviewer.load(),
    ]).subscribe(() => {
      this._setActiveTabIndex();
    });
  }

  private _setActiveTabIndex(): void {
    this.activeTabIndex = parseInt(
      localStorage.getItem('libraryActiveTabIndex')
    );
    if (this.activeTabIndex) {
      if (
        this.activeTabIndex === 3 &&
        !this.selfCreatedValidations.state.items.length &&
        !this.selfSpecialityReviewer.state.items.length
      ) {
        this.saveActiveTabIndex(0);
      } else {
        this.loadActiveTab(this.activeTabIndex);
      }
    } else {
      this.saveActiveTabIndex(0);
    }
  }

  saveActiveTabIndex(index: number): void {
    localStorage.setItem('libraryActiveTabIndex', index.toString());
    this.activeTabIndex = index;
    this.loadActiveTab(this.activeTabIndex);
  }

  loadActiveTab(tabIndex: number): void {
    switch (tabIndex) {
      case 0:
        this.loadSpecialityList = true;
        break;
      case 1:
        this.loadLibraryDomainDetail = true;
        break;
      case 2:
        this.loadTeamMatchUsers = true;
        break;
    }
  }
}
