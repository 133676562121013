import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';

import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

import { SpecialityGroupListComponent } from 'src/app/modules/library/speciality-groups/speciality-group-list/speciality-group-list.component';
import { CommonComponentsModule } from 'src/app/components/common/common.module';
import { DomainsComponent } from './domains/domains.component';
import { SpecialityGroupCreateComponent } from './speciality-groups/speciality-group-create/speciality-group-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SpecialityGroupEditComponent } from './speciality-groups/speciality-group-edit/speciality-group-edit.component';
import { ThingCreateComponent } from './thing/thing-create/thing-create.component';
import { ThingEditComponent } from './thing/thing-edit/thing-edit.component';
import { CommonD3ChartsModule } from '../common-d3-charts/common-d3-charts.module';

import { SearchComponent } from './search/search.component';
import { ThingsComponent } from './things/things.component';
import { LibraryDomainDetailComponent } from './domains/domain-detail/domain-detail.component';
import { DomainDetailTreeComponent } from './domains/domain-detail/domain-detail-tree/domain-detail-tree.component';
import { DomainDetailFormComponent } from './domains/domain-detail/domain-detail-form/domain-detail-form.component';
import { MatMenuModule } from '@angular/material/menu';
import { ThingDetailGraphComponent } from './thing/thing-detail/thing-detail-graph/thing-detail-graph.component';

import { UiComponents } from '@components/ui/ui.module';
import { ThingDetailComponent } from './thing/thing-detail/thing-detail.component';
import { SpecialityCreateComponent } from './speciality/speciality-create/speciality-create.component';
import { SpecialityDetailComponent } from './speciality/speciality-detail/speciality-detail.component';
import { MatChipsModule } from '@angular/material/chips';
import { SpecialityEditComponent } from './speciality/speciality-edit/speciality-edit.component';
import { CommonFormModule } from '@components/form/form.module';
import { SpecialityListComponent } from './speciality/speciality-list/speciality-list.component';
import { ThingDetailUsersComponent } from './thing/thing-detail/thing-detail-users/thing-detail-users.component';
import { DomainDetailEditDialogComponent } from './domains/domain-detail/domain-detail-edit-dialog/domain-detail-edit-dialog.component';
import { MainComponent } from './main/main.component';
import { TeamsModule } from '../teams/teams.module';
import { SpecialityThingsTreeComponent } from './speciality/speciality-things-tree/speciality-things-tree.component';
import { SpecialityVerificationModule } from '../speciality-verification/speciality-verification.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ThingDuplicatesComponent } from './thing/thing-duplicates/thing-duplicates.component';

@NgModule({
  declarations: [
    SpecialityGroupListComponent,
    DomainsComponent,
    SpecialityGroupCreateComponent,
    SpecialityGroupEditComponent,
    SearchComponent,
    ThingsComponent,
    ThingCreateComponent,
    ThingEditComponent,
    ThingDetailGraphComponent,
    LibraryDomainDetailComponent,
    LibraryDomainDetailComponent,
    DomainDetailTreeComponent,
    DomainDetailFormComponent,
    ThingDetailComponent,
    SpecialityCreateComponent,
    SpecialityDetailComponent,
    SpecialityEditComponent,
    SpecialityListComponent,
    ThingDetailUsersComponent,
    DomainDetailEditDialogComponent,
    MainComponent,
    SpecialityThingsTreeComponent,
    ThingDuplicatesComponent,
  ],
  imports: [
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatTooltipModule,
    MatCardModule,
    MatButtonToggleModule,
    MatTreeModule,
    MatGridListModule,
    MatDividerModule,
    MatMenuModule,
    MatChipsModule,
    MatDialogModule,
    MatTabsModule,

    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    CommonModule,
    CommonComponentsModule,
    CommonD3ChartsModule,
    UiComponents,
    CommonFormModule,
    TeamsModule,
    SpecialityVerificationModule,
    MatCheckboxModule,
    MatSlideToggleModule,
  ],
  exports: [
    SpecialityGroupListComponent,
    DomainsComponent,
    SpecialityGroupCreateComponent,
    SpecialityGroupEditComponent,
    LibraryDomainDetailComponent,
    SearchComponent,
    ThingsComponent,
    ThingCreateComponent,
    ThingEditComponent,
    ThingDetailComponent,
    SpecialityCreateComponent,
    SpecialityDetailComponent,
    SpecialityEditComponent,
    SpecialityListComponent,
    MainComponent,
    DomainDetailTreeComponent,
  ],
})
export class LibraryModule {}
