import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Thing } from '@models/ontology/thing';
import { ThingLevel } from '@models/ontology/thing-level';
import { SearchResult } from '@models/teams/macth-users-search';

export type ThingLevelMatchValue = 'greater' | 'exact' | 'littler';

interface ThingLevelMatchOption {
  label: string;
  value: ThingLevelMatchValue;
}

@Component({
  selector: 'app-match-users-thing',
  templateUrl: './match-users-thing.component.html',
  styleUrls: ['./match-users-thing.component.css'],
})
export class MatchUsersThingComponent implements OnInit {
  @Input() thing: Thing = null;
  @Input() searchResult: SearchResult = null;
  @Output() selectThingLevel: EventEmitter<ThingLevel> =
    new EventEmitter<ThingLevel>();

  @Output() selectThingLevelMatch: EventEmitter<{
    thing: Thing;
    thingLevelMatchValue: ThingLevelMatchValue;
  }> = new EventEmitter<{
    thing: Thing;
    thingLevelMatchValue: ThingLevelMatchValue;
  }>();

  @Output()
  changeThingIsRequired: EventEmitter<{
    thing: Thing;
    isThingRequired: boolean;
  }> = new EventEmitter<{
    thing: Thing;
    isThingRequired: boolean;
  }>();

  levelMatchOptions: ThingLevelMatchOption[] = [
    { label: '>=', value: 'greater' },
    { label: '==', value: 'exact' },
    { label: '<=', value: 'littler' },
  ];

  selectedThingLevel: ThingLevel;
  selectedThingLevelMatch: ThingLevelMatchOption;

  ngOnInit(): void {
    // init ng-select default values
    this.selectedThingLevel = this.thing.levels[1];
    this.selectedThingLevelMatch = this.levelMatchOptions[0];

    // init match form init values
    this.selectThingLevel.next(this.thing.levels[1]);
    this.changeThingIsRequired.next({
      thing: this.thing,
      isThingRequired: false,
    });
    this.selectThingLevelMatch.next({
      thing: this.thing,
      thingLevelMatchValue: this.levelMatchOptions[0].value,
    });
  }

  onSelectThingLevel($event: ThingLevel): void {
    this.selectThingLevel.next($event);
  }

  onSelectThingLevelMatch($event: ThingLevelMatchOption): void {
    this.selectThingLevelMatch.next({
      thing: this.thing,
      thingLevelMatchValue: $event.value,
    });
  }

  toggleThingRequired(isThingRequired: boolean): void {
    this.changeThingIsRequired.next({ thing: this.thing, isThingRequired });
  }

  getThingSearchResults(): { isShow: boolean; count: number } {
    return {
      isShow: Boolean(this.searchResult),
      count:
        this.searchResult?.things && this.searchResult.things[this.thing.uuid]
          ? Object.keys(this.searchResult.things[this.thing.uuid].users_uuid)
              .length
          : 0,
    };
  }
}
