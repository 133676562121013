import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@models/user/user';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
})
export class UserListComponent implements OnInit {
  @Input() users: User[];
  @Input() checkedUsers: User[];
  @Input() addedUser = new BehaviorSubject<User>(null);
  @Input() selectMode: 'single' | 'multiple' | 'none' = 'none';
  @Output() selectUsers: EventEmitter<User[]> = new EventEmitter<User[]>();

  selectedUsers: User[] = [];

  constructor() {}

  ngOnInit(): void {
    this._addedUserSubscribe();
    if (this.checkedUsers) this.selectedUsers.push(...this.checkedUsers);
  }

  private _addedUserSubscribe(): void {
    this.addedUser.subscribe((user) => {
      if (user) {
        this.selectedUsers.push(user);
        this.selectUsers.emit(this.selectedUsers);
      }
    });
  }

  onSelectionToggle(user: User): void {
    switch (this.selectMode) {
      case 'none':
        break;
      case 'single':
        if (this.selectedUsers[0] && this.selectedUsers[0].uuid === user.uuid) {
          this.selectedUsers = [];
        } else {
          this.selectedUsers = [user];
        }
        this.selectUsers.emit(this.selectedUsers);
        break;
      case 'multiple':
        if (
          !this.selectedUsers.find(
            (selectedUser) => selectedUser.uuid === user.uuid
          )
        ) {
          this.selectedUsers.push(user);
        } else {
          this.selectedUsers = this.selectedUsers.filter(
            (selectedUser) => selectedUser.uuid !== user.uuid
          );
        }
        this.selectUsers.emit(this.selectedUsers);
        break;
    }
  }

  isUserSelected(userUuid: string): boolean {
    return !!this.selectedUsers.find(
      (selectedUser) => selectedUser.uuid === userUuid
    );
  }
}
