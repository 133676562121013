import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyAnswerOptionService {
  constructor(
    private api_service: ApiService,
    private logging_service: LoggingService
  ) {}

  public fetch_all_for_question_uuid(question_uuid: string): Observable<any> {
    const params = { question: question_uuid, expand: 'survey,question' };
    return this.api_service.get(`surveys/option`, params).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} fetched ${response.count} answer options`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to fetch answer options`
          );
        }
      )
    );
  }

  public create(option_data: any): Observable<any> {
    return this.api_service.post(`surveys/option`, option_data, {}).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} created answer option with uuid ${response.uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to create answer option`
          );
        }
      )
    );
  }

  public update(option_uuid: string, option_data: any): Observable<any> {
    return this.api_service
      .patch(`surveys/option/${option_uuid}`, option_data, {})
      .pipe(
        tap(
          (response) => {
            this.logging_service.debug(
              `${this.constructor.name} updated option with uuid ${response.uuid}`
            );
          },
          (err) => {
            this.logging_service.error(
              `${this.constructor.name} failed to update option`
            );
          }
        )
      );
  }

  public delete(option_uuid: string): Observable<any> {
    return this.api_service.delete(`surveys/option/${option_uuid}`, {}).pipe(
      tap(
        (response) => {
          this.logging_service.debug(
            `${this.constructor.name} deleted option with uuid ${option_uuid}`
          );
        },
        (err) => {
          this.logging_service.error(
            `${this.constructor.name} failed to delete option`
          );
        }
      )
    );
  }
}
