export const sortByField =
  (field: string) =>
  (elements): any => {
    elements.sort((elementA: any, elementB: any) => {
      if (elementA[field] > elementB[field]) {
        return 1;
      }

      if (elementA[field] < elementB[field]) {
        return -1;
      }

      return 0;
    });
  };

export const sort_by_get_field =
  (get_field: any) =>
  (elements): any => {
    elements.sort((elementA: any, elementB: any) => {
      if (get_field(elementA) > get_field(elementB)) {
        return 1;
      }

      if (get_field(elementA) < get_field(elementB)) {
        return -1;
      }

      return 0;
    });
  };

export const sort_recursive =
  (get_field: any, get_children: any) => (elements) => {
    sort_by_get_field(get_field)(elements);
    elements.forEach((element) => {
      if (get_children(element)) {
        sort_recursive(get_field, get_children)(get_children(element));
      }
    });
  };
