<h1 mat-dialog-title>Добавление нового уровня для компетенции</h1>
<form [formGroup]="form">
  <div mat-dialog-content>
    <div>
      <mat-form-field class="full-width-field">
        <mat-label>Название</mat-label>
        <input matInput placeholder="Новый уровень" formControlName="title" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width-field">
        <mat-label>Описание</mat-label>
        <textarea
          matInput
          placeholder="Описание нового уровня"
          formControlName="description"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button
      class="dialog-button"
      (click)="submit()"
      [disabled]="form.invalid"
      mat-raised-button
      cdkFocusInitial
    >
      Создать
    </button>
    <button class="dialog-button" (click)="close()" mat-raised-button>
      Отмена
    </button>
  </div>
</form>
