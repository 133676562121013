<app-with-processing
  [processing]='
    selfCreatedValidations?.state.isProcessing ||
    selfSpecialityReviewer?.state.isProcessing
  '
>
  <div
    class='verifications-container'
    *ngIf='
      selfCreatedValidations?.state.isLoaded &&
      selfSpecialityReviewer?.state.isLoaded
    '
  >
    <div class='title'>Верификация специальностей</div>
    <div>
      <mat-tab-group [animationDuration]="'0ms'" mat-stretch-tabs='false'>
        <mat-tab label='Новый запрос'>
          <ng-container
            [ngTemplateOutlet]='validation_list'
            [ngTemplateOutletContext]='{
              validations: selfUnansweredValidations
            }'
          >
          </ng-container>
        </mat-tab>
        <mat-tab label='Ожидание'>
          <ng-container
            [ngTemplateOutlet]='validation_list'
            [ngTemplateOutletContext]='{
              validations: getFilteredValidations(selfAnsweredValidations)
            }'
          >
          </ng-container>
        </mat-tab>
        <mat-tab label='Одобренные'>
          <ng-container
            [ngTemplateOutlet]='validation_list'
            [ngTemplateOutletContext]='{
              validations: acceptedValidations
            }'
          >
          </ng-container>
        </mat-tab>
        <mat-tab label='Отмененные'>
          <ng-container
            [ngTemplateOutlet]='validation_list'
            [ngTemplateOutletContext]='{
              validations: declinedValidations
            }'
          >
          </ng-container>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</app-with-processing>

<ng-template #validation_list let-validations='validations'>
  <div
    *ngIf='!validations.length'
    fxLayout='row'
    fxLayoutAlign='center center'
    [style.margin-top.px]='30'
  >
    Нет данных
  </div>
  <div *ngIf='validations.length'>
    <div fxLayout='row' [style.margin-top.px]='20'>
      <span fxFlex='50'>Название и описание</span>
      <span fxFlex='20'>Дата</span>
      <span fxFlex='30'>Согласующие</span>
    </div>
    <mat-divider class='divider'></mat-divider>
    <div
      *ngFor='let validation of validations'
      class='validation-container'
      fxLayout='row'
      (click)='redirectToValidation(validation)'
    >
      <div
        fxFlex='50'
        class='validation-name'
        fxLayout='row'
        fxLayoutAlign='start center'
      >
        {{ validation.speciality.name }}
      </div>
      <div fxFlex='20' fxLayoutAlign='start center'>
        <span class='validation-date'>{{
          getPrettyValidationDate(validation.date_created)
          }}</span>
      </div>
      <div
        fxFlex='30'
        fxLayout='column'
        fxLayoutAlign='start start'
        fxLayoutGap='5px'
      >
        <div
          *ngFor='let reviewer of validation.related_reviewers'
          fxLayout='row'
          fxLayoutAlign='start center'
          fxLayoutGap='5px'
        >
          <mat-icon *ngIf='reviewer.status === 0'
          >radio_button_unchecked
          </mat-icon
          >
          <mat-icon *ngIf='reviewer.status === 1' class='green-icon'
          >check_circle
          </mat-icon
          >
          <mat-icon *ngIf='reviewer.status === 2' class='red-icon'
          >cancel
          </mat-icon
          >
          <span>{{
            reviewer.user.last_name + ' ' + reviewer.user.first_name
            }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
