import { Component, Input, OnInit } from '@angular/core';
import { SpecialityGrade } from '@models/specialities/speciality-grade';
import { SpecialityGradeHttpService } from '@services/http/SpecialityGradeHttpService';
import { AsyncList } from '@rest/AsyncList';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-speciality-edit-grades',
  templateUrl: './speciality-edit-grades.component.html',
  styleUrls: ['./speciality-edit-grades.component.css'],
})
export class SpecialityEditGradesComponent implements OnInit {
  @Input() specialityUuid;
  _updateGrade$ = new Subject();
  specialityGrades: AsyncList<SpecialityGrade>;
  constructor(
    private _specialityGradesHttpService: SpecialityGradeHttpService
  ) {
    this.specialityGrades = new AsyncList<SpecialityGrade>(
      this._specialityGradesHttpService
    );
  }

  ngOnInit(): void {
    this.specialityGrades.setRequestParams({
      params: {
        speciality: this.specialityUuid,
      },
    });

    this.specialityGrades.load();

    this._updateGrade$
      .pipe(debounceTime(1000))
      .subscribe((specialityGrade: SpecialityGrade) => {
        this.specialityGrades.update(specialityGrade.uuid, specialityGrade);
      });
  }

  onChange(name: string, uuid: string): void {
    this._updateGrade$.next({ name, uuid });
  }

  removeGrade(uuid: string): void {
    this.specialityGrades.remove(uuid);
  }

  addGrade(): void {
    this.specialityGrades.create({
      speciality: this.specialityUuid,
      name: `Уровень ${this.specialityGrades.state.items.length + 1}`,
    });
  }
}
