<div class="select-thing-level-wrapper">
  <div
    *ngFor="let level of thingLevels.state.items"
    class="thing-level"
    [class.selected]="isSelected(level)"
    (click)="selectLevel(level)"
  >
    <div fxLayout="row">
      <div fxFlex>
        <div fxLayout="row" class="level-header">
          <div class="level-title" fxFlex>
            {{ level.title }}
          </div>
          <div
            *ngIf="
              competence &&
              competence.is_verified &&
              level.uuid === this.currentLevel?.uuid
            "
            fxFlex
            fxLayoutAlign="end center"
            class="verified-info"
          >
            <span class="verified-text">Подтверждено</span>
            <mat-icon
              class="verified-icon"
              matTooltip="{{
                getVerifiedCompetenceTooltipText(competence.verified_by) +
                  '\n' +
                  (competence.date_verified | date: 'dd-MM-yyyy hh:mm')
              }}"
              matTooltipClass="verified-tooltip"
            >
              done_outline
            </mat-icon>
          </div>
        </div>
        <div
          class="level-description"
          *ngIf="isSelected(level)"
          [innerHTML]="level.description | newlineToBr"
        ></div>
      </div>
    </div>
  </div>
  <button
    mat-stroked-button
    color="primary"
    [disabled]="selectedLevelUuid === '' || isSaveButtonDisabled"
    (click)="save()"
  >
    <mat-icon>save</mat-icon>
    Сохранить выбор
  </button>
</div>
