<ng-container *ngIf="is_data_loaded; then tmpl_answer_edit; else preloader">
</ng-container>

<ng-template #preloader>
  <app-preloader></app-preloader>
</ng-template>

<ng-template #tmpl_answer_edit>
  <div class="app-admin-survey-edit-tree-question-container">
    <div class="app-admin-survey-edit-tree-head" fxLayout="row">
      <div class="app-admin-survey-edit-tree-head-notification" fxFlex="50" fxLayoutAlign="center center">
        {{ thing_notification }}
      </div>
      <div class="app-admin-survey-edit-tree-head-alert" fxLayout="column" fxFlex="50">
        <div class="app-admin-survey-edit-tree-head-alert-icon">
          <mat-icon>info</mat-icon>
        </div>
        <div class="app-admin-survey-edit-tree-head-alert-description">
          Вы можете изменить формулировку вопроса,ответа или описания, но не связанный навык. Все уже данные ответы, и будущие, сохранят связь с навыком, поэтому сильные изменения могут повлиять на понимание результатов опроса.
        </div>
      </div>
    </div>

    <mat-divider class="app-admin-survey-edit-tree-head-alert-dash"></mat-divider>
    <br>

    <ng-container
      [ngTemplateOutlet]=" head_is_editing ? tmpl_question_edit_mode : tmpl_question_normal_mode">
    </ng-container>

    <mat-divider></mat-divider>
    <br>

    <div *ngFor="let answer_option of current_question_answer_options">
      <ng-container
        [ngTemplateOutlet]=" editing_answer == answer_option.uuid ? tmpl_answer_edit_mode : tmpl_answer_normal_mode"
        [ngTemplateOutletContext]=" {answer:answer_option} ">
      </ng-container>
    </div>
  </div>

  <div class="app-admin-survey-edit-tree-buttons-container" fxLayout="row" fxLayoutAlign="center center"
       fxLayoutGap="20px">
    <button mat-raised-button
            aria-label="Cancel" routerLink="/admin/surveys/survey/{{survey_uuid}}/edit">
      Назад
    </button>
  </div>
</ng-template>

<ng-template #tmpl_question_edit_mode>
  <div class="app-admin-survey-edit-tree-head" fxLayout="row" fxLayoutGap="1rem">
    <form [formGroup]="question_edit_form" fxFlex="90" fxLayout="column">
      <div class="form-container">
        <input #question_new_title class="app-admin-survey-edit-tree-question-edit-field"
               formControlName="question_title" matInput placeholder="Вопрос*" autocomplete="off" fxFlex>
        <div class="app-admin-survey-edit-tree-field-title-count"
             [class.warn]="question_new_title.value.length > 128 || question_new_title.value.length == 0">
          {{ question_new_title.value.length }}/128
        </div>
      </div>
      <div class="app-admin-survey-edit-tree-question-edit-field-dash"
           [class.warn]="question_new_title.value.length == 0"></div>
      <textarea #question_new_description class="app-admin-survey-edit-tree-question-edit-description"
                [class.warn]="question_new_description.value.length > 1024" formControlName="question_description"
                matInput placeholder="Описание*"></textarea>
      <div class="app-admin-survey-edit-tree-field-count" [class.warn]="question_new_description.value.length > 1024">
        {{ question_new_description.value.length }}/1024
      </div>
    </form>
    <div fxFlex="10" fxLayout="column" fxLayoutAlign="center">
      <div class="app-admin-survey-edit-tree-question-button-area" fxLayoutAlign="center center">
        <button mat-icon-button
                matTooltip="Сохранить"
                aria-label="Save question"
                [disabled]="question_edit_form.invalid"
                (click)="save_question_data()">
          <mat-icon>save</mat-icon>
        </button>
        <button mat-icon-button
                color="warn"
                matTooltip="Отмена"
                aria-label="Cancel editing"
                (click)="cancel_edit_question()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_question_normal_mode>
  <app-preloader *ngIf="!question_data_loaded"></app-preloader>
  <div *ngIf="question_data_loaded" class="app-admin-survey-edit-tree-head" fxLayout="row">
    <div class="app-admin-survey-edit-tree-head-title" fxLayout="column" fxFlex>
      <div class="app-admin-survey-edit-tree-head-name">
        {{current_question.title}}
      </div>
      <div *ngIf="current_question.description" class="app-admin-survey-edit-tree-head-description">
        {{current_question.description}}
      </div>
      <div *ngIf="!current_question.description" class="app-admin-survey-edit-tree-head-description-empty">
        Для этого вопроса пока нет описания, добавив его в поможете участникам опроса
      </div>
    </div>
    <div fxFlex="5rem" class="app-admin-survey-edit-tree-buttons-container" fxLayoutAlign="center center"
         style="margin-right: 1rem">
      <button mat-raised-button
              aria-label="Edit"
              (click)="edit_head()">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_answer_edit_mode let-answer='answer'>
  <div class="app-survey-edit-tree-answer-options-container" fxLayout="row" fxLayoutGap="1rem">
    <form [formGroup]="answer_edit_form" fxFlex="90" fxLayout="column">
      <div class="app-survey-edit-tree-answer-options-listitem-title">
        {{ answer.title }}
      </div>
      <textarea #answer_new_description class="app-admin-survey-edit-tree-answer-edit-description"
                formControlName="answer_description" matInput placeholder="Описание*"
                [class.warn]="answer_edit_form.invalid"></textarea>
      <div class="app-admin-survey-edit-tree-field-count" [class.warn]="answer_edit_form.invalid">
        {{ answer_new_description.value.length }}/1024
      </div>
    </form>
    <div fxFlex="10" fxLayout="column" fxLayoutAlign="center">
      <div class="app-admin-survey-edit-tree-question-button-area" fxLayoutAlign="center center">
        <button mat-icon-button
                matTooltip="Сохранить"
                aria-label="Save answer"
                [disabled]="answer_edit_form.invalid"
                (click)="save_answer_new_data()">
          <mat-icon>save</mat-icon>
        </button>
        <button mat-icon-button
                color="warn"
                matTooltip="Отмена"
                aria-label="Cancel editing"
                (click)="cancel_edit_answer()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tmpl_answer_normal_mode let-answer='answer'>
  <div class="app-survey-edit-tree-answer-options-container">
    <div fxLayout="row">
      <div fxFlex>
        <div class="app-survey-edit-tree-answer-options-listitem-title">
          {{ answer.title }}
        </div>
        <div class="app-survey-tree-answer-options-listitem-description"
             [innerHTML]="answer.description | newlineToBr">
        </div>
      </div>
      <div fxFlex="5rem" class="app-admin-survey-edit-tree-buttons-container" fxLayoutAlign="center center">
        <button mat-raised-button
                aria-label="Edit"
                (click)="edit_answer(answer)">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
