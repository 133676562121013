import { Component, Input, OnInit } from '@angular/core';

import { LoggingService } from 'src/app/services/logging.service';
import { AlertService } from 'src/app/services/ui/ui-alert.service';
import { SurveySessionService } from 'src/app/services/survey/survey-session.service';

import { User } from 'src/app/models/user/user';
import { Survey } from 'src/app/models/survey/survey';
import { SurveySession } from 'src/app/models/survey/survey-session';


@Component({
  selector: 'app-survey-details-tab-sessions',
  templateUrl: './survey-details-tab-sessions.component.html',
  styleUrls: ['./survey-details-tab-sessions.component.css']
})
export class SurveyDetailsTabSessionsComponent implements OnInit {

  @Input() survey: Survey;

  public survey_sessions: SurveySession[] = [];

  private uistate = {
    survey_sessions_loaded: false,
  }

  constructor(
    private logging_service: LoggingService,
    private alert_service: AlertService,
    private survey_session_service: SurveySessionService,
  ) { }

  ngOnInit(): void {
    this.logging_service.debug(`${this.constructor.name} init`);
    this.load_survey_sessions();
  }

  public get is_data_loaded(): boolean {
    return this.uistate.survey_sessions_loaded;
  }

  private load_survey_sessions(): void {
    this.survey_session_service.fetch_all_for_survey(this.survey.uuid).subscribe(
      response => {
        this.survey_sessions = response.results as SurveySession[];
        this.uistate.survey_sessions_loaded = true;
        this.logging_service.debug(`${this.constructor.name} loaded survey sessions`);
      },
      err => {
        this.logging_service.debug(`${this.constructor.name} failed to load survey sessions`);
        this.alert_service.error(`Ошибка загрузки сессий ${err.status}`);
      }
    );
  }

  public util_user_get_initials_for_avatar(user: User): string {
    const first_name_initial = user.first_name.charAt(0);
    const last_name_initial = user.last_name.charAt(0);
    return `${first_name_initial}${last_name_initial}`
  }

}
