import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewSetHttpService } from './rest/ViewSetHttpService';
import { SpecialityGroup } from 'src/app/models/specialities/speciality-group';

@Injectable({
  providedIn: 'root',
})
export class SpecialityGroupHttpService extends ViewSetHttpService<SpecialityGroup> {
  constructor(_http: HttpClient) {
    super(_http);
  }
  public viewSetUrl = 'specialities/group';
}
